import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Listbox } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import usePagination, { DOTS } from 'hooks/usePagination';
import { ReactComponent as DownIcon } from 'assets/images/arrow-down.svg';
import { ReactComponent as PrevIcon } from 'assets/images/arrow-back.svg';
import { ReactComponent as NextIcon } from 'assets/images/arrow-forward.svg';
import styles from './Table.module.scss';

function Pagination({
  currentPage,
  changePage,
  count,
  rowsPerPage,
  changeRowsPerPage,
  rowsPerPageOptions = [10, 20, 30],
}) {
  const [pageCount, setPageCount] = useState(0);

  const paginationRange = usePagination({
    currentPage,
    pageCount,
    siblingCount: 1,
  });

  useEffect(() => {
    setPageCount(Math.ceil(count / rowsPerPage));
  }, [count, rowsPerPage]);

  return (
    <div className={styles.footer}>
      {changeRowsPerPage ? (
        <div className={styles.itemCountSelection}>
          <p>Items per page</p>
          <Listbox value={rowsPerPage} onChange={changeRowsPerPage}>
            <Float placement="top-start" offset={4} portal>
              <Listbox.Button className={styles.perPageSelectionButton}>
                {rowsPerPage}
                <DownIcon />
              </Listbox.Button>
              <Listbox.Options className={styles.selectDropdown}>
                {rowsPerPageOptions.map((value) => (
                  <Listbox.Option
                    key={value}
                    value={value}
                    className={({ active }) =>
                      clsx({ [styles.option]: true, [styles.active]: active })
                    }
                  >
                    {value}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Float>
          </Listbox>
        </div>
      ) : (
        <span />
      )}
      <div className={styles.paginationWrapper}>
        <button
          type="button"
          className={styles.paginationButton}
          onClick={() => changePage(currentPage - 1)}
          disabled={currentPage <= 1}
        >
          <PrevIcon />
        </button>
        {paginationRange.map((page, index) => (
          <button
            type="button"
            className={clsx({
              [styles.paginationButton]: true,
              [styles.active]: currentPage === page,
            })}
            key={`page-${page}-${index}`}
            onClick={() => changePage(page)}
            disabled={page === DOTS}
          >
            {page}
          </button>
        ))}
        <button
          type="button"
          className={styles.paginationButton}
          onClick={() => changePage(currentPage + 1)}
          disabled={pageCount <= currentPage}
        >
          <NextIcon />
        </button>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  changePage: PropTypes.func,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  changeRowsPerPage: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

export default Pagination;
