import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import { ReactComponent as CloseIcon } from 'assets/images/colored-cross.svg';
import styles from './dialog.module.scss';

const sizes = {
  xs: styles.xs,
  sm: styles.sm,
  md: styles.md,
  lg: styles.lg,
  xl: styles.xl,
};

const postions = {
  center: styles.center,
  'top-left': styles.topLeft,
  'top-center': styles.topCenter,
  'top-right': styles.topRight,
  'bottom-left': styles.bottomLeft,
  'bottom-center': styles.bottomCenter,
  'bottom-right': styles.bottomRight,
  'center-left': styles.centerLeft,
  'center-right': styles.centerRight,
};

const Dialog = ({
  title,
  render,
  children,
  open = false,
  close,
  showCloseBtn = true,
  size = 'sm',
  fullWidth = false,
  fullHeight = false,
  postion = 'center',
  rounded = false,
  isTransparentPanel = false,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
    if (close) close();
  };
  const onOpen = () => setIsOpen(true);

  const isDefinedSize = useMemo(
    () => Object.keys(sizes)?.includes(size),
    [size]
  );

  return (
    <>
      {render ? render({ open: onOpen }) : null}
      <Transition appear show={isOpen || open} as={Fragment}>
        <HeadlessDialog {...rest} onClose={onClose} className={styles.dialog}>
          <Transition.Child
            as={Fragment}
            enter={styles.enter}
            enterFrom={styles.opacity0}
            enterTo={styles.opacity1}
            leave={styles.leave}
            leaveFrom={styles.opacity1}
            leaveTo={styles.opacity0}
          >
            <HeadlessDialog.Overlay className={styles.overlay} />
          </Transition.Child>
          <div
            className={clsx({
              [styles.dialogContainer]: true,
              [postions[postion]]: postions,
            })}
          >
            <Transition.Child
              as={Fragment}
              enter={styles.enter}
              enterFrom={styles.hidden}
              enterTo={styles.view}
              leave={styles.leave}
              leaveFrom={styles.view}
              leaveTo={styles.hidden}
            >
              <HeadlessDialog.Panel
                className={clsx({
                  [styles.dialogPanel]: true,
                  [sizes[size]]: isDefinedSize,
                  [styles.fullWidth]: fullWidth,
                  [styles.fullHeight]: fullHeight,
                  [styles.rounded]: rounded,
                  [styles.transparent]: isTransparentPanel,
                })}
                style={isDefinedSize ? undefined : { maxWidth: size }}
              >
                {title ? (
                  <div className={styles.headerContainer}>
                    <HeadlessDialog.Title className={styles.dialogTitle}>
                      {title}
                    </HeadlessDialog.Title>
                    {showCloseBtn ? (
                      <button type="button" onClick={onClose}>
                        <CloseIcon className={styles.closeIcon} />
                      </button>
                    ) : null}
                  </div>
                ) : null}
                {children}
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </HeadlessDialog>
      </Transition>
    </>
  );
};

Dialog.propTypes = {
  title: PropTypes.node,
  render: PropTypes.func,
  showCloseBtn: PropTypes.bool,
  children: PropTypes.node,
  open: PropTypes.bool,
  close: PropTypes.func,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  postion: PropTypes.oneOf(Object.keys(postions)),
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  rounded: PropTypes.bool,
  isTransparentPanel: PropTypes.bool,
};

export default Dialog;
