import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { uploadCamapignDeliverable } from 'api/influencer/campaign';
import { queryClient } from 'App';
import { InputWrapper, Input } from 'components/common/campaign';
import ErrorMessage from 'components/common/form/ErrorMessage';
import Dropzone from 'components/common/form/Dropzone';
import Button from 'components/common/Button';
import styles from 'assets/scss/pages/popup.module.scss';
import {
  uplImgVideoIcon,
  campaignPopupImg,
  descIcon,
  featherEyeImg,
} from 'constants/images';
import { INF_UPLOAD_CAMPAIGN_DELIVERABLE_SCHEMA } from 'constants/schema';
import errorMessages from 'constants/config/error-messagess';
import { VIDEO_EXTENTIONS_REGX } from 'constants/config/regex';
import TagsSection from './TagsSection';

const defaultValues = {
  title: '',
  description: '',
  tags: [],
  media: '',
  platform: '',
};

const DirectUploadForm = ({
  close,
  platform,
  campaignId,
  campaignTags,
  deliverableType,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(INF_UPLOAD_CAMPAIGN_DELIVERABLE_SCHEMA),
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tags',
  });

  const onClose = () => {
    reset();
    close();
  };
  const uploadDeliverableMutation = useMutation({
    mutationFn: ({ title, description, tags, media, campaignId, platform }) => {
      const payload = new FormData();

      payload.append('title', title);
      if (description) payload.append('description', description);
      payload.append('tags', JSON.stringify(tags.map(({ name }) => name)));
      payload.append('media', deliverableType);
      payload.append('platform', platform);
      if (media?.[0]) {
        payload.append('file', media[0]);
      }

      return uploadCamapignDeliverable({
        campaignId,
        body: payload,
      });
    },
    onSuccess: (res) => {
      toast.success(res?.message, {
        position: 'top-center',
      });
      onClose();
    },
    onSettled: (res, error, { campaignId, platform }) => {
      queryClient.invalidateQueries({
        queryKey: ['influencer-campaign-deliverables', campaignId, platform],
      });
      queryClient.invalidateQueries({
        queryKey: ['influencer-campaign-details', campaignId],
      });
    },
  });

  const onSubmit = (data) => {
    uploadDeliverableMutation.mutate({ ...data, campaignId });
  };

  useEffect(() => {
    setValue('platform', platform);
  }, [platform, setValue]);

  const getAllowedFileTypes = (deliverableType) => {
    if (deliverableType === 'image') {
      return {
        'image/jpeg': [],
        'image/png': [],
      };
    }
    if (
      deliverableType === 'video' ||
      deliverableType === 'reel' ||
      deliverableType === 'stories' ||
      deliverableType === 'shorts'
    ) {
      return {
        'video/mp4': [],
        'video/quicktime': [], // for .mov
      };
    }
    if (deliverableType === 'posts') {
      return {
        'image/jpeg': [],
        'image/png': [],

        'video/mp4': [],
        'video/quicktime': [], // for .mov
      };
    }

    return {
      'image/jpeg': [],
      'image/png': [],
    };
  };

  const allowedFileTypeMessage =
    (deliverableType === 'image' && errorMessages.INSTA_IMAGE) ||
    (deliverableType === 'video' &&
      platform === 'instagram' &&
      errorMessages.INSTA_VIDEO) ||
    (deliverableType === 'reel' && errorMessages.INSTA_REEL) ||
    (deliverableType === 'stories' && errorMessages.INSTA_STORY) ||
    (deliverableType === 'video' &&
      platform === 'youtube' &&
      errorMessages.YOUTUBE_VIDEO) ||
    (deliverableType === 'shorts' && errorMessages.YOUTUBE_SHORTS) ||
    (deliverableType === 'posts' &&
      platform === 'facebook' &&
      errorMessages.FB_POST);
  return (
    <>
      <div className={styles.secBody} style={{ height: 'calc(100vh - 299px)' }}>
        <form id="upload-deliverable-form" onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper icon={campaignPopupImg}>
            <Input
              label="Title"
              maxLength="80"
              error={errors?.title}
              {...register('title')}
            />
          </InputWrapper>
          <InputWrapper icon={descIcon}>
            <Input
              label="Description"
              maxLength="255"
              error={errors?.description}
              {...register('description')}
            />
          </InputWrapper>
          <TagsSection
            fields={fields}
            append={append}
            remove={remove}
            errors={errors}
            tags={campaignTags?.map(({ id, hashtag_name: name }) => ({
              tagId: id,
              name,
            }))}
          />
          {platform !== 'twitter' ? (
            <InputWrapper icon={uplImgVideoIcon}>
              <div className={styles.popupInputWrap}>
                <h2 className={styles.hTxt}>Upload Images/Videos</h2>
                <Controller
                  render={({ field }) => {
                    let isSelectedFile = '';
                    let fileName = '';
                    let fileExt = '';
                    let isVideo = '';
                    let previewUrl = '';
                    if (field?.value === '' || field?.value?.length > 0) {
                      isSelectedFile = field.value[0] instanceof File;
                      fileName = isSelectedFile
                        ? field?.value[0]?.name
                        : field?.value?.split('/')?.slice(-1)[0];
                      fileExt = fileName
                        ?.split('.')
                        ?.slice(-1)?.[0]
                        ?.toLowerCase();

                      isVideo = fileExt?.match(VIDEO_EXTENTIONS_REGX);
                      previewUrl = isSelectedFile
                        ? URL.createObjectURL(field.value[0])
                        : field.value;
                    }
                    return (
                      <>
                        <Dropzone
                          wrapperClass={styles.uploadFolderSec}
                          ref={field.ref}
                          onChange={field.onChange}
                          render={() => (
                            <>
                              <img src="#" alt="" />
                              <h3 className={styles.dragDropTxt}>
                                <span>Drag & drop</span> or <span>browse</span>{' '}
                                from your computer
                              </h3>
                              <p className={styles.folderSizeTxt}>
                                {allowedFileTypeMessage}
                                {/* Support .jpg, .png, .gif, .mp4 max 200 MB */}
                              </p>
                            </>
                          )}
                          accept={getAllowedFileTypes(deliverableType)}
                        />
                        {field.value && (
                          <div className={styles.previewContainer}>
                            {isVideo ? (
                              // eslint-disable-next-line jsx-a11y/media-has-caption
                              <video
                                src={previewUrl}
                                className={styles.preview}
                              />
                            ) : (
                              <img
                                src={previewUrl}
                                width="80"
                                height="80"
                                className={styles.preview}
                              />
                            )}
                            <div className={styles.fileInfoWrapper}>
                              <p className={styles.filename}>{fileName}</p>
                              <p className={styles.filesize}>
                                {isSelectedFile
                                  ? `${(
                                      (field.value[0].size || 0) / 1048576
                                    ).toFixed(2)} MB`
                                  : ' '}
                              </p>
                            </div>
                            <a
                              className={styles.viewLink}
                              href={previewUrl}
                              target="_blank"
                              rel="noreferrer"
                              title="View File"
                            >
                              <img src={featherEyeImg} alt="view" />
                            </a>
                          </div>
                        )}
                      </>
                    );
                  }}
                  control={control}
                  name="media"
                />
                <ErrorMessage error={errors?.media} />
              </div>
            </InputWrapper>
          ) : null}
        </form>
      </div>
      <div className={styles.createInfFooter}>
        <div className={clsx(styles.campaignBtns, styles.nxtWrap)}>
          <Button
            type="submit"
            style={{ width: 166 }}
            className={styles.updateBtn}
            form="upload-deliverable-form"
            size="large"
            isLoading={uploadDeliverableMutation.isLoading}
          >
            Publish
          </Button>
        </div>
      </div>
    </>
  );
};

DirectUploadForm.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  platform: PropTypes.oneOf(['instagram', 'youtube', 'twitter', 'facebook']),
  deliverableType: PropTypes.string,
  campaignTags: PropTypes.array,
  campaignId: PropTypes.string,
};

export default DirectUploadForm;
