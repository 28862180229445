const routes = {
  HOME: '/',
  SUBSCRIPTION: '/subscription',
  PRIVACY_POLICY: '/privacy-policy',
  ABOUT_US: '/about-us',
  CONTACT_US: '/contact-us',
  FAQ: '/faq',
  HELP: '/help-and-support',
  PAYMENTS: '/payments',
  CASE_STUDIES: '/case-studies',
  BRAND_TERMS_AND_CONDTIONS: '/terms-and-conditions/brand',
  INF_TERMS_AND_CONDTIONS: '/terms-and-conditions/influencer',

  BRAND: '/brand',
  BRAND_LOGIN: '/brand/login',
  BRAND_SIGNUP: '/brand/signup',
  BRAND_SIGNUP_VERIFY_OTP: '/brand/signup-verify',
  BRAND_SIGNUP_PROFILE: '/brand/signup-profile-setup',
  BRAND_SIGNUP_ADD_ADDRESS: '/brand/signup-add-address',
  BRNAD_HELP: '/brand/help-and-support',

  BRAND_DASHBOARD: '/brand/dashboard',
  BRAND_SEARCH_INF: '/brand/dashboard/search',
  BRAND_SEARCH_INF_LIST: '/brand/dashboard/search/inf-list',
  BRAND_SEARCH_CUSTOM_LIST: '/brand/dashboard/search/custom-list',
  BRAND_COMPARE_INFS: '/brand/dashboard/search/compare-influencers',
  BRAND_CAMPAIGNS: '/brand/dashboard/campaigns',
  BRAND_NOTIFICATIONS: '/brand/dashboard/notifications',
  BRAND_PROFILE: '/brand/dashboard/profile',
  BRAND_PROFILE_SUBSCRIPTION: '/brand/dashboard/profile/subscription',
  BRAND_PROFILE_CAMPAIGN_REPORTS: '/brand/dashboard/profile/campaign-reports',
  BRAND_ANALYSIS: '/brand/dashboard/brand-analysis',

  INF: '/influencer',
  INF_LOGIN: '/influencer/login',
  INF_SIGNUP: '/influencer/signup',
  INF_SIGNUP_VERIFY_OTP: '/influencer/signup-verify',
  INF_SIGNUP_PROFILE: '/influencer/signup-profile-setup',
  INF_SIGNUP_ADD_ADDRESS: '/influencer/signup-add-address',
  INF_SIGNUP_ADD_SOCIAL: '/influencer/signup-add-social',
  INF_SIGNUP_ADD_RATE_CARD: '/influencer/signup-add-rate-card',
  INF_FORGOT_PASS: '/influencer/forgot-password',
  INF_RESET_PASS: '/influencer/reset-password',
  INF_HELP: '/influencer/help-and-support',

  INF_DASHBOARD: '/influencer/dashboard',
  INF_CAMPAIGNS: '/influencer/dashboard/campaigns',
  INF_CAMPAIGN_REPORTS: '/influencer/dashboard/reports',
  INF_PROFILE: '/influencer/dashboard/profile',
  INF_NOTIFICATIONS: '/influencer/dashboard/notifications',
  INF_ANALYSE_SELF_PROFILE: '/influencer/dashboard/analyse-self-profile',
};

Object.freeze(routes);
export default routes;
