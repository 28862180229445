import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { format } from 'date-fns';
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
} from 'recharts';
import InfoTooltip from 'components/common/Tooltip';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import { growthGrayIcon } from 'constants/images';
import { formatNumber } from 'utils';
import { NoData } from 'components/common/NoData';

const FollowerGrowth = ({ data, isLoading, filter, handleFilterChange }) => (
  <>
    <div className={styles.navCategory}>
      <div className={styles.sectionHeader}>
        <img src={growthGrayIcon} alt="" />
        <h2 className={styles.contntTxt}>Growth</h2>
      </div>
      <div className={clsx(styles.sectionBody, styles.growthSection)}>
        <div className={clsx(styles.tabCardSection, styles.growthWrapper)}>
          <div className={styles.sectionHeader}>
            <h3 className={styles.followerLabel}>30D FOLLOWERS GROWTH RATE</h3>
            <InfoTooltip label="(Followers gain in 30 days / Total Followers) * 100" />
          </div>
          <div className={styles.sectionBottom}>
            <p className={styles.followerCount}>
              {formatNumber(data?.followersGrowthRate30Day, {
                maximumFractionDigits: 2,
                notation: 'standard',
              })}
              %
            </p>
          </div>
        </div>
        <div className={clsx(styles.tabCardSection, styles.growthWrapper)}>
          <div className={styles.sectionHeader}>
            <h3 className={styles.followerLabel}>30D FOLLOWERS GAIN</h3>
            <InfoTooltip label="Total Followers gain in last 30 days" />
          </div>
          <div className={styles.sectionBottom}>
            <p className={styles.followerCount}>
              {formatNumber(data?.followersGain30Day)}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.navCategory} style={{ marginTop: '25px' }}>
      <div className={styles.postHeader}>
        <div>
          <h2 className={styles.insightHeading}>FOLLOWERS GROWTH</h2>
        </div>
        <div>
          <ul className={styles.TimeBox}>
            <button
              type="button"
              className={clsx(
                styles.yearList,
                filter === 90 && styles.activeYearList
              )}
              onClick={() => handleFilterChange(90)}
            >
              3m
            </button>
            <button
              type="button"
              className={clsx(
                styles.yearList,
                filter === 180 && styles.activeYearList
              )}
              onClick={() => handleFilterChange(180)}
            >
              6M
            </button>
            <button
              type="button"
              className={clsx(
                styles.yearList,
                filter === 365 && styles.activeYearList
              )}
              onClick={() => handleFilterChange(365)}
            >
              1y
            </button>
          </ul>
        </div>
      </div>
      <div className={styles.benchmarkGraph}>
        {!isLoading && !data?.followersGrowthData?.length ? (
          <NoData height={400} />
        ) : (
          <ResponsiveContainer width="100%" height={400}>
            <AreaChart
              width={500}
              height={400}
              data={data?.followersGrowthData || []}
              margin={{
                top: 30,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid
                vertical={false}
                strokeWidth={1}
                stroke="#e6e6e6a6"
              />
              <XAxis
                dataKey="timestamp"
                tickLine={false}
                axisLine={false}
                fontSize={12}
                fill="#7B8794"
                tickFormatter={(value) =>
                  value ? format(new Date(value), 'dd MMM yyyy') : ''
                }
                dy={30}
                angle={-45}
                height={80}
              />
              <YAxis domain={['dataMin', 'dataMax']} dataKey="followers" hide />
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ fill: 'transparent' }}
                wrapperStyle={{ outline: 'none' }}
              />
              <Area
                type="monotone"
                dataKey="followers"
                stroke="transparent"
                fill="url(#followers-growth-gradient)"
                isAnimationActive={false}
              />
              <defs>
                <linearGradient
                  x1=".5"
                  x2=".5"
                  y2="1"
                  id="followers-growth-gradient"
                >
                  <stop stopColor="#000" stopOpacity="0" />
                  <stop stopColor="#FAA3FC" />
                  <stop offset="661" stopColor="#E9E9E9" />
                </linearGradient>
              </defs>
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  </>
);

FollowerGrowth.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  filter: PropTypes.number,
  handleFilterChange: PropTypes.func,
};

const CustomTooltip = ({ payload }) => {
  const { timestamp, followers } = payload?.[0]?.payload || {};

  return (
    <div
      style={{
        background: '#fff',
        padding: 8,
        border: '1px solid #FF00A233',
        boxShadow: '0px 2px 10px #FF00A24D',
      }}
    >
      <p style={{ fontSize: 14, color: '#7B8794', fontWeight: 600 }}>
        {timestamp ? format(new Date(timestamp), 'dd MMM yyyy') : ''}
      </p>
      <p style={{ fontSize: 14, color: '#d633db', fontWeight: 600 }}>
        {formatNumber(followers, {
          maximumFractionDigits: 2,
          notation: 'standard',
        })}
      </p>
    </div>
  );
};

CustomTooltip.propTypes = {
  payload: PropTypes.array,
};

export default FollowerGrowth;
