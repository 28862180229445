import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { Element } from 'react-scroll';
import { useQuery } from '@tanstack/react-query';
import {
  getFollowersGrowth,
  getProfile,
  getRank,
} from 'api/influencer/profile';
import {
  BrandsSection,
  ContentSection,
  EngagementCard,
  InfContentPerformanceAndBenchmark,
  InfFollowerGrowth,
  InfProfileDetailsNavbar,
  OverviewCard,
} from 'components/common/inf-profile';
import { formatNumber } from 'utils';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import {
  brandIcon,
  contentIcon,
  growthGrayIcon,
  heartGrayIcon,
  instaReels,
  instaVideos,
  platformIcon,
} from 'constants/images';
import { NoData } from 'components/common/NoData';

const Youtube = () => {
  const [follersGrowthFilter, setFollersGrowthFilter] = useState(90);

  const { data: influencer } = useQuery({
    queryKey: ['influencer-profile'],
    queryFn: () => getProfile(),
  });

  const { data: infleucnerRank } = useQuery({
    queryKey: ['influencer-rank'],
    queryFn: () => getRank(),
  });

  const { data: followerGrowthData, isLoading: isLoadingFollowersGrowth } =
    useQuery({
      queryKey: ['influencer-follower-growth', 'youtube', follersGrowthFilter],
      queryFn: () => {
        const params = {
          follower_growth_filter: follersGrowthFilter,
          platform: 'youtube',
        };
        return getFollowersGrowth(params);
      },
    });

  const { youtubeProfile } = influencer || {};
  const {
    shortsStats,
    videoStats,
    brandPosts,
    recentPosts,
    topPosts,
    hashtags,
    brands,
  } = youtubeProfile || {};

  const contentPerformance = useMemo(
    () =>
      recentPosts?.map((video) => ({
        videoId: video.postId,
        views: Number(video.viewsCount),
      })),
    [recentPosts]
  );

  return (
    <section style={{ position: 'relative' }}>
      <InfProfileDetailsNavbar
        config={[
          {
            title: 'Overview',
            to: 'overview',
            icon: platformIcon,
            offset: -200,
          },
          { title: 'Engagement', to: 'engagement', icon: heartGrayIcon },
          // { title: 'Audience', to: 'audience', icon: usersGrayIcon },
          { title: 'Growth', to: 'growth', icon: growthGrayIcon },
          { title: 'Content', to: 'content', icon: contentIcon },
          { title: 'Brands', to: 'brands', icon: brandIcon },
        ]}
      />

      <Element className={styles.navContentSection} name="overview">
        <OverviewCard
          title="Subscribers"
          value={formatNumber(youtubeProfile?.followers)}
        />
        {/* <OverviewCard
          title="Total videos"
          value={formatNumber(youtubeProfile?.totalMedia)}
        /> */}
        <OverviewCard
          title="Estimated reach"
          value={formatNumber(followerGrowthData?.estimatedReach)}
        />
        {/* <OverviewCard
          title="SMINCO Score"
          value={formatNumber(youtubeProfile?.smincoScore)}
          chipValue={infleucnerRank?.topRankText}
          info="Platform Score"
        /> */}
      </Element>

      <Element className={styles.navCategory} name="engagement">
        <div className={styles.sectionHeader}>
          <img src={heartGrayIcon} alt="" />
          <h2 className={styles.contntTxt}>Engagements & Views</h2>
        </div>
        <div className={clsx(styles.sectionBody, styles.engamntBody)}>
          <EngagementCard
            icon={instaVideos}
            title="Videos"
            items={[
              // {
              //   name: 'Total Videos',
              //   value: formatNumber(videoStats?.total),
              // },
              {
                name: 'Avg. Views',
                value: formatNumber(videoStats?.avgViews),
              },
              {
                name: 'Avg. Likes',
                value: formatNumber(videoStats?.avgLikes),
              },
              {
                name: 'Avg. Comments',
                value: formatNumber(videoStats?.avgComments),
              },
              {
                name: 'Likes-Comments Ratio ',
                value: formatNumber(videoStats?.likeCommentRatio, {
                  maximumFractionDigits: 2,
                }),
              },
            ]}
          />
          <EngagementCard
            icon={instaReels}
            title="Shorts"
            items={[
              // {
              //   name: 'Total Shorts',
              //   value: formatNumber(shortsStats?.total),
              // },
              {
                name: 'Avg. Views',
                value: formatNumber(shortsStats?.avgViews),
              },
              {
                name: 'Avg. Likes',
                value: formatNumber(shortsStats?.avgLikes),
              },
              {
                name: 'Avg. Comments',
                value: formatNumber(shortsStats?.avgComments),
              },
              {
                name: 'Likes-Comments Ratio ',
                value: formatNumber(shortsStats?.likeCommentRatio, {
                  maximumFractionDigits: 2,
                }),
              },
            ]}
          />
        </div>

        <InfContentPerformanceAndBenchmark
          data={contentPerformance}
          valueKey="views"
          influencerAverage={youtubeProfile?.avgViews}
        />
      </Element>

      <Element name="growth">
        <InfFollowerGrowth
          data={followerGrowthData}
          isLoading={isLoadingFollowersGrowth}
          handleFilterChange={setFollersGrowthFilter}
          filter={follersGrowthFilter}
        />
      </Element>
      <Element name="content">
        <ContentSection
          brandPosts={brandPosts}
          hashtags={hashtags}
          recentPosts={recentPosts}
          topPosts={topPosts}
        />
      </Element>
      <Element name="brands">
        <BrandsSection brands={brands} platform="youtube" />
      </Element>

      {!youtubeProfile.isDataFetched && (
        <div className={styles.profileOverlay}>
          <NoData title="Your profile is under review." description="" />
        </div>
      )}
    </section>
  );
};

export default Youtube;
