import React, { useMemo, useState } from 'react';
import styles from 'assets/scss/pages/dashboard.module.scss';
import { cardtransBg } from 'constants/images';
import TopCampaginsBarChart from 'components/brand-dashboard/TopCampaginsBarChart';
import { formatNumber } from 'utils';
import { useQuery } from '@tanstack/react-query';
import { getCampaignDashboard } from 'api/brand/campaign';
import CustomTooltip from 'components/custom/CustomTooltip';
import { NoData } from 'components/common/NoData';

const CampaignDashBoard = () => {
  const [filterData, setFilterData] = useState('views');

  const { data, isLoading } = useQuery({
    queryKey: ['brand-campaign-dashboard', filterData],
    queryFn: () =>
      getCampaignDashboard({
        dataFilter: 'views',
      }),
  });

  const dashboardCampaignData = useMemo(() => data?.dashboardResult, [data]);

  const menuHandler = (e) => setFilterData(e.target.value);

  return (
    <div className={styles.campaignBox}>
      <div className={styles.boxTitle}>
        <h2>Campaign</h2>
      </div>
      <div className={styles.boxContent}>
        <div className={styles.boxLiner}>
          <div className={styles.totalCount}>
            <div className={styles.cardtransBg}>
              <img src={cardtransBg} alt="" />
            </div>
            <h2 className={styles.countTitle}>Total</h2>
            <p className={styles.countNumber}>
              {formatNumber(dashboardCampaignData?.totalCampaignCount)}
            </p>
          </div>
          <div className={styles.totalCount}>
            <div className={styles.cardtransBg}>
              <img src={cardtransBg} alt="" />
            </div>
            <h2 className={styles.countTitle}>Completed</h2>
            <p className={styles.countNumber}>
              {formatNumber(dashboardCampaignData?.completedCampaignCount)}
            </p>
          </div>
          <div className={styles.totalCount}>
            <div className={styles.cardtransBg}>
              <img src={cardtransBg} alt="" />
            </div>
            <h2 className={styles.countTitle}>Active</h2>
            <p className={styles.countNumber}>
              {formatNumber(dashboardCampaignData?.activeCampaignCount)}
            </p>
          </div>
          <div className={styles.totalCount}>
            <div className={styles.cardtransBg}>
              <img src={cardtransBg} alt="" />
            </div>
            <h2 className={styles.countTitle}>Upcoming</h2>
            <p className={styles.countNumber}>
              {formatNumber(dashboardCampaignData?.upcomingCampaignCount)}
            </p>
          </div>
        </div>
        <div className={styles.performanceBenchMark}>
          <div className={styles.benchmarkSectionTitle}>
            <h3>TOP CAMPAIGNS IN LAST 7 DAYS</h3>
          </div>
          <div className={styles.benchmarkSectionView}>
            <select className={styles.selctMenu} onChange={menuHandler}>
              <option value="views">Views</option>
              <option value="likes">Likes</option>
              <option value="comments">Comments</option>
            </select>
          </div>
        </div>
        <div className="campaignGraph">
          <CustomTooltip>
            {dashboardCampaignData?.dashboardData?.length > 0 || isLoading ? (
              <TopCampaginsBarChart
                dashboardData={dashboardCampaignData?.dashboardData || []}
                filterData={filterData}
              />
            ) : (
              <NoData height={400} />
            )}
          </CustomTooltip>
        </div>
      </div>
    </div>
  );
};

export default CampaignDashBoard;
