import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, EffectFade, Autoplay } from 'swiper';
import { ReactComponent as MainBackground } from 'assets/images/main-bg.svg';
import styles from 'assets/scss/pages/landing-homepage.module.scss';
import {
  sehnazGillImg,
  bhuvanBamImg,
  hnMImg,
  homeBgMaskWebp,
  homeBgMaskPng,
  macdImg,
} from 'constants/images';
import routes from 'constants/routes';
import BrandIndianRankGraph from './svg/BrandIndianRankGraph';
import B2bIntrestInInfluencerMarketing from './svg/B2bIntrestInInfluencerMarketing';
import UsersOnInstagramRed from './svg/UsersOnInstagramRed';
import IndustryMarketValueGraph from './svg/IndustryMarketValueGraph';
import UsersOnInstagramPink from './svg/UsersOnInstagramPink';
import DirectConsumers from './svg/DirectConsumers';
import MarketersBelieveMainBrand from './svg/MarketersBelieveMainBrand';
import MonthlyActiveUsersMainInfluencer from './svg/MonthlyActiveUsersMainInfluencer';

const MainHeroSection = () => {
  const [_, setInit] = useState();
  const paginationRef = useRef(null);

  return (
    <div className={styles.bannerWrapper}>
      <Swiper
        modules={[Navigation, Pagination, A11y, EffectFade, Autoplay]}
        slidesPerView={1}
        pagination={{ clickable: true, el: paginationRef.current }}
        onInit={() => setInit(true)}
        effect="fade"
        fadeEffect={{
          crossFade: true,
        }}
        autoplay={{ delay: 6000, disableOnInteraction: true }}
        className="hero-slider"
      >
        <SwiperSlide>
          <BrandSection />
        </SwiperSlide>
        <SwiperSlide>
          <InfluencerSection />
        </SwiperSlide>
      </Swiper>
      <div>
        <div ref={paginationRef} className="swiper-pill-pagination" />
      </div>
      {/* ============== background =================== */}
      <MainBackground
        className={styles.backgroundSvg}
        width="100%"
        height="100%"
      />
      <picture className={styles.backgroundDotsImage}>
        <source srcSet={homeBgMaskWebp} type="image/webp" />
        <img
          src={homeBgMaskPng}
          alt=" "
          width="100%"
          height="100%"
          style={{ height: '100%' }}
        />
      </picture>
      {/* ============== background end =================== */}
    </div>
  );
};

const BrandSection = () => (
  <div className={styles.bannrInnrWrapper}>
    <div className={styles.bannerHeading}>
      <motion.h2
        className={styles.headingTxt}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, delay: 0.2 }}
      >
        Your search ends here!
      </motion.h2>
      <motion.p
        className={styles.paragraphTxt}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, delay: 0.4 }}
      >
        Discover the ultimate solution for executing winning social media
        strategies with our influencer marketing platform.
      </motion.p>
      <motion.div
        className={styles.bannerBtns}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, delay: 0.6 }}
      >
        <Link to={routes.BRAND} className={styles.startCampBtn}>
          Are you a Brand?
        </Link>
        <Link to={routes.INF} className={styles.bookCampBtn}>
          Are you an influencer?
        </Link>
      </motion.div>
    </div>
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: 0.8 }}
    >
      <div className={styles.multiImages}>
        <div className={styles.blockImg1}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <BrandIndianRankGraph />
            <h3 className={styles.titleTxt}>
              in terms of influencer advertising market
            </h3>
          </div>
        </div>
        <div className={styles.blockImg2}>
          <B2bIntrestInInfluencerMarketing />
          <h3 className={styles.titleTxt}>
            B2B marketers citing increased interest in influencer marketing
          </h3>
        </div>
        <div className={styles.blockImg3}>
          <div className={styles.img1}>
            <img src={sehnazGillImg} alt="" width="184" height="229" />
          </div>
          <div>
            <MarketersBelieveMainBrand />
            <div className={styles.messageContent}>
              <div className={styles.messageBodyCheckmark} />
              <p className={styles.videoTxt}>
                Video content is important for successful influencer marketing
                campaigns.
              </p>
            </div>
          </div>
          <div className={styles.img2}>
            <UsersOnInstagramRed />
            <h3 className={styles.title2Txt}>
              of users are 25-34 years old on Instagram
            </h3>
          </div>
          <div>
            <img src={bhuvanBamImg} alt="" width="184" height="229" />
          </div>
        </div>
      </div>
    </motion.div>
  </div>
);

const InfluencerSection = () => (
  <div className={styles.bannrInnrWrapper}>
    <div className={styles.bannerHeading}>
      <motion.h2
        className={styles.headingTxt}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Increase your Global Reach with collaborating with Top Brands on SMINCO
      </motion.h2>
      <motion.p
        className={styles.paragraphTxt}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        An integrated hub for influencers and brands, offering seamless
        multilingual management.
      </motion.p>
      <motion.div
        className={styles.bannerBtns}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        <Link to={routes.INF} className={styles.startCampBtn}>
          Are you an influencer?
        </Link>
        <Link to={routes.BRAND} className={styles.bookCampBtn}>
          Are you a Brand?
        </Link>
      </motion.div>
    </div>
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: 0.8 }}
    >
      <div className={styles.multiImages}>
        <div className={styles.blockImg1}>
          <IndustryMarketValueGraph />
          <h3 className={styles.titleTxt}>
            Value of influencer marketing industry in India
          </h3>
        </div>
        <div className={styles.blockImg2}>
          <UsersOnInstagramPink />
          <h3 className={styles.titleTxt}>
            Users are 25-34 years old on Instagram
          </h3>
        </div>
        <div className={styles.blockImg3}>
          <div className={styles.img1} style={{ top: '80px', left: '-210px' }}>
            <img src={hnMImg} alt="" width="184" height="229" />
          </div>
          <div>
            <MonthlyActiveUsersMainInfluencer />
            <div className={styles.messageContent}>
              <p className={styles.videoTxt}>Short Videos</p>
            </div>
          </div>
          <div className={styles.img2} style={{ top: '340px' }}>
            {/* <img
              className={clsx(styles.graph2Img, styles.activeBorder)}
              src={directConsumer2Img}
              alt=""
            /> */}
            <DirectConsumers />
            <h3 className={styles.title2Txt}>
              Direct consumers for Influencers
            </h3>
          </div>
          <div>
            <img src={macdImg} alt="" width="184" height="229" />
          </div>
        </div>
      </div>
    </motion.div>
  </div>
);

export default MainHeroSection;
