import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import { locationDownIcon } from 'constants/images';

const NavItem = ({ iconUrl, title, ...props }) => (
  <NavLink
    className={({ isActive }) =>
      clsx({
        [styles.summaryTabListItem]: true,
        [styles.activeTabItem]: isActive,
      })
    }
    {...props}
    title={title}
  >
    <span className={styles.subList}>
      <img className={styles.socialImg} src={iconUrl} alt="" />
      <span className={styles.activeTxt}>{title}</span>
    </span>
    <img className={styles.rightArrow} src={locationDownIcon} alt="" />
  </NavLink>
);

NavItem.propTypes = {
  iconUrl: PropTypes.string,
  title: PropTypes.string,
};

export default NavItem;
