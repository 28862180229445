import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import styles from 'assets/scss/pages/landing-homepage.module.scss';
import { featureBannrImg, featureBannrImgWebp } from 'constants/images';

const marqueeVariants = {
  animate: {
    x: [0, -3866],
    transition: {
      x: {
        repeat: Infinity,
        repeatType: 'loop',
        duration: 28,
        ease: 'linear',
      },
    },
  },
  notInView: {},
};

const FeaturedOnSection = () => {
  const containerRef = useRef();
  const inView = useInView(containerRef);

  return (
    <div ref={containerRef}>
      <div className={styles.scrollContainer}>
        <div className="featureHeader">
          <h2 className={styles.serviceTxt}>Our Happy Clients!</h2>
        </div>
        <motion.div
          className={styles.featureBanner}
          variants={marqueeVariants}
          animate={inView ? 'animate' : 'notInView'}
          // style={{ x: spring }}
        >
          <div className={styles.featuredImagesWrapper}>
            {[...Array(4).keys()].map((_, index) => (
              <picture key={index}>
                <source srcSet={featureBannrImgWebp} type="image/webp" />
                <img
                  src={featureBannrImg}
                  alt=" "
                  width="100%"
                  height="100%"
                  style={{ width: 'auto', maxWidth: 'none' }}
                  className={styles.featureBnnrImg}
                />
              </picture>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default FeaturedOnSection;
