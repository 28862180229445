import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { formatNumber } from 'utils';
import { profileIcon as fallbackImage } from 'constants/images';
import styles from './Avatar.module.scss';

const sizes = {
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
};

function Avatar({ size = 'medium', src, alt, children }) {
  return (
    <div className={clsx(styles.avatar, sizes[size])}>
      {src ? (
        <img src={src} alt={alt} className={styles.avatarImage} />
      ) : (
        <>
          {children !== null && children !== undefined ? (
            children
          ) : (
            <img
              src={fallbackImage}
              alt="user icon"
              className={styles.fallbackImage}
            />
          )}
        </>
      )}
    </div>
  );
}

Avatar.propTypes = {
  size: PropTypes.oneOf(Object.keys(sizes)),
  src: PropTypes.string,
  alt: PropTypes.string,
  children: PropTypes.node,
};

function AvatarGroup({
  max = 5,
  total,
  size = 'medium',
  children: childrenProp,
}) {
  let clampedMax = max < 2 ? 2 : max;

  const children = React.Children.toArray(childrenProp).filter((child) =>
    React.isValidElement(child)
  );

  const totalAvatars = total || children.length;

  if (totalAvatars === clampedMax) {
    clampedMax += 1;
  }

  clampedMax = Math.min(totalAvatars + 1, clampedMax);

  const maxAvatars = Math.min(children.length, clampedMax - 1);
  const extraAvatars = Math.max(
    totalAvatars - clampedMax,
    totalAvatars - maxAvatars,
    0
  );

  return (
    <div className={styles.avatarGroup}>
      {children.slice(0, maxAvatars).map((child) =>
        React.cloneElement(child, {
          size: child.props.size || size,
        })
      )}
      {extraAvatars ? (
        <Avatar size={size}>
          +{formatNumber(extraAvatars, { maximumFractionDigits: 0 })}
        </Avatar>
      ) : null}
    </div>
  );
}

AvatarGroup.propTypes = {
  max: PropTypes.number,
  total: PropTypes.number,
  size: PropTypes.oneOf(Object.keys(sizes)),
  children: PropTypes.node,
};

export { AvatarGroup, Avatar };
