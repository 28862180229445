import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Loader from 'components/common/Loader';
import styles from './Button.module.scss';

const varients = {
  contained: styles.contained,
  outlined: styles.outlined,
  text: styles.text,
};

const sizes = {
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
};

const Button = React.forwardRef(
  (
    {
      type,
      isLoading = false,
      children,
      disabled,
      variant = 'contained',
      size = 'medium',
      fullWidth = false,
      className,
      ...props
    },
    ref
  ) => (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      className={clsx({
        [varients[variant]]: true,
        [sizes[size]]: true,
        [styles.fullWidth]: fullWidth,
        [styles.loading]: isLoading,
        [className]: className,
      })}
      {...props}
      disabled={isLoading || disabled}
      ref={ref}
    >
      <span className={styles.buttonText}>{children}</span>
      {isLoading ? (
        <span className={styles.loader}>
          <Loader />
        </span>
      ) : null}
    </button>
  )
);

Button.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(varients)),
  size: PropTypes.oneOf(Object.keys(sizes)),
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
};

export default Button;
