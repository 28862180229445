import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './onboarding.module.scss';

const ONBOARDING_VIDEO_URL =
  'https://storage.googleapis.com/radiocity-uat/Radiocity%20Animation%2015%20Dec%202023.mp4';

function Onboarding() {
  return (
    <div className={styles.onboardingContainer}>
      <section className={styles.onboardingContainerLeft}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          autoPlay
          loop
          muted
          width="577"
          height="769"
          className={styles.video}
        >
          <source src={ONBOARDING_VIDEO_URL} type="video/mp4" />
        </video>
      </section>
      <section className={styles.onboardingContainerRight}>
        <Outlet />
      </section>
    </div>
  );
}

export default Onboarding;
