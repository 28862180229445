import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tab } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getInfluencerDetails } from 'api/brand/search';
import { Dialog } from 'components/common/dialog';
import Menu from 'components/common/Menu';
import { PostContentCard } from 'components/common/inf-profile';
import routes from 'constants/routes';
import { useDialog } from 'stores/dialog';
import { useCreateCampaign } from 'stores/create-campaign';
import { DIALOGS } from 'constants/config/dialog-names';
import { formatNumber } from 'utils';
import styles from 'assets/scss/pages/search.module.scss';
import {
  whiteAddIcon,
  coloredCrossIcon,
  logoImageNew,
  locationIcon,
  ytIcon,
  twitterSvg,
  instaSvg,
  facebookSvg,
  copyText,
  materialDropDown,
  solidGraph,
  indianRupeeSign,
  topPostIcon,
  recentPostIcon,
  brandPostIcon,
  blueTickIcon,
} from 'constants/images';
import { checkKeys } from 'utils/check-keys';
import { uinqueCategories } from 'pages/brand/dashboard/search/influencer-profile/formatData/sortData';

const InfProfileOverviewDialog = ({ open, close, influencerId, platform }) => {
  const setDialog = useDialog((state) => state.setDialog);
  const setInfsForCampaign = useCreateCampaign((state) => state.setFormData);

  const { data: influencer, isLoading } = useQuery({
    queryKey: ['brand-influencer-details', influencerId],
    queryFn: () => getInfluencerDetails({ influencerId }),
  });

  const platformData = useMemo(() => {
    switch (platform) {
      case 'youtube':
        return influencer?.youtubeProfile || {};
      case 'instagram':
        return influencer?.instagramProfile || {};
      case 'twitter':
        return influencer?.twitterProfile || {};
      case 'facebook':
        return influencer?.facebookProfile || {};
      default:
        return {};
    }
  }, [influencer, platform]);

  const onViewCost = () => {
    setDialog(DIALOGS.BRAND_INF_COST_OVERVIEW, { influencerId, platform });
  };

  const onAddToCustomList = () => {
    setDialog(DIALOGS.ADD_INF_TO_CUSTOM_LIST, {
      influencerIds: [influencerId],
    });
  };

  const onAddToNewCampaign = () => {
    const platforms = [];

    if (influencer.is_instagram) {
      platforms.push('instagram');
    }
    if (influencer.is_facebook) {
      platforms.push('facebook');
    }
    if (influencer.is_twitter) {
      platforms.push('twitter');
    }
    if (influencer.is_youtube) {
      platforms.push('youtube');
    }

    setInfsForCampaign({
      influencers: [
        {
          platforms,
          influencerId: influencer.id,
          influencerProfile: influencer.profile_image,
          influencerName: influencer.full_name,
          deliverables: [],
        },
      ],
    });
    setDialog(DIALOGS.CREATE_CAMPAIGN);
  };

  const onAddToExistingCampaign = () => {
    setDialog(DIALOGS.ADD_INF_TO_EXISTING_CAMPAIGN, {
      influencers: [influencer],
    });
  };

  const {
    full_name: name,
    influencer_categories: categories,
    city,
    is_registered: isRegistered,
    profile_image: profileImage,
  } = influencer || {};

  const categoriesInf = uinqueCategories(categories || []);

  const {
    followers,
    avgLikes,
    avgComments,
    avgViews,
    avgEngagementRate,
    topPosts,
    recentPosts,
    brandPosts,
    username,
    profileUrl,
    smincoScore,
  } = platformData;

  const instagramProfileUrl = influencer?.instagramProfile?.profileUrl;
  const facebookProfileUrl = influencer?.facebookProfile?.profileUrl;
  const twitterProfileUrl = influencer?.twitterProfile?.profileUrl;
  const youtubeProfileUrl = influencer?.youtubeProfile?.profileUrl;

  if (isLoading) {
    return null;
  }

  return (
    <Dialog open={open} size="1239px" fullWidth close={close}>
      <div className={styles.profileDrawer}>
        <div className={styles.profileAnalysis}>
          <div className={styles.profileHeader}>
            <div className={styles.p1Block}>
              <div className={styles.profileBlock1}>
                <div className={styles.profileOuterDiv}>
                  <img
                    className={styles.profilePicture}
                    src={profileImage}
                    alt=""
                  />
                </div>
                {/* <div className={styles.subBlock1}>
                  <p className={styles.scoreTxt}>Score</p>
                  <div className={styles.subInnrBlock}>
                    <img src={logoImageNew} alt="" width="12" height="15" />
                    <span className={styles.spanTxt}>
                      {formatNumber(smincoScore)}
                    </span>
                  </div>
                </div> */}
              </div>
              <div className="profileBlock2">
                <div className={styles.profileBrief}>
                  <h2 className={styles.influencerName}>
                    <span>{name} </span>
                    {isRegistered ? (
                      <img
                        src={blueTickIcon}
                        alt="verified"
                        style={{ display: 'inline-block' }}
                      />
                    ) : null}
                  </h2>
                  {profileUrl && username && (
                    <a
                      href={profileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.influencerHandle}
                    >
                      {username}
                    </a>
                  )}
                </div>
                {checkKeys(influencer, 'docusign_response') && city && (
                  <div className={styles.userLocation}>
                    <img src={locationIcon} alt="" />
                    <p className={styles.locTxt}>{city}</p>
                  </div>
                )}
                <div className={styles.userSocialPlaylist}>
                  {instagramProfileUrl && (
                    <a
                      href={instagramProfileUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img className={styles.socialImg} src={instaSvg} alt="" />
                    </a>
                  )}
                  {twitterProfileUrl && (
                    <a
                      href={twitterProfileUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className={styles.socialImg}
                        src={twitterSvg}
                        alt=""
                      />
                    </a>
                  )}
                  {facebookProfileUrl && (
                    <a
                      href={facebookProfileUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className={styles.socialImg}
                        src={facebookSvg}
                        alt=""
                      />
                    </a>
                  )}
                  {youtubeProfileUrl && (
                    <a
                      href={youtubeProfileUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img className={styles.socialImg} src={ytIcon} alt="" />
                    </a>
                  )}
                </div>
              </div>
            </div>
            <button
              type="button"
              className={styles.profileBlock3}
              onClick={onAddToCustomList}
            >
              <img src={copyText} alt="" />
            </button>
          </div>
          <div>
            <div className={styles.profileCategory}>
              {categoriesInf?.map((category, ind) => (
                <span className={styles.categoryTxt} key={ind}>
                  {category}
                </span>
              ))}
            </div>
            <div className={styles.profileActionButton}>
              {checkKeys(influencer, 'docusign_response') && (
                <Menu
                  renderButton={() => (
                    <Menu.Button
                      type="button"
                      className={styles.addCampaignButton}
                      disabled={!isRegistered}
                    >
                      <img src={whiteAddIcon} alt="" />
                      Add to Campaign <img src={materialDropDown} alt="" />
                    </Menu.Button>
                  )}
                  floatOptions={{
                    placement: 'bottom-start',
                    flip: true,
                    autoPlacement: false,
                    offset: 4,
                  }}
                >
                  <Menu.Item onClick={onAddToNewCampaign}>
                    Add To New Campaign
                  </Menu.Item>
                  <Menu.Item onClick={onAddToExistingCampaign}>
                    Add To Existing Campaign
                  </Menu.Item>
                </Menu>
              )}
              <div className={styles.actionsLinks}>
                <Link
                  className={styles.analyseProfileButton}
                  to={`${routes.BRAND_SEARCH_INF}/${influencerId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={solidGraph} alt="" />
                  Analyse Profile
                </Link>
                {/* {checkKeys(influencer, 'docusign_response') && (
                  <button
                    type="button"
                    className={styles.viewCostButton}
                    onClick={onViewCost}
                    disabled={!isRegistered}
                  >
                    <img src={indianRupeeSign} alt="" />
                    View Cost
                  </button>
                )} */}
              </div>
            </div>
          </div>
          <div className={styles.platformMetrics}>
            <ul className={styles.metricsList}>
              <li>
                <h2 className={styles.metricsHeding}>
                  {formatNumber(followers)}
                </h2>
                <p className={styles.metricLabel}>Followers</p>
              </li>
              <li>
                <h2 className={styles.metricsHeding}>
                  {formatNumber(avgLikes)}
                </h2>
                <p className={styles.metricLabel}>Avg Likes</p>
              </li>
              <li>
                <h2 className={styles.metricsHeding}>
                  {formatNumber(avgComments)}
                </h2>
                <p className={styles.metricLabel}>Avg. Comments</p>
              </li>
              {platform === 'youtube' && (
                <li>
                  <h2 className={styles.metricsHeding}>
                    {formatNumber(avgViews)}
                  </h2>
                  <p className={styles.metricLabel}>Avg Views</p>
                </li>
              )}
              <li>
                <h2
                  className={styles.metricsHeding}
                  style={{ color: '#01B000' }}
                >
                  {formatNumber(avgEngagementRate)}%
                </h2>
                <p className={styles.metricLabel}>Engagement Rate</p>
              </li>
            </ul>
          </div>
        </div>
        <Tab.Group as="div" className={styles.postAnalysis}>
          <div className={styles.tabsWrapper}>
            <Tab.List as="ul" className={styles.tab}>
              <Tab
                as="li"
                className={({ selected }) =>
                  clsx({ [styles.tabInnr]: true, [styles.activeTab]: selected })
                }
              >
                <img src={topPostIcon} alt="" />
                <h3 className={styles.postTxt}>Top Posts</h3>
              </Tab>
              <Tab
                as="li"
                className={({ selected }) =>
                  clsx({ [styles.tabInnr]: true, [styles.activeTab]: selected })
                }
              >
                <img src={recentPostIcon} alt="" />
                <h3 className={styles.postTxt}>Recent Posts</h3>
              </Tab>
              <Tab
                as="li"
                className={({ selected }) =>
                  clsx({ [styles.tabInnr]: true, [styles.activeTab]: selected })
                }
              >
                <img src={brandPostIcon} alt="" />
                <h3 className={styles.postTxt}>Brand Posts</h3>
              </Tab>
            </Tab.List>
            <div className={styles.cross}>
              <button type="button" onClick={close}>
                <img
                  style={{ cursor: 'pointer' }}
                  src={coloredCrossIcon}
                  alt=""
                />
              </button>
            </div>
          </div>
          <Tab.Panels as="div">
            <Tab.Panel as="div" className={styles.postWrapper}>
              {topPosts?.map((post) => (
                <PostContentCard key={post?.postId} {...post} />
              ))}
            </Tab.Panel>
            <Tab.Panel as="div" className={styles.postWrapper}>
              {recentPosts?.map((post) => (
                <PostContentCard key={post?.postId} {...post} />
              ))}
            </Tab.Panel>
            <Tab.Panel as="div" className={styles.postWrapper}>
              {brandPosts?.map((post) => (
                <PostContentCard key={post?.postId} {...post} />
              ))}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Dialog>
  );
};

InfProfileOverviewDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  influencerId: PropTypes.string,
  platform: PropTypes.oneOf(['instagram', 'youtube', 'twitter', 'facebook']),
};

export default InfProfileOverviewDialog;
