const COUNTRY_CODES = [
  {
    code: '+91',
    isoCode: 'IN',
    name: 'india',
    flagCode: '&#x1F1EE;&#x1F1F3;',
  },
  // {
  //   code: '+65',
  //   isoCode: 'SG',
  //   name: 'singapore',
  //   flagCode: '&#x1F1F8;&#x1F1EC;',
  // },
];
Object.freeze(COUNTRY_CODES);
export { COUNTRY_CODES };
