import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import useDebounce from 'hooks/useDebounce';
import { Dialog } from 'components/common/dialog';
import { searchInfluencers } from 'api/brand/search';
import { searchImg } from 'constants/images';
import styles from 'assets/scss/pages/search.module.scss';
import styles2 from 'assets/scss/pages/popup.module.scss';
import { Avatar } from 'components/common/Avatar';

const AddInfluencerDialog = ({ onCancel, onAdd, render, platforms = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedInf, setSelectedInf] = useState([]);
  const [prevSelected, setPrevSelected] = useState([]);
  const [search, setSearch] = useState('');

  const isSearching = !!search;

  const debouncedSearchQuery = useDebounce(search);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      'brand-influencer-search',
      1,
      10,
      debouncedSearchQuery,
      platforms,
    ],
    queryFn: () =>
      searchInfluencers({
        pageNo: 1,
        pageSize: 10,
        search: debouncedSearchQuery || undefined,
        is_youtube: platforms.includes('youtube') || undefined,
        is_instagram: platforms.includes('instagram') || undefined,
        is_facebook: platforms.includes('facebook') || undefined,
        is_twitter: platforms.includes('twitter') || undefined,
      }),
    enabled: isOpen,
  });

  const onClose = () => {
    setIsOpen(false);
    setSelectedInf([]);
    setSearch('');
  };
  const onOpen = () => setIsOpen(true);

  const onAddBtnClick = () => {
    onAdd(selectedInf);
    onClose();
  };

  const onCancelBtnClick = () => {
    onClose();
    if (onCancel) onCancel();
  };

  const onSearchChange = (e) => {
    setPrevSelected(selectedInf);
    setSearch(e.target.value);
  };

  const onInfSelect = (influencer, isSelected) => {
    if (isSelected) {
      setSelectedInf((prev) => prev.filter((inf) => inf.id !== influencer.id));
    } else {
      setSelectedInf((prev) => [...prev, influencer]);
    }
  };

  const selectedInfIds = useMemo(
    () => selectedInf.map((inf) => inf.id),
    [selectedInf]
  );

  const prevSelectedIds = useMemo(
    () => prevSelected.map((inf) => inf.id),
    [prevSelected]
  );

  const filteredInfList = useMemo(
    () =>
      isSearching
        ? data?.data?.influencerList?.rows
        : data?.data?.influencerList?.rows?.filter(
            (inf) => !prevSelectedIds.includes(inf.id)
          ),
    [isSearching, data, prevSelectedIds]
  );

  return (
    <>
      {render({ open: onOpen })}
      <Dialog
        open={isOpen}
        close={onCancelBtnClick}
        fullWidth
        size="526px"
        title="Add Influencers"
      >
        <div>
          <div className={styles.searchField} style={{ margin: '0 25px' }}>
            <img
              className={clsx(styles.sIcon, styles.campignSearchIcon)}
              src={searchImg}
              alt=""
            />
            <input
              className={clsx(styles.searchTxt, styles.campaignSearchField)}
              placeholder="Type to search list below"
              type="text"
              value={search}
              onChange={onSearchChange}
            />
          </div>
          <div className={styles.infCheckBox}>
            {!isSearching &&
              !isLoading &&
              !isFetching &&
              prevSelected.map((influencer) => (
                <InfluencerButton
                  key={influencer.id}
                  isSelected={selectedInfIds?.includes(influencer.id)}
                  influencer={influencer}
                  onSelect={onInfSelect}
                />
              ))}
            {filteredInfList?.map((influencer) => (
              <InfluencerButton
                key={influencer.id}
                isSelected={selectedInfIds?.includes(influencer.id)}
                influencer={influencer}
                onSelect={onInfSelect}
              />
            ))}
          </div>
          <div className={styles.selectInfFooter}>
            <div className={styles.crBtn}>
              <button
                type="button"
                className={styles.prevBtn}
                onClick={onCancelBtnClick}
              >
                Cancel
              </button>
              <button
                type="button"
                style={{ width: '166px' }}
                className={styles.updateBtn}
                onClick={onAddBtnClick}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

AddInfluencerDialog.propTypes = {
  onCancel: PropTypes.func,
  onAdd: PropTypes.func,
  render: PropTypes.func,
  platforms: PropTypes.array,
};

const InfluencerButton = ({ isSelected, influencer, onSelect }) => (
  <button
    type="button"
    className={styles.innrBoxCnt}
    onClick={() => onSelect(influencer, isSelected)}
  >
    <span className={styles2.checkBox} data-checked={isSelected} />
    <span className={styles.subCnt}>
      <Avatar src={influencer?.profile_image} size="large" />
      <span className={styles.cntntTitle}>{influencer?.full_name}</span>
    </span>
  </button>
);

InfluencerButton.propTypes = {
  isSelected: PropTypes.bool,
  influencer: PropTypes.object,
  onSelect: PropTypes.func,
};

export default AddInfluencerDialog;
