import React from 'react';
import PropTypes from 'prop-types';
import { Link as ScrollLink } from 'react-scroll';
import styles from 'assets/scss/pages/influencer-profile.module.scss';

const ProfileDetailsNavbar = ({ config = [] }) => (
  <div className={styles.navListWrapper}>
    <nav className={styles.navList}>
      {config.map(({ icon, title, to, ...rest }) => (
        <ScrollLink
          to={to}
          className={styles.navListItem}
          activeClass={styles.activeNavHeading}
          spy
          smooth
          spyThrottle={200}
          offset={-50}
          isDynamic
          key={title}
          {...rest}
        >
          {icon && <img src={icon} alt="" />}
          <span>{title}</span>
        </ScrollLink>
      ))}
    </nav>
  </div>
);

ProfileDetailsNavbar.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
      to: PropTypes.string,
    })
  ).isRequired,
};

export default ProfileDetailsNavbar;
