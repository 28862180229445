import { baseQuery } from './baseQuery';

const apiVersion = process.env.REACT_APP_API_VERSION;

export const subscribeEmail = (body) =>
  baseQuery({
    url: `/auth/${apiVersion}/influencer/homepage-subscribe`,
    method: 'POST',
    body,
  });
