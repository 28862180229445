import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from 'components/common/Footer';
import Header from 'components/common/Header';
import styles from './default.module.scss';

function DefaultLayout() {
  return (
    <div>
      <Header />
      <main className={styles.main}>
        <Outlet />
      </main>
      <Footer showEmailSubscription={false} />
    </div>
  );
}

export default DefaultLayout;
