import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Menu as HeadlessMenu } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import styles from './Menu.module.scss';

const Menu = ({
  renderButton,
  options = [],
  children,
  floatOptions,
  ...restProps
}) => (
  <HeadlessMenu as="div" {...restProps}>
    <Float autoPlacement {...floatOptions}>
      {renderButton()}
      <HeadlessMenu.Items as="section" className={styles.menuItems}>
        {children ||
          options.map(({ label, disabled, ...rest }, index) => (
            <MenuItem disabled={disabled} key={index} {...rest}>
              {label}
            </MenuItem>
          ))}
      </HeadlessMenu.Items>
    </Float>
  </HeadlessMenu>
);

Menu.propTypes = {
  renderButton: PropTypes.func,
  options: PropTypes.array,
  children: PropTypes.node,
  floatOptions: PropTypes.object,
};

const MenuButton = React.forwardRef(({ children, ...rest }, ref) => (
  <HeadlessMenu.Button className={styles.menuBtn} {...rest} ref={ref}>
    {children}
  </HeadlessMenu.Button>
));

MenuButton.propTypes = {
  children: PropTypes.node,
};

const MenuItem = ({ disabled, children, ...rest }) => (
  <HeadlessMenu.Item
    className={({ active, disabled }) =>
      clsx({
        [styles.menuItem]: true,
        [styles.active]: active,
        [styles.disabled]: disabled,
      })
    }
    as="button"
    disabled={disabled}
    {...rest}
  >
    {children}
  </HeadlessMenu.Item>
);

MenuItem.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

Menu.Button = MenuButton;
Menu.Item = MenuItem;
export default Menu;
