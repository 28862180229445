import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { getTestimonials } from 'api/common/informativeContent';
import { Avatar } from 'components/common/Avatar';
import styles from 'assets/scss/pages/about.module.scss';
import {
  dotBg,
  dotImage,
  facebookSvg,
  instaSvg,
  ourMisionImg,
  // ourStoryImg,
  ourTeamImg,
  ourValuesImg,
  twitterSvg,
} from 'constants/images';
import clsx from 'clsx';
import {
  InfluencerTestimonialCard,
  influencerTestimonials,
} from 'components/home/InfluencerTestimonials';

const About = () => {
  const [_, setInit] = useState();
  const testimonialsPaginationRef = useRef(null);
  const ourTeamPaginationRef = useRef(null);

  // const { data: testimonials } = useQuery({
  //   queryKey: ['testimonials', 1, 20],
  //   queryFn: () => getTestimonials({ pageNo: 1, pageSize: 20 }),
  //   select: (res) => res?.data?.rows,
  // });

  return (
    <section>
      <div className={styles.headingContainer}>
        <h2 className={styles.headingTxt}>About Us</h2>
      </div>
      <div className={styles.container}>
        <div className={styles.aboutContainer}>
          <div className={styles.ourStoryWrapper}>
            <div className={styles.ourStory}>
              <div>
                <h1 className={styles.storyTitle}>
                  Welcome to SMINCO – Your Window To The Social Media World!
                </h1>
                <p className={styles.storyTxt}>
                  SMINCO is not just a platform it's a game-changer in the
                  influencer marketing realm. Disrupting the traditional agency
                  model, we empower brands with data-driven influencer
                  marketing, making success accessible to all. Plan, track, and
                  triumph with SMINCO – Where Data Meets Influence!
                </p>
              </div>
            </div>
            <div className={styles.ourStory}>
              <div>
                <h2 className={styles.storyTitle}>Who We Are</h2>
                <p className={styles.storyTxt}>
                  At SMINCO, we are more than just a team, we are a collective
                  of passionate and seasoned marketing professionals committed
                  to redefining the way brands connect with their audience. We
                  firmly believe that influencer marketing is not just a trend
                  but a powerful means to achieve multifaceted marketing goals.
                </p>
              </div>
              {/* <div>
                <img src={ourStoryImg} alt="" />
              </div> */}
            </div>
          </div>
          <div className={styles.backgroundImg}>
            <img className="dotImg" src={dotBg} alt="" />
          </div>

          {/* =============== our mission ================ */}
          <div className={styles.visionSection}>
            <h2 className={styles.visionSectionTitle}>
              Our mission is to assist brands in:
            </h2>
            <div className={styles.visionContainer}>
              <div className={styles.visionWrapper}>
                <img src={ourMisionImg} alt="" />
                <div>
                  <h3 className={styles.visionTitle}>
                    Increasing Brand Awareness
                  </h3>
                  <p className={styles.visionText}>
                    We leverage the influence of authentic creators to amplify
                    your brand's visibility.
                  </p>
                </div>
              </div>
              <div className={styles.visionWrapper}>
                <img src={ourMisionImg} alt="" />
                <div>
                  <h3 className={styles.visionTitle}>Generating Leads</h3>
                  <p className={styles.visionText}>
                    Through strategic influencer partnerships, we drive
                    engagement and lead acquisition.
                  </p>
                </div>
              </div>
              <div className={styles.visionWrapper}>
                <img src={ourMisionImg} alt="" />
                <div>
                  <h3 className={styles.visionTitle}>Driving Sales</h3>
                  <p className={styles.visionText}>
                    Our proven track record demonstrates our ability to turn
                    influence into tangible results for your bottom line.
                  </p>
                </div>
              </div>
              <div className={styles.visionWrapper}>
                <img src={ourMisionImg} alt="" />
                <div>
                  <h3 className={styles.visionTitle}>Building Brand Loyalty</h3>
                  <p className={styles.visionText}>
                    We understand the importance of long-term relationships,
                    cultivating loyalty that extends beyond individual
                    campaigns.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* =============== our values ================ */}
          <div className={clsx(styles.visionSection, styles.bgPink)}>
            <h2 className={styles.visionSectionTitle}>Our Values:</h2>
            <div className={styles.visionContainer}>
              <div className={styles.visionWrapper}>
                <img src={ourValuesImg} alt="" />
                <div>
                  <h3 className={styles.visionTitle}>Authenticity</h3>
                  <p className={styles.visionText}>
                    We curate partnerships with influencers who embody a genuine
                    passion for the brands they promote, ensuring an authentic
                    connection with their audience.
                  </p>
                </div>
              </div>
              <div className={styles.visionWrapper}>
                <img src={ourValuesImg} alt="" />
                <div>
                  <h3 className={styles.visionTitle}>Transparency</h3>
                  <p className={styles.visionText}>
                    Openness is at our core. We provide clear insights into our
                    processes, fees, and campaign performance through regular,
                    comprehensive reports.
                  </p>
                </div>
              </div>
              <div className={styles.visionWrapper}>
                <img src={ourValuesImg} alt="" />
                <div>
                  <h3 className={styles.visionTitle}>
                    Data-Driven Decision Making
                  </h3>
                  <p className={styles.visionText}>
                    In a world inundated with information, we cut through the
                    noise. Our strategies are grounded in data, informing every
                    decision from influencer selection to content creation.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* =============== our services ================ */}
          <div className={styles.visionSection}>
            <h2 className={styles.visionSectionTitle}>Our Services:</h2>
            <div className={styles.visionContainer}>
              <div className={styles.visionWrapper}>
                <img src={ourValuesImg} alt="" />
                <div>
                  <h3 className={styles.visionTitle}>
                    Influencer Discovery and Selection
                  </h3>
                  <p className={styles.visionText}>
                    Our proprietary platform ensures the perfect match between
                    your brand and influencers.
                  </p>
                </div>
              </div>
              <div className={styles.visionWrapper}>
                <img src={ourValuesImg} alt="" />
                <div>
                  <h3 className={styles.visionTitle}>Campaign Strategy</h3>
                  <p className={styles.visionText}>
                    We craft comprehensive strategies tailored to your marketing
                    goals, ensuring a seamless alignment of objectives.
                  </p>
                </div>
              </div>
              <div className={styles.visionWrapper}>
                <img src={ourValuesImg} alt="" />
                <div>
                  <h3 className={styles.visionTitle}>Content Creation</h3>
                  <p className={styles.visionText}>
                    Collaborating with influencers, we produce compelling,
                    shareable content that resonates with your target audience.
                  </p>
                </div>
              </div>
              <div className={styles.visionWrapper}>
                <img src={ourValuesImg} alt="" />
                <div>
                  <h3 className={styles.visionTitle}>Campaign Management</h3>
                  <p className={styles.visionText}>
                    From inception to completion, we handle all facets of your
                    influencer marketing campaign.
                  </p>
                </div>
              </div>
              <div className={styles.visionWrapper}>
                <img src={ourValuesImg} alt="" />
                <div>
                  <h3 className={styles.visionTitle}>Campaign Analytics</h3>
                  <p className={styles.visionText}>
                    Through an array of metrics, we meticulously track and
                    measure campaign performance to provide actionable insights.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.backgroundImg2}>
            <img className="dotImg" src={dotImage} alt="" />
          </div>

          <div className={styles.testimonial}>
            <div className={styles.testimonialHeader}>
              <h2 className={styles.storyTitle}>Influencer Testimonials</h2>
              <p className={styles.storyTxt} style={{ maxWidth: '100%' }}>
                Join the fastest growing community on SMINCO, where thousands of
                influencers and brands have already found success. Don't miss
                out – become a part of the next big thing in influencer
                marketing!
              </p>
            </div>
            <div className={styles.testimonialBox}>
              <Swiper
                modules={[Navigation, Pagination, A11y, Autoplay]}
                pagination={{
                  clickable: true,
                  el: testimonialsPaginationRef.current,
                }}
                onInit={() => setInit((prev) => !prev)}
                className="about-page-slider"
                breakpoints={{
                  1024: {
                    spaceBetween: 30,
                  },
                }}
                slidesPerView={1}
                autoplay={{ delay: 3000, disableOnInteraction: true }}
                centeredSlides
              >
                {influencerTestimonials?.map(({ id, ...rest }) => (
                  <SwiperSlide key={id}>
                    <InfluencerTestimonialCard {...rest} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div>
              <div
                className="about-page-pill-navigation"
                ref={testimonialsPaginationRef}
              />
            </div>
          </div>

          {/* ================= client testimonials =================== */}
          {/* <div className={styles.testimonial}>
            <div className={styles.testimonialHeader}>
              <h2 className={styles.storyTitle}>Client Testimonials</h2>
              <p className={styles.storyTxt} style={{ maxWidth: '100%' }}>
                We are proud of the work that we have done for our clients, and
                we believe that their success stories speak for themselves. Here
                are just a few examples of what our clients have to say about
                working with us:
              </p>
            </div>
            <div className={styles.testimonialBox}>
              <Swiper
                modules={[Navigation, Pagination, A11y, Autoplay]}
                slidesPerView={1}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 1.5,
                    spaceBetween: 20,
                  },
                  1280: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                }}
                pagination={{
                  clickable: true,
                  el: testimonialsPaginationRef.current,
                }}
                onInit={() => setInit((prev) => !prev)}
                className="about-page-slider"
                autoplay={{ delay: 1500, disableOnInteraction: true }}
              >
                {testimonials?.map((testimonial) => (
                  <SwiperSlide key={testimonial.id}>
                    <TestimonialCard testimonial={testimonial} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div>
              <div
                className="about-page-pill-navigation"
                ref={testimonialsPaginationRef}
              />
            </div>
          </div> */}

          {/* ================= our team =================== */}

          {/* <div className={styles.testimonial}>
            <div className={styles.testimonialHeader}>
              <h2 className={styles.storyTitle}>Our Team</h2>
              <p className={styles.storyTxt} style={{ maxWidth: '100%' }}>
                Our team is what sets us apart from other influencer marketing
                agencies. We are a team of seasoned professionals with years of
                experience in influencer marketing, social media, content
                creation, and digital marketing. We are dedicated to staying on
                top of the latest trends and best practices in the industry, and
                we work tirelessly to ensure that our clients get the best
                possible results from their influencer campaigns.
              </p>
            </div>
            <div className={styles.testimonialBox}>
              <Swiper
                modules={[Navigation, Pagination, A11y, Autoplay]}
                slidesPerView={1}
                breakpoints={{
                  640: {
                    slidesPerView: 1.5,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1280: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                pagination={{
                  clickable: true,
                  el: ourTeamPaginationRef.current,
                }}
                onInit={() => setInit(true)}
                className="about-page-slider"
                autoplay={{ delay: 1500, disableOnInteraction: true }}
              >
                <SwiperSlide>
                  <TeamMemberCard />
                </SwiperSlide>
                <SwiperSlide>
                  <TeamMemberCard />
                </SwiperSlide>
                <SwiperSlide>
                  <TeamMemberCard />
                </SwiperSlide>
                <SwiperSlide>
                  <TeamMemberCard />
                </SwiperSlide>
                <SwiperSlide>
                  <TeamMemberCard />
                </SwiperSlide>
                <SwiperSlide>
                  <TeamMemberCard />
                </SwiperSlide>
                <SwiperSlide>
                  <TeamMemberCard />
                </SwiperSlide>
              </Swiper>
            </div>
            <div>
              <div
                className="about-page-pill-navigation"
                ref={ourTeamPaginationRef}
              />
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

const TestimonialCard = ({ testimonial }) => {
  const {
    description,
    image_url: profileImage,
    designation,
    user,
  } = testimonial;
  return (
    <div className={styles.testWrapp}>
      <div className="testBox1">
        <p className={styles.testimonialTxt}>"{description}"</p>
      </div>
      <div className={styles.testBox2}>
        <Avatar src={profileImage} size="large" />
        <div className={styles.innrBox}>
          <h4 className={styles.innrSubHeading}>
            {user?.first_name} {user?.first_name}
          </h4>
          <p className={styles.innrSubTxt}>{designation}</p>
        </div>
      </div>
    </div>
  );
};

TestimonialCard.propTypes = {
  testimonial: PropTypes.object.isRequired,
};

const TeamMemberCard = () => (
  <div className={styles.ourTeam}>
    <div className={styles.sectionHeader}>
      <img src={ourTeamImg} alt="" />
    </div>
    <div className={styles.sectionMiddle}>
      <h3 className={styles.usernameTxt}>John Smith </h3>
      <p className={styles.userdesignationTxt}>Chief Executive Officer</p>
    </div>
    <div className={styles.sectionBottom}>
      <img src={instaSvg} alt="" />
      <img src={twitterSvg} alt="" />
      <img src={facebookSvg} alt="" />
    </div>
  </div>
);

export default About;
