import React from 'react';
import PropTypes from 'prop-types';
import Loader from './Loader';
import styles from './Loader.module.scss';

const LoaderWithWrapper = ({ show = false, height = 300 }) => (
  <div className={styles.loaderWrapper} style={{ height }}>
    {show && <Loader />}
  </div>
);

LoaderWithWrapper.propTypes = {
  height: PropTypes.number,
  show: PropTypes.bool,
};

export default LoaderWithWrapper;
