import React, { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { linkYoutube } from 'api/influencer/social';
import routes from 'constants/routes';
import { toast } from 'react-toastify';
import err from 'constants/config/error-messagess';

const YoutubeRedirect = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: (data) => {
      if (data.error) {
        throw data;
      }
      return linkYoutube({
        code: data.code,
        redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
      });
    },
    onSuccess: () => {
      navigate(routes.INF_SIGNUP_ADD_SOCIAL, { replace: true });
      toast.success('Youtube connected successfully');
    },
    onError: (res) => {
      if (res.error) {
        toast.error(err.UNABLE_TO_PROCESS);
      } else {
        toast.error(
          res.response?.data?.message || res?.data?.message || res?.message
        );
      }
      navigate(routes.INF_SIGNUP_ADD_SOCIAL, { replace: true });
    },
  });

  useEffect(() => {
    mutate(Object.fromEntries(searchParams));
  }, [searchParams, mutate]);

  return <div>Loading...</div>;
};

export default YoutubeRedirect;
