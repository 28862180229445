import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Footer from 'components/onboarding/Footer';
import Button from 'components/common/Button';
import CountryCodeSelect from 'components/common/CountryCodeSelect';

import { logoImageBlackText, eyeImage, eyeCloseImage } from 'constants/images';
import { INFLUENCER_SIGNUP_PROFILE_SCHEMA } from 'constants/schema';
import { signupProfileSetup } from 'api/influencer/auth';
import styles from 'assets/scss/pages/onboarding.module.scss';
import routes from 'constants/routes';
import { useInfluencerAuth } from 'stores/auth/influencer';
import MultiSelect from 'components/common/form/MultiSelect';
import { LANGUAGES } from 'constants/config';
import { format } from 'date-fns';

const defaultValues = {
  managerEmail: '',
  managerMobile: '',
  managerCountryCode: '+91',
  language: [],
  password: '',
  confirmPassword: '',
  consent: false,
  dob: null,
};

function SignUpProfileSetup() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { token, setToken, setUserData } = useInfluencerAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(INFLUENCER_SIGNUP_PROFILE_SCHEMA),
    defaultValues,
  });

  const profileMutation = useMutation({
    mutationFn: ({
      managerEmail,
      managerMobile,
      managerCountryCode,
      language,
      password,
      confirmPassword,
      consent,
      dob,
    }) => {
      const payload = {
        manager_email: managerEmail,
        manager_mobile: managerMobile,
        country_code_manager: managerCountryCode,
        language,
        password,
        confirm_password: confirmPassword,
        terms_condition: consent,
        token,
        dob: new Date(dob).toISOString(),
      };
      return signupProfileSetup(payload);
    },
    onSuccess: (data) => {
      setToken(data?.data?.token);
      setUserData(data?.data?.influencer);
      navigate(routes.INF_SIGNUP_ADD_ADDRESS);
    },
  });

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  useEffect(() => {
    if (locationState && !locationState.hasManager) {
      setValue('managerMobile', locationState.mobile);
      setValue('managerEmail', locationState.email);
    }
  }, [locationState, setValue]);

  return (
    <div className={styles.signupInnerContent}>
      <div className={clsx(styles.signupItems, styles.profile)}>
        <div>
          <div>
            <Link to={routes.HOME}>
              <img src={logoImageBlackText} alt="" width={140} />
            </Link>
          </div>
          <h1>Profile Completion</h1>
          <p>Please provide your details to create a unique profile</p>
          <form
            className={styles.signupFormItems}
            onSubmit={handleSubmit(profileMutation.mutate)}
          >
            <div>
              <input
                type="email"
                placeholder="Manager Email Id"
                autoComplete="email"
                maxLength="70"
                {...register('managerEmail')}
              />
              {errors?.managerEmail ? (
                <p className={styles.error}>{errors.managerEmail?.message}</p>
              ) : null}
            </div>

            <div>
              <div className={styles.mobileWrapper}>
                <div className={styles.countryCodeSelect}>
                  <Controller
                    render={({ field }) => (
                      <CountryCodeSelect
                        onChange={field.onChange}
                        value={field.value}
                      />
                    )}
                    control={control}
                    name="countryCode"
                  />
                </div>
                <input
                  type="tel"
                  placeholder="Enter Manager Mobile Number"
                  autoComplete="mobile"
                  maxLength="10"
                  {...register('managerMobile')}
                />
              </div>
              {errors?.managerMobile ? (
                <p className={styles.error}>{errors.managerMobile?.message}</p>
              ) : null}
            </div>
            <div>
              <input
                type="date"
                placeholder="Date of Birth"
                autoComplete="bday"
                max={format(new Date(), 'yyyy-MM-dd')}
                {...register('dob')}
              />
              {errors?.dob ? (
                <p className={styles.error}>{errors.dob?.message}</p>
              ) : (
                <p style={{ color: '#7c087f' }} className={styles.error}>
                  Date of birth
                </p>
              )}
            </div>
            <div>
              <Controller
                render={({ field }) => (
                  <MultiSelect
                    isOnboarding
                    placeholder="Language"
                    onChange={field.onChange}
                    value={field.value}
                    options={LANGUAGES}
                  />
                )}
                control={control}
                name="language"
              />
              {errors?.language ? (
                <p className={styles.error}>{errors.language?.message}</p>
              ) : null}
            </div>
            <div>
              <div className={styles.passwordWrapper}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter Password"
                  autoComplete="new-password"
                  {...register('password')}
                />
                <button
                  className={styles.eyeImage}
                  type="button"
                  onClick={toggleShowPassword}
                  title={showPassword ? 'Hide password' : 'Show password'}
                >
                  <img src={showPassword ? eyeCloseImage : eyeImage} alt="" />
                </button>
                {errors?.password ? (
                  <p className={styles.error}>{errors.password?.message}</p>
                ) : (
                  <p style={{ color: '#7c087f' }} className={styles.error}>
                    Use at least 6 characters and 1 number or symbol.
                  </p>
                )}
              </div>
            </div>
            <div>
              <div className={styles.passwordWrapper}>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="Confirm Password"
                  autoComplete="new-password"
                  {...register('confirmPassword')}
                />
                <button
                  className={styles.eyeImage}
                  type="button"
                  onClick={toggleShowConfirmPassword}
                  title={
                    showConfirmPassword
                      ? 'Hide confirm password'
                      : 'Show confirm password'
                  }
                >
                  <img
                    src={showConfirmPassword ? eyeCloseImage : eyeImage}
                    alt=""
                  />
                </button>
                {errors?.confirmPassword ? (
                  <p className={styles.error}>
                    {errors.confirmPassword?.message}
                  </p>
                ) : null}
              </div>
            </div>
            <div>
              <div className={styles.checkbox}>
                <input
                  type="checkbox"
                  id="terms-checkbox"
                  {...register('consent')}
                />
                <label htmlFor="terms-checkbox">
                  I agree to the{' '}
                  <Link to={routes.INF_TERMS_AND_CONDTIONS}>Terms of Use</Link>{' '}
                  and <Link to={routes.PRIVACY_POLICY}>Privacy Policy</Link>
                </label>
              </div>
              {errors?.consent ? (
                <p className={styles.error}>{errors.consent?.message}</p>
              ) : null}
            </div>
            <Button
              type="submit"
              size="large"
              isLoading={profileMutation.isLoading}
              fullWidth
            >
              Next
            </Button>
          </form>
        </div>
      </div>
      <Footer type="influencer" />
    </div>
  );
}

export default SignUpProfileSetup;
