import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, Scrollbar } from 'swiper';
import instagramIcon from 'assets/images/instagram-icon.svg';
import facebookIcon from 'assets/images/facebook-icon.svg';
import youtubeIcon from 'assets/images/youtube-icon.svg';
import twitterIcon from 'assets/images/twitter-icon.svg';
import styles from 'assets/scss/pages/landing-homepage.module.scss';

const TopCategoriesSlider = ({ influencers = [], selectedCategory }) => {
  const [_, setInit] = useState();

  const scrollbarRef = useRef(null);

  return (
    <div>
      <Swiper
        modules={[Navigation, Pagination, Autoplay, Scrollbar]}
        slidesPerView="auto"
        onInit={() => setInit(true)}
        className="top-categories-slider"
        autoplay={{ delay: 1500, disableOnInteraction: true }}
        scrollbar={{
          el: scrollbarRef.current,
          dragSize: 27,
          draggable: true,
        }}
      >
        {influencers.map(
          ({
            id,
            profile_image: profileImage,
            full_name: name,
            is_facebook: isFacebook,
            is_instagram: isInstagram,
            is_twitter: isTwitter,
            is_youtube: isYoutube,
          }) => (
            <SwiperSlide key={id}>
              <div className="slider-element">
                <div className={styles.influencerCard}>
                  <img src={profileImage} alt="" className={styles.cardImage} />
                  <div className={styles.cardContent}>
                    <h2>{name}</h2>
                    <p>{selectedCategory}</p>
                    <div className={styles.socialIcons}>
                      {isFacebook && (
                        <img src={facebookIcon} height="44" width="44" />
                      )}
                      {isInstagram && (
                        <img src={instagramIcon} height="44" width="44" />
                      )}
                      {isYoutube && (
                        <img src={youtubeIcon} height="44" width="44" />
                      )}
                      {isTwitter && (
                        <img src={twitterIcon} height="44" width="44" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          )
        )}
      </Swiper>

      <div className="scrollbar-wrapper">
        <div className="custom-scrollbar" ref={scrollbarRef} />
      </div>
    </div>
  );
};

TopCategoriesSlider.propTypes = {
  influencers: PropTypes.array,
  selectedCategory: PropTypes.string,
};

export default TopCategoriesSlider;
