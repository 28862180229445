import React from 'react';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const TopAudience = ({ title, value, desc }) => (
  <>
    <div className={styles.overviewGrid}>
      <div className={styles.sectionHeader}>
        <h3 className={styles.followerLabel}>{title}</h3>
      </div>
      <div className={clsx(styles.sectionBottom, styles.stateBottom)}>
        <p className={styles.ratioTxt}>{value}</p>
        {desc && <span className={styles.commntTxt}>{desc}</span>}
      </div>
    </div>
  </>
);

TopAudience.propTypes = {
  value: PropTypes.node,
  desc: PropTypes.node,
  title: PropTypes.string,
};

export default TopAudience;
