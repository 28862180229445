import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from 'assets/scss/pages/search.module.scss';
import { pinkCrossIcon, cutIcon } from 'constants/images';

const FilterChip = ({ isActive, icon, onRemove, label, value }) => (
  <div
    className={clsx(styles.categryDropdown, isActive && styles.activeDropdown)}
  >
    <div className={styles.dropdownItem}>
      {icon ? (
        <img
          src={icon}
          alt=""
          height="24"
          width="24"
          className={styles.itemImage}
        />
      ) : null}
      <span className={clsx(styles.itemTxt, isActive && styles.activeTxt)}>
        {label}
      </span>
    </div>
    {onRemove ? (
      <button type="button" onClick={() => onRemove(value)}>
        <img src={isActive ? cutIcon : pinkCrossIcon} alt="" />
      </button>
    ) : null}
  </div>
);

FilterChip.propTypes = {
  isActive: PropTypes.bool,
  icon: PropTypes.string,
  onRemove: PropTypes.func,
  label: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default FilterChip;
