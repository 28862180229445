import { useLayoutEffect, useRef } from 'react';

const useSyncronizedAnimations = () => {
  const ref = useRef();

  // This will sync loaders if there is multiple loaders in the screen
  useLayoutEffect(() => {
    const animations = document
      .getAnimations()
      // "rotator" is animation name coming from Loader.module.css
      .filter((animation) => animation.animationName === 'rotator');

    const currentAnimation = animations.find(
      (animation) => animation.effect.target === ref.current
    );

    if (currentAnimation !== animations[0]) {
      currentAnimation.currentTime = animations[0].currentTime;
    }
  }, []);

  return ref;
};

export default useSyncronizedAnimations;
