import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import FaqSection from 'components/home/FaqSection';
import BrandIndianRankGraphOutline from 'components/home/svg/BrandIndianRankGraphOutline';
import { ReactComponent as MainBackground } from 'assets/images/brand-bg.svg';
import styles from 'assets/scss/pages/brand-homepage.module.scss';
import {
  sehnazGillImg,
  bhuvanBamImg,
  elevtionBrndsImg,
  basicPlanImg,
  contentImg,
  languageImg,
  dotPattrnImg,
  img205,
  dashboardAnalyticsImg,
  elevatingBg,
  homeBgMaskWebp,
  homeBgMaskPng,
} from 'constants/images';
import routes from 'constants/routes';
import B2bIntrestInInfluencerMarketing from 'components/home/svg/B2bIntrestInInfluencerMarketing';
import UsersOnInstagramRed from 'components/home/svg/UsersOnInstagramRed';
import MarketersBelieveBrand from 'components/home/svg/MarketersBelieveBrand';

const BrandHome = () => (
  <div>
    <Header varient="brand" />
    <div className={styles.bannerWrapper}>
      <div className={styles.bannrInnrWrapper}>
        <div className={styles.bannerHeading}>
          <motion.h2
            className={styles.headingTxt}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.2 }}
          >
            Your search ends here!
          </motion.h2>
          <motion.p
            className={styles.paragraphTxt}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.4 }}
          >
            Discover a unique marketplace, SMINCO, your go-to platform with an
            Influencer Database featuring Multi-lingual Capabilities, tailored
            to elevate brand reach.
          </motion.p>
          <motion.div
            className={styles.bannerBtns}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.6 }}
          >
            {/* <Link to={routes.BRAND_SIGNUP} className={styles.startCampBtn}>
              Start Campaign Now
            </Link>
            <Link
              className={styles.bookCampBtn}
              to={routes.CONTACT_US}
              state={{ type: 'brand' }}
            >
              Connect With Us
            </Link> */}
            <Link
              className={styles.startCampBtn}
              to={routes.CONTACT_US}
              state={{ type: 'brand' }}
            >
              Connect With Us
            </Link>
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0.8 }}
        >
          <div className={styles.multiImages}>
            <div className={styles.blockImg1}>
              <BrandIndianRankGraphOutline />
              <h3 className={styles.titleTxt}>
                in terms of influencer advertising market
              </h3>
            </div>
            <div className={styles.blockImg2}>
              <B2bIntrestInInfluencerMarketing outlined />
              <h3 className={styles.titleTxt}>
                B2B marketers citing increased interest in influencer marketing
              </h3>
            </div>
            <div className={styles.blockImg3}>
              <div className={styles.img1}>
                <img src={sehnazGillImg} alt="" width={184} />
              </div>
              <div>
                <MarketersBelieveBrand />

                <div className={styles.messageContent}>
                  <div className={styles.messageBodyCheckmark} />

                  <p className={styles.videoTxt}>
                    Video content is important for successful influencer
                    marketing campaigns.
                  </p>
                </div>
              </div>
              <div className={styles.img2}>
                <UsersOnInstagramRed outlined />
                <h3 className={styles.title2Txt}>
                  of users are 25-34 years old on Instagram
                </h3>
              </div>
              <div>
                <img src={bhuvanBamImg} alt="" width={184} />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      {/* ============== background =================== */}
      <MainBackground
        className={styles.backgroundSvg}
        width="100%"
        height="100%"
      />
      <picture className={styles.backgroundDotsImage}>
        <source srcSet={homeBgMaskWebp} type="image/webp" />
        <img
          src={homeBgMaskPng}
          alt=" "
          width="100%"
          height="100%"
          style={{ height: '100%' }}
        />
      </picture>
      {/* ============== background end =================== */}
    </div>
    {/* second================== */}
    <div className={styles.elevationBrands}>
      <h1 className={styles.elevationTxt}>
        Enhancing brands through engaging content and immersive experiences
      </h1>
      <div className={styles.brandsInnrSec}>
        <div>
          <img src={elevtionBrndsImg} alt="" />
          <img className={styles.elevatingBg} src={elevatingBg} alt="" />
        </div>
        <img className={styles.dotPattrnImg} src={dotPattrnImg} alt="" />
        <div className={styles.collabInnrContainer}>
          <ul className={styles.listItem}>
            <li className={styles.listTxt}>
              Connecting brands with creators through unique and credible
              content, offering immersive experiences.
            </li>
            <li className={styles.listTxt}>
              We are the experts in content-based, all-inclusive advertising
              solutions, from counselling to development and post-sale support.
            </li>
          </ul>
          <div className={styles.prfileBtnContainer}>
            {/* <Link to={routes.BRAND_SIGNUP} className={styles.createProfileBtn}>
              Join Now
            </Link> */}
            <Link
              className={styles.createProfileBtn}
              to={routes.CONTACT_US}
              state={{ type: 'brand' }}
            >
              Join Now
            </Link>
          </div>
        </div>
      </div>
    </div>
    {/* third======================== */}
    {/* <div className={styles.elevationBrands}>
      <h1 className={styles.elevationTxt}>The Right Plans For You</h1>
      <p className={styles.exTxt}>Excited to start your brand campaign?</p>
      <div className={styles.subscribeContainer}>
        <div className={styles.subscribeBtns}>
          <button type="button" className={styles.activeBtn}>
            Monthly
          </button>
          <button type="button">Quaterly</button>
          <button type="button">Yearly</button>
        </div>
        <div className={styles.subscribePlans}>
          <div className={styles.subInnrPlanWrapper}>
            <div className={styles.subscriptionContainer}>
              <div className={styles.innerSubscriptionColumn}>
                <img src={basicPlanImg} alt="" />
                <h2>Basic</h2>
                <div className={styles.pricingExpiry}>
                  <h3>
                    ₹1499<span>/mo</span>
                  </h3>
                </div>
                <div className={styles.trialTime}>
                  <p>1 month free</p>
                </div>
                <div className={styles.priceList}>
                  <ul className={styles.pHeight}>
                    <li>Unlimited content access</li>
                    <li>Optimized hashtags</li>
                    <li>Own platform to perform</li>
                    <li>Chat support</li>
                    <li>Unlimited sharing of content</li>
                  </ul>
                </div>
              </div>
              <div className={styles.buyNowBtn}>
                <button
                  type="button"
                  className={styles.editBooking}
                  data-loading-btn="false"
                >
                  Go with Basic
                </button>
              </div>
            </div>
            <div
              className={clsx(
                styles.subscriptionContainer,
                styles.subscribeContainerActive
              )}
            >
              <div className={styles.innerSubscriptionColumn}>
                <img src={basicPlanImg} alt="" />
                <h2>Standard</h2>
                <div className={styles.pricingExpiry}>
                  <h3>
                    ₹2499<span>/mo</span>
                  </h3>
                </div>
                <div className={styles.trialTime}>
                  <p>2 month free</p>
                </div>
                <div className={styles.priceList}>
                  <ul className={styles.pHeight}>
                    <li>Unlimited content access</li>
                    <li>Optimized hashtags</li>
                    <li>Own platform to perform</li>
                    <li>Chat support</li>
                    <li>Unlimited sharing of content</li>
                  </ul>
                </div>
              </div>
              <div className={styles.buyNowBtn}>
                <button
                  type="button"
                  className={clsx(styles.editBooking, styles.hoverBooking)}
                  data-loading-btn="false"
                >
                  Go with Standard
                </button>
              </div>
            </div>
            <div className={styles.subscriptionContainer}>
              <div className={styles.innerSubscriptionColumn}>
                <img src={basicPlanImg} alt="" />
                <h2>Premium</h2>
                <div className={styles.pricingExpiry}>
                  <h3>
                    ₹3499<span>/mo</span>
                  </h3>
                </div>
                <div className={styles.trialTime}>
                  <p>15 days trail</p>
                </div>
                <div className={styles.priceList}>
                  <ul className={styles.pHeight}>
                    <li>Unlimited content access</li>
                    <li>Optimized hashtags</li>
                    <li>Own platform to perform</li>
                    <li>Chat support</li>
                    <li>Unlimited sharing of content</li>
                  </ul>
                </div>
              </div>
              <div className={styles.buyNowBtn}>
                <button
                  type="button"
                  className={styles.editBooking}
                  data-loading-btn="false"
                >
                  Go with Premium
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    {/* fourth================================= */}
    <div className={styles.curatingContent}>
      {/* <img className={styles.contntImg} src={curateImg} alt="" /> */}
      <div className={styles.contentInnr}>
        <div className={styles.contntSubBox}>
          <h2 className={styles.curateTxt}>
            Create, Curate, Engage: Unlocking the Power of Relevant Content
          </h2>
          <p className={styles.curateDetailTxt}>
            At SMINCO, we specialize in crafting content that goes beyond mere
            sharing. Our tailored content strategy is designed to resonate with
            your audience, creating a connection that encourages them to keep
            coming back for more! <br />
            <br /> Using data and insights, we consistently discover, organize,
            add context to, and distribute top-notch resources to assist you in
            your growth.
          </p>
          <div className={styles.prfileBtnContainer}>
            <Link
              className={styles.createProfileBtn}
              to={routes.CONTACT_US}
              state={{ type: 'brand' }}
            >
              Connect With Us
            </Link>
          </div>
        </div>
        <div className={styles.curteImgContainer}>
          <img className={styles.curtePicture} src={contentImg} alt="" />
        </div>
      </div>
    </div>
    {/* fifth ======================================= */}
    <div className={styles.langugeSec}>
      <div>
        <img src={languageImg} alt="" />
      </div>
      <div className={styles.contntSubBox}>
        <h2 className={styles.curateTxt}>
          Breaking Language Barriers, Unlocking Glocal Opportunities
        </h2>
        <img className={styles.dotPattrnImg} src={dotPattrnImg} alt="" />
        <p className={styles.curateDetailTxt}>
          Localization is vital for business expansion into new markets.
          <br />
          <br />
          The distinctive feature of our SMINCO platform lies in its ability to
          generate advertising in multiple languages, effectively conveying a
          brand's message across diverse linguistic landscapes for maximum
          impact and reach.
        </p>
        <div className={styles.prfileBtnContainer}>
          {/* <Link to={routes.BRAND_SIGNUP} className={styles.createProfileBtn}>
            Join Now
          </Link> */}
          <Link
            className={styles.createProfileBtn}
            to={routes.CONTACT_US}
            state={{ type: 'brand' }}
          >
            Join Now
          </Link>
        </div>
      </div>
    </div>
    {/* sixth=============================================== */}
    <div className={styles.influencerContainer}>
      {/* <div>
          <img className={styles.contntImg} src={managnmntImg} alt="" />
        </div> */}
      <div className={styles.influncerInnrContnt}>
        <div className={styles.contnt1}>
          <h2 className={styles.curateTxt} style={{ color: '#fff' }}>
            Our Influencer Management Platform
          </h2>
          <p className={styles.curateDetailTxt} style={{ color: '#fff' }}>
            Our platform offers a comprehensive solution for connecting with
            influencers, creating campaigns, and executing them seamlessly.
            <br />
            <br /> Gain control and insights into your social media influencers'
            engagement, statistics, content, and campaign outcomes through our
            monitoring tools.
          </p>
          <div className={styles.prfileBtnContainer}>
            {/* <Link className={styles.bookUsBtn} to={routes.BRAND_SIGNUP}>
              Get Started
            </Link> */}
            <Link
              className={styles.bookUsBtn}
              to={routes.CONTACT_US}
              state={{ type: 'brand' }}
            >
              Get Started
            </Link>
          </div>
        </div>
        <div className={styles.contnt2}>
          <img className={styles.platformImg} src={img205} alt="" />
        </div>
      </div>
    </div>

    {/* seventh=================================================== */}
    <div className={styles.langugeSec}>
      <div>
        <img src={dashboardAnalyticsImg} alt="" />
      </div>
      <div className={styles.contntSubBox}>
        <h2 className={styles.curateTxt}>Dashboard & Analytics</h2>

        <p className={styles.curateDetailTxt}>
          Our platform features a user-friendly analytics dashboard,
          highlighting key performance indicators (KPIs) for easy monitoring.
          <br />
          <br />
          By tracking multiple indicators, we enable marketers to assess the
          effectiveness of influencer campaigns efficiently.
        </p>
        <div className={styles.prfileBtnContainer}>
          {/* <Link to={routes.BRAND_SIGNUP} className={styles.createProfileBtn}>
            Create Profile
          </Link> */}
          <Link
            className={styles.createProfileBtn}
            to={routes.CONTACT_US}
            state={{ type: 'brand' }}
          >
            Create Profile
          </Link>
        </div>
      </div>
    </div>
    {/* eigth=============================================================== */}
    <FaqSection varient="brand" />
    <Footer varient="brand" />
  </div>
);

export default BrandHome;
