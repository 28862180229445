import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getProfile,
  getRateCard,
  updateRateCard,
} from 'api/influencer/profile';
import Button from 'components/common/Button';
import ProfileUnderReviewDialog from 'components/onboarding/ProfileUnderReviewDialog';
import Footer from 'components/onboarding/Footer';
import { useInfluencerAuth } from 'stores/auth/influencer';
import routes from 'constants/routes';
import { queryClient } from 'App';
import { logoImageBlackText } from 'constants/images';
import { INF_RATE_CARD_SCHEMA } from 'constants/schema';
import styles from 'assets/scss/pages/onboarding.module.scss';

const defaultValues = {
  youtubeVideo: '',
  youtubeShort: '',
  instImage: '',
  instStory: '',
  instReel: '',
  fbPost: '',
  twitterTweets: '',
};

const SignupAddRateCard = () => {
  const [showInReviwDialog, setShowInReviwDialog] = useState(false);
  const resetAuth = useInfluencerAuth((state) => state.reset);
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ['influencer-rate-card'],
    queryFn: () => getRateCard(),
  });

  const { data: influencer } = useQuery({
    queryKey: ['influencer-profile'],
    queryFn: () => getProfile(),
  });

  const methods = useForm({
    resolver: yupResolver(INF_RATE_CARD_SCHEMA),
    defaultValues,
  });

  const { handleSubmit, reset, register } = methods;

  useEffect(() => {
    if (data) {
      reset({
        youtubeVideo: data?.video_youtube || '0',
        youtubeShort: data?.shorts || '0',
        instImage: data?.image || '0',
        instStory: data?.stories || '0',
        instReel: data?.reel || '0',
        fbPost: data?.posts || '0',
        twitterTweets: data?.tweets || '0',
      });
    }
  }, [data, reset]);

  const editRateCardMutation = useMutation({
    mutationFn: (data) => {
      const payload = {
        reel: data.instReel,
        stories: data.instStory,
        image: data.instImage,
        video_instagram: data.instVideo,
        video_youtube: data.youtubeVideo,
        shorts: data.youtubeShort,
        posts: data.fbPost,
        tweets: data.twitterTweets,
      };
      return updateRateCard(payload);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['influencer-rate-card'] });
      reset();
      if (influencer?.profile_status === 'verified') {
        navigate(routes.INF_DASHBOARD);
      } else {
        setShowInReviwDialog(true);
      }
    },
  });

  const onInReviewDialogClose = () => {
    setShowInReviwDialog(false);
    resetAuth();
    queryClient.clear();
    navigate(routes.INF_LOGIN);
  };

  const {
    is_facebook: isFacebookVerified,
    is_instagram: isInstaVerified,
    is_twitter: isTwitterVerified,
    is_youtube: isYoutubeVerified,
  } = influencer || {};

  return (
    <>
      <div className={styles.signupInnerContent}>
        <div className={styles.signupItems}>
          <div>
            <div>
              <Link to={routes.HOME}>
                <img src={logoImageBlackText} alt="" width={140} />
              </Link>
            </div>
            <div className={styles.linkSocialInfo}>
              <h1>Add Rate Card</h1>
            </div>

            {/* <p>Let us know how much do you charge.</p> */}
            <form
              onSubmit={handleSubmit(editRateCardMutation.mutate)}
              id="add-rate-card-form"
            >
              <table className={styles.costContainer}>
                <tbody>
                  {isInstaVerified && (
                    <>
                      <tr>
                        <td className={styles.socialList}>
                          Instagram Swipe-up Story
                        </td>
                        <td>
                          <div className={styles.rateInputWrapper}>
                            <input
                              type="number"
                              className={styles.rateInput}
                              {...register('instStory')}
                              min="1"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.socialList}>
                          Instagram Image Post
                        </td>
                        <td>
                          <div className={styles.rateInputWrapper}>
                            <input
                              type="number"
                              className={styles.rateInput}
                              {...register('instImage')}
                              min="1"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.socialList}>Instagram Reels</td>
                        <td>
                          <div className={styles.rateInputWrapper}>
                            <input
                              type="number"
                              className={styles.rateInput}
                              {...register('instReel')}
                              min="1"
                            />
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                  {isFacebookVerified && (
                    <tr>
                      <td className={styles.socialList}>Facebook Post</td>
                      <td>
                        <div className={styles.rateInputWrapper}>
                          <input
                            type="number"
                            className={styles.rateInput}
                            {...register('fbPost')}
                            min="1"
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                  {isTwitterVerified && (
                    <tr>
                      <td className={styles.socialList}>Text Tweet</td>
                      <td>
                        <div className={styles.rateInputWrapper}>
                          <input
                            type="number"
                            className={styles.rateInput}
                            {...register('twitterTweets')}
                            min="1"
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                  {isYoutubeVerified && (
                    <>
                      <tr>
                        <td className={styles.socialList}>YouTube Video</td>
                        <td>
                          <div className={styles.rateInputWrapper}>
                            <input
                              type="number"
                              className={styles.rateInput}
                              {...register('youtubeVideo')}
                              min="1"
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className={styles.socialList}>YouTube Shorts</td>
                        <td>
                          <div className={styles.rateInputWrapper}>
                            <input
                              type="number"
                              className={styles.rateInput}
                              {...register('youtubeShort')}
                              min="1"
                            />
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </form>
            <div className={styles.signupFormItems}>
              <Button
                size="large"
                fullWidth
                isLoading={editRateCardMutation.isLoading}
                type="submit"
                form="add-rate-card-form"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
        <Footer type="influencer" />
      </div>
      <ProfileUnderReviewDialog
        isOpen={showInReviwDialog}
        onClose={onInReviewDialogClose}
      />
    </>
  );
};

export default SignupAddRateCard;
