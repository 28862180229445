import React, { useState } from 'react';
import { Dialog } from 'components/common/dialog';
import Button from 'components/common/Button';
import styles from 'components/common/dialog/dialog.module.scss';

const RequestRevisionDialog = ({ onAgree, render }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState('');

  const onClose = () => {
    setIsOpen(false);
    setReason('');
  };
  const onOpen = () => setIsOpen(true);

  const onAgreeBtnClick = (e) => {
    e.preventDefault();
    if (onAgree) onAgree(reason);
    onClose();
  };

  const onDisagreeBtnClick = () => {
    onClose();
  };
  return (
    <>
      {render ? render({ open: onOpen }) : null}
      <Dialog
        title="Request revision"
        showCloseBtn
        open={isOpen}
        close={onClose}
        size="526px"
        fullWidth
      >
        <form onSubmit={onAgreeBtnClick}>
          <div className={styles.alertDialogContent}>
            <div>
              <textarea
                placeholder="Revision request reason"
                name="reason"
                style={{
                  width: '100%',
                  borderBottom: '2px solid #ff00a2',
                  outline: 'none',
                }}
                rows={4}
                required
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.dialogActions}>
            <Button
              variant="text"
              onClick={onDisagreeBtnClick}
              style={{ color: '#757575' }}
            >
              Cancel
            </Button>
            <Button type="submit" style={{ minWidth: 130 }}>
              Submit
            </Button>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default RequestRevisionDialog;
