import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useParams, Link, NavLink, Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSpinDelay } from 'spin-delay';
import { Avatar, AvatarGroup } from 'components/common/Avatar';
import { LoaderWithWrapper } from 'components/common/Loader';
import styles from 'assets/scss/pages/campaign.module.scss';
import { transparentBg, locationDownIcon } from 'constants/images';
import routes from 'constants/routes';
import { PLATFORM_ICONS } from 'constants/config/platformIcons';
import { formatNumber } from 'utils';
import { getBrandDetails } from 'api/brand/brandAnalysis';

const NavItem = ({ children, ...props }) => (
  <NavLink
    className={({ isActive }) =>
      clsx({
        [styles.influencerCateBtn]: true,
        [styles.whiteActiveBtn]: isActive,
      })
    }
    {...props}
  >
    {children}
  </NavLink>
);

NavItem.propTypes = {
  children: PropTypes.node,
};

const BrandDetails = () => {
  const { id: brandId, platform } = useParams();

  const lowerCasePlatform = platform.toLowerCase();

  const { data, isLoading } = useQuery({
    queryKey: ['brand-analysis-details', brandId, lowerCasePlatform],
    queryFn: () => getBrandDetails(brandId, { media_type: lowerCasePlatform }),
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  if (isLoading || delayedIsLoading) {
    return <LoaderWithWrapper show={delayedIsLoading} />;
  }

  return (
    <>
      <div className={styles.dashboardTop}>
        <img className={styles.transBg} src={transparentBg} alt="" />
        <div className={styles.campaignContainer}>
          <div className={styles.campignBreadcrumbs}>
            <Link to={routes.BRAND_ANALYSIS} className={styles.navTxt}>
              Brand Analysis
            </Link>
            <img className={styles.rightArrow} src={locationDownIcon} alt="" />
            <span className={styles.navSecndTxt}>{data?.brand_name}</span>
          </div>
          <div className={styles.campaignHeading}>
            <div className={styles.filterHeader}>
              <h2 className={styles.campignTxt}>{data?.brand_name}</h2>
            </div>
          </div>
          <div className="campaignDiv">
            <ul className={styles.campaignList}>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Handle Name</h2>
                <p className={styles.detailTxt}>
                  {data?.handle_name ? data?.handle_name : '-'}
                </p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Platform</h2>
                {data?.media_type ? (
                  <AvatarGroup>
                    <Avatar
                      src={PLATFORM_ICONS[data?.media_type?.toLowerCase()]}
                      alt=""
                      size="small"
                    />
                  </AvatarGroup>
                ) : (
                  '-'
                )}
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Total Followers</h2>
                <p className={styles.detailInnrTxt}>
                  {formatNumber(data?.followers_count)}
                </p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Posts</h2>
                <p className={styles.detailInnrTxt}>
                  {formatNumber(data?.total_post)}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.categoryButtons}>
          <NavItem to="" end>
            Posts
          </NavItem>

          <NavItem to="influencers">Influencer List</NavItem>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default BrandDetails;
