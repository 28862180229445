import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'framer-motion';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Dialog } from 'components/common/dialog';
import { LoaderWithWrapper } from 'components/common/Loader';
import styles from 'assets/scss/pages/search.module.scss';
import { PostContentCard } from 'components/common/inf-profile';
import { getBrandPosts } from 'api/influencer/profile';

const PAGE_SIZE = 12;

const InfBrandPostsDialog = ({ open, close, brandName, platform }) => {
  const loadMoreRef = useRef(null);
  const isLoadMoreInView = useInView(loadMoreRef);

  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['influencer-brand-posts', brandName, platform],

      queryFn: ({ pageParam = 1 }) =>
        getBrandPosts({
          brandName,
          pageNo: pageParam,
          pageSize: PAGE_SIZE,
          media_type: platform,
        }),
      enabled: Boolean(brandName),

      getNextPageParam: (lastpage, allPages) => {
        const totalPages = Math.ceil(lastpage?.count / PAGE_SIZE);

        return allPages.length < totalPages ? allPages.length + 1 : undefined;
      },
    });

  useEffect(() => {
    if (isLoadMoreInView && hasNextPage && !isLoading) {
      fetchNextPage();
    }
  }, [isLoadMoreInView, fetchNextPage, hasNextPage, isLoading]);

  return (
    <Dialog
      title="Brand Posts"
      open={open}
      close={close}
      size="940px"
      fullWidth
    >
      <div className={styles.postsContainer}>
        <div className={styles.postsWrapper}>
          {data?.pages?.map((page) =>
            page?.rows?.map((post) => (
              <div className={styles.centerPost} key={post?.postId}>
                <PostContentCard {...post} />
              </div>
            ))
          )}
        </div>
        <div className={styles.postsWrapper}>
          {isFetchingNextPage || isLoading ? (
            <LoaderWithWrapper
              height={100}
              show={isFetchingNextPage || isLoading}
            />
          ) : null}
        </div>
        <div className={styles.postsWrapper}>
          <p ref={loadMoreRef} style={{ visibility: 'hidden' }}>
            Load More
          </p>
        </div>
      </div>
    </Dialog>
  );
};

InfBrandPostsDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  influencerId: PropTypes.string,
  platform: PropTypes.string,
  brandName: PropTypes.string,
};

export default InfBrandPostsDialog;
