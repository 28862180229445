import * as yup from 'yup';
import err from 'constants/config/error-messagess';
import {
  AGENCY_NAME_REGX,
  ALPHABETS_REGX,
  INDIAN_MOBILE_NO_REGEX,
  PASSWORD_REGX,
  // GST_NO_REGX,
  // PAN_CARD_NO_REGX,
  // TIN_NO_REGX,
  PINCODE_REGX,
  CARD_CVV_REGX,
  CARD_EXPIRY_REGX,
  CARD_NUM_REGX,
  PAN_CARD_NO_REGX,
  GST_NO_REGX,
} from 'constants/config/regex';
import { PAYMENT_TYPES } from 'constants/config/payment';

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'or', function (schemas, msg) {
  return this.test({
    name: 'or',
    message: msg || 'Invalid input',
    test: (value) => {
      if (Array.isArray(schemas) && schemas.length > 1) {
        const resee = schemas.map((schema) => schema.isValidSync(value));
        return resee.some((res) => res);
      }
      throw new TypeError('Schemas is not correct array schema');
    },
    exclusive: false,
  });
});

const emailValidation = yup.string().email(err.INVALID_EMAIL).required(err.EMAIL_REQ);

const passwordValidation = yup.string().required(err.PASSWORD_REQ).matches(PASSWORD_REGX, err.INVALID_PASSWORD);

const confirmPassValidation = yup
  .string()
  .oneOf([yup.ref('password'), null], err.PASS_MATCH)
  .required(err.CONFIRM_PASS_REQ);

const mobileValidation = yup.string().required(err.MOBILE_REQ).matches(INDIAN_MOBILE_NO_REGEX, err.INVALID_MOBILE);

const managerMobileValidation = yup
  .string()
  .required(err.MANAGER_MOBILE_REQ)
  .matches(INDIAN_MOBILE_NO_REGEX, err.INVALID_MANAGER_MOBILE);

const managerEmailValidation = yup.string().email(err.INVALID_MANAGER_EMAIL).required(err.MANAGER_EMAIL_REQ);

const usernameValidation = yup
  .string()
  .or([yup.string().email(), yup.string().matches(INDIAN_MOBILE_NO_REGEX)], err.INVALID_EMAIL_OR_MOBILE)
  .required(err.INVALID_EMAIL_OR_MOBILE);

export const EMAIL_SCHEMA = yup.object({
  email: emailValidation,
});

export const OTP_SCHEMA = yup.object({
  otp: yup.string().min(6, err.INVALID_OTP).required(err.INVALID_OTP),
});

export const USERNAME_SCHEMA = yup.object({
  username: usernameValidation,
});

export const NEW_PASSWORD_FORM_SCHEMA = yup.object({
  password: passwordValidation,
  confirmPassword: confirmPassValidation,
});

export const CHANGE_PASSWORD_FORM_SCHEMA = yup.object({
  oldPassword: yup.string().required(err.PASSWORD_REQ),
  password: passwordValidation,
  confirmPassword: confirmPassValidation,
});

export const ADDRESS_FORM_SCHEMA = yup.object({
  addressLine1: yup.string().required(err.ADDRESS_LINE_REQ),
  addressLine2: yup.string(),
  landmark: yup.string().required(err.LANDMARK_REQ),
  pincode: yup.string().required(err.PINCODE_REQ).matches(PINCODE_REGX, err.INVALID_PINCODE),
  state: yup.string().required(err.STATE_REQ).matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  city: yup.string().required(err.CITY_REQ).matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  addressType: yup.string().required(err.ADDRESS_TYPE_REQ).matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
});

export const GET_IN_TOUCH_SCHEMA = yup.object({
  email: emailValidation,
  mobile: mobileValidation,
});

export const BRAND_SIGNUP_PROFILE_SCHEMA = yup.object({
  agencyName: yup
    .string()
    .matches(AGENCY_NAME_REGX, err.INVALID_AGENCY_NAME)
    .matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  firstName: yup.string().required(err.FIRST_NAME_REQ).matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  lastName: yup.string().required(err.LAST_NAME_REQ).matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  email: emailValidation,
  countryCode: yup.string(),
  mobile: mobileValidation,
  // address: yup.object({
  //   houseNo: yup.string().required(err.HOUSE_NO_REQ),
  //   city: yup.string().required(err.CITY_REQ).matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  //   state: yup.string().required(err.STATE_REQ).matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  //   pincode: yup.string().matches(PINCODE_REGX, err.INVALID_PINCODE),
  // }),
  gstCert: yup
    .mixed()
    .test('fileType', err.ONLY_PDF, (value) => (value?.[0]?.type ? value?.[0]?.type === 'application/pdf' : true)),
  consent: yup.bool().oneOf([true], err.CONSENT_REQ),
});

export const BRAND_SIGNUP_PROFILE_2_SCHEMA = yup.object({
  contactName: yup.string().required(err.CONTACT_PERSON_NAME_REQ).matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  contactMobile: yup.string().required(err.CONTACT_PERSON_MOBILE_REQ),
  roContactName: yup.string().required(err.RO_CONTACT_PERSON_NAME_REQ).matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  roContactMobile: yup.string().required(err.RO_CONTACT_PERSON_MOBILE_REQ),
  teamSegment: yup.string().required(err.TEAM_SEGMENT_REQ),
  rcAccountManager: yup.string().required(err.RC_ACC_MANAGER_REQ).matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  gstCert: yup
    .mixed()
    .test('required', err.GST_REQ, (value) => value?.[0])
    .test('fileType', err.ONLY_PDF, (value) => value?.[0]?.type === 'application/pdf'),
  consent: yup.bool().oneOf([true], err.CONSENT_REQ),
});

export const BRAND_PROFILE_SCHEMA = yup.object({
  // agencyName: yup.string().matches(AGENCY_NAME_REGX, err.INVALID_AGENCY_NAME),
  email: emailValidation,
  mobile: mobileValidation,
  firstName: yup.string().required(err.FIRST_NAME_REQ).matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  lastName: yup.string().required(err.LAST_NAME_REQ).matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  // ro_contact_person: yup.string().optional().matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  ro_contact_person: yup.lazy((value) =>
    value ? yup.string().matches(ALPHABETS_REGX, err.ONLY_ALPHABETS) : yup.string()
  ),

  ro_contact_number: yup.lazy((value) =>
    value ? yup.string().matches(INDIAN_MOBILE_NO_REGEX, err.INVALID_MOBILE) : yup.string()
  ),

  // designation: yup.string().required(),
  // role: yup.string().required(),
});
export const BRAND_PROFILE_CATEGORIES_SCHEMA = yup.object({ categories: yup.array().min(1, err.CATEGORY_REQ) });
export const BRAND_PROFILE_PREFERENCES_SCHEMA = yup.object({ preferences: yup.array().min(1, err.PREFRENCE_REQ) });
export const BRAND_PROFILE_GSTDOC_SCHEMA = yup.object({
  gstDoc: yup.mixed().when('hasGstDoc', {
    is: (value) => !value,
    then: (schema) =>
      schema
        .test('required', err.GST_DOC_REQ, (value) => value?.[0])
        .test('fileType', err.ONLY_PDF, (value) => value?.[0]?.type === 'application/pdf' || value === ''),
  }),
  gst_no: yup.string().required(err.GST_NUMBER_REQ).matches(GST_NO_REGX, err.INVALID_GST_NO),
});

export const BRAND_PROFILE_PANDOC_SCHEMA = yup.object({
  panDoc: yup.mixed().when('hasPanDoc', {
    is: (value) => !value,
    then: (schema) =>
      schema
        .test('required', err.PAN_DOC_REQ, (value) => value?.[0])
        .test('fileType', err.ONLY_PDF, (value) => value?.[0]?.type === 'application/pdf'),
  }),
  pan_card_no: yup.string().required(err.PAN_NO_REQ).matches(PAN_CARD_NO_REGX, err.INVALID_PAN_NO),
});

export const BRAND_CUSTOM_LIST_SCHEMA = yup.object({
  name: yup.string().required(err.LIST_NAME_REQ),
});

export const BRAND_CREATE_CAMPAIGN_STEP1_SCHEMA = yup.object({
  campaignName: yup.string().required(err.CAMPAIGN_NAME_REQ),
  brandName: yup.string().required(err.BRAND_NAME_REQ),
  startDateTime: yup
    .date()
    .transform((current, orignal) => (orignal === '' ? null : current))
    .typeError(err.START_DATE_TIME_REQ)
    .required(err.START_DATE_TIME_REQ),
  endDateTime: yup
    .date()
    .nullable()
    .transform((current, orignal) => (orignal === '' ? null : current)),
  expectedBudget: yup.number().typeError(err.INVALID_BUDGET).min(0, err.INVALID_BUDGET).required(err.BUDGET_REQ),
  paymentTerms: yup.string().required(err.PAYMENT_TERMS_REQ),
  description: yup.string().required(err.DESCRIPTION_REQ),
  categories: yup.array().min(1, err.CATEGORY_REQ),
  tags: yup.array().min(1, err.TAGS_REQ),
  media: yup.mixed().test('required', err.CAMPAIGN_MEDIA_REQ, (value) => value?.[0]),
});

export const BRAND_CREATE_CAMPAIGN_STEP2_SCHEMA = yup.object({
  region: yup.array().min(1, err.CAMPAIGN_REGION_REQ),
  gender: yup.array().min(1, err.CAMPAIGN_GENDER_REQ),
  ageGroup: yup
    .array(
      yup.object({
        from: yup.number().typeError(err.INVALID_AGE_GROUP).required(err.INVALID_AGE_GROUP),
        to: yup
          .number()
          .typeError(err.INVALID_AGE_GROUP)
          .when('from', (from, schema) => (from ? schema.min(Number(from) + 1, err.INVALID_AGE_GROUP) : schema))
          .required(err.INVALID_AGE_GROUP),
      })
    )
    .min(1),
  platforms: yup.array().min(1, err.CAMPAIGN_PLATFORM_REQ),
});

export const BRAND_CREATE_CAMPAIGN_STEP3_SCHEMA = yup.object({
  influencers: yup.array().of(
    yup.object({
      platforms: yup.array(),
      influencerId: yup.string(),
      influencerProfile: yup.string().nullable(),
      influencerName: yup.string(),
      deliverables: yup
        .array()
        .of(
          yup.object({
            platform: yup.string(),
            reelsCount: yup.number(),
            storiesCount: yup.number(),
            imagesCount: yup.number(),
            videosCount: yup.number(),
            shortsCount: yup.number(),
            postsCount: yup.number(),
            tweetsCount: yup.number(),
          })
        )
        .test('hasAtleastOneDeliverable', err.DELIVERABLE_REQ, (value) =>
          Boolean(
            value?.find(
              ({ reelsCount, storiesCount, imagesCount, videosCount, shortsCount, postsCount, tweetsCount }) =>
                reelsCount || storiesCount || imagesCount || videosCount || shortsCount || postsCount || tweetsCount
            )
          )
        ),
    })
  ), // .min(1, err.INFLUENCER_REQ) add this to make min 1 mandatory
});

export const BRAND_CAMPAIGN_CHECKOUT = yup.object({
  paymentType: yup.string().required(),
});

export const INFLUENCER_LOGIN_SCHEMA = yup.object({
  username: usernameValidation,
  password: yup.string().required(err.PASSWORD_REQ),
});

export const INFLUENCER_FORGOT_PASS_SCHEMA = yup.object({
  username: usernameValidation,
  otp: yup.string().when('isOtpVerifyStep', {
    is: (isOtpVerifyStep) => isOtpVerifyStep === true,
    then: () => yup.string().min(6, err.INVALID_OTP).required(err.INVALID_OTP),
  }),
});

export const INFLUENCER_SIGNUP_SCHEMA = yup.object({
  profile: yup.mixed().test('required', err.PROFILE_REQ, (value) => value?.[0]),
  name: yup.string().required(err.NAME_REQ).matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  email: emailValidation,
  mobile: mobileValidation,
  countryCode: yup.string().required(),
});

export const INFLUENCER_SIGNUP_PROFILE_SCHEMA = yup.object({
  managerEmail: managerEmailValidation,
  managerMobile: managerMobileValidation,
  managerCountryCode: yup.string().required(),
  language: yup.array().min(1, err.LANGUAGES_REQ),
  password: passwordValidation,
  confirmPassword: confirmPassValidation,
  consent: yup.bool().oneOf([true], err.CONSENT_REQ),
  dob: yup.date().typeError(err.DOB_REQ).required(err.DOB_REQ),
});

export const INFLUENCER_PROFIE_LANGUAGES_SCHEMA = yup.object({ language: yup.array().min(1, err.LANGUAGES_REQ) });

export const INFLUENCER_PROFILE_SCHEMA = yup.object({
  profile: yup.mixed(),
  name: yup.string().required(err.NAME_REQ).matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  email: emailValidation,
  mobile: mobileValidation,
  type: yup.string().required().matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  isEmailChanged: yup.boolean(),
  otp: yup.string().when('isEmailChanged', {
    is: (isEmailChanged) => isEmailChanged,
    then: (schema) => schema.required(err.INVALID_OTP),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  dob: yup.date().typeError(err.DOB_REQ).required(err.DOB_REQ),
});

export const INFLUENCER_PROFILE_PAN_SCHEMA = yup.object({
  pan_card: yup.mixed().when('hasDoc', {
    is: (value) => !value,
    then: (schema) =>
      schema
        .test('required', err.PAN_DOC_REQ, (value) => value?.[0])
        .test('fileType', err.ONLY_PDF, (value) => value?.[0]?.type === 'application/pdf'),
  }),
  pan_no: yup.string().required().matches(PAN_CARD_NO_REGX, err.INVALID_PAN_NO),
});

export const INFLUENCER_PROFILE_GST_SCHEMA = yup.object({
  gstDoc: yup.mixed().when('hasGstDoc', {
    is: (value) => !value,
    then: (schema) =>
      schema
        .test('required', err.GST_DOC_REQ, (value) => value?.[0])
        .test('fileType', err.ONLY_PDF, (value) => value?.[0]?.type === 'application/pdf' || value?.length === 0),
  }),
  gst_no: yup.string().required(err.GST_NUMBER_REQ).matches(GST_NO_REGX, err.INVALID_GST_NO),
});

export const INFLUENCER_SCHEDULE_POST_SCHEMA = yup.object({
  dateTime: yup
    .date()
    .typeError(err.SCHEDULE_DATE_TIME_REQ)
    .min(new Date(), err.INVALID_SCHEDULE_DATE_TIME)
    .required(err.SCHEDULE_DATE_TIME_REQ),
});

export const INF_UPLOAD_CAMPAIGN_DELIVERABLE_SCHEMA = yup.object({
  title: yup.string().required(err.TITLE_REQ),
  platform: yup.string().required(),
  // description: yup.string().when('platform', {
  //   is: (platform) => platform !== 'twitter',
  //   then: (schema) => schema.required(err.DESCRIPTION_REQ),
  //   otherwise: (schema) => schema.optional().nullable(),
  // }),
  description: yup.string().required(err.DESCRIPTION_REQ),
  tags: yup.array().min(1, err.TAGS_REQ),
  media: yup.mixed().when('platform', {
    is: (platform) => platform !== 'twitter',
    then: (schema) => schema.test('required', err.CAMPAIGN_MEDIA_REQ, (value) => value?.[0]),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

export const INF_LINK_CAMPAIGN_DELIVERABLE_SCHEMA = yup.object({
  title: yup.string().required(err.TITLE_REQ),
  link: yup.string().required(err.POST_LINK_REQ),
});

export const PAYMENT_FORM_SCHEMA = yup.object({
  paymentType: yup.string().required(),
  cardName: yup.string().when('paymentType', {
    is: (val) => val === PAYMENT_TYPES.NET_BANKING,
    then: (schema) => schema.required(err.BANK_TYPE_REQ),
    otherwise: (schema) => schema.required(err.CARD_TYPE_REQ),
  }),
  cardNumber: yup.string().when('paymentType', {
    is: (val) => val === PAYMENT_TYPES.CREDIT_CARD || val === PAYMENT_TYPES.DEBIT_CARD,
    then: (schema) => schema.required(err.CARD_NUM_REQ).matches(CARD_NUM_REGX, err.INVALID_CARD_NUM),
  }),
  cardExpiry: yup.string().when('paymentType', {
    is: (val) => val === PAYMENT_TYPES.CREDIT_CARD || val === PAYMENT_TYPES.DEBIT_CARD,
    then: (schema) =>
      schema
        .required(err.CARD_EXP_REQ)
        .matches(CARD_EXPIRY_REGX, err.INVALID_CARD_EXP)
        .test('isPastDate', err.INVALID_CARD_EXP_DATE, (cardExpiry) => {
          const cardExpiryArray = cardExpiry?.split('/');
          const currentDate = new Date();
          const expiryDate = new Date();
          expiryDate.setFullYear(Number(cardExpiryArray?.[1]), Number(cardExpiryArray?.[0]), 1);
          return expiryDate >= currentDate;
        }),
  }),
  cardCvv: yup.string().when('paymentType', {
    is: (val) => val === PAYMENT_TYPES.CREDIT_CARD || val === PAYMENT_TYPES.DEBIT_CARD,
    then: (schema) => schema.required(err.CARD_CVV_REQ).matches(CARD_CVV_REGX, err.CARD_CVV_REQ),
  }),
  // billingName: yup.string().required(),
  agreeTerms: yup.boolean().oneOf([true], err.CONSENT_REQ),
});

export const CONTACT_US_SCHEMA = yup.object({
  name: yup.string().required(err.NAME_REQ).matches(ALPHABETS_REGX, err.ONLY_ALPHABETS),
  company: yup.string(),
  email: emailValidation,
  mobile: mobileValidation,
  type: yup.string().required(),
  message: yup.string(),
});

export const INF_RATE_CARD_SCHEMA = yup.object().shape({
  youtubeVideo: yup.number().typeError(err.REQUIRED).required(err.REQUIRED),
  youtubeShort: yup.number().typeError(err.REQUIRED).required(err.REQUIRED),
  instImage: yup.number().typeError(err.REQUIRED).required(err.REQUIRED),
  instStory: yup.number().typeError(err.REQUIRED).required(err.REQUIRED),
  instReel: yup.number().typeError(err.REQUIRED).required(err.REQUIRED),
  fbPost: yup.number().typeError(err.REQUIRED).required(err.REQUIRED),
  twitterTweets: yup.number().typeError(err.REQUIRED).required(err.REQUIRED),
});

export const INFLUENCER_ADD_SOCIAL_HANDLES_SCHEMA = yup.object({
  instagramId: yup.string(),
  facebookId: yup.string(),
  youtubeId: yup.string(),
  twitterId: yup.string(),
});
