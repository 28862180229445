import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { getInfCosts, getInfluencerDetails } from 'api/brand/search';
import { Dialog } from 'components/common/dialog';
import { Avatar } from 'components/common/Avatar';
import styles from 'assets/scss/pages/search.module.scss';
import { logoImageNew, locationIcon } from 'constants/images';
import { formatCurrency, formatNumber } from 'utils';

const InfCostOverviewDialog = ({ open, close, influencerId }) => {
  const { data } = useQuery({
    queryKey: ['brand-influencer-costs', influencerId],
    queryFn: () => getInfCosts({ influencer_id: influencerId }),
  });

  const { data: influencer } = useQuery({
    queryKey: ['brand-influencer-details', influencerId],
    queryFn: () => getInfluencerDetails({ influencerId }),
  });

  const {
    stories: instaStory,
    image: instaImagePost,
    reel: instaReel,
    shorts: youtubeShort,
    video_youtube: youtubeVideo,
    posts: facebookPost,
    tweets: twitterTweets,
  } = data?.data?.result || {};

  const {
    profile_image: profileImage,
    full_name: name,
    city,
  } = influencer || {};

  return (
    <Dialog
      title="Influencer cost"
      open={open}
      close={close}
      size="526px"
      fullWidth
    >
      <div className={styles.popupProfileDetail}>
        <div className={styles.profileBlock}>
          <Avatar size="large" src={profileImage} />
          <div>
            <h4 className={styles.userName}>{name}</h4>
          </div>
        </div>
        <div>
          {city ? (
            <div className={styles.locationContainer}>
              <img src={locationIcon} alt="" />
              <span className={styles.countryTxt}>{city}</span>
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.costTable}>
        <table className={styles.costContainer}>
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>Deliverable</th>
              <th>Rate</th>
              <th>ROI</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles.socialList}>Instagram Swipe-up Story</td>
              <td className={styles.socialCost}>
                {instaStory ? formatCurrency(instaStory) : '-'}
              </td>
              <td className={styles.socialCost}>-</td>
            </tr>
            <tr>
              <td className={styles.socialList}>Instagram Image Post</td>
              <td className={styles.socialCost}>
                {instaImagePost ? formatCurrency(instaImagePost) : '-'}
              </td>
              <td className={styles.socialCost}>-</td>
            </tr>
            <tr>
              <td className={styles.socialList}>Instagram Reels</td>
              <td className={styles.socialCost}>
                {instaReel ? formatCurrency(instaReel) : '-'}
              </td>
              <td className={styles.socialCost}>-</td>
            </tr>
            <tr>
              <td className={styles.socialList}>Facebook Post</td>
              <td className={styles.socialCost}>
                {facebookPost ? formatCurrency(facebookPost) : '-'}
              </td>
              <td className={styles.socialCost}>-</td>
            </tr>
            <tr>
              <td className={styles.socialList}>Text Tweet</td>
              <td className={styles.socialCost}>
                {twitterTweets ? formatCurrency(twitterTweets) : '-'}
              </td>
              <td className={styles.socialCost}>-</td>
            </tr>
            <tr>
              <td className={styles.socialList}>YouTube Video</td>
              <td className={styles.socialCost}>
                {youtubeVideo ? formatCurrency(youtubeVideo) : '-'}
              </td>
              <td className={styles.socialCost}>-</td>
            </tr>
            <tr>
              <td className={styles.socialList}>YouTube Shorts</td>
              <td className={styles.socialCost}>
                {youtubeShort ? formatCurrency(youtubeShort) : '-'}
              </td>
              <td className={styles.socialCost}>-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Dialog>
  );
};

InfCostOverviewDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  influencerId: PropTypes.string,
};

export default InfCostOverviewDialog;
