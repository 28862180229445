import React from 'react';
import clsx from 'clsx';
import { CenterZoomSlider } from 'components/common/swiper-slider';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import FaqSection from 'components/home/FaqSection';
import NumbersSection from 'components/home/NumbersSection';
import OurServicesSection from 'components/home/OurServicesSection';
import HowWeWorkSection from 'components/home/HowWeWorkSection';
import TopCategoriesSection from 'components/home/TopCategoriesSection';
// import TestimonialsSection from 'components/home/TestimonialsSection';
import InfluencerTestimonials from 'components/home/InfluencerTestimonials';
import MainHeroSection from 'components/home/MainHeroSection';
// import MainSubscriptionSection from 'components/home/MainSubscriptionSection';
import SlidingLogosStrip from 'components/home/SlidingLogosStrip';
import CaseStudies from 'components/home/CaseStudies';
import styles from 'assets/scss/pages/landing-homepage.module.scss';
import { creatorBg } from 'constants/images';
import FeaturedOnSection from 'components/home/FeaturedOnSection';
import { Link } from 'react-router-dom';
import routes from 'constants/routes';

const Home = () => (
  <div>
    <Header isDark />
    {/* banner======================== */}
    <MainHeroSection />
    {/* Brand Selection ================== */}
    <NumbersSection />
    {/* How we work============================ */}

    <HowWeWorkSection />
    {/* subscribe plans ============================= */}
    {/* <MainSubscriptionSection /> */}

    {/* Top Influencer Categories================= */}
    <TopCategoriesSection />
    {/* Our services =========================================== */}
    <OurServicesSection />

    {/* idea banner ========================== */}
    <SlidingLogosStrip />
    {/* High Impact Work For Brands & Creators ======================= */}
    <div className={styles.sliderContainer}>
      <div className={styles.slideHeader}>
        <img className={styles.creatorBg} src={creatorBg} alt="" />
      </div>
      <div>
        <div className={styles.blurHeading}>
          <span className={styles.blurInnr} />
          <h2 className={clsx(styles.serviceTxt, styles.impactTxt)}>
            Creating high impact content for brands & creators.
          </h2>
        </div>
        <div className={styles.sliderTopBody}>
          <CenterZoomSlider />
        </div>
      </div>
    </div>
    {/* fetured Banner ================================= */}
    <FeaturedOnSection />

    {/* What Are They Saying? ============================== */}
    {/* <TestimonialsSection /> */}
    <InfluencerTestimonials />

    {/* Post Your Campaign==================================== */}
    <div className={styles.postCampaignWrapper}>
      <div className={styles.postCampaignInnr}>
        <div className="campaignTxtBody">
          <h3 className={styles.campaignTxt1}>
            Your campaign can begin whenever you like.
          </h3>
          <p className={styles.campaignTxt2}>
            Launch your campaign at lightning speed, kicking off in the shortest
            time possible!
          </p>
        </div>
        <div className={styles.postCampBtn}>
          <Link
            to={routes.CONTACT_US}
            state={{ type: 'brand' }}
            className={styles.postBtn}
          >
            Get Started!
          </Link>
        </div>
      </div>
    </div>

    <CaseStudies />

    {/* Quessionare=============================================================== */}
    <FaqSection />
    <Footer />
  </div>
);

export default Home;
