import React from 'react';
import PropTypes from 'prop-types';
import styles from './NoData.module.scss';

const NoData = ({
  title = 'Data not available!',
  description = ' We don’t have enough data currently to generate insights.',
  height,
  style,
}) => (
  <div className={styles.wrapper} style={{ height, ...style }}>
    <h2 className={styles.title}>{title}</h2>
    <p className={styles.description}>{description}</p>
  </div>
);

NoData.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  height: PropTypes.number,
  style: PropTypes.object,
};

export default NoData;
