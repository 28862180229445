import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import FaqSection from 'components/home/FaqSection';
import { ReactComponent as BackgroundSvg } from 'assets/images/influencer-bg.svg';
import styles from 'assets/scss/pages/brand-homepage.module.scss';
import {
  dotPattrnImg,
  brandsImg,
  sideEllipseImg,
  hnMImg,
  influPostingImg,
  gromingMasterclassImg,
  nuturingInfluencerImg,
  guidingInfluencerImg,
  bhuvanBamImg,
  homeBgMaskWebp,
  homeBgMaskPng,
} from 'constants/images';
import routes from 'constants/routes';
import IndustryMarketValueDarkPink from 'components/home/svg/IndustryMarketValueDarkPink';
import UsersOnInstagramDarkPink from 'components/home/svg/UsersOnInstagramDarkPink';
import DirectConsumersDarkPink from 'components/home/svg/DirectConsumersDarkPink';
import MonthlyActiveUsersInfluencer from 'components/home/svg/MonthlyActiveUsersInfluencer';

const InfluencerHome = () => (
  <div>
    <Header varient="influencer" />
    {/* banner ============== */}
    <div className={styles.bannerWrapper}>
      <div className={styles.bannrInnrWrapper}>
        <div className={styles.bannerHeading}>
          <motion.h2
            className={styles.headingTxt}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.2 }}
          >
            Elevate your global reach by collaborating with top brands on
            SMINCO.
          </motion.h2>
          <motion.p
            className={styles.paragraphTxt}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.4 }}
          >
            An integrated hub for influencers and brands, offering seamless
            multilingual management.
          </motion.p>
          <motion.div
            className={styles.bannerBtns}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.6 }}
          >
            <Link
              to={routes.INF_SIGNUP}
              className={styles.startCampBtn}
              style={{ color: '#FF00A2' }}
            >
              Create Profile Now
            </Link>
            <Link
              className={styles.bookCampBtn}
              to={routes.CONTACT_US}
              state={{
                type: 'influencer',
              }}
            >
              Contact Us
            </Link>
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0.8 }}
        >
          <div className={styles.multiImages}>
            <div className={styles.blockImg1}>
              <IndustryMarketValueDarkPink />
              <h3 className={styles.titleTxt}>
                Value of influencer marketing industry in India
              </h3>
            </div>
            <div className={styles.blockImg2}>
              <UsersOnInstagramDarkPink />
              <h3 className={styles.titleTxt}>
                Users are 25-34 years old on Instagram
              </h3>
            </div>
            <div className={styles.blockImg3}>
              <div className={styles.img1}>
                <img src={hnMImg} alt="" />
              </div>
              <div>
                <MonthlyActiveUsersInfluencer />
                <div className={styles.messageContent}>
                  {/* <div className={styles.messageBodyCheckmark} /> */}
                  <p
                    className={styles.videoTxt}
                    style={{ textAlign: 'center' }}
                  >
                    Short Videos
                  </p>
                </div>
              </div>
              <div className={styles.img2}>
                <DirectConsumersDarkPink />
                <h3 className={styles.title2Txt}>
                  Direct consumers for Influencers
                </h3>
              </div>
              <div>
                <img src={bhuvanBamImg} alt="" />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      {/* ============== background =================== */}
      <BackgroundSvg
        className={styles.backgroundSvg}
        width="100%"
        height="100%"
      />
      <picture className={styles.backgroundDotsImage}>
        <source srcSet={homeBgMaskWebp} type="image/webp" />
        <img
          src={homeBgMaskPng}
          alt=" "
          width="100%"
          height="100%"
          style={{ height: '100%' }}
        />
      </picture>
      {/* ============== background =================== */}
    </div>
    {/* second================== */}
    <div className={styles.elevationBrands} style={{ position: 'relative' }}>
      <img className={styles.dotImage} src={dotPattrnImg} alt="" />
      <h1 className={styles.elevationTxt}>
        Discover exciting opportunities to collaborate with highly recognized
        brands you'll love!
      </h1>
      <div className={styles.brandsInnrSec}>
        <div className={styles.brandContainer}>
          <img src={brandsImg} alt="" />
          {/* <img className={styles.elevatingBg} src={elevatingBg} alt="" /> */}
        </div>
        <div className={styles.collabInnrContainer}>
          <ul className={styles.listItem}>
            <li className={styles.listTxt}>
              At SMINCO, influencers get the spotlight as we showcase
              advertisements for India's Top{' '}
              <span className={styles.listTxtSpan}> 10,000 </span>recognized
              companies across diverse sectors, from Corporate to Retail. We
              broadcast
            </li>
            <li className={styles.listTxt}>
              Our cutting-edge professional team of experts excels in delivering
              exceptional content across multiple regions.
            </li>
            <li className={styles.listTxt}>
              SMINCO offers a comprehensive advertising services to meet your
              diverse needs.
            </li>
          </ul>
          <div className={styles.prfileBtnContainer}>
            <Link
              to={routes.INF_SIGNUP}
              className={clsx(styles.createProfileBtn, styles.pinkBg)}
            >
              Create Profile
            </Link>
          </div>
        </div>
      </div>
      <img className={styles.sideEllipseImg} src={sideEllipseImg} alt="" />
    </div>
    {/* third======================== */}
    <div className={styles.boostContentWrapper}>
      <div className={clsx(styles.contentInnr, styles.boostContInnr)}>
        <div className={styles.influenerBoostContainer}>
          <h2 className={styles.curateTxt} style={{ color: '#fff' }}>
            Increase your social capital and get Insights!
          </h2>
          <p className={styles.curateDetailTxt} style={{ color: '#fff' }}>
            At our influencer marketing hub, recognition awaits our influencers,
            providing them a platform to share experiences, ongoing projects,
            and upcoming tasks. <br />
            <br /> We spotlight influencers across diverse markets, enabling
            them to broaden their fan base and reach new audiences effectively.
          </p>
          <div className={styles.prfileBtnContainer}>
            <Link
              className={styles.bookUsBtn}
              to={routes.CONTACT_US}
              state={{
                type: 'influencer',
              }}
            >
              Signup Now!
            </Link>
          </div>
        </div>
        <div className={styles.influncrPostingContiner}>
          <img className={styles.infImg} src={influPostingImg} alt="" />
        </div>
      </div>
    </div>
    {/* fourth ======================================= */}
    <div className={styles.langugeSec}>
      <img className={styles.dotLangSec} src={dotPattrnImg} alt="" />
      <div>
        <img src={gromingMasterclassImg} alt="" />
      </div>
      <div className={styles.contntSubBox}>
        <h2 className={styles.curateTxt}>Grooming Masterclass</h2>
        <p className={styles.curateDetailTxt}>
          We will help elevate your social capital with our expert-led events
          and workshops, guiding you through strategic growth in the influencer
          marketing realm.
          <br />
          <br />
          Our social media courses are guided by top influencers to keep you
          up-to-date on the newest social media trends, enhancing your
          interpersonal skills.
        </p>
        <div className={styles.prfileBtnContainer}>
          <Link
            to={routes.INF_SIGNUP}
            className={clsx(styles.createProfileBtn, styles.pinkBg)}
          >
            Create Profile
          </Link>
        </div>
      </div>
    </div>
    {/* fifth=============================================== */}
    <div className={clsx(styles.langugeSec, styles.wrapReverse)}>
      <div className={styles.contntSubBox}>
        <h2 className={clsx(styles.curateTxt, styles.withNegativeMargin)}>
          Nurturing influencers potential for informed and sustainable growth in
          the dynamic landscape of influencer marketing.
        </h2>
        {/* <img className={styles.dotPattrnImg} src={dotPattrnImg} alt="" /> */}
        <p className={styles.curateDetailTxt}>
          Our professional advancement path empowers micro and nano influencers,
          providing them with a sense of value and enabling their growth into
          big influencers.
          <br />
          <br />
          We support and elevate micro and nano influencers through a range of
          activities, including training, webinars, mentorship, and more.
        </p>
        <div className={styles.prfileBtnContainer}>
          <Link
            className={clsx(styles.createProfileBtn, styles.pinkBg)}
            to={routes.INF_SIGNUP}
            // to={routes.CONTACT_US}
            // state={{
            //   type: 'influencer',
            // }}
          >
            {/* Book Us Now */}
            Signup Now!
          </Link>
        </div>
      </div>
      <div>
        <img src={nuturingInfluencerImg} alt="" />
      </div>
    </div>
    {/* sixth=================================================== */}
    <div className={styles.langugeSec}>
      <div>
        <img src={guidingInfluencerImg} alt="" />
      </div>
      <div className={styles.influenerBoostContainer}>
        <h2 className={styles.curateTxt}>
          Working with our campaign strategist to steer your campaign to success
        </h2>
        <p className={styles.curateDetailTxt}>
          Our Influencer Campaign Strategist is committed to Support influencers
          in advancing their careers and promoting their work.
          <br />
          <br />
          They will also efficiently manage public relations monitoring,
          contracts and campaign schedules.
          <br />
          <br />
          Ready to elevate your experience?
        </p>
        <div className={styles.prfileBtnContainer}>
          <Link
            to={routes.CONTACT_US}
            className={clsx(styles.createProfileBtn, styles.pinkBg)}
            state={{
              type: 'influencer',
            }}
          >
            Connect with us
          </Link>
        </div>
      </div>
      <img
        className={styles.dotLangSec}
        style={{ right: '0' }}
        src={dotPattrnImg}
        alt=""
      />
    </div>
    {/* seventh=============================================================== */}
    <FaqSection varient="influencer" />
    {/* footer====================================================================== */}
    <Footer varient="influencer" />
  </div>
);

export default InfluencerHome;
