import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Listbox } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import styles from 'assets/scss/pages/compare-influencer.module.scss';
import { locationDownIcon } from 'constants/images';
import clsx from 'clsx';

const Select = ({
  value,
  onChange,
  options = [],
  labelSelector = 'label',
  valueSelector = 'value',
  iconSelector = 'icon',
}) => {
  const selected = useMemo(
    () => options?.find((option) => value === option[valueSelector]) || '',
    [value, options, valueSelector]
  );

  return (
    <Listbox value={selected} onChange={onChange}>
      <Float placement="bottom" flip offset={2} adaptiveWidth portal>
        <Listbox.Button className={styles.brandBtn}>
          <img src={selected?.[iconSelector]} alt="" />
          <span>{selected?.[labelSelector]}</span>
          <img src={locationDownIcon} alt="" />
        </Listbox.Button>
        <Listbox.Options className={styles.selectOptions}>
          {options.map((option) => (
            <Listbox.Option
              key={option[labelSelector]}
              value={option[valueSelector]}
              disabled={option.disabled}
              className={({ selected, active }) =>
                clsx({
                  [styles.selectOption]: true,
                  [styles.active]: selected || active,
                })
              }
            >
              {option[labelSelector]}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Float>
    </Listbox>
  );
};

Select.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  labelSelector: PropTypes.string,
  valueSelector: PropTypes.string,
  iconSelector: PropTypes.string,
};

export default Select;
