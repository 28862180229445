import React from 'react';
import clsx from 'clsx';
import { useSpinDelay } from 'spin-delay';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { getTrendingInfluencers } from 'api/brand/search';
import { Avatar } from 'components/common/Avatar';
import { LoaderWithWrapper } from 'components/common/Loader';
import styles from 'assets/scss/pages/search.module.scss';
import {
  copyImage,
  ytIcon,
  cardtransBg,
  instaSvg,
  facebookSvg,
  twitterSvg,
  // blueTickIcon
} from 'constants/images';
import { DIALOGS } from 'constants/config/dialog-names';
import { useDialog } from 'stores/dialog';
import { formatNumber } from 'utils';

const TrendingInfluencers = ({ platformFilter }) => {
  const setDialog = useDialog((state) => state.setDialog);
  const { data, isLoading } = useQuery({
    queryKey: ['brand-trending-influencers', 1, 5],
    queryFn: () => getTrendingInfluencers({ pageNo: 1, pageSize: 5 }),
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  const influencers = data?.data?.trendingCampaignListData;
  return (
    <div className="trendingInfluencer">
      <h2 className={clsx(styles.trendingTxt, styles.subCategoryTitle)}>
        Trending Influencers
      </h2>
      <div className={styles.influencerProfileContainer}>
        {isLoading || delayedIsLoading ? (
          <LoaderWithWrapper show={delayedIsLoading} />
        ) : (
          influencers
            ?.slice(0, 5)
            ?.map(({ id, full_name: name, profile_image: image, ...inf }) => (
              <div className={styles.influencerProfile} key={id}>
                <div className={styles.cardtransBg}>
                  <img src={cardtransBg} alt="" />
                </div>
                <div className="influencrImg">
                  <img
                    className={styles.influencrProfilePhoto}
                    src={image}
                    alt=""
                    width="114"
                    height="116"
                  />
                </div>
                <div className={styles.influencrDetail}>
                  <div className={styles.profileContent}>
                    <div className={styles.profileTop}>
                      <h3 className={styles.profileName}>{name}</h3>
                      {/* <img src={blueTickIcon} alt="" /> */}
                    </div>
                    <button
                      type="button"
                      className={styles.copyImg}
                      onClick={() =>
                        setDialog(DIALOGS.ADD_INF_TO_CUSTOM_LIST, {
                          influencerIds: [id],
                        })
                      }
                    >
                      <img src={copyImage} alt="" />
                    </button>
                  </div>
                  <span className={styles.profileHandle}>
                    {platformFilter === 'youtube'
                      ? inf?.[`influencer_${platformFilter}`]?.handle
                      : inf?.[`influencer_${platformFilter}`]?.username}
                  </span>
                  <div className={styles.profileActions}>
                    {/* <div className="radiocityScore">
                      <h3 className={styles.scoreTxt}>Platform Score</h3>
                      <div className={styles.scoreCount}>
                        <p className={styles.score}>
                          {inf?.rc_score ? formatNumber(inf?.rc_score) : null}
                        </p>
                        <span className={styles.scorePercent}>+36%</span>
                      </div>
                    </div> */}
                    <div className="radiocityScore">
                      <h3 className={styles.scoreTxt}>Followers</h3>
                      <div className={styles.scoreCount}>
                        <p className={styles.score}>
                          {formatNumber(
                            (platformFilter === 'instagram' &&
                              inf?.influencer_instagram?.followers_count) ||
                              (platformFilter === 'youtube' &&
                                inf?.influencer_youtube?.subscriber_count) ||
                              (platformFilter === 'facebook' &&
                                inf?.influencer_facebook?.follower_count) ||
                              (platformFilter === 'twitter' &&
                                inf?.influencer_twitter?.follower_count)
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="socialImage">
                      <Avatar
                        src={
                          (platformFilter === 'instagram' && instaSvg) ||
                          (platformFilter === 'youtube' && ytIcon) ||
                          (platformFilter === 'facebook' && facebookSvg) ||
                          (platformFilter === 'twitter' && twitterSvg)
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
        )}
      </div>
    </div>
  );
};

TrendingInfluencers.propTypes = {
  platformFilter: PropTypes.string,
};
export default React.memo(TrendingInfluencers);
