export const generateDefaultDeliverable = (platform = '') => ({
  platform,
  reelsCount: 0,
  storiesCount: 0,
  imagesCount: 0,
  videosCount: 0,
  shortsCount: 0,
  postsCount: 0,
  tweetsCount: 0,
});
