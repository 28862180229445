import React, { useEffect, useRef } from 'react';
import { animate, motion } from 'framer-motion';

const UsersOnInstagramRed = ({ outlined = false, props }) => {
  const numberRef = useRef();

  useEffect(() => {
    const node = numberRef.current;
    if (!node) return;

    const controls = animate(0, 47, {
      duration: 1,
      delay: 1,
      onUpdate(value) {
        node.textContent = `${Math.round(value).toString()}%`;
      },
    });

    // eslint-disable-next-line consistent-return
    return () => controls.stop();
  }, []);

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width={170}
      height={170}
      {...props}
    >
      <defs>
        <filter
          id="usersOnInstagramFilter1"
          width={170}
          height={170}
          x={0}
          y={0}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={4} dy={5} />
          <feGaussianBlur result="blur" stdDeviation={5} />
          <feFlood floodColor="#faa3da" floodOpacity={0.502} />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="usersOnInstagramFilter3"
          width={82}
          height={82}
          x={40}
          y={39}
          filterUnits="userSpaceOnUse"
        >
          <feOffset />
          <feGaussianBlur result="blur-2" stdDeviation={7.5} />
          <feFlood floodColor="#ee2ea8" floodOpacity={0.502} result="color" />
          <feComposite in="SourceGraphic" in2="blur-2" operator="out" />
          <feComposite in="color" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <linearGradient
          id="usersOnInstagramFilter2"
          x2={0.984}
          y1={0.5}
          y2={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.607} stopColor="#b584ce" stopOpacity={0.149} />
          <stop offset={1} stopColor="#641582" stopOpacity={0.8} />
        </linearGradient>
      </defs>
      <g data-name="Group 54846">
        <g
          filter="url(#usersOnInstagramFilter1)"
          transform="translate(.003 .003)"
        >
          <g
            fill="url(#usersOnInstagramFilter2)"
            stroke="rgba(255,255,255,0.3)"
            strokeWidth={1.5}
            data-name="Ellipse 1524"
            transform="translate(11 10)"
          >
            <circle cx={70} cy={70} r={70} stroke="none" />
            <circle
              cx={70}
              cy={70}
              r={69.25}
              fill="none"
              stroke={outlined ? '#f8f9fa' : ''}
            />
          </g>
        </g>

        <g data-type="innerShadowGroup">
          <g
            filter="url(#usersOnInstagramFilter3)"
            transform="translate(.003 .003)"
          >
            <circle
              cx={41}
              cy={41}
              r={41}
              fill="#fff"
              data-name="Ellipse 1525"
              transform="translate(40 39)"
            />
          </g>
          <g
            fill="none"
            stroke="rgba(255,255,255,0.3)"
            data-name="Ellipse 1525"
            transform="translate(40 39)"
          >
            <circle
              cx={41}
              cy={41}
              r={41}
              stroke={outlined ? '#f8f9fa' : 'none'}
            />
            <circle cx={41} cy={41} r={41} />
          </g>
        </g>
        <g
          fill="none"
          stroke="#ff00a2"
          strokeDasharray="210 1000"
          strokeWidth={30}
          data-name="Ellipse 1526"
          transform="translate(11 10)"
        >
          <circle cx={70} cy={70} r={70} stroke="none" />
          <motion.circle
            cx={70}
            cy={70}
            r={55}
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 0.47 }}
            transition={{ duration: 1, delay: 1 }}
            transform="rotate(-90, 70, 70)"
          />
        </g>
        <text
          fill="#fff"
          data-name="47%"
          fontFamily="Poppins-Bold, Poppins"
          fontSize={26}
          fontWeight={700}
          transform="translate(81 89)"
        >
          <tspan x={-27} y={0} ref={numberRef} />
        </text>
      </g>
    </motion.svg>
  );
};
export default UsersOnInstagramRed;
