/**
 * save array buffer into file
 * @param {ArrayBuffer} data data of document
 * @param {string} fileType mime type of file
 * @param {string} fileName name of file
 */

const saveFile = (data, fileType, fileName) => {
  const a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(
    new Blob([data], {
      type: fileType,
    })
  );
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export { saveFile };
