import React from 'react';
import { Dialog } from 'components/common/dialog';
import styles from 'assets/scss/pages/popup.module.scss';
import {
  cogwheelIcon,
  coloredCrossIcon,
  transparentBg,
  // calndrImg,
  // chooseTagsIcon,
  // downIcon,
  // pinkAddIcon,
  // uplImgVideoIcon,
} from 'constants/images';
import instagramAnimatedIcon from 'assets/lottie/instagram-icon.json';
import facebookAnimatedIcon from 'assets/lottie/facebook-icon.json';
import youtubeAnimatedIcon from 'assets/lottie/youtube-icon.json';
import twitterAnimatedIcon from 'assets/lottie/twitter-icon.json';
import { Player } from '@lottiefiles/react-lottie-player';
import clsx from 'clsx';

const ScheduleCampagin = ({ open, close }) => (
  <Dialog open={open} close={close} fullWidth>
    <div className={styles.dashboardTop}>
      <img className={styles.transBg} src={transparentBg} alt="" />
      <div className={styles.campaignContainer} style={{ padding: '25px' }}>
        <div className={styles.innrWrapper}>
          <h2 className={styles.creationTxt}>Campaign Creation</h2>
          <img style={{ cursor: 'pointer' }} src={coloredCrossIcon} alt="" />
        </div>
      </div>
      <div className={styles.categoryButtons}>
        <a className={styles.influencerCateBtn}>
          <span className={styles.counTxt}>1</span> General
        </a>
        <a className={clsx(styles.influencerCateBtn, styles.whiteActiveBtn)}>
          <span className={clsx(styles.counTxt, styles.activeCountTxt)}>2</span>
          Social Media
        </a>
      </div>
    </div>
    {/* General Section----- */}
    {/* <div className={styles.secBody}>
      <div className={styles.secBodyWrapper}>
        <img src={calndrImg} alt="" />
        <div className="timeZoneDiv">
          <div className={styles.timeWrapp}>
            <div>
              <h2 className={styles.hTxt}>Start On</h2>
              <div className={styles.timeDiv}>
                <p className={styles.innrTimeTxt}>15 Apr 2023</p>
                <p className={styles.innrTimeTxt}>10:00 PM</p>
              </div>
            </div>
            <div>
              <h2 style={{ marginBottom: '10px' }} className={styles.hTxt}>
                To
              </h2>
            </div>
            <div>
              <h2 className={styles.hTxt}>Ends On (Optional)</h2>
              <div className={styles.timeDiv}>
                <p className={styles.innrTimeTxt}>15 Apr 2023</p>
                <p className={styles.innrTimeTxt}>10:00 PM</p>
              </div>
            </div>
          </div>
          <div className={styles.locationWrap}>
            <p className={styles.locaTxt}>Asia/ Mumbai</p>
            <img className={styles.downImg} src={downIcon} alt="" />
          </div>
        </div>
      </div>

      <div className={styles.secBodyWrapper}>
        <img src={chooseTagsIcon} alt="" />
        <div className={styles.popupInputWrap}>
          <h2 className={styles.hTxt}>Choose Tags</h2>
          <div className={styles.tagsField}>
            <p className={clsx(styles.tags, styles.activeTags)}>Summerstyle</p>
            <p className={clsx(styles.tags, styles.activeTags)}>
              Summerfashion
            </p>
            <p className={styles.tags}>Styling</p>
            <p className={styles.tags}>Summer</p>
            <p className={styles.tags}>Fashion</p>
          </div>
          <div className="tagsField">
            <button className={styles.addNewBtn}>
              <img src={pinkAddIcon} alt="" /> Add New
            </button>
          </div>
        </div>
      </div>
      <div className={styles.secBodyWrapper}>
        <img src={uplImgVideoIcon} alt="" />
        <div className={styles.popupInputWrap}>
          <h2 className={styles.hTxt}>Upload Images/Videos</h2>
          <div className={styles.uploadFolderSec}>
            <img src="#" alt="" />
            <h3 className={styles.dragDropTxt}>
              <span>Drag & drop</span> or <span>browse</span> from your computer
            </h3>
            <p className={styles.folderSizeTxt}>
              Support .jpg, .png, .gif, .mp4 max 200 MB
            </p>
          </div>
        </div>
      </div>
      <div className={clsx(styles.campaignBtns, styles.nxtWrap)}>
        <button style={{ width: '30%' }} className={styles.updateBtn}>
          Next
        </button>
      </div>
    </div> */}

    {/* Social Media section------------------ */}
    <div className={clsx(styles.secBody, styles.socialMediaBody)}>
      <div>
        <div className={styles.secBodyWrapper}>
          <img src={cogwheelIcon} alt="" />
          <div className={styles.popupInputWrap}>
            <h2 className={styles.hTxt}>Choose Platform</h2>
            <input
              className={styles.inputTxt}
              type="text"
              placeholder="Reebok"
            />
          </div>
        </div>
        <div className={styles.secBodyWrapper} style={{ marginBottom: '15px' }}>
          <img src={cogwheelIcon} alt="" />
          <h2 className={styles.hTxt}>Social Media Profile Links</h2>
        </div>
        <div className={styles.popupInputWrap}>
          <form className={styles.signupFormItems}>
            <div className={styles.accountLink}>
              <div className={styles.socialImage}>
                <Player loop autoplay src={instagramAnimatedIcon} />
              </div>
              <div className={styles.popupInputWrap}>
                <input
                  className={styles.inputTxt}
                  type="text"
                  placeholder="Reebok"
                />
              </div>
            </div>
            <div className={styles.accountLink}>
              <div className={styles.socialImage}>
                <Player loop autoplay src={facebookAnimatedIcon} />
              </div>
              <div className={styles.popupInputWrap}>
                <input
                  className={styles.inputTxt}
                  type="text"
                  placeholder="Reebok"
                />
              </div>
            </div>
            <div className={styles.accountLink}>
              <div className={styles.socialImage}>
                <Player
                  loop
                  autoplay
                  src={youtubeAnimatedIcon}
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    transform: 'scale(2.1)',
                  }}
                />
              </div>
              <div className={styles.popupInputWrap}>
                <input
                  className={styles.inputTxt}
                  type="text"
                  placeholder="Reebok"
                />
              </div>
            </div>
            <div className={styles.accountLink}>
              <div className={styles.socialImage}>
                <Player loop autoplay src={twitterAnimatedIcon} />
              </div>
              <div className={styles.popupInputWrap}>
                <input
                  className={styles.inputTxt}
                  type="text"
                  placeholder="Reebok"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={clsx(styles.campaignBtns, styles.nxtWrap)}>
        <button type="button" className={styles.prevBtn}>
          Previous
        </button>
        <button
          type="button"
          style={{ width: '30%' }}
          className={styles.updateBtn}
        >
          Next
        </button>
      </div>
    </div>
  </Dialog>
);

export default ScheduleCampagin;
