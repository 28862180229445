import axios, { AxiosError } from 'axios';

const axiosBaseQuery =
  ({ baseURL, prepareHeaders, onUnauthorized }) =>
  async ({ url, method, body: data, params, transformResponse, ...rest }) => {
    let interceptor;
    try {
      // this to allow prepare headers
      if (prepareHeaders) {
        interceptor = axios.interceptors.request.use((config) => {
          const newHeaders = prepareHeaders(config.headers);
          config.headers = { ...newHeaders };
          return config;
        });
      }

      const result = await axios({
        url,
        baseURL,
        method,
        data,
        params,
        transformResponse: transformResponse
          ? axios.defaults.transformResponse.concat(transformResponse)
          : undefined,
        ...rest,
      });

      if (result.data?.error) {
        throw new AxiosError({
          message: result.data.error?.message ?? 'someting went wrong.',
          code: result.data.error?.code ?? 'INTERNAL_SERVER_ERROR',
          isAxiosError: true,
          status: result.data.error?.status ?? 500,
          config: result.config,
          request: result.request,
          response: null,
        });
      }
      return result.data;
    } catch (axiosError) {
      if (
        onUnauthorized &&
        (axiosError.response?.status === 401 ||
          axiosError.response?.data?.code === 401)
      ) {
        onUnauthorized();
      }
      throw axiosError;
    } finally {
      axios.interceptors.request.eject(interceptor);
    }
  };

export default axiosBaseQuery;
