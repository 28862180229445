import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { Dialog } from 'components/common/dialog';
import { InputWrapper, Input } from 'components/common/campaign';
import ErrorMessage from 'components/common/form/ErrorMessage';
import errorMessages from 'constants/config/error-messagess';
import { chooseTagsIcon, pinkAddIcon } from 'constants/images';
import styles from 'assets/scss/pages/popup.module.scss';

const TagsSection = ({ fields, remove, append, errors, tags = [] }) => {
  const [newTags, setNewTags] = useState([]);

  const selectedTagIds = useMemo(
    () => fields.map(({ tagId }) => tagId),
    [fields]
  );
  const filteredTags = useMemo(
    () =>
      [...tags, ...newTags].map((tag) => ({
        ...tag,
        isSelected: selectedTagIds.includes(tag.tagId),
      })),
    [tags, selectedTagIds, newTags]
  );

  const onTagClick = (tag) => () => {
    if (tag.isSelected) {
      const index = fields.findIndex((field) => field.tagId === tag.tagId);
      remove(index);
    } else {
      append({ name: tag.name, tagId: tag.tagId });
    }
  };

  const onNewTagAdd = (tagName) => {
    const newTag = { name: tagName, tagId: tagName };
    append(newTag);
    setNewTags((prev) => {
      prev.push(newTag);
      return prev;
    });
  };

  return (
    <InputWrapper icon={chooseTagsIcon}>
      <div className={styles.popupInputWrap}>
        <h2 className={styles.hTxt}>Choose Tags</h2>
        <div className={styles.tagsField}>
          {filteredTags?.map((tag) => (
            <button
              key={tag.tagId}
              type="button"
              className={clsx({
                [styles.tags]: true,
                [styles.activeTags]: tag.isSelected,
              })}
              onClick={onTagClick(tag)}
            >
              {tag.name}
            </button>
          ))}
        </div>
        <div className="tagsField">
          <CreateTagDialog
            onAdd={onNewTagAdd}
            allTags={filteredTags}
            render={({ open }) => (
              <button type="button" className={styles.addNewBtn} onClick={open}>
                <img src={pinkAddIcon} alt="" /> Add New
              </button>
            )}
          />
        </div>
        <ErrorMessage error={errors?.tags} />
      </div>
    </InputWrapper>
  );
};

TagsSection.propTypes = {
  fields: PropTypes.array,
  append: PropTypes.func,
  remove: PropTypes.func,
  errors: PropTypes.object,
  tags: PropTypes.array,
};

const CreateTagDialog = ({ render, onAdd, allTags }) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { tagName: '' } });

  const onClose = () => {
    setIsOpen(false);
    reset();
  };
  const onOpen = () => setIsOpen(true);

  const onSubmit = ({ tagName }) => {
    onAdd(tagName);
    onClose();
  };

  const checkTagAlreadyExists = (tagName) => {
    const existingTags = allTags?.map(({ name }) => name);
    if (existingTags?.includes(tagName)) {
      return errorMessages.DUPLICATE_TAG_NAME;
    }
    return true;
  };

  return (
    <>
      {render ? render({ open: onOpen }) : null}
      <Dialog
        title="Add New Tag"
        open={isOpen}
        close={onClose}
        size="526px"
        fullWidth
      >
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}
          id="add-tag-form"
        >
          <div
            className={styles.createInfFooter}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <Input
              {...register('tagName', {
                required: errorMessages.TAG_NAME_REQ,
                validate: checkTagAlreadyExists,
              })}
            />
            <ErrorMessage error={errors?.tagName} />
          </div>
          <div className={styles.createInfFooter}>
            <div className={clsx(styles.campaignBtns, styles.nxtWrap)}>
              <button
                type="button"
                onClick={onClose}
                className={styles.prevBtn}
              >
                Back
              </button>
              <button
                type="submit"
                style={{ width: 166 }}
                className={styles.updateBtn}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

CreateTagDialog.propTypes = {
  render: PropTypes.func,
  getValues: PropTypes.func,
  allTags: PropTypes.array,
};

export default TagsSection;
