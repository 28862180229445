/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-key */
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Popover } from '@headlessui/react';
import { useTable, useRowSelect, useSortBy } from 'react-table';
import { useSpinDelay } from 'spin-delay';
import { ReactComponent as InfoIcon } from 'assets/images/information-circle-outline.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/images/arrow-down-filter.svg';
import styles from './Table.module.scss';
import Pagination from './Pagination';
import PaginationWithPageInput from './PaginationWithPageInput';
import TableState from './TableState';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type="checkbox"
          className={styles.checkBox}
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

const InfoPopover = ({ info }) => {
  if (!info) {
    return null;
  }

  return (
    <Popover className={styles.infoPopover}>
      <Popover.Button>
        <InfoIcon />
      </Popover.Button>
      <Popover.Panel className={styles.popoverPanel}>{info}</Popover.Panel>
    </Popover>
  );
};

// This fixes rerendring issue
const emptyArray = [];

const Table = forwardRef(
  (
    {
      container = true,
      dense = false,
      isLoading = false,
      noDataValue,
      loadingValue,
      columns,
      data = emptyArray,
      paginationProps,
      isRowsSelectable,
      onSlectedRowsChange,
      onSortChange,
      ...rest
    },
    ref
  ) => {
    const delayedIsLoading = useSpinDelay(isLoading, {
      delay: 500,
      minDuration: 700,
    });

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
      state: { selectedRowIds, sortBy },
      toggleAllRowsSelected,
      setHiddenColumns,
    } = useTable(
      {
        ...rest,
        columns,
        data,
        manualSortBy: true,
        autoResetSortBy: false,
        defaultColumn: {
          disableSortBy: true,
        },
      },
      useSortBy,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) =>
          isRowsSelectable
            ? [
                {
                  id: 'selection',
                  // The header can use the table's getToggleAllRowsSelectedProps method
                  // to render a checkbox
                  // eslint-disable-next-line react/prop-types
                  Header: ({ getToggleAllRowsSelectedProps }) => (
                    <IndeterminateCheckbox
                      {...getToggleAllRowsSelectedProps()}
                    />
                  ),
                  // The cell can use the individual row's getToggleRowSelectedProps method
                  // to the render a checkbox
                  // eslint-disable-next-line react/prop-types
                  Cell: ({ row }) => (
                    <IndeterminateCheckbox
                      // eslint-disable-next-line react/prop-types
                      {...row.getToggleRowSelectedProps()}
                    />
                  ),
                  canSort: false,
                  disableSortBy: true,
                  width: 20,
                  minWidth: 20,
                },
                ...columns,
              ]
            : columns
        );
      }
    );

    useImperativeHandle(ref, () => ({
      resetSelectedRows: () => toggleAllRowsSelected(false),
      setHiddenColumns: (hiddenColumns) => setHiddenColumns(hiddenColumns),
    }));

    useEffect(() => {
      if (onSlectedRowsChange) {
        onSlectedRowsChange(selectedFlatRows, selectedRowIds);
      }
    }, [selectedFlatRows, selectedRowIds, onSlectedRowsChange]);

    useEffect(() => {
      if (onSortChange) {
        onSortChange(sortBy);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortBy]);

    return (
      <div className={clsx({ [styles.tableContainer]: container })}>
        <div className={styles.tableWrapper}>
          <table className={styles.table} {...getTableProps()}>
            <thead className={styles.thead}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps({
                        ...column.getSortByToggleProps(),
                        style: {
                          minWidth: column.minWidth,
                          width: column.width,
                          ...column.getSortByToggleProps().style,
                        },
                      })}
                    >
                      <div className={styles.head}>
                        <p>{column.render('Header')}</p>
                        <InfoPopover info={column.info} />
                        {column.canSort ? (
                          <ArrowDownIcon
                            className={clsx({
                              [styles.ascIcon]: !column.isSortedDesc,
                              [styles.hideSortIcon]: !column.isSorted,
                            })}
                          />
                        ) : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              className={clsx({
                [styles.tbody]: true,
                [styles.denseTbody]: dense,
              })}
              {...getTableBodyProps()}
            >
              {rows.length > 0 && !delayedIsLoading ? (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <TableState
                  isLoading={isLoading}
                  delayedIsLoading={delayedIsLoading}
                  loadingValue={loadingValue}
                  noDataValue={noDataValue}
                />
              )}
            </tbody>
          </table>
        </div>
        {paginationProps ? (
          <>
            {paginationProps.withPageInput ? (
              <PaginationWithPageInput {...paginationProps} />
            ) : (
              <Pagination {...paginationProps} />
            )}
          </>
        ) : null}
      </div>
    );
  }
);

Table.propTypes = {
  container: PropTypes.bool,
  dense: PropTypes.bool,
  isLoading: PropTypes.bool,
  noDataValue: PropTypes.node,
  loadingValue: PropTypes.node,
  columns: PropTypes.array,
  data: PropTypes.array,
  isRowsSelectable: PropTypes.bool,
  onSlectedRowsChange: PropTypes.func,
  onSortChange: PropTypes.func,
  paginationProps: PropTypes.shape({
    currentPage: PropTypes.number,
    changePage: PropTypes.func,
    count: PropTypes.number,
    rowsPerPage: PropTypes.number,
    changeRowsPerPage: PropTypes.func,
    withPageInput: PropTypes.bool,
  }),
};

export default React.memo(Table);
