import React from 'react';
import { Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getProfile } from 'api/influencer/profile';
import { useDialog } from 'stores/dialog';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import {
  blueTickIcon,
  copyText,
  facebookSvg,
  indianRupeeSign,
  instaSvg,
  locationIcon,
  materialDropDown,
  pinkDropIcon,
  profileSummary,
  twitterSvg,
  whiteAddIcon,
  ytIcon,
} from 'constants/images';
import { NavItem } from 'components/common/inf-profile';
import { DIALOGS } from 'constants/config/dialog-names';

const AnalyseSelfProfile = () => {
  const setDialog = useDialog((state) => state.setDialog);

  const { data: influencer } = useQuery({
    queryKey: ['influencer-profile'],
    queryFn: () => getProfile(),
  });

  const onViewCostOverview = () => {
    setDialog(DIALOGS.INF_COST_OVERVIEW);
  };

  const {
    profile_image: profileImage,
    full_name: name,
    state,
    is_registered: isRegistered,
    instagramProfile,
    facebookProfile,
    youtubeProfile,
    twitterProfile,
  } = influencer || {};

  return (
    <div className={styles.influeProfileContainer}>
      <div className={styles.innrProfileAnalysis}>
        <div className={styles.profileLeftContainer}>
          <div className="influencerHeading">
            <h2 className={styles.heading}>Influencer Profile</h2>
          </div>
          <div className={styles.influencerBriefContent}>
            <div className={styles.infInnerBrief}>
              <div className={styles.profileImg}>
                <img className={styles.ellipseImg} src={profileImage} alt="" />
                {/* <div
                  className={styles.subBlock1}
                  style={{ bottom: '-22px', left: '34px' }}
                >
                  <p className={styles.scoreTxt}>Score</p>
                  <div className={styles.subInnrBlock}>
                    <img src={logoImageNew} alt="" width="15" />
                    <span className={styles.spanTxt}>
                      {formatNumber(influencer?.rc_score)}
                    </span>
                  </div>
                </div> */}
              </div>
              <div>
                <h2 className={styles.infName}>
                  {name}{' '}
                  {isRegistered ? (
                    <img
                      src={blueTickIcon}
                      alt="verified"
                      style={{ display: 'inline-block' }}
                    />
                  ) : null}
                </h2>
              </div>
              <div className={styles.userLocation} style={{ margin: '0' }}>
                <img src={locationIcon} alt="" />
                <p className={styles.locTxt}>{state}</p>
              </div>
              {/* <div>
                <p className={styles.passionTxt}>living my dream 🌸</p>
              </div> */}
              <div
                className={styles.userSocialPlaylist}
                style={{ margin: '0' }}
              >
                {instagramProfile?.profileUrl && (
                  <a
                    href={instagramProfile.profileUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className={styles.socialImg} src={instaSvg} alt="" />
                  </a>
                )}
                {twitterProfile?.profileUrl && (
                  <a
                    href={twitterProfile.profileUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className={styles.socialImg} src={twitterSvg} alt="" />
                  </a>
                )}
                {facebookProfile?.profileUrl && (
                  <a
                    href={facebookProfile.profileUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className={styles.socialImg}
                      src={facebookSvg}
                      alt=""
                    />
                  </a>
                )}
                {youtubeProfile?.profileUrl && (
                  <a
                    href={youtubeProfile.profileUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className={styles.socialImg} src={ytIcon} alt="" />
                  </a>
                )}
              </div>
              <div className={styles.profileCategory}>
                {/* {categoriesInf?.map((category, ind) => (
                  <span className={styles.categoryTxt} key={ind}>
                    {category}
                  </span>
                ))} */}
              </div>
              <div className={styles.addBtn}>
                <button
                  type="button"
                  className={styles.addCampaignButton}
                  disabled
                >
                  <img src={whiteAddIcon} alt="" />
                  Add to Campaign
                  <img src={materialDropDown} alt="" />
                </button>

                <button
                  type="button"
                  className={styles.profileBlock3}
                  disabled
                  title="Add to custom list"
                >
                  <img src={copyText} alt="" />
                </button>
              </div>
              <div className={styles.connectBtns}>
                <button
                  type="button"
                  className={styles.commonBtn}
                  onClick={onViewCostOverview}
                  disabled={!isRegistered}
                >
                  <img src={indianRupeeSign} alt="" /> Cost
                  <img src={pinkDropIcon} alt="" />
                </button>
              </div>
            </div>
            <div className="summaryTabs">
              <nav className={styles.summaryTabList}>
                <NavItem
                  to=""
                  title="Profile Summary"
                  iconUrl={profileSummary}
                  end
                />
                {influencer?.is_instagram && (
                  <NavItem
                    to="instagram"
                    title={instagramProfile?.username || name}
                    iconUrl={instaSvg}
                  />
                )}
                {influencer?.is_youtube && (
                  <NavItem
                    to="youtube"
                    title={youtubeProfile?.username || name}
                    iconUrl={ytIcon}
                  />
                )}
                {influencer?.is_twitter && (
                  <NavItem
                    to="twitter"
                    title={twitterProfile?.username || name}
                    iconUrl={twitterSvg}
                  />
                )}
                {influencer?.is_facebook && (
                  <NavItem
                    to="facebook"
                    title={facebookProfile?.username || name}
                    iconUrl={facebookSvg}
                  />
                )}
              </nav>
            </div>
          </div>
        </div>
        <div className={styles.profileRightSummary}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AnalyseSelfProfile;
