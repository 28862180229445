import React from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Footer from 'components/onboarding/Footer';
import OTPInput from 'components/common/OtpInput';
import Button from 'components/common/Button';
import useCountdownTimer from 'hooks/useCountdownTimer';

import { signup, signupVerifyOtp } from 'api/influencer/auth';
import { useInfluencerAuth } from 'stores/auth/influencer';
import { OTP_COUNTDOWN_SECONDS } from 'constants/config';
import routes from 'constants/routes';
import { OTP_SCHEMA } from 'constants/schema';
import { logoImageBlackText } from 'constants/images';
import styles from 'assets/scss/pages/onboarding.module.scss';

const defaultValues = { otp: '' };

function SignUpVerifyOtp() {
  const { timeLeft, setTimeLeft, formattedValue } = useCountdownTimer(
    OTP_COUNTDOWN_SECONDS
  );

  const setToken = useInfluencerAuth((state) => state.setToken);

  const navigate = useNavigate();
  const location = useLocation();
  const { mobile, countryCode, email, name, profile, type } =
    location.state || {};

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(OTP_SCHEMA),
    defaultValues,
  });

  const verifyOtpMutation = useMutation({
    mutationFn: ({ otp }) =>
      signupVerifyOtp({ otp, mobile, country_code: countryCode }),
    onSuccess: (data) => {
      setToken(data?.data?.token);
      navigate(routes.INF_SIGNUP_PROFILE, { state: location.state });
    },
  });

  const resendOtpMutation = useMutation({
    mutationFn: () => {
      const payload = new FormData();
      payload.append('mobile', mobile);
      payload.append('country_code', countryCode);
      payload.append('full_name', name);
      payload.append('email', email);
      payload.append('role', type);
      payload.append('profile_image', profile[0]);

      return signup(payload);
    },
    onSuccess: (data) => {
      setTimeLeft(OTP_COUNTDOWN_SECONDS);
      toast.success(data.message);
    },
  });

  if (!mobile || !countryCode) {
    return <Navigate to={routes.INF_SIGNUP} replace />;
  }

  return (
    <div className={styles.signupInnerContent}>
      <div className={styles.signupItems}>
        <div>
          <div>
            <Link to={routes.HOME}>
              <img src={logoImageBlackText} alt="" width={140} />
            </Link>
          </div>
          <h1>Verify Your Mobile</h1>
          <p>
            We have sent a 6-digit verification code to your mobile. <br />
            Please enter the code below.
          </p>
          <form
            onSubmit={handleSubmit(verifyOtpMutation.mutate)}
            className={styles.signupFormItems}
          >
            <div>
              <div>
                <Controller
                  render={({ field }) => (
                    <OTPInput
                      length={6}
                      onChangeOTP={field.onChange}
                      className={styles.otpVerification}
                    />
                  )}
                  control={control}
                  name="otp"
                />
                {errors?.otp ? (
                  <p className={styles.error}>{errors.otp?.message}</p>
                ) : null}
              </div>

              <div className={styles.resentBtn}>
                <button
                  type="button"
                  onClick={resendOtpMutation.mutate}
                  disabled={timeLeft > 0}
                >
                  Resend code
                </button>
                <p>{formattedValue}</p>
              </div>
            </div>
            <Button
              type="submit"
              size="large"
              fullWidth
              isLoading={verifyOtpMutation.isLoading}
            >
              Verify & Proceed
            </Button>
          </form>
          <p className={styles.signupLink}>
            Already have an account?{' '}
            <Link to={routes.INF_LOGIN}>Sign in here</Link>
          </p>
        </div>
      </div>
      <Footer type="influencer" />
    </div>
  );
}

export default SignUpVerifyOtp;
