export const uinqueCategories = (categoriesData) => {
  const categories = categoriesData
    ?.map((data) => data?.category?.category_name)
    .filter(
      (categoryName, index, currentVal) =>
        currentVal.indexOf(categoryName) === index && categoryName
    );
  return categories;
};

export const uinqueProfileCategories = (categoriesData) => {
  const categories = categoriesData
    ?.map((data) => data?.subcategory?.category?.category_name)
    .filter(
      (categoryName, index, currentVal) =>
        currentVal.indexOf(categoryName) === index && categoryName
    );
  return categories;
};
