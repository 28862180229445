import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import { brandIcon, contentIcon } from 'constants/images';
import Tooltip from 'components/common/Tooltip';
import { NoData } from 'components/common/NoData';
import { useDialog } from 'stores/dialog';
import { DIALOGS } from 'constants/config/dialog-names';

const brandCategories = [
  'All',
  // 'Watches And Eyewear',
  // 'Non-Alcoholic Beverages',
  // 'Device',
  // 'Electronic Appliances',
  // 'Apparels',
  // 'Tv Channels',
  // 'Cosmetics',
  // 'E-Commerce',
  // 'FMCG',
  // 'Banking and Finance',
  // 'Telecommunication',
  // 'Alcohol',
];

const BrandsSection = ({ brands, platform, variant, influencerId }) => {
  const setDialog = useDialog((state) => state.setDialog);

  const formattedBrands = useMemo(
    () =>
      brands
        ?.map((brand) => {
          if (typeof brand === 'string') {
            return { logo: '', name: brand, post_count: 0 };
          }

          return brand;
        })
        ?.filter((brand) => brand.post_count > 0),
    [brands]
  );

  const handleViewBrandPosts = (brandName) => {
    if (platform) {
      setDialog(
        variant === 'brand'
          ? DIALOGS.BRAND_INF_BRAND_POSTS
          : DIALOGS.INF_BRAND_POSTS,
        {
          influencerId,
          brandName,
          platform,
        }
      );
    }
  };

  return (
    <div className={styles.navCategory}>
      <div className={styles.sectionHeader}>
        <img src={brandIcon} alt="" />
        <h2 className={styles.contntTxt}>Brands</h2>
      </div>
      <div className={styles.sectionBody}>
        <div className={styles.sectioncategoryHeader}>
          <h3 className={styles.contcatTxt}>brand mentions</h3>
          <Tooltip label="Brands mentioned or associated with the influencers" />
        </div>
        <div className={styles.brandPartnerTypeTab}>
          <ul className={styles.brandTypeList}>
            {brandCategories.map((category) => (
              <li className={styles.brandSubList} key={category}>
                {category}
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.partnerList}>
          {formattedBrands?.length > 0 ? (
            formattedBrands.map(({ logo, name, post_count: postCount }) => (
              <div className={styles.brandCard} key={name}>
                <div className={styles.cardHeader}>
                  <img
                    src={logo || contentIcon}
                    alt=""
                    width="84"
                    height="84"
                    className={styles.brandLogo}
                  />
                </div>
                <div className={styles.cardBody}>
                  <h5 className={styles.patnrName}>{name}</h5>
                  <h6 className={styles.patnrHandle}>@{name}</h6>
                </div>
                <div className={styles.cardFooter}>
                  <button
                    type="button"
                    className={styles.patnrCollabPostCount}
                    onClick={() => handleViewBrandPosts(name)}
                    style={{ cursor: platform ? 'pointer' : 'default' }}
                  >
                    {postCount} posts
                  </button>
                </div>
              </div>
            ))
          ) : (
            <NoData
              height={70}
              style={{ minHeight: 0 }}
              description="We don't have brands data available currently."
            />
          )}
        </div>
      </div>
    </div>
  );
};

BrandsSection.propTypes = {
  brands: PropTypes.array,
  platform: PropTypes.string,
  variant: PropTypes.oneOf(['brand', 'influencer']),
  influencerId: PropTypes.string,
};

export default BrandsSection;

// NOTE: following code is for refrence to combine brans
// function combineBrands(arr) {
//   const brands = {};
//   arr.forEach((brand) => {
//     if (typeof brand === 'object') {
//       if (brand.name in brands) {
//         brands[brand.name].post_count += brand.post_count;
//       }
//       brands[brand.name] = brand;
//     }
//   });
//   return Object.values(brands);
// }
// switch (platform) {
//   case 'instagram':
//     return instagramData?.brands;
//   case 'youtube':
//     return youtubeData?.brands;
//   case 'facebook':
//     return facebookData?.brands;
//   case 'twitter':
//     return twittereData?.brands;
//   default:
//     if (
//       instagramData?.brands?.length > 0 ||
//       youtubeData?.brands?.length > 0 ||
//       twittereData?.brands?.length > 0 ||
//       facebookData?.brands?.length > 0
//     ) {
//       const mergeBrands = [
//         ...(instagramData?.brands || []),
//         ...(youtubeData?.brands || []),
//         ...(twittereData?.brands || []),
//         ...(facebookData?.brands || []),
//       ];
//       return combineBrands(mergeBrands);
//     }
//     return [];
// }
