import { useMemo } from 'react';
import orderBy from 'lodash/orderBy';

const useFormatInstaCityData = (audienceByCity = {}) => {
  const formattedData = useMemo(() => {
    let total = 0;
    const cities = [];
    const states = [];

    if (audienceByCity) {
      Object.keys(audienceByCity).forEach((key) => {
        const value = Number(audienceByCity[key]);
        const [city, state] = key.split(', ');

        total += value;

        cities.push({ name: city, value });
        states.push({ name: state, value });
      });
    }

    const citiesWithPercentage = cities.map((city) => ({
      ...city,
      percentage: Number(((city.value * 100) / total).toFixed(2)),
    }));

    const statesWithPercentage = cities.map((state) => ({
      ...state,
      percentage: Number(((state.value * 100) / total).toFixed(2)),
    }));

    return {
      audienceByCity: orderBy(citiesWithPercentage, ['percentage'], ['desc']),
      audienceByState: orderBy(statesWithPercentage, ['percentage'], ['desc']),
    };
  }, [audienceByCity]);
  return formattedData;
};

export default useFormatInstaCityData;
