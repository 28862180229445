import React, { useEffect, useRef } from 'react';
import { animate, motion } from 'framer-motion';

const B2bIntrestInInfluencerMarketing = ({ outlined = false, props }) => {
  const numberRef = useRef();

  useEffect(() => {
    const node = numberRef.current;
    if (!node) return;

    const controls = animate(10, 75, {
      duration: 1,
      delay: 1,
      onUpdate(value) {
        node.textContent = `${Math.round(value).toString()}%`;
      },
    });

    // eslint-disable-next-line consistent-return
    return () => controls.stop();
  }, []);

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width={210}
      height={209}
      {...props}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.7 }}
    >
      <defs>
        <filter
          id="b2bInterestFilter1"
          width={210}
          height={209}
          x={0}
          y={0}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={4} dy={5} />
          <feGaussianBlur result="blur" stdDeviation={5} />
          <feFlood floodColor="#e79ce3" floodOpacity={0.502} />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="b2bInterestFilter3"
          width={106}
          height={107}
          x={48}
          y={46}
          filterUnits="userSpaceOnUse"
        >
          <feOffset />
          <feGaussianBlur result="blur-2" stdDeviation={7.5} />
          <feFlood floodColor="#ee18e3" floodOpacity={0.502} result="color" />
          <feComposite in="SourceGraphic" in2="blur-2" operator="out" />
          <feComposite in="color" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <linearGradient
          id="b2bInterestFilter2"
          x2={0.984}
          y1={0.5}
          y2={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.607} stopColor="#b584ce" stopOpacity={0.149} />
          <stop offset={1} stopColor="#641582" stopOpacity={0.8} />
        </linearGradient>
      </defs>
      <g data-name="Group 55293">
        <g filter="url(#b2bInterestFilter1)" transform="translate(-.004)">
          <g
            fill="url(#b2bInterestFilter2)"
            stroke="rgba(255,255,255,0.3)"
            strokeWidth={1.5}
            data-name="Ellipse 1524"
            transform="translate(11 10)"
          >
            <ellipse cx={90} cy={89.5} stroke="none" rx={90} ry={89.5} />
            <ellipse
              cx={90}
              cy={89.5}
              fill="none"
              rx={89.25}
              ry={88.75}
              stroke={outlined ? '#f8f9fa' : ''}
            />
          </g>
        </g>
        <g data-type="innerShadowGroup">
          <g filter="url(#b2bInterestFilter3)" transform="translate(-.004)">
            <ellipse
              cx={53}
              cy={53.5}
              fill="#fff"
              data-name="Ellipse 1525"
              rx={53}
              ry={53.5}
              transform="translate(48 46)"
            />
          </g>
          <g
            fill="none"
            stroke="rgba(255,255,255,0.3)"
            data-name="Ellipse 1525"
            transform="translate(48 46)"
          >
            <ellipse
              cx={53}
              cy={53.5}
              rx={53}
              ry={53.5}
              stroke={outlined ? '#f8f9fa' : 'none'}
            />
            <ellipse cx={53} cy={53.5} rx={52.5} ry={53} />
          </g>
        </g>
        <g
          fill="none"
          stroke="#ec40f1"
          strokeDasharray="420 1000"
          strokeWidth={37}
          data-name="Ellipse 1526"
          transform="translate(11 10)"
        >
          <ellipse cx={90} cy={89.5} stroke="none" rx={90} ry={89.5} />
          <motion.ellipse
            cx={90}
            cy={89.5}
            rx={71.5}
            ry={71}
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 0.75 }}
            transition={{ duration: 1, delay: 1 }}
            transform="rotate(-90, 90, 89.5)"
          />
        </g>
        <text
          fontSize={36}
          fontWeight={700}
          fill="#ffffff"
          transform="translate(67 115)"
        >
          <tspan ref={numberRef} />
        </text>
      </g>
    </motion.svg>
  );
};
export default B2bIntrestInInfluencerMarketing;
