import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSpinDelay } from 'spin-delay';
import { getProfile } from 'api/brand/profile';
import { useBrandAuth } from 'stores/auth/brand';
import { LoaderWithWrapper } from 'components/common/Loader';
import routes from 'constants/routes';
import { Error } from 'components/common/Error';

function BrandAuthGuard({
  children,
  checkAgencyName = true,
  checkForAddress = true,
}) {
  const token = useBrandAuth((state) => state.token);
  const setUserData = useBrandAuth((state) => state.setUserData);

  const {
    data: user,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ['brand-profile'],
    queryFn: () => getProfile(),
    select: (res) => res?.data?.getProfile,
    onSuccess: (profileData) => {
      if (profileData) {
        setUserData(profileData);
      }
    },
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  if (!token) {
    return <Navigate to={routes.BRAND_LOGIN} replace />;
  }

  if (delayedIsLoading || isLoading) {
    return <LoaderWithWrapper show={delayedIsLoading} />;
  }

  if (isError) {
    return <Error retry={refetch} errorMessage={error?.message} />;
  }

  if (checkAgencyName && !user?.agency_name) {
    return <Navigate to={routes.BRAND_SIGNUP_PROFILE} replace />;
  }

  if (checkForAddress && !user?.addresses?.length > 0) {
    return <Navigate to={routes.BRAND_SIGNUP_ADD_ADDRESS} replace />;
  }

  return children;
}

BrandAuthGuard.propTypes = {
  children: PropTypes.node,
  checkAgencyName: PropTypes.bool,
  checkForAddress: PropTypes.bool,
};

export default BrandAuthGuard;
