import React, { useId } from 'react';
import PropTypes from 'prop-types';
import styles from 'assets/scss/pages/popup.module.scss';
import ErrorMessage from '../form/ErrorMessage';

const Input = React.forwardRef(
  (
    {
      label,
      error,
      endAdornment,
      startAdornment,
      ismobile,
      wrapperClass = styles.formGroup,
      as: Element = 'input',
      ...rest
    },
    ref
  ) => {
    const inputId = useId();
    const errorId = useId();
    return (
      <div className={wrapperClass}>
        {label ? <label className={styles.formLabel}>{label}</label> : null}
        <div className={styles.formInputWrapper}>
          {startAdornment && (
            <div className={styles.formInputStartAdorment}>
              {startAdornment}
            </div>
          )}
          <Element
            type="text"
            maxLength="40"
            {...rest}
            className={styles.formInput}
            style={{ paddingLeft: ismobile === 'mobile' && '85px' }}
            ref={ref}
            id={inputId}
            aria-invalid={error ? 'true' : 'false'}
            aria-describedby={errorId}
          />
          {endAdornment && (
            <div className={styles.formInputEndAdorment}>{endAdornment}</div>
          )}
        </div>
        <ErrorMessage error={error} id={errorId} />
      </div>
    );
  }
);

Input.propTypes = {
  label: PropTypes.node,
  error: PropTypes.object,
  endAdornment: PropTypes.node,
  startAdornment: PropTypes.node,
  wrapperClass: PropTypes.string,
  ismobile: PropTypes.string,
  as: PropTypes.elementType,
};

export default Input;
