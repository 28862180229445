import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getBrandInfluencers } from 'api/brand/brandAnalysis';
import { Avatar } from 'components/common/Avatar';
import Table from 'components/common/Table';
import Menu from 'components/common/Menu';
import TableError from 'components/common/Table/TableError';
import styles from 'assets/scss/pages/search.module.scss';
import { ReactComponent as StatsIcon } from 'assets/images/stats-icon.svg';
import { ReactComponent as MoreIcon } from 'assets/images/more-icon.svg';
import { facebookSvg, instaSvg, twitterSvg, ytIcon } from 'constants/images';
import { useDialog } from 'stores/dialog';
import { DIALOGS } from 'constants/config/dialog-names';
import routes from 'constants/routes';
import useFilterProps from 'hooks/useFilterProps';
import { formatNumber } from 'utils';

const columns = [
  {
    Header: 'Profile',
    accessor: 'name',
    width: 270,
    minWidth: 270,
    Cell: (cellInfo) => (
      <Link
        to={`${routes.BRAND_SEARCH_INF}/${cellInfo?.row?.original?.id}`}
        className={styles.profileDiv}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Avatar
          src={cellInfo?.row?.original?.profileImage}
          alt=""
          size="large"
        />
        <p>{cellInfo?.row?.original?.name}</p>
        <span />
      </Link>
    ),
  },
  {
    Header: 'Platform',
    accessor: 'platform',
    width: 60,
    minWidth: 60,
    Cell: (cellInfo) => {
      const getIcon = () => {
        switch (cellInfo?.row?.original?.platform?.toLowerCase()) {
          case 'youtube':
            return ytIcon;
          case 'instagram':
            return instaSvg;
          case 'twitter':
            return twitterSvg;
          case 'facebook':
            return facebookSvg;
          default:
            return null;
        }
      };
      return <Avatar src={getIcon()} alt="" />;
    },
  },
  // {
  //   Header: 'SIMINCO Scrore',
  //   accessor: 'smincoScore',
  //   info: 'Platform Score',
  //   Cell: (cellInfo) => {
  //     const row = cellInfo?.row?.original;
  //     return (
  //       <p className={styles.downloadedTxt}>{formatNumber(row?.smincoScore)}</p>
  //     );
  //   },
  // },
  {
    Header: 'Categories',
    accessor: 'categories',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;

      const categoriesCount = row?.categories?.length;
      return (
        <>
          {categoriesCount > 0 && (
            <div className={styles.catgryDiv}>
              {categoriesCount > 0 && (
                <span className={styles.catgrySpan}>{row.categories[0]}</span>
              )}
              {categoriesCount > 1 ? (
                <p className={styles.catgryTxt}>+{categoriesCount - 1} More</p>
              ) : null}
            </div>
          )}
        </>
      );
    },
  },
  {
    Header: 'Followers',
    accessor: 'followers_count',
    disableSortBy: false,
    Cell: (cellInfo) => (
      <p className={styles.downloadedTxt}>
        {formatNumber(cellInfo?.row?.original?.followers)}
      </p>
    ),
  },
  {
    Header: 'Avg Likes',
    accessor: 'avg_likes',
    Cell: (cellInfo) => (
      <p className={styles.downloadedTxt}>
        {formatNumber(cellInfo?.row?.original?.avgLikes)}
      </p>
    ),
  },
  {
    Header: 'Avg Views',
    accessor: 'avgViews',
    Cell: (cellInfo) => formatNumber(cellInfo?.row?.original?.avgViews),
  },
  {
    Header: 'ER',
    accessor: 'engagementRate',
    info: 'Engagement Rate',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return (
        <p className={styles.downloadedTxt}>
          {formatNumber(row?.avgEngagementRate)}%
        </p>
      );
    },
  },
  {
    Header: 'Location',
    accessor: 'location',
    Cell: (cellInfo) => (
      <p className={styles.downloadedTxt}>
        {cellInfo?.row?.original?.location}
      </p>
    ),
  },
  {
    Header: '',
    accessor: 'options',
    width: 20,
    Cell: (cellInfo) => {
      const setDialog = useDialog((state) => state.setDialog);
      return (
        <Menu
          renderButton={() => (
            <Menu.Button>
              <MoreIcon />
            </Menu.Button>
          )}
          options={[
            {
              label: (
                <>
                  <StatsIcon />
                  <span>Preview</span>
                </>
              ),
              onClick: () =>
                setDialog(DIALOGS.BRAND_INF_PROFILE_OVERVIEW, {
                  influencerId: cellInfo?.row?.original?.id,
                  platform: cellInfo?.row?.original?.platform,
                }),
            },
          ]}
        />
      );
    },
  },
];

const InfluencerList = () => {
  const { id: brandId, platform } = useParams();
  const { page, rowsPerPage, changePage, changeRowsPerPage } = useFilterProps();
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      'brand-analysis-influencers',
      brandId,
      platform,
      page,
      rowsPerPage,
    ],
    queryFn: () =>
      getBrandInfluencers(brandId, {
        pageNo: page,
        pageSize: rowsPerPage,
        media_type: platform,
      }),
  });

  const influencers = useMemo(
    () =>
      data?.result?.map((influencer) => {
        const formattedInfluencer = {};
        const instagramData = influencer?.influencer_instagram;
        const youtubeData = influencer?.influencer_youtube;
        const facebookData = influencer?.influencer_facebook;
        const twitterData = influencer?.influencer_twitter;

        switch (influencer?.media_type?.toLowerCase()) {
          case 'instagram':
            formattedInfluencer.id = instagramData?.influencerId;
            formattedInfluencer.profileImage = instagramData?.profile_image_url;
            formattedInfluencer.name = instagramData?.name;
            formattedInfluencer.handle = instagramData?.username;
            formattedInfluencer.smincoScore = instagramData?.sminco_score;
            formattedInfluencer.platform = 'instagram';
            formattedInfluencer.followers = instagramData?.followers_count;
            formattedInfluencer.avgLikes = instagramData?.media_stat?.avg_likes;
            formattedInfluencer.avgEngagementRate =
              instagramData?.avg_enagagement_rate;
            formattedInfluencer.avgComments =
              instagramData?.media_stat?.avg_comments;
            formattedInfluencer.likesVsComments =
              instagramData?.media_stat?.like_vs_comments;
            formattedInfluencer.location =
              instagramData?.influencer?.addresses?.[0]?.cit;
            break;
          case 'youtube':
            formattedInfluencer.id = youtubeData?.influencerId;
            formattedInfluencer.profileImage = youtubeData?.profile_image_url;
            formattedInfluencer.name =
              youtubeData?.name || youtubeData?.nickname;
            formattedInfluencer.handle = youtubeData?.handle;
            formattedInfluencer.smincoScore = youtubeData?.sminco_score;
            formattedInfluencer.platform = 'youtube';
            formattedInfluencer.followers = youtubeData?.subscriber_count;
            formattedInfluencer.avgLikes = youtubeData?.avg_likes;
            formattedInfluencer.avgEngagementRate =
              youtubeData?.avg_enagagement_rate;
            formattedInfluencer.avgComments =
              youtubeData?.media_stat?.avg_comments;
            formattedInfluencer.avgViews = youtubeData?.media_stat?.avg_views;
            formattedInfluencer.likesVsComments =
              youtubeData?.media_stat?.likes_vs_comments;
            formattedInfluencer.location =
              youtubeData?.influencer?.addresses?.[0]?.city;
            break;
          // TODO: add facebook and twitter values once that is added
          case 'facebook':
            formattedInfluencer.id = facebookData?.influencerId;
            formattedInfluencer.smincoScore = facebookData?.sminco_score;
            break;
          case 'twitter':
            formattedInfluencer.id = twitterData?.influencerId;
            formattedInfluencer.smincoScore = twitterData?.sminco_score;
            break;
          default:
            break;
        }

        return formattedInfluencer;
      }),
    [data]
  );

  return (
    <>
      <div className={styles.influencerHeader}>
        <Table
          data={influencers}
          columns={columns}
          isLoading={isLoading || isFetching}
          noDataValue={<TableError infoText="No Influencers Found." />}
          paginationProps={{
            currentPage: page,
            changePage,
            count: data?.count || 0,
            rowsPerPage,
            changeRowsPerPage,
            rowsPerPageOptions: [10, 30, 50],
          }}
        />
      </div>
    </>
  );
};

export default InfluencerList;
