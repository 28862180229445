/**
 * Format a number eg: 12223 -> 12.2K etc.
 * @param {string | number} number number that needs to be formatted
 * @param {object} object options object
 * @returns formatted string
 */

export const formatNumber = (
  number,
  { maximumFractionDigits = 1, notation = 'compact', ...rest } = {}
) =>
  number
    ? Intl.NumberFormat('en-US', {
        notation,
        maximumFractionDigits,
        ...rest,
      }).format(number)
    : 0;
