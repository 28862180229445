import React, { useMemo, useState } from 'react';
import printJS from 'print-js';
import { toast } from 'react-toastify';
import { cogwheelIcon, featherDownloadWhiteImg } from 'constants/images';
import FilterDropdown from 'components/common/FilterDropdown';
import { FullPageLoader } from 'components/common/Loader';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCampaignDetails, getCampaignLiveTrack } from 'api/brand/campaign';
import { CampaignReport } from 'components/campaign-report';
import errorMessages from 'constants/config/error-messagess';
import useFormatCampaignAnalyticsData from 'hooks/useFormatCampaignAnalyticsData';

const CampaignReports = () => {
  const [renderExport, setRenderExport] = useState(false);
  const [postingsFilter, setPostingsFilter] = useState('daily');
  const [platformFilter, setPlatformFilter] = useState('all');
  const { id: campaignId } = useParams();

  const { data: campaign } = useQuery({
    queryKey: ['brand-campaign-details', campaignId],
    queryFn: () => getCampaignDetails({ campaignId }),
    select: (res) => res?.data?.campaign,
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      'brand-campaign-deliverables',
      campaignId,
      platformFilter,
      postingsFilter,
    ],
    queryFn: () =>
      getCampaignLiveTrack(
        { campaignId, platform: platformFilter },
        { data_filter: postingsFilter }
      ),
    select: (res) => res?.data,
  });

  const {
    totalPosts,
    totalViews,
    totalLikes,
    totalComments,
    engagementRate,
    totalInfluencers,
    audienceEngRate,
    influencerPerformance,
    campaignPerformance,
    hashtags,
    posts,
  } = useFormatCampaignAnalyticsData({ trackingData: data?.trackingData });

  const tragetPlatforms = useMemo(() => {
    const availablePlatforms = campaign?.target_platforms?.map(
      (trgtPlatform) => {
        if (trgtPlatform === 'instagram') {
          return {
            label: 'Instagram',
            value: trgtPlatform,
          };
        }
        if (trgtPlatform === 'youtube') {
          return {
            label: 'Youtube',
            value: trgtPlatform,
          };
        }
        if (trgtPlatform === 'twitter') {
          return {
            label: 'Twitter',
            value: trgtPlatform,
          };
        }
        if (trgtPlatform === 'facebook') {
          return {
            label: 'Facebook',
            value: trgtPlatform,
          };
        }

        return [];
      }
    );
    return [
      {
        label: 'All',
        value: 'all',
      },
      ...availablePlatforms,
    ];
  }, [campaign]);

  const handleDownladReport = () => {
    setRenderExport(true);
    setTimeout(() => {
      printJS({
        printable: 'section-to-print',
        type: 'html',
        scanStyles: false,
        documentTitle: `Campaign Report ${platformFilter} Platform`,
        css: ['/campaign-report.css', '/normalize.css'],
        onError: (error) => console.log(error),
        onIncompatibleBrowser: () =>
          toast.error(errorMessages.INCOMPATIBLE_BROWSER),
        onLoadingEnd: () => setRenderExport(false),
      });
    }, 500);
  };

  return (
    <section>
      <div className="reportContainer">
        <div className="reportHeader">
          <FilterDropdown
            options={tragetPlatforms}
            label="Platform"
            multiple={false}
            startIconUrl={cogwheelIcon}
            onChange={setPlatformFilter}
            value={platformFilter}
          />
          <div className="headerActions">
            <button
              type="button"
              className="actionBtn"
              onClick={handleDownladReport}
            >
              <img src={featherDownloadWhiteImg} alt="" /> Download Report
            </button>
            {/* <button type="button" className={styles.addList}>
                  <img src={shareIcon} alt="" /> Share Report
                </button> */}
          </div>
        </div>
        <CampaignReport
          varient="brand"
          insights={{
            totalPosts,
            totalViews,
            totalLikes,
            totalComments,
            engagementRate,
            totalInfluencers,
          }}
          postingsData={{
            data: data?.insightsData || [],
            hashtags,
            filter: postingsFilter,
            changeFilter: setPostingsFilter,
          }}
          audienceEngagementRateData={audienceEngRate}
          influencerPerformanceData={influencerPerformance}
          campaignPerformanceData={campaignPerformance}
          posts={posts}
        />
        {renderExport && (
          <div className="sectionForExport">
            <CampaignReport
              isExporting
              varient="brand"
              insights={{
                totalPosts,
                totalViews,
                totalLikes,
                totalComments,
                engagementRate,
                totalInfluencers,
              }}
              postingsData={{
                data: data?.insightsData || [],
                hashtags,
                filter: postingsFilter,
                changeFilter: setPostingsFilter,
              }}
              audienceEngagementRateData={audienceEngRate}
              influencerPerformanceData={influencerPerformance}
              campaignPerformanceData={campaignPerformance}
              posts={posts}
            />
          </div>
        )}
      </div>
      <FullPageLoader
        show={renderExport}
        loadingText="Exporting report, please wait..."
      />
    </section>
  );
};

export default CampaignReports;
