import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { Player } from '@lottiefiles/react-lottie-player';
import successAnimation from 'assets/lottie/success.json';

import Button from 'components/common/Button';
import routes from 'constants/routes';
import styles from './PasswordResetSuccessDialog.module.scss';

const PasswordResetSuccessDialog = ({ isOpen = false, onClose }) => {
  const navigate = useNavigate();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className={styles.containerhoverlay}>
        <div className={styles.customInner}>
          <div className={styles.container_content}>
            <Dialog.Panel>
              <div className={styles.reviewImage}>
                <Player autoplay keepLastFrame src={successAnimation} />
              </div>

              <Dialog.Title className={styles.popupTitle}>
                Congratulations!
              </Dialog.Title>
              <Dialog.Description className={styles.popupDesc}>
                You have successfully reset your password.
              </Dialog.Description>
              <Button
                style={{
                  maxWidth: '538px',
                  margin: '0px auto 10px auto',
                  display: 'block',
                }}
                fullWidth
                onClick={() => navigate(routes.INF_LOGIN)}
              >
                Login
              </Button>
            </Dialog.Panel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

PasswordResetSuccessDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PasswordResetSuccessDialog;
