import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Sector,
  Legend,
} from 'recharts';

// const data = [
//   {
//     id: 4,
//     platform: 'Youtube',
//     audience: 25,
//     color: '#E289F2',
//   },
//   {
//     id: 3,
//     platform: 'Twitter',
//     audience: 10,
//     color: '#7F60FF',
//   },
//   {
//     id: 2,
//     platform: 'Facebook',
//     audience: 15,
//     color: '#FF00A2',
//   },
//   {
//     id: 1,
//     platform: 'Instagram',
//     audience: 50,
//     color: '#7C087F',
//   },
// ];

const AudienceEngagementRatesPieChart = ({ trackingData }) => {
  const [pieActiveIndex, setPieActiveIndex] = useState(0);
  const onPieEnter = (_, index) => {
    setPieActiveIndex(index);
  };
  return (
    <div className="analyticsSection">
      <div className="header">
        <h2 className="title">audience engagement rates insights</h2>

        {/* <div className={styles.benchmarkSectionView}>
          <select className={styles.selctMenu}>
            <option value="value">All</option>
          </select>
          <select className={styles.selctMenu}>
            <option value="value">Entertainment</option>
          </select>
        </div> */}
      </div>
      <div className="contentWrapper">
        <ResponsiveContainer width="100%" height={224}>
          <PieChart width={216} height={216}>
            <Pie
              activeIndex={pieActiveIndex}
              activeShape={renderCustomizedLabelPieChart}
              dataKey="engagementRate"
              nameKey="platform"
              data={trackingData}
              innerRadius={50}
              outerRadius={100}
              fill="#7C087F"
              startAngle={90}
              endAngle={550}
              isAnimationActive={false}
              labelLine={false}
              onMouseEnter={onPieEnter}
            >
              {trackingData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry?.color || undefined}
                  style={{ outline: 'none' }}
                />
              ))}
            </Pie>
            <Legend
              iconType="circle"
              iconSize={10}
              align="right"
              verticalAlign="middle"
              layout="vertical"
              formatter={renderLegendText}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const renderLegendText = (value) => (
  <span
    style={{
      color: '#999E9D',
      fontSize: 12,
      letterSpacing: 1,
      fontWeight: 500,
      marginLeft: 8,
    }}
  >
    {value}
  </span>
);

const renderCustomizedLabelPieChart = (props) => {
  const {
    cx,
    cy,
    value,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  } = props;
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        textAnchor="middle"
      />
      <text
        textAnchor="middle"
        x={cx}
        y={cy + 9}
        fill={fill}
        fontSize={18}
        fontWeight={600}
      >
        {value}%
      </text>
    </g>
  );
};

AudienceEngagementRatesPieChart.propTypes = {
  trackingData: PropTypes.array,
};

export default AudienceEngagementRatesPieChart;
