import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink, Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getProfile } from 'api/brand/profile';
import { useBrandAuth } from 'stores/auth/brand';
import { useDialog } from 'stores/dialog';
import { queryClient } from 'App';
import { AlertDialog } from 'components/common/dialog';
import styles from 'assets/scss/pages/profile-dashboard.module.scss';
import routes from 'constants/routes';
import {
  editIcon,
  // redCross,
  profileLogout,
  purplePlusSign,
} from 'constants/images';
import { DIALOGS } from 'constants/config/dialog-names';

const NavItem = ({ children, ...props }) => (
  <NavLink
    className={({ isActive }) =>
      clsx({
        [styles.listTerm]: true,
        [styles.activeList]: isActive,
      })
    }
    {...props}
  >
    {children}
  </NavLink>
);

NavItem.propTypes = {
  children: PropTypes.node,
};

const Profile = () => {
  const reset = useBrandAuth((state) => state.reset);

  const onLogout = () => {
    reset();
    queryClient.clear();
  };

  return (
    <section>
      <div className={styles.profileDashboard}>
        <div className={styles.myProfileColumn}>
          <div className={styles.profileHeader}>
            <h2 className={styles.profileTxt}>My Profile</h2>
            {/* <button
              type="button"
              className={styles.editBtn}
              onClick={onEditProfile}
            >
              Edit
            </button> */}
          </div>
          <ProfileInfo />
        </div>
        <div className={styles.profileSettingcolumn}>
          <div className={styles.settingborder}>
            <nav className={styles.settingList}>
              <NavItem to="" end>
                Setting
              </NavItem>
              {/* <NavItem to={routes.BRAND_PROFILE_SUBSCRIPTION}>
                Subscription
              </NavItem> */}
              {/* <NavItem to={routes.BRAND_PROFILE_CAMPAIGN_REPORTS}>
                My Campaign Report
              </NavItem> */}
            </nav>
            <div className={styles.logoutBtn}>
              <AlertDialog
                render={({ open }) => (
                  <button
                    onClick={open}
                    type="button"
                    className={styles.profileLogoutBtn}
                  >
                    <img src={profileLogout} alt="" />
                    Logout
                  </button>
                )}
                title="Logout"
                description="Are you sure you want to logout?"
                agreeBtnText="Logout"
                disagreeBtnText="Close"
                postion="top-center"
                onAgree={onLogout}
              />
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </section>
  );
};

const ProfileInfo = () => {
  const setDialog = useDialog((state) => state.setDialog);
  const setUserData = useBrandAuth((state) => state.setUserData);

  const { data } = useQuery({
    queryKey: ['brand-profile'],
    queryFn: () => getProfile(),
    onSuccess: (data) => {
      setUserData(data?.data?.getProfile);
    },
  });

  const onEditProfile = () => {
    setDialog(DIALOGS.BRAND_EDIT_PROFILE);
  };

  const onEditAddress = (addressId) => {
    setDialog(DIALOGS.BRAND_EDIT_ADDRESS, { addressId });
  };

  const onEditCategory = () => {
    setDialog(DIALOGS.BRAND_EDIT_PROFILE_CATEGORIES);
  };

  const onEditPreference = () => {
    setDialog(DIALOGS.BRAND_EDIT_PROFILE_PREFERENCES);
  };

  const onEditGstDoc = () => {
    setDialog(DIALOGS.BRAND_EDIT_GST_DOC);
  };

  const onEditPanDoc = () => {
    setDialog(DIALOGS.BRAND_EDIT_PAN_DOC);
  };

  const {
    first_name: firstName,
    last_name: lastName,
    // profile_status: profileStatus,
    mobile,
    email,
    // designation,
    // company_role: role,
    pan_card_no: panNo,
    gst_no: gstNo,
    addresses,
    upload_gst: gstDoc,
    pan_card_upload: panDoc,
    category_data: categories,
    preference_data: preferences,
    ro_contact_person: roContactPerson,
    ro_contact_number: roContactNumber,
  } = data?.data?.getProfile || {};

  return (
    <div className={styles.profileInformation}>
      <div className="profileForm">
        <div className={styles.profileHeader}>
          <h3 className={styles.headingTxt}>Profile Information</h3>
          <button type="button" title="edit" onClick={onEditProfile}>
            <img src={editIcon} alt="edit" />
          </button>
        </div>
        <div className="detailsection">
          <div className={styles.detailInner}>
            <label className={styles.labelTxt}>Full Name</label>
            <p className={styles.subTxt}>
              {firstName ? `${firstName} ${lastName}` : '-'}
            </p>
          </div>
          <div className={styles.detailInner}>
            <label className={styles.labelTxt}>Email Id</label>
            <p className={styles.subTxt}>{email || '-'}</p>
          </div>
          <div className={styles.detailInner}>
            <label className={styles.labelTxt}>Mobile Number</label>
            <p className={styles.subTxt}>+91 {mobile || '-'}</p>
          </div>
          <div className={styles.detailInner}>
            <label className={styles.labelTxt}>RO Contact Person</label>
            <p className={styles.subTxt}>{roContactPerson || '-'}</p>
          </div>
          <div className={styles.detailInner}>
            <label className={styles.labelTxt}>RO Contact Number</label>
            <p className={styles.subTxt}>+91 {roContactNumber || '-'}</p>
          </div>
        </div>
      </div>
      <div className="profileForm">
        <div className={styles.profileHeader}>
          <h3 className={styles.headingTxt}>GST Document Details</h3>
          <button type="button" title="edit" onClick={onEditGstDoc}>
            <img src={editIcon} alt="edit" />
          </button>
        </div>
        <div className="detailsection">
          <div className={styles.panFileUpload}>
            <div className={styles.detailInner}>
              <label className={styles.labelTxt}>GST Number</label>
              <p className={styles.subTxt}>{gstNo || '-'}</p>
            </div>
            <h3 className={styles.gstJpgFile}>
              {gstDoc?.split('/')?.slice(-1)?.[0]?.split('?')?.[0] || ''}
            </h3>
          </div>
        </div>
      </div>
      <div className="profileForm">
        <div className={styles.profileHeader}>
          <h3 className={styles.headingTxt}>PAN Card Details</h3>
          <button type="button" title="edit" onClick={onEditPanDoc}>
            <img src={editIcon} alt="edit" />
          </button>
        </div>
        <div className="detailsection">
          <div className={styles.panFileUpload}>
            <div className={styles.detailInner}>
              <label className={styles.labelTxt}>PAN Number</label>
              <p className={styles.subTxt}>{panNo || '-'}</p>
            </div>
            <h3 className={styles.gstJpgFile}>
              {panDoc?.split('/')?.slice(-1)?.[0]?.split('?')?.[0] || ''}
            </h3>
          </div>
        </div>
      </div>
      <div className="profileForm">
        <div className={styles.profileHeader}>
          <h3 className={styles.headingTxt}>Addresses</h3>
          <button
            type="button"
            title="Add address"
            onClick={onEditAddress}
            className={styles.addButton}
          >
            <img src={purplePlusSign} alt="edit" />
            <span>Add</span>
          </button>
        </div>
        <div className="detailsection">
          {addresses?.map(
            ({
              id,
              pincode,
              address_line_1: addressLine1,
              // address_line_2: addressLine2,
              landmark,
              state,
              city,
              address_type: addressType,
            }) => (
              <div className={styles.addressContainer} key={id}>
                <div className={styles.detailInner}>
                  <p className={styles.subTxt}>
                    {addressLine1 && `${addressLine1}, `}
                    {/* {addressLine2 && `${addressLine2}, `} */}
                    {landmark && `${landmark}, `}
                    {city && `${city}, `}
                    {state} - {pincode}
                  </p>
                  <span
                    style={{ marginLeft: '0px', marginTop: '9px' }}
                    className={styles.userStatus}
                  >
                    {addressType}
                  </span>
                </div>
                <button
                  type="button"
                  title="edit"
                  onClick={() => onEditAddress(id)}
                >
                  <img src={editIcon} alt="edit" />
                </button>
              </div>
            )
          )}
        </div>
      </div>
      <div className="profileForm">
        <div className={styles.profileHeader}>
          <h3 className={styles.headingTxt}>Category</h3>
          <button type="button" title="edit" onClick={onEditCategory}>
            <img src={editIcon} alt="edit" />
          </button>
        </div>
        <div className="detailsection">
          <div className={styles.detailInner}>
            <div className={styles.categoryBlock}>
              {categories?.map((category) => (
                <span className={styles.categoryText} key={category}>
                  {category}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="profileForm">
        <div className={styles.profileHeader}>
          <h3 className={styles.headingTxt}>Preference</h3>
          <button type="button" title="edit" onClick={onEditPreference}>
            <img src={editIcon} alt="edit" />
          </button>
        </div>
        <div className="detailsection">
          <div className={styles.detailInner}>
            <div className={styles.categoryBlock}>
              {preferences?.map((preference) => (
                <span className={styles.categoryText} key={preference}>
                  {preference}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
