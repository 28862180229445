import {
  instagramWhiteOutlineIcon,
  facebookWhiteOutlineIcon,
  twitterWhiteOutlineIcon,
  youtubeWhiteOutlineIcon,
} from 'constants/images';
import { CAMPAIGN_STATUS } from './campaign';

const PLATFORMS = [
  { label: 'Instagram', value: 'instagram', icon: instagramWhiteOutlineIcon },
  { label: 'Youtube', value: 'youtube', icon: youtubeWhiteOutlineIcon },
  { label: 'Facebook', value: 'facebook', icon: facebookWhiteOutlineIcon },
  { label: 'Twitter', value: 'twitter', icon: twitterWhiteOutlineIcon },
];
Object.freeze(PLATFORMS);

const INF_USER_TYPES = [
  { label: 'All', value: '' },
  { label: 'Verified', value: 'verified' },
  { label: 'Not Verified', value: 'not-verified' },
];
Object.freeze(INF_USER_TYPES);

const CAMPAIGN_STATUS_LIST = [
  { label: 'Drafted', value: CAMPAIGN_STATUS.DRAFTED },
  { label: 'Upcoming', value: CAMPAIGN_STATUS.UPCOMING },
  { label: 'Active', value: CAMPAIGN_STATUS.ACTIVE },
  { label: 'Paused', value: CAMPAIGN_STATUS.PAUSED },
  { label: 'Completed', value: CAMPAIGN_STATUS.COMPLETED },
  { label: 'Archived', value: CAMPAIGN_STATUS.ARCHIVED },
  { label: 'Dropped', value: CAMPAIGN_STATUS.DROPPED },
];
Object.freeze(CAMPAIGN_STATUS_LIST);

const INF_CAMPAIGN_STATUS_LIST = [
  { label: 'Upcoming', value: CAMPAIGN_STATUS.UPCOMING },
  { label: 'Active', value: CAMPAIGN_STATUS.ACTIVE },
  { label: 'Paused', value: CAMPAIGN_STATUS.PAUSED },
  { label: 'Completed', value: CAMPAIGN_STATUS.COMPLETED },
  { label: 'Archived', value: CAMPAIGN_STATUS.ARCHIVED },
];
Object.freeze(INF_CAMPAIGN_STATUS_LIST);

const GENDERS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' },
];
Object.freeze(GENDERS);

export { PLATFORMS, CAMPAIGN_STATUS_LIST, INF_CAMPAIGN_STATUS_LIST, GENDERS, INF_USER_TYPES };
