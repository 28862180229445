import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Sector,
} from 'recharts';
import styles from 'assets/scss/pages/influencer-profile.module.scss';

const renderCustomizedLabelBarChart = (props) => {
  const { x, y, width, value } = props;

  const WIDTH = 50;
  const HEIGHT = 22;

  return (
    <g>
      <rect
        x={x - (WIDTH - width) / 2}
        y={y - 30}
        width={WIDTH}
        height={HEIGHT}
        stroke="#7C087F"
        fill="#fff"
      />
      <text
        x={x + width / 2}
        y={y - 18}
        fill="#3E4C59"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={12}
      >
        {value}%
      </text>
    </g>
  );
};

const AudienceAgeGenderCharts = ({ data }) => (
  <div className={styles.audienceDemography}>
    <div className={styles.audienceDemographywrapper} style={{ width: '100%' }}>
      <div className={styles.sectionHeader} style={{ gap: '5px' }}>
        <h3 className={styles.followerLabel}>AUDIENCE AGE GROUP</h3>
      </div>

      <div className={styles.sectionBottom} style={{ gap: '5px' }}>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={500}
            height={270}
            data={data}
            margin={{
              top: 30,
              right: 0,
              left: 0,
              bottom: 5,
            }}
            barGap={2}
            barSize={32}
          >
            <CartesianGrid vertical={false} strokeWidth={1} stroke="#E6E6E6" />
            <XAxis
              dataKey="group"
              tickLine={false}
              axisLine={false}
              fontSize={13}
              fill="#7B8794"
              width={30}
            />
            <YAxis
              tickFormatter={(value) => `${value}%`}
              dataKey="percentage"
              tickLine={false}
              axisLine={false}
              fontSize={13}
              fill="#7B8794"
              width={40}
            />
            <Bar
              dataKey="percentage"
              fill="#7C087F"
              radius={4}
              isAnimationActive={false}
            >
              <LabelList
                dataKey="percentage"
                content={renderCustomizedLabelBarChart}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  </div>
);

export default AudienceAgeGenderCharts;
