import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import styles from 'assets/scss/pages/campaign.module.scss';
import { formatNumber } from 'utils';

const data = [
  {
    id: 1,
    views: 225000,
    type: 'Historical',
  },
  {
    id: 2,
    views: 375000,
    type: 'Actual',
  },
];

const BAR_COLORS = ['#7F60FF', '#FF00A2'];

const HistoricalVsActualBarChart = () => (
  <div
    className={styles.postingInsightBox}
    style={{ maxWidth: '521px', margin: '0px' }}
  >
    <div className={styles.postHeader}>
      <div>
        <h2 className={styles.insightHeading}>historical vs actual</h2>
      </div>
      <div className={styles.benchmarkSectionView}>
        <select className={styles.selctMenu}>
          <option value="value">Audience Reach</option>
        </select>
      </div>
    </div>
    <div className={styles.postBody}>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          data={data}
          margin={{
            top: 30,
            right: 0,
            left: 0,
            bottom: 5,
          }}
          barGap={2}
          barSize={32}
        >
          <CartesianGrid vertical={false} strokeWidth={1} stroke="#E6E6E6" />
          <XAxis
            dataKey="type"
            tickLine={false}
            axisLine={false}
            fontSize={14}
            fill="#7B8794"
          />
          <YAxis
            tickFormatter={(value) => formatNumber(value)}
            dataKey="views"
            tickLine={false}
            axisLine={false}
            fontSize={14}
            fill="#7B8794"
            width={48}
          />
          <Tooltip cursor={{ fill: 'transparent' }} />
          <Bar dataKey="views" fill="#E289F2" isAnimationActive={false}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={BAR_COLORS[index]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  </div>
);

export default HistoricalVsActualBarChart;
