import React from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Footer from 'components/onboarding/Footer';
import OTPInput from 'components/common/OtpInput';
import Button from 'components/common/Button';

import { signupResendOtp, signupVerifyOtp } from 'api/brand/auth';
import { useBrandAuth } from 'stores/auth/brand';
import useCountdownTimer from 'hooks/useCountdownTimer';
import { OTP_COUNTDOWN_SECONDS } from 'constants/config';
import routes from 'constants/routes';
import { logoImageBlackText } from 'constants/images';
import { OTP_SCHEMA } from 'constants/schema';
import styles from 'assets/scss/pages/onboarding.module.scss';

const defaultValues = { otp: '' };

function SignUpVerifyOtp() {
  const { timeLeft, setTimeLeft, formattedValue } = useCountdownTimer(
    OTP_COUNTDOWN_SECONDS
  );

  const { setToken, setUserData } = useBrandAuth();
  const navigate = useNavigate();

  const location = useLocation();
  const email = location.state?.email;
  const isLogin = location.state?.isLogin;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(OTP_SCHEMA),
    defaultValues,
  });

  const verifyOtpMutation = useMutation({
    mutationFn: ({ otp }) => signupVerifyOtp({ otp, email }),
    onSuccess: (data) => {
      setToken(data?.data?.token);
      setUserData(data?.data?.users);
      if (isLogin && data?.data?.users?.agency_name) {
        navigate(routes.BRAND_DASHBOARD, { replace: true });
      } else {
        navigate(routes.BRAND_SIGNUP_PROFILE, { replace: true });
      }
    },
  });

  const resendOtpMutation = useMutation({
    mutationFn: () => {
      setTimeLeft(OTP_COUNTDOWN_SECONDS);
      return signupResendOtp({ email });
    },
    onSuccess: (data) => {
      toast.success(data.message);
    },
  });

  if (!email) {
    return <Navigate to={routes.BRAND_SIGNUP} replace />;
  }

  return (
    <div className={styles.signupInnerContent}>
      <div className={styles.signupItems}>
        <div>
          <div>
            <Link to={routes.HOME}>
              <img src={logoImageBlackText} alt="" width={140} />
            </Link>
          </div>
          <h1>Verify Your Email</h1>
          <p>
            We have sent a 6-digit verification code to your email. <br />{' '}
            Please enter the code below.
          </p>
          <form
            onSubmit={handleSubmit(verifyOtpMutation.mutate)}
            className={styles.signupFormItems}
          >
            <div>
              <div>
                <Controller
                  render={({ field }) => (
                    <OTPInput
                      length={6}
                      onChangeOTP={field.onChange}
                      className={styles.otpVerification}
                    />
                  )}
                  control={control}
                  name="otp"
                />
                {errors?.otp ? (
                  <p className={styles.error}>{errors.otp?.message}</p>
                ) : null}
              </div>
              <div className={styles.resentBtn}>
                <button
                  type="button"
                  onClick={resendOtpMutation.mutate}
                  disabled={timeLeft > 0}
                >
                  Resend code
                </button>
                <p>{formattedValue}</p>
              </div>
            </div>
            <Button
              type="submit"
              size="large"
              isLoading={verifyOtpMutation.isLoading}
              fullWidth
            >
              Verify & Proceed
            </Button>
          </form>
          <p className={styles.signupLink}>
            Already have an account?{' '}
            <Link to={routes.BRAND_LOGIN}>Sign in here</Link>
          </p>
        </div>
      </div>
      <Footer type="brand" />
    </div>
  );
}

export default SignUpVerifyOtp;
