import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Tab } from '@headlessui/react';
import { useCreateCampaign } from 'stores/create-campaign';
import { BRAND_CREATE_CAMPAIGN_STEP3_SCHEMA } from 'constants/schema';
import { AlertDialog } from 'components/common/dialog';
import { Avatar } from 'components/common/Avatar';
import ErrorMessage from 'components/common/form/ErrorMessage';
import { queryClient } from 'App';
import {
  createCampaign,
  editCampaign,
  getExpectedReach,
} from 'api/brand/campaign';
import { useDialog } from 'stores/dialog';
import errorMessages from 'constants/config/error-messagess';
import { PLATFORM_ICONS } from 'constants/config/platformIcons';
import { formatNumber, generateDefaultDeliverable } from 'utils';
import styles from 'assets/scss/pages/popup.module.scss';
import {
  arrowRight,
  awsmSquareAdd,
  awsmSquareMinus,
  deleteIcon,
  pinkAddIcon,
  pinkMinusIcon,
  xInfluenceIcon,
} from 'constants/images';
import AddInfluencerDialog from './AddInfluencerDialog';

const defaultValues = {
  influencers: [],
};

const StepThree = () => {
  const {
    formData,
    goToPrevStep,
    reset: resetData,
    setFormData,
  } = useCreateCampaign();
  const clearDialog = useDialog((state) => state.clearDialog);
  const isEdit = Boolean(formData.campaignId);

  const methods = useForm({
    resolver: yupResolver(BRAND_CREATE_CAMPAIGN_STEP3_SCHEMA),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    control,
    getValues,
    watch,
    formState: { errors },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'influencers',
  });

  const selectedInfluencers = watch('influencers');

  const selectedInfleuncerIds = useMemo(
    () => selectedInfluencers?.map(({ influencerId }) => influencerId),
    [selectedInfluencers]
  );

  const { data: expectedReachData } = useQuery({
    queryKey: [
      'brand-campaign-expected-reach',
      formData.platforms,
      selectedInfleuncerIds,
    ],
    queryFn: () =>
      getExpectedReach({
        ids: selectedInfleuncerIds,
        platforms: formData.platforms,
      }),
    enabled:
      Boolean(formData.platforms?.length) &&
      Boolean(selectedInfleuncerIds?.length),
  });

  const createCampaignMutation = useMutation({
    mutationFn: ({
      campaignName,
      brandName,
      description,
      startDateTime,
      endDateTime,
      expectedBudget,
      paymentTerms,
      tags,
      media,
      categories,
      region,
      gender,
      ageGroup,
      platforms,
      influencers,
      campaignId,
    }) => {
      const deliverables = influencers.map((influencer) => {
        const filteredDeliverables = influencer.deliverables.filter(
          ({
            reelsCount,
            storiesCount,
            imagesCount,
            videosCount,
            shortsCount,
            postsCount,
            tweetsCount,
          }) =>
            reelsCount ||
            storiesCount ||
            imagesCount ||
            videosCount ||
            shortsCount ||
            postsCount ||
            tweetsCount
        );
        return {
          influencerId: influencer.influencerId,
          deliverables: filteredDeliverables.map(
            ({
              platform,
              reelsCount,
              storiesCount,
              imagesCount,
              videosCount,
              shortsCount,
              postsCount,
              tweetsCount,
            }) => ({
              media_type: platform,
              reel: reelsCount,
              stories: storiesCount,
              image: imagesCount,
              video: videosCount,
              shorts: shortsCount,
              posts: postsCount,
              tweets: tweetsCount,
            })
          ),
        };
      });

      const payload = new FormData();
      payload.append('campaign_name', campaignName);
      payload.append('brand_name', brandName);
      payload.append('campaign_description', description);
      payload.append('campaign_start_date', startDateTime);
      if (endDateTime) payload.append('campaign_end_date', endDateTime);
      payload.append('budget', expectedBudget);
      payload.append('payment_terms', paymentTerms);
      payload.append(
        'social_hashtags',
        JSON.stringify(tags?.map(({ tagId }) => tagId))
      );
      if (media[0] instanceof File) payload.append('upload_image', media[0]);
      payload.append('categoryIds', JSON.stringify(categories));
      payload.append('regions', JSON.stringify(region));
      payload.append('target_genders', JSON.stringify(gender));
      payload.append(
        'age',
        JSON.stringify(
          ageGroup.map(({ from, to }) => ({
            age_from: `${from}`,
            age_to: `${to}`,
          }))
        )
      );
      payload.append('target_platforms', JSON.stringify(platforms));
      payload.append('influencers', JSON.stringify(deliverables));
      if (isEdit) payload.append('campaign_id', campaignId);

      return isEdit ? editCampaign(payload) : createCampaign(payload);
    },
    onSuccess: (res, { campaignId }) => {
      queryClient.invalidateQueries({ queryKey: ['brand-campaigns'] });
      if (isEdit) {
        queryClient.invalidateQueries({
          queryKey: ['brand-campaigns'],
        });
        queryClient.invalidateQueries({
          queryKey: ['brand-campaign-details', campaignId],
        });
        queryClient.invalidateQueries({
          queryKey: ['brand-campaign-influencers', campaignId],
        });
      }
      toast.success(res?.message, {
        position: 'top-center',
      });
      resetData();
      clearDialog();
    },
  });

  const onSubmit = (data) => {
    createCampaignMutation.mutate({ ...formData, ...data });
  };

  const onAddInfluencers = (influencers) => {
    const existingInfluencerIds = getValues('influencers').map(
      ({ influencerId }) => influencerId
    );
    const removedInfluencers = [];
    influencers
      .filter(({ id }) => !existingInfluencerIds.includes(id))
      .forEach((influencer) => {
        const deliverables = [];
        const platforms = [];

        if (influencer.is_instagram) {
          deliverables.push(generateDefaultDeliverable('instagram'));
          platforms.push('instagram');
        }
        if (influencer.is_facebook) {
          deliverables.push(generateDefaultDeliverable('facebook'));
          platforms.push('facebook');
        }
        if (influencer.is_twitter) {
          deliverables.push(generateDefaultDeliverable('twitter'));
          platforms.push('twitter');
        }
        if (influencer.is_youtube) {
          deliverables.push(generateDefaultDeliverable('youtube'));
          platforms.push('youtube');
        }

        const filteredDeliverables = deliverables.filter(({ platform }) =>
          formData.platforms.includes(platform)
        );

        if (filteredDeliverables.length > 0) {
          append({
            platforms,
            influencerId: influencer.id,
            influencerProfile: influencer.profile_image,
            influencerName: influencer.full_name,
            deliverables: filteredDeliverables,
          });
        } else {
          removedInfluencers.push(influencer);
        }
      });

    if (removedInfluencers.length > 0) {
      toast.error(errorMessages.UNABLE_TO_ADD_INFLUENCERS, {
        position: 'top-center',
      });
    }
  };

  const onGoBack = () => {
    const currentFormData = getValues();
    setFormData(currentFormData);
    goToPrevStep();
  };

  useEffect(() => {
    if (formData.influencers) {
      const filteredInfluencers = formData.influencers
        /**
         * following map will
         * 1. add deliverables for platforms that are supported by that provider but not persent in existing data
         * 2. remove deliverable that do not belong to campaign platforms
         */
        .map((influencer) => {
          const { deliverables, platforms } = influencer;
          platforms.forEach((platform) => {
            const foundIndex = deliverables.findIndex(
              (deliverable) => deliverable.platform === platform
            );
            if (foundIndex < 0) {
              deliverables.push(generateDefaultDeliverable(platform));
            }
          });
          return {
            ...influencer,
            deliverables: deliverables.filter(({ platform }) =>
              formData?.platforms?.includes(platform)
            ),
          };
        })
        // following filter will remove influencers that do not have any deliverables
        .filter(({ deliverables }) => deliverables.length > 0);

      reset({ influencers: filteredInfluencers });
      if (formData.influencers.length > filteredInfluencers.length) {
        toast.error(errorMessages.UNABLE_TO_ADD_INFLUENCERS, {
          position: 'top-center',
        });
      }
    }
  }, [reset, formData]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.secBody}>
          <div className={styles.secBodyWrapper}>
            <img src={xInfluenceIcon} alt="" />
            <div className={styles.popupInputWrap}>
              <h2 className={styles.hTxt}>Influencers & Deliverables</h2>

              {fields.map((field, index) => (
                <InfluencerCard
                  key={field.id}
                  onRemove={remove}
                  index={index}
                  influencer={field}
                />
              ))}
              <div className="tagsField">
                <AddInfluencerDialog
                  render={({ open }) => (
                    <button
                      type="button"
                      className={styles.addNewBtn}
                      onClick={open}
                    >
                      <img src={pinkAddIcon} alt="" /> Add New Influencers
                    </button>
                  )}
                  onAdd={onAddInfluencers}
                  platforms={formData.platforms}
                />
              </div>
              <ErrorMessage
                error={
                  Array.isArray(errors?.influencers)
                    ? null
                    : errors?.influencers
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.createInfFooter}>
          <div className={styles.sectinBottomButtons}>
            <div className={styles.expctedReachCal}>
              <h3 className={styles.expcTxt}>Expected Reach </h3>
              <p className={styles.amntTxt}>
                {formatNumber(expectedReachData?.lower_bound)}-
                {formatNumber(expectedReachData?.upper_bound)}
              </p>
            </div>
            <div className={styles.crBtn}>
              <button
                type="button"
                onClick={onGoBack}
                className={styles.prevBtn}
              >
                Previous
              </button>
              <button
                type="submit"
                style={{ width: 166 }}
                className={styles.updateBtn}
                disabled={createCampaignMutation.isLoading}
              >
                {isEdit ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const InfluencerCard = ({ onRemove, index, influencer }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { control } = useFormContext();
  const { errors } = useFormState();

  const name = `influencers.${index}.deliverables`;

  const { fields } = useFieldArray({ control, name });

  const toggleIsExpanded = () => {
    setIsExpanded((prev) => !prev);
  };
  return (
    <>
      <div className={styles.influencerDeliverableContainer}>
        <div className={styles.deliverBlock1}>
          <div className={styles.delivSubBlock}>
            <button type="button" onClick={toggleIsExpanded}>
              <img src={isExpanded ? awsmSquareMinus : awsmSquareAdd} alt="" />
            </button>
            <div className={styles.delSubInnrBlck}>
              <Avatar src={influencer?.influencerProfile} size="large" alt="" />
              <h3 className={styles.nameTxt}>{influencer?.influencerName}</h3>
              {/* <img src={blueTickIcon} alt="" /> */}
            </div>
          </div>
          <AlertDialog
            render={({ open }) => (
              <button onClick={open} type="button">
                <img className={styles.delImg} src={deleteIcon} alt="" />
              </button>
            )}
            title="Are you sure?"
            description="Do you really want to remove this Influencer?"
            agreeBtnText="Yes"
            disagreeBtnText="No"
            size="526px"
            onAgree={() => onRemove(index)}
          />
        </div>
        <Tab.Group
          className="delWrapp"
          as="div"
          style={{ display: isExpanded ? 'block' : 'none' }}
        >
          <Tab.List className={styles.wrapCont1}>
            {fields?.map((field) => (
              <Tab
                key={field.id}
                className={({ selected }) =>
                  clsx({
                    [styles.wrapLink]: true,
                    [styles.activeWrapLink]: selected,
                  })
                }
              >
                {field.platform}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {fields?.map((field, idx) => (
              <TabContent
                key={field.id}
                initalName={`influencers.${index}.deliverables.${idx}`}
                platform={field.platform}
              />
            ))}
          </Tab.Panels>
        </Tab.Group>
        <DeliverablesDetails name={name} />
      </div>
      <ErrorMessage error={errors?.influencers?.[index]?.deliverables} />
    </>
  );
};

InfluencerCard.propTypes = {
  onRemove: PropTypes.func,
  index: PropTypes.number,
  influencer: PropTypes.object,
};

const DeliverablesDetails = ({ name }) => {
  const { control } = useFormContext();
  const deliverables = useWatch({ control, name });

  const formattedDeliverables = useMemo(
    () =>
      deliverables
        ?.map(
          ({
            platform,
            reelsCount,
            storiesCount,
            imagesCount,
            videosCount,
            shortsCount,
            postsCount,
            tweetsCount,
          }) => {
            const mediaList = [
              { type: 'Reel', value: reelsCount },
              { type: 'Story', value: storiesCount },
              { type: 'Image', value: imagesCount },
              { type: 'Video', value: videosCount },
              { type: 'Shorts', value: shortsCount },
              { type: 'Posts', value: postsCount },
              { type: 'Tweets', value: tweetsCount },
            ].filter(({ value }) => !!value);

            return { platform, mediaList };
          }
        )
        ?.filter(({ mediaList }) => mediaList.length > 0),
    [deliverables]
  );
  return (
    <div className={styles.deliverBlock2}>
      {formattedDeliverables?.map(({ platform, mediaList }) => (
        <div className={styles.delSubSecndBlck} key={platform}>
          <img src={PLATFORM_ICONS[platform]} alt="" width="20" height="20" />
          <div>
            {mediaList?.map(({ type, value }, index) => (
              <p className={styles.reelTxt} key={type}>
                {type}: <span>{value}</span>
                {mediaList.length !== index + 1 ? ',' : null}&nbsp;
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

DeliverablesDetails.propTypes = {
  name: PropTypes.string,
};

const TabContent = ({ initalName, platform }) => {
  const [_, setInit] = useState();
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <Tab.Panel className={styles.postCountContnt} unmount={false}>
      <button type="button" className={styles.arrImg} ref={prevRef}>
        <img src={arrowRight} alt="" className={styles.leftArrow} />
      </button>
      <div className={styles.leftSec}>
        <div>
          <Swiper
            spaceBetween={36}
            slidesPerView="auto"
            modules={[Navigation]}
            className="mySlider"
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onInit={() => setInit(true)}
          >
            {platform === 'instagram' && (
              <SwiperSlide style={{ width: 102 }}>
                <CountSection name={`${initalName}.reelsCount`} title="reel" />
              </SwiperSlide>
            )}
            {platform === 'instagram' && (
              <SwiperSlide style={{ width: 102 }}>
                <CountSection
                  name={`${initalName}.storiesCount`}
                  title="stories"
                />
              </SwiperSlide>
            )}
            {platform === 'youtube' && (
              <SwiperSlide style={{ width: 102 }}>
                <CountSection
                  name={`${initalName}.videosCount`}
                  title="video"
                />
              </SwiperSlide>
            )}
            {platform === 'instagram' && (
              <SwiperSlide style={{ width: 102 }}>
                <CountSection name={`${initalName}.imagesCount`} title="post" />
              </SwiperSlide>
            )}
            {platform === 'youtube' && (
              <SwiperSlide style={{ width: 102 }}>
                <CountSection
                  name={`${initalName}.shortsCount`}
                  title="short"
                />
              </SwiperSlide>
            )}
            {platform === 'facebook' && (
              <SwiperSlide style={{ width: 102 }}>
                <CountSection name={`${initalName}.postsCount`} title="post" />
              </SwiperSlide>
            )}
            {platform === 'twitter' && (
              <SwiperSlide style={{ width: 102 }}>
                <CountSection
                  name={`${initalName}.tweetsCount`}
                  title="tweets"
                />
              </SwiperSlide>
            )}
          </Swiper>
        </div>
      </div>
      <button type="button" className={styles.arrImg} ref={nextRef}>
        <img src={arrowRight} alt="" />
      </button>
    </Tab.Panel>
  );
};

TabContent.propTypes = {
  initalName: PropTypes.string,
  platform: PropTypes.string,
};

const CountSection = ({ title, name }) => {
  const { control } = useFormContext();

  return (
    <div>
      <div>
        <h4 className={styles.reelTitle}>{title}</h4>
      </div>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className={styles.reelCounts}>
            <button
              type="button"
              onClick={() =>
                field.onChange(
                  field.value > 0 ? (Number(field.value) || 0) - 1 : 0
                )
              }
            >
              <img src={pinkMinusIcon} alt="" />
            </button>
            <input
              className={styles.reelCnt}
              {...field}
              type="number"
              min="0"
              max="99"
            />
            <button
              type="button"
              onClick={() =>
                field.onChange(
                  field.value < 99 ? (Number(field.value) || 0) + 1 : 99
                )
              }
            >
              <img src={pinkAddIcon} alt="" />
            </button>
          </div>
        )}
      />
    </div>
  );
};

CountSection.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
};

export default StepThree;
