// NOTE: this page is not in use any more as we are not collecting card info at our end
// TODO: remove this page once diffrent flow is completed and verified by client
import React, { useMemo } from 'react';
import { useSpinDelay } from 'spin-delay';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { PaymentForm } from 'components/payments';
import PaymentSuccessDialog from 'components/payments/PaymentSuccessDialog';
import PaymentFailDialog from 'components/payments/PaymentFailDialog';
import styles from 'assets/scss/pages/payments.module.scss';
import { dotImage } from 'constants/images';
import { PAYMENT_MODULES } from 'constants/config/payment';
import routes from 'constants/routes';
import { getPaymentDetails } from 'api/brand/payments';
import { LoaderWithWrapper } from 'components/common/Loader';

const PAYMENT_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
};

const formatPrice = (amount, props = {}) =>
  Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 2,
    ...props,
  }).format(amount);

const Payments = () => {
  /**
   * * moduleId is a section or feature of website for which we are doing payment
   * * For eg. "campaing", "brand-subscription" etc.
   *  */
  const { paymentId, moduleId } = useParams();
  const [searchParams] = useSearchParams();
  const paymentStatus = searchParams.get('status');
  const paymentError = searchParams.get('error');

  const navigate = useNavigate();

  const { data: paymentData, isLoading } = useQuery({
    queryKey: ['payment-details', paymentId],
    queryFn: () => getPaymentDetails({ paymentId }),
    select: (res) => res?.data?.order,
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  // const { order_status: status, quotationCampaignId: quoteId } =
  //   paymentData || {};

  const formattedPayableAmount = useMemo(
    () => formatPrice(paymentData?.quotation_campaign?.amount_payable || 0),
    [paymentData]
  );

  const formattedAmountWithCode = useMemo(() => {
    const amount =
      paymentData?.payload?.amount ||
      paymentData?.quotation_campaign?.amount_payable ||
      0;
    return formatPrice(amount, { currencyDisplay: 'code' });
  }, [paymentData]);

  const onStatusDialogClose = () => {
    if (moduleId === PAYMENT_MODULES.CAMPAIGN) {
      navigate(routes.BRAND_CAMPAIGNS);
      return;
    }
    navigate(routes.HOME);
  };

  const isPaymentSuccess = paymentStatus === PAYMENT_STATUS.SUCCESS;
  const isPaymentFail = paymentStatus === PAYMENT_STATUS.FAILED;

  if (isLoading || delayedIsLoading) {
    return <LoaderWithWrapper show={delayedIsLoading} />;
  }

  return (
    <section className={styles.payments}>
      <div className={styles.headingContainer}>
        <h1 className={styles.heading}>Payments</h1>
      </div>
      <div className={styles.container}>
        <div className={styles.topRightDots}>
          <img className="dotImg" src={dotImage} alt="" />
        </div>
        <div className={styles.bottomLeftDots}>
          <img className="dotImg" src={dotImage} alt="" />
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <h2 className={styles.sectionHeading}>YOUR ORDER</h2>
            <table className={styles.orderTable}>
              <thead>
                <tr>
                  <th className={styles.left}>PRODUCT</th>
                  <th className={styles.right}>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={styles.left}>Basic × 1</td>
                  <td className={styles.right}>{formattedAmountWithCode}</td>
                </tr>
                {/* <tr className={styles.gstRow}>
                  <td className={styles.left}>
                    <span>GST</span>
                  </td>
                  <td className={styles.right}>18%</td>
                </tr> */}
              </tbody>
              <tfoot>
                <tr>
                  <th className={styles.left}>TOTAL</th>
                  <td className={styles.right}>{formattedAmountWithCode}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className={styles.column}>
            <h2 className={styles.sectionHeading}>PAYMENT METHOD</h2>
            <PaymentForm
              formattedAmount={formattedPayableAmount}
              paymentData={paymentData}
              disabled={isPaymentSuccess || isPaymentFail}
            />
          </div>
        </div>
      </div>
      <PaymentSuccessDialog
        isOpen={isPaymentSuccess}
        onClose={onStatusDialogClose}
        amount={formattedAmountWithCode}
        orderId={paymentId}
      />
      <PaymentFailDialog
        isOpen={isPaymentFail}
        onClose={onStatusDialogClose}
        error={paymentError}
      />
    </section>
  );
};

export default Payments;
