import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import styles from './dialog.module.scss';
import Dialog from './Dialog';

const AlertDialog = ({
  open = false,
  title,
  description,
  render,
  onAgree,
  onDisagre,
  agreeBtnText = 'Agree',
  disagreeBtnText = 'Disagree',
  showCloseBtn = true,
  postion,
  size,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  const onAgreeBtnClick = () => {
    if (onAgree) onAgree();
    onClose();
  };

  const onDisagreeBtnClick = () => {
    onClose();
    if (onDisagre) onDisagre();
  };

  return (
    <>
      {render ? render({ open: onOpen }) : null}
      <Dialog
        title={title}
        showCloseBtn={showCloseBtn}
        open={isOpen || open}
        close={onClose}
        postion={postion}
        size={size}
        fullWidth
      >
        <div className={styles.alertDialogContent}>
          <p>{description}</p>
        </div>
        <div className={styles.dialogActions}>
          <Button
            variant="text"
            onClick={onDisagreeBtnClick}
            style={{ color: '#757575' }}
          >
            {disagreeBtnText}
          </Button>
          <Button onClick={onAgreeBtnClick} style={{ minWidth: 130 }}>
            {agreeBtnText}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

AlertDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  description: PropTypes.node,
  render: PropTypes.func,
  onAgree: PropTypes.func,
  onDisagre: PropTypes.func,
  agreeBtnText: PropTypes.string,
  disagreeBtnText: PropTypes.string,
  showCloseBtn: PropTypes.bool,
  postion: PropTypes.string,
  size: PropTypes.string,
};

export default AlertDialog;
