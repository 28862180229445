import React, { useId } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useInfulencerOnboardingForm } from 'stores/onboarding';

import routes from 'constants/routes';
import { influencerImage, creatorImage } from 'constants/images';
import styles from 'assets/scss/pages/onboarding.module.scss';

const RadioButton = ({ icon, label, ...rest }) => {
  const id = useId();
  return (
    <div>
      <input type="radio" name="type" id={id} {...rest} />
      <label htmlFor={id}>
        <img src={icon} alt="" />
        <span>{label}</span>
      </label>
    </div>
  );
};

RadioButton.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
};

function InfluencerTypeSelectForm() {
  const { goToNextStep, addFormData } = useInfulencerOnboardingForm();

  const onTypeSelect = (e) => {
    addFormData({ type: e.target.value });
    goToNextStep();
  };

  return (
    <>
      <h1>How do you want yourself to be recognised?</h1>
      <p>We'll streamline your experience accordingly.</p>
      <form>
        <fieldset className={styles.userSelection}>
          {/* <legend>How do you want yourself to be recognised?</legend> */}
          <RadioButton
            value="influencer"
            onChange={onTypeSelect}
            label="Influencers"
            icon={influencerImage}
          />
          <RadioButton
            value="creator"
            onChange={onTypeSelect}
            label="Creators"
            icon={creatorImage}
          />
        </fieldset>
        <p className={styles.signupLink}>
          Already have an account?{' '}
          <Link to={routes.INF_LOGIN}>Sign in here</Link>
        </p>
      </form>
    </>
  );
}

export default InfluencerTypeSelectForm;
