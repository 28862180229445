const caseStudies = [
  {
    id: '1',
    title: 'Magnite is Music – Launch of new Nissan Magnite Geza ',
    shortDesc:
      'It’s was a proud moment for Nissan Motors India when the 1 lakhth  Magnite rolled out – through the campaign we wanted to instill this sense of pride amongst the Magnite owners, that their car is one in a “Million”. ',
    videoUrl: 'https://www.youtube.com/embed/Wcl2pFg9iC4?si=jVk0ZnyutufL0t1y',
    clientBrief:
      'Nissan Motors completed the landmark of 1 lac Magnite Roll out in the month of June. They marked this occasion with a limited edition launch of Nissan Magnite Geza (Music edition of Nissan Magnite). They wanted to use RJs as the flagbearers of this campaign since they drive strong music affinity. Also, they wanted to use World Music Day as an apt opportunity to talk about Music Variant of their hero brand in India.\n\n Brand Goals: \n1.	Create buzz about brand’s landmark delivery in India – 1 lac Magnite Roll out \n2.	Build strong music affinity for Nissan Magnite Geza\n3.	Create brand trials, drive business objective of more test drives for Nissan Magnite.',
    details:
      'It’s was a proud moment for Nissan Motors India when the 1 lakhth  Magnite rolled out – through the campaign we wanted to instill this sense of pride amongst the Magnite owners, that their car is one in a “Million”. \n To mark this day we did the biggest disruption with our influencers, we celebrated 1 Lakh seconds of drive music celebration with our partner Radio City– where for approx. 28 hrs (which is roughly 1 lakh secs) of uninterrupted music was played on air. There was a Celebration Anthem on the theme of #LakhonHaiNigahonMein to mark the launch of the Big Bold Beautiful Nissan Magnite Geza Special Edition. The song was By Magnite, for Magnite owners. \n We kept up the Celebration, throughout the month with a gala musical extravaganza - Nissan Magnite Geza Musical Fest. Here we collaborated with our creators/ singing sensations like Jasleen Royal, Akhil Sachdeva, and Ankur Tewari, and provided an unforgettable musical experience to listeners to create maximum buzz around the launch of the Big Bold Beautiful Nissan Magnite Geza Special Edition. \n We culminated the celebration on World Music Day, where from select Nissan showrooms, we flagged off a Nissan Magnite Geza Music drive with Radio City’s jockeys as influencers in the music space. The entire day, the Influencers did their shows from Nissan Magnite Geza, allowing listeners to dedicate songs of their choice on radio and play the same music in the Music Edition of Nissan Magnite.\n The Influencer drove the music affinity further by creating reels of them vibing on the “Lakhon Hain Nigah mein” Anthem.',
    results: 'Engagement Rate- 6-7% \nReach- 1.3 Mn \nViews- 0.2 Mn plus',
  },
  {
    id: '2',
    title: 'Yes Bank – The New Sound of Yes',
    shortDesc:
      'Our Influencers across India come together to introduce and celebrate the New Sound of Yes. A video was created using the long format of the Yes Bank musical Identity as the background and every influencer spoke about what they associate this sound with.',
    videoUrl: 'https://www.youtube.com/embed/nVnT6OsnFPo?si=X7Hu9HaDxYCLBlyB',
    clientBrief:
      'To promote the new musical identity of Yes Bank and create top-of-mind recall for the brand vision behind this MOGO.\n\nBrand Goals: \n1.	Create conversations for the new Brand MOGO\n2.	Establish brand philosophy & vision through the new MOGO\n3.	Use micro & nano influencers for the content push to create virality for the new MOGO',
    details:
      'Our Influencers across India come together to introduce and celebrate the New Sound of Yes. A video was created using the long format of the Yes Bank musical Identity as the background and every influencer spoke about what they associate this sound with. \nWith 1.4M followers on Instagram, Influencer Raghav is the most-followed Radio Jockey in India. Known for his engaging Instagram content, we used his popularity to create more engagement for the Yes Bank sonic identity. Raghav created a reel with the Yes Bank 25 sec creative – and spoke about which sound according to him is represented by the music\nHe urged his followers to “Remix” his reel with their version of what the sound represents for them – with #TheNewSoundOfYES.\nAlso as this was amplified on Radio city 91.1,  every city has specific sounds that are generally associated with it. Local train announcements of Mumbai, upbeat garba music in Rajkot, The haunting sounds of Ektara from the Jaisalmer desert -  each city has its own sound identity. And to this sound identity, a new sound was added – The New Sound of Yes. We created & aired multiple audio promos across platforms with soundscapes of different cities – each combined with the New Sound of Yes to showcase that wherever you are, this new sound will reach you.',
    results: 'Engagement Rate- 3%\nReach- 0.3 Mn\nViews-1.7 Mn',
  },
  {
    id: '3',
    title: 'Sampoorn Swasthya ki Baat by Patanjali',
    shortDesc:
      'In an age of an ever-evolving health & wellness industry where there is information available on the internet in abundance, we often find it difficult to rely on one credible source. Also, with increasing amount of stress and lifestyle diseases, it is important to adopt a more holistic and integrated method to stay healthy.',
    videoUrl: 'https://www.youtube.com/embed/K-8oa6qoEXo?si=AaVDhpNeXjYIGrWa',
    clientBrief:
      'Create a 360-degree plan for Patanjali Ayurveda which helps create content assets that become a credible source of information for the consumers. Thus, meeting the following brand objectives.  \n\n    Brand Goals: \n    1.	Create strong share of voice for Patanjali Ayurveda in the health and holistic wellbeing space\n    2.	Co-create content with macro influencer Acharya Balkrishna ji and establish him as the face of the brand\n    3.	Promote brand’s philosophy of Integratedpathy, which stands for holistic approach towards health & wellness – Ayurveda, Yoga, & healthy eating habits.',
    details:
      'In an age of an ever-evolving health & wellness industry where there is information available on the internet in abundance, we often find it difficult to rely on one credible source. Also, with increasing amount of stress and lifestyle diseases, it is important to adopt a more holistic and integrated method to stay healthy. Thus, we brought in the most Trusted and Credible wellness Scholar Acharya BalKrishna ji, the Founder Secretary of Patanjali Yogpeeth Trust. Acharya Balkrishna ji is a great scholar of Ayurveda, Sanskrit and the Vedas. He is the Flag Bearer of ancient lifestyle, traditions & natural healings and hence is acceptable by one and all in these modern times & together with the great scholar we created a Radio + Digital show – Sampoorna Swasthya Ki Baat\nThis was a national weekly AFP with our media partner – Radio City, where Acharya Balkrishna ji spoke about various health aspects combining the philosophy of Integratedpathy and holistic healing. Every week was dedicated to one aspect of health and wellness and Acharyaji shed light on the ways to deal with it in a more natural way. Videos & Audiograms of this AFP were shared on Facebook & Instragram, creating a wider reach and impact for the overall campaign. ',
    results: 'Engagement Rate- 5-6%\nReach: 4.5 Mn\nViews:0.4 mn',
  },
  {
    id: '4',
    title: 'Western Railway',
    shortDesc:
      'We built a strategic content & influencer marketing partnership with Western Railways. Thereafter on various occasions such as Independence Day, Women’s Day, Republic Day, 125 years of Chatrapati Shivaji Terminus we created content for moment marketing using micro and nano influencers.',
    videoUrl: 'https://www.youtube.com/embed/esQVSGnq54M?si=S40bSwrgCAuWAjF3',
    clientBrief:
      'Western Railways is one of the oldest subsidiary held under Indian Railways. They were looking at promoting their legacy, modernization and create a top of mind campaign that connects to various segments of the society.  \n\n   Brand Goals: \n   1.        Promote their legacy as the oldest Railways in India.\n   2.        Showcase their modernization through a local connect of micro & nano influencer.\n   3. Utilize various occasions such as Independence Day, Women’s Day, Republic Day, 125 years of Chatrapati Shivaji Terminus etc and amplify it on digital platforms.',
    details:
      'We built a strategic content & influencer marketing partnership with Western Railways. Thereafter on various occasions such as Independence Day, Women’s Day, Republic Day, 125 years of Chatrapati Shivaji Terminus we created content for moment marketing using micro and nano influencers.\nOn each of these occasions anthem videos featuring the influencer were made and distributed through facebook, Instagram and Youtube. These videos featured the Chitrapati Shivaji Terminus showcasing the legacy of Western Railways, the newest trains such as Vande Bharat express showcasing the modernisation and influencer interaction with commuters showcasing the brand’s connect with the masses. \nWe won the First Golden Mikes award for Western Railways campaign.',
    results: 'Engagement Rate- 3-4%\nReach-  8 Mn\nViews- 1 Mn plus',
  },
  {
    id: '5',
    title: 'IKEA – The Multifunctional Wonder',
    shortDesc:
      'We worked with Influencers across Mumbai, Bangalore and Hyderabad, who became the face of IKEA’s The Multifunctional Wonder campaign.',
    videoUrl: 'https://www.youtube.com/embed/RA90oQ7MQVY?si=0qN8a5XlfMe55jSA',
    clientBrief:
      'Although a legendary brand world over, IKEA is relatively a new entrant in the Indian market. They were looking at establishing the brand in urban Indians’ minds as functional affordable functional furniture & home solutions one stop shop. \n\n    Brand Goals: \n    1.        Brand recall for IKEA amongst Urban India\n    2.        Amidst the highly fragmented and unorganised market of home & lifestyle products in India, IKEA wanted to establish how branded stores like IKEA are one stop solution\n    3.        Establish IKEA’s range of furniture as highly multifunctional & affordable, best suited for Indian Homes.',
    details:
      'We worked with Influencers across Mumbai, Bangalore and Hyderabad, who became the face of IKEA’s The Multifunctional Wonder campaign. \nThe art of multifunctioning has become a habit that is enjoyed by urban Indians, especially since work from home became a way of life post-pandemic. The influencers showed their personalities as “The Multifunctional Wonder”. They personally took a tour of IKEA Stores in their respective cities and picked up IKEA products matching their personality & choice. ',
    results: 'Engagement Rate- 2-3%\nReach-1 Mn\nViews- 0.3 Mn plus',
  },
  {
    id: '6',
    title: 'Maruti Suzuki - Driven By Safety',
    shortDesc:
      'Our objective was to make “Driven By Safety” a lifestyle choice, for which we required a creator, who could be the face of the campaign. A personality that the mass audience connected with and admired.',
    videoUrl: 'https://www.youtube.com/embed/f8vill4Ep1Y?si=tQTadGoMHTgnNu-F',
    clientBrief:
      'Maruti Suzuki India Limited wanted to use the occasion of Road Safety Month, to raise awareness of the importance of safe driving, using the safety features of Maruti Suzuki cars and their commitment to creating products that are packed with safety features. \n\n    Brand Goals: \n    1.        Raise awareness about Road Safety\n    2.        Raise awareness about Maruti Suzuki’s commitment to making Indian Roads safer for both drivers and non-drivers\n    3.Raise awareness about safety features in all Maruti Suzuki Cars',
    details:
      'Our objective was to make “Driven By Safety” a lifestyle choice, for which we required a creator, who could be the face of the campaign. A personality that the mass audience connected with and admired. Comedian, actor and presenter Manish Paul fit the bill. Apart from Manish Paul a host of influencers wholeheartedly participated in taking pledges to drive safe and also encourage the listeners of the country to follow traffic rules and drive safe on the road.',
    results: 'Engagement Rate- 7-8%\nReach- 1.4 Mn\nViews- 1 Mn plus',
  },
];

export { caseStudies };
