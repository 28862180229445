import React, { useState } from 'react';
import clsx from 'clsx';
import { useMutation } from '@tanstack/react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Footer from 'components/onboarding/Footer';
import Button from 'components/common/Button';
import PasswordResetSuccessDialog from 'components/onboarding/PasswordResetSuccessDialog';

import { resetPassword } from 'api/influencer/auth';
import { NEW_PASSWORD_FORM_SCHEMA } from 'constants/schema';
import { logoImageBlackText, eyeImage, eyeCloseImage } from 'constants/images';
import routes from 'constants/routes';
import styles from 'assets/scss/pages/onboarding.module.scss';
import { useInfluencerAuth } from 'stores/auth/influencer';

const defaultValues = {
  password: '',
  confirmPassword: '',
};

function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const token = useInfluencerAuth((state) => state.token);
  const navigate = useNavigate();

  const location = useLocation();
  const username = location.state?.username;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(NEW_PASSWORD_FORM_SCHEMA),
    defaultValues,
  });

  const resetPassMutation = useMutation({
    mutationFn: ({ password, confirmPassword }) =>
      resetPassword({
        new_password: password,
        confirm_password: confirmPassword,
        token,
        email: username,
      }),
    onSuccess: () => {
      setShowSuccessDialog(true);
    },
  });

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const onSuccessDialogClose = () => {
    setShowSuccessDialog(false);
    navigate(routes.INF_LOGIN);
  };

  return (
    <>
      <div className={styles.signupInnerContent}>
        <div className={clsx(styles.signupItems)}>
          <div>
            <div>
              <Link to={routes.HOME}>
                <img src={logoImageBlackText} alt="" width={140} />
              </Link>
            </div>
            <h1>Reset Password</h1>
            <p>
              Please enter a new password meeting the criteria mentioned below.
            </p>

            {/* TODO: Password forms should have (optionally hidden) username fields for accessibility */}
            <form
              className={styles.signupFormItems}
              onSubmit={handleSubmit(resetPassMutation.mutate)}
            >
              <div>
                <div className={styles.passwordWrapper}>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Enter Password"
                    autoComplete="new-password"
                    {...register('password')}
                  />
                  <button
                    className={styles.eyeImage}
                    type="button"
                    onClick={toggleShowPassword}
                    title={showPassword ? 'Hide password' : 'Show password'}
                  >
                    <img src={showPassword ? eyeCloseImage : eyeImage} alt="" />
                  </button>
                  {errors?.password ? (
                    <p className={styles.error}>{errors.password?.message}</p>
                  ) : null}
                </div>
              </div>
              <div>
                <div className={styles.passwordWrapper}>
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    autoComplete="new-password"
                    {...register('confirmPassword')}
                  />
                  <button
                    className={styles.eyeImage}
                    type="button"
                    onClick={toggleShowConfirmPassword}
                    title={
                      showConfirmPassword
                        ? 'Hide confirm password'
                        : 'Show confirm password'
                    }
                  >
                    <img
                      src={showConfirmPassword ? eyeCloseImage : eyeImage}
                      alt=""
                    />
                  </button>
                  {errors?.confirmPassword ? (
                    <p className={styles.error}>
                      {errors.confirmPassword?.message}
                    </p>
                  ) : (
                    <p style={{ color: '#D7B2D8' }} className={styles.error}>
                      Use at least 6 characters and 1 number or symbol.
                    </p>
                  )}
                </div>
              </div>
              <Button
                type="submit"
                size="large"
                onLoading={resetPassMutation.isLoading}
                fullWidth
              >
                Reset Password
              </Button>
            </form>
          </div>
        </div>
        <Footer type="influencer" />
      </div>
      <PasswordResetSuccessDialog
        isOpen={showSuccessDialog}
        onClose={onSuccessDialogClose}
      />
    </>
  );
}

export default ResetPassword;
