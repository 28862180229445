import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'components/common/dialog';
import Loader from './Loader';
import styles from './Loader.module.scss';

const FullPageLoader = ({ show = false, loadingText = 'Please wait...' }) => {
  if (show) {
    return (
      <Dialog open={show}>
        <div className={styles.fullPageLoaderWrapper}>
          <Loader />
          <p style={{ color: '#616E7C' }}>{loadingText}</p>
        </div>
      </Dialog>
    );
  }

  return null;
};

FullPageLoader.propTypes = {
  loadingText: PropTypes.string,
  show: PropTypes.bool,
};

export default FullPageLoader;
