import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from 'assets/scss/pages/profile-dashboard.module.scss';
import { blueTickIcon } from 'constants/images';

const ProfileStatus = ({ profileStatus }) => {
  const status = useMemo(() => {
    if (profileStatus === 'verified')
      return { text: 'Approved', color: '#0C9B16' };
    if (profileStatus === 'pending')
      return { text: 'Profile under review', color: '#EDAC3C' };
    return { text: '' };
  }, [profileStatus]);

  return (
    <div className={styles.statusContainer}>
      <h3 className={styles.statusTxt}>Status:</h3>
      <p
        className={styles.approvedTxt}
        style={status.color ? { color: status.color } : undefined}
      >
        {profileStatus === 'verified' && <img src={blueTickIcon} alt="" />}
        {status.text}
      </p>
    </div>
  );
};

ProfileStatus.propTypes = {
  profileStatus: PropTypes.string,
};

export default ProfileStatus;
