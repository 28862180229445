import React from 'react';
import { formatDate, formatNumber } from 'utils';
import { ellipseImage1, videoPlay, viewIcon } from 'constants/images';
import styles from 'assets/scss/pages/campaign.module.scss';

const YOUTUBE_URL = 'https://www.youtube.com/';
const FACEBOOK_URL = 'https://www.facebook.com/';

const Post = ({ postDetails }) => {
  const {
    view_count: views,
    views: instaViews,
    timestamp,
    permalink,
    title,
    thumbnail,
    publishedAt,
    media_id: id,
    media_type: type,
    ...mapData
  } = postDetails || {};
  console.log('🚀 ~ file: Post.js:22 ~ Post ~ timestamp:', publishedAt);

  return (
    <div className="postWrapper">
      {/* <div className="postHeader">
        <img src={ellipseImage1} alt="" />

        <h2 className="title">{title || mapData?.caption}</h2>
      </div> */}
      {/* FIXME: video type can be insta video also make following logic correctly  */}
      <a
        href={
          ((type === 'shorts' || type === 'video') &&
            `${YOUTUBE_URL}/watch?v=${id}`) ||
          permalink
        }
        target="_blank"
        rel="noreferrer"
        className="postContent"
      >
        <img className="postImage" src={thumbnail} alt={title} />
        <img className="overlayIcon" src={videoPlay} alt="" />
        <div className="postDetails">
          <div>
            <div className="postsDetailsItem">
              <img src={viewIcon} alt="" />
              <span className="viewText">
                {formatNumber(views) || formatNumber(instaViews)}
              </span>
            </div>
          </div>
          <div className="viewText">
            {(publishedAt &&
              formatDate(
                typeof publishedAt === 'object'
                  ? publishedAt.$date
                  : publishedAt
              )) ||
              (timestamp && formatDate(timestamp))}
          </div>
        </div>
      </a>
    </div>
  );
};

export default Post;
