import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Listbox } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { ReactComponent as DownIcon } from 'assets/images/down.svg';
import { ReactComponent as CrossIcon } from 'assets/images/cross_white.svg';
import styles from 'assets/scss/pages/popup.module.scss';

const CustomSelect = React.forwardRef(
  (
    {
      label,
      options = [],
      onChange,
      value = [],
      labelSelector = 'label',
      valueSelector = 'value',
      placeholder,
      multiple = false,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    const onRemove = (id, e) => {
      e.preventDefault();
      onChange(value.filter((selectedId) => selectedId !== id));
    };

    const selected = useMemo(
      () =>
        multiple
          ? options.filter((option) => value.includes(option[valueSelector]))
          : options?.find((option) => value === option[valueSelector]) || '',
      [value, options, valueSelector, multiple]
    );

    const onValueChange = (newValues) =>
      multiple
        ? onChange(newValues.map((option) => option[valueSelector]))
        : onChange(newValues[valueSelector]);

    return (
      <Listbox
        value={selected}
        onChange={onValueChange}
        className={styles.selectWrapper}
        ref={ref}
        as="div"
        disabled={disabled}
        multiple={multiple}
        {...rest}
      >
        {label ? (
          <Listbox.Label className={styles.formLabel}>{label}</Listbox.Label>
        ) : null}
        <Float placement="bottom-start" flip offset={4} portal adaptiveWidth>
          <Listbox.Button className={styles.selectButton}>
            <span className={styles.selectedValues}>
              {multiple
                ? selected.map((option) => (
                    <span
                      className={styles.selectedValue}
                      key={option[valueSelector]}
                    >
                      <span>{option[labelSelector]}</span>
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                      <CrossIcon
                        onClick={(e) =>
                          disabled
                            ? undefined
                            : onRemove(option[valueSelector], e)
                        }
                        className={styles.cross}
                      />
                    </span>
                  ))
                : selected[labelSelector]}
              {!selected && (
                <span className={styles.selectPlaceholder}>{placeholder}</span>
              )}
            </span>
            <DownIcon className={styles.dropdownIcon} />
          </Listbox.Button>
          <Listbox.Options className={styles.selectOptions}>
            {options.map((option) => (
              <Listbox.Option
                key={option[valueSelector]}
                value={option}
                className={({ selected, active }) =>
                  clsx({
                    [styles.selectOption]: true,
                    [styles.selectOptionActive]: selected,
                    [styles.active]: active,
                  })
                }
              >
                {multiple && <span className={styles.checkMark} />}
                <span className={styles.optionLabel}>
                  {option[labelSelector]}
                </span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Float>
      </Listbox>
    );
  }
);

CustomSelect.propTypes = {
  label: PropTypes.node,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  labelSelector: PropTypes.string,
  valueSelector: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
};

export default CustomSelect;
