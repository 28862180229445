import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { Disclosure } from '@headlessui/react';
import { ReactComponent as DownIcon } from 'assets/images/location.svg';
import styles from 'assets/scss/pages/static-content.module.scss';

const Faq = ({ question, answer, defaultOpen = false }) => (
  <Disclosure
    className={({ open }) =>
      clsx({
        [styles.faqOuter]: true,
        [styles.activeContainer]: open, // this class will be addded when discloser is open
      })
    }
    as="div"
    defaultOpen={defaultOpen}
  >
    {({ open }) => (
      <>
        <Disclosure.Button className={styles.closerBtn}>
          <div className={styles.faqContainer}>
            <span className={styles.faQuestn}>{question}</span>
            <span>
              <DownIcon
                className={clsx({
                  [styles.rightArrow]: true,
                  [styles.openFaq]: open,
                })}
              />
            </span>
          </div>
        </Disclosure.Button>
        <AnimatePresence>
          {open && (
            <Disclosure.Panel
              as={motion.div}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{
                height: 0,
                opacity: 0,
                transition: { duration: 0.2, type: 'tween' },
              }}
              transition={{ duration: 0.3, type: 'tween' }}
              static
            >
              <p className={styles.faqAnswer}>{answer}</p>
            </Disclosure.Panel>
          )}
        </AnimatePresence>
      </>
    )}
  </Disclosure>
);

Faq.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
  defaultOpen: PropTypes.bool,
};

export default Faq;
