export const formatInfRank = (rawRank = 100) => {
  const rank = parseFloat(rawRank);

  let topRankText = '';

  if (rank <= 1) {
    topRankText = 'Top 1%';
  }
  if (rank <= 2 && rank > 1) {
    topRankText = 'Top 2%';
  }
  if (rank <= 3 && rank > 2) {
    topRankText = 'Top 3%';
  }
  if (rank <= 4 && rank > 3) {
    topRankText = 'Top 4%';
  }
  if (rank <= 5 && rank > 4) {
    topRankText = 'Top 5%';
  }

  return { rank, topRankText };
};
