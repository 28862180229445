import React from 'react';
import PropTypes from 'prop-types';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import {
  commentGrayIcon,
  likeGrayIcon,
  multipleFilesIcon,
  videoPlay,
  // viewIcon,
} from 'constants/images';

const YOUTUBE_URL = 'https://www.youtube.com/';
const FACEBOOK_URL = 'https://www.facebook.com/';
const PostContentCard = ({
  postId,
  thumbnail,
  permalink,
  likes,
  comments,
  kind,
  publishedAt,
  title,
}) => {
  const youtubeVideoLink =
    kind === 'video' && `${YOUTUBE_URL}watch?v=${postId}`;
  const facebookLink = kind === 'picture' && `${FACEBOOK_URL}watch?v=${postId}`;
  return (
    <div className={styles.postBlock}>
      <a
        href={
          (permalink && permalink) ||
          (youtubeVideoLink && youtubeVideoLink) ||
          (facebookLink && facebookLink)
        }
        target="_blank"
        rel="noreferrer"
      >
        {thumbnail ? (
          <img
            className={styles.propicImg}
            width="204"
            height="204"
            src={thumbnail}
            alt=""
          />
        ) : (
          <div className={styles.contentTextWrapper}>
            <p>{title}</p>
          </div>
        )}
      </a>
      {kind === 'video' ? (
        <img className={styles.overlayIcon} src={videoPlay} alt="" />
      ) : (
        <img className={styles.overlayIcon} src={multipleFilesIcon} alt="" />
      )}
      <div className={styles.bottomBlock}>
        <div className={styles.viewsColumn}>
          {/* <div className={styles.viewsColumn}>
            <img src={viewIcon} alt="" />
            <span className={styles.viewTxt}>{views}</span>
          </div> */}
          <div className={styles.viewsColumn}>
            <img src={likeGrayIcon} alt="" width="15" height="17" />
            <span className={styles.viewTxt}>{likes}</span>
          </div>
          <div className={styles.viewsColumn}>
            <img src={commentGrayIcon} alt="" width="10" height="17" />
            <span className={styles.viewTxt}>{comments}</span>
          </div>
        </div>
        <div className={styles.monthTxt}>{publishedAt}</div>
      </div>
    </div>
  );
};

PostContentCard.propTypes = {
  postId: PropTypes.string,
  thumbnail: PropTypes.string,
  permalink: PropTypes.string,
  // views: PropTypes.string,
  likes: PropTypes.string,
  comments: PropTypes.string,
  kind: PropTypes.string,
  publishedAt: PropTypes.string,
  title: PropTypes.string,
};

export default PostContentCard;
