import React from 'react';
import { useSpinDelay } from 'spin-delay';
import { useQuery } from '@tanstack/react-query';
import { getHelpAndSupportContent } from 'api/common/informativeContent';
import { LoaderWithWrapper } from 'components/common/Loader';
import styles from 'assets/scss/pages/static-content.module.scss';
import { dotImage } from 'constants/images';

const HelpAndSupport = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['help-and-support'],
    queryFn: () => getHelpAndSupportContent(),
    select: (data) => data?.data?.rows?.[0],
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  console.log(data, 'help');

  return (
    <section>
      <div className={styles.headingContainer}>
        <h2 className={styles.headingTxt}>Help & Support</h2>
      </div>
      <div className={styles.container} style={{ position: 'relative' }}>
        <div className={styles.dotBg}>
          <img className="dotImg" src={dotImage} alt="" />
        </div>
        <div className={styles.dotBg2}>
          <img className="dotImg" src={dotImage} alt="" />
        </div>
        <div className={styles.detailOuterContainer}>
          {isLoading || delayedIsLoading ? (
            <LoaderWithWrapper show={delayedIsLoading} />
          ) : (
            <>
              {data?.is_content_url ? (
                <iframe
                  src={data?.content}
                  style={{ height: '85vh', width: '100%', borderRadius: 10 }}
                  title="help docment"
                />
              ) : (
                <div
                  className={styles.detailContainer}
                  dangerouslySetInnerHTML={{ __html: data?.content }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default HelpAndSupport;
