import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = {
  token: null,
  user: null,
};

export const useInfluencerAuth = create(
  persist(
    (set) => ({
      ...initialState,
      setToken: (token) => set((state) => ({ ...state, token })),
      setUserData: (user) => set((state) => ({ ...state, user })),
      reset: () => set(initialState),
    }),
    {
      name: 'influencer-auth',
    }
  )
);
