import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './Chip.module.scss';

const colors = {
  gray: styles.gray,
  green: styles.green,
  yellow: styles.yellow,
  blue: styles.blue,
  violet: styles.violet,
  red: styles.red,
};

const sizes = {
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
};

const Chip = ({ value, outlined = true, size = 'small', color = 'gray' }) => (
  <div
    className={clsx({
      [styles.chip]: true,
      [sizes[size]]: true,
      [colors[color]]: true,
      [styles.outlined]: outlined,
    })}
    role="button"
  >
    <span className={styles.chipText}>{value}</span>
  </div>
);

Chip.propTypes = {
  value: PropTypes.string,
  outlined: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(sizes)),
  color: PropTypes.oneOf(Object.keys(colors)),
};

export default Chip;
