import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'App';
import { getInfCategories, getInfSubCategories } from 'api/brand/search';
import Table from 'components/common/Table';
import FilterDropdown from 'components/common/FilterDropdown';
import TrendingInfluencers from 'components/brand-dashboard/TrendingInfluencers';
import TrendingSubCategories from 'components/brand-dashboard/TrendingSubCategories';
import { Avatar, AvatarGroup } from 'components/common/Avatar';
import Button from 'components/common/Button';
import { FilterChip } from 'components/common/Chip';
import TableError from 'components/common/Table/TableError';
import useFilterProps from 'hooks/useFilterProps';
import useDebounce from 'hooks/useDebounce';
import { PLATFORMS } from 'constants/config/filters';
import styles from 'assets/scss/pages/search.module.scss';
import {
  searchImg,
  cogwheelIcon,
  categoryIcon,
  locationDownIcon,
  ytIcon,
  instaSvg,
  twitterSvg,
  facebookSvg,
} from 'constants/images';

const columns = [
  {
    Header: 'Sub Category',
    accessor: 'category_name',
    // disableSortBy: false,
    width: 220,
    minWidth: 220,
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return (
        <p className={styles.platformTxt}>
          {row?.subcategory?.subcategory_name}
        </p>
      );
    },
  },
  {
    Header: 'Platform',
    accessor: 'platform',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      const icon = useMemo(() => {
        switch (row?.platform) {
          case 'youtube':
            return ytIcon;
          case 'instagram':
            return instaSvg;
          case 'twitter':
            return twitterSvg;
          case 'facebook':
            return facebookSvg;
          default:
            return null;
        }
      }, [row]);
      return icon ? <Avatar src={icon} alt="" /> : null;
    },
  },
  {
    Header: 'Influencers',
    accessor: 'influencers',
    width: 220,
    minWidth: 220,
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;

      return (
        <AvatarGroup total={row?.influencerCount}>
          {row?.influencers?.map((influencer) => (
            <Avatar
              src={influencer?.profile_image}
              alt=""
              key={influencer?.id}
            />
          ))}
        </AvatarGroup>
      );
    },
  },
  {
    Header: 'Action',
    accessor: 'action',
    width: 130,
    minWidth: 130,
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return (
        <div className="actionButton">
          <Link
            to="inf-list"
            state={{
              subCategories: [row?.subcategory?.id],
              platform: row?.platform,
            }}
            className={styles.viewAllBtn}
            type="button"
          >
            View All
            <img className={styles.rightArrow} src={locationDownIcon} alt="" />
          </Link>
        </div>
      );
    },
  },
];

export const prefectchSubCategories = ({
  page = 1,
  rowsPerPage = 10,
  search = '',
  categoriesFilter = null,
  platformFilter = 'instagram',
} = {}) => {
  queryClient.prefetchQuery({
    queryKey: [
      'brand-sub-categories',
      page,
      rowsPerPage,
      search,
      categoriesFilter?.toString(),
      platformFilter,
    ],
    queryFn: () =>
      getInfSubCategories({
        pageNo: page,
        pageSize: rowsPerPage,
        search: search || undefined,
        category_id: categoriesFilter || undefined,
        is_youtube: platformFilter === 'youtube' || undefined,
        is_instagram: platformFilter === 'instagram' || undefined,
        is_facebook: platformFilter === 'facebook' || undefined,
        is_twitter: platformFilter === 'twitter' || undefined,
      }),
    staleTime: 30 * 60 * 1000, // 30 minute in milliseconds
    cacheTime: 60 * 60 * 1000, // 60 minute in milliseconds});
  });
};

const InfluencerCategories = () => {
  const {
    page,
    rowsPerPage,
    changePage,
    changeRowsPerPage,
    search,
    changeSearch,
    // sortBy,
    // sortDir,
    // changeSortBy,
    filter,
    changeFilter,
  } = useFilterProps({ defaultFilters: { platform: 'instagram' } });

  const platformFilter = filter.platform;
  const categoriesFilter = filter.categories;

  const debouncedSearchQuery = useDebounce(search);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      'brand-sub-categories',
      page,
      rowsPerPage,
      debouncedSearchQuery,
      filter.categories?.toString(),
      platformFilter,
    ],
    queryFn: () =>
      getInfSubCategories({
        pageNo: page,
        pageSize: rowsPerPage,
        search: debouncedSearchQuery || undefined,
        category_id: categoriesFilter || undefined,
        is_youtube: platformFilter === 'youtube' || undefined,
        is_instagram: platformFilter === 'instagram' || undefined,
        is_facebook: platformFilter === 'facebook' || undefined,
        is_twitter: platformFilter === 'twitter' || undefined,
      }),
    staleTime: 30 * 60 * 1000, // 30 minute in milliseconds
    cacheTime: 60 * 60 * 1000, // 60 minute in milliseconds
  });

  const { data: categories } = useQuery({
    queryKey: ['brand-categories'],
    queryFn: () => getInfCategories(),
  });

  const onPlatformChange = (newValue) => {
    changeFilter('platform', newValue);
  };

  const onCategoriesChange = (newValue) => {
    changeFilter('categories', newValue);
  };

  const onSearchChange = (e) => {
    changeSearch(e.target.value);
  };

  const onCategoryRemove = (id) => {
    onCategoriesChange(
      categoriesFilter?.filter((categoryId) => categoryId !== id)
    );
  };

  const onClearFilter = () => {
    changeFilter('categories', []);
  };

  // const onPlatformRemove = () => {
  //   onPlatformChange('');
  // };

  const selectdCategories = useMemo(
    () =>
      categories?.filter((category) => categoriesFilter?.includes(category.id)),
    [categoriesFilter, categories]
  );

  const selectedPlatform = useMemo(
    () => PLATFORMS.find((platform) => platform.value === platformFilter),
    [platformFilter]
  );

  const subCategories = useMemo(
    () =>
      data?.data?.result?.rows?.map((inf = {}) => {
        inf.platform = platformFilter;
        return inf;
      }),
    [data, platformFilter]
  );

  const subCategoriesCount = data?.data?.result?.count;

  const showClearButton = selectdCategories?.length > 0;
  return (
    <>
      <div className={styles.influencerHeader}>
        <h1 className={styles.inflencrTxt}>Find An Influencer</h1>
        <div className={styles.categorySelector}>
          <FilterDropdown
            options={PLATFORMS}
            label="Platform"
            startIconUrl={cogwheelIcon}
            multiple={false}
            onChange={onPlatformChange}
            value={platformFilter}
          />
          <FilterDropdown
            options={categories}
            label="Categories"
            startIconUrl={categoryIcon}
            labelSelector="name"
            valueSelector="id"
            onChange={onCategoriesChange}
            value={categoriesFilter}
          />
        </div>
      </div>
      <div className="influencerList">
        <div className={styles.influencerSelectors}>
          {selectedPlatform ? (
            <FilterChip
              icon={selectedPlatform.icon}
              label={selectedPlatform.label}
              isActive
            />
          ) : null}
          {selectdCategories?.map(({ id, name, image }) => (
            <FilterChip
              icon={image}
              label={name}
              key={id}
              value={id}
              onRemove={onCategoryRemove}
            />
          ))}

          {showClearButton && (
            <Button variant="text" onClick={onClearFilter}>
              Clear Filters
            </Button>
          )}
        </div>
      </div>
      <div className={styles.influencerCardList}>
        <div className={styles.influencerCardSection1}>
          <TrendingInfluencers platformFilter={platformFilter} />
          <TrendingSubCategories />
        </div>
        <div className={styles.influencerCardSection2}>
          <div className={styles.subCategoryTitle}>
            <h2 className={styles.trendingTxt}>Sub Categories</h2>
            <div className={styles.searchField}>
              <img className={styles.sIcon} src={searchImg} alt="" />
              <input
                className={styles.searchTxt}
                placeholder="Type to search list below"
                type="text"
                value={search || ''}
                onChange={onSearchChange}
              />
            </div>
          </div>
          <Table
            data={subCategories}
            columns={columns}
            paginationProps={{
              currentPage: page,
              changePage,
              count: subCategoriesCount,
              rowsPerPage,
              changeRowsPerPage,
            }}
            noDataValue={
              <TableError infoText="No categories match the search criteria." />
            }
            isLoading={isLoading || isFetching}
            // onSortChange={changeSortBy}
            // initialState={{
            //   sortBy: [{ id: sortBy, desc: sortDir === 'desc' }],
            // }}
          />
        </div>
      </div>
    </>
  );
};

export default InfluencerCategories;
