import { baseQuery } from './baseQuery';

const apiVersion = process.env.REACT_APP_API_VERSION;

export const getCampaigns = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/campaigns`,
    method: 'GET',
    params,
  });

export const createCampaign = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/add-campaign`,
    method: 'POST',
    body,
  });

export const editCampaign = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/edit-campaign`,
    method: 'PUT',
    body,
  });

export const deleteCampaign = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/delete-campaign`,
    method: 'DELETE',
    body,
  });

export const getCampaignDetails = ({ id } = {}) =>
  baseQuery({
    url: `/users/${apiVersion}/campaigns/${id}`,
    method: 'GET',
  });

export const getCampaignLiveTrack = ({ campaignId, platform }, params) =>
  baseQuery({
    url: `/users/${apiVersion}/campaigns/${campaignId}/live-tracking/${platform}`,
    method: 'GET',
    params,
  });

export const updateCampaignStatus = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/edit-campaign-status`,
    method: 'PUT',
    body,
  });

export const requestCampaignQuote = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/shortlist-influencer-campaign`,
    method: 'POST',
    body,
  });

export const getCampaignQuotes = ({ id } = {}) =>
  baseQuery({
    url: `/users/${apiVersion}/campaigns/${id}/quotations`,
    method: 'GET',
  });

export const getCampaignQuoteDetails = ({ campaignId, quoteId } = {}) =>
  baseQuery({
    url: `/users/${apiVersion}/campaigns/${campaignId}/quotations/${quoteId}`,
    method: 'GET',
  });

export const downloadCampaignQuotePdf = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/quotation-pdf`,
    method: 'GET',
    params,
    responseType: 'blob',
  });

export const updateCampaignQuoteStatus = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/accept-reject-quotation`,
    method: 'PUT',
    body,
  });

export const getCampaignInfluencers = ({ id, ...params } = {}) =>
  baseQuery({
    url: `/users/${apiVersion}/campaigns/${id}/influencers`,
    method: 'GET',
    params,
  });

export const getCampaignMeetingNotes = ({ id, ...params } = {}) =>
  baseQuery({
    url: `/users/${apiVersion}/campaigns/${id}/meetingnotes`,
    method: 'GET',
    params,
  });

export const downloadCampaignMeetingNote = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/download-meeting-notes`,
    method: 'GET',
    params,
    responseType: 'blob',
  });

export const getCheckoutDetails = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/payment-checkout-detail`,
    method: 'GET',
    params,
  });

export const getBrands = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/brands`,
    method: 'GET',
    params,
    transformResponse: (res) =>
      res?.data?.brands?.map((brand) => ({
        name: brand,
      })),
  });

export const addTag = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/social-tags`,
    method: 'POST',
    body,
  });

export const getTags = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/social-tags`,
    method: 'GET',
    params,
    transformResponse: (res) =>
      res?.data?.social_tags?.map(({ id, hashtag_name: name }) => ({
        name,
        id,
      })),
  });

export const createTag = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/social-tags`,
    method: 'POST',
    body,
  });

export const getCampaignDashboard = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/brand-dashboard`,
    method: 'GET',
    params,
    transformResponse: (res) => res?.data,
  });

export const getExpectedReach = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/expected-reach`,
    method: 'POST',
    body,
    transformResponse: (res) => res?.data,
  });
