import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getProfile, getRateCard } from 'api/influencer/profile';
import styles from 'assets/scss/pages/profile-dashboard.module.scss';
import { formatCurrency } from 'utils';
import { editIcon } from 'constants/images';
import { DIALOGS } from 'constants/config/dialog-names';
import { useDialog } from 'stores/dialog';

const RateCard = () => {
  const setDialog = useDialog((state) => state.setDialog);

  const { data } = useQuery({
    queryKey: ['influencer-rate-card'],
    queryFn: () => getRateCard(),
  });

  const { data: influencer } = useQuery({
    queryKey: ['influencer-profile'],
    queryFn: () => getProfile(),
  });

  const onEdit = () => {
    setDialog(DIALOGS.INF_EDIT_RATE_CARD);
  };

  const {
    stories: instaStory,
    image: instaImagePost,
    reel: instaReel,
    shorts: youtubeShort,
    video_youtube: youtubeVideo,
    posts: facebookPost,
    tweets: twitterTweets,
  } = data || {};

  const {
    is_facebook: isFacebookVerified,
    is_instagram: isInstaVerified,
    is_twitter: isTwitterVerified,
    is_youtube: isYoutubeVerified,
  } = influencer || {};

  return (
    <div className={styles.settingCounter}>
      <div className="settingContainer" style={{ marginTop: 10 }}>
        <div className={styles.notifyHeader}>
          <h3 className={styles.notifyTxt}>Rate Card</h3>
          <button type="button" title="edit rate card" onClick={onEdit}>
            <img src={editIcon} alt="edit" />
          </button>
        </div>

        <div
          className={styles.billingTableContainer}
          style={{ maxWidth: 400, marginTop: 30 }}
        >
          <table className={styles.billingTable}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>Deliverable</th>
                <th>Rate</th>
              </tr>
            </thead>
            {/* TODO: only show fileds for linked accounts */}
            <tbody>
              {isInstaVerified && (
                <>
                  <tr>
                    <td>Instagram Swipe-up Story</td>
                    <td className={styles.amntText}>
                      {instaStory ? formatCurrency(instaStory) : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>Instagram Image Post</td>
                    <td className={styles.amntText}>
                      {instaImagePost ? formatCurrency(instaImagePost) : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>Instagram Reels</td>
                    <td className={styles.amntText}>
                      {instaReel ? formatCurrency(instaReel) : '-'}
                    </td>
                  </tr>
                </>
              )}

              {isFacebookVerified && (
                <tr>
                  <td>Facebook Post</td>
                  <td className={styles.amntText}>
                    {facebookPost ? formatCurrency(facebookPost) : '-'}
                  </td>
                </tr>
              )}
              {isTwitterVerified && (
                <tr>
                  <td>Text Tweet</td>
                  <td className={styles.amntText}>
                    {twitterTweets ? formatCurrency(twitterTweets) : '-'}
                  </td>
                </tr>
              )}
              {isYoutubeVerified && (
                <>
                  <tr>
                    <td>YouTube Video</td>
                    <td className={styles.amntText}>
                      {youtubeVideo ? formatCurrency(youtubeVideo) : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>YouTube Shorts</td>
                    <td className={styles.amntText}>
                      {youtubeShort ? formatCurrency(youtubeShort) : '-'}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RateCard;
