import React, { useEffect, useRef } from 'react';
import { animate, motion } from 'framer-motion';

const UsersOnInstagramPink = (props) => {
  const numberRef = useRef();

  useEffect(() => {
    const node = numberRef.current;
    if (!node) return;

    const controls = animate(0, 47, {
      duration: 1,
      delay: 1,
      onUpdate(value) {
        node.textContent = `${Math.round(value).toString()}%`;
      },
    });

    // eslint-disable-next-line consistent-return
    return () => controls.stop();
  }, []);

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width={202}
      height={202}
      {...props}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.7 }}
    >
      <defs>
        <filter
          id="usersOnInstagramPinkFilter1"
          width={202}
          height={202}
          x={0}
          y={0}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={4} dy={5} />
          <feGaussianBlur result="blur" stdDeviation={5} />
          <feFlood floodColor="#e79ce3" floodOpacity={0.502} />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="usersOnInstagramPinkFilter2"
          width={102}
          height={102}
          x={46}
          y={45}
          filterUnits="userSpaceOnUse"
        >
          <feOffset />
          <feGaussianBlur result="blur-2" stdDeviation={7.5} />
          <feFlood floodColor="#ee18e3" floodOpacity={0.502} result="color" />
          <feComposite in="SourceGraphic" in2="blur-2" operator="out" />
          <feComposite in="color" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <linearGradient
          id="usersOnInstagramPinkGradient1"
          x2={0.984}
          y1={0.5}
          y2={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.607} stopColor="#b584ce" stopOpacity={0.149} />
          <stop offset={1} stopColor="#641582" stopOpacity={0.8} />
        </linearGradient>
      </defs>
      <g filter="url(#usersOnInstagramPinkFilter1)">
        <g
          fill="url(#usersOnInstagramPinkGradient1)"
          stroke="rgba(255,255,255,0.3)"
          strokeWidth={1.5}
          data-name="Ellipse 1524"
          transform="translate(11 10)"
        >
          <circle cx={86} cy={86} r={86} stroke="none" />
          <circle cx={86} cy={86} r={85.25} fill="none" />
        </g>
      </g>
      <g
        fill="none"
        stroke="#ec40f1"
        strokeDasharray="250 1000"
        strokeWidth={35}
        data-name="Ellipse 1526"
        transform="translate(11 10)"
      >
        <circle cx={86} cy={86} r={86} stroke="none" />
        <motion.circle
          cx={86}
          cy={86}
          r={68.5}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 0.47 }}
          transition={{ duration: 1, delay: 1 }}
          transform="rotate(-90, 86, 86)"
        />
      </g>
      <g data-type="innerShadowGroup">
        <g filter="url(#usersOnInstagramPinkFilter2)">
          <circle
            cx={51}
            cy={51}
            r={51}
            fill="#fff"
            data-name="Ellipse 1525"
            transform="translate(46 45)"
          />
        </g>
        <g
          fill="none"
          stroke="rgba(255,255,255,0.3)"
          data-name="Ellipse 1525"
          transform="translate(46 45)"
        >
          <circle cx={51} cy={51} r={51} stroke="none" />
          <circle cx={51} cy={51} r={50.5} />
        </g>
      </g>
      <text
        x={97}
        y={109}
        fill="#fff"
        data-name="47%"
        fontFamily="Poppins-Bold, Poppins"
        fontSize={35}
        fontWeight={700}
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan
          x={61}
          y={109}
          style={{
            fontSize: 35,
            wordSpacing: 0,
          }}
          ref={numberRef}
        />
      </text>
    </motion.svg>
  );
};
export default UsersOnInstagramPink;
