import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as NoDataIcon } from 'assets/images/no-data-image.svg';
import styles from './Table.module.scss';

const TableError = ({ searchQuery, infoText = '' }) => (
  <div className={styles.errorContainer}>
    <NoDataIcon />
    <p className={styles.errorText}>
      {searchQuery ? (
        <>
          No results found for &nbsp;
          <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or
          using complete words.
        </>
      ) : (
        infoText
      )}
    </p>
  </div>
);

TableError.propTypes = {
  searchQuery: PropTypes.string,
  infoText: PropTypes.string,
};

export default TableError;
