import React, { useId } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from 'assets/scss/pages/popup.module.scss';

const RadioInput = React.forwardRef(({ label, ...rest }, ref) => {
  const inputId = useId();
  return (
    <div className={styles.innrTnput}>
      <label className={styles.inputContainer}>
        <span className={styles.genderLabel} htmlFor={inputId}>
          {label}
        </span>
        <input
          className={styles.innrSubIput}
          type="radio"
          {...rest}
          ref={ref}
          id={inputId}
        />
        <span className={clsx(styles.radioField, styles.fieldInf)} />
      </label>
    </div>
  );
});

RadioInput.propTypes = {
  label: PropTypes.node,
};

export default RadioInput;
