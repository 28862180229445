import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import routes from 'constants/routes';

const DOCUSIGN_EVENTS = {
  URL_EXPIRED: 'ttl_expired',
  SIGNED: 'signing_complete',
};

const Docusign = () => {
  const { userType } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const event = searchParams.get('event');
    switch (event) {
      case DOCUSIGN_EVENTS.URL_EXPIRED:
        toast.warning('Contract is already signed.');
        break;
      case DOCUSIGN_EVENTS.SIGNED:
        toast.success('Contract signed successfully.');
        break;
      default:
        break;
    }
    if (userType === 'influencer') {
      navigate(routes.INF_CAMPAIGNS);
    } else if (userType === 'brand') {
      navigate(routes.BRAND_CAMPAIGNS);
    }
  }, [searchParams, navigate, userType]);

  return <></>;
};

export default Docusign;
