import React from 'react';
import clsx from 'clsx';
import { useSpinDelay } from 'spin-delay';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getFaqs } from 'api/common/informativeContent';
import { LoaderWithWrapper } from 'components/common/Loader';
import FaqSection from 'components/common/Faq';
import styles from 'assets/scss/pages/static-content.module.scss';
import { dotBg, dotImage } from 'constants/images';

const Faq = () => {
  const [searchParams] = useSearchParams();

  const type = searchParams.get('type');

  const { data: faqs, isLoading } = useQuery({
    queryKey: ['faqs', type],
    queryFn: () => getFaqs({ sortBy: type }),
    select: (data) => data?.data?.faqs?.rows,
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  return (
    <section>
      <div className={styles.headingContainer}>
        <h2 className={styles.headingTxt}>FAQs</h2>
      </div>
      <div className={styles.container} style={{ position: 'relative' }}>
        <div className={styles.dotBg}>
          <img className="dotImg" src={dotBg} alt="" />
        </div>
        <div className={clsx(styles.dotBg2, styles.backgBg)}>
          <img className="dotImg" src={dotImage} alt="" />
        </div>
        {isLoading || delayedIsLoading ? (
          <LoaderWithWrapper height="50vh" show={delayedIsLoading} />
        ) : (
          faqs?.map(({ question, answer, id }, index) => (
            <FaqSection
              key={id}
              question={question}
              answer={answer}
              defaultOpen={index === 0}
            />
          ))
        )}
      </div>
    </section>
  );
};

export default Faq;
