import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Element } from 'react-scroll';
import { getProfile } from 'api/influencer/profile';
import {
  InfProfileDetailsNavbar,
  PlatformCard,
} from 'components/common/inf-profile';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import { platformIcon } from 'constants/images';

const MyProfileSummary = () => {
  const { data: influencer } = useQuery({
    queryKey: ['influencer-profile'],
    queryFn: () => getProfile(),
  });

  const { instagramProfile, facebookProfile, youtubeProfile, twitterProfile } =
    influencer || {};

  return (
    <>
      <InfProfileDetailsNavbar
        config={[
          {
            title: 'Platforms',
            to: 'platforms',
            icon: platformIcon,
            offset: -200,
          },
        ]}
      />
      <div className="rightCont">
        <Element className={styles.navContent} name="platforms">
          <PlatformCard data={instagramProfile} />
          <PlatformCard data={youtubeProfile} />
          <PlatformCard data={facebookProfile} />
          <PlatformCard data={twitterProfile} />
        </Element>
      </div>
    </>
  );
};

export default MyProfileSummary;
