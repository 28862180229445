import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Popover } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import Slider from 'components/common/Slider';
import { ReactComponent as DownIcon } from 'assets/images/location.svg';
import styles from './FilterDropdown.module.scss';

const FilterMenu = ({
  label,
  startIconUrl,
  children,
  fullWidth,
  filterCount = 0,
}) => (
  <Popover className={styles.wrapper}>
    {label && <p className={styles.label}>{label}</p>}
    <Float placement="bottom-start" offset={4} portal adaptiveWidth>
      <MenuButton
        fullWidth={fullWidth}
        startIconUrl={startIconUrl}
        buttonText={`${filterCount} selected`}
      />
      <Popover.Panel className={styles.selectOptions}>{children}</Popover.Panel>
    </Float>
  </Popover>
);

FilterMenu.propTypes = {
  label: PropTypes.node,
  startIconUrl: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  filterCount: PropTypes.number,
};

const MenuButton = React.forwardRef(
  ({ fullWidth, startIconUrl, buttonText }, ref) => (
    <Popover.Button
      className={styles.selectButton}
      style={{ width: fullWidth ? '100%' : 173 }}
      ref={ref}
    >
      <span className={styles.selectDropdown}>
        <span className={styles.selectItem}>
          {startIconUrl && (
            <img src={startIconUrl} alt="" width="20" height="20" />
          )}
          <span className={styles.selectItemTxt}>{buttonText}</span>
        </span>
      </span>
      <DownIcon height="18" width="18" />
    </Popover.Button>
  )
);

MenuButton.propTypes = {
  fullWidth: PropTypes.bool,
  startIconUrl: PropTypes.string,
  buttonText: PropTypes.string,
};

const RangeSelector = ({
  label,
  popoverLabel,
  minLimit,
  maxLimit,
  onClear,
  onApply,
  currentValue,
  disabled = false,
  isMenuChild = true,
  startIconUrl,
  buttonText,
  fullWidth = false,
  placement = 'left-start',
  adaptiveWidth = false,
  portal = false,
  sliderProps = {},
}) => {
  const [value, setValue] = useState([minLimit, maxLimit]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClear = () => {
    onClear();
    setValue([minLimit, maxLimit]);
  };

  const handleApply = () => {
    onApply(value);
  };

  useEffect(() => {
    if (currentValue) {
      setValue([...currentValue]);
    }
  }, [currentValue]);

  return (
    <Popover className={styles.wrapper}>
      {label && !isMenuChild && <p className={styles.label}>{label}</p>}
      <Float
        placement={placement}
        flip
        offset={4}
        adaptiveWidth={adaptiveWidth}
        portal={portal}
      >
        {isMenuChild ? (
          <Popover.Button
            className={({ open }) =>
              clsx({
                [styles.selectOption]: true,
                [styles.active]: open,
              })
            }
            disabled={disabled}
          >
            {label}
          </Popover.Button>
        ) : (
          <MenuButton
            fullWidth={fullWidth}
            startIconUrl={startIconUrl}
            buttonText={buttonText}
          />
        )}
        <Popover.Panel className={styles.rangeSelectorPopover}>
          <p className={styles.rangeSelectorHeading}>{popoverLabel || label}</p>
          <div className={styles.rangeSliderSection}>
            <Slider
              getAriaLabel={() => popoverLabel || label}
              {...sliderProps}
              value={value}
              onChange={handleChange}
              min={minLimit}
              max={maxLimit}
            />
          </div>
          <div className={styles.rangeInputSection}>
            <div className={styles.rangeFormGroup}>
              <p className={styles.rangeLabel}>Min</p>
              <p className={styles.rangeInput}>{value[0]}</p>
            </div>
            <div className={styles.rangeFormGroup}>
              <p className={styles.rangeLabel}>Max</p>
              <p className={styles.rangeInput}>{value[1]}</p>
            </div>
          </div>
          <div className={styles.rangeSelectorFooter}>
            <Popover.Button
              className={styles.clearButton}
              onClick={handleClear}
            >
              Clear
            </Popover.Button>
            <Popover.Button
              className={styles.applyButton}
              onClick={handleApply}
            >
              Apply
            </Popover.Button>
          </div>
        </Popover.Panel>
      </Float>
    </Popover>
  );
};

RangeSelector.propTypes = {
  label: PropTypes.node,
  popoverLabel: PropTypes.node,
  minLimit: PropTypes.number,
  maxLimit: PropTypes.number,
  onClear: PropTypes.func,
  onApply: PropTypes.func,
  currentValue: PropTypes.array,
  sliderProps: PropTypes.object,
  disabled: PropTypes.bool,
  isMenuChild: PropTypes.bool,
  startIconUrl: PropTypes.string,
  buttonText: PropTypes.string,
  fullWidth: PropTypes.bool,
  placement: PropTypes.string,
  adaptiveWidth: PropTypes.bool,
  portal: PropTypes.bool,
};

FilterMenu.RangeSelector = RangeSelector;

export default FilterMenu;
