import React, { useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from 'recharts';

const PostingBarChart = ({ data, filter, changeFilter }) => (
  <div className="analyticsSection">
    <div className="header">
      <h2 className="title">campaign posting insight</h2>
      <div>
        <ul className="filterList">
          <button
            type="submit"
            className={clsx('filterBtn', filter === 'daily' && 'active')}
            onClick={() => changeFilter('daily')}
          >
            Daily
          </button>
          <button
            type="submit"
            className={clsx('filterBtn', filter === 'weekly' && 'active')}
            onClick={() => changeFilter('weekly')}
          >
            Weekly
          </button>
          <button
            type="submit"
            className={clsx('filterBtn', filter === 'monthly' && 'active')}
            onClick={() => changeFilter('monthly')}
          >
            Monthly
          </button>
          <button
            type="submit"
            className={clsx('filterBtn', filter === 'yearly' && 'active')}
            onClick={() => changeFilter('yearly')}
          >
            Yearly
          </button>
        </ul>
      </div>
    </div>
    <div className="contentWrapper">
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 30,
            right: 0,
            left: 0,
            bottom: 5,
          }}
          barGap={2}
          barSize={32}
        >
          <CartesianGrid vertical={false} strokeWidth={1} stroke="#E6E6E6" />
          <XAxis
            dataKey="date"
            tickLine={false}
            axisLine={false}
            fontSize={14}
            fill="#7B8794"
          />
          <YAxis
            label={
              <Label
                value="Numbers of Campaign Posting"
                angle={270}
                dx={-20}
                fill="#7B8794"
                fontSize={14}
              />
            }
            tickLine={false}
            axisLine={false}
            fontSize={14}
            fill="#7B8794"
          />
          {/* TODO: add content on tooltip and legend to provide custom render function */}
          <Tooltip cursor={{ fill: 'transparent' }} />
          <Bar
            dataKey="total_new_posts"
            fill="#E289F2"
            // radius={4}
            isAnimationActive={false}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  </div>
);

PostingBarChart.propTypes = {
  data: PropTypes.array,
  filter: PropTypes.string,
  changeFilter: PropTypes.func,
};

export default PostingBarChart;
