import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getProfile,
  getRateCard,
  updateRateCard,
} from 'api/influencer/profile';
import { Dialog } from 'components/common/dialog';
import styles from 'assets/scss/pages/search.module.scss';
import { INF_RATE_CARD_SCHEMA } from 'constants/schema';
import { toast } from 'react-toastify';
import { queryClient } from 'App';

const defaultValues = {
  youtubeVideo: '',
  youtubeShort: '',
  instImage: '',
  instStory: '',
  instReel: '',
  fbPost: '',
  twitterTweets: '',
};

const EditRateCard = ({ open, close }) => {
  const { data } = useQuery({
    queryKey: ['influencer-rate-card'],
    queryFn: () => getRateCard(),
  });

  const { data: influencer } = useQuery({
    queryKey: ['influencer-profile'],
    queryFn: () => getProfile(),
  });

  const methods = useForm({
    resolver: yupResolver(INF_RATE_CARD_SCHEMA),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    register,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (data) {
      reset({
        youtubeVideo: data?.video_youtube || '0',
        youtubeShort: data?.shorts || '0',
        instImage: data?.image || '0',
        instStory: data?.stories || '0',
        instReel: data?.reel || '0',
        fbPost: data?.posts || '0',
        twitterTweets: data?.tweets || '0',
      });
    }
  }, [data, reset]);

  const editRateCardMutation = useMutation({
    mutationFn: (data) => {
      const payload = {
        reel: data.instReel,
        stories: data.instStory,
        image: data.instImage,
        video_instagram: data.instVideo,
        video_youtube: data.youtubeVideo,
        shorts: data.youtubeShort,
        posts: data.fbPost,
        tweets: data.twitterTweets,
      };
      return updateRateCard(payload);
    },

    onSuccess: (res) => {
      toast.success(res.message);
      queryClient.invalidateQueries({ queryKey: ['influencer-rate-card'] });
      reset();
      close();
    },
  });

  const {
    is_facebook: isFacebookVerified,
    is_instagram: isInstaVerified,
    is_twitter: isTwitterVerified,
    is_youtube: isYoutubeVerified,
  } = influencer || {};

  return (
    <Dialog
      title="Edit Rate Card"
      open={open}
      close={close}
      size="526px"
      fullWidth
    >
      <form
        className={styles.costTable}
        onSubmit={handleSubmit(editRateCardMutation.mutate)}
        style={{ paddingBottom: 0 }}
      >
        <table className={styles.costContainer}>
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>Deliverable</th>
              <th style={{ textAlign: 'left' }}>Rate</th>
            </tr>
          </thead>
          <tbody>
            {isInstaVerified && (
              <>
                <tr>
                  <td className={styles.socialList}>
                    Instagram Swipe-up Story
                  </td>
                  <td>
                    <div className={styles.rateInputWrapper}>
                      <input
                        type="number"
                        className={styles.rateInput}
                        {...register('instStory')}
                        min="1"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={styles.socialList}>Instagram Image Post</td>
                  <td>
                    <div className={styles.rateInputWrapper}>
                      <input
                        type="number"
                        className={styles.rateInput}
                        {...register('instImage')}
                        min="1"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={styles.socialList}>Instagram Reels</td>
                  <td>
                    <div className={styles.rateInputWrapper}>
                      <input
                        type="number"
                        className={styles.rateInput}
                        {...register('instReel')}
                        min="1"
                      />
                    </div>
                  </td>
                </tr>
              </>
            )}
            {isFacebookVerified && (
              <tr>
                <td className={styles.socialList}>Facebook Post</td>
                <td>
                  <div className={styles.rateInputWrapper}>
                    <input
                      type="number"
                      className={styles.rateInput}
                      {...register('fbPost')}
                      min="1"
                    />
                  </div>
                </td>
              </tr>
            )}
            {isTwitterVerified && (
              <tr>
                <td className={styles.socialList}>Text Tweet</td>
                <td>
                  <div className={styles.rateInputWrapper}>
                    <input
                      type="number"
                      className={styles.rateInput}
                      {...register('twitterTweets')}
                      min="1"
                    />
                  </div>
                </td>
              </tr>
            )}
            {isYoutubeVerified && (
              <>
                <tr>
                  <td className={styles.socialList}>YouTube Video</td>
                  <td>
                    <div className={styles.rateInputWrapper}>
                      <input
                        type="number"
                        className={styles.rateInput}
                        {...register('youtubeVideo')}
                        min="1"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={styles.socialList}>YouTube Shorts</td>
                  <td>
                    <div className={styles.rateInputWrapper}>
                      <input
                        type="number"
                        className={styles.rateInput}
                        {...register('youtubeShort')}
                        min="1"
                      />
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
        <div className={styles.selectInfFooter}>
          <div className={styles.crBtn} style={{ marginTop: '16px' }}>
            <button type="button" className={styles.prevBtn} onClick={close}>
              Back
            </button>
            <button
              type="submit"
              style={{ width: 144 }}
              className={styles.updateBtn}
              disabled={isSubmitting}
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

EditRateCard.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default EditRateCard;
