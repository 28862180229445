import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
  ReferenceLine,
} from 'recharts';
import InfoTooltip from 'components/common/Tooltip';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import { formatNumber } from 'utils';
import { NoData } from 'components/common/NoData';

const ContentPerformanceAndBenchmark = ({
  data = [],
  valueKey = 'views',
  categoryAverage,
  influencerAverage,
}) => (
  <div className={styles.navCategory}>
    <div className={styles.sectionHeader} style={{ gap: '5px' }}>
      <h3 className={styles.followerLabel}>CONTENT PERFORMANCE & BENCHMARKS</h3>
      <InfoTooltip label="Indicates the engagement of recently uploaded posts" />
    </div>
    <div className={styles.performanceAverageBrief}>
      {categoryAverage ? (
        <div className={styles.datasetPill}>
          <div className={styles.categoryDashedIcon} />
          <div className={styles.datasetInfo}>
            <span className={styles.datasetName}>Category Average -</span>
            <span className={styles.datasetValue}>
              {formatNumber(categoryAverage, {
                maximumFractionDigits: 0,
                notation: 'standard',
                style: 'decimal',
              })}
            </span>
          </div>
        </div>
      ) : null}
      {influencerAverage ? (
        <div className={styles.datasetPill}>
          <div className={styles.creatorDashedIcon} />
          <div className={styles.datasetInfo}>
            <span className={styles.datasetName}>Creator's Average -</span>
            <span className={styles.datasetValue}>
              {formatNumber(influencerAverage, {
                maximumFractionDigits: 0,
                notation: 'standard',
                style: 'decimal',
              })}
            </span>
          </div>
        </div>
      ) : null}
    </div>
    <div className={styles.benchmarkGraph}>
      {data?.length ? (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 30,
              right: 0,
              left: 0,
              bottom: 5,
            }}
            barGap={2}
            barSize={32}
          >
            <CartesianGrid vertical={false} strokeWidth={1} stroke="#E6E6E6" />

            {categoryAverage ? (
              <ReferenceLine
                y={categoryAverage}
                // label="Category Average"
                stroke="#7C287D"
                strokeDasharray="6 3"
                strokeWidth={2}
              />
            ) : null}
            {influencerAverage ? (
              <ReferenceLine
                y={influencerAverage}
                // label="Creator's Average"
                stroke="#FB6EFF"
                strokeDasharray="6 3"
                strokeWidth={2}
              />
            ) : null}

            <XAxis
              label={
                <Label value="Posts" dy={10} fill="#7B8794" fontSize={14} />
              }
              dataKey="videoId"
              tick={false}
              tickLine={false}
              axisLine={false}
              fontSize={14}
              fill="#7B8794"
            />
            <YAxis
              label={
                <Label
                  value="Engagements"
                  angle={270}
                  fill="#7B8794"
                  fontSize={14}
                  dx={-6}
                />
              }
              dataKey={valueKey}
              tickFormatter={() => ''}
              tickLine={false}
              axisLine={false}
              fontSize={14}
              fill="#7B8794"
              width={30}
            />
            <Tooltip
              wrapperStyle={{ outline: 'none' }}
              cursor={{ fill: 'transparent' }}
              content={<CustomTooltip />}
            />
            <Bar
              dataKey={valueKey}
              fill="#7C087F"
              radius={4}
              isAnimationActive={false}
              minPointSize={2}
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <NoData height={400} />
      )}
    </div>
  </div>
);

ContentPerformanceAndBenchmark.propTypes = {
  data: PropTypes.array,
  valueKey: PropTypes.string,
  categoryAverage: PropTypes.number,
  influencerAverage: PropTypes.number,
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          background: '#fff',
          padding: 8,
          border: '1px solid #FF00A233',
          boxShadow: '0px 2px 10px #FF00A24D',
          minWidth: 100,
        }}
      >
        <p
          style={{
            fontSize: 14,
            color: '#7B8794',
            fontWeight: 600,
            textTransform: 'capitalize',
          }}
        >
          {payload[0].name}
        </p>
        <p style={{ fontSize: 14, color: '#d633db', fontWeight: 600 }}>
          {formatNumber(payload[0].value, {
            maximumFractionDigits: 2,
            notation: 'standard',
            style: 'decimal',
          })}
        </p>
      </div>
    );
  }

  return null;
};

export default ContentPerformanceAndBenchmark;
