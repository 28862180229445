import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { getBrandDetails, getBrandPosts } from 'api/brand/brandAnalysis';
import { BrandPostContent } from 'components/brand-dashboard/brand-analysis';
import styles from 'assets/scss/pages/campaign.module.scss';
import clsx from 'clsx';
import { LoaderWithWrapper } from 'components/common/Loader';
import { useInView } from 'framer-motion';

const PAGE_SIZE = 20;

const BrandDetails = () => {
  const { id: brandId, platform } = useParams();
  const loadMoreRef = useRef(null);
  const isLoadMoreInView = useInView(loadMoreRef);

  const { data: brandData } = useQuery({
    queryKey: ['brand-analysis-details', brandId, platform],
    queryFn: () => getBrandDetails(brandId, { media_type: platform }),
  });

  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['brand-analysis-posts', brandId, platform],

      queryFn: ({ pageParam = 1 }) =>
        getBrandPosts(brandData?.brand_name, {
          pageNo: pageParam,
          pageSize: PAGE_SIZE,
          media_type: platform.toLowerCase(),
        }),
      enabled: Boolean(brandData?.brand_name),

      getNextPageParam: (lastpage, allPages) => {
        const totalPages = Math.ceil(lastpage?.count / PAGE_SIZE);

        return allPages.length < totalPages ? allPages.length + 1 : undefined;
      },
    });

  useEffect(() => {
    if (isLoadMoreInView && hasNextPage && !isLoading) {
      fetchNextPage();
    }
  }, [isLoadMoreInView, fetchNextPage, hasNextPage, isLoading]);

  return (
    <>
      <div>
        <div
          className={styles.postWrapper}
          style={{ maxHeight: 'none', height: 'auto' }}
        >
          {data?.pages?.map((page) =>
            page?.rows?.map((post) => (
              <BrandPostContent key={post?.id} {...post} />
            ))
          )}
        </div>
        <div className={clsx(styles.campaignReportContainer, styles.postBody)}>
          {isFetchingNextPage || isLoading ? (
            <LoaderWithWrapper
              height={66}
              show={isFetchingNextPage || isLoading}
            />
          ) : null}
        </div>
        <div style={{ visibility: 'hidden', height: 1 }}>
          <button type="button" onClick={fetchNextPage} ref={loadMoreRef}>
            Load More
          </button>
        </div>
      </div>
    </>
  );
};

export default BrandDetails;
