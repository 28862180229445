import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Listbox } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { ReactComponent as DownIcon } from 'assets/images/down.svg';
import { ReactComponent as CrossIcon } from 'assets/images/cross_white.svg';
import styles from 'assets/scss/pages/popup.module.scss';

const Select = React.forwardRef(
  (
    {
      label,
      options = [],
      onChange,
      value = [],
      labelSelector = 'label',
      valueSelector = 'value',
      noDataText = 'No options found.',
      placeholder,
      multiple = false,
      disabled = false,
      showSelectAll = false,
      ...rest
    },
    ref
  ) => {
    const onRemove = (id, e) => {
      e.preventDefault();
      onChange(value.filter((selectedId) => selectedId !== id));
    };

    const selected = useMemo(
      () =>
        multiple
          ? options.filter((option) => value.includes(option[valueSelector]))
          : options?.find((option) => value === option[valueSelector]) || '',
      [value, options, valueSelector, multiple]
    );

    const onValueChange = (newValues) => {
      if (
        multiple &&
        newValues.includes('selectAll') &&
        selected.length === options.length
      ) {
        onChange([]);
        return;
      }

      if (multiple && newValues.includes('selectAll')) {
        onChange(options.map((option) => option[valueSelector]));
        return;
      }

      if (multiple) {
        onChange(newValues.map((option) => option[valueSelector]));
        return;
      }

      onChange(newValues[valueSelector]);
    };

    return (
      <Listbox
        value={selected}
        onChange={onValueChange}
        className={styles.selectWrapper}
        ref={ref}
        as="div"
        disabled={disabled}
        multiple={multiple}
        {...rest}
      >
        {label ? (
          <Listbox.Label className={styles.hTxt}>{label}</Listbox.Label>
        ) : null}
        <Float placement="bottom-start" flip offset={4} portal adaptiveWidth>
          <Listbox.Button
            className={styles.selectButton}
            style={{ padding: '6px 0 8px 0' }}
          >
            <span className={styles.selectedValues}>
              {multiple ? (
                selected.map((option) => (
                  <span
                    className={styles.selectedValue}
                    key={option[valueSelector]}
                  >
                    <span>{option[labelSelector]}</span>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <CrossIcon
                      onClick={(e) =>
                        disabled
                          ? undefined
                          : onRemove(option[valueSelector], e)
                      }
                      className={styles.cross}
                    />
                  </span>
                ))
              ) : (
                <span className={styles.singleSelected}>
                  {selected[labelSelector]}
                </span>
              )}
              {!selected && (
                <span className={styles.selectPlaceholder}>{placeholder}</span>
              )}
            </span>
            <DownIcon className={styles.dropdownIcon} />
          </Listbox.Button>
          <Listbox.Options className={styles.selectOptions}>
            {options.length > 0 ? (
              <>
                {showSelectAll && multiple && (
                  <Listbox.Option
                    className={({ active }) =>
                      clsx({
                        [styles.selectOption]: true,
                        [styles.selectOptionActive]:
                          selected.length === options.length,
                        [styles.active]: active,
                      })
                    }
                    value="selectAll"
                  >
                    <span className={styles.checkMark} />
                    <span className={styles.optionLabel}>Select All</span>
                  </Listbox.Option>
                )}
                {options.map((option) => (
                  <Listbox.Option
                    key={option[valueSelector]}
                    value={option}
                    className={({ selected, active, disabled }) =>
                      clsx({
                        [styles.selectOption]: true,
                        [styles.selectOptionActive]: selected,
                        [styles.active]: active,
                        [styles.disabled]: disabled,
                      })
                    }
                    disabled={option.disabled}
                  >
                    {multiple && <span className={styles.checkMark} />}
                    <span className={styles.optionLabel}>
                      {option[labelSelector]}
                    </span>
                  </Listbox.Option>
                ))}
              </>
            ) : (
              <Listbox.Option className={styles.selectOption} disabled>
                <span className={styles.optionLabel}>{noDataText}</span>
              </Listbox.Option>
            )}
          </Listbox.Options>
        </Float>
      </Listbox>
    );
  }
);

Select.propTypes = {
  label: PropTypes.node,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  labelSelector: PropTypes.string,
  valueSelector: PropTypes.string,
  noDataText: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  showSelectAll: PropTypes.bool,
};
export default Select;
