import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from 'App';
import { Dialog } from 'components/common/dialog';
import CustomSelect from 'components/common/edit-profile/CustomSelect';
import { editProfile, getProfile } from 'api/brand/profile';
import { getInfCategories } from 'api/brand/search';
import { BRAND_PROFILE_CATEGORIES_SCHEMA } from 'constants/schema';
import styles from 'assets/scss/pages/popup.module.scss';
import ErrorMessage from 'components/common/form/ErrorMessage';

const defaultValues = {
  categories: [],
};

const EditProfileCategories = ({ open, close }) => {
  const { data } = useQuery({
    queryKey: ['brand-profile'],
    queryFn: () => getProfile(),
  });
  const { data: categoriesData } = useQuery({
    queryKey: ['brand-categories'],
    queryFn: () => getInfCategories(),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BRAND_PROFILE_CATEGORIES_SCHEMA),
    defaultValues,
  });

  const editProfileMutation = useMutation({
    mutationFn: ({ categories }) => {
      const payload = { category_data: categories };

      return editProfile(payload);
    },
    onSuccess: (data) => {
      toast.success(data?.message, { position: 'top-center' });
      queryClient.invalidateQueries({ queryKey: ['brand-profile'] });
      reset();
      close();
    },
  });

  useEffect(() => {
    if (data?.data) {
      const { category_data: categories } = data?.data?.getProfile || {};
      reset({ categories });
    }
  }, [data, reset]);

  // FIXME: remove this duplicate filter and send id instead of name to server
  // this is only to fix bug on UAT
  const categories = useMemo(
    () =>
      categoriesData?.reduce((acc, curr) => {
        if (!acc.find((category) => category.name === curr.name))
          acc.push(curr);
        return acc;
      }, []),
    [categoriesData]
  );

  return (
    <Dialog
      title="Edit Categories"
      open={open}
      close={close}
      size="591px"
      fullWidth
    >
      <form onSubmit={handleSubmit(editProfileMutation.mutate)}>
        <div className={styles.editFormWrapper}>
          <div className={styles.formGroup}>
            <Controller
              render={({ field }) => (
                <CustomSelect
                  // label="Category"
                  onChange={field.onChange}
                  value={field.value}
                  options={categories}
                  labelSelector="name"
                  valueSelector="name"
                  ref={field.ref}
                  multiple
                />
              )}
              control={control}
              name="categories"
            />
            <ErrorMessage error={errors?.categories} />
          </div>
        </div>
        <div className={styles.editProfileContainer}>
          <div
            className={clsx(styles.campaignBtns, styles.nxtWrap)}
            style={{ marginTop: '16px' }}
          >
            <button type="button" className={styles.prevBtn} onClick={close}>
              Back
            </button>
            <button
              type="submit"
              style={{ width: '30%' }}
              className={styles.updateBtn}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

EditProfileCategories.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default EditProfileCategories;
