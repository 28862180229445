import PropTypes from 'prop-types';
import styles from './form.module.scss';

const ErrorMessage = ({ error, id }) => {
  if (error) {
    return (
      <p className={styles.error} id={id} role="alert">
        {error.message}
      </p>
    );
  }

  return null;
};

ErrorMessage.propTypes = {
  error: PropTypes.object,
  id: PropTypes.string,
};

export default ErrorMessage;
