import React from 'react';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import PropTypes from 'prop-types';

const ProgressBar = ({ label, value }) => (
  <div className={styles.customBar}>
    <div className={styles.dataBrief}>
      <p className={styles.dataLabel}>{label}</p>
      <p className={styles.dataShare}>{value}%</p>
    </div>
    <div className={styles.dataBar}>
      <div className={styles.progressB}>
        <span
          style={{
            background: '#8F64CE',
            width: `${value}%`,
          }}
          className={styles.progressBar}
        />
      </div>
    </div>
  </div>
);

ProgressBar.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
};

export default ProgressBar;
