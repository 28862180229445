import React, { useState } from 'react';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { Combobox } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { useQuery } from '@tanstack/react-query';
import { getInfluencersUpdated } from 'api/brand/search';
import useDebounce from 'hooks/useDebounce';
import styles from 'assets/scss/pages/compare-influencer.module.scss';
import { Avatar } from 'components/common/Avatar';

const page = 1;
const rowsPerPage = 10;

const InfluencerSearch = () => {
  const [query, setQuery] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const debouncedSearchQuery = useDebounce(query);
  const platform = searchParams.get('platform') || 'instagram';

  const { data } = useQuery({
    queryKey: [
      'brand-influencer-list',
      page,
      rowsPerPage,
      debouncedSearchQuery,
      platform,
    ],
    queryFn: () =>
      getInfluencersUpdated({
        pageNo: page,
        pageSize: rowsPerPage,
        search: debouncedSearchQuery || undefined,
        is_youtube: platform ? platform === 'youtube' : undefined,
        is_instagram: platform ? platform === 'instagram' : undefined,
        is_facebook: platform ? platform === 'facebook' : undefined,
        is_twitter: platform ? platform === 'twitter' : undefined,
      }),
    keepPreviousData: true,
  });

  const onInfSelect = (selectedInfluencer) => {
    setSearchParams((prev) => {
      const existingInfluencers = prev.get('ids')?.split(',');
      const isInfAlreadyExists = existingInfluencers?.find(
        (inf) => inf === selectedInfluencer
      );

      if (isInfAlreadyExists) {
        return prev;
      }
      prev.set('ids', [...existingInfluencers, selectedInfluencer]);

      return prev;
    });
  };

  const influencers = data?.data?.influencers?.rows;

  return (
    <Combobox
      value=""
      onChange={onInfSelect}
      className={styles.searchContent}
      as="div"
    >
      <Float placement="bottom" flip offset={2} adaptiveWidth portal>
        <Combobox.Input
          onChange={(event) => setQuery(event.target.value)}
          className={styles.inputSearch}
          placeholder="Enter Influencer Name"
          type="text"
        />
        <Combobox.Options className={styles.selectOptions}>
          {influencers?.length ? (
            influencers.map(
              ({ id, full_name: fullName, profile_image: profileImage }) => (
                <Combobox.Option
                  key={id}
                  value={id}
                  className={({ selected, active }) =>
                    clsx({
                      [styles.selectOption]: true,
                      [styles.active]: selected || active,
                    })
                  }
                >
                  <Avatar size="small" src={profileImage} />
                  <span>{fullName}</span>
                </Combobox.Option>
              )
            )
          ) : (
            <Combobox.Option disabled className={styles.selectOption}>
              No influencers found.
            </Combobox.Option>
          )}
        </Combobox.Options>
      </Float>
    </Combobox>
  );
};

export default InfluencerSearch;
