const useFacebookAuth = () => {
  const onFacebookLogin = () => {
    const facebookAuthUrl = new URL(
      'https://www.facebook.com/v16.0/dialog/oauth'
    );

    facebookAuthUrl.searchParams.append('response_type', 'code');
    facebookAuthUrl.searchParams.append(
      'client_id',
      process.env.REACT_APP_FB_CLIENT_ID
    );
    facebookAuthUrl.searchParams.append(
      'redirect_uri',
      process.env.REACT_APP_FB_REDIRECT_URL
    );
    facebookAuthUrl.searchParams.append(
      'scope',
      process.env.REACT_APP_FB_AUTH_SCOPE
    );
    facebookAuthUrl.searchParams.append('state', 'state'); // TODO: make this dynamic it should be unique for each user
    window.location = facebookAuthUrl;
  };
  return { onFacebookLogin };
};

export default useFacebookAuth;
