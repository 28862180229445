import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSpinDelay } from 'spin-delay';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useExpanded, useTable } from 'react-table';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  updateCampaignQuoteStatus,
  downloadCampaignQuotePdf,
  getCampaignQuoteDetails,
} from 'api/brand/campaign';
import { queryClient } from 'App';
import { useDialog } from 'stores/dialog';
import { Avatar } from 'components/common/Avatar';
import routes from 'constants/routes';
import styles from 'assets/scss/pages/campaign-quotation.module.scss';
import { ReactComponent as EyeIcon } from 'assets/images/feather-eye.svg';
import { ReactComponent as DownIcon } from 'assets/images/down.svg';
import { locationDownIcon, whiteDownloadIcon } from 'constants/images';
import { DIALOGS } from 'constants/config/dialog-names';
import { formatCurrency, saveFile } from 'utils';
import { LoaderWithWrapper } from 'components/common/Loader';
import {
  CAMPAIGN_QUOTE_ACTIONS,
  CAMPAIGN_QUOTE_STATUS,
} from 'constants/config/campaign';
import { PLATFORM_ICONS } from 'constants/config/platformIcons';
import { AlertDialog } from 'components/common/dialog';
import RequestRevisionDialog from 'components/brand-dashboard/campaigns/RequestRevisionDialog';

const CampaignQuotationDetails = () => {
  const { id: campaignId, quoteId } = useParams();
  const navigate = useNavigate();

  const { data: quoteData, isLoading } = useQuery({
    queryKey: ['brand-campaign-quote-details', campaignId, quoteId],
    queryFn: () => getCampaignQuoteDetails({ campaignId, quoteId }),
    select: (res) => res?.data?.quotation,
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  const downloadQuoteMutation = useMutation({
    mutationFn: ({ campaignId }) => downloadCampaignQuotePdf({ campaignId }),
    onSuccess: (res) => {
      saveFile(
        res,
        'application/pdf',
        `campaign_quote${format(new Date(), 'dd_MMM_yyyy_hh_mm_aaa')}`
      );
    },
    onError: async (error) => {
      if (error.response?.data) {
        const errorResponse = await JSON.parse(
          await error.response.data.text()
        );
        toast.error(errorResponse.message);
      }
    },
  });

  const updateQuoteStatusMutation = useMutation({
    mutationFn: ({ quoteId, action, reason }) =>
      updateCampaignQuoteStatus({ quoteId, action, reason }),
    onSuccess: (res, { campaignId, action }) => {
      queryClient.invalidateQueries({
        queryKey: ['brand-campaigns'],
      });
      queryClient.invalidateQueries({
        queryKey: ['brand-campaign-details', campaignId],
      });
      queryClient.invalidateQueries({
        queryKey: ['brand-campaign-quotes', campaignId],
      });
      queryClient.invalidateQueries({
        queryKey: ['brand-campaign-quote-details', campaignId],
      });
      toast.success(res?.message, {
        position: 'top-center',
      });

      if (
        action === CAMPAIGN_QUOTE_ACTIONS.DROP ||
        action === CAMPAIGN_QUOTE_ACTIONS.DROP
      ) {
        navigate('../', { replace: true });
      }
    },
  });

  const onProceedToPay = () => {
    navigate(`payment-and-checkout`);
  };

  const influencerDeliverablesCosts = useMemo(
    () =>
      quoteData?.campaign?.campaign_influencers?.map((influencerData) => {
        const {
          influencer,
          deliverables = [],
          cost,
          reel_unitprice: instaReelsUnitPrice,
          stories_unitprice: instaStoriesUnitPrice,
          video_instagram_unitprice: instaVideosUnitPrice,
          image_unitprice: instaImagesUnitPrice,
          shorts_unitprice: youtubeShortsUnitPrice,
          video_youtube_unitprice: youtubeVideosUnitPrice,
          posts_unitprice: facebookPostsUnitPrice,
          tweets_unitprice: twitterTweetsUnitPrice,
        } = influencerData;
        const platforms = [];
        const instaDeliverablesCostBreakdown = [];
        const youtubeDeliverablesCostBreakdown = [];
        const facebookDeliverablesCostBreakdown = [];
        const twitterDeliverablesCostBreakdown = [];

        const costBreakdownByPlatform = deliverables.map((deliverable) => {
          switch (deliverable.media_type) {
            case 'instagram':
              platforms.push('instagram');
              if (deliverable?.reel) {
                const qty = Number(deliverable.reel);
                const unitPrice = Number(instaReelsUnitPrice);
                const total = qty * unitPrice;
                instaDeliverablesCostBreakdown.push({
                  type: 'reel',
                  qty,
                  unitPrice,
                  total,
                });
              }
              if (deliverable?.stories) {
                const qty = Number(deliverable.stories);
                const unitPrice = Number(instaStoriesUnitPrice);
                const total = qty * unitPrice;
                instaDeliverablesCostBreakdown.push({
                  type: 'story',
                  qty,
                  unitPrice,
                  total,
                });
              }
              if (deliverable?.video) {
                const qty = Number(deliverable.video);
                const unitPrice = Number(instaVideosUnitPrice);
                const total = qty * unitPrice;
                instaDeliverablesCostBreakdown.push({
                  type: 'video',
                  qty,
                  unitPrice,
                  total,
                });
              }
              if (deliverable?.image) {
                const qty = Number(deliverable.image);
                const unitPrice = Number(instaImagesUnitPrice);
                const total = qty * unitPrice;
                instaDeliverablesCostBreakdown.push({
                  type: 'image',
                  qty,
                  unitPrice,
                  total,
                });
              }
              return {
                platform: 'instagram',
                totalQty: instaDeliverablesCostBreakdown.reduce(
                  (acc, current) => acc + current.qty,
                  0
                ),
                total: instaDeliverablesCostBreakdown.reduce(
                  (acc, current) => acc + current.total,
                  0
                ),
                deliverablesCostBreakdown: instaDeliverablesCostBreakdown,
              };
            case 'youtube':
              platforms.push('youtube');

              if (deliverable?.video) {
                const qty = Number(deliverable.video);
                const unitPrice = Number(youtubeVideosUnitPrice);
                const total = qty * unitPrice;
                youtubeDeliverablesCostBreakdown.push({
                  type: 'video',
                  qty,
                  unitPrice,
                  total,
                });
              }
              if (deliverable?.shorts) {
                const qty = Number(deliverable.shorts);
                const unitPrice = Number(youtubeShortsUnitPrice);
                const total = qty * unitPrice;
                youtubeDeliverablesCostBreakdown.push({
                  type: 'short',
                  qty,
                  unitPrice,
                  total,
                });
              }
              return {
                platform: 'youtube',
                totalQty: youtubeDeliverablesCostBreakdown.reduce(
                  (acc, current) => acc + current.qty,
                  0
                ),
                total: youtubeDeliverablesCostBreakdown.reduce(
                  (acc, current) => acc + current.total,
                  0
                ),
                deliverablesCostBreakdown: youtubeDeliverablesCostBreakdown,
              };
            case 'facebook':
              platforms.push('facebook');
              // posts
              if (deliverable?.posts) {
                const qty = Number(deliverable.posts || 0);
                const unitPrice = Number(facebookPostsUnitPrice || 0);
                const total = qty * unitPrice;
                facebookDeliverablesCostBreakdown.push({
                  type: 'posts',
                  qty,
                  unitPrice,
                  total,
                });
              }
              return {
                platform: 'facebook',
                totalQty: facebookDeliverablesCostBreakdown.reduce(
                  (acc, current) => acc + current.qty,
                  0
                ),
                total: facebookDeliverablesCostBreakdown.reduce(
                  (acc, current) => acc + current.total,
                  0
                ),
                deliverablesCostBreakdown: facebookDeliverablesCostBreakdown,
              };
            case 'twitter':
              platforms.push('twitter');
              // posts
              if (deliverable?.tweets) {
                const qty = Number(deliverable.tweets || 0);
                const unitPrice = Number(twitterTweetsUnitPrice || 0);
                const total = qty * unitPrice;
                twitterDeliverablesCostBreakdown.push({
                  type: 'tweets',
                  qty,
                  unitPrice,
                  total,
                });
              }
              return {
                platform: 'twitter',
                totalQty: twitterDeliverablesCostBreakdown.reduce(
                  (acc, current) => acc + current.qty,
                  0
                ),
                total: twitterDeliverablesCostBreakdown.reduce(
                  (acc, current) => acc + current.total,
                  0
                ),
                deliverablesCostBreakdown: twitterDeliverablesCostBreakdown,
              };
            default:
              return {};
          }
        });

        return {
          id: influencer.id,
          fullName: influencer.full_name,
          profileImage: influencer.profile_image,
          totalDeliverables: costBreakdownByPlatform.reduce(
            (acc, current) => acc + current.totalQty,
            0
          ),
          totalCost: Number(cost),
          platforms,
          costBreakdownByPlatform,
        };
      }) || [],
    [quoteData]
  );

  const paymentSummaries = useMemo(() => {
    const summariesArray = [];
    const summaryData = quoteData?.summary;
    if (summaryData) {
      const instagramBreakdown = [];
      const youtubeBreakdown = [];
      const facebookBreakdown = [];
      const twitterBreakdown = [];

      // instagram deliverables
      if (summaryData.reelCount?.qty) {
        instagramBreakdown.push({
          name: 'Reels',
          qty: Number(summaryData.reelCount.qty),
          total: Number(summaryData.reelCount.cost),
        });
      }
      if (summaryData.storiesCount?.qty) {
        instagramBreakdown.push({
          name: 'Stories',
          qty: Number(summaryData.storiesCount.qty),
          total: Number(summaryData.storiesCount.cost),
        });
      }
      if (summaryData.videoInstaCount?.qty) {
        instagramBreakdown.push({
          name: 'Videos',
          qty: Number(summaryData.videoInstaCount.qty),
          total: Number(summaryData.videoInstaCount.cost),
        });
      }
      if (summaryData.imageCount?.qty) {
        instagramBreakdown.push({
          name: 'Images',
          qty: Number(summaryData.imageCount.qty),
          total: Number(summaryData.imageCount.cost),
        });
      }
      if (instagramBreakdown.length > 0) {
        summariesArray.push({
          name: `Instagram ${instagramBreakdown
            .map(({ name }) => name)
            .join(', ')}`,
          qty: instagramBreakdown.reduce(
            (qty, current) => qty + current.qty,
            0
          ),
          total: instagramBreakdown.reduce(
            (total, current) => total + current.total,
            0
          ),
          subRows: instagramBreakdown,
        });
      }

      // youtube deliverables
      if (summaryData.shortsCount?.qty) {
        youtubeBreakdown.push({
          name: 'Shorts',
          qty: Number(summaryData.shortsCount.qty),
          total: Number(summaryData.shortsCount.cost),
        });
      }
      if (summaryData.videoYoutubeCount?.qty) {
        youtubeBreakdown.push({
          name: 'Videos',
          qty: Number(summaryData.videoYoutubeCount.qty),
          total: Number(summaryData.videoYoutubeCount.cost),
        });
      }
      if (youtubeBreakdown.length > 0) {
        summariesArray.push({
          name: `Youtube ${youtubeBreakdown
            .map(({ name }) => name)
            .join(', ')}`,
          qty: youtubeBreakdown.reduce((qty, current) => qty + current.qty, 0),
          total: youtubeBreakdown.reduce(
            (total, current) => total + current.total,
            0
          ),
          subRows: youtubeBreakdown,
        });
      }

      // facebook deliverables
      if (summaryData.postsCount?.qty) {
        facebookBreakdown.push({
          name: 'Posts',
          qty: Number(summaryData.postsCount.qty),
          total: Number(summaryData.postsCount.cost),
        });
      }
      if (facebookBreakdown.length > 0) {
        summariesArray.push({
          name: `Facebook ${facebookBreakdown
            .map(({ name }) => name)
            .join(', ')}`,
          qty: facebookBreakdown.reduce((qty, current) => qty + current.qty, 0),
          total: facebookBreakdown.reduce(
            (total, current) => total + current.total,
            0
          ),
          subRows: facebookBreakdown,
        });
      }
      // twitter deliverables
      if (summaryData.tweetsCount?.qty) {
        twitterBreakdown.push({
          name: 'Tweets',
          qty: Number(summaryData.tweetsCount.qty),
          total: Number(summaryData.tweetsCount.cost),
        });
      }
      if (twitterBreakdown.length > 0) {
        summariesArray.push({
          name: `Twitter ${twitterBreakdown
            .map(({ name }) => name)
            .join(', ')}`,
          qty: twitterBreakdown.reduce((qty, current) => qty + current.qty, 0),
          total: twitterBreakdown.reduce(
            (total, current) => total + current.total,
            0
          ),
          subRows: twitterBreakdown,
        });
      }
    }

    return summariesArray;
  }, [quoteData]);

  const {
    quotation_id: quotationId,
    quotation_status: quoteStatus,
    campaign_name: campaignName,
    // payment_status: paymentStatus,
    duration: campaignDurationInDays,
    region: regionString,
    age_group: ageGroupString,
    total: grandTotal,
    address,
    full_name: clientFullName,
    email: clientEmail,
    mobile: clientMobile,
    submitted_by: submittedBy,
    campaign: campaignData,
  } = quoteData || {};

  if (isLoading || delayedIsLoading) {
    return <LoaderWithWrapper show={delayedIsLoading} />;
  }

  return (
    <section>
      <div className={styles.quotationContainer}>
        <div className={styles.campignBreadcrumbs}>
          <Link to={routes.BRAND_CAMPAIGNS} className={styles.navTxt}>
            Campaings
          </Link>
          <img className={styles.rightArrow} src={locationDownIcon} alt="" />
          <Link
            to={`${routes.BRAND_CAMPAIGNS}/${campaignId}`}
            className={styles.navSecndTxt}
          >
            {campaignName}
          </Link>
          <img className={styles.rightArrow} src={locationDownIcon} alt="" />
          <span className={styles.navSecndTxt}>Quotation Detail</span>
        </div>
        <div className={styles.quotationHeader}>
          <h2 className={styles.quoHeading}>
            Quotation ID
            <span className={styles.quoSubHeaidng}>{quotationId}</span>
          </h2>
          {(quoteStatus === CAMPAIGN_QUOTE_STATUS.USER_APPROVED_SIGNED ||
            quoteStatus === CAMPAIGN_QUOTE_STATUS.USER_APPROVED) && (
            <button
              className={styles.downloadQuotBtn}
              type="button"
              onClick={() => downloadQuoteMutation.mutate({ campaignId })}
              disabled={downloadQuoteMutation.isLoading}
            >
              <img src={whiteDownloadIcon} alt="" />
              Download Contract{' '}
              {quoteStatus === CAMPAIGN_QUOTE_STATUS.USER_APPROVED
                ? '(Unsigned)'
                : null}
            </button>
          )}
        </div>

        <div className={styles.quoSub}>
          <div className={styles.quotationDetail}>
            <div className={styles.detailHeader}>
              <h2 className={styles.detailHeading}>CLIENT DETAILS</h2>
            </div>
            <div className={styles.detailInnerContent}>
              <div className={styles.subPart}>
                <div>
                  <div className={styles.labelContainer}>
                    <label className={styles.title}>Full Name:</label>
                    <p className={styles.content}>{clientFullName}</p>
                  </div>
                  <div className={styles.labelContainer}>
                    <label className={styles.title}>Email Id:</label>
                    <p className={styles.content}>{clientEmail}</p>
                  </div>
                  <div className={styles.labelContainer}>
                    <label className={styles.title}>Mobile Number:</label>
                    <p className={styles.content}>{clientMobile}</p>
                  </div>
                  <div className={styles.labelContainer}>
                    <label className={styles.title}>Address:</label>
                    <p className={styles.addressContent}>
                      {address}
                      {/* {clientAddress.address_line_1 &&
                        `${clientAddress.address_line_1}, `}
                      {clientAddress.address_line_2 &&
                        `${clientAddress.address_line_2}, `}
                      {clientAddress.landmark && `${clientAddress.landmark}, `}
                      {clientAddress.city && `${clientAddress.city}, `}
                      {clientAddress.state} - {clientAddress.pincode} */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.quotationDetail}>
            <div className={styles.detailHeader}>
              <h2 className={styles.detailHeading}>CAMPAIGN DETAILS</h2>
            </div>
            <div className={styles.detailInnerContent}>
              <div className={styles.subPart}>
                <div>
                  <div className={styles.labelContainer}>
                    <label className={styles.title}>Campaign Name:</label>
                    <p className={styles.content}>{campaignName}</p>
                  </div>
                  <div className={styles.labelContainer}>
                    <label className={styles.title}>Platform:</label>
                    <p className={styles.platformIconsWrapper}>
                      {campaignData?.target_platforms?.map((platformName) => (
                        <img
                          src={PLATFORM_ICONS[platformName]}
                          alt=""
                          key={platformName}
                        />
                      ))}
                    </p>
                  </div>
                  <div className={styles.labelContainer}>
                    <label className={styles.title}>Duration:</label>
                    <p className={styles.content}>
                      {campaignDurationInDays} days
                    </p>
                  </div>
                  <div className={styles.labelContainer}>
                    <label className={styles.title}>Region:</label>
                    <p className={styles.content}>{regionString}</p>
                  </div>
                  <div className={styles.labelContainer}>
                    <label className={styles.title}>Age Group:</label>
                    <p className={styles.content}>{ageGroupString}</p>
                  </div>
                  <div className={styles.labelContainer}>
                    <label className={styles.title}>Submitted By:</label>
                    <p className={styles.content}>{submittedBy}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 className={styles.tableHeading}>Influencers Payment Detail</h2>
          <PaymentDetailsTable
            influencerDeliverablesCosts={influencerDeliverablesCosts}
            grandTotal={Number(grandTotal)}
          />
        </div>
        <div>
          <h2 className={styles.tableHeading}>Summary</h2>
          <PaymentSummaryTable
            paymentSummaries={paymentSummaries}
            grandTotal={Number(grandTotal)}
          />
        </div>
        <div className={styles.totalAmntCal}>
          <h2 className={styles.amnTitle}>Grand Total</h2>
          <p className={styles.price}>{formatCurrency(grandTotal)}</p>
        </div>
        <div className={styles.purchaseBtns}>
          {quoteStatus === CAMPAIGN_QUOTE_STATUS.ADMIN_CONFIRMED && (
            <>
              <AlertDialog
                render={({ open }) => (
                  <button
                    className={styles.uploadBtn}
                    type="button"
                    onClick={open}
                    disabled={updateQuoteStatusMutation.isLoading}
                  >
                    Reject Quotation
                  </button>
                )}
                title="Are you sure?"
                description="Do you really want to reject this Quotation?"
                agreeBtnText="Yes"
                disagreeBtnText="No"
                size="526px"
                onAgree={() =>
                  updateQuoteStatusMutation.mutate({
                    quoteId,
                    campaignId,
                    action: CAMPAIGN_QUOTE_ACTIONS.DROP,
                  })
                }
              />
              <RequestRevisionDialog
                render={({ open }) => (
                  <button
                    className={styles.uploadBtn}
                    type="button"
                    onClick={open}
                    disabled={updateQuoteStatusMutation.isLoading}
                  >
                    Request Revision
                  </button>
                )}
                onAgree={(reason) =>
                  updateQuoteStatusMutation.mutate({
                    quoteId,
                    campaignId,
                    action: CAMPAIGN_QUOTE_ACTIONS.RECHECK,
                    reason,
                  })
                }
              />
              <AlertDialog
                render={({ open }) => (
                  <button
                    className={styles.proceedBtn}
                    type="button"
                    onClick={open}
                    disabled={updateQuoteStatusMutation.isLoading}
                  >
                    Approve
                  </button>
                )}
                title="Are you sure?"
                description="Do you really want to approve this Quotation?"
                agreeBtnText="Yes"
                disagreeBtnText="No"
                size="526px"
                onAgree={() =>
                  updateQuoteStatusMutation.mutate({
                    quoteId,
                    campaignId,
                    action: CAMPAIGN_QUOTE_ACTIONS.ACCEPT,
                  })
                }
              />
            </>
          )}
          {(quoteStatus === CAMPAIGN_QUOTE_STATUS.USER_APPROVED ||
            quoteStatus === CAMPAIGN_QUOTE_STATUS.USER_APPROVED_SIGNED) && (
            <button
              onClick={onProceedToPay}
              className={styles.proceedBtn}
              type="button"
              disabled={
                quoteStatus !== CAMPAIGN_QUOTE_STATUS.USER_APPROVED_SIGNED
              }
            >
              Proceed To Pay
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

const paymentDetailsColumns = [
  {
    Header: 'Influencers Name',
    accessor: 'influencer',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return (
        <div className={styles.profileDiv}>
          <Avatar src={row?.profileImage} alt="" size="large" />
          <p className={styles.boldTableText}>{row?.fullName}</p>
          <span />
        </div>
      );
    },
  },
  {
    Header: 'Platforms',
    accessor: 'platforms',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return (
        <div className={styles.profileDiv}>
          {row?.platforms?.map((platformName) => (
            <Avatar
              src={PLATFORM_ICONS[platformName]}
              alt=""
              size="small"
              key={platformName}
            />
          ))}
        </div>
      );
    },
  },
  {
    Header: 'Total Deliverables',
    accessor: 'totalDeliverables',
    Cell: (cellInfo) => (
      <p className={styles.boldTableText}>{cellInfo.value}</p>
    ),
  },
  {
    Header: 'Total Cost',
    accessor: 'totalCost',
    Cell: (cellInfo) => (
      <p className={styles.boldTableText}>{formatCurrency(cellInfo.value)}</p>
    ),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cellInfo) => {
      const setDialog = useDialog((state) => state.setDialog);
      const row = cellInfo.row?.original;
      const onView = () => {
        setDialog(DIALOGS.DELIVERABLES_COST_BREAKDOWN, { influencer: row });
      };
      return (
        <button type="button" className={styles.actionButton} onClick={onView}>
          <EyeIcon />
        </button>
      );
    },
  },
];

const PaymentDetailsTable = ({ influencerDeliverablesCosts, grandTotal }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: paymentDetailsColumns,
      data: influencerDeliverablesCosts,
    });
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <th {...column.getHeaderProps()}>
                  <div className={styles.head}>
                    <p>{column.render('Header')}</p>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <tr {...row.getRowProps()} className={styles.dense}>
                {row.cells.map((cell) => (
                  // eslint-disable-next-line react/jsx-key
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={paymentDetailsColumns.length - 1}>Total</td>
            <td>{formatCurrency(grandTotal)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

PaymentDetailsTable.propTypes = {
  influencerDeliverablesCosts: PropTypes.array,
  grandTotal: PropTypes.number,
};

const paymentSummaryColumns = [
  {
    id: 'expander',
    Header: 'Item Name',
    accessor: 'name',
    Cell: (cellInfo) => {
      const { row, value } = cellInfo;
      return row.canExpand ? (
        <span
          {...row.getToggleRowExpandedProps({
            style: {
              paddingLeft: `${row.depth * 2}rem`,
            },
            className: styles.profileDiv,
          })}
        >
          {value}
          <DownIcon
            className={row.isExpanded ? styles.upIcon : styles.downIcon}
          />
        </span>
      ) : (
        <span
          style={{
            paddingLeft: `${row.depth * 2.375}rem`,
          }}
        >
          {value}
        </span>
      );
    },
  },
  {
    Header: 'Qty',
    accessor: 'qty',
  },
  {
    Header: 'Total',
    accessor: 'total',
    Cell: (cellInfo) => formatCurrency(cellInfo.value),
  },
];

const PaymentSummaryTable = ({ paymentSummaries, grandTotal }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // state: { expanded },
  } = useTable(
    {
      columns: paymentSummaryColumns,
      data: paymentSummaries,
    },
    useExpanded
  );
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <th {...column.getHeaderProps()}>
                  <div className={styles.head}>
                    <p>{column.render('Header')}</p>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <tr
                {...row.getRowProps({
                  className:
                    row.depth > 0 || row.isExpanded
                      ? styles.nestedRow
                      : styles.collapsibleRow,
                })}
              >
                {row.cells.map((cell) => (
                  // eslint-disable-next-line react/jsx-key
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={paymentSummaryColumns.length - 1}>Total</td>
            <td>{formatCurrency(grandTotal)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

PaymentSummaryTable.propTypes = {
  paymentSummaries: PropTypes.array,
  grandTotal: PropTypes.number,
};

export default CampaignQuotationDetails;
