import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSpinDelay } from 'spin-delay';
import { Dialog } from 'components/common/dialog';
import styles from 'assets/scss/pages/popup.module.scss';
import { whiteAddIcon } from 'constants/images';
import {
  addInfToCustomList,
  createCustomList,
  getCustomLists,
} from 'api/brand/search';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from 'App';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { LoaderWithWrapper } from 'components/common/Loader';

const defaultValues = { name: '' };

const AddInfToCustomListDialog = ({ open, close, influencerIds }) => {
  const [showAdd, setShowAdd] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ['brand-custom-lists'],
    queryFn: () => getCustomLists(),
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  const { register, handleSubmit, reset } = useForm({
    defaultValues,
  });

  const addInfToListMutation = useMutation({
    mutationFn: ({ id, infIds }) =>
      addInfToCustomList({
        influencerId: infIds,
        customWhishlistId: id,
      }),
    onSuccess: (_, { infIds, id }) => {
      toast.success(
        `Successfully added influencer${
          infIds.length > 1 ? 's' : ''
        } in custom list`,
        {
          position: 'top-center',
        }
      );
      reset();
      close();
      queryClient.invalidateQueries({ queryKey: ['brand-custom-lists'] });
      queryClient.invalidateQueries({
        queryKey: ['brand-custom-lists-details', id],
      });
    },
  });

  const createListMutation = useMutation({
    mutationFn: ({ name }) => createCustomList({ whishlist_name: name }),
    onSuccess: ({ data }) => {
      addInfToListMutation.mutate({
        infIds: influencerIds,
        id: data?.whishlistData?.id,
      });
    },
  });

  const customLists = data?.data?.viewCustomWhishlist;

  return (
    <Dialog
      title="Add To Custom List"
      open={open}
      close={close}
      size="xs"
      fullWidth
    >
      <div className={styles.campListWrap}>
        {isLoading || delayedIsLoading ? (
          <LoaderWithWrapper show={delayedIsLoading} height={120} />
        ) : (
          <ul className={styles.campList}>
            {customLists?.map(({ id, whishlist_name: name }) => (
              <li className={styles.listItem} key={id}>
                <button
                  type="button"
                  onClick={() =>
                    addInfToListMutation.mutate({ id, infIds: influencerIds })
                  }
                >
                  {name}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
      <form
        className={styles.createCustmListWrap}
        onSubmit={handleSubmit(createListMutation.mutate)}
      >
        {showAdd ? (
          <>
            <div className={styles.inputWrapper}>
              <input
                className={styles.custmIn}
                type="text"
                placeholder="Enter List Name"
                required
                {...register('name')}
              />
            </div>
            <button
              type="submit"
              className={styles.createListBtns}
              disabled={
                createListMutation.isLoading || addInfToListMutation.isLoading
              }
            >
              Create and Add
            </button>
          </>
        ) : (
          <button
            type="button"
            className={styles.createListBtns}
            onClick={() => setShowAdd(true)}
          >
            <img src={whiteAddIcon} alt="" /> Create List
          </button>
        )}
      </form>
    </Dialog>
  );
};

AddInfToCustomListDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  influencerIds: PropTypes.arrayOf(PropTypes.string),
};

export default AddInfToCustomListDialog;
