import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { createCustomList } from 'api/brand/search';
import { Dialog } from 'components/common/dialog';
import Button from 'components/common/Button';
import { queryClient } from 'App';
import { BRAND_CUSTOM_LIST_SCHEMA } from 'constants/schema';
import styles from 'assets/scss/pages/search.module.scss';

const defaultValues = { name: '' };

const CreateCustomListDialog = ({ open, close }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BRAND_CUSTOM_LIST_SCHEMA),
    defaultValues,
  });

  const createListMutation = useMutation({
    mutationFn: ({ name }) => createCustomList({ whishlist_name: name }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['brand-custom-lists'] });
      toast.success('Custom list created successfully', {
        position: 'top-center',
      });
      reset();
      close();
    },
  });

  return (
    <Dialog
      title="Create New Custom List"
      open={open}
      close={close}
      size="xs"
      fullWidth
    >
      <form
        onSubmit={handleSubmit(createListMutation.mutate)}
        style={{ padding: '18px 24px' }}
      >
        <div className={styles.CustmPopupWrapper}>
          <div>
            <div className={styles.inputWrapper}>
              <input
                className={styles.custmIn}
                type="text"
                placeholder="Enter List Name"
                style={{ width: '100%' }}
                {...register('name')}
              />
            </div>
            {errors?.name ? (
              <p className={styles.error}>{errors.name?.message}</p>
            ) : null}
          </div>
        </div>
        <Button
          style={{ display: 'flex', marginTop: '30px', marginLeft: 'auto' }}
          size="large"
          type="submit"
          isLoading={createListMutation.isLoading}
        >
          Create List
        </Button>
      </form>
    </Dialog>
  );
};

CreateCustomListDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default CreateCustomListDialog;
