import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from 'assets/scss/pages/search.module.scss';
import { useQuery } from '@tanstack/react-query';
import {
  searchImg,
  cogwheelIcon,
  categoryIcon,
  ytIcon,
  instaSvg,
  twitterSvg,
  facebookSvg,
  postIcon,
} from 'constants/images';
import clsx from 'clsx';
import { NavLink, useNavigate, Link, useLocation } from 'react-router-dom';
import { brandCategories } from 'api/brand/search';
import { getBrands } from 'api/brand/brandAnalysis';
import routes from 'constants/routes';
import FilterDropdown from 'components/common/FilterDropdown';
import { PLATFORMS } from 'constants/config/filters';
import useDebounce from 'hooks/useDebounce';
import useFilterProps from 'hooks/useFilterProps';
import { formatNumber } from 'utils';
import { FilterChip } from 'components/common/Chip';
import Button from 'components/common/Button';
import Table from 'components/common/Table';

import Menu from 'components/common/Menu';
import { ReactComponent as MoreIcon } from 'assets/images/more-icon.svg';
import { ReactComponent as EyeIcon } from 'assets/images/eye.svg';
import TableError from 'components/common/Table/TableError';
import { Avatar } from 'components/common/Avatar';
import FilterMenu from 'components/common/FilterDropdown/FilterMenu';

const NavItem = ({ children, ...props }) => (
  <NavLink
    className={({ isActive }) =>
      clsx({
        [styles.influencerCateBtn]: true,
        [styles.whiteActiveBtn]: isActive,
      })
    }
    {...props}
  >
    {children}
  </NavLink>
);

NavItem.propTypes = {
  children: PropTypes.node,
};

const BrandsList = () => {
  const location = useLocation();
  const locationState = location.state;

  const {
    page,
    rowsPerPage,
    changePage,
    changeRowsPerPage,
    search,
    changeSearch,
    filter,
    changeFilter,
  } = useFilterProps({
    defaultFilters: { platform: 'Instagram' },
  });

  const debouncedSearchQuery = useDebounce(search);
  const platformFilter = filter.platform;
  const categoriesFilter = filter.category_name;
  const postFilter = filter.post;

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      'brand-influencer-list',
      page,
      rowsPerPage,
      debouncedSearchQuery,
      categoriesFilter?.toString(),
      platformFilter,
      postFilter,
    ],
    queryFn: () =>
      getBrands({
        media_type: platformFilter,
        post_from: postFilter?.[0] || undefined,
        post_to: postFilter?.[1] || undefined,
        category_name: categoriesFilter,
        search: debouncedSearchQuery || undefined,
        pageNo: page || 1,
        pageSize: rowsPerPage,
      }),
  });

  const postFilterText = postFilter
    ? `${formatNumber(postFilter[0])} - ${formatNumber(postFilter[1])}`
    : 'All';

  const { result: brands } = data?.data || [];

  const { data: categories } = useQuery({
    queryKey: ['brand-analysis-categories'],
    queryFn: () => brandCategories(),
  });

  // columun
  const columns = useMemo(
    () => [
      {
        Header: 'Brand Name',
        accessor: 'brand_name',
        width: 240,
        minWidth: 240,
        Cell: (cellInfo) => {
          const row = cellInfo?.row?.original;
          return (
            <Link
              to={`${routes.BRAND_ANALYSIS}/${row?.id}/${platformFilter}`}
              className={styles.profileDiv}
              target="_blank"
            >
              <Avatar src={row?.profile_picture_url} alt="" size="large" />
              <p>{row?.brand_name}</p>
              <span />
            </Link>
          );
        },
      },
      {
        Header: 'Platform',
        accessor: 'media_type',
        width: 60,
        minWidth: 60,
        Cell: (cellInfo) => {
          const row = cellInfo?.row?.original;
          const icon = useMemo(() => {
            switch (row?.media_type?.toLowerCase()) {
              case 'youtube':
                return ytIcon;
              case 'instagram':
                return instaSvg;
              case 'twitter':
                return twitterSvg;
              case 'facebook':
                return facebookSvg;
              default:
                return null;
            }
          }, [row]);
          return icon ? <Avatar src={icon} alt="" /> : null;
        },
      },
      {
        Header: 'Handle Name',
        accessor: 'handle_name',
        Cell: (cellInfo) => {
          const row = cellInfo?.row?.original;
          return <p className={styles.downloadedTxt}>{row?.handle_name}</p>;
        },
      },
      {
        Header: 'Categories',
        accessor: 'brand_categories',
        Cell: (cellInfo) => {
          const row = cellInfo?.row?.original;
          const categoriesCount = row?.brand_categories?.length;
          return (
            <div className={styles.catgryDiv}>
              {categoriesCount > 0 && (
                <span className={styles.catgrySpan}>
                  {row.brand_categories[0]
                    ? row.brand_categories[0]?.category_name
                    : null}
                </span>
              )}
              {categoriesCount > 1 ? (
                <p className={styles.catgryTxt}>+{categoriesCount - 1} More</p>
              ) : null}
            </div>
          );
        },
      },
      {
        Header: 'Followers',
        accessor: 'followers_count',
        disableSortBy: false,
        Cell: (cellInfo) => {
          const row = cellInfo?.row?.original;
          return <>{formatNumber(row?.followers_count)}</>;
        },
      },
      { Header: 'Post', accessor: 'total_post' },
      {
        Header: '',
        accessor: 'options',
        width: 20,
        Cell: (cellInfo) => {
          const row = cellInfo?.row?.original;
          const navigate = useNavigate();
          return (
            <Menu
              renderButton={() => (
                <Menu.Button>
                  <MoreIcon />
                </Menu.Button>
              )}
            >
              <Menu.Item
                onClick={() =>
                  navigate(
                    `${routes.BRAND_ANALYSIS}/${row?.id}/${platformFilter}`
                  )
                }
              >
                <EyeIcon />
                <span>View Details</span>
              </Menu.Item>
            </Menu>
          );
        },
      },
    ],
    [platformFilter]
  );

  useEffect(() => {
    if (locationState?.search) {
      changeSearch(locationState.search);
    }
    if (locationState?.categories) {
      changeFilter('category_name', locationState.categories);
    }
  }, [locationState, changeSearch, changeFilter]);

  const onPlatformChange = (newValue) => {
    changeFilter('platform', newValue);
  };

  const onCategoriesChange = (newValue) => {
    changeFilter('category_name', newValue);
  };
  const onPostChange = (newValue) => {
    changeFilter('post', newValue);
  };

  const onSearchChange = (e) => {
    changeSearch(e.target.value);
  };

  const onCategoryRemove = (id) => {
    onCategoriesChange(
      categoriesFilter?.filter((categoryId) => categoryId !== id)
    );
  };
  const onFollowersFilterRemove = () => {
    onPostChange('');
  };

  const onClearFilter = () => {
    changeFilter('category_name', []);
    changeFilter('post', undefined);
  };

  const selectedPlatform = useMemo(
    () =>
      PLATFORMS.find(
        (platform) =>
          platform.value?.toLowerCase() === filter.platform?.toLowerCase()
      ),
    [filter]
  );

  const brandsCount = data?.data?.count;
  const showClearButton = Boolean(
    categoriesFilter?.length > 0 || postFilter?.[0]
  );

  return (
    <section>
      <div className={styles.influencerHeader}>
        <div className={styles.categorySelector}>
          <FilterDropdown
            options={PLATFORMS}
            label="Platform"
            startIconUrl={cogwheelIcon}
            multiple={false}
            onChange={onPlatformChange}
            value={filter.platform}
            valueSelector="label"
          />
          <FilterDropdown
            options={categories}
            label="Categories"
            startIconUrl={categoryIcon}
            labelSelector="name"
            valueSelector="name"
            onChange={onCategoriesChange}
            value={categoriesFilter}
          />
          <FilterMenu.RangeSelector
            label="Posts Count"
            startIconUrl={postIcon}
            minLimit={0}
            maxLimit={8000}
            onClear={() => onPostChange(null)}
            onApply={onPostChange}
            buttonText={postFilterText}
            value={postFilter}
            isMenuChild={false}
            placement="bottom-start"
            adaptiveWidth
            portal
            sliderProps={{ step: 10 }}
          />
        </div>
      </div>
      <div className="influencerList">
        <div className={styles.influencerSelectors}>
          {selectedPlatform ? (
            <FilterChip
              icon={selectedPlatform.icon}
              label={selectedPlatform.label}
              isActive
              multiple={false}
              onChange={onPlatformChange}
              value={filter.platform}
            />
          ) : null}
          {categoriesFilter?.map((category) => (
            <FilterChip
              label={category}
              key={category}
              value={category}
              onRemove={onCategoryRemove}
            />
          ))}
          {postFilter && (
            <FilterChip
              label={`${postFilterText} Posts`}
              onRemove={onFollowersFilterRemove}
            />
          )}

          {showClearButton && (
            <Button variant="text" onClick={onClearFilter}>
              Clear Filters
            </Button>
          )}
        </div>
      </div>
      <div className={styles.influencerHeader}>
        <div className={styles.subCategoryTitle}>
          <h2 className={styles.trendingTxt}>Top Brands</h2>
          <div className={styles.searchField}>
            <img className={styles.sIcon} src={searchImg} alt="" />
            <input
              className={styles.searchTxt}
              placeholder="Search Brand Name"
              type="text"
              value={search || ''}
              onChange={onSearchChange}
            />
          </div>
        </div>
        <Table
          data={brands}
          columns={columns}
          paginationProps={{
            currentPage: page,
            changePage,
            count: brandsCount,
            rowsPerPage,
            changeRowsPerPage,
            rowsPerPageOptions: [10, 30, 50],
            withPageInput: true,
          }}
          isLoading={isLoading || isFetching}
          noDataValue={<TableError infoText="No brands found." />}
        />
      </div>
    </section>
  );
};

export default BrandsList;
