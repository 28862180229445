import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Listbox } from '@headlessui/react';
import { ReactComponent as DownIcon } from 'assets/images/down.svg';
import { ReactComponent as CrossIcon } from 'assets/images/cross_white.svg';
import styles from './form.module.scss';

const MultiSelect = React.forwardRef(
  (
    {
      label,
      options = [],
      onChange,
      value = [],
      placeholder,
      labelSelector = 'label',
      valueSelector = 'value',
      disabled = false,
      isOnboarding = false,
      ...rest
    },
    ref
  ) => {
    const onRemove = (id, e) => {
      e.preventDefault();
      onChange(value.filter((selectedId) => selectedId !== id));
    };

    const selected = useMemo(
      () => options.filter((option) => value.includes(option[valueSelector])),
      [value, options, valueSelector]
    );

    return (
      <Listbox
        value={selected}
        onChange={(values) =>
          onChange(values.map((option) => option[valueSelector]))
        }
        className={styles.selectWrapper}
        ref={ref}
        as="div"
        disabled={disabled}
        multiple
        {...rest}
      >
        {label ? <Listbox.Label>{label}</Listbox.Label> : null}
        <Listbox.Button
          className={clsx({
            [styles.languageSelectButton]: !!isOnboarding,
            [styles.selectButton]: !isOnboarding,
            [styles.selectButtonFilled]: selected.length > 0,
          })}
        >
          <span className={styles.selectedValues}>
            {selected.length > 0 ? (
              selected.map((option) => (
                <span
                  className={styles.selectedValue}
                  key={option[valueSelector]}
                >
                  <span>{option[labelSelector]}</span>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <CrossIcon
                    onClick={(e) =>
                      disabled ? undefined : onRemove(option[valueSelector], e)
                    }
                    className={styles.cross}
                  />
                </span>
              ))
            ) : (
              <span className={styles.selectPlaceholder}>{placeholder}</span>
            )}
          </span>
          <DownIcon className={styles.dropdownIcon} />
        </Listbox.Button>
        <Listbox.Options className={styles.selectOptions}>
          {options.map((option) => (
            <Listbox.Option
              key={option[valueSelector]}
              value={option}
              className={({ selected }) =>
                clsx({
                  [styles.selectOption]: true,
                  [styles.selectOptionActive]: selected,
                })
              }
            >
              <span className={styles.checkMark} />
              <span>{option[labelSelector]}</span>
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    );
  }
);

MultiSelect.propTypes = {
  label: PropTypes.node,
  options: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.node,
  value: PropTypes.array,
  labelSelector: PropTypes.string,
  valueSelector: PropTypes.string,
  disabled: PropTypes.bool,
  isOnboarding: PropTypes.bool,
};

export default MultiSelect;
