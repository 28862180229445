import React from 'react';
import { BrandsList } from 'components/brand-dashboard/brand-analysis';
import styles from 'assets/scss/pages/search.module.scss';
import styles2 from 'assets/scss/pages/campaign.module.scss';
import { transparentBg } from 'constants/images';

const AnalyseBrands = () => (
  <section>
    <div className="dashboard">
      <div className="dashboardSearch">
        <div className={styles.dashboardTop}>
          <img className={styles.transBg} src={transparentBg} alt="" />
          <div className={styles.searchBox}>
            <h2 className={styles2.campignTxt}>Brand Analysis</h2>
          </div>
        </div>
        <BrandsList />
      </div>
    </div>
  </section>
);

export default AnalyseBrands;
