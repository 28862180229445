import { useMemo } from 'react';
import orderBy from 'lodash/orderBy';

const useFromatInstaCountryData = (audienceByCountry = {}) => {
  const formattedData = useMemo(() => {
    let total = 0;
    const countries = audienceByCountry
      ? Object.keys(audienceByCountry)
          .map((key) => {
            const value = Number(audienceByCountry[key]);
            total += value;
            return { name: key, value };
          })
          .map((country) => ({
            ...country,
            percentage: Number(((country.value * 100) / total).toFixed(2)),
          }))
      : [];

    return orderBy(countries, ['percentage'], ['desc']);
  }, [audienceByCountry]);
  return formattedData;
};

export default useFromatInstaCountryData;
