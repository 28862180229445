import { baseQuery } from './baseQuery';

const apiVersion = process.env.REACT_APP_API_VERSION;

export const login = (body) =>
  baseQuery({
    url: `/auth/${apiVersion}/influencer/login`,
    method: 'POST',
    body,
  });

export const signup = (body) =>
  baseQuery({
    url: `/auth/${apiVersion}/influencer/send-otp`,
    method: 'POST',
    body,
  });

export const signupVerifyOtp = (body) =>
  baseQuery({
    url: `/auth/${apiVersion}/influencer/verify-otp`,
    method: 'POST',
    body,
  });

export const signupProfileSetup = (body) =>
  baseQuery({
    url: `/auth/${apiVersion}/influencer/register`,
    method: 'POST',
    body,
  });

export const signupResendOtp = (body) =>
  baseQuery({
    url: `/auth/${apiVersion}/influencer/resend-otp`,
    method: 'POST',
    body,
  });

export const forgotPassword = (body) =>
  baseQuery({
    url: `/auth/${apiVersion}/influencer/forgot-password`,
    method: 'POST',
    body,
  });

export const forgotPasswordOtpVerify = (body) =>
  baseQuery({
    url: `/auth/${apiVersion}/influencer/verify-forgot-password-otp`,
    method: 'POST',
    body,
  });

export const resetPassword = (body) =>
  baseQuery({
    url: `/auth/${apiVersion}/influencer/reset-password`,
    method: 'POST',
    body,
  });
