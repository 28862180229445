import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Disclosure } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useDialog } from 'stores/dialog';
import Tooltip from 'components/common/Tooltip';
import styles from 'assets/scss/pages/deliverables.module.scss';
import {
  awsmSquareAdd,
  awsmSquareMinus,
  featherEyeImg,
  greenCheckCircleIcon,
  whiteAddIcon,
  calenderSVG,
} from 'constants/images';
import {
  CAMPAIGN_STATUS,
  INF_DELIVERABLE_STATUS,
} from 'constants/config/campaign';
import { DIALOGS } from 'constants/config/dialog-names';
// import { AlertDialog } from 'components/common/dialog';

const INSTA_URL = 'https://instagram.com/';
const YOUTUBE_URL = 'https://www.youtube.com/watch?v=';
const FACEBOOK_URL = 'https://www.facebook.com/';
const TWITTER_URL = 'https://twitter.com/i/web/status/';

const getPostUrl = (paltform, mediaId) => {
  if (!mediaId) return undefined;
  switch (paltform) {
    case 'instagram': {
      return undefined;
    }
    case 'facebook': {
      return `${FACEBOOK_URL}${mediaId}`;
    }
    case 'youtube':
      return `${YOUTUBE_URL}${mediaId}`;
    case 'twitter':
      return `${TWITTER_URL}${mediaId}`;
    default:
      return undefined;
  }
};

const DeliverableSection = ({
  platform,
  deliverableType,
  allocatedCount,
  uploadedFiles,
  campaignStatus,
  campaignId,
  campaignTags,
}) => {
  const setDialog = useDialog((state) => state.setDialog);
  const onAdd = () => {
    setDialog(DIALOGS.INF_UPLOAD_CAMPAGIN_DELIVERABLE, {
      platform,
      deliverableType,
      campaignId,
      campaignTags,
    });
  };

  const postSchedulingHandler = (postId) => {
    setDialog(DIALOGS.INF_SCHEDULE_POST, {
      campaignId,
      postId,
      platform,
    });
  };

  const postsToShow = useMemo(
    () => uploadedFiles?.filter(({ media }) => media === deliverableType),
    [uploadedFiles, deliverableType]
  );

  const [canUpload, isCompleted] = useMemo(() => {
    let inProgressPostsCount = 0;
    let uploadedPostCount = 0;

    postsToShow?.forEach((post) => {
      if (post.status !== INF_DELIVERABLE_STATUS.REJECTED) {
        inProgressPostsCount += 1;
      }
      if (post.status === INF_DELIVERABLE_STATUS.UPLOADED) {
        uploadedPostCount += 1;
      }
    });

    return [
      inProgressPostsCount < allocatedCount,
      uploadedPostCount >= allocatedCount,
    ];
  }, [postsToShow, allocatedCount]);

  return (
    <Disclosure
      className={({ open }) =>
        clsx({ [styles.reelsBlock]: true, [styles.activeContnt]: open })
      }
      as="div"
      defaultOpen={false}
    >
      {({ open }) => (
        <>
          <div className={styles.blockInnr}>
            <div className={styles.block1}>
              <Disclosure.Button>
                {({ open }) => (
                  <img
                    className=""
                    src={open ? awsmSquareMinus : awsmSquareAdd}
                    alt=""
                  />
                )}
              </Disclosure.Button>
              <h2 className={styles.reelTxt}>
                {platform} {deliverableType}
              </h2>
            </div>
            <div className={styles.block2}>
              {allocatedCount !== 0 && (
                <h3 className={styles.allcTxt}>
                  {allocatedCount} {deliverableType} Allocated
                </h3>
              )}
              {campaignStatus === CAMPAIGN_STATUS.ACTIVE ? (
                <>
                  {isCompleted ? (
                    <span className={styles.complteBtn}>
                      <img src={greenCheckCircleIcon} alt="" />
                      Completed
                    </span>
                  ) : (
                    <>
                      {canUpload ? (
                        <button
                          type="button"
                          className={styles.addBtn}
                          onClick={onAdd}
                        >
                          <img src={whiteAddIcon} alt="" />
                          Add
                        </button>
                      ) : null}
                    </>
                  )}
                </>
              ) : null}
            </div>
          </div>
          <AnimatePresence>
            {open && (
              <Disclosure.Panel
                as={motion.div}
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{
                  height: 0,
                  opacity: 0,
                  transition: { duration: 0.2, type: 'tween' },
                }}
                transition={{ duration: 0.3, type: 'tween' }}
                static
              >
                <div className={styles.postsTableWrapper}>
                  <table className={styles.postsTable}>
                    <thead>
                      <tr>
                        <th>Title</th>
                        {platform !== 'twitter' ? <th>Image</th> : null}
                        <th>Status</th>
                        <th className={styles.actions}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {postsToShow?.map(
                        ({
                          id,
                          fileLink,
                          status,
                          thumbnail_link: thumbnail,
                          title,
                          media_id: mediaId,
                          message: errorMessage,
                          permalink,
                          custom_link: customLink,
                        }) => (
                          <tr key={id}>
                            <td>{title}</td>
                            {platform !== 'twitter' && (
                              <td>
                                {thumbnail ? (
                                  <img
                                    src={thumbnail}
                                    className={styles.postThumnail}
                                    width="100"
                                    height="67"
                                  />
                                ) : null}
                              </td>
                            )}
                            <td>
                              <div className={styles.postStatus}>
                                {status?.split('_')?.join(' ')}
                                {status === INF_DELIVERABLE_STATUS.FAILED && (
                                  <Tooltip
                                    label={errorMessage}
                                    maxWidth={300}
                                  />
                                )}
                              </div>
                            </td>
                            <td>
                              <div className={styles.actionsIcon}>
                                {platform !== 'twitter' ||
                                status === INF_DELIVERABLE_STATUS.UPLOADED ? (
                                  <button type="button">
                                    <a
                                      href={
                                        status ===
                                        INF_DELIVERABLE_STATUS.UPLOADED
                                          ? permalink ||
                                            customLink ||
                                            getPostUrl(platform, mediaId)
                                          : fileLink
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img src={featherEyeImg} alt="" />
                                    </a>
                                  </button>
                                ) : null}
                                {(status === INF_DELIVERABLE_STATUS.APPROVED ||
                                  status === INF_DELIVERABLE_STATUS.FAILED) && (
                                  <button
                                    type="button"
                                    onClick={() => postSchedulingHandler(id)}
                                  >
                                    <img src={calenderSVG} alt="" />
                                  </button>
                                )}
                                {/* <AlertDialog
                          render={({ open }) => (
                            <button
                              onClick={open}
                              type="button"
                              className={styles.profileLogoutBtn}
                            >
                              <img src={deletIconImage} alt="" />
                            </button>
                          )}
                          postion="top-center"
                          title="Delete Post"
                          description="Do you really want to delete this Post?"
                          agreeBtnText="Delete"
                          disagreeBtnText="Close"
                          // onAgree={onDelete}
                        /> */}
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </Disclosure.Panel>
            )}
          </AnimatePresence>
        </>
      )}
    </Disclosure>
  );
};

DeliverableSection.propTypes = {
  platform: PropTypes.oneOf(['instagram', 'youtube', 'twitter', 'facebook']),
  deliverableType: PropTypes.string,
  allocatedCount: PropTypes.number,
  uploadedFiles: PropTypes.array,
  campaignStatus: PropTypes.string,
  campaignId: PropTypes.string,
  campaignTags: PropTypes.array,
};

export default DeliverableSection;
