import React from 'react';
import { motion } from 'framer-motion';

const IndustryMarketValueGraph = (props) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width={368}
    height={298}
    {...props}
    style={{ overflow: 'visible', postion: 'relative' }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3, delay: 0.7 }}
  >
    <defs>
      <linearGradient
        id="industryMarketValueGraphGradient1"
        x2={0.984}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0} />
        <stop offset={0.607} stopColor="#b584ce" stopOpacity={0.149} />
        <stop offset={1} stopColor="#641582" stopOpacity={0.8} />
      </linearGradient>
      <linearGradient
        id="industryMarketValueGraphGradient2"
        x1={0.5}
        x2={0.5}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ec40f1" stopOpacity={0.2} />
        <stop offset={1} stopColor="#ff68b5" stopOpacity={0.051} />
      </linearGradient>
      <linearGradient
        id="industryMarketValueGraphGradient3"
        x1={0.5}
        x2={0.5}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fc9eff" />
        <stop offset={1} stopColor="#fee4ff" stopOpacity={0.988} />
      </linearGradient>
      <filter
        id="industryMarketValueGraphFilter1"
        width={384}
        height={319}
        x={110}
        y={119}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dx={4} dy={5} />
        <feGaussianBlur result="blur" stdDeviation={5} />
        <feFlood floodColor="#e79ce3" floodOpacity={0.502} />
        <feComposite in2="blur" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="industryMarketValueGraphFilter2">
        <feOffset dy={3} />
        <feGaussianBlur result="blur-2" stdDeviation={3} />
        <feFlood floodColor="#ec40f1" floodOpacity={0.702} result="color" />
        <feComposite in="SourceGraphic" in2="blur-2" operator="out" />
        <feComposite in="color" operator="in" />
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs>
    <g data-name="Group 54650">
      <g data-name="Group 54649" opacity={0.8} transform="translate(-378 -552)">
        <rect
          width={354}
          height={292}
          fill="#ec40f1"
          data-name="Rectangle 28682"
          rx={12}
          transform="translate(392 558)"
          opacity={0.3}
          filter="blur(45px)"
        />
        <g
          filter="url(#industryMarketValueGraphFilter1)"
          transform="translate(257 423)"
        >
          <g
            fill="url(#b)"
            stroke="rgba(255,255,255,0.3)"
            strokeWidth={1.5}
            data-name="Rectangle 28681"
            transform="translate(121 129)"
          >
            <rect width={354} height={289} stroke="none" rx={12} />
            <rect
              width={352.5}
              height={287.5}
              x={0.75}
              y={0.75}
              fill="none"
              rx={11.25}
            />
          </g>
        </g>
        <rect
          width={354}
          height={289}
          fill="rgba(255,255,255,0)"
          data-name="Rectangle 28683"
          rx={12}
          transform="translate(378 552)"
        />
      </g>
      <g data-name="Group 54648">
        <g data-name="Group 54592">
          <path
            fill="none"
            stroke="#b4b6c4"
            strokeLinecap="round"
            d="M73.305 238.954h260.779"
            data-name="Line 244"
            opacity={0.2}
          />
          <path
            fill="none"
            stroke="#f8f9fa"
            strokeLinecap="round"
            d="M73.305 202.232h260.779"
            data-name="Line 245"
            opacity={0.2}
          />
          <path
            fill="none"
            stroke="#f8f9fa"
            strokeLinecap="round"
            d="M73.305 165.513h260.779"
            data-name="Line 246"
            opacity={0.2}
          />
          <path
            fill="none"
            stroke="#f8f9fa"
            strokeLinecap="round"
            d="M73.305 128.792h260.779"
            data-name="Line 247"
            opacity={0.2}
          />
          <path
            fill="none"
            stroke="#f8f9fa"
            strokeLinecap="round"
            d="M73.305 92.073h260.779"
            data-name="Line 248"
            opacity={0.2}
          />
          <text
            fill="#e1e1e1"
            data-name={250}
            fontFamily="Poppins-SemiBold, Poppins"
            fontSize={12}
            fontWeight={600}
            style={{
              whiteSpace: 'pre',
            }}
            transform="translate(54.518 96.121)"
          >
            <tspan x={-25} y={0}>
              {'30 B'}
            </tspan>
          </text>
          <text
            fill="#e1e1e1"
            data-name={250}
            fontFamily="Poppins-SemiBold, Poppins"
            fontSize={12}
            fontWeight={600}
            style={{
              whiteSpace: 'pre',
            }}
            transform="translate(54.648 130.544)"
          >
            <tspan x={-22} y={0}>
              {'10 B'}
            </tspan>
          </text>
          <text
            fill="#e1e1e1"
            data-name={250}
            fontFamily="Poppins-SemiBold, Poppins"
            fontSize={12}
            fontWeight={600}
            style={{
              whiteSpace: 'pre',
            }}
            transform="translate(54.995 167.265)"
          >
            <tspan x={-14} y={0}>
              {'1 B'}
            </tspan>
          </text>
          <text
            fill="#e1e1e1"
            data-name={250}
            fontFamily="Poppins-SemiBold, Poppins"
            fontSize={12}
            fontWeight={600}
            style={{
              whiteSpace: 'pre',
            }}
            transform="translate(51.998 203.986)"
          >
            <tspan x={-36} y={0}>
              {'500 M'}
            </tspan>
          </text>
          <text
            fill="#e1e1e1"
            data-name={250}
            fontFamily="Poppins-SemiBold, Poppins"
            fontSize={12}
            fontWeight={600}
            style={{
              whiteSpace: 'pre',
            }}
            transform="translate(53.041 240.708)"
          >
            <tspan x={-36} y={0}>
              {'250 M'}
            </tspan>
          </text>
          <text
            fill="#e1e1e1"
            data-name={250}
            fontFamily="Poppins-Medium, Poppins"
            fontSize={12}
            fontWeight={500}
            style={{
              whiteSpace: 'pre',
            }}
            transform="translate(296.042 268.177)"
          >
            <tspan x={0} y={0}>
              {'2026'}
            </tspan>
          </text>
          <text
            fill="#e1e1e1"
            data-name={250}
            fontFamily="Poppins-Medium, Poppins"
            fontSize={12}
            fontWeight={500}
            style={{
              whiteSpace: 'pre',
            }}
            transform="translate(241 268.177)"
          >
            <tspan x={0} y={0}>
              {'2025'}
            </tspan>
          </text>
          <text
            fill="#e1e1e1"
            data-name={250}
            fontFamily="Poppins-Medium, Poppins"
            fontSize={12}
            fontWeight={500}
            style={{
              whiteSpace: 'pre',
            }}
            transform="translate(188 268.177)"
          >
            <tspan x={0} y={0}>
              {'2024'}
            </tspan>
          </text>
          <text
            fill="#e1e1e1"
            data-name={250}
            fontFamily="Poppins-Medium, Poppins"
            fontSize={12}
            fontWeight={500}
            style={{
              whiteSpace: 'pre',
            }}
            transform="translate(135.364 268.177)"
          >
            <tspan x={0} y={0}>
              {'2023'}
            </tspan>
          </text>
          <text
            fill="#e1e1e1"
            data-name={250}
            fontFamily="Poppins-Medium, Poppins"
            fontSize={12}
            fontWeight={500}
            style={{
              whiteSpace: 'pre',
            }}
            transform="translate(83.196 268.177)"
          >
            <tspan x={0} y={0}>
              {'2022'}
            </tspan>
          </text>
          <text
            fill="#fff"
            data-name={250}
            fontFamily="Poppins-Bold, Poppins"
            fontSize={35}
            fontWeight={700}
            style={{
              whiteSpace: 'pre',
            }}
            transform="translate(95.998 53.999)"
          >
            <tspan x={-80} y={0}>
              {'28 Billion'}
            </tspan>
          </text>
          <text
            fill="#ffc8e4"
            data-name={250}
            fontFamily="Poppins-Bold, Poppins"
            fontSize={16}
            fontWeight={700}
            style={{
              whiteSpace: 'pre',
            }}
            transform="translate(217.532 54.882)"
          >
            <tspan x={-31} y={0}>
              {'by 2026'}
            </tspan>
          </text>
          <g data-name="Group 54589" transform="translate(286.215 60.882)">
            <rect
              width={51.477}
              height={19.463}
              fill="#ff00a2"
              stroke="#fff"
              data-name="Rectangle 27878"
              rx={4}
            />
            <g data-name="Group 54590">
              <g data-name="Group 53517" transform="translate(31.944 3.652)">
                <circle
                  cx={6.574}
                  cy={6.574}
                  r={6.574}
                  fill="#fff"
                  data-name="Ellipse 1450"
                />
                <path
                  fill="#38e7b2"
                  d="M7.159 4.923a.677.677 0 0 0-.958 0l-2.46 2.628c-.329.349-.037.871.479.871h4.921c.516 0 .807-.522.479-.871Z"
                  data-name="Path 59216"
                />
              </g>
              <text
                fill="#fff"
                stroke="rgba(0,0,0,0)"
                data-name={250}
                fontFamily="Poppins-SemiBold, Poppins"
                fontSize={9}
                fontWeight={600}
                letterSpacing=".038em"
                style={{
                  whiteSpace: 'pre',
                }}
                transform="translate(25.948 12.229)"
              >
                <tspan x={-19} y={0}>
                  {'28 B'}
                </tspan>
              </text>
            </g>
          </g>
          <g data-type="innerShadowGroup">
            <path
              fill="url(#industryMarketValueGraphGradient2)"
              d="M11720-4372.292v-2.078s7.355-38.325 49.478-40.224 54.757-19.329 54.757-19.329 20.277-21.513 53.521-14.864 56.038-22.178 56.038-22.178v151.388H11720Z"
              data-name="Path 60146"
              transform="translate(-11623.153 4557.446)"
            />
            <g
              filter="url(#industryMarketValueGraphFilter2)"
              transform="translate(-121.002 -128.998)"
            >
              <path
                fill="#fff"
                d="M217.85 314.158v-2.078s7.355-38.325 49.478-40.224 54.757-19.329 54.757-19.329 20.277-21.513 53.521-14.864 56.038-22.178 56.038-22.178v151.388H217.85Z"
                data-name="Path 60146"
              />
            </g>
          </g>
          <path
            fill="none"
            stroke="#ec40f1"
            d="M310.641 86.481s-22.8 28.827-56.038 22.178-53.521 14.864-53.521 14.864-12.633 17.429-54.757 19.329-49.478 40.224-49.478 40.224v2.078"
            data-name="Path 60145"
          />
          <path
            fill="url(#industryMarketValueGraphGradient3)"
            d="M3.652 0A3.652 3.652 0 0 1 7.3 3.652v45.386H0V3.652A3.652 3.652 0 0 1 3.652 0Z"
            data-name="Rectangle 258"
            transform="translate(93.716 189.096)"
          />
          <path
            fill="url(#industryMarketValueGraphGradient3)"
            d="M3.652 0A3.652 3.652 0 0 1 7.3 3.652v79.817H0V3.652A3.652 3.652 0 0 1 3.652 0Z"
            data-name="Rectangle 259"
            transform="translate(145.884 154.665)"
          />
          <path
            fill="url(#industryMarketValueGraphGradient3)"
            d="M3.652 0A3.652 3.652 0 0 1 7.3 3.652v100.684H0V3.652A3.652 3.652 0 0 1 3.652 0Z"
            data-name="Rectangle 260"
            transform="translate(198 133.798)"
          />
          <path
            fill="url(#industryMarketValueGraphGradient3)"
            d="M3.652 0A3.652 3.652 0 0 1 7.3 3.652V117.9H0V3.652A3.652 3.652 0 0 1 3.652 0Z"
            data-name="Rectangle 261"
            transform="translate(252 120.235)"
          />
          <path
            fill="url(#industryMarketValueGraphGradient3)"
            d="M3.652 0A3.652 3.652 0 0 1 7.3 3.652V142.94H0V3.652A3.652 3.652 0 0 1 3.652 0Z"
            data-name="Rectangle 262"
            transform="translate(306.561 95.194)"
          />
          <path
            fill="none"
            stroke="#ffbfe0"
            d="M73.371 238.656H334.21"
            data-name="Line 27"
            opacity={0.5}
          />
          <g data-name="Group 54591" transform="translate(305.09 83.829)">
            <g
              fill="#fff"
              stroke="#ff00a2"
              strokeWidth={0.5}
              data-name="Ellipse 1468"
              transform="translate(.074 .282)"
            >
              <circle cx={4.5} cy={4.5} r={4.5} stroke="none" />
              <circle cx={4.5} cy={4.5} r={4.25} fill="none" />
            </g>
            <circle
              cx={2.5}
              cy={2.5}
              r={2.5}
              fill="#ff00a2"
              data-name="Ellipse 1469"
              transform="translate(2.074 2.282)"
            />
          </g>
        </g>
      </g>
    </g>
  </motion.svg>
);
export default IndustryMarketValueGraph;
