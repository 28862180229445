import { baseQuery } from './baseQuery';

const apiVersion = process.env.REACT_APP_API_VERSION;

export const getTestimonials = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/testimonials`,
    method: 'GET',
    params,
  });

export const getFaqs = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/faqs`,
    method: 'GET',
    params,
  });

export const getAboutUsContent = () =>
  baseQuery({
    url: `/users/${apiVersion}/informative_contents?type=about_us`,
    method: 'GET',
  });

export const getPrivacyPolicyContent = () =>
  baseQuery({
    url: `/users/${apiVersion}/informative_contents?type=privacy_policy`,
    method: 'GET',
  });

export const getBrandTermsAndConditionsContent = () =>
  baseQuery({
    url: `/users/${apiVersion}/informative_contents?type=terms_condition_brand`,
    method: 'GET',
  });

export const getInfluencerTermsAndConditionsContent = () =>
  baseQuery({
    url: `/users/${apiVersion}/informative_contents?type=terms_condition_influencer`,
    method: 'GET',
  });

export const getHelpAndSupportContent = () =>
  baseQuery({
    url: `/users/${apiVersion}/informative_contents?type=help_support`,
    method: 'GET',
  });
