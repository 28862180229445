import React from 'react';
import { useQuery } from '@tanstack/react-query';
import ProfileStatus from 'components/inf-dashboard/ProfileStatus';
import { getProfile } from 'api/brand/profile';
import { useDialog } from 'stores/dialog';
import { DIALOGS } from 'constants/config/dialog-names';
import styles from 'assets/scss/pages/dashboard.module.scss';
import { waveIcon, editIcon, purplePlusSign } from 'constants/images';

const DashboardProfileSeaction = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['brand-profile'],
    queryFn: () => getProfile(),
  });
  const setDialog = useDialog((state) => state.setDialog);

  const onEditProfile = () => {
    setDialog(DIALOGS.BRAND_EDIT_PROFILE);
  };

  const onEditAddress = (addressId) => {
    setDialog(DIALOGS.BRAND_EDIT_ADDRESS, { addressId });
  };

  const onEditCategory = () => {
    setDialog(DIALOGS.BRAND_EDIT_PROFILE_CATEGORIES);
  };

  const onEditPreference = () => {
    setDialog(DIALOGS.BRAND_EDIT_PROFILE_PREFERENCES);
  };

  // const onEditGstDoc = () => {
  //   setDialog(DIALOGS.BRAND_EDIT_GST_DOC);
  // };

  const {
    first_name: firstName,
    last_name: lastName,
    profile_status: profileStatus,
    mobile,
    email,
    // designation,
    // company_role: role,
    addresses,
    // upload_gst: gstDoc,
    category_data: categories,
    preference_data: preferences,
  } = data?.data?.getProfile || {};

  if (isLoading) {
    return null;
  }

  if (profileStatus === 'verified') {
    return (
      <h4 className={styles.useGreeting}>
        Welcome back, {firstName} {lastName}! <img src={waveIcon} alt="" />
      </h4>
    );
  }

  return (
    <div className={styles.personlInfoCont}>
      <span className={styles.sideBar} />
      <div className={styles.innrBlock1}>
        <div className={styles.subblock1}>
          <h3 className={styles.perTxt}>Personal Information</h3>
          <button type="button" onClick={onEditProfile}>
            <img className={styles.editIcon} src={editIcon} alt="" />
          </button>
        </div>
        <ProfileStatus profileStatus={profileStatus} />
      </div>
      <div className={styles.innrBlock2}>
        <div className="profileForm">
          <div className={styles.detailsection}>
            <div className={styles.detailInner}>
              <label className={styles.labelTxt}>Full Name</label>
              <p className={styles.subTxt}>
                {firstName} {lastName}
              </p>
            </div>
            <div className={styles.detailInner}>
              <label className={styles.labelTxt}>Email Id</label>
              <p className={styles.subTxt}>{email}</p>
            </div>
            <div className={styles.detailInner}>
              <label className={styles.labelTxt}>Mobile Number</label>
              <p className={styles.subTxt}>+91 {mobile}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.innrBlock2}>
        <div className={styles.profileForm}>
          <div className={styles.subblock1}>
            <h3 className={styles.perTxt}>Category</h3>
            <button type="button" title="edit" onClick={onEditCategory}>
              <img src={editIcon} alt="edit" />
            </button>
          </div>
          <div className="detailsection">
            <div className={styles.detailInner}>
              <div className={styles.categoryBlock}>
                {categories?.map((category) => (
                  <span className={styles.categoryText} key={category}>
                    {category}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="profileForm">
          <div className={styles.subblock1}>
            <h3 className={styles.perTxt}>Preference</h3>
            <button type="button" title="edit" onClick={onEditPreference}>
              <img src={editIcon} alt="edit" />
            </button>
          </div>
          <div className="detailsection">
            <div className={styles.detailInner}>
              <div className={styles.categoryBlock}>
                {preferences?.map((preference) => (
                  <span className={styles.categoryText} key={preference}>
                    {preference}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.innrBlock2}>
        <div className={styles.profileForm}>
          <div className={styles.subblock1}>
            <h3 className={styles.perTxt}>Addresses</h3>
            <button
              type="button"
              title="Add address"
              onClick={onEditAddress}
              className={styles.addButton}
            >
              <img src={purplePlusSign} alt="edit" />
              <span>Add</span>
            </button>
          </div>
          <div className="detailsection">
            {addresses?.map(
              ({
                id,
                pincode,
                address_line_1: addressLine1,
                // address_line_2: addressLine2,
                landmark,
                state,
                city,
                address_type: addressType,
              }) => (
                <div className={styles.addressContainer} key={id}>
                  <div className={styles.detailInner}>
                    <p className={styles.subTxt} style={{ maxWidth: 350 }}>
                      {addressLine1 && `${addressLine1}, `}
                      {/* {addressLine2 && `${addressLine2}, `} */}
                      {landmark && `${landmark}, `}
                      {city && `${city}, `}
                      {state} - {pincode}
                      <br />
                      <span
                        style={{
                          marginLeft: '0px',
                          marginTop: '4px',
                          display: 'inline-block',
                        }}
                        className={styles.userStatus}
                      >
                        {addressType}
                      </span>
                    </p>
                  </div>
                  <button
                    type="button"
                    title="edit"
                    onClick={() => onEditAddress(id)}
                  >
                    <img src={editIcon} alt="edit" />
                  </button>
                </div>
              )
            )}
          </div>
        </div>
        {/* <div className="profileForm">
          <div className={styles.subblock1}>
            <h3 className={styles.perTxt}>GST Document</h3>
            <button type="button" title="edit" onClick={onEditGstDoc}>
              <img src={editIcon} alt="edit" />
            </button>
          </div>
          <div className="detailsection">
            <div className={styles.detailInner}>
              <div className={styles.categoryBlock}>
                {gstDoc?.split('/')?.slice(-1)?.[0]?.split("?")?.[0] || ''}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DashboardProfileSeaction;
