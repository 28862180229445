import React from 'react';
import PropTypes from 'prop-types';
import styles from 'assets/scss/pages/profile-dashboard.module.scss';
import Tooltip from 'components/common/Tooltip';

const SocialProfileStatus = ({ isVerified = false }) => (
  <p
    className={styles.approvedTxt}
    style={{ color: isVerified ? '#0C9B16' : '#EDAC3C', gap: 4 }}
  >
    {isVerified ? 'Verified' : 'In Progress'}
    {!isVerified && <Tooltip label="Your profile is under review." />}
  </p>
);

SocialProfileStatus.propTypes = {
  isVerified: PropTypes.bool,
};

export default SocialProfileStatus;
