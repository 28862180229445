const ADDRESS_TYPES = [
  { label: 'Home', value: 'home' },
  { label: 'Office', value: 'office' },
];
Object.freeze(ADDRESS_TYPES);

const LANGUAGES = [
  { label: 'Hindi', value: 'hindi' },
  { label: 'English', value: 'english' },
  { label: 'Bengali', value: 'bengali' },
  { label: 'Urdu', value: 'urdu' },
  { label: 'Punjabi', value: 'punjabi' },
  { label: 'Marathi', value: 'marathi' },
  { label: 'Telugu', value: 'telugu' },
  { label: 'Tamil', value: 'tamil' },
  { label: 'Gujarati', value: 'gujarati' },
  { label: 'Kannada	', value: 'kannada' },
  { label: 'Odia', value: 'odia' },
  { label: 'Malayalam', value: 'malayalam' },
  { label: 'Assamese', value: 'assamese' },
  { label: 'Santali', value: 'santali' },
  { label: 'Meitei (Manipuri)', value: 'meitei' },
];
Object.freeze(LANGUAGES);

const OTP_COUNTDOWN_SECONDS = 60;
export { OTP_COUNTDOWN_SECONDS, ADDRESS_TYPES, LANGUAGES };
