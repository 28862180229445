import { Dialog } from '@headlessui/react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';
import InReviewAnimation from 'assets/lottie/profile-in-review.json';
import { coloredCrossIcon } from 'constants/images';
import Button from 'components/common/Button';
import styles from '../PasswordResetSuccessDialog/PasswordResetSuccessDialog.module.scss';

function ProfileUnderReviewDialog({ isOpen = false, onClose }) {
  return (
    <Dialog open={isOpen} onClose={() => onClose(false)} className="">
      {/* following div will be backdrop */}
      <div className="" aria-hidden="true" />

      <div className={styles.containerhoverlay}>
        <div className={styles.customInner}>
          <Dialog.Panel className={styles.container_content}>
            <button
              type="button"
              className={styles.crossIcon}
              onClick={() => onClose(false)}
            >
              <img src={coloredCrossIcon} alt="" />
            </button>
            <div className={styles.reviewImage}>
              <Player
                autoplay
                loop
                keepLastFrame
                src={InReviewAnimation}
                style={{
                  position: 'absolute',
                  bottom: 0,
                  transform: 'scale(1.5)',
                }}
              />
            </div>

            <Dialog.Title
              className={styles.popupTitle}
              style={{ color: '#7C087F' }}
            >
              Great! You're All Set
            </Dialog.Title>
            <Dialog.Description className={styles.popupDesc}>
              We are reviewing your profile <br />
              We'll get back to you soon
            </Dialog.Description>
            <Button
              onClick={() => onClose(false)}
              style={{
                marginBottom: 60,
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                minWidth: 140,
              }}
            >
              Ok
            </Button>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}

ProfileUnderReviewDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ProfileUnderReviewDialog;
