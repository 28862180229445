import { create } from 'zustand';

const initialState = {
  dialog: null,
  dialogProps: {},
};

export const useDialog = create((set) => ({
  ...initialState,
  setDialog: (dialog, dialogProps) =>
    set(() => ({ dialog, dialogProps: dialogProps || {} })),
  clearDialog: () => set(initialState),
  reset: () => set(initialState),
}));
