import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Label,
  Text,
} from 'recharts';
import { format } from 'date-fns';
import { useTooltipContext } from 'components/custom/CustomTooltip';

const TopCampaginsBarChart = ({ dashboardData, filterData }) => {
  const { openTooltip, closeTooltip } = useTooltipContext();
  const tooltipContent = (e, bar) => {
    const { data, date } = e || {};
    return (
      <div
        style={{
          background: '#fff',
          padding: 8,
          border: '1px solid #FF00A233',
          boxShadow: '0px 2px 10px #FF00A24D',
        }}
      >
        <p style={{ fontSize: 14, color: '#7B8794', fontWeight: 600 }}>
          {date ? format(new Date(date), 'dd MMM yyyy') : ''}
        </p>
        <p
          style={{
            fontSize: 14,
            color:
              (bar === 0 && '#FF00A2') ||
              (bar === 1 && '#7C087F') ||
              (bar === 2 && '#d633db'),
            fontWeight: 600,
          }}
        >
          {data && data[bar]?.campaign_name}
        </p>
        <p
          style={{
            fontSize: 14,
            color:
              (bar === 0 && '#FF00A2') ||
              (bar === 1 && '#7C087F') ||
              (bar === 2 && '#d633db'),
            fontWeight: 600,
          }}
        >
          {(filterData === 'views' && 'Views') ||
            (filterData === 'likes' && 'Likes') ||
            (filterData === 'comments' && 'Comments')}
          :
          {data &&
            data[bar][
              (filterData === 'views' && 'total_views') ||
                (filterData === 'likes' && 'total_likes') ||
                (filterData === 'comments' && 'total_comments')
            ]}
        </p>
      </div>
    );
  };

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={500}
          height={300}
          data={dashboardData}
          margin={{
            top: 30,
            right: 0,
            left: 0,
            bottom: 5,
          }}
          barGap={2}
          barSize={32}
        >
          <CartesianGrid vertical={false} strokeWidth={1} stroke="#E6E6E6" />
          <XAxis
            dataKey={(dateValue) =>
              new Date(dateValue.date).toLocaleDateString('en-GB', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
            }
            tickLine={false}
            axisLine={false}
            fontSize={14}
            fill="#7B8794"
          />
          <YAxis
            label={
              <Label
                value={
                  (filterData === 'likes' && 'Likes') ||
                  (filterData === 'views' && 'Views') ||
                  (filterData === 'comments' && 'Comments')
                }
                angle={270}
                dx={-20}
                fill="#1F2933"
                fontSize={14}
              />
            }
            tickLine={false}
            axisLine={false}
            fontSize={14}
            fill="#7B8794"
          />

          <Legend
            formatter={(value) => (
              <span style={{ color: '#1F2933', fontSize: 14 }}>{value}</span>
            )}
            payload={
              dashboardData?.length > 0
                ? dashboardData[0]?.data?.map((pl, i) => ({
                    value: pl?.campaign_name,
                    type: 'circle',
                    color:
                      (i === 0 && '#FF00A2') ||
                      (i === 1 && '#7C087F') ||
                      (i === 2 && '#E289F2'),
                  }))
                : []
            }
          />

          <Bar
            dataKey={(dataKey) =>
              dataKey?.data[0][
                (filterData === 'views' && 'total_views') ||
                  (filterData === 'likes' && 'total_likes') ||
                  (filterData === 'comments' && 'total_comments')
              ]
            }
            fill="#FF00A2"
            legendType="circle"
            radius={4}
            isAnimationActive={false}
            onMouseEnter={(e) =>
              openTooltip({
                content: tooltipContent(e, 0),
              })
            }
            onMouseLeave={() => closeTooltip()}
          />
          <Bar
            dataKey={(dataKey) =>
              dataKey?.data[1]?.[
                (filterData === 'views' && 'total_views') ||
                  (filterData === 'likes' && 'total_likes') ||
                  (filterData === 'comments' && 'total_comments')
              ]
            }
            fill="#7C087F"
            legendType="circle"
            radius={4}
            isAnimationActive={false}
            onMouseEnter={(e) =>
              openTooltip({
                content: tooltipContent(e, 1),
              })
            }
            onMouseLeave={() => closeTooltip()}
          />
          <Bar
            dataKey={(dataKey) =>
              dataKey?.data[2]?.[
                (filterData === 'views' && 'total_views') ||
                  (filterData === 'likes' && 'total_likes') ||
                  (filterData === 'comments' && 'total_comments')
              ]
            }
            fill="#E289F2"
            legendType="circle"
            radius={4}
            isAnimationActive={false}
            onMouseEnter={(e) =>
              openTooltip({
                content: tooltipContent(e, 2),
              })
            }
            onMouseLeave={() => closeTooltip()}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

TopCampaginsBarChart.propTypes = {
  props: PropTypes.object,
  dashboardData: PropTypes.array,
  filterData: PropTypes.string,
};

export default TopCampaginsBarChart;
