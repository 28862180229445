import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useSpinDelay } from 'spin-delay';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCustomLists } from 'api/brand/search';
import { Avatar, AvatarGroup } from 'components/common/Avatar';
import Pagination from 'components/common/Table/Pagination';
import { NoData } from 'components/common/NoData';
import useFilterProps from 'hooks/useFilterProps';
import useDebounce from 'hooks/useDebounce';
import { useDialog } from 'stores/dialog';
import { DIALOGS } from 'constants/config/dialog-names';
import { PLATFORM_ICONS } from 'constants/config/platformIcons';
import styles from 'assets/scss/pages/search.module.scss';
import {
  // cardtransBg,
  customInnrImg,
  profileIcon,
  searchImg,
  whiteAddIcon,
} from 'constants/images';
import { LoaderWithWrapper } from 'components/common/Loader';

const CustomList = () => {
  const setDialog = useDialog((state) => state.setDialog);

  const { page, rowsPerPage, changePage, search, changeSearch } =
    useFilterProps({ rowsPerPage: 6 });

  const debouncedSearchQuery = useDebounce(search);

  const { data, isLoading } = useQuery({
    queryKey: ['brand-custom-lists', page, rowsPerPage, debouncedSearchQuery],
    queryFn: () =>
      getCustomLists({
        pageNo: page,
        pageSize: rowsPerPage,
        search: debouncedSearchQuery || undefined,
      }),
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  const onSearchChange = (e) => {
    changeSearch(e.target.value);
  };

  const customLists = useMemo(
    () =>
      data?.data?.viewCustomWhishlist?.map((customList) => {
        const influencers = customList?.influencer_whishlists;

        const categoryArray = influencers?.reduce(
          (accumulator, { category_name: subCategories }) => {
            subCategories.forEach(({ subcategory: subCategory }) => {
              if (accumulator.includes(subCategory?.category?.category_name)) {
                return;
              }
              accumulator.push(subCategory?.category?.category_name);
            });

            return accumulator;
          },
          []
        );
        const platforms = influencers?.reduce((accumulator, { influencer }) => {
          const {
            is_facebook: isFacebook,
            is_instagram: isInstagram,
            is_twitter: isTwitter,
            is_youtube: isYoutube,
          } = influencer || {};
          if (accumulator.length === 4) {
            return accumulator;
          }
          if (isFacebook && !accumulator.includes('facebook')) {
            accumulator.push('facebook');
          }
          if (isInstagram && !accumulator.includes('instagram')) {
            accumulator.push('instagram');
          }
          if (isTwitter && !accumulator.includes('twitter')) {
            accumulator.push('twitter');
          }
          if (isYoutube && !accumulator.includes('youtube')) {
            accumulator.push('youtube');
          }
          return accumulator;
        }, []);
        return {
          ...customList,
          categoryString: categoryArray.toString(),
          platforms,
        };
      }),
    [data]
  );
  const customListsCount = data?.data?.count;

  return (
    <>
      <div className={styles.influencerHeader} style={{ paddingBottom: '0px' }}>
        <div className={styles.customListDiv}>
          <h1 className={styles.inflencrTxt}>Custom List</h1>
        </div>
      </div>
      <div className={styles.influencerHeader} style={{ paddingTop: '20px' }}>
        <div className={styles.subCategoryTitle}>
          <h2 className={styles.trendingTxt}>{customListsCount} List Found</h2>
          <div className={clsx(styles.searchField, styles.customSearch)}>
            <img className={styles.sIcon} src={searchImg} alt="" />
            <input
              className={styles.searchTxt}
              placeholder="Type to search list below"
              type="text"
              value={search || ''}
              onChange={onSearchChange}
            />
            <button
              className={styles.addList}
              type="button"
              onClick={() => setDialog(DIALOGS.CREATE_CUSTOM_LIST)}
            >
              <img src={whiteAddIcon} alt="" /> Create New Custom List
            </button>
          </div>
        </div>
      </div>
      {isLoading || delayedIsLoading ? (
        <LoaderWithWrapper show={delayedIsLoading} />
      ) : (
        <>
          {customLists?.length > 0 ? (
            <div className={styles.customBoxWrapper}>
              {customLists?.map(
                ({
                  id,
                  whishlist_name: listName,
                  influencer_whishlists: influencers,
                  createdAt,
                  categoryString,
                  platforms,
                }) => (
                  <Link
                    to={id}
                    className={styles.customBox}
                    key={id}
                    state={{ listName }}
                  >
                    <div className={styles.cardtransBg}>
                      <img src={customInnrImg} alt="" />
                    </div>
                    <div className={styles.customHeader}>
                      <h2 className={styles.brandHeading}>{listName}&nbsp;</h2>
                      <p className={styles.brandDetail}>
                        Created On:{' '}
                        <span>
                          {format(new Date(createdAt), 'dd MMMM yyyy')}
                        </span>
                      </p>
                      <div className={styles.custmInnrImg}>
                        <AvatarGroup>
                          {influencers?.length > 0 ? (
                            influencers.map(
                              ({
                                influencer: { profile_image: image } = {},
                                id,
                              }) => <Avatar src={image} alt="" key={id} />
                            )
                          ) : (
                            <Avatar src={profileIcon} alt="" />
                          )}
                        </AvatarGroup>
                        <div className={styles.customImgs}>
                          {platforms.map((platform) => (
                            <img
                              key={platform}
                              className={styles.iconWidth}
                              src={PLATFORM_ICONS[platform]}
                              alt=""
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className={styles.customBody}>
                      <span className={styles.brandDetail}>
                        Category: {categoryString}
                      </span>
                      <span className={styles.brandDetail}>
                        No. of influencers: {influencers?.length}
                      </span>
                      {/* <span className={styles.brandDetail}>Age group: 20-45</span> */}
                    </div>
                  </Link>
                )
              )}
            </div>
          ) : (
            <NoData
              title="No Custom Lists Found!"
              description="You did not created a custom list yet."
            />
          )}
          {customLists?.length > 0 && (
            <div className={styles.influencerHeader}>
              <Pagination
                currentPage={page}
                changePage={changePage}
                count={customListsCount}
                rowsPerPage={rowsPerPage}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CustomList;
