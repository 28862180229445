const DIALOGS = {
  BRAND_INF_COST_OVERVIEW: 'BRAND_INF_COST_OVERVIEW',
  BRAND_INF_PROFILE_OVERVIEW: 'BRAND_INF_PROFILE_OVERVIEW',
  BRAND_INF_BRAND_POSTS: 'BRAND_INF_BRAND_POSTS',
  BRAND_USER_TYPES: 'BRAND_USER_TYPES',
  ADD_INF_TO_EXISTING_CAMPAIGN: 'ADD_INF_TO_EXISTING_CAMPAIGN',
  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  BRAND_DELETE_CAMPAIGN: 'BRAND_DELETE_CAMPAIGN',
  DELIVERABLES_COST_BREAKDOWN: 'DELIVERABLES_COST_BREAKDOWN',
  CAMPAIGN_INSIGHTS: 'CAMPAIGN_INSIGHTS',
  ADD_INF_TO_CUSTOM_LIST: 'ADD_INF_TO_CUSTOM_LIST',
  CREATE_CUSTOM_LIST: 'CREATE_CUSTOM_LIST',
  QUESTIONAIRE: 'QUESTIONAIRE',
  BRAND_EDIT_PROFILE: 'BRAND_EDIT_PROFILE',
  BRAND_EDIT_PROFILE_CATEGORIES: 'BRAND_EDIT_PROFILE_CATEGORIES',
  BRAND_EDIT_PROFILE_PREFERENCES: 'BRAND_EDIT_PROFILE_PREFERENCES',
  BRAND_EDIT_GST_DOC: 'BRAND_EDIT_GST_DOC',
  BRAND_EDIT_PAN_DOC: 'BRAND_EDIT_PAN_DOC',
  BRAND_EDIT_ADDRESS: 'BRAND_EDIT_ADDRESS',
  INF_EDIT_PROFILE: 'INF_EDIT_PROFILE',
  INF_EDIT_PAN_DOC: 'INF_EDIT_PAN_DOC',
  INF_EDIT_GST_DOC: 'INF_EDIT_GST_DOC',
  INF_SCHEDULE_POST: 'INF_SCHEDULE_POST',
  INF_EDIT_PROFILE_LANGUAGES: 'INF_EDIT_PROFILE_LANGUAGES',
  INF_EDIT_ADDRESS: 'INF_EDIT_ADDRESS',
  INF_SCHEDULE_CAMPAGIN: 'INF_SCHEDULE_CAMPAGIN',
  INF_UPLOAD_CAMPAGIN_DELIVERABLE: 'INF_UPLOAD_CAMPAGIN_DELIVERABLE',
  INF_RESET_PASSWORD: 'INF_RESET_PASSWORD',
  INF_BRAND_POSTS: 'INF_BRAND_POSTS',
  INF_COST_OVERVIEW: 'INF_COST_OVERVIEW',
  INF_EDIT_RATE_CARD: 'INF_EDIT_RATE_CARD',
};
Object.freeze(DIALOGS);
export { DIALOGS };
