import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import ErrorMessage from 'components/common/form/ErrorMessage';
import { Dialog } from 'components/common/dialog';
import { queryClient } from 'App';
import { INFLUENCER_SCHEDULE_POST_SCHEMA } from 'constants/schema';
import styles from 'assets/scss/pages/popup.module.scss';
import { postSchedule } from 'api/influencer/campaign';

const defaultValues = {
  dateTime: '',
};

const SchedulePost = ({ open, close, campaignId, postId, platform }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(INFLUENCER_SCHEDULE_POST_SCHEMA),
    defaultValues,
  });

  const scheduleMutation = useMutation({
    mutationFn: ({ dateTime }) => {
      const payload = {
        dateTime: new Date(dateTime).toISOString(),
        id: postId,
        publish_type: 'schedule',
        // publish_type: 'instant',
        platform,
      };
      if (platform === 'youtube') {
        payload.redirect_uri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
      }
      return postSchedule(payload);
    },
    onSuccess: () => {
      toast.success('Post scheduled successfully', {
        position: 'top-center',
      });
      queryClient.invalidateQueries({
        queryKey: ['influencer-campaign-deliverables', campaignId, platform],
      });
      reset();
      close();
    },
  });

  return (
    <Dialog
      title="Schedule Post"
      open={open}
      close={close}
      size="591px"
      fullWidth
    >
      <form onSubmit={handleSubmit(scheduleMutation.mutate)}>
        <div className={styles.editFormWrapper}>
          <div className={styles.gstUploadWrapper}>
            <div className={styles.timeDiv}>
              <input
                type="datetime-local"
                className={styles.innrTimeTxt}
                {...register('dateTime')}
              />
            </div>
            <ErrorMessage error={errors?.dateTime} />
          </div>
        </div>
        <div className={styles.editProfileContainer}>
          <div
            className={clsx(styles.campaignBtns, styles.nxtWrap)}
            style={{ marginTop: '16px' }}
          >
            <button type="button" className={styles.prevBtn} onClick={close}>
              Cancel
            </button>
            <button
              type="submit"
              style={{ width: '30%' }}
              className={styles.updateBtn}
            >
              Proceed
            </button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

SchedulePost.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  campaignId: PropTypes.string,
  postId: PropTypes.string,
  platform: PropTypes.string,
};
export default SchedulePost;
