import React, { useMemo } from 'react';
import { formatNumber } from 'utils';

const useFormatCampaignAnalyticsData = ({ trackingData }) => {
  const { audienceEngRate, avgEngRate, totalAudienceReach, avgAudienceReach } =
    useMemo(() => {
      const audienceEngRate = [];
      let avgEngRate = 0;
      let totalAudienceReach = 0;
      let avgAudienceReach = 0;

      trackingData?.camp_metrics?.forEach((platformMetrics) => {
        const engagementRate = parseInt(
          formatNumber(platformMetrics.engagement_rate),
          10
        );
        const audienceReach = parseInt(
          formatNumber(platformMetrics.audience_reach),
          10
        );
        avgEngRate = (avgEngRate + engagementRate) / 2;
        totalAudienceReach += audienceReach;
        avgAudienceReach = (avgAudienceReach + audienceReach) / 2;

        audienceEngRate.push({
          id: platformMetrics.platform,
          audienceReach,
          engagementRate,
          platform: platformMetrics.platform,
          color:
            (platformMetrics.platform === 'youtube' && '#E289F2') ||
            (platformMetrics.platform === 'instagram' && '#7C087F') ||
            (platformMetrics.platform === 'facebook' && '#FF00A2') ||
            (platformMetrics.platform === 'twitter' && '#7F60FF'),
        });
      });
      return {
        audienceEngRate,
        avgEngRate,
        totalAudienceReach,
        avgAudienceReach,
      };
    }, [trackingData]);

  const influencerPerformance = useMemo(
    () => [
      {
        id: 'audienceReach',
        value: avgAudienceReach,
        color: '#FF00A2',
      },
      {
        id: 'remaining',
        value: 100 - avgAudienceReach,
        color: '#F1F1F5',
      },
    ],
    [avgAudienceReach]
  );

  const campaignPerformance = useMemo(
    () => [
      {
        id: 'avgEngagementRate',
        value: avgEngRate,
        color: '#FF00A2',
      },
      {
        id: 'remaining',
        value: 100 - avgEngRate,
        color: '#F1F1F5',
      },
    ],
    [avgEngRate]
  );
  return {
    totalPosts: trackingData?.total_media,
    totalViews: trackingData?.total_views,
    totalLikes: trackingData?.total_likes,
    totalComments: trackingData?.total_comments,
    totalInfluencers: trackingData?.total_influencer_count,
    engagementRate: avgEngRate,
    audienceReach: totalAudienceReach,
    avgAudienceReach,
    hashtags: trackingData?.hashtag || [],
    posts: Array.prototype.concat.apply(
      [],
      Object.values(trackingData?.post || {})
    ),
    audienceEngRate,
    influencerPerformance,
    campaignPerformance,
  };
};

export default useFormatCampaignAnalyticsData;
