import { useEffect, useState } from 'react';

function useCountdownTimer(seconds) {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    if (!timeLeft) return undefined;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return {
    timeLeft,
    setTimeLeft,
    formattedValue: new Date(timeLeft * 1000).toISOString().substring(14, 19),
  };
}

export default useCountdownTimer;
