import React, { useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useInfulencerOnboardingForm } from 'stores/onboarding';
import Button from 'components/common/Button';
import CountryCodeSelect from 'components/common/CountryCodeSelect';

import { signup } from 'api/influencer/auth';
import { INFLUENCER_SIGNUP_SCHEMA } from 'constants/schema';
import { userPlaceholderImage } from 'constants/images';
import { ReactComponent as UploadIcon } from 'assets/images/upload_icon.svg';
import styles from 'assets/scss/pages/onboarding.module.scss';
import routes from 'constants/routes';

const defaultValues = {
  profile: '',
  name: '',
  email: '',
  mobile: '',
  countryCode: '+91',
};

function InfluencerProfileForm() {
  const [hasManager, setHasManager] = useState(true);
  const navigate = useNavigate();

  const { formData, reset } = useInfulencerOnboardingForm();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(INFLUENCER_SIGNUP_SCHEMA),
    defaultValues,
  });

  const signupMutation = useMutation({
    mutationFn: ({ profile, name, email, mobile, countryCode }) => {
      const payload = new FormData();
      payload.append('mobile', mobile);
      payload.append('country_code', countryCode);
      payload.append('full_name', name);
      payload.append('email', email);
      payload.append('role', formData.type);
      payload.append('profile_image', profile[0]);

      return signup(payload);
    },
    onSuccess: (_, variables) => {
      reset();
      navigate(routes.INF_SIGNUP_VERIFY_OTP, {
        state: { ...variables, ...formData, hasManager },
      });
    },
  });

  const selectedProfile = watch('profile')?.[0];
  const profilePreview = selectedProfile
    ? URL.createObjectURL(selectedProfile)
    : '';

  return (
    <>
      <h1>Complete Profile</h1>
      <p>Enter details for your account</p>
      <form
        className={clsx(styles.signupFormItems, styles.profile)}
        onSubmit={handleSubmit(signupMutation.mutate)}
      >
        <div>
          <div className={styles.profileImageUpload}>
            <img src={profilePreview || userPlaceholderImage} alt="" />
            <label className={styles.uploadIconImage}>
              <input
                type="file"
                {...register('profile')}
                accept="image/*"
                hidden
              />
              <UploadIcon />
            </label>
          </div>
          {errors?.profile ? (
            <p className={styles.error}>{errors.profile?.message}</p>
          ) : null}
        </div>
        <div>
          <input
            type="text"
            placeholder="Enter Your Full name"
            maxLength="40"
            {...register('name')}
          />
          {errors?.name ? (
            <p className={styles.error}>{errors.name?.message}</p>
          ) : null}
        </div>
        <div>
          <input
            type="email"
            placeholder="Enter Your Email Id"
            maxLength="40"
            {...register('email')}
          />
          {errors?.email ? (
            <p className={styles.error}>{errors.email?.message}</p>
          ) : null}
        </div>
        <div>
          <div className={styles.mobileWrapper}>
            <div className={styles.countryCodeSelect}>
              <Controller
                render={({ field }) => (
                  <CountryCodeSelect
                    onChange={field.onChange}
                    value={field.value}
                  />
                )}
                control={control}
                name="countryCode"
              />
            </div>
            <input
              type="tel"
              placeholder="Enter Your Mobile Number"
              maxLength="10"
              {...register('mobile')}
            />
          </div>
          {errors?.mobile ? (
            <p className={styles.error}>{errors.mobile?.message}</p>
          ) : null}
        </div>
        <div className={styles.managerQueryWrapper}>
          <p>Are you associated with a manager?</p>
          <div className={styles.yesNoButtons}>
            <button
              type="button"
              className={clsx(hasManager && styles.activeBtn)}
              onClick={() => setHasManager(true)}
            >
              YES
            </button>
            <button
              type="button"
              className={clsx(!hasManager && styles.activeBtn)}
              onClick={() => setHasManager(false)}
            >
              NO
            </button>
          </div>
        </div>
        <Button
          type="submit"
          size="large"
          fullWidth
          isLoading={signupMutation.isLoading}
        >
          Next
        </Button>
      </form>
    </>
  );
}

export default InfluencerProfileForm;
