import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Footer from 'components/onboarding/Footer';
import Button from 'components/common/Button';

import { EMAIL_SCHEMA } from 'constants/schema';
import { logoImageBlackText } from 'constants/images';
import routes from 'constants/routes';
import { login } from 'api/brand/auth';
import styles from 'assets/scss/pages/onboarding.module.scss';

const defaultValues = { email: '' };

function Login() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EMAIL_SCHEMA),
    defaultValues,
  });

  const loginMutation = useMutation({
    mutationFn: (data) => login(data),
    onSuccess: (_, variables) => {
      navigate(routes.BRAND_SIGNUP_VERIFY_OTP, {
        state: { isLogin: true, email: variables.email },
      });
    },
  });

  return (
    <div className={styles.signupInnerContent}>
      <div className={styles.signupItems}>
        <div>
          <div>
            <Link to={routes.HOME}>
              <img src={logoImageBlackText} alt="" width={140} />
            </Link>
          </div>
          <h1>Log In to your account</h1>
          <p>Enter details to login into your account</p>
          <form
            className={styles.signupFormItems}
            onSubmit={handleSubmit(loginMutation.mutate)}
          >
            <div>
              <input
                type="email"
                placeholder="Enter Your Email ID"
                maxLength="70"
                {...register('email')}
              />
              {errors?.email ? (
                <p className={styles.error}>{errors.email?.message}</p>
              ) : null}
            </div>
            <Button
              type="submit"
              size="large"
              isLoading={loginMutation.isLoading}
              fullWidth
            >
              Login
            </Button>
          </form>
          <p className={styles.signupLink}>
            Don't have an account?{' '}
            <Link to={routes.BRAND_SIGNUP}>Sign Up here </Link>
          </p>
        </div>
      </div>
      <Footer type="brand" />
    </div>
  );
}

export default Login;
