import axiosBaseQuery from 'api/axios-base-query';
import { queryClient } from 'App';
import { useInfluencerAuth } from 'stores/auth/influencer';

export const baseQuery = axiosBaseQuery({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers) => {
    const { token } = useInfluencerAuth.getState();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    return headers;
  },
  onUnauthorized: () => {
    const { reset } = useInfluencerAuth.getState();
    reset();
    queryClient.clear();
  },
});
