import React, { useMemo } from 'react';
import clsx from 'clsx';
import { differenceInCalendarDays } from 'date-fns';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDialog } from 'stores/dialog';
import {
  deleteCampaign,
  getBrands,
  getCampaigns,
  getTags,
  updateCampaignStatus,
} from 'api/brand/campaign';
import { getStateList } from 'api/brand/search';
import useFilterProps from 'hooks/useFilterProps';
import useDebounce from 'hooks/useDebounce';
import { Avatar, AvatarGroup } from 'components/common/Avatar';
import Table from 'components/common/Table';
import Menu from 'components/common/Menu';
import FilterDropdown from 'components/common/FilterDropdown';
import { FilterChip } from 'components/common/Chip';
import Button from 'components/common/Button';
import TableError from 'components/common/Table/TableError';
import FilterMenu from 'components/common/FilterDropdown/FilterMenu';
import { queryClient } from 'App';
import routes from 'constants/routes';
import { DIALOGS } from 'constants/config/dialog-names';
import {
  CAMPAIGN_STATUS_LIST,
  GENDERS,
  PLATFORMS,
} from 'constants/config/filters';
import {
  CAMPAIGN_QUOTE_STATUS,
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_CHIPS,
} from 'constants/config/campaign';
import styles from 'assets/scss/pages/campaign.module.scss';
import { ReactComponent as MoreIcon } from 'assets/images/more-icon.svg';
import { ReactComponent as EyeIcon } from 'assets/images/feather-eye.svg';
import { ReactComponent as EditIcon } from 'assets/images/Edit Campaign.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/delete-2-svgrepo-com-red.svg';
import { ReactComponent as PauseIcon } from 'assets/images/Pause Campaign.svg';
import { ReactComponent as PlayIcon } from 'assets/images/Start Campaign.svg';
import { ReactComponent as ArchiveIcon } from 'assets/images/Archived Campaign.svg';
import { ReactComponent as CompleteCampaign } from 'assets/images/Complete Campaign.svg';
import {
  transparentBg,
  cogwheelIcon,
  locationSvg,
  searchImg,
  chooseTagsIcon,
  targetAudienceIcon,
  targetGendrIcon,
  statusIcon,
} from 'constants/images';
import { useCreateCampaign } from 'stores/create-campaign';
import { PLATFORM_ICONS } from 'constants/config/platformIcons';
import { formatNumber } from 'utils';
import { AlertDialog } from 'components/common/dialog';

const columns = [
  {
    Header: 'Campaign',
    accessor: 'campaign_name',
    width: 230,
    minWidth: 230,
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      const campaignStatus = row?.campaign_status;
      return (
        <div className={styles.profileDiv} style={{ paddingRight: '50px' }}>
          <Avatar src={row?.upload_image} alt="" size="large" />
          <div className="">
            <Link
              to={`${routes.BRAND_CAMPAIGNS}/${row?.id}`}
              className={styles.inflNameText}
            >
              {row?.campaign_name}
            </Link>
            <div className={styles.chipBtns}>
              {CAMPAIGN_STATUS_CHIPS[campaignStatus]}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    Header: 'Brand',
    accessor: 'brand_name',
    Cell: (cellInfo) => (
      <p className={styles.downloadedTxt}>{cellInfo.value}</p>
    ),
  },
  {
    Header: 'Platform',
    accessor: 'platform',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return (
        <AvatarGroup>
          {row?.target_platforms?.map((platformName) => (
            <Avatar
              src={PLATFORM_ICONS[platformName]}
              alt=""
              key={platformName}
            />
          ))}
        </AvatarGroup>
      );
    },
  },
  {
    Header: 'Duration (Days)',
    accessor: 'duration',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      if (row?.campaign_start_date && row?.campaign_end_date) {
        return `${differenceInCalendarDays(
          new Date(row?.campaign_end_date),
          new Date(row.campaign_start_date)
        )}`;
      }
      return '-';
    },
  },
  {
    Header: 'Region',
    accessor: 'region',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      const regionString = row?.regions
        ?.map((region) => region.region_name)
        .join(', ');
      return (
        <span title={regionString} className="line-clamp-2">
          {regionString}
        </span>
      );
    },
  },
  {
    Header: 'Gender',
    accessor: 'gender',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return (
        <p style={{ textTransform: 'capitalize' }}>
          {row?.target_genders?.length === 3
            ? 'All'
            : row?.target_genders?.join(', ')}{' '}
        </p>
      );
    },
  },
  {
    Header: 'Age group (Yrs)',
    accessor: 'age_group',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return row?.ages
        ?.map(({ age_from: from, age_to: to }) => `${from}-${to}`)
        .join(', ');
    },
  },
  {
    Header: 'Tags',
    accessor: 'tags',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      const tagsString = row?.social_hashtags
        ?.map((tag) => tag.hashtag_name)
        .join(', ');
      return (
        <span title={tagsString} className="line-clamp-2">
          {tagsString}
        </span>
      );
    },
  },
  {
    Header: '',
    accessor: 'options',
    width: 20,
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      const navigate = useNavigate();
      const setCampaingData = useCreateCampaign((state) => state.setFormData);
      const setDialog = useDialog((state) => state.setDialog);

      const updateCampaignStatusMutation = useMutation({
        mutationFn: ({ campaignId, status }) =>
          updateCampaignStatus({
            campaign_id: campaignId,
            campaign_status: status,
          }),
        onSuccess: (res, { campaignId }) => {
          queryClient.invalidateQueries({ queryKey: ['brand-campaigns'] });
          queryClient.invalidateQueries({
            queryKey: ['brand-campaign-details', campaignId],
          });
        },
      });

      const onEditCampaign = () => {
        setCampaingData({ campaignId: row?.id });
        setDialog(DIALOGS.CREATE_CAMPAIGN);
      };

      const onDeleteCampaign = () => {
        setDialog(DIALOGS.BRAND_DELETE_CAMPAIGN, {
          campaignId: row?.id,
          campaignName: row?.campaign_name,
        });
      };

      const onStartCampaign = () => {
        updateCampaignStatusMutation.mutate({
          campaignId: row?.id,
          status: CAMPAIGN_STATUS.ACTIVE,
        });
      };

      const onPauseCampaign = () => {
        updateCampaignStatusMutation.mutate({
          campaignId: row?.id,
          status: CAMPAIGN_STATUS.PAUSED,
        });
      };

      const onArchiveCampaign = () => {
        updateCampaignStatusMutation.mutate({
          campaignId: row?.id,
          status: CAMPAIGN_STATUS.ARCHIVED,
        });
      };

      const onCompleteCampaign = () => {
        updateCampaignStatusMutation.mutate({
          campaignId: row?.id,
          status: CAMPAIGN_STATUS.COMPLETED,
        });
      };

      const campaignStatus = row?.campaign_status;
      // const paymentStatus = row?.payment_status;
      const campaignQuoteStatus = row?.quotation_status;
      const isContractSigned =
        campaignQuoteStatus === CAMPAIGN_QUOTE_STATUS.USER_APPROVED_SIGNED;

      const showStartCampaign =
        (campaignStatus === CAMPAIGN_STATUS.UPCOMING ||
          campaignStatus === CAMPAIGN_STATUS.PAUSED) &&
        isContractSigned;

      return (
        <Menu
          renderButton={() => (
            <Menu.Button>
              <MoreIcon />
            </Menu.Button>
          )}
        >
          {campaignStatus === CAMPAIGN_STATUS.ACTIVE && (
            <Menu.Item onClick={onPauseCampaign}>
              <PauseIcon />
              <span>Pause Campaign</span>
            </Menu.Item>
          )}
          {showStartCampaign && (
            <Menu.Item onClick={onStartCampaign}>
              <PlayIcon />
              <span>Start Campaign</span>
            </Menu.Item>
          )}
          {campaignStatus === CAMPAIGN_STATUS.COMPLETED && (
            <Menu.Item onClick={onArchiveCampaign}>
              <ArchiveIcon />
              <span>Archived Campaign</span>
            </Menu.Item>
          )}
          {campaignStatus === CAMPAIGN_STATUS.ACTIVE && (
            <Menu.Item onClick={onCompleteCampaign}>
              <CompleteCampaign />
              <span>Complete Campaign</span>
            </Menu.Item>
          )}
          {campaignStatus === CAMPAIGN_STATUS.DRAFTED &&
            (!campaignQuoteStatus ||
              campaignQuoteStatus === CAMPAIGN_QUOTE_STATUS.NOT_SENT) && (
              <Menu.Item onClick={onEditCampaign}>
                <EditIcon />
                <span>Edit Campaign</span>
              </Menu.Item>
            )}
          {((campaignStatus === CAMPAIGN_STATUS.DRAFTED &&
            (!campaignQuoteStatus ||
              campaignQuoteStatus === CAMPAIGN_QUOTE_STATUS.NOT_SENT)) ||
            campaignStatus === CAMPAIGN_STATUS.DROPPED) && (
            <Menu.Item onClick={onDeleteCampaign}>
              <DeleteIcon />
              <span>Delete Campaign</span>
            </Menu.Item>
          )}
          <Menu.Item
            onClick={() => navigate(`${routes.BRAND_CAMPAIGNS}/${row?.id}`)}
          >
            <EyeIcon />
            <span>View Details</span>
          </Menu.Item>
        </Menu>
      );
    },
  },
];

const Campaigns = () => {
  const setDialog = useDialog((state) => state.setDialog);

  const {
    page,
    rowsPerPage,
    changePage,
    changeRowsPerPage,
    search,
    changeSearch,
    // sortBy,
    // sortDir,
    // changeSortBy,
    filter,
    changeFilter,
  } = useFilterProps();

  const debouncedSearchQuery = useDebounce(search);
  const platformFilter = filter.platform;
  const brandFilter = filter.brand;
  const statusFilter = filter.status;
  const tagsFilter = filter.tags;
  const regionFilter = filter.region;
  const ageGroupFilter = filter.ageGroup;
  const genderFilter = filter.gender;

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      'brand-campaigns',
      page,
      rowsPerPage,
      debouncedSearchQuery,
      platformFilter,
      brandFilter,
      statusFilter,
      tagsFilter,
      regionFilter,
      ageGroupFilter,
      genderFilter,
    ],
    queryFn: () =>
      getCampaigns({
        pageNo: page,
        pageSize: rowsPerPage,
        search: debouncedSearchQuery || undefined,
        platform: platformFilter || undefined,
        brand: brandFilter || undefined,
        status: statusFilter || undefined,
        tags: tagsFilter || undefined,
        region: regionFilter || undefined,
        age_from: ageGroupFilter ? ageGroupFilter[0] : undefined,
        age_to: ageGroupFilter ? ageGroupFilter[1] : undefined,
        gender: genderFilter || undefined,
      }),
  });

  const { data: states } = useQuery({
    queryKey: ['brand-states'],
    queryFn: () => getStateList(),
  });

  const { data: tags } = useQuery({
    queryKey: ['brand-campaign-tags-filter'],
    queryFn: () => getTags(),
  });

  const { data: brands } = useQuery({
    queryKey: ['brand-campaign-brands-filter'],
    queryFn: () => getBrands(),
  });

  const onSearchChange = (e) => {
    changeSearch(e.target.value);
  };

  const onPlatformFilterRemove = (id) => {
    changeFilter(
      'platform',
      platformFilter?.filter((platform) => platform !== id)
    );
  };
  const onBrandFilterRemove = (id) => {
    changeFilter(
      'brand',
      brandFilter?.filter((brand) => brand !== id)
    );
  };
  const onStatusFilterRemove = (id) => {
    changeFilter(
      'status',
      statusFilter?.filter((status) => status !== id)
    );
  };

  const onTagsFilterRemove = (id) => {
    changeFilter(
      'tags',
      tagsFilter?.filter((tags) => tags !== id)
    );
  };

  const onRegionFilterRemove = (id) => {
    changeFilter(
      'region',
      regionFilter?.filter((region) => region !== id)
    );
  };

  const onAgeGroupFilterRemove = () => {
    changeFilter('ageGroup', null);
  };

  const onGenderFilterRemove = (id) => {
    changeFilter(
      'gender',
      genderFilter?.filter((gender) => gender !== id)
    );
  };

  const onClearFilter = () => {
    changeFilter('platform', []);
    changeFilter('brand', []);
    changeFilter('status', []);
    changeFilter('tags', []);
    changeFilter('region', []);
    changeFilter('ageGroup', null);
    changeFilter('gender', []);
  };

  const selectedPlatforms = useMemo(
    () =>
      PLATFORMS.filter((platform) => platformFilter?.includes(platform.value)),
    [platformFilter]
  );

  const selectedStatus = useMemo(
    () =>
      CAMPAIGN_STATUS_LIST.filter((status) =>
        statusFilter?.includes(status.value)
      ),
    [statusFilter]
  );

  const selectedTags = useMemo(
    () => tags?.filter(({ id }) => tagsFilter?.includes(id)) || [],
    [tags, tagsFilter]
  );

  const selectedRegions = useMemo(
    () => states?.filter(({ id }) => regionFilter?.includes(id)) || [],
    [states, regionFilter]
  );

  const selectedGenders = useMemo(
    () => GENDERS.filter((gender) => genderFilter?.includes(gender.value)),
    [genderFilter]
  );

  const campaigns = data?.data?.campaigns?.rows;
  const campaignsCount = data?.data?.campaigns?.count;
  const showClearButton =
    platformFilter?.length ||
    brandFilter?.length ||
    statusFilter?.length ||
    tagsFilter?.length ||
    regionFilter?.length ||
    ageGroupFilter?.length ||
    genderFilter?.length;

  return (
    <>
      <div className={styles.dashboardTop}>
        <img className={styles.transBg} src={transparentBg} alt="" />
        <div className={styles.campaignContainer}>
          <h2 className={styles.campignTxt}>Campaigns</h2>
        </div>
      </div>
      <div>
        <div className={styles.influencerHeader}>
          <div className={styles.categorySelector}>
            <FilterDropdown
              options={PLATFORMS}
              label="Platform"
              startIconUrl={cogwheelIcon}
              onChange={(newValue) => changeFilter('platform', newValue)}
              value={platformFilter}
            />
            <FilterDropdown
              options={brands}
              label="Brand"
              labelSelector="name"
              valueSelector="name"
              startIconUrl={cogwheelIcon}
              onChange={(newValue) => changeFilter('brand', newValue)}
              value={brandFilter}
            />
            <FilterDropdown
              options={CAMPAIGN_STATUS_LIST}
              label="Status"
              startIconUrl={statusIcon}
              onChange={(newValue) => changeFilter('status', newValue)}
              value={statusFilter}
            />
            <FilterDropdown
              options={tags}
              label="Tags"
              labelSelector="name"
              valueSelector="id"
              startIconUrl={chooseTagsIcon}
              onChange={(newValue) => changeFilter('tags', newValue)}
              value={tagsFilter}
            />
            <FilterDropdown
              options={states}
              label="Region"
              labelSelector="name"
              valueSelector="id"
              startIconUrl={locationSvg}
              onChange={(newValue) => changeFilter('region', newValue)}
              value={regionFilter}
            />
            <div style={{ width: 173, flexShrink: 0 }}>
              <FilterMenu.RangeSelector
                label="Age Group"
                startIconUrl={targetAudienceIcon}
                minLimit={0}
                maxLimit={100}
                onClear={() => changeFilter('ageGroup', null)}
                onApply={(newValue) => changeFilter('ageGroup', newValue)}
                buttonText={
                  ageGroupFilter
                    ? `${ageGroupFilter[0]} - ${ageGroupFilter[1]} yrs`
                    : 'All'
                }
                value={ageGroupFilter}
                isMenuChild={false}
                placement="bottom-start"
                fullWidth
                portal
              />
            </div>
            <FilterDropdown
              options={GENDERS}
              label="Gender"
              startIconUrl={targetGendrIcon}
              onChange={(newValue) => changeFilter('gender', newValue)}
              value={genderFilter}
            />
          </div>
        </div>
        <div className="influencerList">
          <div className={styles.influencerSelectors}>
            {selectedPlatforms?.map(({ icon, label, value }) => (
              <FilterChip
                icon={icon}
                label={label}
                isActive
                key={label}
                value={value}
                onRemove={onPlatformFilterRemove}
              />
            ))}
            {brandFilter?.map((brand) => (
              <FilterChip
                label={brand}
                key={brand}
                value={brand}
                onRemove={onBrandFilterRemove}
              />
            ))}
            {selectedStatus?.map(({ label, value }) => (
              <FilterChip
                label={label}
                key={value}
                value={value}
                onRemove={onStatusFilterRemove}
              />
            ))}
            {selectedTags?.map(({ id, name }) => (
              <FilterChip
                label={name}
                key={id}
                value={id}
                onRemove={onTagsFilterRemove}
              />
            ))}
            {selectedRegions?.map(({ id, name }) => (
              <FilterChip
                label={name}
                key={id}
                value={id}
                onRemove={onRegionFilterRemove}
              />
            ))}
            {ageGroupFilter && (
              <FilterChip
                label={`Age ${ageGroupFilter[0]}-${ageGroupFilter[1]}`}
                onRemove={onAgeGroupFilterRemove}
              />
            )}
            {selectedGenders?.map(({ label, value }) => (
              <FilterChip
                label={label}
                key={value}
                value={value}
                onRemove={onGenderFilterRemove}
              />
            ))}
            {showClearButton ? (
              <Button variant="text" onClick={onClearFilter}>
                Clear Filters
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.influencerHeader}>
        <div className={styles.subCategoryTitle}>
          <h2 className={styles.trendingTxt}>
            {formatNumber(campaignsCount, { notation: 'standard' })} Campaigns
            Found
          </h2>
          <div className={clsx(styles.searchField, styles.campaignField)}>
            <div className={styles.searchAction}>
              <img className={styles.sIcon} src={searchImg} alt="" />
              <input
                className={styles.searchTxt}
                placeholder="Type to search list below"
                type="text"
                value={search || ''}
                onChange={onSearchChange}
              />
            </div>
            <button
              type="button"
              className={styles.addList}
              onClick={() => setDialog(DIALOGS.CREATE_CAMPAIGN)}
            >
              Create Campaign
            </button>
          </div>
        </div>
        <Table
          data={campaigns}
          columns={columns}
          paginationProps={{
            currentPage: page,
            changePage,
            count: campaignsCount,
            rowsPerPage,
            changeRowsPerPage,
          }}
          dense
          isLoading={isLoading || isFetching}
          noDataValue={
            <TableError
              infoText="No campaigns Found."
              searchQuery={debouncedSearchQuery}
            />
          }
        />
      </div>
    </>
  );
};

export default Campaigns;
