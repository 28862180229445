import React, { useMemo } from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Tooltip from 'components/common/Tooltip';
import { useQuery } from '@tanstack/react-query';
import { getCompareInfDetails } from 'api/brand/search';
import { Avatar } from 'components/common/Avatar';
import { Select, TotalSection } from 'components/brand-dashboard/compare';
import InfluencerSearch from 'components/brand-dashboard/compare/InfluencerSearch';
import { useDialog } from 'stores/dialog';
import { useCreateCampaign } from 'stores/create-campaign';
import styles from 'assets/scss/pages/compare-influencer.module.scss';
import {
  coloredCrossIcon,
  // instagramWhiteOutlineIcon,
  // instagramImage,
  locationDownIcon,
  whiteAddIcon,
  instaSvg,
  facebookSvg,
  twitterSvg,
  ytIcon,
  blueTickIcon,
} from 'constants/images';
import routes from 'constants/routes';
import { DIALOGS } from 'constants/config/dialog-names';
import { formatNumber } from 'utils';
import { prefetchInfluencers } from './InfluencerList';

const PLATFORMS = [
  { label: 'Instagram', value: 'instagram', icon: instaSvg },
  { label: 'Youtube', value: 'youtube', icon: ytIcon },
  { label: 'Facebook', value: 'facebook', icon: facebookSvg },
  { label: 'Twitter', value: 'twitter', icon: twitterSvg },
];

function CompareInfluencers() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const setInfsForCampaign = useCreateCampaign((state) => state.setFormData);
  const setDialog = useDialog((state) => state.setDialog);

  const influencerIds = searchParams.get('ids')?.split(',');
  const selectedPlatform = searchParams.get('platform') || 'instagram';

  const { data: influencers } = useQuery({
    queryKey: [
      'compare-influencers',
      influencerIds?.toString(),
      selectedPlatform,
    ],
    queryFn: () =>
      getCompareInfDetails({
        influencerIds,
        platform_name: selectedPlatform,
      }),
    enabled: !!(influencerIds?.length > 0 && selectedPlatform),
    keepPreviousData: true,
    select: (res) => res?.data?.viewSocialMediaInfluencerDetailData,
  });

  console.log(influencers, 6363);

  const [totals, formattedInfluencers] = useMemo(() => {
    const totals = {
      totalAudience: 0,
      avgEngagementRate: 0,
      avgLikes: 0,
      avgComments: 0,
    };
    const formattedInfluencers = influencers?.map(
      ({
        id,
        profile_image: profileImage,
        full_name: fullName,
        influencer_instagram: instaData,
        influencer_youtube: youtubeData,
        influencer_facebook: facebookData,
        influencer_twitter: twitterData,
        is_registered: isRegistered,
        city: location,
      }) => {
        switch (selectedPlatform) {
          case 'instagram':
            totals.totalAudience += Number(instaData?.followers_count);
            totals.avgEngagementRate = totals.avgEngagementRate
              ? (totals.avgEngagementRate +
                  Number(instaData?.avg_enagagement_rate)) /
                2
              : Number(instaData?.avg_enagagement_rate);

            totals.avgLikes = totals?.avgLikes
              ? (totals.avgLikes + Number(instaData?.media_stat?.avg_likes)) / 2
              : Number(instaData?.media_stat?.avg_likes);

            totals.avgComments = totals.avgComments
              ? (totals.avgComments +
                  Number(instaData?.media_stat?.avg_comments)) /
                2
              : Number(instaData?.media_stat?.avg_comments);
            return {
              id,
              profileImage: profileImage || instaData?.profile_image_url,
              smincoScore: formatNumber(instaData?.sminco_score),
              fullName,
              handle: instaData?.username || '-',
              followers: formatNumber(instaData?.followers_count),
              avgLikes: formatNumber(instaData?.media_stat?.avg_likes),

              avgComments: formatNumber(instaData?.media_stat?.avg_comments),
              avgEngagementRate: formatNumber(instaData?.avg_enagagement_rate),
              likeCommentRatio: formatNumber(instaData?.likes_vs_comments),
              totalPosts: formatNumber(instaData?.media_stat?.total_media),
              brands: instaData?.brands?.length || 0,
              location: location || '-',
              // TODO: calculate cost based on rate card
              cost: '-',
            };
          case 'youtube':
            totals.totalAudience += Number(youtubeData?.subscriber_count);
            totals.avgEngagementRate = totals.avgEngagementRate
              ? (totals.avgEngagementRate +
                  Number(youtubeData?.avg_enagagement_rate)) /
                2
              : Number(youtubeData?.avg_enagagement_rate);

            totals.avgLikes = totals?.avgLikes
              ? (totals.avgLikes + Number(youtubeData?.avg_likes)) / 2
              : Number(youtubeData?.avg_likes);

            totals.avgComments = totals.avgComments
              ? (totals.avgComments + Number(youtubeData?.avg_comments)) / 2
              : Number(youtubeData?.avg_comments);
            return {
              id,
              profileImage: profileImage || youtubeData?.profile_image_url,
              smincoScore: formatNumber(youtubeData?.sminco_score),
              fullName,
              handle: youtubeData?.handle || '-',
              followers: formatNumber(youtubeData?.subscriber_count),
              avgLikes: formatNumber(youtubeData?.avg_likes),
              avgViews: formatNumber(youtubeData?.avg_views),
              avgComments: formatNumber(youtubeData?.avg_comments),
              avgEngagementRate: formatNumber(
                youtubeData?.avg_enagagement_rate
              ),
              likeCommentRatio: formatNumber(youtubeData?.likes_vs_comments),
              totalPosts: formatNumber(youtubeData?.total_videos),
              brands: youtubeData?.brands?.length || 0,
              location: location || '-',
              // TODO: calculate cost based on rate card
              cost: '-',
              isRegistered,
            };
          case 'twitter':
            totals.totalAudience += Number(twitterData?.followers_count);
            totals.avgEngagementRate = totals.avgEngagementRate
              ? (totals.avgEngagementRate +
                  Number(twitterData?.engagement_rate)) /
                2
              : Number(twitterData?.engagement_rate);

            totals.avgLikes = totals?.avgLikes
              ? (totals.avgLikes + Number(twitterData?.avg_likes)) / 2
              : Number(twitterData?.avg_likes);

            totals.avgComments = totals.avgComments
              ? (totals.avgComments + Number(twitterData?.avg_reply_count)) / 2
              : Number(twitterData?.avg_reply_count);
            return {
              id,
              profileImage: profileImage || twitterData?.profile_picture,
              smincoScore: formatNumber(twitterData?.sminco_score),
              fullName,
              handle: twitterData?.username || '-',
              followers: formatNumber(twitterData?.followers_count),
              avgLikes: formatNumber(twitterData?.avg_likes),
              avgViews: formatNumber(twitterData?.avg_impression_count),
              avgComments: formatNumber(twitterData?.avg_reply_count),
              avgEngagementRate: formatNumber(twitterData?.engagement_rate),
              likeCommentRatio: formatNumber(twitterData?.like_vs_comments),
              totalPosts: formatNumber(twitterData?.media_count),
              brands: twitterData?.brands?.length || 0,
              location: location || '-',
              // TODO: calculate cost based on rate card
              cost: '-',
              isRegistered,
            };
          case 'facebook':
            totals.totalAudience += Number(facebookData?.followers_count);
            totals.avgEngagementRate = totals.avgEngagementRate
              ? (totals.avgEngagementRate +
                  Number(facebookData?.enagagement_rate)) /
                2
              : Number(facebookData?.enagagement_rate);
            totals.avgLikes = totals?.avgLikes
              ? (totals.avgLikes + Number(facebookData?.avg_likes)) / 2
              : Number(facebookData?.avg_likes);

            totals.avgComments = totals.avgComments
              ? (totals.avgComments + Number(facebookData?.avg_comments)) / 2
              : Number(facebookData?.avg_comments);
            return {
              id,
              profileImage: profileImage || facebookData?.profile_picture,
              smincoScore: formatNumber(facebookData?.sminco_score),
              fullName,
              handle: facebookData?.name || '-',
              followers: formatNumber(facebookData?.followers_count),
              avgLikes: formatNumber(facebookData?.avg_likes),
              avgViews: '-',
              avgComments: formatNumber(facebookData?.avg_comments),
              avgEngagementRate: formatNumber(facebookData?.engagement_rate),
              likeCommentRatio: formatNumber(facebookData?.like_vs_comments),
              totalPosts: formatNumber(facebookData?.total_media),
              brands: facebookData?.brands?.length || 0,
              location: location || '-',
              // TODO: calculate cost based on rate card
              cost: '-',
              isRegistered,
            };
          default:
            return {};
        }
      }
    );

    return [totals, formattedInfluencers];
  }, [influencers, selectedPlatform]);

  const isNotRegisterdInfSelected = useMemo(
    () =>
      Boolean(influencers?.find((influencer) => !influencer?.is_registered)),
    [influencers]
  );

  const onAddToNewCampaign = () => {
    if (isNotRegisterdInfSelected) {
      toast.warning('Only verified influencers can be added in a campaign.', {
        position: 'top-center',
      });
      return;
    }
    const influencerDeliverables = [];

    influencers?.forEach((influencer) => {
      const platforms = [];

      if (influencer.is_instagram) platforms.push('instagram');
      if (influencer.is_facebook) platforms.push('facebook');
      if (influencer.is_twitter) platforms.push('twitter');
      if (influencer.is_youtube) platforms.push('youtube');

      influencerDeliverables.push({
        platforms,
        influencerId: influencer.id,
        influencerProfile: influencer.profile_image,
        influencerName: influencer.full_name,
        deliverables: [],
      });
    });

    setInfsForCampaign({ influencers: influencerDeliverables });
    setDialog(DIALOGS.CREATE_CAMPAIGN);
  };

  const onAddInfToExistingCampaign = () => {
    if (isNotRegisterdInfSelected) {
      toast.warning('Only verified influencers can be added in a campaign.', {
        position: 'top-center',
      });
      return;
    }
    setDialog(DIALOGS.ADD_INF_TO_EXISTING_CAMPAIGN, { influencers });
  };

  const changePlatform = (newValue) => {
    setSearchParams(
      (prev) => {
        prev.set('platform', newValue);
        return prev;
      },
      { preventScrollReset: true }
    );
  };

  const removeInfluencer = (id) => {
    const ids = influencerIds.filter((data) => data !== id);
    if (ids?.length > 0) {
      setSearchParams(
        (params) => {
          params.set('ids', ids || []);
          return params;
        },
        { preventScrollReset: true }
      );
    } else {
      navigate(routes.BRAND_SEARCH_INF);
    }
  };

  return (
    <section>
      <div className="dashboardSearch">
        <div className={styles.dashboardTop}>
          <div className={styles.innerDashboardContent}>
            <div className={styles.campignBreadcrumbs}>
              <Link
                to={routes.BRAND_SEARCH_INF_LIST}
                className={styles.navTxt}
                onMouseEnter={() => prefetchInfluencers()}
              >
                Influencer List
              </Link>
              <img
                className={styles.rightArrow}
                src={locationDownIcon}
                alt=""
              />
              <span className={styles.navSecndTxt}>Compare</span>
            </div>
          </div>
          <div className={styles.sectionHeader}>
            <div className={styles.secLeft}>
              <h3 className={styles.HeadingTitle}>Influencer Comparison</h3>
              <div className={styles.brandButtonsWrapper}>
                <Select
                  value={selectedPlatform}
                  onChange={changePlatform}
                  options={PLATFORMS}
                />

                {/* <button className={styles.brandBtn} type="button">
                  <img alt="" />
                  Reebok <img src={locationDownIcon} alt="" />
                </button> */}
              </div>
            </div>
            <div className={styles.secRight}>
              <button
                className={styles.addList}
                type="button"
                onClick={onAddToNewCampaign}
              >
                <img src={whiteAddIcon} alt="" /> Add To New Campaign (
                {influencerIds?.length})
              </button>
              <button
                className={styles.addList}
                type="button"
                onClick={onAddInfToExistingCampaign}
              >
                <img src={whiteAddIcon} alt="" /> Add To Existing Campaigns (
                {influencerIds?.length})
              </button>
            </div>
          </div>
          <InfluencerSearch />
        </div>
      </div>
      <div className={styles.compareContainer}>
        <TotalSection
          totalAccounts={influencerIds?.length}
          totalAudience={formatNumber(totals?.totalAudience)}
          avgEngagementRate={formatNumber(totals?.avgEngagementRate)}
          avgLikes={formatNumber(totals?.avgLikes)}
          avgComments={formatNumber(totals?.avgComments)}
          // totalPostPerformance={0}
          // totalEstimatedReach={0}
          // totalEngagement={0}
          // totalAudienceFit={0}
        />
        <div className={styles.compareWrapper}>
          <div className={styles.compareTable}>
            <ul>
              <h3 className={styles.detailheadingTxt}>Detail Comparison</h3>
              <li className={styles.subHeadingTitle}>
                <p className={styles.subTxt}>Influencer Name</p>
              </li>
              {/* <li className={styles.subHeadingTitle}>
                <p className={styles.subTxt}>
                  SMINCO Score <Tooltip label="Platform Score" />
                </p>
              </li> */}
            </ul>
            <ul>
              <h3
                className={styles.detailheadingTxt}
                style={{ color: '#718096' }}
              >
                Username
              </h3>
              <li className={clsx(styles.subHeadingTitle, styles.mx25)}>
                <p className={styles.subTxt}>Followers</p>
              </li>
              {selectedPlatform === 'youtube' && (
                <li className={clsx(styles.subHeadingTitle, styles.mx25)}>
                  <p className={styles.subTxt}>Avg Views</p>
                </li>
              )}
              <li className={clsx(styles.subHeadingTitle, styles.mx25)}>
                <p className={styles.subTxt}>Avg Likes</p>
              </li>
              <li className={clsx(styles.subHeadingTitle, styles.mx25)}>
                <p className={styles.subTxt}>Avg. Comments</p>
              </li>
              <li className={clsx(styles.subHeadingTitle, styles.mx25)}>
                <p className={styles.subTxt}>
                  ER <Tooltip label="Engagement Rate" />
                </p>
              </li>
              <li className={clsx(styles.subHeadingTitle, styles.mx25)}>
                <h3 className={styles.subTxt}>Like vs Comments</h3>
              </li>
              <li className={clsx(styles.subHeadingTitle, styles.mx25)}>
                <p className={styles.subTxt}>
                  {selectedPlatform === 'youtube'
                    ? 'Total Videos'
                    : 'Total Posts'}
                </p>
              </li>
            </ul>
            <ul>
              <h3
                className={clsx(styles.subHeadingTitle)}
                style={{ color: ' #718096' }}
              >
                Brands
              </h3>
            </ul>
            <ul>
              <li className={styles.subHeadingTitle}>
                <p className={styles.subTxt}>Location</p>
              </li>
              <li className={styles.subHeadingTitle}>
                <p className={styles.subTxt}>Cost</p>
              </li>
            </ul>
          </div>
          <div className={styles.influencerCompareWrapper}>
            {formattedInfluencers?.map(
              ({
                id,
                profileImage,
                smincoScore,
                fullName,
                handle,
                followers,
                avgLikes,
                avgViews,
                avgComments,
                avgEngagementRate,
                likeCommentRatio,
                totalPosts,
                brands,
                location,
                cost,
                isRegistered,
              }) => (
                <div
                  className={clsx(styles.compareTable, styles.compareUser)}
                  key={id}
                >
                  {console.log(brands, 414)}
                  <ul>
                    <div className={styles.userProfile}>
                      <div style={{ margin: 'auto' }}>
                        <Avatar src={profileImage} alt="" size="large" />
                      </div>
                      <button
                        type="submit"
                        onClick={() => removeInfluencer(id)}
                        className={styles.closeBtn}
                      >
                        <img src={coloredCrossIcon} alt="" />
                      </button>
                    </div>
                    <div className={styles.userSubList}>
                      <li className={styles.userList}>
                        <p className={styles.usersName}>
                          {fullName}{' '}
                          {isRegistered ? (
                            <img
                              src={blueTickIcon}
                              alt="verified"
                              style={{ display: 'inline-block' }}
                            />
                          ) : null}
                        </p>
                      </li>
                      {/* <li className={styles.userList}>
                        <p style={{ color: '#7C087F', fontWeight: '600' }}>
                          {formatNumber(smincoScore)}
                        </p>
                      </li> */}
                      <li className={styles.userList}>
                        <p>{handle}</p>
                      </li>
                      <li className={styles.userList}>
                        <p>{followers}</p>
                      </li>
                      {selectedPlatform === 'youtube' && (
                        <li className={styles.userList}>
                          <p>{avgViews}</p>
                        </li>
                      )}
                      <li className={styles.userList}>
                        <p>{avgLikes}</p>
                      </li>
                      <li className={styles.userList}>
                        <p>{avgComments}</p>
                      </li>
                      <li className={styles.userList}>
                        <p>{avgEngagementRate}</p>
                      </li>
                      <li className={styles.userList}>
                        <p>{likeCommentRatio}</p>
                      </li>
                      <li className={styles.userList}>
                        <p>{totalPosts}</p>
                      </li>
                      <li className={styles.userList}>{brands}</li>
                      <li className={styles.userList}>
                        <p>{location}</p>
                      </li>
                      <li className={styles.userList}>
                        <p>{cost}</p>
                      </li>
                      {/* {selectedPlatform === 'instagram' ? (
                        <li className={styles.userList}>
                          <p>
                            {compareData?.influencer_rate_card
                              ? `₹${
                                  compareData?.influencer_rate_card?.image +
                                  compareData?.influencer_rate_card?.reel +
                                  compareData?.influencer_rate_card?.stories
                                }`
                              : '-'}
                          </p>
                        </li>
                      ) : (
                        selectedPlatform === 'youtube' && (
                          <li className={styles.userList}>
                            <p>
                              {' '}
                              {compareData?.influencer_rate_card
                                ? `₹${
                                    compareData?.influencer_rate_card?.video +
                                    compareData?.influencer_rate_card?.shorts
                                  }`
                                : '-'}
                            </p>
                          </li>
                        )
                      )} */}
                    </div>
                  </ul>
                </div>
              )
            )}
            {/* ! Static html for refrence
            <div className={clsx(styles.compareTable, styles.compareUser)}>
              <ul>
                <div className={styles.userProfile}>
                  <img style={{ margin: 'auto' }} src={ellipseImage1} alt="" />
                  <img src={coloredCrossIcon} alt="" />
                </div>
                <div className={styles.userSubList}>
                  <li className={styles.userList}>
                    <p className={styles.usersName}>Annette Black</p>
                  </li>
                  <li className={styles.userList}>
                    <p style={{ color: '#7C087F', fontWeight: '600' }}>9.85</p>
                  </li>
                  <li className={styles.userList}>
                    <p>@AnnBlack</p>
                  </li>
                  <li className={styles.userList}>
                    <p>4.6m</p>
                  </li>
                  <li className={styles.userList}>
                    <p>19.8k</p>
                  </li>
                  <li className={styles.userList}>
                    <p>510k</p>
                  </li>
                  <li className={styles.userList}>
                    <p>4.6k</p>
                  </li>
                  <li className={styles.userList}>
                    <p>0.62%</p>
                  </li>
                  <li className={styles.userList}>
                    <p>Reebok</p>
                  </li>
                  <li className={styles.userList}>
                    <p>19.8k</p>
                  </li>
                  <li className={styles.userList}>
                    <p>510k</p>
                  </li>
                  <li className={styles.userList}>
                    <p>4.6k</p>
                  </li>
                  <li className={styles.userList}>
                    <p>India</p>
                  </li>
                  <li className={styles.userList}>
                    <p>₹5,000</p>
                  </li>
                </div>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CompareInfluencers;
