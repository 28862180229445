const errorMessages = {
  INVALID_EMAIL: 'Please enter a valid email address.',
  EMAIL_REQ: 'Email cannot be empty.',
  INVALID_OTP: 'Please enter a valid OTP.',
  PASSWORD_REQ: 'Please enter a valid password.',
  INVALID_PASSWORD:
    'Password must contain 8 or more characters with at least one of each i.e alteast one uppercase, alteast one lowercase, alteast one number and alteast one special character. ',
  CONFIRM_PASS_REQ: 'Please re-confirm the password. ',
  PASS_MATCH: 'Please enter the passwords again. Password and Confirm Password must match.',
  INVALID_AGENCY_NAME: 'Agency Name must be of atleast two words OR minimum four characters long.',
  INVALID_MOBILE: 'Please enter a valid mobile number.',
  MOBILE_REQ: 'Mobile number cannot be empty.',
  INVALID_MANAGER_EMAIL: 'Please enter a valid manager email address.',
  MANAGER_EMAIL_REQ: 'Manager Email cannot be empty.',
  INVALID_MANAGER_MOBILE: 'Please enter a valid manager mobile number.',
  MANAGER_MOBILE_REQ: 'Manager mobile number cannot be empty.',
  NAME_REQ: 'Name cannot be empty.',
  FIRST_NAME_REQ: 'First name cannot be empty.',
  LAST_NAME_REQ: 'Last name cannot be empty.',
  HOUSE_NO_REQ: 'Please enter a valid House No.',
  CITY_REQ: 'City cannot be empty.',
  STATE_REQ: 'State cannot be empty.',
  INVALID_PINCODE: 'Please enter valid pincode.',
  PINCODE_REQ: 'Pincode cannot be empty.',
  LANDMARK_REQ: 'Landmark cannot be empty.',
  DISTRICT_REQ: 'District cannot be empty.',
  ADDRESS_TYPE_REQ: 'Address Type cannot be empty.',
  ADDRESS_LINE_REQ: 'Address Line 1 cannot be empty.',
  CONTACT_PERSON_NAME_REQ: 'Contact Person Name is required.',
  CONTACT_PERSON_MOBILE_REQ: 'Contact Person Mobile Number is required.',
  RO_CONTACT_PERSON_NAME_REQ: 'RO Contact Person Name is required.',
  RO_CONTACT_PERSON_MOBILE_REQ: 'RO Contact Person Mobile Number is required.',
  TEAM_SEGMENT_REQ: 'Team Segment is required.',
  RC_ACC_MANAGER_REQ: 'RC Account Manager is required.',
  INVALID_EMAIL_OR_MOBILE: 'Please enter valid mobile number or email address.',
  LANGUAGES_REQ: 'Languages is required.',
  DOB_REQ: 'Date of birth is required.',
  PROFILE_REQ: 'Profile Image is required.',
  CONSENT_REQ: 'Please accept Terms & Conditions and Privacy Policy to continue.',
  GST_REQ: 'GST certificate is required.',
  GST_NUMBER_REQ: 'GST number is required.',
  INVALID_GST_NO: 'Please enter a valid GST number.',
  GST_DOC_REQ: 'Please upload GST document.',
  PAN_NO_REQ: 'Pan number is required.',
  INVALID_PAN_NO: 'Please enter a valid PAN number.',
  PAN_DOC_REQ: 'Please upload PAN card.',
  INVALID_TIN_NO: 'Please enter a valid TIN number.',
  ONLY_ALPHABETS: 'Only alphabets are allowed for this field.',
  ONLY_PDF: 'Only pdf files are allowed.',
  UNABLE_TO_PROCESS: 'We are unable to process your request at the moment. Please consider trying again.',
  LIST_NAME_REQ: 'List name cannot be empty.',
  CATEGORY_REQ: 'Category is required.',
  PREFRENCE_REQ: 'Preferance is required.',
  CAMPAIGN_NAME_REQ: 'Campaign name is required.',
  BRAND_NAME_REQ: 'Brand name is required.',
  START_DATE_TIME_REQ: 'Start date time is required.',
  INVALID_END_DATE: "End date can't be before start date.",
  INVALID_BUDGET: 'Expected budget should be a positive number.',
  BUDGET_REQ: 'Expected budget is required.',
  PAYMENT_TERMS_REQ: 'Please select a payment term.',
  DESCRIPTION_REQ: 'Description is required.',
  TAGS_REQ: 'Atleast one tag is required.',
  TAG_NAME_REQ: 'Please enter a valid tag name.',
  DUPLICATE_TAG_NAME: 'A tag with this name already exists.',
  CAMPAIGN_MEDIA_REQ: 'Please select image or video file.',
  CAMPAIGN_REGION_REQ: 'Please select atleast one region.',
  CAMPAIGN_GENDER_REQ: 'Please select atleast one gender.',
  CAMPAIGN_PLATFORM_REQ: 'Please select atleast one platform.',
  INFLUENCER_REQ: 'Please add atleast one influencer.',
  DELIVERABLE_REQ: 'Please add atleast one deliverable or remove this influencer.',
  INVALID_AGE_GROUP: 'Please enter a valid age range.',
  CMAPAIGN_REQ: 'Please select a campaign.',
  UNABLE_TO_ADD_INFLUENCERS: 'Some influencers are not available for selected campaign platforms.',
  TITLE_REQ: 'Title is required.',
  POST_LINK_REQ: 'Post link is required.',

  BANK_TYPE_REQ: 'Please select bank.',
  CARD_TYPE_REQ: 'Please select card type.',
  CARD_NUM_REQ: 'Please enter card number.',
  INVALID_CARD_NUM: 'Please enter valid card number.',
  CARD_EXP_REQ: 'Please enter expiry date.',
  INVALID_CARD_EXP: 'Please enter valid expiry date in MM/YYYY format.',
  INVALID_CARD_EXP_DATE: 'Past dates are not allowed.',
  CARD_CVV_REQ: 'Please enter a valid cvv code printed on the back of your card.',
  INSTA_IMAGE: '.JPEG , max size 8 MB , min width 320,max 1440',
  INSTA_VIDEO: '.mp4,.mov, max 14 MB,frame rate: 23-60 FPS',
  INSTA_REEL: '.mp4,.mov, max 14 MB,frame rate: 23-60 FPS',
  FB_POST: '.jpeg, .png, .mp4, .mov, width 1200px to 4000px',
  INSTA_STORY:
    'JPEG,MP4,mov, image:max 10 MB,video:max 10 MB, aspect ratio: We recommended 9:16 to avoid cropping or blank space',
  YOUTUBE_VIDEO: '.mp4,.mov',
  YOUTUBE_SHORTS: '.mp4,.mov',
  SCHEDULE_DATE_TIME_REQ: 'Schedule date time is required.',
  INVALID_SCHEDULE_DATE_TIME: 'Please choose future date.',

  INCOMPATIBLE_BROWSER: 'We do not support you browser version. Please update to latest version.',

  REQUIRED: 'This field is required.',
};

Object.freeze(errorMessages);

export default errorMessages;
