import React from 'react';
import { Link } from 'react-router-dom';
import { useInfulencerOnboardingForm } from 'stores/onboarding';

import Footer from 'components/onboarding/Footer';
import InfluencerTypeSelectForm from 'components/onboarding/InfluencerTypeSelectForm';
import InfluencerProfileForm from 'components/onboarding/InfluencerProfileForm';

import { logoImageBlackText } from 'constants/images';
import styles from 'assets/scss/pages/onboarding.module.scss';
import routes from 'constants/routes';

function SignUp() {
  const formStep = useInfulencerOnboardingForm((state) => state.formStep);

  return (
    <div className={styles.signupInnerContent}>
      <div className={styles.signupItems}>
        <div>
          <Link to={routes.HOME}>
            <img src={logoImageBlackText} alt="" width={140} />
          </Link>
        </div>

        {/* ========= Step 1 select type ========== */}
        {formStep === 1 ? <InfluencerTypeSelectForm /> : null}

        {/* ============= Stpe 3 email, mobile and profile input  ======== */}
        {formStep === 2 ? <InfluencerProfileForm /> : null}
      </div>

      <Footer type="influencer" />
    </div>
  );
}

export default SignUp;
