import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Player } from '@lottiefiles/react-lottie-player';
import ProfileStatus from 'components/inf-dashboard/ProfileStatus';
import { getProfile } from 'api/influencer/profile';
import { useDialog } from 'stores/dialog';
import { DIALOGS } from 'constants/config/dialog-names';
import routes from 'constants/routes';
import styles from 'assets/scss/pages/dashboard.module.scss';
import { waveIcon, editIcon } from 'constants/images';
import instagramAnimatedIcon from 'assets/lottie/instagram-icon.json';
import facebookAnimatedIcon from 'assets/lottie/facebook-icon.json';
import youtubeAnimatedIcon from 'assets/lottie/youtube-icon.json';
import twitterAnimatedIcon from 'assets/lottie/twitter-icon.json';
import SocialProfileStatus from './SocialProfileStatus';

const DashboardProfileSeaction = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['influencer-profile'],
    queryFn: () => getProfile(),
  });
  const setDialog = useDialog((state) => state.setDialog);

  const navigate = useNavigate();

  const onEditProfile = () => {
    setDialog(DIALOGS.INF_EDIT_PROFILE);
  };
  const onEditAddress = () => {
    setDialog(DIALOGS.INF_EDIT_ADDRESS);
  };
  const onEditLanguages = () => {
    setDialog(DIALOGS.INF_EDIT_PROFILE_LANGUAGES);
  };
  const onAddMoreSocialAccounts = () => {
    navigate(routes.INF_SIGNUP_ADD_SOCIAL);
  };

  const {
    full_name: name,
    email,
    mobile,
    // manager_email: managerEmail,
    // manager_mobile: managerMobile,
    addresses,
    profile_status: profileStatus,
    profile_image: profile,
    language: languages,
    role,
    influencer_categories: categories,
    is_facebook: isFacebookVerified,
    is_instagram: isInstaVerified,
    is_twitter: isTwitterVerified,
    is_youtube: isYoutubeVerified,
    influencer_instagram: instagramData,
    influencer_youtube: youtubeData,
    influencer_facebook: facebookData,
    influencer_twitter: twitterData,
  } = data || {};

  const {
    pincode,
    address_line_1: addressLine1,
    address_line_2: addressLine2,
    landmark,
    state,
    city,
    address_type: addressType,
  } = addresses?.[0] || {};

  const showAddMoreSocial = !(
    isFacebookVerified &&
    isInstaVerified &&
    isTwitterVerified &&
    isYoutubeVerified
  );

  const instgramHandle = instagramData?.username;
  const youtubeHandle = youtubeData?.handle;

  if (isLoading) {
    return null;
  }

  if (profileStatus === 'verified') {
    return (
      <h4 className={styles.useGreeting}>
        Welcome back, {name}! <img src={waveIcon} alt="" />
      </h4>
    );
  }

  return (
    <div className={styles.personlInfoCont}>
      <span className={styles.sideBar} />
      <div className={styles.innrBlock1}>
        <div className={styles.subblock1}>
          <h3 className={styles.perTxt}>Personal Information</h3>
          <button type="button" onClick={onEditProfile}>
            <img className={styles.editIcon} src={editIcon} alt="" />
          </button>
        </div>
        <ProfileStatus profileStatus={profileStatus} />
      </div>
      <div className={styles.innrBlock2}>
        <div className="profileForm">
          <div className={styles.detailsection}>
            <div className={styles.profileImg}>
              <img src={profile} className={styles.profilePhoto} alt="" />
            </div>
            <div className={styles.detailInner}>
              <label className={styles.labelTxt}>Full Name</label>
              <p className={styles.subTxt}>
                {name} <span className={styles.userStatus}>{role}</span>
              </p>
            </div>
            <div className={styles.detailInner}>
              <label className={styles.labelTxt}>Email Id</label>
              <p className={styles.subTxt}>{email}</p>
            </div>
            <div className={styles.detailInner}>
              <label className={styles.labelTxt}>Mobile Number</label>
              <p className={styles.subTxt}>+91 {mobile}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.innrBlock2}>
        <div className={styles.profileForm}>
          <div className={styles.subblock1}>
            <h3 className={styles.perTxt}>Category</h3>
            {/* <img className={styles.editIcon} src={editIcon} alt="" /> */}
          </div>
          <div className="detailsection">
            <div className={styles.detailInner}>
              <div className={styles.categoryBlock}>
                {categories?.map(({ category }) => (
                  <span className={styles.categoryText} key={category?.id}>
                    {category?.category_name}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="profileForm">
          <div className={styles.subblock1}>
            <h3 className={styles.perTxt}>Languages</h3>
            <button type="button" onClick={onEditLanguages}>
              <img className={styles.editIcon} src={editIcon} alt="" />
            </button>
          </div>
          <div className="detailsection">
            <div className={styles.detailInner}>
              <div className={styles.categoryBlock}>
                {languages?.map((language) => (
                  <span className={styles.categoryText} key={language}>
                    {language}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.innrBlock2}>
        <div className={styles.profileForm}>
          <div className={styles.subblock1}>
            <h3 className={styles.perTxt}>Addresses</h3>
            <button type="button" onClick={onEditAddress}>
              <img className={styles.editIcon} src={editIcon} alt="" />
            </button>
          </div>
          <div className="detailsection">
            <div className={styles.detailInner}>
              <p className={styles.subTxt} style={{ maxWidth: 350 }}>
                {addressLine1 && `${addressLine1}, `}
                {addressLine2 && `${addressLine2}, `}
                {landmark && `${landmark}, `}
                {city && `${city}, `}
                {state} - {pincode}
              </p>
              <span
                style={{ marginLeft: '0px', marginTop: '9px' }}
                className={styles.userStatus}
              >
                {addressType}
              </span>
            </div>
          </div>
        </div>
        <div className="profileForm">
          <div className={styles.subblock1}>
            <h3 className={styles.perTxt}>Social Media Accounts</h3>
            {showAddMoreSocial && (
              <button type="button" onClick={onAddMoreSocialAccounts}>
                <img className={styles.editIcon} src={editIcon} alt="" />
              </button>
            )}
          </div>

          <div className={styles.socialPlatformWrapper}>
            {isInstaVerified && (
              <div className={styles.socialPlatform}>
                <div className={styles.socialImage}>
                  <Player loop autoplay src={instagramAnimatedIcon} />
                </div>
                <h3 className={styles.socialTxt}>
                  {instgramHandle}
                  <br />
                  <SocialProfileStatus
                    isVerified={Boolean(instagramData.python_updated_at)}
                  />
                </h3>
              </div>
            )}
            {isFacebookVerified && (
              <div className={styles.socialPlatform}>
                <div className={styles.socialImage}>
                  <Player loop autoplay src={facebookAnimatedIcon} />
                </div>
                <h3 className={styles.socialTxt}>
                  {facebookData?.name || name}
                  <br />
                  <SocialProfileStatus
                    isVerified={Boolean(facebookData.python_updated_at)}
                  />
                </h3>
              </div>
            )}
            {isTwitterVerified && (
              <div className={styles.socialPlatform}>
                <div className={styles.socialImage}>
                  <Player loop autoplay src={twitterAnimatedIcon} />
                </div>
                <h3 className={styles.socialTxt}>
                  {twitterData?.username}
                  <br />
                  <SocialProfileStatus
                    isVerified={Boolean(twitterData?.python_updated_at)}
                  />
                </h3>
              </div>
            )}
            {isYoutubeVerified && (
              <div className={styles.socialPlatform}>
                <div className={styles.socialImage}>
                  <Player
                    loop
                    autoplay
                    src={youtubeAnimatedIcon}
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      transform: 'scale(2.4)',
                    }}
                  />
                </div>
                <h3 className={styles.socialTxt}>
                  {youtubeHandle || name} <br />
                  <br />
                  <SocialProfileStatus
                    isVerified={Boolean(youtubeData?.python_updated_at)}
                  />
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardProfileSeaction;
