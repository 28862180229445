import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { linkCamapignDeliverable } from 'api/influencer/campaign';
import { queryClient } from 'App';
import { InputWrapper, Input } from 'components/common/campaign';
import Button from 'components/common/Button';
import styles from 'assets/scss/pages/popup.module.scss';
import { campaignPopupImg, linkIcon } from 'constants/images';
import { INF_LINK_CAMPAIGN_DELIVERABLE_SCHEMA } from 'constants/schema';

const defaultValues = {
  title: '',
  link: '',
};

const LinkImportForm = ({
  close,
  platform,
  campaignId,
  // campaignTags,
  deliverableType,
}) => {
  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(INF_LINK_CAMPAIGN_DELIVERABLE_SCHEMA),
    defaultValues,
  });

  const onClose = () => {
    reset();
    close();
  };
  const uploadDeliverableMutation = useMutation({
    mutationFn: ({ title, link, campaignId, platform, deliverableType }) => {
      const payload = {
        title,
        custom_link: link,
        platform,
        media: deliverableType,
      };

      return linkCamapignDeliverable({
        campaignId,
        body: payload,
      });
    },
    onSuccess: (res) => {
      toast.success(res?.message, {
        position: 'top-center',
      });
      onClose();
    },
    onSettled: (res, error, { campaignId, platform }) => {
      queryClient.invalidateQueries({
        queryKey: ['influencer-campaign-deliverables', campaignId, platform],
      });
      queryClient.invalidateQueries({
        queryKey: ['influencer-campaign-details', campaignId],
      });
    },
  });

  const onSubmit = (data) => {
    uploadDeliverableMutation.mutate({
      ...data,
      campaignId,
      platform,
      deliverableType,
    });
  };
  return (
    <>
      <div className={styles.secBody} style={{ height: 'calc(100vh - 299px)' }}>
        <form id="link-deliverable-form" onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper icon={campaignPopupImg}>
            <Input
              label="Title"
              maxLength="80"
              error={errors?.title}
              {...register('title')}
            />
          </InputWrapper>
          <InputWrapper icon={linkIcon}>
            <Input
              type="url"
              label="Post Link"
              error={errors?.link}
              {...register('link')}
            />
          </InputWrapper>
        </form>
      </div>
      <div className={styles.createInfFooter}>
        <div className={clsx(styles.campaignBtns, styles.nxtWrap)}>
          <Button
            type="submit"
            style={{ width: 166 }}
            className={styles.updateBtn}
            form="link-deliverable-form"
            size="large"
            isLoading={uploadDeliverableMutation.isLoading}
          >
            Publish
          </Button>
        </div>
      </div>
    </>
  );
};

LinkImportForm.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  platform: PropTypes.oneOf(['instagram', 'youtube', 'twitter', 'facebook']),
  deliverableType: PropTypes.string,
  campaignTags: PropTypes.array,
  campaignId: PropTypes.string,
};

export default LinkImportForm;
