import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { addTag } from 'api/brand/campaign';
import { Dialog } from 'components/common/dialog';
import ErrorMessage from 'components/common/form/ErrorMessage';
import { Input } from 'components/common/campaign';
import errorMessages from 'constants/config/error-messagess';
import { queryClient } from 'App';
import styles from 'assets/scss/pages/popup.module.scss';

const CreateTagDialog = ({ render }) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { tagName: '' } });

  const addTagMutation = useMutation({
    mutationFn: ({ tagName }) => addTag({ hashtag_name: tagName }),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ['brand-campaign-tags-filter'],
      });
      toast.success(res?.message, {
        position: 'top-center',
      });
      onClose();
    },
  });

  const onClose = () => {
    setIsOpen(false);
    reset();
  };
  const onOpen = () => setIsOpen(true);

  const onSubmit = (data) => {
    addTagMutation.mutate(data);
  };

  return (
    <>
      {render ? render({ open: onOpen }) : null}
      <Dialog
        title="Add New Tag"
        open={isOpen}
        close={onClose}
        size="526px"
        fullWidth
      >
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}
          id="add-tag-form"
        >
          <div
            className={styles.createInfFooter}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <Input
              {...register('tagName', {
                required: errorMessages.TAG_NAME_REQ,
              })}
            />
            <ErrorMessage error={errors?.tagName} />
          </div>
          <div className={styles.createInfFooter}>
            <div className={clsx(styles.campaignBtns, styles.nxtWrap)}>
              <button
                type="button"
                onClick={onClose}
                className={styles.prevBtn}
              >
                Back
              </button>
              <button
                type="submit"
                style={{ width: 166 }}
                className={styles.updateBtn}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

CreateTagDialog.propTypes = {
  render: PropTypes.func,
};

export default CreateTagDialog;
