import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import printJS from 'print-js';
import { getCampaignAnalytics } from 'api/influencer/campaign';
import { CampaignReport } from 'components/campaign-report';
import { FullPageLoader } from 'components/common/Loader';
import errorMessages from 'constants/config/error-messagess';
import { ReactComponent as DownloadIcon } from 'assets/images/download.svg';
import useFormatCampaignAnalyticsData from 'hooks/useFormatCampaignAnalyticsData';

const DownloadReport = ({ campaignId }) => {
  const [reportData, setReportData] = useState();
  const [renderExport, setRenderExport] = useState(false);

  const exportReportMutation = useMutation({
    mutationFn: () =>
      getCampaignAnalytics({
        campaignId,
        media_type: 'all',
        data_filter: 'daily',
      }),
    onSuccess: (res) => {
      setReportData(res?.data);
      setRenderExport(true);
      setTimeout(() => {
        printJS({
          printable: 'section-to-print',
          type: 'html',
          scanStyles: false,
          documentTitle: `Campaign Report`,
          css: ['/campaign-report.css', '/normalize.css'],
          onError: (error) => console.log(error),
          onIncompatibleBrowser: () =>
            toast.error(errorMessages.INCOMPATIBLE_BROWSER),
          onLoadingEnd: () => setRenderExport(false),
        });
      }, 500);
    },
  });

  const {
    totalPosts,
    totalViews,
    totalLikes,
    totalComments,
    engagementRate,
    totalInfluencers,
    audienceEngRate,
    influencerPerformance,
    campaignPerformance,
    hashtags,
    posts,
  } = useFormatCampaignAnalyticsData({
    trackingData: reportData?.trackingData,
  });

  const showLoader = exportReportMutation.isLoading || renderExport;

  return (
    <div>
      <button type="button" onClick={exportReportMutation.mutate}>
        <DownloadIcon />
      </button>
      <FullPageLoader
        show={showLoader}
        loadingText="Exporting report, please wait..."
      />
      <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
        {renderExport && (
          <div className="sectionForExport">
            <CampaignReport
              isExporting
              varient="influencer"
              insights={{
                totalPosts,
                totalViews,
                totalLikes,
                totalComments,
                engagementRate,
                totalInfluencers,
              }}
              postingsData={{
                data: reportData?.insightsData || [],
                hashtags,
                filter: 'daily',
                changeFilter: () => null,
              }}
              audienceEngagementRateData={audienceEngRate}
              influencerPerformanceData={influencerPerformance}
              campaignPerformanceData={campaignPerformance}
              posts={posts}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DownloadReport;
