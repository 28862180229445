import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import styles from './default.module.scss';

const BrandDefaultLayout = () => (
  <div>
    <Header varient="brand" />
    <div className={styles.main}>
      <Outlet />
    </div>
    <Footer varient="brand" />
  </div>
);

export default BrandDefaultLayout;
