import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Listbox, Combobox } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { useQuery } from '@tanstack/react-query';
import { getInfluencersUpdated } from 'api/brand/search';
import { Avatar } from 'components/common/Avatar';
import { arrowDownIcon } from 'constants/images';
import styles from 'assets/scss/pages/dashboard.module.scss';
import { PLATFORMS } from 'constants/config/filters';
import useDebounce from 'hooks/useDebounce';
import routes from 'constants/routes';
import { formatNumber } from 'utils';

const page = 1;
const rowsPerPage = 15;

const DashboardSearchSection = () => {
  const [query, setQuery] = useState('');
  const [platform, setPlatform] = useState('instagram');
  const debouncedSearchQuery = useDebounce(query);

  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: [
      'brand-influencer-list',
      page,
      rowsPerPage,
      debouncedSearchQuery,
      platform,
    ],
    queryFn: () =>
      getInfluencersUpdated({
        pageNo: page,
        pageSize: rowsPerPage,
        search: debouncedSearchQuery || undefined,
        is_youtube: platform ? platform === 'youtube' : undefined,
        is_instagram: platform ? platform === 'instagram' : undefined,
        is_facebook: platform ? platform === 'facebook' : undefined,
        is_twitter: platform ? platform === 'twitter' : undefined,
      }),
    keepPreviousData: true,
  });

  const onInfSelect = (selectedInfluencer) => {
    navigate(`${routes.BRAND_SEARCH_INF}/${selectedInfluencer}`);
  };

  const influencers = useMemo(
    () =>
      data?.data?.influencers?.rows?.map((influencer) => {
        const formattedInf = {
          id: influencer.id,
          profileImage: influencer.profile_image,
          fullName: influencer.full_name,
        };

        const instagramUsername = influencer.influencer_instagram?.username;
        const instagramFollowers =
          influencer.influencer_instagram?.followers_count;

        const facebookUsername = influencer.influencer_facebook?.username;
        const facebookFollowers =
          influencer.influencer_facebook?.followers_count;

        const youtubeUsername = influencer.influencer_youtube?.handle;
        const youtubeFollowers =
          influencer.influencer_youtube?.subscriber_count;

        const twitterUsername = influencer.influencer_twitter?.username;
        const twitterFollowers = influencer.influencer_twitter?.followers_count;

        switch (platform) {
          case 'instagram':
            formattedInf.handle = instagramUsername || undefined;
            formattedInf.follwers = formatNumber(instagramFollowers);
            break;
          case 'facebook':
            formattedInf.handle = facebookUsername || undefined;
            formattedInf.follwers = formatNumber(facebookFollowers);
            break;
          case 'youtube':
            formattedInf.handle = youtubeUsername || undefined;
            formattedInf.follwers = formatNumber(youtubeFollowers);
            break;
          case 'twitter':
            formattedInf.handle = twitterUsername || undefined;
            formattedInf.follwers = formatNumber(twitterFollowers);
            break;

          default:
            break;
        }

        return formattedInf;
      }),
    [data, platform]
  );

  return (
    <div className={styles.searchBox}>
      <div className={styles.inputGroup}>
        <Combobox
          value=""
          onChange={onInfSelect}
          className={styles.tagInput}
          as="div"
        >
          <Float placement="bottom" flip offset={2} adaptiveWidth portal>
            <div className="tagOuter">
              <Combobox.Input
                type="text"
                className={styles.searchbarInput}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Type To Search Influencers Using Their Names, Hashtags, Profiles, #Anything!"
              />
            </div>
            <Combobox.Options className={styles.selectOptions}>
              {influencers?.length ? (
                influencers.map(
                  ({ id, fullName, profileImage, handle, follwers }) => (
                    <Combobox.Option
                      key={id}
                      value={id}
                      className={({ selected, active }) =>
                        clsx({
                          [styles.selectOption]: true,
                          [styles.active]: selected || active,
                        })
                      }
                    >
                      <Avatar size="small" src={profileImage} />
                      <span>{fullName}</span>
                      {handle ? (
                        <span className={styles.subText}>{handle}</span>
                      ) : null}
                      <span className={styles.subText}>
                        {follwers} Followers
                      </span>
                    </Combobox.Option>
                  )
                )
              ) : (
                <Combobox.Option disabled className={styles.selectOption}>
                  No influencers found.
                </Combobox.Option>
              )}
            </Combobox.Options>
          </Float>
        </Combobox>
        <Select options={PLATFORMS} value={platform} onChange={setPlatform} />
      </div>
    </div>
  );
};

const Select = ({ value, onChange, options = [] }) => {
  const selected = useMemo(
    () => options?.find((option) => value === option.value) || '',
    [value, options]
  );

  return (
    <Listbox value={selected} onChange={onChange}>
      <Float placement="bottom" flip offset={2} adaptiveWidth portal>
        <Listbox.Button className={styles.dropdownBtn}>
          <span className={styles.selectedItem}>
            <img
              src={selected?.icon}
              alt=""
              className={styles.itemImage}
              height="16"
              width="16"
            />
            <span className={styles.dropdownItemName}>{selected?.label}</span>
          </span>
          <img src={arrowDownIcon} alt="" />
        </Listbox.Button>
        <Listbox.Options className={styles.selectOptions}>
          {options.map((option) => (
            <Listbox.Option
              key={option.label}
              value={option.value}
              disabled={option.disabled}
              className={({ selected, active }) =>
                clsx({
                  [styles.selectOption]: true,
                  [styles.active]: selected || active,
                })
              }
            >
              {option.label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Float>
    </Listbox>
  );
};

Select.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

export default DashboardSearchSection;
