import React from 'react';
import { CaseStudyCard } from 'components/home/CaseStudies';
import styles from 'assets/scss/pages/static-content.module.scss';
import { dotImage } from 'constants/images';
import { caseStudies } from 'constants/config/case-studies';

const CaseStudies = () => (
  <section>
    <div className={styles.headingContainer}>
      <h2 className={styles.headingTxt}>Case Studies</h2>
    </div>
    <div className={styles.container} style={{ position: 'relative' }}>
      <div className={styles.dotBg}>
        <img className="dotImg" src={dotImage} alt="" />
      </div>
      <div className={styles.dotBg2}>
        <img className="dotImg" src={dotImage} alt="" />
      </div>
      <div className={styles.caseStudiesWrapper}>
        {caseStudies.map(({ id, ...rest }) => (
          <CaseStudyCard key={id} id={id} {...rest} />
        ))}
      </div>
    </div>
  </section>
);

export default CaseStudies;
