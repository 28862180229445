import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { format } from 'date-fns';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Player } from '@lottiefiles/react-lottie-player';
import { getProfile } from 'api/influencer/profile';
import { useInfluencerAuth } from 'stores/auth/influencer';
import { queryClient } from 'App';
import { AlertDialog } from 'components/common/dialog';
import styles from 'assets/scss/pages/profile-dashboard.module.scss';
import instagramAnimatedIcon from 'assets/lottie/instagram-icon.json';
import facebookAnimatedIcon from 'assets/lottie/facebook-icon.json';
import youtubeAnimatedIcon from 'assets/lottie/youtube-icon.json';
import twitterAnimatedIcon from 'assets/lottie/twitter-icon.json';
import { editIcon, profileLogout, contract } from 'constants/images';
import { useDialog } from 'stores/dialog';
import { DIALOGS } from 'constants/config/dialog-names';
import { INF_PROFILE_STATUS } from 'constants/config/users';
import routes from 'constants/routes';
import ProfileStatus from 'components/inf-dashboard/ProfileStatus';
import { saveFile } from 'utils';
import { downloadContract } from 'api/influencer/campaign';
import { uinqueProfileCategories } from 'pages/brand/dashboard/search/influencer-profile/formatData/sortData';

const NavItem = ({ children, ...props }) => (
  <NavLink
    className={({ isActive }) =>
      clsx({
        [styles.listTerm]: true,
        [styles.activeList]: isActive,
      })
    }
    {...props}
  >
    {children}
  </NavLink>
);

NavItem.propTypes = {
  children: PropTypes.node,
};

const Profile = () => {
  const reset = useInfluencerAuth((state) => state.reset);

  const { data: influencer } = useQuery({
    queryKey: ['influencer-profile'],
    queryFn: () => getProfile(),
  });

  const onLogout = () => {
    reset();
    queryClient.clear();
  };

  const downloadContractPdf = useMutation({
    mutationFn: () => downloadContract(),
    onSuccess: (res) => {
      saveFile(
        res,
        'application/pdf',
        `contract${format(new Date(), 'dd_MMM_yyyy_hh_mm_aaa')}`
      );
    },
    onError: async (error) => {
      if (error.response?.data) {
        const errorResponse = await JSON.parse(
          await error.response.data.text()
        );
        toast.error(errorResponse.message);
      }
    },
  });

  const downloadContractHandler = () => {
    downloadContractPdf.mutate();
  };

  const isContractSigned = Boolean(influencer?.signed_url);

  return (
    <section>
      <div className={styles.profileDashboard}>
        <ProfileDetailsSidebar influencerData={influencer} />
        <div className={styles.profileSettingcolumn}>
          <div className={styles.settingborder}>
            <nav className={styles.settingList}>
              <NavItem to="" end>
                Setting
              </NavItem>
              <NavItem to="rate-card">Manage Rate Card</NavItem>
            </nav>
            <div className={(styles.logoutBtn, styles.rightcontainer)}>
              {influencer?.status === INF_PROFILE_STATUS.VERIFIED && (
                <button
                  type="button"
                  className={styles.contractBtn}
                  onClick={downloadContractHandler}
                  disabled={downloadContractPdf.isLoading}
                >
                  <img src={contract} alt="" />
                  Signup Contract {isContractSigned ? '' : '(Unsigned)'}
                </button>
              )}

              <AlertDialog
                render={({ open }) => (
                  <button
                    onClick={open}
                    type="button"
                    className={styles.profileLogoutBtn}
                  >
                    <img src={profileLogout} alt="" />
                    Logout
                  </button>
                )}
                postion="top-center"
                title="Logout"
                description="Are you sure you want to logout?"
                agreeBtnText="Logout"
                disagreeBtnText="Close"
                onAgree={onLogout}
              />
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </section>
  );
};

const ProfileDetailsSidebar = ({ influencerData }) => {
  const setDialog = useDialog((state) => state.setDialog);
  const navigate = useNavigate();

  const onEditProfile = () => {
    setDialog(DIALOGS.INF_EDIT_PROFILE);
  };
  const onEditGstDoc = () => {
    setDialog(DIALOGS.INF_EDIT_GST_DOC);
  };
  const onEditPanDoc = () => {
    setDialog(DIALOGS.INF_EDIT_PAN_DOC);
  };
  const onEditAddress = () => {
    setDialog(DIALOGS.INF_EDIT_ADDRESS);
  };
  const onEditLanguages = () => {
    setDialog(DIALOGS.INF_EDIT_PROFILE_LANGUAGES);
  };
  const onAddMoreSocialAccounts = () => {
    navigate(routes.INF_SIGNUP_ADD_SOCIAL);
  };

  const {
    full_name: name,
    email,
    mobile,
    pan_no: panNo,
    pan_card: panCard,
    // manager_email: managerEmail,
    // manager_mobile: managerMobile,
    addresses,
    profile_status: profileStatus,
    gst_no: gstNo,
    profile_image: profile,
    language: languages,
    upload_gst: gstDoc,
    role,
    influencer_categories: categories,
    is_facebook: isFacebookVerified,
    is_instagram: isInstaVerified,
    is_twitter: isTwitterVerified,
    is_youtube: isYoutubeVerified,
    influencer_instagram: instagramData,
    influencer_youtube: youtubeData,
    influencer_facebook: facebookData,
    influencer_twitter: twitterData,
    dob,
  } = influencerData || {};

  const {
    id,
    pincode,
    address_line_1: addressLine1,
    address_line_2: addressLine2,
    landmark,
    state,
    city,
    address_type: addressType,
  } = addresses?.[0] || {};

  const filteredCategories = useMemo(
    () => uinqueProfileCategories(categories),
    [categories]
  );

  const instgramHandle = instagramData?.username || name;
  const youtubeHandle = youtubeData?.handle || name;
  const facebookHandle = facebookData?.username || facebookData?.name || name;
  const twitterHandle = twitterData?.username || name;

  return (
    <div className={styles.myProfileColumn}>
      <div className={styles.profileHeader}>
        <h2 className={styles.profileTxt}>My Profile</h2>
      </div>
      <div className={styles.profileInformation}>
        <div className="profileForm">
          <div className={styles.profileHeader}>
            <h3 className={styles.headingTxt}>Profile Information</h3>
            <button type="button" title="edit" onClick={onEditProfile}>
              <img src={editIcon} alt="edit" />
            </button>
          </div>
          <div className={styles.profileImg}>
            <img src={profile} alt="" className={styles.profile} />
          </div>
          <ProfileStatus profileStatus={profileStatus} />
          <div className="detailsection">
            <div className={styles.detailInner}>
              <label className={styles.labelTxt}>Full Name</label>
              <p className={styles.subTxt}>
                {name} <span className={styles.userStatus}>{role}</span>
              </p>
            </div>
            <div className={styles.detailInner}>
              <label className={styles.labelTxt}>Email Id</label>
              <p className={styles.subTxt}>{email}</p>
            </div>
            <div className={styles.detailInner}>
              <label className={styles.labelTxt}>Mobile Number</label>
              <p className={styles.subTxt}>+91 {mobile}</p>
            </div>
            <div className={styles.detailInner}>
              <label className={styles.labelTxt}>Date Of Birth</label>
              <p className={styles.subTxt}>
                {dob ? format(new Date(dob), 'dd/MM/yyyy') : '-'}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.profileHeader}>
          <h3 className={styles.headingTxt}>GST Document Details</h3>
          <button type="button" title="edit" onClick={onEditGstDoc}>
            <img src={editIcon} alt="edit" />
          </button>
        </div>
        <div className="detailsection">
          <div className={styles.panFileUpload}>
            <div className={styles.detailInner}>
              <label className={styles.labelTxt}>GST Number</label>
              <p className={styles.subTxt}>{gstNo || '-'}</p>
            </div>
            <h3 className={styles.gstJpgFile}>
              {gstDoc?.split('/')?.slice(-1)?.[0]?.split('?')?.[0] || ''}
            </h3>
          </div>
        </div>
        <div className={styles.profileHeader}>
          <h3 className={styles.headingTxt}>PAN Card Details</h3>
          <button type="button" title="edit" onClick={onEditPanDoc}>
            <img src={editIcon} alt="edit" />
          </button>
        </div>
        <div className="detailsection">
          <div className={styles.panFileUpload}>
            <div className={styles.detailInner}>
              <label className={styles.labelTxt}>PAN Number</label>
              <p className={styles.subTxt}>{panNo || '-'}</p>
            </div>
            <h3 className={styles.gstJpgFile}>
              {panCard?.split('/')?.slice(-1)?.[0]?.split('?')?.[0] || ''}
            </h3>
          </div>
        </div>
        <div className="profileForm">
          <div className={styles.profileHeader}>
            <h3 className={styles.headingTxt}>Address</h3>
            <button type="button" title="edit" onClick={onEditAddress}>
              <img src={editIcon} alt="edit" />
            </button>
          </div>
          <div className="detailsection">
            <div className={styles.detailInner} key={id}>
              <p className={styles.subTxt}>
                {addressLine1 && `${addressLine1}, `}
                {addressLine2 && `${addressLine2}, `}
                {landmark && `${landmark}, `}
                {city && `${city}, `}
                {state} - {pincode}
              </p>
              <span
                style={{ marginLeft: '0px', marginTop: '9px' }}
                className={styles.userStatus}
              >
                {addressType}
              </span>
            </div>
          </div>
        </div>
        <div className="profileForm">
          <h3 className={styles.headingTxt}>Category</h3>
          <div className="detailsection">
            <div className={styles.detailInner}>
              <div className={styles.categoryBlock}>
                {filteredCategories?.map((category) => (
                  <span className={styles.categoryText} key={category}>
                    {category}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="profileForm">
          <div className={styles.profileHeader}>
            <h3 className={styles.headingTxt}>Languages</h3>
            <button type="button" title="edit" onClick={onEditLanguages}>
              <img src={editIcon} alt="edit" />
            </button>
          </div>
          <div className="detailsection">
            <div className={styles.detailInner}>
              <div className={styles.categoryBlock}>
                {languages?.map((language) => (
                  <span className={styles.categoryText} key={language}>
                    {language}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="profileForm">
          <div className={styles.profileHeader}>
            <h3 className={styles.headingTxt}>Social Accounts</h3>
            <div>
              <button
                type="button"
                title="edit"
                onClick={onAddMoreSocialAccounts}
              >
                <img src={editIcon} alt="edit" />
              </button>
            </div>
          </div>
          <div className={styles.socialPlatformWrapper}>
            {isInstaVerified && (
              <div className={styles.socialPlatform}>
                <div className={styles.socialImage}>
                  <Player loop autoplay src={instagramAnimatedIcon} />
                </div>
                <h3 className={styles.socialTxt}>{instgramHandle}</h3>
              </div>
            )}
            {isFacebookVerified && (
              <div className={styles.socialPlatform}>
                <div className={styles.socialImage}>
                  <Player loop autoplay src={facebookAnimatedIcon} />
                </div>
                <h3 className={styles.socialTxt}>{facebookHandle}</h3>
              </div>
            )}
            {isTwitterVerified && (
              <div className={styles.socialPlatform}>
                <div className={styles.socialImage}>
                  <Player loop autoplay src={twitterAnimatedIcon} />
                </div>
                <h3 className={styles.socialTxt}>{twitterHandle}</h3>
              </div>
            )}
            {isYoutubeVerified && (
              <div className={styles.socialPlatform}>
                <div className={styles.socialImage}>
                  <Player
                    loop
                    autoplay
                    src={youtubeAnimatedIcon}
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      transform: 'scale(2.4)',
                    }}
                  />
                </div>
                <h3 className={styles.socialTxt}>{youtubeHandle}</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ProfileDetailsSidebar.propTypes = {
  influencerData: PropTypes.object,
};

export default Profile;
