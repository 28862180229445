import React, { useId } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'components/common/form/ErrorMessage';
import styles from 'assets/scss/pages/popup.module.scss';

const Input = React.forwardRef(
  ({ label, error, Component = 'input', startAdornment, ...rest }, ref) => {
    const inputId = useId();
    const errorId = useId();
    return (
      <div className={styles.popupInputWrap}>
        {label ? <label className={styles.hTxt}>{label}</label> : null}
        <div style={{ position: 'relative' }}>
          {startAdornment && (
            <div style={{ position: 'absolute', top: 3, left: 0 }}>
              {startAdornment}
            </div>
          )}
          <Component
            type="text"
            {...rest}
            className={styles.inputTxt}
            style={{
              paddingLeft: startAdornment ? 14 : 0,
              height: rest?.name === 'description' && 115,
            }}
            ref={ref}
            id={inputId}
            aria-invalid={error ? 'true' : 'false'}
            aria-describedby={errorId}
          />
        </div>
        <ErrorMessage error={error} id={errorId} />
      </div>
    );
  }
);

Input.propTypes = {
  label: PropTypes.node,
  error: PropTypes.object,
  Component: PropTypes.elementType,
  startAdornment: PropTypes.node,
};

export default Input;
