import React, { useEffect, useRef } from 'react';
import { animate, motion } from 'framer-motion';

const DirectConsumers = (props) => {
  const numberRef = useRef();

  useEffect(() => {
    const node = numberRef.current;
    if (!node) return;

    const controls = animate(0, 4.55, {
      duration: 1,
      delay: 1,
      onUpdate(value) {
        node.textContent = `${value?.toFixed(2).toString()} M`;
      },
    });

    // eslint-disable-next-line consistent-return
    return () => controls.stop();
  }, []);
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width={225}
      height={89}
      {...props}
      style={{ overflow: 'visible', postion: 'relative' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.7 }}
    >
      <defs>
        <linearGradient
          id="directConsumersGradient1"
          x2={0.984}
          y1={0.5}
          y2={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.607} stopColor="#b584ce" stopOpacity={0.149} />
          <stop offset={1} stopColor="#641582" stopOpacity={0.8} />
        </linearGradient>
        <linearGradient
          id="directConsumersGradient2"
          x2={1}
          y1={0.5}
          y2={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#f1aeed" />
          <stop offset={1} stopColor="#f571f9" />
        </linearGradient>
        <filter
          id="directConsumersFilter1"
          width={241}
          height={110}
          x={110}
          y={119}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={4} dy={5} />
          <feGaussianBlur result="blur" stdDeviation={5} />
          <feFlood floodColor="#e79ce3" floodOpacity={0.502} />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Group 55290">
        <g
          data-name="Group 54643"
          opacity={0.8}
          transform="translate(-378 -552)"
        >
          <rect
            width={211}
            height={83}
            fill="#ec40f1"
            data-name="Rectangle 28682"
            rx={12}
            transform="translate(392 558)"
            opacity={0.3}
            filter="blur(45px)"
          />
          <g
            filter="url(#directConsumersFilter1)"
            transform="translate(257 423)"
          >
            <g
              fill="url(#directConsumersGradient1)"
              stroke="rgba(255,255,255,0.3)"
              strokeWidth={1.5}
              data-name="Rectangle 28681"
              transform="translate(121 129)"
            >
              <rect width={211} height={80} stroke="none" rx={12} />
              <rect
                width={209.5}
                height={78.5}
                x={0.75}
                y={0.75}
                fill="none"
                rx={11.25}
              />
            </g>
          </g>
          <rect
            width={211}
            height={80}
            fill="rgba(255,255,255,0)"
            data-name="Rectangle 28683"
            rx={12}
            transform="translate(378.088 552)"
          />
        </g>
        <g data-name="Group 54645">
          <text
            fill="#fff"
            data-name="4.55 M"
            fontFamily="Poppins-Bold, Poppins"
            fontSize={35}
            fontWeight={700}
            style={{
              whiteSpace: 'pre',
            }}
            transform="translate(75.177 53)"
          >
            <tspan x={0} y={0} ref={numberRef} />
          </text>
          <path
            fill="url(#directConsumersGradient2)"
            d="M20.3 21.946c.422-10.212 14.993-10.213 15.415 0a7.708 7.708 0 0 1-15.415 0Zm20.129 19.762a12.584 12.584 0 0 0-24.983 2.418.842.842 0 0 0 .806.574H39.76c1.414-.119.715-2.093.669-2.992Zm2.538-9.791a5.642 5.642 0 0 0 5.633-5.633c-.283-7.451-10.984-7.449-11.266 0a5.642 5.642 0 0 0 5.633 5.633Zm0 .6a9.066 9.066 0 0 0-4.544 1.217 12.9 12.9 0 0 1 1.115 1.346 14.121 14.121 0 0 1 2.7 7.33h8.917a.86.86 0 0 0 .857-.857 9.051 9.051 0 0 0-9.045-9.037Zm-29.922-.6a5.642 5.642 0 0 0 5.633-5.633c-.283-7.451-10.984-7.449-11.266 0a5.642 5.642 0 0 0 5.633 5.633Zm4.544 1.818C11.743 30.21 3.878 34.716 4 41.553a.86.86 0 0 0 .857.857h8.917a14.178 14.178 0 0 1 3.815-8.676Z"
            data-name="group (1)"
            transform="translate(10.996 10.714)"
          />
        </g>
      </g>
    </motion.svg>
  );
};
export default DirectConsumers;
