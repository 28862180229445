import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { JSEncrypt } from 'jsencrypt';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  chargeUser,
  getCardlist,
  getPaymentRedirectUrl,
} from 'api/brand/payments';
import { ErrorMessage, InputField } from 'components/payments';
import styles from 'assets/scss/pages/payments.module.scss';
import routes from 'constants/routes';
import { PAYMENT_FORM_SCHEMA } from 'constants/schema';
import { PAYMENT_TYPES } from 'constants/config/payment';

const defaultValues = {
  paymentType: PAYMENT_TYPES.CREDIT_CARD,
  cardName: '',
  cardNumber: '',
  cardExpiry: '',
  cardCvv: '',
  // billingName: '',
  agreeTerms: false,
};

const PUBLIC_KEY = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyk2yVx0n2+xAQk+pg+EQ
24NcPGVfVDw1CoT+BM4twfP+4czVGIyj/FIaLWgthj3MyfDOJy6vYn2SnjXXz0tC
EHi7+Ab9W914CHdXmkaCJsOVxwcQnsV6Nr9CE4r1tM7DrzS+g0wqR4x5jyYvmMbZ
1/ThdtFnEvtRg9xThLZRiLzwTOc6Ee/63xCu+s1ksfsuzTxSZ7lPxlRkGY8hugtN
xpyY4ikm4A1ecB8kuFvbpNHo0HGPOIgAJDNKUbD8kGtvBrScM084DMvuujnRINYl
oOazspbwvkZ3LLQDOevV+vj0N6MJYrjtLyI2hgmnI/qd5iuhhzU15qXJfXiQNrLu
OQIDAQAB
-----END PUBLIC KEY-----`;

const PaymentForm = ({ formattedAmount, paymentData, disabled = false }) => {
  /**
   * * moduleId is a section or feature of website for which we are doing payment
   * * For eg. "campaing", "brand-subscription" etc.
   *  */
  const { paymentId, moduleId } = useParams();
  const [processing, setProcessing] = useState(false);

  const quoteId = paymentData?.quotationCampaignId;

  const { data: cardList } = useQuery({
    queryKey: ['payment-cards'],
    queryFn: () => getCardlist(),
    select: (res) => res?.data?.cardlist,
  });

  const {
    register,
    handleSubmit,
    resetField,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(PAYMENT_FORM_SCHEMA),
    defaultValues,
  });

  const payNowMutation = useMutation({
    mutationFn: ({
      paymentType,
      cardName,
      cardNumber,
      cardExpiry,
      cardCvv,
    }) => {
      setProcessing(true);
      const isCardPayment =
        paymentType === 'OPTCRDC' || paymentType === 'OPTDBCRD';
      const cardExpiryArray = cardExpiry?.split('/');
      const payload = {
        payment_option: paymentType,
        card_name: cardName,
        card_number: isCardPayment ? cardNumber.split(' ').join('') : undefined,
        expiry_month: isCardPayment ? cardExpiryArray?.[0] : undefined,
        expiry_year: isCardPayment ? cardExpiryArray?.[1] : undefined,
        cvv_number: isCardPayment ? cardCvv : undefined,
        return_url: window.location.pathname,

        order_id: paymentId,
      };

      // encrypt payload
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(PUBLIC_KEY);
      const encrypted = encrypt.encrypt(JSON.stringify(payload));

      return chargeUser({ quoteId, body: { encryptedData: encrypted } });
    },
    onError: (error) => {
      const errorMessage =
        error.response?.data?.message || error.response?.data?.error;
      toast.error(errorMessage);
      setProcessing(false);
    },
    onSuccess: () => {
      window.location.replace(getPaymentRedirectUrl({ paymentId: quoteId }));
    },
  });

  const paymentType = watch('paymentType');

  useEffect(() => {
    resetField('cardName');
    resetField('cardNumber');
    resetField('cardExpiry');
    resetField('cardCvv');
  }, [paymentType, resetField]);

  const cardNameOptions = useMemo(
    () =>
      cardList?.find((cardType) => cardType.payment_option === paymentType)
        ?.card_names || [],
    [paymentType, cardList]
  );

  const isDisabled =
    isSubmitting || payNowMutation.isLoading || processing || disabled;

  const isCardPayment =
    paymentType === PAYMENT_TYPES.CREDIT_CARD ||
    paymentType === PAYMENT_TYPES.DEBIT_CARD;
  const isNetBanking = paymentType === PAYMENT_TYPES.NET_BANKING;

  return (
    <form
      className={styles.paymentForm}
      id="payment-form"
      onSubmit={handleSubmit(payNowMutation.mutate)}
    >
      <div className={styles.inputContainer}>
        <div className={styles.inputGroup}>
          <div className={styles.inputRow}>
            <label
              className={clsx(styles.input, styles.radioInputContainer)}
              data-disabled={isDisabled || undefined}
            >
              <input
                type="radio"
                value={PAYMENT_TYPES.CREDIT_CARD}
                {...register('paymentType')}
                disabled={isDisabled}
              />
              <span>Credit Card</span>
            </label>
            <label
              className={clsx(styles.input, styles.radioInputContainer)}
              data-disabled={isDisabled || undefined}
            >
              <input
                type="radio"
                value={PAYMENT_TYPES.DEBIT_CARD}
                {...register('paymentType')}
                disabled={isDisabled}
              />
              <span>Debit Card</span>
            </label>
          </div>
          <label
            className={clsx(styles.input, styles.radioInputContainer)}
            data-disabled={isDisabled || undefined}
          >
            <input
              type="radio"
              value={PAYMENT_TYPES.NET_BANKING}
              {...register('paymentType')}
              disabled={isDisabled}
            />
            <span>Net Banking</span>
          </label>
        </div>
      </div>
      <div className={styles.inputGroup}>
        <div className={styles.inputContainer}>
          <div className={styles.selectWrapper}>
            <select
              className={styles.input}
              {...register('cardName')}
              data-invalid={errors.cardName}
              disabled={isDisabled}
            >
              <option value="">
                Select {isNetBanking ? 'Bank' : 'Card Type'}
              </option>
              {cardNameOptions.map((name) => (
                <option value={name} key={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <ErrorMessage error={errors.cardName} />
        </div>
        {isCardPayment && (
          <>
            <InputField
              autoComplete="cc-number"
              spellCheck="false"
              autoCorrect="off"
              inputMode="numeric"
              placeholder="1234 1234 1234 1234"
              aria-label="Card number"
              {...register('cardNumber')}
              error={errors.cardNumber}
              disabled={isDisabled}
            />
            <div className={styles.inputContainer}>
              <div className={styles.inputRow}>
                <InputField
                  autoComplete="cc-exp"
                  autoCorrect="off"
                  spellCheck="false"
                  inputMode="numeric"
                  placeholder="MM/YYYY"
                  maxLength="7"
                  aria-label="Expiry date"
                  {...register('cardExpiry')}
                  error={errors.cardExpiry}
                  disabled={isDisabled}
                />
                <InputField
                  autoComplete="cc-csc"
                  autoCorrect="off"
                  spellCheck="false"
                  inputMode="numeric"
                  placeholder="CVV"
                  aria-label="Security code"
                  minLength="3"
                  maxLength="3"
                  {...register('cardCvv')}
                  error={errors.cardCvv}
                  disabled={isDisabled}
                />
              </div>
            </div>
            {/* <InputField
          label="Name on card"
          autoComplete="cc-name"
          autoCorrect="off"
          spellCheck="false"
          aria-label="Name on card"
          {...register('billingName')}
          error={errors.billingName}
          disabled={isDisabled}
        /> */}
          </>
        )}
      </div>
      <div className={styles.inputGroup}>
        <label className={clsx(styles.radioInputContainer)}>
          <input
            type="checkbox"
            {...register('agreeTerms')}
            disabled={isDisabled}
          />
          <span
            className={styles.termsLabel}
            data-invalid={errors.agreeTerms ? true : undefined}
          >
            I have read and agree to the website terms and conditions{' '}
            <span>*</span>
          </span>
        </label>
        <p className={styles.privacyNote}>
          Your personal data will be used to process your order, support your
          experience throughout this website, and for other purposes described
          in our <Link to={routes.PRIVACY_POLICY}>privacy policy</Link>.
        </p>
      </div>
      <button type="submit" className={styles.payButton} disabled={isDisabled}>
        Pay {formattedAmount}
      </button>
    </form>
  );
};

PaymentForm.propTypes = {
  formattedAmount: PropTypes.string,
  paymentData: PropTypes.object,
  disabled: PropTypes.bool,
};

export default PaymentForm;
