import React, { useId } from 'react';
import { motion } from 'framer-motion';

const BrandIndianRankGraphOutline = (props) => {
  const gradient1 = useId();
  const gradient2 = useId();
  const gradient3 = useId();
  const filter1 = useId();
  const filter2 = useId();

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width={376}
      height={291}
      {...props}
      style={{ overflow: 'visible', postion: 'relative' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.7 }}
    >
      <defs>
        <linearGradient
          id={gradient1}
          x2={0.984}
          y1={0.5}
          y2={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={0} stopColor="#b584ce" stopOpacity={0.149} />
          <stop offset={1} stopColor="#361559" stopOpacity={0.8} />
        </linearGradient>
        <linearGradient
          id={gradient2}
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#ec40f1" stopOpacity={0.2} />
          <stop offset={1} stopColor="#ff68b5" stopOpacity={0.051} />
        </linearGradient>
        <linearGradient
          id={gradient3}
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fc9eff" />
          <stop offset={1} stopColor="#fee4ff" stopOpacity={0.988} />
        </linearGradient>
        <filter
          id={filter1}
          width={392}
          height={312}
          x={110}
          y={119}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={4} dy={5} />
          <feGaussianBlur result="blur" stdDeviation={5} />
          <feFlood floodColor="#e79ce3" floodOpacity={0.502} />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id={filter2}>
          <feOffset dy={3} />
          <feGaussianBlur result="blur-2" stdDeviation={3} />
          <feFlood floodColor="#ec40f1" floodOpacity={0.702} result="color" />
          <feComposite in="SourceGraphic" in2="blur-2" operator="out" />
          <feComposite in="color" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs>
      <g data-name="Group 55312">
        <g
          data-name="Group 54649"
          opacity={0.8}
          transform="translate(-377.079 -552)"
        >
          <rect
            width={362}
            height={285}
            fill="#361559"
            data-name="Rectangle 28682"
            rx={12}
            transform="translate(391.079 558)"
            opacity={0.5}
            filter="blur(45px)"
          />
          <g filter={`url(#${filter1})`} transform="translate(256.08 423)">
            <g
              fill={`url(#${gradient1})`}
              stroke="#fff"
              strokeWidth={1.5}
              data-name="Rectangle 28681"
              transform="translate(121 129)"
            >
              <rect width={362} height={282} stroke="none" rx={12} />
              <rect
                width={360.5}
                height={280.5}
                x={0.75}
                y={0.75}
                fill="none"
                rx={11.25}
              />
            </g>
          </g>
          <rect
            width={362}
            height={282}
            fill="rgba(255,255,255,0)"
            data-name="Rectangle 28683"
            rx={12}
            transform="translate(377.079 552)"
          />
        </g>
        <g data-name="Group 54648">
          <g data-name="Group 54592">
            <path
              fill="none"
              stroke="#b4b6c4"
              strokeLinecap="round"
              d="M79.806 233.072h260.779"
              data-name="Line 244"
              opacity={0.2}
            />
            <path
              fill="none"
              stroke="#f8f9fa"
              strokeLinecap="round"
              d="M79.806 196.35h260.779"
              data-name="Line 245"
              opacity={0.2}
            />
            <path
              fill="none"
              stroke="#f8f9fa"
              strokeLinecap="round"
              d="M79.806 159.631h260.779"
              data-name="Line 246"
              opacity={0.2}
            />
            <path
              fill="none"
              stroke="#f8f9fa"
              strokeLinecap="round"
              d="M79.806 122.91h260.779"
              data-name="Line 247"
              opacity={0.2}
            />
            <motion.text
              fill="#e1e1e1"
              data-name={250}
              fontFamily="Poppins-SemiBold, Poppins"
              fontSize={12}
              fontWeight={600}
              style={{
                whiteSpace: 'pre',
              }}
              transform="translate(63.149 124.662)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 2.3 }}
            >
              <tspan x={-7} y={0}>
                {'6'}
              </tspan>
            </motion.text>
            <motion.text
              fill="#e1e1e1"
              data-name={250}
              fontFamily="Poppins-SemiBold, Poppins"
              fontSize={12}
              fontWeight={600}
              style={{
                whiteSpace: 'pre',
              }}
              transform="translate(63.496 161.383)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 2.1 }}
            >
              <tspan x={-7} y={0}>
                {'4'}
              </tspan>
            </motion.text>
            <motion.text
              fill="#e1e1e1"
              data-name={250}
              fontFamily="Poppins-SemiBold, Poppins"
              fontSize={12}
              fontWeight={600}
              style={{
                whiteSpace: 'pre',
              }}
              transform="translate(60.499 198.104)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 1.9 }}
            >
              <tspan x={-6} y={0}>
                {'2'}
              </tspan>
            </motion.text>
            <motion.text
              fill="#e1e1e1"
              data-name={250}
              fontFamily="Poppins-SemiBold, Poppins"
              fontSize={12}
              fontWeight={600}
              style={{
                whiteSpace: 'pre',
              }}
              transform="translate(61.542 234.826)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 1.7 }}
            >
              <tspan x={-7} y={0}>
                {'0'}
              </tspan>
            </motion.text>
            <motion.text
              fill="#e1e1e1"
              data-name={250}
              fontFamily="Poppins-Medium, Poppins"
              fontSize={12}
              fontWeight={500}
              style={{
                whiteSpace: 'pre',
              }}
              transform="translate(300.501 262.295)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 2.5 }}
            >
              <tspan x={0} y={0}>
                {'India'}
              </tspan>
            </motion.text>
            <motion.text
              fill="#e1e1e1"
              data-name={250}
              fontFamily="Poppins-Medium, Poppins"
              fontSize={12}
              fontWeight={500}
              style={{
                whiteSpace: 'pre',
              }}
              transform="translate(237.501 262.295)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 2.3 }}
            >
              <tspan x={0} y={0}>
                {'Australia'}
              </tspan>
            </motion.text>
            <motion.text
              fill="#e1e1e1"
              data-name={250}
              fontFamily="Poppins-Medium, Poppins"
              fontSize={12}
              fontWeight={500}
              style={{
                whiteSpace: 'pre',
              }}
              transform="translate(192.501 262.295)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 2.1 }}
            >
              <tspan x={0} y={0}>
                {'Spain'}
              </tspan>
            </motion.text>
            <motion.text
              fill="#e1e1e1"
              data-name={250}
              fontFamily="Poppins-Medium, Poppins"
              fontSize={12}
              fontWeight={500}
              style={{
                whiteSpace: 'pre',
              }}
              transform="translate(135.501 262.295)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 1.9 }}
            >
              <tspan x={0} y={0}>
                {'France'}
              </tspan>
            </motion.text>
            <motion.text
              fill="#e1e1e1"
              data-name={250}
              fontFamily="Poppins-Medium, Poppins"
              fontSize={12}
              fontWeight={500}
              style={{
                whiteSpace: 'pre',
              }}
              transform="translate(90.697 262.295)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 1.7 }}
            >
              <tspan x={0} y={0}>
                {'Italy'}
              </tspan>
            </motion.text>
            <text
              fill="#fff"
              data-name={250}
              fontFamily="Poppins-Bold, Poppins"
              fontSize={26}
              fontWeight={700}
              style={{
                whiteSpace: 'pre',
              }}
              transform="translate(54.033 42)"
            >
              <motion.tspan
                x={0}
                y={0}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 1.7 }}
              >
                {'India ranks above '}
              </motion.tspan>
              <motion.tspan
                x={0}
                y={39}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 1.9 }}
              >
                {'developed nations'}
              </motion.tspan>
            </text>
            <motion.path
              fill="none"
              stroke="#ec40f1"
              d="M103.348 205.612s12.028-26.035 49.84-16.982 54.794-3.965 54.794-3.965 31.952-24.852 56.8-11.834 37.927-35.445 52.9-33.906"
              data-name="Path 60295"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 1, delay: 1.5 }}
            />
            <g data-type="innerShadowGroup">
              <motion.path
                fill={`url(#${gradient2})`}
                d="M11938.366-4384.256c-14.972-1.539-28.046 46.924-52.9 33.906s-56.8 11.834-56.8 11.834-16.982 13.018-54.794 3.965-49.84 16.982-49.84 16.982v26.635h214.337Z"
                data-name="Path 60296"
                transform="translate(-11620.681 4523.18)"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 2.5 }}
              />
              {/* <g
                filter={`url(#${filter2})`}
                transform="translate(-121.001 -129)"
              >
                <path
                  fill="#fff"
                  d="M438.686 267.924c-14.972-1.539-28.046 46.924-52.9 33.906s-56.8 11.834-56.8 11.834-16.982 13.018-54.794 3.965-49.84 16.982-49.84 16.982v26.635h214.337Z"
                  data-name="Path 60296"
                />
              </g> */}
            </g>
            <motion.path
              fill={`url(#${gradient3})`}
              d="M3.652 0A3.652 3.652 0 0 1 7.3 3.652V18H0V3.652A3.652 3.652 0 0 1 3.652 0Z"
              data-name="Rectangle 258"
              transform="translate(100.217 214.214)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 1.5 }}
            />
            <motion.path
              fill={`url(#${gradient3})`}
              d="M3.652 0A3.652 3.652 0 0 1 7.3 3.652V36H0V3.652A3.652 3.652 0 0 1 3.652 0Z"
              data-name="Rectangle 259"
              transform="translate(152.501 196.783)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 1.7 }}
            />
            <motion.path
              fill={`url(#${gradient3})`}
              d="M3.652 0A3.652 3.652 0 0 1 7.3 3.652V36H0V3.652A3.652 3.652 0 0 1 3.652 0Z"
              data-name="Rectangle 260"
              transform="translate(205.501 196.916)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 1.9 }}
            />
            <motion.path
              fill={`url(#${gradient3})`}
              d="M3.652 0A3.652 3.652 0 0 1 7.3 3.652V54H0V3.652A3.652 3.652 0 0 1 3.652 0Z"
              data-name="Rectangle 261"
              transform="translate(260.501 178.353)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 2.1 }}
            />
            <motion.path
              fill={`url(#${gradient3})`}
              d="M3.652 0A3.652 3.652 0 0 1 7.3 3.652V74H0V3.652A3.652 3.652 0 0 1 3.652 0Z"
              data-name="Rectangle 262"
              transform="translate(311.5 158.312)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 2.3 }}
            />
            <motion.path
              fill="none"
              stroke="#ffbfe0"
              d="M79.872 232.774h260.839"
              data-name="Line 27"
              opacity={0.5}
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 0.5, delay: 1 }}
            />
            <motion.g
              data-name="Group 54591"
              transform="translate(310.591 135.947)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 2.5 }}
            >
              <g
                fill="#fff"
                stroke="#ff00a2"
                strokeWidth={0.5}
                data-name="Ellipse 1468"
                transform="translate(.074 .282)"
              >
                <circle cx={4.5} cy={4.5} r={4.5} stroke="none" />
                <circle cx={4.5} cy={4.5} r={4.25} fill="none" />
              </g>
              <circle
                cx={2.5}
                cy={2.5}
                r={2.5}
                fill="#ff00a2"
                data-name="Ellipse 1469"
                transform="translate(2.074 2.282)"
              />
            </motion.g>
          </g>
          <motion.text
            fill="#fff"
            data-name="Average increase sales"
            fontFamily="Poppins-Regular, Poppins"
            fontSize={12}
            style={{
              whiteSpace: 'pre',
            }}
            transform="rotate(-90 107.5 86.5)"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 2.1 }}
          >
            <tspan x={-4} y={13}>
              {'No. of Brands'}
            </tspan>
          </motion.text>
          <motion.g
            transform="translate(296.27 106.417)"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 2.5 }}
          >
            <path
              fill="#fab446"
              d="M36.869 0H2.985A2.985 2.985 0 0 0 0 2.985v5.719h39.854V2.985A2.985 2.985 0 0 0 36.869 0Z"
              data-name="Path 59883"
            />
            <path
              fill="#73af00"
              d="M0 23.126a2.985 2.985 0 0 0 2.985 2.985h33.884a2.985 2.985 0 0 0 2.985-2.985v-5.719H0Z"
              data-name="Path 59884"
            />
            <path
              fill="#f5f5f5"
              d="M0 8.704h39.854v8.703H0z"
              data-name="Rectangle 27917"
            />
            <g
              fill="#41479b"
              data-name="Group 53422"
              transform="translate(16.234 9.362)"
            >
              <path
                d="M3.693 7.387a3.693 3.693 0 1 1 3.693-3.693 3.7 3.7 0 0 1-3.693 3.693Zm0-6.7a3.006 3.006 0 1 0 3.006 3.006A3.009 3.009 0 0 0 3.693.687Z"
                data-name="Path 59885"
              />
              <circle
                cx={0.419}
                cy={0.419}
                r={0.419}
                data-name="Ellipse 1449"
                transform="translate(3.275 3.275)"
              />
              <path
                d="m3.694 3.756-1.064.107-2.286-.107V3.63l2.286-.107 1.064.107Z"
                data-name="Path 59886"
              />
              <path
                d="m3.693 3.756 1.064.107 2.286-.107V3.63l-2.286-.107-1.064.107Z"
                data-name="Path 59887"
              />
              <path
                d="M3.63 3.694 3.523 2.63 3.63.344h.126l.107 2.286-.107 1.064Z"
                data-name="Path 59888"
              />
              <path
                d="m3.63 3.693-.107 1.064.107 2.286h.126l.107-2.286-.107-1.064Z"
                data-name="Path 59889"
              />
              <path
                d="m3.649 3.737-.828-.676L1.28 1.369l.089-.089 1.692 1.54.676.828Z"
                data-name="Path 59890"
              />
              <path
                d="m3.649 3.738.676.828 1.692 1.54.089-.089-1.54-1.692-.828-.676Z"
                data-name="Path 59891"
              />
              <path
                d="m3.649 3.649.676-.828L6.019 1.28l.089.089-1.54 1.692-.828.676Z"
                data-name="Path 59892"
              />
              <path
                d="m3.649 3.649-.828.676L1.28 6.019l.089.089 1.692-1.54.676-.828Z"
                data-name="Path 59893"
              />
              <path
                d="m3.718 3.751-.942.506-2.153.775-.048-.116 2.071-.974 1.024-.308Z"
                data-name="Path 59894"
              />
              <path
                d="m3.717 3.751 1.024-.308 2.071-.974-.048-.116-2.153.775-.942.506Z"
                data-name="Path 59895"
              />
              <path
                d="m3.635 3.718-.506-.942L2.354.623 2.47.575l.974 2.071.308 1.024Z"
                data-name="Path 59896"
              />
              <path
                d="m3.635 3.717.308 1.024.974 2.071.116-.048-.775-2.153-.506-.942Z"
                data-name="Path 59897"
              />
              <path
                d="m3.67 3.751-1.028-.308-2.071-.974.048-.116 2.153.775.942.506Z"
                data-name="Path 59898"
              />
              <path
                d="m3.669 3.751.942.506 2.153.775.048-.116-2.071-.974-1.024-.308Z"
                data-name="Path 59899"
              />
              <path
                d="m3.635 3.67.308-1.024.974-2.071.116.048-.775 2.153-.506.942Z"
                data-name="Path 59900"
              />
              <path
                d="m3.635 3.669-.506.942-.775 2.153.116.048.974-2.071.308-1.024Z"
                data-name="Path 59901"
              />
            </g>
            <circle
              cx={0.565}
              cy={0.565}
              r={0.565}
              fill="#f5f5f5"
              data-name="Ellipse 1450"
              transform="translate(19.362 12.491)"
            />
            <circle
              cx={0.339}
              cy={0.339}
              r={0.339}
              fill="#41479b"
              data-name="Ellipse 1451"
              transform="translate(19.588 12.717)"
            />
          </motion.g>
        </g>
      </g>
    </motion.svg>
  );
};
export default BrandIndianRankGraphOutline;
