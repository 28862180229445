import React, { useEffect, useId, useRef } from 'react';
import { animate, motion } from 'framer-motion';
import u1 from 'assets/images/home-svg-users/u1.jpg';
import u2 from 'assets/images/home-svg-users/u2.jpg';
import u3 from 'assets/images/home-svg-users/u3.jpg';
import u4 from 'assets/images/home-svg-users/u4.jpg';
import u5 from 'assets/images/home-svg-users/u5.jpg';

const MonthlyActiveUsersMainInfluencer = (props) => {
  const p1 = useId();
  const p2 = useId();
  const p3 = useId();
  const p4 = useId();
  const p5 = useId();
  const g1 = useId();
  const f1 = useId();

  const numberRef = useRef();

  useEffect(() => {
    const node = numberRef.current;
    if (!node) return;

    const controls = animate(0, 350, {
      duration: 1,
      delay: 1,
      onUpdate(value) {
        node.textContent = `${value?.toFixed(0)} M+`;
      },
    });

    // eslint-disable-next-line consistent-return
    return () => controls.stop();
  }, []);

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={197}
      height={172}
      {...props}
      style={{ overflow: 'visible', postion: 'relative' }}
    >
      <defs>
        <pattern
          id={p1}
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 512 512"
        >
          <image xlinkHref={u1} width={512} height={512} />
        </pattern>
        <pattern
          id={p2}
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 200 200"
        >
          <image xlinkHref={u2} width={200} height={200} />
        </pattern>
        <pattern
          id={p3}
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 128 128"
        >
          <image xlinkHref={u3} width={128} height={128} />
        </pattern>
        <pattern
          id={p4}
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 250 250"
        >
          <image xlinkHref={u4} width={250} height={250} />
        </pattern>
        <pattern
          id={p5}
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 741 772"
        >
          <image xlinkHref={u5} width={741} height={772} />
        </pattern>
        <linearGradient
          id={g1}
          x2={0.984}
          y1={0.5}
          y2={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.607} stopColor="#b584ce" stopOpacity={0.149} />
          <stop offset={1} stopColor="#641582" stopOpacity={0.8} />
        </linearGradient>
        <filter
          id={f1}
          width={213}
          height={193}
          x={110}
          y={119}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={4} dy={5} />
          <feGaussianBlur result="c" stdDeviation={5} />
          <feFlood floodColor="#e79ce3" floodOpacity={0.502} />
          <feComposite in2="c" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g opacity={0.8} transform="translate(-378 -552)">
        <rect
          width={183}
          height={166}
          fill="#ec40f1"
          rx={12}
          transform="translate(392 558)"
          opacity={0.3}
          filter="blur(45px)"
        />
        <g filter={`url(#${f1})`} transform="translate(257 423)">
          <g
            fill={`url(#${g1})`}
            stroke="rgba(255,255,255,0.3)"
            strokeWidth={1.5}
            transform="translate(121 129)"
          >
            <rect width={183} height={163} stroke="none" rx={12} />
            <rect
              width={181.5}
              height={161.5}
              x={0.75}
              y={0.75}
              fill="none"
              rx={11.25}
            />
          </g>
        </g>
        <rect
          width={183}
          height={163}
          fill="rgba(255,255,255,0)"
          rx={12}
          transform="translate(378 552)"
        />
      </g>
      <text
        fill="#fff"
        fontFamily="Poppins-Bold, Poppins"
        fontSize={35}
        fontWeight={700}
        style={{
          whiteSpace: 'pre',
        }}
        transform="translate(30 82.27)"
      >
        <tspan x={0} y={0} ref={numberRef} />
      </text>
      <g stroke="#fffcfc" strokeWidth={1.5}>
        <g fill={`url(#${p1})`} opacity={0.5} transform="translate(27 96.77)">
          <circle cx={23.4} cy={23.4} r={23.4} stroke="none" />
          <circle cx={23.4} cy={23.4} r={22.65} fill="none" />
        </g>
        <g fill={`url(#${p1})`} transform="translate(30 99.77)">
          <circle cx={20.4} cy={20.4} r={20.4} stroke="none" />
          <circle cx={20.4} cy={20.4} r={19.65} fill="none" />
        </g>
        <g
          fill={`url(#${p2})`}
          opacity={0.5}
          transform="translate(50.402 99.77)"
        >
          <circle cx={20.4} cy={20.4} r={20.4} stroke="none" />
          <circle cx={20.4} cy={20.4} r={19.65} fill="none" />
        </g>
        <g fill={`url(#${p2})`} transform="translate(50.4 99.77)">
          <circle cx={20.4} cy={20.4} r={20.4} stroke="none" />
          <circle cx={20.4} cy={20.4} r={19.65} fill="none" />
        </g>
        <g
          fill={`url(#${p3})`}
          opacity={0.5}
          transform="translate(70.801 99.77)"
        >
          <circle cx={20.4} cy={20.4} r={20.4} stroke="none" />
          <circle cx={20.4} cy={20.4} r={19.65} fill="none" />
        </g>
        <g fill={`url(#${p3})`} transform="translate(70.8 99.77)">
          <circle cx={20.4} cy={20.4} r={20.4} stroke="none" />
          <circle cx={20.4} cy={20.4} r={19.65} fill="none" />
        </g>
        <g
          fill={`url(#${p4})`}
          opacity={0.5}
          transform="translate(91.203 99.77)"
        >
          <circle cx={20.4} cy={20.4} r={20.4} stroke="none" />
          <circle cx={20.4} cy={20.4} r={19.65} fill="none" />
        </g>
        <g fill={`url(#${p4})`} transform="translate(91.2 99.77)">
          <circle cx={20.4} cy={20.4} r={20.4} stroke="none" />
          <circle cx={20.4} cy={20.4} r={19.65} fill="none" />
        </g>
        <g fill={`url(#${p5})`} transform="translate(111.4 99.77)">
          <circle cx={20.4} cy={20.4} r={20.4} stroke="none" />
          <circle cx={20.4} cy={20.4} r={19.65} fill="none" />
        </g>
      </g>
      <path
        fill="red"
        d="M54.154 25.7a3.089 3.089 0 0 0-2.181-2.184C50.05 23 42.335 23 42.335 23s-7.715 0-9.635.516a3.089 3.089 0 0 0-2.184 2.184A32.082 32.082 0 0 0 30 31.635a32.082 32.082 0 0 0 .516 5.938 3.089 3.089 0 0 0 2.184 2.181c1.923.516 9.638.516 9.638.516s7.715 0 9.638-.516a3.089 3.089 0 0 0 2.181-2.181 32.08 32.08 0 0 0 .516-5.938 32.271 32.271 0 0 0-.519-5.935Z"
      />
      <path fill="#fff" d="m39.865 35.336 6.409-3.7-6.409-3.7Z" />
      <g fill="#fff">
        <path d="m59.879 34.228-2.77-10h2.417l.971 4.535q.372 1.675.546 2.857h.071q.122-.847.546-2.841l1.005-4.551h2.417l-2.8 10v4.8h-2.4v-4.8ZM65.809 38.709a2.7 2.7 0 0 1-1.042-1.535 10.079 10.079 0 0 1-.309-2.77v-1.57a9.089 9.089 0 0 1 .353-2.8 2.818 2.818 0 0 1 1.1-1.543 3.575 3.575 0 0 1 1.968-.485 3.358 3.358 0 0 1 1.923.493 2.836 2.836 0 0 1 1.058 1.543 9.475 9.475 0 0 1 .335 2.8v1.567a9.623 9.623 0 0 1-.327 2.778 2.725 2.725 0 0 1-1.058 1.535 4.341 4.341 0 0 1-4-.008Zm2.733-1.694a5.3 5.3 0 0 0 .2-1.728v-3.368a5.176 5.176 0 0 0-.2-1.7.726.726 0 0 0-.715-.538.714.714 0 0 0-.7.538 5.184 5.184 0 0 0-.2 1.7v3.369a5.458 5.458 0 0 0 .195 1.728.709.709 0 0 0 .7.53.726.726 0 0 0 .721-.53ZM79.059 39.029h-1.9l-.211-1.324h-.061a2.491 2.491 0 0 1-2.329 1.5 1.834 1.834 0 0 1-1.588-.7 3.792 3.792 0 0 1-.512-2.205v-8.087h2.435v7.938a2.421 2.421 0 0 0 .158 1.032.55.55 0 0 0 .53.309 1.083 1.083 0 0 0 .609-.195 1.215 1.215 0 0 0 .433-.493v-8.593h2.433Z" />
        <path d="M85.674 26.184h-2.417v12.845h-2.382V26.184h-2.417v-1.958h7.215Z" />
        <path d="M91.547 39.029h-1.9l-.211-1.324h-.053a2.491 2.491 0 0 1-2.329 1.5 1.834 1.834 0 0 1-1.588-.7 3.792 3.792 0 0 1-.512-2.205v-8.087h2.435v7.938a2.421 2.421 0 0 0 .158 1.032.551.551 0 0 0 .53.309 1.083 1.083 0 0 0 .609-.195 1.215 1.215 0 0 0 .433-.493v-8.593h2.433ZM99.605 29.941a2.8 2.8 0 0 0-.715-1.482A1.914 1.914 0 0 0 97.531 28a2.246 2.246 0 0 0-1.253.38 2.578 2.578 0 0 0-.9 1h-.018v-5.702h-2.345v15.348h2.01l.248-1.024h.053a2.08 2.08 0 0 0 .847.865 2.514 2.514 0 0 0 1.253.317 1.919 1.919 0 0 0 1.818-1.137 8.2 8.2 0 0 0 .583-3.556V32.78a14.256 14.256 0 0 0-.222-2.839Zm-2.232 4.413a13.6 13.6 0 0 1-.1 1.852 1.982 1.982 0 0 1-.327.952.753.753 0 0 1-.617.282 1.118 1.118 0 0 1-.556-.142 1.064 1.064 0 0 1-.414-.422v-6.139a1.522 1.522 0 0 1 .424-.723.933.933 0 0 1 .652-.282.657.657 0 0 1 .573.29 2.225 2.225 0 0 1 .282.979 18.314 18.314 0 0 1 .079 1.958v1.4ZM103.281 34.977a14.625 14.625 0 0 0 .061 1.562 1.483 1.483 0 0 0 .256.76.729.729 0 0 0 .6.238.755.755 0 0 0 .749-.424 3.674 3.674 0 0 0 .221-1.411l2.1.124a3.186 3.186 0 0 1 .018.388 2.87 2.87 0 0 1-.82 2.24 3.342 3.342 0 0 1-2.319.741 2.758 2.758 0 0 1-2.522-1.129 6.606 6.606 0 0 1-.723-3.493v-1.891a6.55 6.55 0 0 1 .749-3.556 2.857 2.857 0 0 1 2.567-1.118 3.385 3.385 0 0 1 1.923.459 2.465 2.465 0 0 1 .944 1.43 10.312 10.312 0 0 1 .274 2.683v1.852h-4.078Zm.309-5.1a1.493 1.493 0 0 0-.248.749 14.861 14.861 0 0 0-.061 1.582v.776h1.781v-.776a13.185 13.185 0 0 0-.071-1.58 1.51 1.51 0 0 0-.256-.76.71.71 0 0 0-.573-.221.7.7 0 0 0-.57.233Z" />
      </g>
    </motion.svg>
  );
};
export default MonthlyActiveUsersMainInfluencer;
