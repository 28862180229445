import { useMemo } from 'react';
import orderBy from 'lodash/orderBy';

const GENDERS = {
  M: 'Male',
  F: 'Female',
  U: 'Other',
};

const useFormatInstaAgeGenderData = (audienceByAgeGroupAndGender = {}) => {
  const formattedData = useMemo(() => {
    let total = 0;
    const ageGroupObj = {};
    const genderObj = {};

    if (audienceByAgeGroupAndGender) {
      Object.keys(audienceByAgeGroupAndGender).forEach((key) => {
        const value = Number(audienceByAgeGroupAndGender[key]);
        const [gender, ageGroup] = key.split('.');

        total += value;

        if (ageGroupObj[ageGroup]) {
          ageGroupObj[ageGroup] += value;
        } else {
          ageGroupObj[ageGroup] = value;
        }

        if (genderObj[GENDERS[gender]]) {
          genderObj[GENDERS[gender]] += value;
        } else {
          genderObj[GENDERS[gender]] = value;
        }
      });
    }

    const audienceByAgeGroup = Object.keys(ageGroupObj)?.map((key) => ({
      group: key,
      value: ageGroupObj[key],
      percentage: Number(((ageGroupObj[key] * 100) / total).toFixed(2)),
    }));

    const audienceByGender = Object.keys(genderObj)?.map((key) => ({
      gender: key,
      value: genderObj[key],
      percentage: Number(((genderObj[key] * 100) / total).toFixed(2)),
    }));

    return {
      audienceByAgeGroup: orderBy(audienceByAgeGroup, ['group'], ['asc']),
      audienceByGender: orderBy(audienceByGender, ['gender'], ['asc']),
    };
  }, [audienceByAgeGroupAndGender]);

  return formattedData;
};

export default useFormatInstaAgeGenderData;
