import React, { useMemo } from 'react';
import { useSpinDelay } from 'spin-delay';
import { Link, Outlet, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getInfluencerDetails } from 'api/brand/search';
import { LoaderWithWrapper } from 'components/common/Loader';
import Menu from 'components/common/Menu';
import { useDialog } from 'stores/dialog';
import { useCreateCampaign } from 'stores/create-campaign';
import routes from 'constants/routes';
import { DIALOGS } from 'constants/config/dialog-names';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import { NavItem } from 'components/common/inf-profile';
import { formatNumber } from 'utils';
import {
  locationDownIcon,
  logoImageNew,
  locationIcon,
  instaSvg,
  twitterSvg,
  facebookSvg,
  ytIcon,
  whiteAddIcon,
  materialDropDown,
  copyText,
  contactIcon,
  pinkDropIcon,
  indianRupeeSign,
  profileSummary,
  blueTickIcon,
} from 'constants/images';
import {
  FACEBOOK_URL,
  INSTA_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from 'constants/config/social-urls';
import { checkKeys } from 'utils/check-keys';
import { uinqueCategories } from './formatData/sortData';
import { prefetchInfluencers } from '../InfluencerList';

const InfluencerProfile = () => {
  const { id: influencerId } = useParams();
  const setDialog = useDialog((state) => state.setDialog);
  const setInfsForCampaign = useCreateCampaign((state) => state.setFormData);

  const { data: influencer, isLoading } = useQuery({
    queryKey: ['brand-influencer-details', influencerId],
    queryFn: () => getInfluencerDetails({ influencerId }),
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  const instagramProfileUrl = influencer?.instagramProfile?.profileUrl;
  const youtubeProfileUrl = influencer?.youtubeProfile?.profileUrl;
  const twitterProfileUrl = influencer?.twitterProfile?.profileUrl;
  const facebookProfileUrl = influencer?.facebookProfile?.profileUrl;
  const instagramUsername = influencer?.instagramProfile?.username;
  const youtubeUsername = influencer?.youtubeProfile?.username;
  const twitterUsername = influencer?.twitterProfile?.username;
  const facebookUsername = influencer?.facebookProfile?.username;

  const onAddToNewCampaign = () => {
    const platforms = [];

    if (influencer.is_instagram) {
      platforms.push('instagram');
    }
    if (influencer.is_facebook) {
      platforms.push('facebook');
    }
    if (influencer.is_twitter) {
      platforms.push('twitter');
    }
    if (influencer.is_youtube) {
      platforms.push('youtube');
    }

    setInfsForCampaign({
      influencers: [
        {
          platforms,
          influencerId: influencer.id,
          influencerProfile: influencer.profile_image,
          influencerName: influencer.full_name,
          deliverables: [],
        },
      ],
    });
    setDialog(DIALOGS.CREATE_CAMPAIGN);
  };

  const onAddToExistingCampaign = () => {
    setDialog(DIALOGS.ADD_INF_TO_EXISTING_CAMPAIGN, {
      influencers: [influencer],
    });
  };

  const onAddToCustomList = () => {
    setDialog(DIALOGS.ADD_INF_TO_CUSTOM_LIST, {
      influencerIds: [influencer.id],
    });
  };

  const onViewCostOverview = () => {
    setDialog(DIALOGS.BRAND_INF_COST_OVERVIEW, {
      influencerId: influencer.id,
    });
  };

  const {
    profile_image: profileImage,
    full_name: name,
    city,
    influencer_categories: categories,
    is_registered: isRegistered,
  } = influencer || {};

  const profileImageToShow = profileImage;

  const categoriesInf = uinqueCategories(categories || []);

  if (isLoading || delayedIsLoading) {
    return <LoaderWithWrapper show={delayedIsLoading} />;
  }

  return (
    <div className={styles.influeProfileContainer}>
      <div className={styles.campignBreadcrumbs}>
        <Link
          to={routes.BRAND_SEARCH_INF_LIST}
          className={styles.navTxt}
          onMouseEnter={() => prefetchInfluencers()}
        >
          Influencer List
        </Link>
        <img className={styles.rightArrow} src={locationDownIcon} alt="" />
        <span className={styles.navSecndTxt}>{name}</span>
      </div>
      <div className={styles.innrProfileAnalysis}>
        <div className={styles.profileLeftContainer}>
          <div className="influencerHeading">
            <h2 className={styles.heading}>Influencer Profile</h2>
          </div>
          <div className={styles.influencerBriefContent}>
            <div className={styles.infInnerBrief}>
              <div className={styles.profileImg}>
                <img
                  className={styles.ellipseImg}
                  src={profileImageToShow}
                  alt=""
                />
                {/* <div
                  className={styles.subBlock1}
                  style={{ bottom: '-22px', left: '34px' }}
                >
                  <p className={styles.scoreTxt}>Score</p>
                  <div className={styles.subInnrBlock}>
                    <img src={logoImageNew} alt="" width="15" />
                    <span className={styles.spanTxt}>
                      {formatNumber(influencer?.rc_score)}
                    </span>
                  </div>
                </div> */}
              </div>
              <div>
                <h2 className={styles.infName}>
                  {name}{' '}
                  {isRegistered ? (
                    <img
                      src={blueTickIcon}
                      alt="verified"
                      style={{ display: 'inline-block' }}
                    />
                  ) : null}
                </h2>
              </div>
              {checkKeys(influencer, 'docusign_response') && city && (
                <div className={styles.userLocation} style={{ margin: '0' }}>
                  <img src={locationIcon} alt="" />
                  <p className={styles.locTxt}>{city}</p>
                </div>
              )}
              {/* <div>
                <p className={styles.passionTxt}>living my dream 🌸</p>
              </div> */}
              <div
                className={styles.userSocialPlaylist}
                style={{ margin: '0' }}
              >
                {instagramProfileUrl && (
                  <a
                    href={instagramProfileUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className={styles.socialImg} src={instaSvg} alt="" />
                  </a>
                )}
                {twitterProfileUrl && (
                  <a href={twitterProfileUrl} target="_blank" rel="noreferrer">
                    <img className={styles.socialImg} src={twitterSvg} alt="" />
                  </a>
                )}
                {facebookProfileUrl && (
                  <a href={facebookProfileUrl} target="_blank" rel="noreferrer">
                    <img
                      className={styles.socialImg}
                      src={facebookSvg}
                      alt=""
                    />
                  </a>
                )}
                {youtubeProfileUrl && (
                  <a href={youtubeProfileUrl} target="_blank" rel="noreferrer">
                    <img className={styles.socialImg} src={ytIcon} alt="" />
                  </a>
                )}
              </div>
              <div className={styles.profileCategory}>
                {categoriesInf?.map((category, ind) => (
                  <span className={styles.categoryTxt} key={ind}>
                    {category}
                  </span>
                ))}
              </div>
              <div className={styles.addBtn}>
                <Menu
                  style={{ width: '100%' }}
                  renderButton={() => (
                    <Menu.Button
                      type="button"
                      className={styles.addCampaignButton}
                      disabled={!isRegistered}
                    >
                      <img src={whiteAddIcon} alt="" />
                      Add to Campaign
                      <img src={materialDropDown} alt="" />
                    </Menu.Button>
                  )}
                  floatOptions={{
                    placement: 'bottom-start',
                    flip: true,
                    autoPlacement: false,
                    offset: 4,
                  }}
                >
                  <Menu.Item onClick={onAddToNewCampaign}>
                    Add To New Campaign
                  </Menu.Item>
                  <Menu.Item onClick={onAddToExistingCampaign}>
                    Add To Existing Campaign
                  </Menu.Item>
                </Menu>

                <button
                  type="button"
                  className={styles.profileBlock3}
                  onClick={onAddToCustomList}
                >
                  <img src={copyText} alt="" />
                </button>
              </div>
              <div className={styles.connectBtns}>
                {checkKeys(influencer, 'docusign_response') && (
                  <>
                    {/* <button type="button" className={styles.commonBtn} disabled>
                      <img src={contactIcon} alt="" /> Contact
                      <img src={pinkDropIcon} alt="" />
                    </button> */}
                    {/* <button
                      type="button"
                      className={styles.commonBtn}
                      onClick={onViewCostOverview}
                      disabled={!isRegistered}
                    >
                      <img src={indianRupeeSign} alt="" /> Cost
                      <img src={pinkDropIcon} alt="" />
                    </button> */}
                  </>
                )}
              </div>
            </div>
            <div className="summaryTabs">
              <nav className={styles.summaryTabList}>
                <NavItem
                  to=""
                  title="Profile Summary"
                  iconUrl={profileSummary}
                  end
                />
                {influencer?.is_instagram && (
                  <NavItem
                    to="instagram"
                    title={instagramUsername || name}
                    iconUrl={instaSvg}
                  />
                )}
                {influencer?.is_youtube && (
                  <NavItem
                    to="youtube"
                    title={youtubeUsername || name}
                    iconUrl={ytIcon}
                  />
                )}
                {influencer?.is_twitter && (
                  <NavItem
                    to="twitter"
                    title={twitterUsername || name}
                    iconUrl={twitterSvg}
                  />
                )}
                {influencer?.is_facebook && (
                  <NavItem
                    to="facebook"
                    title={facebookUsername || name}
                    iconUrl={facebookSvg}
                  />
                )}
              </nav>
            </div>
          </div>
        </div>
        <div className={styles.profileRightSummary}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default InfluencerProfile;
