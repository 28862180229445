import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from 'assets/scss/pages/search.module.scss';
import { transparentBg, searchImg } from 'constants/images';
import clsx from 'clsx';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import routes from 'constants/routes';
import { Float } from '@headlessui-float/react';
import { Popover } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import SearchPropoverContent from 'components/brand-dashboard/SearchPropoverContent';
import { prefetchInfluencers } from './InfluencerList';

const NavItem = ({ children, ...props }) => (
  <NavLink
    className={({ isActive }) =>
      clsx({
        [styles.influencerCateBtn]: true,
        [styles.whiteActiveBtn]: isActive,
      })
    }
    {...props}
  >
    {children}
  </NavLink>
);

NavItem.propTypes = {
  children: PropTypes.node,
};

const Search = () => {
  const comboboxButtonRef = useRef();
  const navigate = useNavigate();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: { search: '' },
  });

  const onSubmit = ({ search }) => {
    if (search) {
      reset();
      navigate(routes.BRAND_SEARCH_INF_LIST, { state: { search } });
      comboboxButtonRef.current?.click();
    }
  };

  return (
    <section>
      <div className="dashboard">
        <div className="dashboardSearch">
          <div className={styles.dashboardTop}>
            <img className={styles.transBg} src={transparentBg} alt="" />
            <div className={styles.searchBox}>
              <Popover>
                <Float portal offset={2} adaptiveWidth>
                  <div className={styles.inputSearch}>
                    <Popover.Button ref={comboboxButtonRef} hidden />
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                      <input
                        type="text"
                        className={styles.search}
                        placeholder="Type to search all Influencers"
                        autoComplete="off"
                        {...register('search')}
                        onFocus={() => comboboxButtonRef.current?.click()}
                      />
                    </form>
                    <div className={styles.searchImg}>
                      <img src={searchImg} alt="" />
                    </div>
                  </div>

                  <Popover.Panel>
                    <SearchPropoverContent />
                  </Popover.Panel>
                </Float>
              </Popover>
            </div>
            <div className={styles.categoryButtons}>
              <NavItem to="" end>
                Influencer Categories
              </NavItem>
              <NavItem
                to={routes.BRAND_SEARCH_INF_LIST}
                onMouseEnter={() => prefetchInfluencers()}
              >
                Influencer List
              </NavItem>
              <NavItem to={routes.BRAND_SEARCH_CUSTOM_LIST}>
                Custom List
              </NavItem>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default Search;
