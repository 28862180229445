export const ALPHABETS_REGX = /^[aA-zZ\s]+$/;
export const AGENCY_NAME_REGX = /^(([a-zA-Z]+\s?\b){2,})|(\S{4})$/;
export const INDIAN_MOBILE_NO_REGEX = /^[6-9]\d{9}$/;
export const PASSWORD_REGX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
export const PINCODE_REGX = /^[1-9][0-9]{5}$/;
export const GST_NO_REGX = /^[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
export const PAN_CARD_NO_REGX = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
export const TIN_NO_REGX = /^(?:\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/;

export const CARD_NUM_REGX = /^[0-9 ]{8,19}$/;
export const CARD_EXPIRY_REGX = /^(0[1-9]|1[0-2])\/?(20[0-9]{2})$/;
export const CARD_CVV_REGX = /^[0-9]{3,4}$/;

export const VIDEO_EXTENTIONS_REGX = /^mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg$/i;
