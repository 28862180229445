import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';

import { queryClient } from 'App';
import { addAddress, getStateList } from 'api/influencer/profile';

import Footer from 'components/onboarding/Footer';
import Button from 'components/common/Button';
import Select from 'components/common/form/Select';
import ErrorMessage from 'components/common/form/ErrorMessage';

import routes from 'constants/routes';
import { ADDRESS_TYPES } from 'constants/config';
import { ADDRESS_FORM_SCHEMA } from 'constants/schema';
import { logoImageBlackText } from 'constants/images';
import styles from 'assets/scss/pages/onboarding.module.scss';

const defaultValues = {
  addressLine1: '',
  landmark: '',
  pincode: '',
  state: '',
  city: '',
  addressType: '',
};

const SignUpAddAddress = () => {
  const navigate = useNavigate();

  const { data: states } = useQuery({
    queryKey: ['influencer-states'],
    queryFn: () => getStateList(),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ADDRESS_FORM_SCHEMA),
    defaultValues,
  });

  const selectedState = watch('state');

  const addressMutation = useMutation({
    mutationFn: ({
      addressLine1,
      landmark,
      pincode,
      state,
      city,
      addressType,
    }) => {
      const payload = {
        address_line_1: addressLine1,
        pincode,
        state,
        city,
        landmark,
        address_type: addressType,
      };
      return addAddress(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['influencer-profile'] });
      navigate(routes.INF_SIGNUP_ADD_SOCIAL);
    },
  });

  const filteredCities = useMemo(
    () =>
      states
        ?.reduce(
          (accumulator, state) =>
            selectedState === state?.name
              ? [...accumulator, ...state.cities]
              : accumulator,
          []
        )
        .sort()
        .map((city) => ({ name: city })),
    [states, selectedState]
  );

  return (
    <div className={styles.signupInnerContent}>
      <div className={clsx(styles.signupItems, styles.profile)}>
        <div>
          <div>
            <Link to={routes.HOME}>
              <img src={logoImageBlackText} alt="" width={140} />
            </Link>
          </div>
          <h1>Profile Completion</h1>
          <p>Please provide your Address details.</p>
          <form
            className={styles.signupFormItems}
            onSubmit={handleSubmit(addressMutation.mutate)}
          >
            <div>
              <Controller
                render={({ field }) => (
                  <Select
                    placeholder="Address Type"
                    options={ADDRESS_TYPES}
                    {...field}
                  />
                )}
                control={control}
                name="addressType"
              />
              <ErrorMessage error={errors?.addressType} />
            </div>
            <div>
              <textarea
                maxLength="75"
                placeholder="Apartment, Suite, Unit, Floor"
                {...register('addressLine1')}
              />
              <ErrorMessage error={errors?.addressLine1} />
            </div>
            <div>
              <input
                maxLength="34"
                type="text"
                placeholder="Landmark"
                {...register('landmark')}
              />
              <ErrorMessage error={errors?.landmark} />
            </div>
            <div className={styles.addressFields}>
              <div>
                <input
                  type="text"
                  placeholder="Pin Code"
                  maxLength="6"
                  {...register('pincode')}
                />
                <ErrorMessage error={errors?.pincode} />
              </div>
              <div className={styles.select}>
                <Controller
                  render={({ field }) => (
                    <Select
                      placeholder="State"
                      options={states}
                      labelSelector="name"
                      valueSelector="name"
                      onChange={(...params) => {
                        setValue('city', '');
                        field.onChange(...params);
                      }}
                      {...field}
                    />
                  )}
                  control={control}
                  name="state"
                />
                <ErrorMessage error={errors?.state} />
              </div>
            </div>
            <div>
              <Controller
                render={({ field }) => (
                  <Select
                    placeholder="City"
                    options={filteredCities}
                    labelSelector="name"
                    valueSelector="name"
                    {...field}
                  />
                )}
                control={control}
                name="city"
              />
              <ErrorMessage error={errors?.city} />
            </div>

            <Button
              type="submit"
              size="large"
              isLoading={addressMutation.isLoading}
              fullWidth
            >
              Next
            </Button>
          </form>
        </div>
      </div>
      <Footer type="influencer" />
    </div>
  );
};

export default SignUpAddAddress;
