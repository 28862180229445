import { baseQuery } from './baseQuery';

const apiVersion = process.env.REACT_APP_API_VERSION;

export const getCampaigns = (params) =>
  baseQuery({
    url: `/influencer/${apiVersion}/campaigns`,
    method: 'GET',
    params,
  });

export const downloadCampaign = (params) =>
  baseQuery({
    url: `/influencer/${apiVersion}/quotation-pdf`,
    method: 'GET',
    params,
    responseType: 'blob',
  });

export const downloadContract = () =>
  baseQuery({
    url: `/influencer/${apiVersion}/signup-contract-pdf`,
    method: 'GET',
    responseType: 'blob',
  });

export const getCampaignDetails = ({ campaignId, ...params } = {}) =>
  baseQuery({
    url: `/influencer/${apiVersion}/campaigns/${campaignId}`,
    method: 'GET',
    params,
  });

export const getCampaignReports = (params) =>
  baseQuery({
    url: `/influencer/${apiVersion}/campaigns`,
    method: 'GET',
    params,
  });

export const getCampaignAnalytics = ({ campaignId, ...params } = {}) =>
  baseQuery({
    url: `/influencer/${apiVersion}/campaigns/${campaignId}/posts`,
    method: 'GET',
    params,
  });

export const getCampaignDeliverables = ({ campaignId, ...params } = {}) =>
  baseQuery({
    url: `/influencer/${apiVersion}/campaigns/${campaignId}/postsData`,
    method: 'GET',
    params,
  });

export const uploadCamapignDeliverable = ({ campaignId, body }) =>
  baseQuery({
    url: `/influencer/${apiVersion}/campaigns/${campaignId}/post`,
    method: 'POST',
    body,
  });

export const linkCamapignDeliverable = ({ campaignId, body }) =>
  baseQuery({
    url: `/influencer/${apiVersion}/campaigns/${campaignId}/link-import`,
    method: 'POST',
    body,
  });

export const getBrands = (params) =>
  baseQuery({
    url: `/influencer/${apiVersion}/campaigns/brands`,
    method: 'GET',
    params,
    transformResponse: (res) => {
      const brands =
        res?.data?.result?.map(({ brand_name: name }) => ({ name })) || [];
      return brands.filter(
        (brand, index) =>
          index === brands.findIndex((o) => brand.name === o.name)
      );
    },
  });

export const postSchedule = (body) =>
  baseQuery({
    url: `/influencer/${apiVersion}/post/upload`,
    method: 'POST',
    body,
  });

export const getCampaignDashboard = (params) =>
  baseQuery({
    url: `/influencer/${apiVersion}/influencer-dashboard`,
    method: 'GET',
    params,
    transformResponse: (res) => res?.data,
  });
