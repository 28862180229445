import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link, NavLink, Outlet } from 'react-router-dom';
import styles from 'assets/scss/pages/dashboard.module.scss';

import { logoImageNew } from 'constants/images';
import routes from 'constants/routes';

const DashboardLayout = ({ config, config2 }) => (
  <section>
    <nav className={styles.sidebar}>
      <div className={styles.sidebarLogo}>
        <Link to={routes.HOME}>
          <img src={logoImageNew} className={styles.navLogoImg} alt="Logo" />
        </Link>
      </div>
      <div className={styles.navbarList}>
        <ul>
          {config.map(({ title, path, icon: Icon, exact }) => (
            <li key={title}>
              <NavLink
                className={({ isActive }) =>
                  clsx({ [styles.navLinks]: true, [styles.active]: isActive })
                }
                to={path}
                end={exact}
                title={title}
              >
                <Icon className={styles.navImg} />
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div className={clsx(styles.navbarBtm, styles.navbarList)}>
        <ul>
          {config2.map(({ title, path, icon: Icon, exact }) => (
            <li key={title}>
              <NavLink
                className={({ isActive }) =>
                  clsx({ [styles.navLinks]: true, [styles.active]: isActive })
                }
                to={path}
                end={exact}
                title={title}
              >
                <Icon className={styles.navImg} />
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
    <div className={styles.pageContainer}>
      <Outlet />
    </div>
  </section>
);

DashboardLayout.propTypes = {
  config: PropTypes.array,
  config2: PropTypes.array,
};

export default DashboardLayout;
