import { create } from 'zustand';

const initialState = {
  formStep: 1,
  formData: {},
};

export const useCreateCampaign = create((set) => ({
  ...initialState,
  goToNextStep: () =>
    set((prev) => ({
      ...prev,
      formStep:
        prev.formStep < 3 && prev.formStep > 0
          ? prev.formStep + 1
          : prev.formStep,
    })),
  goToPrevStep: () =>
    set((prev) => ({
      ...prev,
      formStep: prev.formStep > 1 ? prev.formStep - 1 : prev.formStep,
    })),
  setFormStep: (newStep) =>
    set((prev) => ({
      ...prev,
      formStep: newStep <= 3 && newStep > 0 ? newStep : prev.formStep,
    })),
  setFormData: (data) =>
    set((prev) => ({ ...prev, formData: { ...prev.formData, ...data } })),
  reset: () => set(initialState),
}));
