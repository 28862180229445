import React from 'react';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Sector,
  Legend,
} from 'recharts';
import styles from 'assets/scss/pages/campaign.module.scss';

const data = [
  { id: 1, type: 'Positive Sentiments', value: 75, color: '#7F60FF' },
  { id: 2, type: 'Negative Sentiments', value: 25, color: '#DCD3FF' },
];

const AudienceLikeDislikeSentimentsPieChart = () => (
  <div
    className={styles.postingInsightBox}
    style={{ maxWidth: '521px', margin: '0px' }}
  >
    <div className={styles.postHeader}>
      <div>
        <h2 className={styles.insightHeading}>
          audience likes/dislikes sentiments
        </h2>
      </div>
    </div>
    <div className={styles.postBody}>
      <ResponsiveContainer width="100%" height={224}>
        <PieChart width={216} height={216}>
          <Pie
            activeIndex={0}
            activeShape={renderCustomizedLabelPieChart}
            dataKey="value"
            nameKey="type"
            data={data}
            innerRadius={50}
            outerRadius={100}
            fill="#7C087F"
            isAnimationActive={false}
            labelLine={false}
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry?.color}
                style={{ outline: 'none' }}
              />
            ))}
          </Pie>
          <Legend
            iconType="circle"
            iconSize={10}
            align="right"
            verticalAlign="middle"
            layout="vertical"
            formatter={renderLegendText}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  </div>
);

const renderLegendText = (value) => (
  <span
    style={{
      color: '#999E9D',
      fontSize: 12,
      letterSpacing: 1,
      fontWeight: 500,
      marginLeft: 8,
    }}
  >
    {value}
  </span>
);

const renderCustomizedLabelPieChart = (props) => {
  const {
    cx,
    cy,
    value,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  } = props;
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        textAnchor="middle"
      />
      <text
        textAnchor="middle"
        x={cx}
        y={cy}
        fill={fill}
        fontSize={18}
        fontWeight={600}
      >
        {value}%
      </text>
      <text
        textAnchor="middle"
        x={cx}
        y={cy + 18}
        fill="#7B8794"
        fontSize={12}
        fontWeight={500}
      >
        Positive
      </text>
    </g>
  );
};

export default AudienceLikeDislikeSentimentsPieChart;
