import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Sector } from 'recharts';
import PropTypes from 'prop-types';
import { formatNumber } from 'utils';

// const data = [
//   {
//     id: 4,
//     value: 74,
//     color: '#7C087F',
//   },
//   {
//     id: 3,
//     value: 26,
//     color: '#F1F1F5',
//   },
// ];

const CampaignPerformancePieChart = ({ cpm }) => (
  <div className="analyticsSection">
    <div className="header">
      <h2 className="title">campaign performance</h2>
      {/* <div className={styles.benchmarkSectionView}>
        <select className={styles.selctMenu}>
          <option value="value">Engagement Rate</option>
        </select>
      </div> */}
    </div>
    <div className="contentWrapper">
      <ResponsiveContainer width="100%" height={224}>
        <PieChart margin={{ top: 0, right: 0, bottom: -175, left: 0 }}>
          <Pie
            activeIndex={0}
            activeShape={renderCustomizedLabelPieChart}
            dataKey="value"
            data={cpm || []}
            innerRadius={90}
            outerRadius={150}
            fill="#7C087F"
            startAngle={180}
            endAngle={0}
            labelLine={false}
            isAnimationActive={false}
            stroke="none"
          >
            {cpm?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry?.color}
                style={{ outline: 'none' }}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  </div>
);

const renderCustomizedLabelPieChart = (props) => {
  const {
    cx,
    cy,
    value,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  } = props;
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        textAnchor="middle"
      />
      <text textAnchor="middle" x={cx} y={cy} fontSize={18} fontWeight={600}>
        {formatNumber(value)}
      </text>
    </g>
  );
};

CampaignPerformancePieChart.propTypes = {
  cpm: PropTypes.array,
};
export default CampaignPerformancePieChart;
