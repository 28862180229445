import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Dialog } from 'components/common/dialog';
import { queryClient } from 'App';
import {
  editProfile,
  getProfile,
  sendOtpEmailEdit,
} from 'api/influencer/profile';
import { INFLUENCER_PROFILE_SCHEMA } from 'constants/schema';
import styles from 'assets/scss/pages/popup.module.scss';
import { userPlaceholderImage } from 'constants/images';
import clsx from 'clsx';
import Input from 'components/common/edit-profile/Input';
import { ReactComponent as UploadIcon } from 'assets/images/upload_icon.svg';
import { toast } from 'react-toastify';
import ReactCountryFlag from 'react-country-flag';
import OTPInput from 'components/common/OtpInput';
import ErrorMessage from 'components/common/form/ErrorMessage';
import errorMessages from 'constants/config/error-messagess';
import useCountdownTimer from 'hooks/useCountdownTimer';
import { OTP_COUNTDOWN_SECONDS } from 'constants/config';
import { format } from 'date-fns';

const defaultValues = {
  profile: '',
  name: '',
  type: '',
  email: '',
  mobile: '',
  isEmailChanged: false,
  otp: '',
  dob: null,
};

const EditProfile = ({ open, close }) => {
  const [currentProfile, setCurrentProfile] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);

  const {
    timeLeft,
    setTimeLeft,
    formattedValue: formattedTimerValue,
  } = useCountdownTimer(0);

  const { data } = useQuery({
    queryKey: ['influencer-profile'],
    queryFn: () => getProfile(),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(INFLUENCER_PROFILE_SCHEMA),
    defaultValues,
  });

  const emailValue = watch('email');
  const isEmailChanged = watch('isEmailChanged');

  useEffect(() => {
    if (data) {
      setCurrentProfile(data?.profile_image);
      reset({
        city: data?.city ?? '',
        name: data?.full_name ?? '',
        type: data?.role ?? '',
        email: data?.email ?? '',
        mobile: data?.mobile ?? '',
        isEmailChanged: false,
        dob: data?.dob ? format(new Date(data.dob), 'yyyy-MM-dd') : '',
      });
    }
  }, [data, reset]);

  const editProfileMutation = useMutation({
    mutationFn: ({
      profile,
      name,
      email,
      mobile,
      type,
      otp,
      isEmailChanged,
      dob,
    }) => {
      console.log('🚀 ~ EditProfile ~ dob:', dob);
      const payload = new FormData();
      if (profile[0]) payload.append('profile_image', profile[0]);
      payload.append('mobile', mobile);
      payload.append('full_name', name);
      payload.append('role', type);
      if (dob) payload.append('dob', new Date(dob).toISOString());
      if (isEmailChanged) payload.append('email', email);
      if (isEmailChanged) payload.append('otp', otp);
      return editProfile(payload);
    },
    onSuccess: (res) => {
      toast.success(res?.message, { position: 'top-center' });
      queryClient.invalidateQueries({ queryKey: ['influencer-profile'] });
      reset();
      close();
    },
  });

  const sendOtpMutation = useMutation({
    mutationFn: async () => {
      const isEmailValid = await trigger('email');
      if (!isEmailValid) {
        setIsOtpSent(false);
        throw new Error(errorMessages.INVALID_EMAIL);
      }
      const email = getValues('email');
      return sendOtpEmailEdit({ email });
    },
    onSuccess: () => {
      setTimeLeft(OTP_COUNTDOWN_SECONDS);
      setIsOtpSent(true);
    },
  });

  useEffect(() => {
    if (emailValue && data?.email)
      if (emailValue !== data.email) {
        setValue('isEmailChanged', true);
      } else {
        setValue('isEmailChanged', false);
      }
  }, [emailValue, data, setValue]);

  const selectedProfile = watch('profile')?.[0];
  const profilePreview = selectedProfile
    ? URL.createObjectURL(selectedProfile)
    : currentProfile;

  return (
    <Dialog
      title="Edit Profile"
      open={open}
      close={close}
      size="591px"
      fullWidth
    >
      <form onSubmit={handleSubmit(editProfileMutation.mutate)}>
        <div className={styles.editFormWrapper}>
          {/* <div className={styles.panAndProfile}> */}
          <div className={styles.usrProfile}>
            <img
              className="usrImg"
              src={profilePreview || userPlaceholderImage}
              alt=""
            />
            <label className={styles.uploadIconImage}>
              <input type="file" {...register('profile')} hidden />
              <UploadIcon />
            </label>
          </div>
          <Input label="Full Name" error={errors?.name} {...register('name')} />
          <div className="editForm">
            <h3 className={styles.formLabel}>Type</h3>
            <div className={clsx(styles.inputRadio, styles.influencerType)}>
              <div className={styles.innrTnput}>
                <label className={styles.inputContainer}>
                  Influencer
                  <input
                    className={styles.innrSubIput}
                    type="radio"
                    value="influencer"
                    {...register('type')}
                  />
                  <span className={clsx(styles.radioField, styles.fieldInf)} />
                </label>
              </div>
              <div className={styles.innrTnput}>
                <label className={styles.inputContainer}>
                  Creator
                  <input
                    className={styles.innrSubIput}
                    type="radio"
                    value="creator"
                    {...register('type')}
                  />
                  <span className={clsx(styles.radioField, styles.fieldInf)} />
                </label>
              </div>
            </div>
          </div>
          <Input
            label="Email Id"
            error={errors?.email}
            endAdornment={
              isEmailChanged ? (
                <>
                  {isOtpSent && timeLeft ? (
                    <p className={styles.otpTimer}>{formattedTimerValue}</p>
                  ) : (
                    <button
                      type="button"
                      className={styles.verifyBtn}
                      disabled={sendOtpMutation.isLoading}
                      onClick={() => sendOtpMutation.mutate()}
                    >
                      {isOtpSent ? 'Resend OTP' : 'Verify'}
                    </button>
                  )}
                </>
              ) : null
            }
            {...register('email')}
          />
          {isOtpSent && isEmailChanged ? (
            <div className={styles.formGroup}>
              <p className={styles.formLabel}>
                We have sent a 6-digit verification code to your email. <br />
                Please enter the code below.
              </p>
              <Controller
                render={({ field }) => (
                  <OTPInput
                    length={6}
                    onChangeOTP={field.onChange}
                    className={styles.otpVerification}
                  />
                )}
                control={control}
                name="otp"
              />
              <ErrorMessage error={errors?.otp} />
            </div>
          ) : null}
          <Input
            startAdornment={
              <span>
                <ReactCountryFlag
                  countryCode="IN"
                  svg
                  style={{ fontSize: '1.3em' }}
                />
                {` (+91)`}
              </span>
            }
            label="Mobile Number"
            ismobile="mobile"
            type="tel"
            maxLength="10"
            error={errors?.mobile}
            {...register('mobile')}
          />

          <Input
            type="date"
            label="Date Of Birth"
            error={errors?.dob}
            max={format(new Date(), 'yyyy-MM-dd')}
            {...register('dob')}
          />
        </div>
        <div className={styles.editProfileContainer}>
          <div
            className={clsx(styles.campaignBtns, styles.nxtWrap)}
            style={{ marginTop: '16px' }}
          >
            <button type="button" className={styles.prevBtn} onClick={close}>
              Back
            </button>
            <button
              type="submit"
              style={{ width: '30%' }}
              className={styles.updateBtn}
              disabled={editProfileMutation.isLoading}
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

EditProfile.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default EditProfile;
