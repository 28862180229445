import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Footer from 'components/onboarding/Footer';
import Button from 'components/common/Button';

import { useInfluencerAuth } from 'stores/auth/influencer';
import { INFLUENCER_LOGIN_SCHEMA } from 'constants/schema';
import routes from 'constants/routes';
import { login } from 'api/influencer/auth';
import { eyeImage, eyeCloseImage, logoImageBlackText } from 'constants/images';
import styles from 'assets/scss/pages/onboarding.module.scss';

const defaultValues = { username: '', password: '' };

function Login() {
  const [showPassword, setShowPassword] = useState(false);

  const { setToken, setUserData } = useInfluencerAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(INFLUENCER_LOGIN_SCHEMA),
    defaultValues,
  });

  const loginMutation = useMutation({
    mutationFn: ({ username, password }) => {
      const payload = { password };
      if (username?.includes('@')) {
        payload.email = username;
      } else {
        payload.mobile = username;
      }
      return login(payload);
    },
    onSuccess: (data) => {
      setUserData({
        ...(data?.data?.influencer || {}),
        addresses: data?.data?.influencer?.addresses,
      });
      setToken(data?.data?.token);
      navigate(routes.INF_DASHBOARD);
    },
  });

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className={styles.signupInnerContent}>
      <div className={styles.signupItems}>
        <div>
          <div>
            <Link to={routes.HOME}>
              <img src={logoImageBlackText} alt="" width={140} />
            </Link>
          </div>
          <h1>Log In to your account</h1>
          <p>Enter details to login into your account</p>
          <form
            className={styles.signupFormItems}
            onSubmit={handleSubmit(loginMutation.mutate)}
          >
            <div>
              <input
                type="text"
                placeholder="Enter Your Email ID/Mobile Number"
                autoComplete="username"
                {...register('username')}
              />
              {errors?.username ? (
                <p className={styles.error}>{errors.username?.message}</p>
              ) : null}
            </div>
            <div>
              <div className={styles.passwordWrapper}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter Password"
                  autoComplete="current-password"
                  {...register('password')}
                />
                <button
                  className={styles.eyeImage}
                  type="button"
                  onClick={toggleShowPassword}
                  title={showPassword ? 'Hide password' : 'Show password'}
                >
                  <img src={showPassword ? eyeCloseImage : eyeImage} alt="" />
                </button>
              </div>
              {errors?.password ? (
                <p className={styles.error}>{errors.password?.message}</p>
              ) : null}
            </div>
            <div className={styles.forgotPassword}>
              <Link to={routes.INF_FORGOT_PASS}>Forgot Password</Link>
            </div>
            <Button
              type="submit"
              fullWidth
              size="large"
              isLoading={loginMutation.isLoading}
            >
              Login
            </Button>
          </form>
          <p className={styles.signupLink}>
            Don't have an account?{' '}
            <Link to={routes.INF_SIGNUP}>Sign Up here </Link>
          </p>
        </div>
      </div>
      <Footer type="influencer" />
    </div>
  );
}

export default Login;
