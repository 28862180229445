import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useInfluencerAuth } from 'stores/auth/influencer';
import routes from 'constants/routes';

function InfluencerGuestGuard({ children }) {
  const { token, user } = useInfluencerAuth();

  if (token && user) {
    return <Navigate to={routes.INF_DASHBOARD} replace />;
  }

  return children;
}

InfluencerGuestGuard.propTypes = {
  children: PropTypes.node,
};

export default InfluencerGuestGuard;
