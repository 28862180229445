import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import {
  logoImageNew,
  instaSvg,
  twitterSvg,
  facebookSvg,
  ytIcon,
} from 'constants/images';
import Tooltip from 'components/common/Tooltip';
import { formatNumber } from 'utils';
import { NoData } from 'components/common/NoData';

const ENGAGEMENT_RATE_FORMULA =
  'engagement_rate = (total_likes+total_comments)/total_media';

const instagramConfig = {
  icon: instaSvg,
  className: styles.instaMetricsCard,
  items: [
    {
      label: 'Followers',
      getValue: (data) => formatNumber(data.followers),
    },
    {
      label: 'Avg. Likes',
      getValue: (data) => formatNumber(data.avgLikes),
    },
    {
      label: 'Avg. Comments',
      getValue: (data) => formatNumber(data.avgComments),
    },
    {
      label: 'Engagement Rate',
      getValue: (data) => `${formatNumber(data.avgEngagementRate)}%`,
      info: ENGAGEMENT_RATE_FORMULA,
    },
  ],
};

const facebookConfig = {
  icon: facebookSvg,
  className: styles.facebookMetricsCard,
  items: [
    {
      label: 'Followers',
      getValue: (data) => formatNumber(data.followers),
    },
    {
      label: 'Avg. Likes',
      getValue: (data) => formatNumber(data.avgLikes),
    },
    {
      label: 'Avg. Comments',
      getValue: (data) => formatNumber(data.avgComments),
    },
    {
      label: 'Avg. Shares',
      getValue: (data) => formatNumber(data.avgShares),
    },
  ],
};

const youtubeConfig = {
  icon: ytIcon,
  className: styles.youtubeMetricsCard,
  items: [
    {
      label: 'Subscribers',
      getValue: (data) => formatNumber(data.followers),
    },
    // {
    //   label: 'Total Videos',
    //   getValue: (data) => formatNumber(data.totalMedia),
    // },
    {
      label: 'Avg. Likes',
      getValue: (data) => formatNumber(data.avgLikes),
    },
    {
      label: 'Avg. Comments',
      getValue: (data) => formatNumber(data.avgComments),
    },
    {
      label: 'Avg. Views',
      getValue: (data) => formatNumber(data.avgViews),
    },
    {
      label: 'Engagement Rate',
      getValue: (data) => `${formatNumber(data.avgEngagementRate)}%`,
      info: ENGAGEMENT_RATE_FORMULA,
    },
  ],
};

const twitterConfig = {
  icon: twitterSvg,
  className: styles.twitterMetricsCard,
  items: [
    {
      label: 'Followers',
      getValue: (data) => formatNumber(data.followers),
    },
    {
      label: 'Avg. Likes',
      getValue: (data) => formatNumber(data.avgLikes),
    },
    {
      label: 'Avg. Comments',
      getValue: (data) => formatNumber(data.avgComments),
    },
    {
      label: 'Avg. Retweets',
      getValue: (data) => formatNumber(data.avgRetweets),
    },
    {
      label: 'Engagement Rate',
      getValue: (data) => `${formatNumber(data.avgEngagementRate)}%`,
      info: ENGAGEMENT_RATE_FORMULA,
    },
  ],
};

const PlatformCard = ({ data }) => {
  const config = useMemo(() => {
    switch (data?.platform) {
      case 'facebook':
        return facebookConfig;
      case 'instagram':
        return instagramConfig;
      case 'youtube':
        return youtubeConfig;
      case 'twitter':
        return twitterConfig;
      default:
        return '';
    }
  }, [data?.platform]);

  if (!data) {
    return null;
  }

  return (
    <div className={clsx(styles.socialProfileMetricsCard, config.className)}>
      <div className={styles.metricsCardTop}>
        <a
          href={data.preofileUrl}
          target="_blank"
          rel="noreferrer"
          style={{ zIndex: 20 }}
        >
          <span className={styles.platformHandle}>
            <img className={styles.socialImg} src={config.icon} alt="" />
            <span className={styles.platformHandleName}>
              {data.username || data.name}
            </span>
          </span>
        </a>
        {/* <div className={styles.platformScore}>
          <img src={logoImageNew} alt="" width="15" />
          <p className={styles.platformScoreTxt}>
            {formatNumber(data.smincoScore)}
          </p>
        </div> */}
      </div>
      <div className={styles.metricsCardBottom}>
        <ul className={styles.platformMetrics}>
          {config?.items.map(({ label, getValue, info }) => (
            <li className={styles.platformMetricsItems} key={label}>
              <span className={styles.mtricsLabel}>
                {label} {info && <Tooltip label={info} />}
              </span>
              <p className={styles.metricsValue}>{getValue(data)}</p>
            </li>
          ))}
        </ul>
      </div>

      {!data.isDataFetched && (
        <div className={styles.profileOverlay}>
          <NoData
            style={{ height: '100%' }}
            title=""
            description="Profile is under review."
          />
        </div>
      )}
    </div>
  );
};

PlatformCard.propTypes = {
  data: PropTypes.object,
};

export default PlatformCard;
