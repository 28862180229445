import { DIALOGS } from 'constants/config/dialog-names';
import React from 'react';
import { useDialog } from 'stores/dialog';
import EditProfile from './EditProfile';
import EditAddress from './EditAddress';
import ScheduleCampagin from './ScheduleCampagin';
import ResetPassword from './ResetPassword';
import EditProfileLanguages from './EditProfileLanguages';
import UploadCampaignDeliverable from './UploadCampaignDeliverable';
import EditProfilePanDoc from './EditProfilePanDoc';
import EditProfileGstDoc from './EditProfileGstDoc';
import SchedulePost from './SchedulePost';
import InfBrandPostsDialog from './InfBrandPostsDialog';
import InfCostOverviewDialog from './InfCostOverviewDialog';
import EditRateCard from './EditRateCard';

function DialogSwicher() {
  const { dialog, dialogProps, clearDialog } = useDialog();
  if (dialog === DIALOGS.INF_EDIT_PROFILE) {
    return <EditProfile {...dialogProps} open={!!dialog} close={clearDialog} />;
  }

  if (dialog === DIALOGS.INF_EDIT_PROFILE_LANGUAGES) {
    return (
      <EditProfileLanguages
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }

  if (dialog === DIALOGS.INF_EDIT_ADDRESS) {
    return <EditAddress {...dialogProps} open={!!dialog} close={clearDialog} />;
  }
  if (dialog === DIALOGS.INF_EDIT_PAN_DOC) {
    return (
      <EditProfilePanDoc {...dialogProps} open={!!dialog} close={clearDialog} />
    );
  }
  if (dialog === DIALOGS.INF_EDIT_GST_DOC) {
    return (
      <EditProfileGstDoc {...dialogProps} open={!!dialog} close={clearDialog} />
    );
  }
  if (dialog === DIALOGS.INF_SCHEDULE_POST) {
    return (
      <SchedulePost {...dialogProps} open={!!dialog} close={clearDialog} />
    );
  }
  if (dialog === DIALOGS.INF_SCHEDULE_CAMPAGIN) {
    return (
      <ScheduleCampagin {...dialogProps} open={!!dialog} close={clearDialog} />
    );
  }

  if (dialog === DIALOGS.INF_UPLOAD_CAMPAGIN_DELIVERABLE) {
    return (
      <UploadCampaignDeliverable
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }

  if (dialog === DIALOGS.INF_BRAND_POSTS) {
    return (
      <InfBrandPostsDialog
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }

  if (dialog === DIALOGS.INF_COST_OVERVIEW) {
    return (
      <InfCostOverviewDialog
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }

  if (dialog === DIALOGS.INF_EDIT_RATE_CARD) {
    return (
      <EditRateCard {...dialogProps} open={!!dialog} close={clearDialog} />
    );
  }

  if (dialog === DIALOGS.INF_RESET_PASSWORD) {
    return (
      <ResetPassword {...dialogProps} open={!!dialog} close={clearDialog} />
    );
  }

  return null;
}

export default DialogSwicher;
