import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { ReactComponent as NoDataIcon } from 'assets/images/no-data-image.svg';
import styles from './error.module.scss';

const Error = ({ retry, errorMessage, children }) => (
  <div className={styles.errorContainerWrapper}>
    <div className={styles.errorContainer}>
      <NoDataIcon />
      <div className={styles.errorText}>
        {children || <p>{errorMessage}</p>}
      </div>
      {retry && (
        <Button onClick={retry} variant="text" size="small">
          Retry
        </Button>
      )}
    </div>
  </div>
);

Error.propTypes = {
  retry: PropTypes.func,
  errorMessage: PropTypes.string,
  children: PropTypes.node,
};

export default Error;
