import { baseQuery } from './baseQuery';

const apiVersion = process.env.REACT_APP_API_VERSION;

export const login = (body) =>
  baseQuery({
    url: `/auth/${apiVersion}/users/login`,
    method: 'POST',
    body,
  });

export const signup = (body) =>
  baseQuery({
    url: `/auth/${apiVersion}/users/send-otp`,
    method: 'POST',
    body,
  });

export const signupVerifyOtp = (body) =>
  baseQuery({
    url: `/auth/${apiVersion}/users/verifyOtp`,
    method: 'POST',
    body,
  });

export const signupResendOtp = (body) =>
  baseQuery({
    url: `/auth/${apiVersion}/users/resend-otp`,
    method: 'POST',
    body,
  });

export const signupProfileSetup = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/create-profile`,
    method: 'POST',
    body,
  });
