import {
  formatFacebookPost,
  formatInstagramPost,
  formatTwitterPost,
  formatYoutubePost,
} from 'utils';
import { baseQuery } from './baseQuery';

const apiVersion = process.env.REACT_APP_API_VERSION;

export const getBrands = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/brand`,
    method: 'GET',
    params,
  });

export const getBrandDetails = (brandId, params) =>
  baseQuery({
    url: `/users/${apiVersion}/brand/${brandId}`,
    method: 'GET',
    params,
    transformResponse: (res) => res?.data?.result,
  });

export const getBrandPosts = (brandId, params) =>
  baseQuery({
    url: `/users/${apiVersion}/brand/posts-list/${brandId}`,
    method: 'GET',
    params,
    transformResponse: (res) => {
      const result = {
        rows: res?.data?.result.data,
        count: res?.data?.result?.count,
      };
      switch (params.media_type.toLowerCase()) {
        case 'instagram':
          result.rows = result.rows?.map(formatInstagramPost);
          break;
        case 'youtube':
          result.rows = result.rows?.map(formatYoutubePost);
          break;
        case 'twitter':
          result.rows = result.rows?.map(formatTwitterPost);
          break;
        case 'facebook':
          result.rows = result.rows?.map(formatFacebookPost);
          break;
        default:
          break;
      }

      return result;
    },
  });

export const getBrandInfluencers = (brandId, params) =>
  baseQuery({
    url: `/users/${apiVersion}/brand/influencers-list/${brandId}`,
    method: 'GET',
    params,
    transformResponse: (res) => res?.data,
  });
