import React, { useEffect } from 'react';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBrandAuth } from 'stores/auth/brand';
import ErrorMessage from 'components/common/form/ErrorMessage';
import {
  dotImage,
  contactImage1,
  contactImage2,
  mailIcon,
  locIcon,
  dirIcon,
  phIcon,
  dotBg,
} from 'constants/images';
import styles from 'assets/scss/pages/static-content.module.scss';
import { CONTACT_US_SCHEMA } from 'constants/schema';
import { useMutation } from '@tanstack/react-query';
import { createContactRequest } from 'api/common/contactUs';
import { toast } from 'react-toastify';

const defaultValues = {
  name: '',
  company: '',
  email: '',
  mobile: '',
  message: '',
  type: 'guest',
};

const ContactUs = () => {
  const location = useLocation();
  const locationState = location.state;
  const navigate = useNavigate();

  const currentBrandUser = useBrandAuth((state) => state.user);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CONTACT_US_SCHEMA),
    defaultValues,
  });

  const contactUsMutation = useMutation({
    mutationFn: ({ name, company, email, mobile, message, type }) => {
      const payload = { name, email, mobile, user_type: type };
      if (company) payload.company_name = company;
      if (message) payload.message = message;

      return createContactRequest(payload);
    },
    onSuccess: (data) => {
      toast.success(data?.message);
      reset();
      navigate('/');
    },
  });

  useEffect(() => {
    if (locationState?.type === 'admin-support' && currentBrandUser) {
      const name =
        currentBrandUser.first_name && currentBrandUser.last_name
          ? `${currentBrandUser.first_name} ${currentBrandUser.last_name}`
          : '';

      reset({
        name,
        company: currentBrandUser.agency_name || '',
        email: currentBrandUser.email || '',
        mobile: currentBrandUser.mobile || '',
        message: 'Campaign admin support request.',
        type: 'brand',
      });
    }
    if (locationState?.type === 'brand') {
      reset({
        ...defaultValues,
        type: 'brand',
        message: locationState?.message || '',
      });
    }
    if (locationState?.type === 'influencer') {
      reset({
        ...defaultValues,
        type: 'influencer',
        message: locationState?.message || '',
      });
    }
  }, [currentBrandUser, locationState, reset]);

  return (
    <section>
      <div className={styles.headingContainer}>
        <h2 className={styles.headingTxt}>Contact Us</h2>
      </div>
      <div style={{ position: 'relative' }}>
        <div className={clsx(styles.dotBg, styles.backgr)}>
          <img className="dotImg" src={dotBg} alt="" />
        </div>
        <div className={styles.contactContainer}>
          <div className={styles.contctLeft}>
            <div className={styles.contactInnrLeft}>
              <img className={styles.cntctImage1} src={contactImage1} alt="" />

              <div>
                <h2 className={styles.contctHeading}>Get in touch</h2>
                <p className={styles.contctDetail}>
                  Ready to elevate your brand through influencer marketing?
                  We're here for you! Contact us today for a complimentary
                  consultation.
                </p>
              </div>
              <div className={styles.cntctInnrDetail}>
                <img src={mailIcon} alt="" />
                <div>
                  <h3 className={styles.subTitle}>Chat to us</h3>
                  <p className={styles.subDetail}>
                    <a href="mailto:contactus@sminco.in">support@sminco.in</a>
                  </p>
                </div>
              </div>
              <div className={styles.cntctInnrDetail}>
                <img src={locIcon} alt="" />
                <div>
                  <h3 className={styles.subTitle}>Office</h3>
                  <p className={styles.subDetail}>
                    Music Broadcast Ltd. 5th Floor, RNA Corporate Park, Off.
                    Western Express Highway, Kala Nagar, Bandra (East), Mumbai -
                    400051
                  </p>
                  <a
                    href="https://maps.app.goo.gl/bP1Le9qi2qDVY2EU7"
                    target="_blank"
                    type="button"
                    className={styles.dirBtn}
                    rel="noreferrer"
                  >
                    Direction <img src={dirIcon} alt="" />
                  </a>
                </div>
              </div>
              <div className={styles.cntctInnrDetail}>
                <img src={phIcon} alt="" />
                <div>
                  <h3 className={styles.subTitle}>Phone</h3>
                  <p className={styles.subDetail}>022-66969100</p>
                </div>
              </div>
              <img className={styles.cntctImage2} src={contactImage2} alt="" />
            </div>
          </div>
          <form
            className={styles.contctRight}
            onSubmit={handleSubmit(contactUsMutation.mutate)}
          >
            <div className={styles.contctForm}>
              <h2 className={styles.cntctFormTitle}>
                Fill in your inquiry below and send it to us
              </h2>
              <div className={styles.formControl}>
                <label className={styles.label}>Full Name</label>
                <div className={styles.inputWrapper}>
                  <input
                    className={styles.input}
                    type="text"
                    {...register('name')}
                  />
                </div>
                <ErrorMessage error={errors?.name} />
              </div>
              <div className={styles.formControl}>
                <label className={styles.label}>Company Name</label>
                <div className={styles.inputWrapper}>
                  <input
                    className={styles.input}
                    type="text"
                    {...register('company')}
                  />
                </div>
                <ErrorMessage error={errors?.company} />
              </div>
              <div className={styles.emCntainer}>
                <div className={styles.formControl}>
                  <label className={styles.label}>Email ID</label>
                  <div className={styles.inputWrapper}>
                    <input
                      className={styles.input}
                      type="text"
                      {...register('email')}
                    />
                  </div>
                  <ErrorMessage error={errors?.email} />
                </div>
                <div className={styles.formControl}>
                  <label className={styles.label}>Mobile Number</label>
                  <div className={styles.inputWrapper}>
                    <input
                      className={styles.input}
                      type="text"
                      {...register('mobile')}
                    />
                  </div>
                  <ErrorMessage error={errors?.mobile} />
                </div>
              </div>
              <div className={styles.formControl}>
                <label className={styles.label}>Message</label>
                <div className={styles.inputWrapper}>
                  <textarea
                    className={styles.input}
                    cols="5"
                    rows="5"
                    {...register('message')}
                  />
                </div>
                <ErrorMessage error={errors?.message} />
              </div>
            </div>
            <div className={clsx(styles.submitBtn, styles.mx)}>
              <button
                type="submit"
                className={clsx(styles.saveBtn, styles.buttonWidth)}
                disabled={contactUsMutation.isLoading}
              >
                <span className={styles.buttonTxt}>Submit</span>
              </button>
            </div>
          </form>
        </div>
        <div className={clsx(styles.dotBg2, styles.backgr2)}>
          <img className="dotImg" src={dotImage} alt="" />
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
