import React, { useId } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useBrandOnboardingForm } from 'stores/onboarding';

import {
  inhousetermImage,
  externalAgencyImage,
  bothImage,
} from 'constants/images';
import routes from 'constants/routes';
import styles from 'assets/scss/pages/onboarding.module.scss';

const CampaignTypeButton = React.forwardRef(({ icon, label, ...rest }, ref) => {
  const id = useId();
  return (
    <div>
      <input type="radio" id={id} ref={ref} {...rest} />
      <label htmlFor={id}>
        <img src={icon} alt="" />
        <span>{label}</span>
      </label>
    </div>
  );
});

CampaignTypeButton.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
};

function BrandCampaignTypeSelectForm() {
  const { goToNextStep, addFormData } = useBrandOnboardingForm();

  const onSelect = (e) => {
    addFormData({ campaignType: e.target.value });
    goToNextStep();
  };
  return (
    <>
      <h1>How do you currently run Influencer campaigns?</h1>
      <p>We'll streamline your experience accordingly.</p>
      <fieldset className={styles.userSelection}>
        <CampaignTypeButton
          icon={inhousetermImage}
          label="Direct"
          value="in_house_team"
          onChange={onSelect}
        />
        <CampaignTypeButton
          icon={externalAgencyImage}
          label="An External Agency"
          value="external_agency"
          onChange={onSelect}
        />
        <CampaignTypeButton
          icon={bothImage}
          label="Both"
          value="both"
          onChange={onSelect}
        />
      </fieldset>
      <p className={styles.signupLink}>
        Already have an account?{' '}
        <Link to={routes.BRAND_LOGIN}>Sign in here</Link>
      </p>
    </>
  );
}

export default BrandCampaignTypeSelectForm;
