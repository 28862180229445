import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Dialog } from 'components/common/dialog';
import { queryClient } from 'App';
import { editProfile, getProfile } from 'api/brand/profile';
import { BRAND_PROFILE_SCHEMA } from 'constants/schema';
import styles from 'assets/scss/pages/popup.module.scss';
import { toast } from 'react-toastify';
import ReactCountryFlag from 'react-country-flag';
import Input from 'components/common/edit-profile/Input';

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  ro_contact_person: '',
  ro_contact_number: '',
};

const EditProfile = ({ open, close }) => {
  const { data } = useQuery({
    queryKey: ['brand-profile'],
    queryFn: () => getProfile(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BRAND_PROFILE_SCHEMA),
    defaultValues,
  });

  const editProfileMutation = useMutation({
    mutationFn: ({
      firstName,
      lastName,
      mobile,
      ro_contact_person: roContactPerson,
      ro_contact_number: roContactNumber,
    }) => {
      const payload = new FormData();
      payload.append('first_name', firstName);
      payload.append('last_name', lastName);
      payload.append('mobile', mobile);
      if (roContactPerson) {
        payload.append('ro_contact_person', roContactPerson);
      }
      if (roContactNumber) {
        payload.append('ro_contact_number', roContactNumber);
      }

      return editProfile(payload);
    },
    onSuccess: (data) => {
      toast.success(data?.message, { position: 'top-center' });
      queryClient.invalidateQueries({ queryKey: ['brand-profile'] });
      reset();
      close();
    },
  });

  useEffect(() => {
    if (data?.data) {
      const {
        first_name: firstName,
        last_name: lastName,
        email,
        mobile,
        ro_contact_number: roContactNumber,
        ro_contact_person: roContactPerson,
      } = data?.data?.getProfile || {};
      reset({
        firstName: firstName || '',
        lastName: lastName || '',
        email: email || '',
        mobile: mobile || '',
        ro_contact_number: roContactNumber || '',
        ro_contact_person: roContactPerson || '',
      });
    }
  }, [data, reset]);

  return (
    <Dialog
      title="Edit Profile"
      open={open}
      close={close}
      size="591px"
      fullWidth
    >
      <form onSubmit={handleSubmit(editProfileMutation.mutate)}>
        <div className={styles.editFormWrapper}>
          <Input
            label="First Name"
            error={errors?.firstName}
            {...register('firstName')}
          />
          <Input
            label="Last Name"
            error={errors?.lastName}
            {...register('lastName')}
          />
          <Input
            label="Email ID"
            type="email"
            error={errors?.email}
            placeholder="johan@gmail.com"
            maxLength="75"
            readOnly
            {...register('email')}
          />
          <Input
            startAdornment={
              <span>
                <ReactCountryFlag
                  countryCode="IN"
                  svg
                  style={{ fontSize: '1.3em' }}
                />{' '}
                (+91)
              </span>
            }
            label="Mobile Number"
            ismobile="mobile"
            error={errors?.mobile}
            type="tel"
            maxLength="10"
            {...register('mobile')}
          />
          <Input
            label="Ro Contact Person"
            error={errors?.ro_contact_person}
            {...register('ro_contact_person')}
          />
          <Input
            startAdornment={
              <span>
                <ReactCountryFlag
                  countryCode="IN"
                  svg
                  style={{ fontSize: '1.3em' }}
                />{' '}
                (+91)
              </span>
            }
            label="Ro Contact Number"
            error={errors?.ro_contact_number}
            ismobile="mobile"
            type="tel"
            maxLength="10"
            {...register('ro_contact_number')}
          />
        </div>
        <div className={styles.editProfileContainer}>
          <div
            className={clsx(styles.campaignBtns, styles.nxtWrap)}
            style={{ marginTop: '16px' }}
          >
            <button type="button" className={styles.prevBtn} onClick={close}>
              Back
            </button>
            <button
              type="submit"
              style={{ width: '30%' }}
              className={styles.updateBtn}
              disabled={editProfileMutation.isLoading}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

EditProfile.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default EditProfile;
