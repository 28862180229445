import {
  INSTA_URL,
  FACEBOOK_URL,
  YOUTUBE_URL,
  TWITTER_URL,
} from 'constants/config/social-urls';
import {
  formatFacebookPost,
  formatInstagramPost,
  formatTwitterPost,
  formatYoutubePost,
} from './format-posts';

export const formatInstagramProfile = ({
  instagram_id: instagramId,
  name,
  username,
  profile_image_url: profilePicture,
  bio,
  followers_count: followers,
  avg_enagagement_rate: avgEngagementRate,
  media_stat: mediaStats,
  hashtags,
  brand_post: brandPosts,
  recent_post: recentPosts,
  top_post: topPosts,
  brands,
  image_stats: imageStats,
  reels_stats: reelsStats,
  video_stats: videoStats,
  ageGroup_and_gender: audienceByAgeGroupAndGenderRaw,
  city: audienceByCityRaw,
  country: audienceByCountryRaw,
  id,
  sminco_score: smincoScore,
  python_updated_at: lastDataUpdateDate,
}) => ({
  platform: 'instagram',
  name,
  username,
  instagramId,
  profileUrl: username ? INSTA_URL + username : null,
  profilePicture,
  bio,
  followers,
  avgLikes: mediaStats?.avg_likes,
  avgComments: mediaStats?.avg_comments,
  likeCommentRatio: mediaStats?.like_vs_comments,
  totalComments: mediaStats?.total_comments,
  totalLikes: mediaStats?.total_likes,
  totalMedia: mediaStats?.total_media,
  avgEngagementRate,
  postsStats: {
    total: imageStats?.total_image,
    avgLikes: imageStats?.avg_image_like,
    avgComments: imageStats?.avg_image_comment,
    likeCommentRatio: imageStats?.like_vs_comments,
  },
  reelsStats: {
    total: reelsStats?.total_reels,
    avgLikes: reelsStats?.avg_reels_like,
    avgComments: reelsStats?.avg_reels_comment,
    likeCommentRatio: reelsStats?.like_vs_comment,
  },
  videoStats: {
    total: videoStats?.total_videos,
    avgLikes: videoStats?.avg_video_like,
    avgComments: videoStats?.avg_video_comment,
    likeCommentRatio: videoStats?.like_vs_comments,
  },
  hashtags,
  brandPosts: brandPosts.map(formatInstagramPost),
  recentPosts: recentPosts.map(formatInstagramPost),
  topPosts: topPosts.map(formatInstagramPost),
  brands,
  audienceByAgeGroupAndGenderRaw,
  audienceByCityRaw,
  audienceByCountryRaw,
  id,
  smincoScore,
  isDataFetched: Boolean(lastDataUpdateDate),
});

export const formatFacebookProfile = ({
  facebook_id: facebookId,
  facebook_page_id: facebookPageId,
  name,
  username,
  profile_picture: profilePicture,
  followers_count: followers,
  bio,
  engagement_rate: avgEngagementRate,
  avg_likes: avgLikes,
  avg_comments: avgComments,
  avg_reactions: avgReactions,
  avg_shares: avgShares,
  like_vs_comments: likeCommentRatio,
  total_comments: totalComments,
  total_likes: totalLikes,
  total_reactions: totalReactions,
  total_shares: totalShares,
  total_media: totalMedia,
  hashtags,
  brand_post: brandPosts,
  recent_post: recentPosts,
  top_post: topPosts,
  brands,
  id,
  sminco_score: smincoScore,
  python_updated_at: lastDataUpdateDate,
}) => ({
  platform: 'facebook',
  name,
  username,
  facebookId,
  facebookPageId,
  profileUrl: username ? FACEBOOK_URL + username : null,
  profilePicture,
  bio,
  followers,
  avgEngagementRate,
  avgLikes,
  avgComments,
  avgReactions,
  avgShares,
  likeCommentRatio,
  totalComments,
  totalLikes,
  totalReactions,
  totalShares,
  totalMedia,
  postsStats: {
    total: totalMedia,
    avgLikes,
    avgComments,
    avgReactions,
    avgShares,
    likeCommentRatio,
    totalComments,
    totalLikes,
    totalReactions,
    totalShares,
  },
  hashtags,
  brandPosts: brandPosts.map(formatFacebookPost),
  recentPosts: recentPosts.map(formatFacebookPost),
  topPosts: topPosts.map(formatFacebookPost),
  brands,
  id,
  smincoScore,
  isDataFetched: Boolean(lastDataUpdateDate),
});

export const formatYoutubeProfile = ({
  channel_id: channelId,
  nickname,
  handle,
  profile_image_url: profilePicture,
  subscriber_count: followers,
  bio,
  avg_enagagement_rate: avgEngagementRate,
  avg_likes: avgLikes,
  avg_comments: avgComments,
  avg_views: avgViews,
  like_vs_comment: likeCommentRatio,
  total_likes: totalLikes,
  total_comments: totalComments,
  total_views: totalViews,
  total_videos: totalMedia,
  shorts_stats: shortsStats,
  video_stats: videoStats,
  hashtags,
  brand_post: brandPosts,
  recent_post: recentPosts,
  top_post: topPosts,
  brands,
  id,
  sminco_score: smincoScore,
  python_updated_at: lastDataUpdateDate,
}) => ({
  platform: 'youtube',
  name: nickname,
  username: handle,
  channelId,
  profileUrl: channelId ? `${YOUTUBE_URL}channel/${channelId}` : null,
  profilePicture,
  bio,
  followers,
  avgEngagementRate,
  avgLikes,
  avgComments,
  avgViews,
  likeCommentRatio,
  totalLikes,
  totalComments,
  totalViews,
  totalMedia,
  shortsStats: {
    total: shortsStats?.total_shorts,
    avgLikes: shortsStats?.avg_likes,
    avgComments: shortsStats?.avg_comments,
    avgViews: shortsStats?.avg_views,
    likeCommentRatio: shortsStats?.like_vs_comments,
    totalLikes: shortsStats?.total_likes,
    totalComments: shortsStats?.total_comments,
    totalViews: shortsStats?.total_views,
  },
  videoStats: {
    total: videoStats?.total_videos,
    avgLikes: videoStats?.avg_likes,
    avgComments: videoStats?.avg_comments,
    avgViews: videoStats?.avg_views,
    likeCommentRatio: videoStats?.like_vs_comments,
    totalLikes: videoStats?.total_likes,
    totalComments: videoStats?.total_comments,
    totalViews: videoStats?.total_views,
  },
  hashtags,
  brandPosts: brandPosts.map(formatYoutubePost),
  recentPosts: recentPosts.map(formatYoutubePost),
  topPosts: topPosts.map(formatYoutubePost),
  brands,
  id,
  smincoScore,
  isDataFetched: Boolean(lastDataUpdateDate),
});

export const formatTwitterProfile = ({
  twitter_id: twitterId,
  name,
  username,
  profile_picture: profilePicture,
  followers_count: followers,
  bio,
  engagement_rate: avgEngagementRate,
  avg_likes: avgLikes,
  avg_reply_count: avgComments,
  avg_retweet_count: avgRetweets,
  avg_impression_count: avgImpressions,
  media_count: totalMedia,
  total_likes: totalLikes,
  total_reply_count: totalComments,
  total_retweet_count: totalRetweets,
  total_impression_count: totalImpressions,
  hashtags,
  brand_post: brandPosts,
  recent_post: recentPosts,
  top_post: topPosts,
  brands,
  id,
  sminco_score: smincoScore,
  python_updated_at: lastDataUpdateDate,
}) => ({
  platform: 'twitter',
  name,
  username,
  twitterId,
  profileUrl: username ? TWITTER_URL + username : null,
  profilePicture,
  bio,
  followers,
  avgEngagementRate,
  avgLikes,
  avgComments,
  avgRetweets,
  avgImpressions,
  totalMedia,
  totalLikes,
  totalComments,
  totalRetweets,
  totalImpressions,
  postsStats: {
    total: totalMedia,
    avgLikes,
    avgComments,
    avgRetweets,
    avgImpressions,
    totalLikes,
    totalComments,
    totalRetweets,
    totalImpressions,
  },
  hashtags,
  brandPosts: brandPosts.map(formatTwitterPost),
  recentPosts: recentPosts.map(formatTwitterPost),
  topPosts: topPosts.map(formatTwitterPost),
  brands,
  id,
  smincoScore,
  isDataFetched: Boolean(lastDataUpdateDate),
});

export const formatInfSocialProfiles = (influencer) => {
  if (!influencer) return null;

  const facebookProfile = influencer.influencer_facebook
    ? formatFacebookProfile(influencer.influencer_facebook)
    : null;
  const instagramProfile = influencer.influencer_instagram
    ? formatInstagramProfile(influencer.influencer_instagram)
    : null;
  const youtubeProfile = influencer.influencer_youtube
    ? formatYoutubeProfile(influencer.influencer_youtube)
    : null;
  const twitterProfile = influencer.influencer_twitter
    ? formatTwitterProfile(influencer.influencer_twitter)
    : null;

  // delete influencer.influencer_facebook;
  // delete influencer.influencer_instagram;
  // delete influencer.influencer_youtube;
  // delete influencer.influencer_twitter;

  return {
    ...influencer,
    facebookProfile,
    instagramProfile,
    youtubeProfile,
    twitterProfile,
  };
};
