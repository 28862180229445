import React from 'react';
import PropTypes from 'prop-types';
import styles from 'assets/scss/pages/compare-influencer.module.scss';

const TotalSection = ({
  totalAccounts,
  totalAudience,
  avgEngagementRate,
  avgLikes,
  avgComments,
  // totalPostPerformance,
  // totalEstimatedReach,
  // totalEngagement,
  // totalAudienceFit,
}) => (
  <div className={styles.metricsCardBottom}>
    <ul className={styles.platformMetrics}>
      <li className={styles.platformMetricsItems}>
        <span className={styles.mtricsLabel}>Total Accounts</span>
        <p className={styles.metricsValue}>{totalAccounts}</p>
      </li>
      <li className={styles.platformMetricsItems}>
        <span className={styles.mtricsLabel}>Total Audience</span>
        <p className={styles.metricsValue}>{totalAudience}</p>
      </li>
      <li className={styles.platformMetricsItems}>
        <span className={styles.mtricsLabel}>Avg. Engagement Rate</span>
        <p className={styles.metricsValue}>{avgEngagementRate}</p>
      </li>
      <li className={styles.platformMetricsItems}>
        <span className={styles.mtricsLabel}>Avg. Likes </span>
        <p className={styles.metricsValue}>{avgLikes}</p>
      </li>
      <li className={styles.platformMetricsItems}>
        <span className={styles.mtricsLabel}>Avg. Comments </span>
        <p className={styles.metricsValue}>{avgComments}</p>
      </li>
      {/* <li className={styles.platformMetricsItems}>
        <span className={styles.mtricsLabel}>Post Performance</span>
        <p className={styles.metricsValue}>{totalPostPerformance}</p>
      </li>
      <li className={styles.platformMetricsItems}>
        <span className={styles.mtricsLabel}>Est. Total Reach</span>
        <p className={styles.metricsValue}>{totalEstimatedReach}</p>
      </li>
      <li className={styles.platformMetricsItems}>
        <span className={styles.mtricsLabel}>Total Engagement</span>
        <p className={styles.metricsValue}>{totalEngagement}</p>
      </li>
      <li className={styles.platformMetricsItems}>
        <span className={styles.mtricsLabel}>Audience Fit</span>
        <p className={styles.metricsValue}>{totalAudienceFit}</p>
      </li> */}
    </ul>
  </div>
);

TotalSection.propTypes = {
  totalAccounts: PropTypes.node,
  totalAudience: PropTypes.node,
  avgEngagementRate: PropTypes.node,
  avgLikes: PropTypes.node,
  avgComments: PropTypes.node,
  // totalPostPerformance: PropTypes.node,
  // totalEstimatedReach: PropTypes.node,
  // totalEngagement: PropTypes.node,
  // totalAudienceFit: PropTypes.node,
};

export default TotalSection;
