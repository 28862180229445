import React, { useRef, useState } from 'react';
// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import {
  nextArrowImg,
  prevArrowImg,
  slideImg1,
  slideImg2,
  slideImg3,
  slideImg4,
  slideImg5,
} from 'constants/images';

const CenterZoomSlider = () => {
  const [_, setInit] = useState();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const progressbarRef = useRef(null);
  return (
    <div>
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, A11y, Autoplay]}
        slidesPerView="auto"
        centeredSlides
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        pagination={{
          el: progressbarRef.current,
          type: 'progressbar',
        }}
        onInit={() => setInit(true)}
        className="custom-slider"
        autoplay={{ delay: 1500, disableOnInteraction: true }}
        loop
      >
        <SwiperSlide>
          <div className="slider-element">
            <img src={slideImg1} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-element">
            <img src={slideImg2} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-element">
            <img src={slideImg3} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-element">
            <img src={slideImg4} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-element">
            <img src={slideImg5} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-element">
            <img src={slideImg1} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-element">
            <img src={slideImg2} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-element">
            <img src={slideImg3} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-element">
            <img src={slideImg4} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-element">
            <img src={slideImg5} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-element">
            <img src={slideImg1} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-element">
            <img src={slideImg2} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-element">
            <img src={slideImg3} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-element">
            <img src={slideImg4} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-element">
            <img src={slideImg5} alt="" />
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="progressbar-wrapper">
        <div ref={progressbarRef} className="custom-progressbar" />

        <div className="navigation-wrapper">
          <button type="button" ref={prevRef}>
            <img src={prevArrowImg} alt="" width={80} height={80} />
          </button>
          <button type="button" ref={nextRef}>
            <img src={nextArrowImg} alt="" width={80} height={80} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CenterZoomSlider;
