import { instaSvg, facebookSvg, twitterSvg, ytIcon } from 'constants/images';

const PLATFORM_ICONS = {
  instagram: instaSvg,
  facebook: facebookSvg,
  twitter: twitterSvg,
  youtube: ytIcon,
};
Object.freeze(PLATFORM_ICONS);

export { PLATFORM_ICONS };
