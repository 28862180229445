import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Listbox } from '@headlessui/react';
import { COUNTRY_CODES } from 'constants/config/country-codes';
// import { ReactComponent as DropdownIcon } from 'assets/images/drop_down.svg';
import styles from './CountryCodeSelect.module.scss';

function CountryCodeSelect({ onChange, value }) {
  const [selectedCountry, setSelectedCountry] = useState(COUNTRY_CODES[0]);

  const onCountryChange = (country) => {
    setSelectedCountry(country);
    onChange(country.code);
  };

  useEffect(() => {
    COUNTRY_CODES.forEach((country) => {
      if (country.code === value) {
        setSelectedCountry(country);
      }
    });
  }, [value]);

  return (
    <Listbox
      value={selectedCountry}
      onChange={onCountryChange}
      className={styles.wrapper}
      disabled
      as="div"
    >
      <Listbox.Button className={styles.button}>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: selectedCountry.flagCode }} />
        {/* <DropdownIcon /> */}
        <span>({selectedCountry.code})</span>
      </Listbox.Button>
      <Listbox.Options className={styles.options}>
        {COUNTRY_CODES.map((country) => (
          <Listbox.Option
            key={country.code}
            value={country}
            className={({ active }) =>
              clsx({ [styles.option]: true, [styles.active]: active })
            }
          >
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: country.flagCode }} />{' '}
            {country.name} ({country.code})
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
}

CountryCodeSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default CountryCodeSelect;
