import React from 'react';
import { useSpinDelay } from 'spin-delay';
import { useQuery } from '@tanstack/react-query';
import { Avatar, AvatarGroup } from 'components/common/Avatar';
import { LoaderWithWrapper } from 'components/common/Loader';
import { getTrendingSubCategories } from 'api/brand/search';
import styles from 'assets/scss/pages/search.module.scss';
import { ytIcon, cardtransBg, musicianImg } from 'constants/images';

const TrendingSubCategories = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['brand-trending-subcategories'],
    queryFn: () => getTrendingSubCategories(),
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  const subcategories = data?.data?.trendingSubCategoriesListData;

  return (
    <div className="trendingsubCategory">
      <h2 className={styles.trendingTxt}>Trending Sub Categories</h2>
      <div className={styles.influencerProfileContainer}>
        {isLoading || delayedIsLoading ? (
          <LoaderWithWrapper show={delayedIsLoading} />
        ) : (
          subcategories
            ?.slice(0, 5)
            ?.map(
              ({
                id,
                subcategory_name: subCategoryName,
                influencer_categories: infCategories,
              }) => (
                <div className={styles.influencerProfile} key={id}>
                  <div className={styles.cardtransBg}>
                    <img src={cardtransBg} alt="" />
                  </div>
                  <div className="influencrImg">
                    <img
                      className={styles.influencrProfilePhoto}
                      src={musicianImg}
                      alt=""
                    />
                  </div>
                  <div className={styles.influencrDetail}>
                    <div className={styles.profileContent}>
                      <div className={styles.profileTop}>
                        <h3 className={styles.profileName}>
                          {subCategoryName}
                        </h3>
                      </div>
                    </div>
                    <div className={styles.profileActions}>
                      <div className="radiocityScore">
                        <div className={styles.scoreCount}>
                          {/* <span className={styles.scorePercent}>+36%</span> */}
                        </div>
                      </div>
                    </div>
                    <div className={styles.influencerProfileList}>
                      <AvatarGroup>
                        {infCategories?.map(({ influencer }) => (
                          <Avatar
                            src={influencer?.profile_image}
                            alt=""
                            key={influencer?.id}
                          />
                        ))}
                      </AvatarGroup>
                      <Avatar src={ytIcon} alt="" />
                    </div>
                  </div>
                </div>
              )
            )
        )}
      </div>
    </div>
  );
};

export default React.memo(TrendingSubCategories);
