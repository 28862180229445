import React from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import {
  deleteAccount,
  deactivateAccount,
  // editProfile,
} from 'api/influencer/profile';
// import { useInfluencerAuth } from 'stores/auth/influencer';
// import { queryClient } from 'App';
import { AlertDialog } from 'components/common/dialog';
import Tooltip from 'components/common/Tooltip';
// import Switch from 'components/common/form/Switch';
import { useDialog } from 'stores/dialog';
import { DIALOGS } from 'constants/config/dialog-names';
import styles from 'assets/scss/pages/profile-dashboard.module.scss';

const Setting = () => {
  // const [isEmailNotification, setIsEmailNotification] = useState(true);
  // const [isPlatformNotification, setIsPlatformNotification] = useState(true);
  // const user = useInfluencerAuth((state) => state.user);
  const setDialog = useDialog((state) => state.setDialog);

  const deactivateAccountMutation = useMutation({
    mutationFn: () => deactivateAccount(),
    onSuccess: (res) => toast.success(res?.message),
  });

  const deleteAccountMutation = useMutation({
    mutationFn: () => deleteAccount(),
    onSuccess: (res) => toast.success(res?.message),
  });

  // const editProfileMutation = useMutation({
  //   mutationFn: (body) => editProfile(body),
  //   onSuccess: () =>
  //     queryClient.invalidateQueries({ queryKey: ['influencer-profile'] }),
  // });

  // const onEmailNotificationChange = (newValue) => {
  //   setIsEmailNotification(newValue);
  //   editProfileMutation.mutate({ email_notification: newValue });
  // };
  // const onPlatformNotificationChange = (newValue) => {
  //   setIsPlatformNotification(newValue);
  //   editProfileMutation.mutate({ platform_notification: newValue });
  // };

  const onResetPassword = () => {
    setDialog(DIALOGS.INF_RESET_PASSWORD);
  };

  // useEffect(() => {
  //   setIsEmailNotification(user?.email_notification);
  //   setIsPlatformNotification(user?.platform_notification);
  // }, [user]);

  return (
    <div className={styles.settingCounter}>
      {/* <div className="settingContainer">
        <div className={styles.notifyHeader}>
          <h3 className={styles.notifyTxt}>Notification</h3>
          <Tooltip label="Manage Notification settings" />
        </div>
        <div className={styles.notificationBtns}>
          <h2 className={styles.preferenceTxt}>Email Notification</h2>
          <Switch
            checked={isEmailNotification}
            onChange={onEmailNotificationChange}
          />
        </div>
        <div className={styles.notificationBtns}>
          <h2 className={styles.preferenceTxt}>Platform Notification</h2>
          <Switch
            checked={isPlatformNotification}
            onChange={onPlatformNotificationChange}
          />
        </div>
      </div> */}
      <div className="settingContainer" style={{ marginTop: 10 }}>
        <div className={styles.notifyHeader}>
          <h3 className={styles.notifyTxt}>Reset Password</h3>
          <Tooltip label="You can update your password from here" />
        </div>
        <div className="deactiveMsg">
          <p className={styles.disableTxt}>
            You can update your password from here.
          </p>

          <button
            type="button"
            className={styles.disableBtn}
            onClick={onResetPassword}
          >
            Reset
          </button>
        </div>
      </div>
      <div className="settingContainer" style={{ marginTop: 50 }}>
        <div className={styles.notifyHeader}>
          <h3 className={styles.notifyTxt}>Deactivate Account</h3>
          <Tooltip label="This will make your account unusable for 4 months." />
        </div>
        <div className="deactiveMsg">
          <p className={styles.disableTxt}>
            This will make your account unusable for 4 months.
          </p>
          <AlertDialog
            render={({ open }) => (
              <button
                type="button"
                className={styles.disableBtn}
                onClick={open}
              >
                Deactivate
              </button>
            )}
            onAgree={deactivateAccountMutation.mutate}
            postion="top-center"
            agreeBtnText="Deactivate"
            disagreeBtnText="Cancel"
            title="Deactivate Account"
            description="Are you sure you want to deactivate your account? This will make your account unusable for 4 months."
          />
        </div>
      </div>
      <div className="settingContainer" style={{ marginTop: '50px' }}>
        <div className={styles.notifyHeader}>
          <h3 className={styles.notifyTxt}>Delete Account</h3>
          <Tooltip
            label="This will make your account permanently unusable. You will not be
            able to re-register the same user ID. And your data will be deleted permanently."
          />
        </div>
        <div className="deactiveMsg">
          <p className={styles.disableTxt}>
            This will make your account permanently unusable. You will not be
            able to re-register the same user ID.
          </p>
          <AlertDialog
            render={({ open }) => (
              <button
                type="button"
                className={styles.disableBtn}
                onClick={open}
              >
                Delete
              </button>
            )}
            postion="top-center"
            onAgree={deleteAccountMutation.mutate}
            agreeBtnText="Delete"
            disagreeBtnText="Cancel"
            title="Delete Account"
            description="Are you sure you want to delete your account? This will make your account permanently unusable. You will not be able to re-register the same user ID."
          />
        </div>
      </div>
    </div>
  );
};

export default Setting;
