import React from 'react';
import PropTypes from 'prop-types';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import Tooltip from 'components/common/Tooltip';

const EngagementCard = ({ icon, title, items = [] }) => (
  <div className={styles.innerDiv}>
    <div className={styles.enganmntViewContainer}>
      <img src={icon} alt="" />
      <p className={styles.timelineTxt}>{title}</p>
    </div>
    <ul className={styles.platformMetrics}>
      {items.map(({ name, value, info }) => (
        <li className={styles.platformMetricsItems} key={name}>
          <span className={styles.mtricsLabel}>
            {name} {info && <Tooltip label={info} />}
          </span>
          <p className={styles.metricsValue}>{value}</p>
        </li>
      ))}
    </ul>
  </div>
);

EngagementCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.node,
      info: PropTypes.string,
    })
  ).isRequired,
};

export default EngagementCard;
