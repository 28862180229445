import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import styles from 'assets/scss/pages/landing-homepage.module.scss';
import brandLogo1 from 'assets/images/home-strip-logos/brnad_logo1.png';
import brandLogo2 from 'assets/images/home-strip-logos/brnad_logo2.png';
import brandLogo3 from 'assets/images/home-strip-logos/brnad_logo3.png';
import brandLogo4 from 'assets/images/home-strip-logos/brnad_logo4.png';
import brandLogo5 from 'assets/images/home-strip-logos/brnad_logo5.png';
import brandLogo6 from 'assets/images/home-strip-logos/brnad_logo6.png';
import brandLogo7 from 'assets/images/home-strip-logos/brnad_logo7.png';
import brandLogo8 from 'assets/images/home-strip-logos/brnad_logo8.png';
import brandLogo9 from 'assets/images/home-strip-logos/brnad_logo9.png';
import brandLogo10 from 'assets/images/home-strip-logos/brnad_logo10.png';
import brandLogo11 from 'assets/images/home-strip-logos/brnad_logo11.png';
import brandLogo12 from 'assets/images/home-strip-logos/brnad_logo12.png';

const logos = [
  { id: 1, image: brandLogo1 },
  { id: 2, image: brandLogo2 },
  { id: 3, image: brandLogo3 },
  { id: 4, image: brandLogo4 },
  { id: 5, image: brandLogo5 },
  { id: 6, image: brandLogo6 },
  { id: 7, image: brandLogo7 },
  { id: 8, image: brandLogo8 },
  { id: 9, image: brandLogo9 },
  { id: 10, image: brandLogo10 },
  { id: 11, image: brandLogo11 },
  { id: 12, image: brandLogo12 },
  { id: 13, image: brandLogo1 },
  { id: 14, image: brandLogo2 },
  { id: 15, image: brandLogo3 },
  { id: 16, image: brandLogo4 },
  { id: 17, image: brandLogo5 },
  { id: 18, image: brandLogo6 },
  { id: 19, image: brandLogo7 },
  { id: 20, image: brandLogo8 },
  { id: 21, image: brandLogo9 },
  { id: 22, image: brandLogo10 },
  { id: 23, image: brandLogo11 },
  { id: 24, image: brandLogo12 },
  { id: 25, image: brandLogo1 },
  { id: 26, image: brandLogo2 },
  { id: 27, image: brandLogo3 },
  { id: 28, image: brandLogo4 },
  { id: 29, image: brandLogo5 },
  { id: 30, image: brandLogo6 },
  { id: 31, image: brandLogo7 },
  { id: 32, image: brandLogo8 },
  { id: 33, image: brandLogo9 },
  { id: 34, image: brandLogo10 },
  { id: 35, image: brandLogo11 },
  { id: 36, image: brandLogo12 },
];

const marqueeVariants = {
  animate: {
    x: [0, -3162],
    transition: {
      x: {
        repeat: Infinity,
        repeatType: 'loop',
        duration: 24,
        ease: 'linear',
      },
    },
  },
  notInView: {
    opacity: 1,
    transition: { duration: 1 },
  },
};

const SlidingLogosStrip = () => {
  const ref = useRef();
  const inView = useInView(ref);

  return (
    <div className={styles.ideaBanner} ref={ref}>
      <div className={styles.ideaBannerInnr}>
        <div className={styles.moverWrraper}>
          <motion.div
            className={styles.mover}
            variants={marqueeVariants}
            animate={inView ? 'animate' : 'notInView'}
          >
            <div className={styles.logosWrapper}>
              {logos.map(({ image, id }) => (
                <img src={image} height="54" alt="" key={id} />
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SlidingLogosStrip;
