import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Listbox } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { ReactComponent as DownIcon } from 'assets/images/location.svg';
import styles from './FilterDropdown.module.scss';

const FilterDropdown = React.forwardRef(
  (
    {
      label,
      options = [],
      onChange,
      value = '',
      labelSelector = 'label',
      valueSelector = 'value',
      disabled = false,
      startIconUrl,
      multiple = true,
      fullWidth = false,
      showZero = false,
      isVirtual = false,
      ...rest
    },
    ref
  ) => {
    const selected = useMemo(
      () =>
        multiple
          ? options?.filter((option) => value?.includes(option[valueSelector]))
          : options?.find((option) => value === option[valueSelector]) || '',
      [value, options, valueSelector, multiple]
    );

    const onValueChange = (newValues) =>
      multiple
        ? onChange(newValues.map((option) => option[valueSelector]))
        : onChange(newValues[valueSelector]);

    return (
      <Listbox
        value={selected}
        onChange={onValueChange}
        className={clsx({
          [styles.wrapper]: true,
          [styles.disabled]: disabled,
        })}
        ref={ref}
        as="div"
        disabled={disabled}
        multiple={multiple}
        {...rest}
      >
        {label ? (
          <Listbox.Label className={styles.label}>{label}</Listbox.Label>
        ) : null}
        <Float placement="bottom-start" offset={4} portal adaptiveWidth>
          <Listbox.Button
            className={styles.selectButton}
            style={{ width: fullWidth ? '100%' : 173 }}
          >
            <span className={styles.selectDropdown}>
              <span className={styles.selectItem}>
                {startIconUrl && (
                  <img src={startIconUrl} alt="" width="20" height="20" />
                )}
                <span className={styles.selectItemTxt}>
                  {multiple &&
                    (selected.length > 0 || showZero
                      ? `${selected.length} Selected`
                      : 'All')}

                  {!multiple && (selected ? selected[labelSelector] : 'All')}
                </span>
              </span>
            </span>
            <DownIcon height="18" width="18" />
          </Listbox.Button>
          <Listbox.Options className={clsx(!isVirtual && styles.selectOptions)}>
            {isVirtual ? (
              <VirtualizedList
                options={options}
                multiple={multiple}
                labelSelector={labelSelector}
                valueSelector={valueSelector}
              />
            ) : (
              options.map((option, index) => (
                <Listbox.Option
                  key={option[valueSelector] + index}
                  value={option}
                  className={({ selected, active, disabled }) =>
                    clsx({
                      [styles.selectOption]: true,
                      [styles.selectOptionActive]: selected,
                      [styles.active]: active || (selected && multiple),
                      [styles.disabled]: disabled,
                    })
                  }
                  title={option[labelSelector]}
                  disabled={option.disabled}
                >
                  {multiple ? <span className={styles.checkMark} /> : null}
                  <span className={styles.optionLabel}>
                    {option[labelSelector]}
                  </span>
                </Listbox.Option>
              ))
            )}
          </Listbox.Options>
        </Float>
      </Listbox>
    );
  }
);

FilterDropdown.propTypes = {
  label: PropTypes.node,
  startIconUrl: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  labelSelector: PropTypes.string,
  valueSelector: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  fullWidth: PropTypes.bool,
  showZero: PropTypes.bool,
  isVirtual: PropTypes.bool,
};

const VirtualizedList = ({
  options,
  labelSelector = 'label',
  valueSelector = 'value',
  multiple = true,
}) => {
  const parentRef = useRef();

  const rowVirtualizer = useVirtualizer({
    count: options?.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 28,
    overscan: 2,
  });

  return (
    <div ref={parentRef} className={styles.selectOptions}>
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => (
          <Listbox.Option
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`,
            }}
            key={options[virtualRow.index]?.[valueSelector] + virtualRow.index}
            value={options[virtualRow.index]}
            className={({ selected, active, disabled }) =>
              clsx({
                [styles.selectOption]: true,
                [styles.selectOptionActive]: selected,
                [styles.active]: active || (selected && multiple),
                [styles.disabled]: disabled,
              })
            }
            title={options[virtualRow.index]?.[labelSelector]}
            disabled={options[virtualRow.index]?.disabled}
          >
            {multiple ? <span className={styles.checkMark} /> : null}
            <span className={styles.optionLabel}>
              {options[virtualRow.index]?.[labelSelector]}
            </span>
          </Listbox.Option>
        ))}
      </div>
    </div>
  );
};

VirtualizedList.propTypes = {
  options: PropTypes.array,
  labelSelector: PropTypes.string,
  valueSelector: PropTypes.string,
  multiple: PropTypes.bool,
};

export default FilterDropdown;
