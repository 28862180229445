import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'components/common/dialog';
import styles from 'assets/scss/pages/popup.module.scss';
import clsx from 'clsx';

const QuestionnaireDialog = ({ open, close }) => (
  <Dialog
    title="For better understanding fill the questionnaire"
    open={open}
    close={close}
    fullWidth
  >
    <div className={styles.questionareContainer}>
      <div className={styles.objQuest}>
        <h3 className={styles.quesTitle}>What is your Objective?</h3>
        <input type="text" />
      </div>
      <div className={styles.objQuest}>
        <h3 className={styles.quesTitle}>Do you want promote your brand?</h3>
        <div className={styles.inputRadio}>
          <div className={styles.innrTnput}>
            <label className={styles.inputContainer}>
              Yes
              <input
                className={styles.innrSubIput}
                type="radio"
                checked="checked"
                name="tick"
              />
              <span className={styles.radioField} />
            </label>
          </div>
          <div className={styles.innrTnput}>
            <label className={styles.inputContainer}>
              No
              <input
                className={styles.innrSubIput}
                type="radio"
                checked="checked"
                name="tick"
              />
              <span className={styles.radioField} />
            </label>
          </div>
        </div>
      </div>
      <div className={styles.objQuest}>
        <h3 className={styles.quesTitle}>Are you a business/individual?</h3>
        <div className={styles.inputRadio}>
          <div className={styles.innrTnput}>
            <label className={styles.inputContainer}>
              Business
              <input
                className={styles.innrSubIput}
                type="radio"
                checked="checked"
                name="tick1"
              />
              <span className={styles.radioField} />
            </label>
          </div>
          <div className={styles.innrTnput}>
            <label className={styles.inputContainer}>
              Individual
              <input
                className={styles.innrSubIput}
                type="radio"
                checked="checked"
                name="tick1"
              />
              <span className={styles.radioField} />
            </label>
          </div>
        </div>
      </div>
      {/* <div className={styles.objQuest}>
        <input
          type="checkbox"
          className="checkBox"
          title="Toggle All Rows Selected"
          checked=""
        />
      </div> */}
      <div className={clsx(styles.campaignBtns, styles.questnrBtn)}>
        <button
          type="button"
          className={clsx(styles.createCampaignBtn, styles.canBtn)}
        >
          Cancel
        </button>
        <button type="button" className={styles.updateBtn}>
          Submit
        </button>
      </div>
    </div>
  </Dialog>
);

QuestionnaireDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default QuestionnaireDialog;
