import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { ReactComponent as InfoIcon } from 'assets/images/information-circle-outline.svg';
import styles from './Tooltip.module.scss';

const Tooltip = ({
  label,
  children,
  leaveDelay = 150,
  maxWidth = 220,
  ...rest
}) => {
  const triggerRef = useRef();
  const timeOutRef = useRef();

  const onEnter = (isOpen) => {
    clearTimeout(timeOutRef.current);
    if (!isOpen) triggerRef.current?.firstChild?.click();
  };

  const onLeave = (isOpen) => {
    timeOutRef.current = setTimeout(() => {
      if (isOpen) triggerRef.current?.firstChild?.click();
    }, leaveDelay);
  };
  return (
    <Popover className="relative">
      {({ open }) => (
        <div ref={triggerRef} className={styles.wrapper}>
          <Float autoPlacement portal offset={8}>
            {children ? (
              <Popover.Button
                {...rest}
                onMouseEnter={() => onEnter(open)}
                onMouseLeave={() => onLeave(open)}
              >
                {children}
              </Popover.Button>
            ) : (
              <Popover.Button className={styles.button}>
                <InfoIcon />
              </Popover.Button>
            )}
            <Popover.Panel className={styles.popoverPanel} style={{ maxWidth }}>
              {label}
            </Popover.Panel>
          </Float>
        </div>
      )}
    </Popover>
  );
};

Tooltip.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node,
  leaveDelay: PropTypes.number,
  maxWidth: PropTypes.number,
};

export default Tooltip;
