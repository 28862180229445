import React from 'react';
import PropTypes from 'prop-types';
import { cardtransBg } from 'constants/images';

const AnalyticsCard = ({ image, title, value }) => (
  <div className="metricCard">
    <img src={cardtransBg} className="metricCardBg" alt="" />
    <img src={image} className="metricImage" alt="" />
    <div>
      <h2 className="metricTitle">{title}</h2>
      <p className="metricValue">{value}</p>
    </div>
  </div>
);

AnalyticsCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.node,
  value: PropTypes.node,
};

export default AnalyticsCard;
