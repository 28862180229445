import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useBrandAuth } from 'stores/auth/brand';
import routes from 'constants/routes';

const BrandGuestGuard = ({ children }) => {
  const token = useBrandAuth((state) => state.token);

  if (token) {
    return <Navigate to={routes.BRAND_DASHBOARD} replace />;
  }

  return children;
};

BrandGuestGuard.propTypes = {
  children: PropTypes.node,
};

export default BrandGuestGuard;
