import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSpinDelay } from 'spin-delay';
import { useQuery } from '@tanstack/react-query';
import { getFaqs } from 'api/common/informativeContent';
import { LoaderWithWrapper } from 'components/common/Loader';
import styles from 'assets/scss/pages/landing-homepage.module.scss';
import { arrowBlackImg } from 'constants/images';

import routes from 'constants/routes';
import Faq from 'components/common/Faq';
import clsx from 'clsx';

const FaqSection = ({ varient }) => {
  const [selectedTab, setSelectedTab] = useState('brand');

  const filterBy = varient || selectedTab;

  const { data: faqs, isLoading } = useQuery({
    queryKey: ['faqs', 1, 4, filterBy],
    queryFn: () => getFaqs({ pageNo: 1, pageSize: 4, sortBy: filterBy }),
    select: (data) => data?.data?.faqs?.rows,
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  return (
    <div className={styles.questionSec}>
      <div className={styles.queryBlock1}>
        <h3 className={styles.queTxt}>We got you</h3>
        {/* <p className={styles.assistanceTXT}>
          Yet bed any for assistance indulgence unpleasing. Not thoughts all
          exercise blessing. Indulgence way everything joy alteration boisterous
          the attachment.
        </p> */}
        <Link
          to={varient ? `${routes.FAQ}?type=${varient}` : routes.FAQ}
          className={styles.moreFaqBtn}
        >
          More FAQs <img src={arrowBlackImg} alt="" />
        </Link>
        {!varient && (
          <div className={styles.navListWrapper} style={{ marginTop: 40 }}>
            <div className={styles.navList}>
              <button
                type="button"
                className={clsx({
                  [styles.navListItem]: true,
                  [styles.activeNavHeading]: selectedTab === 'brand',
                })}
                onClick={() => setSelectedTab('brand')}
              >
                <span>Brand</span>
              </button>
              <button
                type="button"
                className={clsx({
                  [styles.navListItem]: true,
                  [styles.activeNavHeading]: selectedTab === 'influencer',
                })}
                onClick={() => setSelectedTab('influencer')}
              >
                <span>Influencer</span>
              </button>
            </div>
          </div>
        )}
      </div>
      <div>
        {isLoading || delayedIsLoading ? (
          <LoaderWithWrapper height={373} show={delayedIsLoading} />
        ) : (
          faqs?.map(({ question, answer, id }) => (
            <Faq key={id} question={question} answer={answer} />
          ))
        )}
      </div>
    </div>
  );
};

FaqSection.propTypes = {
  varient: PropTypes.oneOf(['brand', 'influencer']),
};

export default FaqSection;
