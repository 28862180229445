import { baseQuery } from './baseQuery';

const apiVersion = process.env.REACT_APP_API_VERSION;

export const getProfile = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/view-profile`,
    method: 'GET',
    params,
  });

export const editProfile = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/edit-profile`,
    method: 'PUT',
    body,
  });

export const deleteAccount = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/delete-account`,
    method: 'PUT',
    body,
  });

export const deactivateAccount = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/deactivate-account`,
    method: 'PUT',
    body,
  });

export const addAddress = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/users-add-address`,
    method: 'POST',
    body,
  });

export const editAddress = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/users-edit-address`,
    method: 'PUT',
    body,
  });

export const getInTouch = (body) =>
  baseQuery({
    url: `/users/${apiVersion}/get-in-touch`,
    method: 'PUT',
    body,
  });
