import { baseQuery } from './baseQuery';

const apiVersion = process.env.REACT_APP_API_VERSION;

export const getCardlist = () =>
  baseQuery({
    url: `/users/${apiVersion}/payment/cardlist`,
    method: 'GET',
  });

export const initiateCampaignPayment = ({ quoteId, body }) =>
  baseQuery({
    url: `/users/${apiVersion}/payment/initiate/${quoteId}`,
    method: 'POST',
    body,
  });

export const uploadCampaignPaymentSlip = ({ quoteId, body }) =>
  baseQuery({
    url: `/users/payment/slip/${quoteId}`,
    method: 'POST',
    body,
  });

export const getPaymentDetails = ({ paymentId }) =>
  baseQuery({
    url: `/users/${apiVersion}/orders/${paymentId}`,
    method: 'GET',
  });

// TODO: remove this once new payment flow is implemented
export const chargeUser = ({ quoteId, body }) =>
  baseQuery({
    url: `/users/${apiVersion}/payment/details/${quoteId}`,
    method: 'POST',
    body,
  });

export const getPaymentRedirectUrl = ({ paymentId }) =>
  `${process.env.REACT_APP_API_BASE_URL}/users/${apiVersion}/payment/pay/${paymentId}`;
