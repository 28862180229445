import React from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';
import { Dialog } from 'components/common/dialog';
import failAnimation from 'assets/lottie/payment-fail.json';
import styles from 'assets/scss/pages/payments.module.scss';
import { ReactComponent as CloseIcon } from 'assets/images/colored-cross.svg';

const PaymentFailDialog = ({ isOpen = false, onClose }) => (
  <Dialog open={isOpen} close={onClose} rounded fullWidth>
    <div className={styles.statusContainer}>
      <button type="button" className={styles.closeButton} onClick={onClose}>
        <CloseIcon className={styles.closeIcon} />
      </button>
      <div>
        <div className={styles.playerWrapper}>
          <Player autoplay keepLastFrame src={failAnimation} />
        </div>
        <h2 className={styles.statusHeading}>Payment Failed</h2>
        <p className={styles.statusDescription}>
          An error occurred while processing your payment
        </p>
      </div>
    </div>
  </Dialog>
);

PaymentFailDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PaymentFailDialog;
