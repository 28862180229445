import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import routes from 'constants/routes';
import styles from './Footer.module.scss';

function Footer({ type }) {
  const helpLink = type
    ? type === 'brand'
      ? routes.BRNAD_HELP
      : routes.INF_HELP
    : routes.HELP;

  const termsLink =
    type === 'brand'
      ? routes.BRAND_TERMS_AND_CONDTIONS
      : routes.INF_TERMS_AND_CONDTIONS;

  return (
    <footer className={styles.footer}>
      <p className={styles.item}>© 2024 Radio City</p>
      <Link to={routes.PRIVACY_POLICY} className={styles.item}>
        Privacy Policy
      </Link>
      <Link to={termsLink} className={styles.item}>
        Terms & conditions
      </Link>
      <Link to={routes.CONTACT_US} state={{ type }} className={styles.item}>
        Help
      </Link>
    </footer>
  );
}

Footer.propTypes = {
  type: PropTypes.oneOf(['brand', 'influencer']),
};

export default Footer;
