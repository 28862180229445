import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DashboardProfileSeaction from 'components/inf-dashboard/DashboardProfileSeaction';
import routes from 'constants/routes';
import styles from 'assets/scss/pages/dashboard.module.scss';
import {
  arrowDownIcon,
  locationDownIcon,
  reportIcon,
  expertCallImage,
  svgExport1,
  svgExport2,
  svgExport3,
  svgExport4,
  cardImage1,
  cardImage2,
  cardImage3,
  playButton,
  transparentBg,
} from 'constants/images';
import CampaignDashBoard from './CampaignDashBoard';

const Dashboard = () => {
  const [showHowItWorks, setShowHowItWorks] = useState(false);

  const toggleHowItWorks = () => {
    setShowHowItWorks((prev) => !prev);
  };
  return (
    <>
      <div className="dashboardSearch">
        <div className={styles.dashboardTop}>
          <img className={styles.transBg} src={transparentBg} alt="" />
          <div className={styles.innerDashboardContent}>
            {/* <h3 className={styles.alertBox}>
              <span className={styles.spanBox} />
              News or Alert Box: Check out our latest offers. Subscribe now to
              avail combo!
            </h3> */}
            <DashboardProfileSeaction />

            <button
              type="button"
              className={styles.searchModal}
              onClick={toggleHowItWorks}
            >
              <span className={styles.linkTxt}>See how it works</span>
              <img src={locationDownIcon} alt="" />
            </button>
            {showHowItWorks ? (
              <div className={styles.rowCard}>
                <div className={styles.cardHeader}>
                  <iframe
                    className={styles.cardImage}
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/5nYvLUvQ-cg?si=O6nqgqDy3GekY2jx"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />

                  <h2 className={styles.cardTxt}>Payed 02</h2>
                  <p className={styles.expDate}>Added 26 Dec 2023</p>
                </div>
                <div className={styles.cardHeader}>
                  <iframe
                    className={styles.cardImage}
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/cOQV9dpCQkc?si=H0YxiPpvzk5sfWjg"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />

                  <h2 className={styles.cardTxt}>Cidco</h2>
                  <p className={styles.expDate}>Added 26 Dec 2023</p>
                </div>
                <div className={styles.cardHeader}>
                  {/* <img className={styles.cardImage} src={cardImage1} alt="" /> */}
                  <iframe
                    className={styles.cardImage}
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/Wb9cKd1OQ_g?si=tVcS06pcIOPi8od6"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />

                  {/* </iframe> */}

                  <h2 className={styles.cardTxt}>Payed</h2>
                  <p className={styles.expDate}>Added 26 Dec 2023</p>
                </div>
                <div className={styles.cardHeader}>
                  {/* <img className={styles.cardImage} src={cardImage1} alt="" /> */}
                  <iframe
                    className={styles.cardImage}
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/WJgf9TAnS7I?si=Z9soN0HImXO973R1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />

                  {/* </iframe> */}

                  <h2 className={styles.cardTxt}>Aazad hai influencer</h2>
                  <p className={styles.expDate}>Added 26 Dec 2023</p>
                </div>
                <div className={styles.cardHeader}>
                  {/* <img className={styles.cardImage} src={cardImage1} alt="" /> */}
                  <iframe
                    className={styles.cardImage}
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/ph7Spjp3MxI?si=nD3wJvvARSzxWrLc"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                  {/* </iframe> */}

                  <h2 className={styles.cardTxt}>Nature's Basket Influencer</h2>
                  <p className={styles.expDate}>Added 26 Dec 2023</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.categoryContainer}>
        <Link to={routes.INF_CAMPAIGNS} className={styles.viewAllCate}>
          <div className={styles.cateImg}>
            <img src={reportIcon} alt="" />
          </div>
          <div className={styles.categoryHeader}>
            <span className={styles.categoryTxt}>View All Campaigns</span>
            <img className={styles.drImg} src={arrowDownIcon} alt="" />
          </div>
        </Link>
        <div className={styles.expertCate}>
          <div className={styles.expertInner}>
            <div className={styles.expertCallImg}>
              <img src={expertCallImage} alt="" />
            </div>
            <div className={styles.extContent}>
              <h2 className={styles.extTxt}>
                Get strategic inputs from our experts who have serviced over 500
                global brands
              </h2>
              <ul className={styles.expList}>
                <li className={styles.expInnerList}>
                  <img src={svgExport1} alt="" />
                  <span className={styles.guidedText}>
                    Guided influencer selection
                  </span>
                </li>
                <li className={styles.expInnerList}>
                  <img src={svgExport2} alt="" />
                  <span className={styles.guidedText}>Better reach</span>
                </li>
                <li className={styles.expInnerList}>
                  <img src={svgExport3} alt="" />
                  <span className={styles.guidedText}>
                    Prompt response usually in an hour
                  </span>
                </li>
                <li className={styles.expInnerList}>
                  <img src={svgExport4} alt="" />
                  <span className={styles.guidedText}>Transparent pricing</span>
                </li>
                {/* <li>
                  <div className="connectBtns">
                    <button type="button" className={styles.connctBtn}>
                      Talk To Us
                    </button>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <CampaignDashBoard />
    </>
  );
};

export default Dashboard;
