import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useQuery } from '@tanstack/react-query';
import { getCampaignDetails, getCampaignInfluencers } from 'api/brand/campaign';
import { Dialog } from 'components/common/dialog';
import { useCreateCampaign } from 'stores/create-campaign';
import styles from 'assets/scss/pages/popup.module.scss';
import { coloredCrossIcon, transparentBg } from 'constants/images';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

const blankArray = [];

const CreateCampaignDialog = ({
  open,
  close,
  newAddedInfluencers = blankArray,
}) => {
  const { formStep, reset, formData, setFormData } = useCreateCampaign();

  const { campaignId } = formData;

  const { data: campaignData } = useQuery({
    queryKey: ['brand-campaign-details', campaignId],
    queryFn: () => getCampaignDetails({ id: campaignId }),
    select: (res) => res?.data?.campaign,
    enabled: Boolean(campaignId),
  });

  const { data: campaignInfluencers } = useQuery({
    queryKey: ['brand-campaign-influencers', campaignId],
    queryFn: () => getCampaignInfluencers({ id: campaignId }),
    select: (res) => res?.data?.influencers?.rows,
    enabled: Boolean(campaignId),
  });

  useEffect(() => {
    if (campaignData) {
      const {
        campaign_name: campaignName,
        brand_name: brandName,
        campaign_start_date: startDateTime,
        campaign_end_date: endDateTime,
        budget: expectedBudget,
        payment_terms: paymentTerms,
        campaign_description: description,
        categories,
        social_hashtags: tags,
        upload_image: media,
        regions,
        target_genders: genders,
        ages,
        target_platforms: platforms,
      } = campaignData;

      setFormData({
        campaignName,
        brandName,
        startDateTime,
        endDateTime,
        expectedBudget,
        paymentTerms,
        description,
        categories: categories?.map(({ id }) => id),
        tags: tags?.map(({ id, hashtag_name: name }) => ({ name, tagId: id })),
        media,
        region: regions?.map(({ id }) => id),
        gender: genders,
        ageGroup: ages?.map(({ age_from: from, age_to: to }) => ({ from, to })),
        platforms,
      });
    }
  }, [campaignData, setFormData]);

  useEffect(() => {
    if (campaignInfluencers?.length > 0) {
      const influencers = campaignInfluencers.map((influencerData) => {
        const { influencer } = influencerData;
        const platforms = [];

        const deliverables = influencerData.deliverables?.map(
          (deliverable) => ({
            platform: deliverable.media_type,
            reelsCount: Number(deliverable.reel) || 0,
            storiesCount: Number(deliverable.stories) || 0,
            imagesCount: Number(deliverable.image) || 0,
            videosCount: Number(deliverable.video) || 0,
            shortsCount: Number(deliverable.shorts) || 0,
            postsCount: Number(deliverable.posts) || 0,
            tweetssCount: Number(deliverable.tweets) || 0,
          })
        );

        if (influencer?.is_facebook) platforms.push('facebook');
        if (influencer?.is_instagram) platforms.push('instagram');
        if (influencer?.is_twitter) platforms.push('twitter');
        if (influencer?.is_youtube) platforms.push('youtube');

        return {
          influencerId: influencer.id,
          influencerProfile: influencer.profile_image,
          influencerName: influencer.full_name,
          platforms,
          deliverables,
        };
      });

      const influencerIds = influencers.map(({ influencerId }) => influencerId);

      const filteredNewAddedInfluencers = newAddedInfluencers.filter(
        ({ influencerId }) => !influencerIds.includes(influencerId)
      );

      setFormData({
        influencers: [...filteredNewAddedInfluencers, ...influencers],
      });
    }
  }, [campaignInfluencers, setFormData, newAddedInfluencers]);

  const isFirstStep = formStep === 1;
  const isSecondStep = formStep === 2;
  const isThirdStep = formStep === 3;

  const onClose = () => {
    reset();
    close();
  };

  return (
    <Dialog open={open} close={onClose} fullWidth fullHeight>
      <div className="campaignCreationWrapper">
        <div className={styles.dashboardTop}>
          <img className={styles.transBg} src={transparentBg} alt="" />
          <div className={styles.campaignContainer} style={{ padding: '25px' }}>
            <div className={styles.innrWrapper}>
              <h2 className={styles.creationTxt}>
                {campaignId ? 'Edit Campaign' : 'Campaign Creation'}
              </h2>
              <button onClick={onClose} type="button">
                <img src={coloredCrossIcon} alt="" />
              </button>
            </div>
          </div>
          <div className={styles.categoryButtons}>
            <p
              className={clsx({
                [styles.influencerCateBtn]: true,
                [styles.whiteActiveBtn]: isFirstStep,
              })}
            >
              <span
                className={clsx({
                  [styles.counTxt]: true,
                  [styles.activeCountTxt]: isSecondStep || isThirdStep,
                })}
              >
                1
              </span>
              General
            </p>
            <p
              className={clsx({
                [styles.influencerCateBtn]: true,
                [styles.whiteActiveBtn]: isSecondStep,
              })}
            >
              <span
                className={clsx({
                  [styles.counTxt]: true,
                  [styles.activeCountTxt]: isThirdStep,
                })}
              >
                2
              </span>
              Targeting
            </p>
            <p
              className={clsx({
                [styles.influencerCateBtn]: true,
                [styles.whiteActiveBtn]: isThirdStep,
              })}
            >
              <span className={clsx(styles.counTxt)}>3</span>
              Influencers
            </p>
          </div>
        </div>

        {{ 1: <StepOne />, 2: <StepTwo />, 3: <StepThree /> }[formStep]}
      </div>
    </Dialog>
  );
};

CreateCampaignDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  newAddedInfluencers: PropTypes.array,
};

export default CreateCampaignDialog;
