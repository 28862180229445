import React, { useMemo } from 'react';
import ReactWordcloud from 'react-wordcloud';
import PropTypes from 'prop-types';
import useElementDimensions from 'hooks/useElementDimentions';

const HashTag = ({ hashtags }) => {
  const { dimensions, ref: targetRef } = useElementDimensions();

  const tags = useMemo(
    () => hashtags?.map((tags, ind) => ({ text: tags, value: ind })) || [],
    [hashtags]
  );

  const callbacks = {
    getWordColor: (word) => (word.text % 2 === 0 ? '#7c087f' : '#e289f2'),
    getWordTooltip: () => '',
  };

  const options = {
    rotations: 1,
    rotationAngles: [0, -90],
    colors: ['#7c087f', '#7F60FF', '#FF00A2', '#E289F2'],
    fontFamily:
      'Poppins, -apple-system, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
  };

  return (
    <div ref={targetRef} style={{ height: 340, width: '100%' }}>
      {dimensions ? (
        <ReactWordcloud
          words={tags}
          options={options}
          callbacks={callbacks}
          size={[dimensions.width, dimensions.height]}
        />
      ) : null}
    </div>
  );
};

HashTag.propTypes = {
  hashtags: PropTypes.array,
};

export default HashTag;
