import React from 'react';
import styles from 'assets/scss/pages/campaign.module.scss';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  //   Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatNumber } from 'utils';

const VideoViewLineChart = ({ data, insights }) => (
  <div className="analyticsSection">
    <div className="header">
      <h2 className="title">video view insight</h2>
    </div>
    <div className="videoStatsContainer">
      <div className="statsWrapper">
        <div className="statsTitle">
          <span className="statsDot" />
          <h2 className="statsTitleText">Likes</h2>
        </div>
        <p className="statsValue">{formatNumber(insights.totalViews)}</p>
      </div>
      <div className="statsWrapper">
        <div className="statsTitle">
          <span className="statsDot" style={{ background: '#FF00A2' }} />
          <h2 className="statsTitleText">Comments</h2>
        </div>
        <p className="statsValue">{formatNumber(insights?.totalComments)}</p>
      </div>
      <div className="statsWrapper">
        <div className="statsTitle">
          <span className="statsDot" style={{ background: '#7F60FF' }} />
          <h2 className="statsTitleText">Videos Watched</h2>
        </div>
        <p className="statsValue">{formatNumber(insights?.totalViews)}</p>
      </div>
    </div>
    <div className="contentWrapper">
      <ResponsiveContainer width="100%" height={250}>
        <LineChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
        >
          <CartesianGrid
            vertical={false}
            strokeWidth={1}
            strokeDasharray="5 5"
            stroke="#E6E6E6"
          />
          <XAxis
            dataKey="date"
            tickLine={false}
            axisLine={false}
            fontSize={14}
            fill="#7B8794"
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            fontSize={14}
            tickFormatter={(value) => formatNumber(value)}
            fill="#7B8794"
            width={42}
          />
          <Tooltip />
          {/* <Legend /> */}
          <Line
            type="monotone"
            dataKey="total_likes"
            stroke="#7C087F"
            dot={false}
            strokeWidth={3}
            isAnimationActive={false}
          />
          <Line
            type="monotone"
            dataKey="total_comments"
            stroke="#FF00A2"
            dot={false}
            strokeWidth={3}
            isAnimationActive={false}
          />
          <Line
            type="monotone"
            dataKey="total_views"
            stroke="#7F60FF"
            dot={false}
            strokeWidth={3}
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </div>
);

VideoViewLineChart.propTypes = {
  data: PropTypes.array,
  insights: PropTypes.object,
};

export default VideoViewLineChart;
