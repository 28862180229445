import React from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';
import { Dialog } from 'components/common/dialog';
import successAnimation from 'assets/lottie/success.json';
import styles from 'assets/scss/pages/payments.module.scss';
import { ReactComponent as CloseIcon } from 'assets/images/colored-cross.svg';

const PaymentSuccessDialog = ({ isOpen = false, onClose, orderId, amount }) => (
  <Dialog open={isOpen} close={onClose} rounded fullWidth>
    <div className={styles.statusContainer}>
      <button type="button" className={styles.closeButton} onClick={onClose}>
        <CloseIcon className={styles.closeIcon} />
      </button>
      <div>
        <div className={styles.playerWrapper}>
          <Player autoplay keepLastFrame src={successAnimation} />
        </div>
        <h2 className={styles.statusHeading}>Congratulations!</h2>
        <p className={styles.statusDescription}>
          Payment completed successfully
        </p>
        {orderId && amount ? (
          <p className={styles.paymentDetails}>
            <strong>Order ID:</strong> {orderId}
            <br />
            <strong>Amount:</strong> {amount}
          </p>
        ) : null}
      </div>
    </div>
  </Dialog>
);

PaymentSuccessDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  orderId: PropTypes.string,
  amount: PropTypes.string,
};

export default PaymentSuccessDialog;
