/**
 * format amount into currency
 * @param {Number | String} value value that you want to format
 * @returns string liks
 */

export const formatCurrency = (value) =>
  Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 0,
  }).format(value);
