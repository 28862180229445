import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import styles from './error.module.scss';

const PageLoadError = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <div className={styles.notFound}>
        <div>
          <h1>
            <strong>{error.status}.</strong> That's an error
          </h1>
          {error.status === 404 && (
            <p>The pages you requested does not exists. That's all we know.</p>
          )}
          {error.status === 401 && (
            <p>You aren't authorized to visit this page. That's all we know.</p>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.notFound}>
      <div>
        <h1>
          <b>Error</b>
          <br />
          {error?.message || 'There was an error, please try again later.'}
        </h1>
        {process.env.REACT_APP_SHOW_ERROR_STACK === 'true' ? (
          <>
            <br />
            <h2>
              <b>Error Stack</b>
            </h2>
            <pre>{error?.stack}</pre>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default PageLoadError;
