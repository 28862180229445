import React, {
  useEffect,
  // useMemo,
} from 'react';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import {
  useMutation,
  // useQuery,
} from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// import {  getStateList } from 'api/brand/search';
import { signupProfileSetup } from 'api/brand/auth';
import { useBrandAuth } from 'stores/auth/brand';
import { queryClient } from 'App';

import Footer from 'components/onboarding/Footer';
import CountryCodeSelect from 'components/common/CountryCodeSelect';
import Button from 'components/common/Button';
// import Select from 'components/common/form/Select';

import routes from 'constants/routes';
import { logoImageBlackText, addIconImage } from 'constants/images';
import { BRAND_SIGNUP_PROFILE_SCHEMA } from 'constants/schema';
import styles from 'assets/scss/pages/onboarding.module.scss';

const defaultValues = {
  agencyName: '',
  firstName: '',
  lastName: '',
  email: '',
  countryCode: '',
  mobile: '',
  // address: {
  //   houseNo: '',
  //   city: '',
  //   state: '',
  //   pincode: '',
  // },
  gstCert: '',
  consent: false,
};

function SignUpProfileSetup() {
  const user = useBrandAuth((state) => state.user);
  const navigate = useNavigate();

  // const { data: states } = useQuery({
  //   queryKey: ['brand-states'],
  //   queryFn: () => getStateList(),
  // });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(BRAND_SIGNUP_PROFILE_SCHEMA),
    defaultValues,
  });

  // const selectedState = watch('address.state');

  const profileMutation = useMutation({
    mutationFn: ({
      agencyName,
      firstName,
      lastName,
      email,
      // countryCode,
      mobile,
      // address,
      consent,
      gstCert,
    }) => {
      const payload = new FormData();
      payload.append('agency_name', agencyName);
      payload.append('first_name', firstName);
      payload.append('last_name', lastName);
      payload.append('email', email);
      payload.append('mobile', mobile);
      // payload.append('address_line_1', address.houseNo);
      // payload.append('city', address.city);
      // payload.append('state', address.state);
      // payload.append('pincode', address.pincode);
      // payload.append('address_type', 'office');
      payload.append('payment_terms', consent);
      payload.append('terms_condition', consent);
      if (gstCert?.[0]) payload.append('upload_gst', gstCert?.[0]);

      return signupProfileSetup(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['brand-profile'] });
      navigate(routes.BRAND_SIGNUP_ADD_ADDRESS);
    },
  });

  const onRemoveGstCert = () => {
    setValue('gstCert', '');
  };

  useEffect(() => {
    if (user) {
      setValue('email', user.email);
    }
  }, [user, setValue]);

  // const filteredCities = useMemo(
  //   () =>
  //     states
  //       ?.reduce(
  //         (accumulator, state) =>
  //           selectedState === state?.name
  //             ? [...accumulator, ...state.cities]
  //             : accumulator,
  //         []
  //       )
  //       .sort()
  //       .map((city) => ({ name: city })),
  //   [states, selectedState]
  // );

  const gstCert = watch('gstCert')?.[0];

  return (
    <>
      <div className={styles.signupInnerContent}>
        <div className={clsx(styles.signupItems, styles.profile)}>
          <div>
            <Link to={routes.HOME}>
              <img src={logoImageBlackText} alt="" width={140} />
            </Link>
          </div>
          <h1>Profile Completion</h1>
          <p>Please provide your details to create a unique profile</p>
          <form
            className={styles.signupFormItems}
            onSubmit={handleSubmit(profileMutation.mutate)}
          >
            <div>
              <input
                type="text"
                placeholder="Enter Your Brand/Agency Name"
                maxLength="40"
                {...register('agencyName')}
              />
              {errors?.agencyName ? (
                <p className={styles.error}>{errors.agencyName?.message}</p>
              ) : null}
            </div>
            <div className={styles.addressFields}>
              <div>
                <input
                  type="text"
                  placeholder="Enter Your First Name"
                  maxLength="40"
                  {...register('firstName')}
                />
                {errors?.firstName ? (
                  <p className={styles.error}>{errors.firstName?.message}</p>
                ) : null}
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Enter Your Last Name"
                  maxLength="40"
                  {...register('lastName')}
                />
                {errors?.lastName ? (
                  <p className={styles.error}>{errors.lastName?.message}</p>
                ) : null}
              </div>
            </div>
            <div>
              <input
                type="text"
                placeholder="Enter Your Email Id"
                maxLength="70"
                {...register('email')}
                readOnly
              />
              {errors?.email ? (
                <p className={styles.error}>{errors.email?.message}</p>
              ) : null}
            </div>
            <div>
              <div>
                <div className={styles.mobileWrapper}>
                  <div className={styles.countryCodeSelect}>
                    <Controller
                      render={({ field }) => (
                        <CountryCodeSelect
                          onChange={field.onChange}
                          value={field.value}
                        />
                      )}
                      control={control}
                      name="countryCode"
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Enter Your Mobile Number"
                    maxLength="10"
                    {...register('mobile')}
                  />
                </div>
              </div>
              {errors?.mobile ? (
                <p className={styles.error}>{errors.mobile?.message}</p>
              ) : null}
            </div>
            {/* <fieldset>
              <legend className={styles.addressTitle}>Business Address</legend>
              <div className={styles.addressFields}>
                <div>
                  <input
                    type="text"
                    placeholder="Apartment, Suite, Unit, Floor"
                    maxLength="40"
                    {...register('address.houseNo')}
                  />
                  {errors?.address?.houseNo ? (
                    <p className={styles.error}>
                      {errors.address.houseNo?.message}
                    </p>
                  ) : null}
                </div>

                <div className={styles.select}>
                  <Controller
                    render={({ field }) => (
                      <Select
                        placeholder="State"
                        options={states}
                        labelSelector="name"
                        valueSelector="name"
                        onChange={(...params) => {
                          setValue('address.city', '');
                          field.onChange(...params);
                        }}
                        {...field}
                      />
                    )}
                    control={control}
                    name="address.state"
                  />
                  {errors?.address?.state ? (
                    <p className={styles.error}>
                      {errors.address.state?.message}
                    </p>
                  ) : null}
                </div>
                <div className={styles.select}>
                  <Controller
                    render={({ field }) => (
                      <Select
                        placeholder="City"
                        options={filteredCities}
                        labelSelector="name"
                        valueSelector="name"
                        {...field}
                      />
                    )}
                    control={control}
                    name="address.city"
                  />
                  {errors?.address?.city ? (
                    <p className={styles.error}>
                      {errors.address.city?.message}
                    </p>
                  ) : null}
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Pincode"
                    maxLength="6"
                    {...register('address.pincode')}
                  />
                  {errors?.address?.pincode ? (
                    <p className={styles.error}>
                      {errors.address.pincode?.message}
                    </p>
                  ) : null}
                </div>
              </div>
            </fieldset> */}
            <div>
              <div className={styles.uploadContainer}>
                <div>
                  <p className={styles.uploadPdfDetail}>GST Certificate</p>
                  <p className={styles.documentFormat}>
                    Allowed file formats includes: .pdf
                  </p>
                </div>
                {gstCert ? (
                  <div>
                    <p className={styles.filename} title={gstCert?.name}>
                      {gstCert?.name}
                    </p>
                    <p>
                      <button
                        type="button"
                        onClick={onRemoveGstCert}
                        className={styles.removeBtn}
                      >
                        Remove
                      </button>
                    </p>
                  </div>
                ) : (
                  <label className={styles.uploadBtn}>
                    <input
                      type="file"
                      {...register('gstCert')}
                      accept="application/pdf"
                      hidden
                    />
                    <img src={addIconImage} alt="" /> upload
                  </label>
                )}
              </div>
              {errors?.gstCert ? (
                <p className={styles.error}>{errors.gstCert?.message}</p>
              ) : null}
            </div>
            <div>
              <div className={styles.checkbox}>
                <input
                  type="checkbox"
                  id="terms-checkbox"
                  {...register('consent')}
                />
                <label htmlFor="terms-checkbox">
                  I agree to the{' '}
                  <Link to={routes.BRAND_TERMS_AND_CONDTIONS}>
                    Terms of Use
                  </Link>{' '}
                  and <Link to={routes.PRIVACY_POLICY}>Privacy Policy</Link>
                </label>
              </div>
              {errors?.consent ? (
                <p className={styles.error}>{errors.consent?.message}</p>
              ) : null}
            </div>
            <Button
              type="submit"
              fullWidth
              size="large"
              isLoading={profileMutation.isLoading}
            >
              Next
            </Button>
          </form>
        </div>
        <Footer type="brand" />
      </div>
    </>
  );
}

export default SignUpProfileSetup;
