import React, { useState } from 'react';
import clsx from 'clsx';
import { Element } from 'react-scroll';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  getFollowersGrowth,
  getInfluencerDetails,
  getInfluencerRank,
} from 'api/brand/search';
import {
  BrandsSection,
  ContentSection,
  EngagementCard,
  InfFollowerGrowth,
  InfProfileDetailsNavbar,
  OverviewCard,
} from 'components/common/inf-profile';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import {
  brandIcon,
  contentIcon,
  facebookPost,
  growthGrayIcon,
  heartGrayIcon,
  platformIcon,
  usersGrayIcon,
} from 'constants/images';
import { formatNumber } from 'utils';
import { NoData } from 'components/common/NoData';

const FacebookProfile = () => {
  const [follersGrowthFilter, setFollersGrowthFilter] = useState(90);

  const { id: influencerId } = useParams();
  const { data: influencer } = useQuery({
    queryKey: ['brand-influencer-details', influencerId],
    queryFn: () => getInfluencerDetails({ influencerId }),
  });

  const { data: infleucnerRank } = useQuery({
    queryKey: ['brand-influencer-rank', influencerId],
    queryFn: () => getInfluencerRank({ influencerId }),
  });

  const { data: followerGrowthData, isLoading: isLoadingFollowersGrowth } =
    useQuery({
      queryKey: [
        'brand-influencer-follower-growth',
        influencerId,
        'facebook',
        follersGrowthFilter,
      ],
      queryFn: () => {
        const params = {
          influencer_id: influencerId,
          follower_growth_filter: follersGrowthFilter,
          platform: 'facebook',
        };
        return getFollowersGrowth(params);
      },
    });

  const { facebookProfile } = influencer || {};
  const { brandPosts, recentPosts, topPosts, hashtags, brands } =
    facebookProfile || {};

  return (
    <section style={{ position: 'relative' }}>
      <InfProfileDetailsNavbar
        config={[
          {
            title: 'Overview',
            to: 'overview',
            icon: platformIcon,
            offset: -200,
          },
          { title: 'Engagement', to: 'engagement', icon: heartGrayIcon },
          { title: 'Audience', to: 'audience', icon: usersGrayIcon },
          { title: 'Growth', to: 'growth', icon: growthGrayIcon },
          { title: 'Content', to: 'content', icon: contentIcon },
          { title: 'Brands', to: 'brands', icon: brandIcon },
        ]}
      />
      <Element className={styles.navContentSection} name="overview">
        <OverviewCard
          title="Followers"
          value={formatNumber(facebookProfile?.followers)}
        />
        {/* <OverviewCard
          title="Total Posts"
          value={formatNumber(facebookProfile?.totalMedia)}
        /> */}

        <OverviewCard
          title="Estimated reach"
          value={formatNumber(followerGrowthData?.estimatedReach)}
        />

        {/* <OverviewCard
          title="SMINCO Score"
          value={formatNumber(facebookProfile?.smincoScore)}
          chipValue={infleucnerRank?.topRankText}
          info="Platform Score"
        /> */}
      </Element>
      {/* Engagement */}
      <Element className={styles.navCategory} name="engagement">
        <div className={styles.sectionHeader}>
          <img src={heartGrayIcon} alt="" />
          <h2 className={styles.contntTxt}>Engagements & Views</h2>
        </div>
        <div className={clsx(styles.sectionBody, styles.engamntBody)}>
          <EngagementCard
            icon={facebookPost}
            title="Posts"
            items={[
              {
                name: 'Avg. Likes',
                value: formatNumber(facebookProfile?.avgLikes),
              },
              {
                name: 'Avg. Comments',
                value: formatNumber(facebookProfile?.avgComments),
              },
              {
                name: 'Avg. Shares',
                value: formatNumber(facebookProfile?.avgShares),
              },
              {
                name: 'Likes-Comments Ratio ',
                value: formatNumber(facebookProfile?.likeCommentRatio, {
                  maximumFractionDigits: 2,
                }),
              },
              {
                name: 'Avg. Reactions',
                value: formatNumber(facebookProfile?.avgReactions, {
                  maximumFractionDigits: 2,
                }),
              },
            ]}
          />
          <EngagementCard
            icon={facebookPost}
            title="Bio"
            items={[
              {
                name: 'Profile Tagline',
                value: facebookProfile?.bio,
              },
            ]}
          />
        </div>
      </Element>
      <Element name="growth">
        <InfFollowerGrowth
          data={followerGrowthData}
          isLoading={isLoadingFollowersGrowth}
          handleFilterChange={setFollersGrowthFilter}
          filter={follersGrowthFilter}
        />
      </Element>
      <Element name="content">
        <ContentSection
          brandPosts={brandPosts}
          hashtags={hashtags}
          recentPosts={recentPosts}
          topPosts={topPosts}
        />
      </Element>
      {/* Brands */}
      <Element name="brands">
        <BrandsSection
          brands={brands}
          platform="facebook"
          variant="brand"
          influencerId={influencerId}
        />
      </Element>

      {!facebookProfile.isDataFetched && (
        <div className={styles.profileOverlay}>
          <NoData title="Profile is under review." description="" />
        </div>
      )}
    </section>
  );
};

export default FacebookProfile;
