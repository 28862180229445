import {
  formatFolloweGrowth,
  formatInstagramPost,
  formatTwitterPost,
  formatYoutubePost,
  formatFacebookPost,
  formatInfRank,
  formatInfSocialProfiles,
} from 'utils';
import { baseQuery } from './baseQuery';

const apiVersion = process.env.REACT_APP_API_VERSION;

export const getProfile = () =>
  baseQuery({
    url: `/influencer/${apiVersion}/profile`,
    method: 'GET',
    transformResponse: (res) => formatInfSocialProfiles(res?.data?.result),
  });

export const editProfile = (body) =>
  baseQuery({
    url: `/influencer/${apiVersion}/edit-profile`,
    method: 'PATCH',
    body,
  });

export const sendOtpEmailEdit = (body) =>
  baseQuery({
    url: `/influencer/${apiVersion}/send-email-otp`,
    method: 'POST',
    body,
  });

export const editSocialHandles = (body) =>
  baseQuery({
    url: `/influencer/${apiVersion}/social-media-linking`,
    method: 'PUT',
    body,
  });

export const addAddress = (body) =>
  baseQuery({
    url: `/influencer/${apiVersion}/add-address`,
    method: 'POST',
    body,
  });

export const editAddress = (body) =>
  baseQuery({
    url: `/influencer/${apiVersion}/edit-address`,
    method: 'PATCH',
    body,
  });

export const changePassword = (body) =>
  baseQuery({
    url: `/influencer/${apiVersion}/change-password`,
    method: 'PATCH',
    body,
  });

export const deleteAccount = (body) =>
  baseQuery({
    url: `/influencer/${apiVersion}/delete-account`,
    method: 'PUT',
    body,
  });

export const deactivateAccount = (body) =>
  baseQuery({
    url: `/influencer/${apiVersion}/deactivate-account`,
    method: 'PUT',
    body,
  });

export const getCategories = (params) =>
  baseQuery({
    url: `/influencer/${apiVersion}/categories-list`,
    method: 'GET',
    params,
    transformResponse: (res) =>
      res?.data?.categoriesList?.rows?.map(
        ({ category_name: name, category_type: type, id }) => ({
          name,
          type,
          id,
        })
      ),
  });

export const getStateList = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/get-state-list`,
    method: 'GET',
    params,
    transformResponse: (res) =>
      res?.data?.stateListData?.map(({ id, region_name: state, cities }) => ({
        name: state,
        id,
        cities,
      })),
  });

export const getFollowersGrowth = ({ platform, ...params }) =>
  baseQuery({
    url: `/influencer/${apiVersion}/get-follower-growth-${platform}`,
    method: 'GET',
    params,
    transformResponse: (res) =>
      formatFolloweGrowth({ data: res?.data, platform }),
  });

export const getRank = (params) =>
  baseQuery({
    url: `/influencer/${apiVersion}/influencer-rank`,
    method: 'GET',
    params,
    transformResponse: (res) => formatInfRank(res?.data.rank),
  });

export const getRateCard = (params) =>
  baseQuery({
    url: `/influencer/${apiVersion}/view-influencer-rate-card`,
    method: 'GET',
    params,
    transformResponse: (res) => res?.data?.result?.[0] || {},
  });

export const updateRateCard = (body) =>
  baseQuery({
    url: `/influencer/${apiVersion}/influencer-rate-card`,
    method: 'POST',
    body,
  });

export const getBrandPosts = ({ brandName, ...params }) =>
  baseQuery({
    url: `/influencer/${apiVersion}/brand/posts-list/${brandName}`,
    method: 'GET',
    params,
    transformResponse: (res) => {
      const result = {
        rows: res?.data?.result.data,
        count: res?.data?.result?.count,
      };
      switch (params.media_type.toLowerCase()) {
        case 'instagram':
          result.rows = result.rows?.map(formatInstagramPost);
          break;
        case 'youtube':
          result.rows = result.rows?.map(formatYoutubePost);
          break;
        case 'twitter':
          result.rows = result.rows?.map(formatTwitterPost);
          break;
        case 'facebook':
          result.rows = result.rows?.map(formatFacebookPost);
          break;
        default:
          break;
      }

      return result;
    },
  });
