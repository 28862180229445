import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSpinDelay } from 'spin-delay';
import { useQuery } from '@tanstack/react-query';
import {
  getCategoryTopInfluencers,
  getTopCategories,
} from 'api/common/topCategories';
import { LoaderWithWrapper } from 'components/common/Loader';
import styles from 'assets/scss/pages/landing-homepage.module.scss';
import TopCategoriesSlider from './TopCategoriesSlider';

const TopCategoriesSection = () => {
  const [selectedCategory, setSelectedCaetegory] = useState();

  const { data: topCategories } = useQuery({
    queryKey: ['top-categories'],
    queryFn: () => getTopCategories(),
    select: (res) => res?.data?.trendingCategoriesListData,
  });
  const { data: categoryInfluencer, isLoading } = useQuery({
    queryKey: ['top-category-influencers', 1, 25, selectedCategory],
    queryFn: () =>
      getCategoryTopInfluencers({
        pageNo: 1,
        pageSize: 25,
        category_id: selectedCategory,
      }),
    select: (res) => res?.data?.trendingCampaignListData,
    enabled: !!selectedCategory,
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  useEffect(() => {
    if (topCategories?.length > 0) {
      setSelectedCaetegory(topCategories[0]?.id);
    }
  }, [topCategories]);

  const selectedCategoryName = topCategories?.find(
    (category) => category?.id === selectedCategory
  )?.category_name;

  return (
    <div className={styles.influencerCategoryContainer}>
      <div className={styles.impactTxt}>
        <h2 className={clsx(styles.serviceTxt)}>
          The top influencer categories
        </h2>
        <p className={styles.exTxt}>Discover the most impactful voices!</p>
      </div>
      <div className={styles.navListWrapper}>
        <div className={styles.navList}>
          {topCategories?.slice(0, 8)?.map(({ category_name: name, id }) => (
            <button
              type="button"
              className={clsx({
                [styles.navListItem]: true,
                [styles.activeNavHeading]: id === selectedCategory,
              })}
              key={id}
              onClick={() => setSelectedCaetegory(id)}
            >
              <span>{name}</span>
            </button>
          ))}
        </div>
      </div>
      <div style={{ paddingBottom: 48 }}>
        {isLoading || delayedIsLoading ? (
          <LoaderWithWrapper show={delayedIsLoading} height={700} />
        ) : (
          <TopCategoriesSlider
            influencers={categoryInfluencer}
            selectedCategory={selectedCategoryName}
          />
        )}
      </div>
    </div>
  );
};

export default TopCategoriesSection;
