import React from 'react';
import { useParams } from 'react-router-dom';
import { Element } from 'react-scroll';
import { useQuery } from '@tanstack/react-query';
import { getInfluencerDetails } from 'api/brand/search';
import {
  InfProfileDetailsNavbar,
  PlatformCard,
} from 'components/common/inf-profile';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import { platformIcon } from 'constants/images';

const ProfileSummary = () => {
  const { id: influencerId } = useParams();

  const { data: influencer } = useQuery({
    queryKey: ['brand-influencer-details', influencerId],
    queryFn: () => getInfluencerDetails({ influencerId }),
  });

  const { instagramProfile, facebookProfile, youtubeProfile, twitterProfile } =
    influencer || {};

  return (
    <>
      <InfProfileDetailsNavbar
        config={[
          {
            title: 'Platforms',
            to: 'platforms',
            icon: platformIcon,
            offset: -200,
          },
        ]}
      />
      <div className="rightCont">
        <Element className={styles.navContent} name="platforms">
          <PlatformCard data={instagramProfile} />
          <PlatformCard data={youtubeProfile} />
          <PlatformCard data={facebookProfile} />
          <PlatformCard data={twitterProfile} />
        </Element>
      </div>
    </>
  );
};

export default ProfileSummary;
