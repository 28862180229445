import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/common/Button';
import Footer from 'components/onboarding/Footer';
import OTPInput from 'components/common/OtpInput';

import useCountdownTimer from 'hooks/useCountdownTimer';

import { forgotPassword, forgotPasswordOtpVerify } from 'api/influencer/auth';
import { useInfluencerAuth } from 'stores/auth/influencer';
import { OTP_COUNTDOWN_SECONDS } from 'constants/config';
import { INFLUENCER_FORGOT_PASS_SCHEMA } from 'constants/schema';
import { logoImageBlackText } from 'constants/images';
import routes from 'constants/routes';
import styles from 'assets/scss/pages/onboarding.module.scss';

const defaultValues = {
  username: '',
  otp: '',
  isOtpVerifyStep: false,
};

function ForgotPassword() {
  const { timeLeft, setTimeLeft, formattedValue } = useCountdownTimer(
    OTP_COUNTDOWN_SECONDS
  );

  const setToken = useInfluencerAuth((state) => state.setToken);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(INFLUENCER_FORGOT_PASS_SCHEMA),
    defaultValues,
  });

  const verifyOtpMutation = useMutation({
    mutationFn: ({ username, otp, isOtpVerifyStep }) => {
      if (isOtpVerifyStep) {
        return forgotPasswordOtpVerify({ email: username, otp });
      }
      return forgotPassword({ email: username });
    },
    onSuccess: (data, variables) => {
      if (variables.isOtpVerifyStep) {
        setToken(data?.data?.token);
        navigate(routes.INF_RESET_PASS, {
          state: { username: variables.username },
        });
      } else {
        setValue('isOtpVerifyStep', true);
        setTimeLeft(OTP_COUNTDOWN_SECONDS);
      }
    },
  });

  const onResendOtp = () => {
    verifyOtpMutation.mutate({
      username: getValues('username'),
      isOtpVerifyStep: false,
    });
  };

  const isOtpVerifyStep = watch('isOtpVerifyStep');

  return (
    <div className={styles.signupInnerContent}>
      <div className={styles.signupItems}>
        <div>
          <div>
            <Link to={routes.HOME}>
              <img src={logoImageBlackText} alt="" width={140} />
            </Link>
          </div>
          <h1>Verify Your Email</h1>
          <p>
            We have sent a 6-Digit verification code to your email. Please enter
            the code below.
          </p>
          <form
            className={styles.signupFormItems}
            onSubmit={handleSubmit(verifyOtpMutation.mutate)}
          >
            <div>
              <input
                type="text"
                placeholder="Enter Your Email ID"
                autoComplete="username"
                {...register('username')}
                disabled={isOtpVerifyStep}
              />
              {errors?.username ? (
                <p className={styles.error}>{errors.username?.message}</p>
              ) : null}
            </div>
            {isOtpVerifyStep ? (
              <div>
                <div>
                  <Controller
                    render={({ field }) => (
                      <OTPInput
                        length={6}
                        onChangeOTP={field.onChange}
                        className={styles.otpVerification}
                      />
                    )}
                    control={control}
                    name="otp"
                  />
                  {errors?.otp ? (
                    <p className={styles.error}>{errors.otp?.message}</p>
                  ) : null}
                </div>
                <div className={styles.resentBtn}>
                  <button
                    type="button"
                    onClick={onResendOtp}
                    disabled={timeLeft > 0}
                  >
                    Resend code
                  </button>
                  <p>{formattedValue}</p>
                </div>
              </div>
            ) : null}
            <Button
              type="submit"
              size="large"
              isLoading={verifyOtpMutation.isLoading}
              fullWidth
            >
              {isOtpVerifyStep ? 'Verify Otp' : 'Send Code'}
            </Button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ForgotPassword;
