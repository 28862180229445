import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog } from '@headlessui/react';
import { Player } from '@lottiefiles/react-lottie-player';

import Button from 'components/common/Button';
import { GET_IN_TOUCH_SCHEMA } from 'constants/schema';
import { getInTouch } from 'api/brand/profile';
import getInTouchAnimation from 'assets/lottie/get-in-touch.json';
import styles1 from 'assets/scss/pages/onboarding.module.scss';
import styles from '../PasswordResetSuccessDialog/PasswordResetSuccessDialog.module.scss';

const defaultValues = { email: '', mobile: '' };
function GetInTouchDialog({ isOpen, setIsOpen, email = '' }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(GET_IN_TOUCH_SCHEMA),
    defaultValues,
  });

  const getInTouchMutation = useMutation({
    mutationFn: ({ email, mobile }) => {
      const payload = {
        additional_email: email,
        additional_mobile: mobile,
      };
      return getInTouch(payload);
    },
    onSuccess: (res) => {
      setIsOpen('');
      toast.success(res?.message);
    },
  });

  useEffect(() => {
    reset({ email, mobile: '' });
  }, [email, reset]);

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="">
      {/* following div will be backdrop */}
      <div className="" aria-hidden="true" />

      <div className={styles.containerhoverlay}>
        <div className={styles.customInner}>
          <div className={styles.container_content}>
            <Dialog.Panel className="">
              <div className={styles.popupImage}>
                <Player
                  loop
                  autoplay
                  src={getInTouchAnimation}
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    transform: 'scale(1.5)',
                  }}
                />
              </div>

              <Dialog.Title
                className={styles.popupTitle}
                style={{ fontSize: '2.75em', color: '#7C087F' }}
              >
                Get In Touch
              </Dialog.Title>
              <Dialog.Description className={styles.popupDesc}>
                Please enter your details below
                <br />
                Our Team will get in touch with you soon
              </Dialog.Description>
              <form
                className={styles1.signupFormItems}
                style={{ maxWidth: '470px', margin: '0px auto 10px auto' }}
                onSubmit={handleSubmit(getInTouchMutation.mutate)}
              >
                <div>
                  <input
                    type="email"
                    placeholder="Enter Your Email ID"
                    maxLength="70"
                    {...register('email')}
                  />
                  {errors?.username ? (
                    <p className={styles.error}>{errors.username?.message}</p>
                  ) : null}
                </div>
                <div>
                  <input
                    type="tel"
                    placeholder="Enter Mobile Number"
                    maxLength="10"
                    {...register('mobile')}
                  />
                  {errors?.mobile ? (
                    <p className={styles.error}>{errors.mobile?.message}</p>
                  ) : null}
                </div>
                <Button
                  type="submit"
                  size="large"
                  isLoading={getInTouchMutation.isLoading}
                >
                  Submit
                </Button>
              </form>
            </Dialog.Panel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

GetInTouchDialog.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  email: PropTypes.string,
};

export default GetInTouchDialog;
