export const formatFolloweGrowth = ({ data, platform }) => {
  const formattedData = {
    followersGrowthData: [],
    followersGain30Day: 0,
    followersGrowthRate30Day: 0,
    estimatedReach: 0,
  };
  switch (platform) {
    case 'instagram':
      formattedData.followersGrowthData =
        data?.followerGrowthInstagramListData?.data?.map((dayData) => ({
          timestamp: dayData?.datetime,
          followers: dayData?.followers_count,
        }));
      formattedData.followersGain30Day =
        data?.followerGrowthInstagramListData?.total_growth;
      formattedData.followersGrowthRate30Day =
        data?.followerGrowthInstagramListData?.growth_percentage;
      formattedData.estimatedReach =
        data?.followerGrowthInstagramListData?.estimated_reach;
      break;
    case 'youtube':
      formattedData.followersGrowthData =
        data?.followerGrowthYoutubeListData?.data?.map((dayData) => ({
          timestamp: dayData?.datetime,
          followers: dayData?.subscriber_count,
        }));
      formattedData.followersGain30Day =
        data?.followerGrowthYoutubeListData?.total_growth;
      formattedData.followersGrowthRate30Day =
        data?.followerGrowthYoutubeListData?.growth_percentage;
      formattedData.estimatedReach =
        data?.followerGrowthYoutubeListData?.estimated_reach;
      break;
    case 'facebook':
      formattedData.followersGrowthData =
        data?.followerGrowthFacebookListData?.data?.map((dayData) => ({
          timestamp: dayData?.datetime,
          followers: dayData?.followers_count,
        }));
      formattedData.followersGain30Day =
        data?.followerGrowthFacebookListData?.total_growth;
      formattedData.followersGrowthRate30Day =
        data?.followerGrowthFacebookListData?.growth_percentage;
      formattedData.estimatedReach =
        data?.followerGrowthFacebookListData?.estimated_reach;
      break;
    case 'twitter':
      formattedData.followersGrowthData =
        data?.followerGrowthFacebookListData?.data?.data?.map((dayData) => ({
          timestamp: dayData?.datetime,
          followers: dayData?.follower_count,
        }));
      formattedData.followersGain30Day =
        data?.followerGrowthFacebookListData?.data?.total_growth;
      formattedData.followersGrowthRate30Day =
        data?.followerGrowthFacebookListData?.data?.growth_percentage;
      formattedData.estimatedReach =
        data?.followerGrowthFacebookListData?.estimated_reach;
      break;

    default:
      break;
  }
  return formattedData;
};
