import { baseQuery } from './baseQuery';

const apiVersion = process.env.REACT_APP_API_VERSION;

export const getTopCategories = (params) =>
  baseQuery({
    url: `/users/${apiVersion}/get-trending-categories`,
    method: 'GET',
    params,
  });

export const getCategoryTopInfluencers = (params) =>
  baseQuery({
    url: `/auth/${apiVersion}/influencer/trending-influencer`,
    method: 'GET',
    params,
  });
