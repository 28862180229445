import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Listbox } from '@headlessui/react';
import { ReactComponent as DownIcon } from 'assets/images/down.svg';
import styles from './form.module.scss';

const Select = React.forwardRef(
  (
    {
      label,
      options = [],
      onChange,
      value,
      placeholder,
      labelSelector = 'label',
      valueSelector = 'value',
      ...rest
    },
    ref
  ) => {
    const selected = useMemo(
      () => options.find((option) => value === option?.[valueSelector]),
      [value, options, valueSelector]
    );

    return (
      <Listbox
        value={selected || ''}
        onChange={(option) => onChange(option[valueSelector])}
        className={styles.selectWrapper}
        as="div"
        ref={ref}
        {...rest}
      >
        {label ? <Listbox.Label>{label}</Listbox.Label> : null}
        <Listbox.Button
          className={clsx({
            [styles.singleSelectButton]: true,
            [styles.selectButtonFilled]: !!selected,
          })}
        >
          {selected?.[labelSelector] ? (
            <span className={styles.selectButtonValue}>
              {selected?.[labelSelector]}
            </span>
          ) : (
            <span className={styles.selectPlaceholder}>{placeholder}</span>
          )}
          <DownIcon className={styles.dropdownIcon} />
        </Listbox.Button>
        <Listbox.Options className={styles.selectOptions}>
          {options.map((option) => (
            <Listbox.Option
              key={option?.[valueSelector]}
              value={option}
              className={({ active }) =>
                clsx({
                  [styles.selectOption]: true,
                  [styles.singleSelectOptionActive]: active,
                })
              }
            >
              {option?.[labelSelector]}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    );
  }
);

Select.propTypes = {
  label: PropTypes.node,
  options: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.node,
  value: PropTypes.string,
  labelSelector: PropTypes.string,
  valueSelector: PropTypes.string,
};

export default Select;
