import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { Dialog } from 'components/common/dialog';
import Input from 'components/common/edit-profile/Input';
import styles from 'assets/scss/pages/popup.module.scss';
import { CHANGE_PASSWORD_FORM_SCHEMA } from 'constants/schema';
import { eyeImage, eyeCloseImage } from 'constants/images';
import { changePassword } from 'api/influencer/profile';

const defaultValues = { oldPassword: '', password: '', confirmPassword: '' };

const ResetPassword = ({ open, close }) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CHANGE_PASSWORD_FORM_SCHEMA),
    defaultValues,
  });

  const resetPasswordMutation = useMutation({
    mutationFn: ({ oldPassword, password }) => {
      const payload = { old_password: oldPassword, new_password: password };

      return changePassword(payload);
    },
    onSuccess: (res) => {
      toast.success(res?.message, { position: 'top-center' });
      reset();
      close();
    },
  });

  const toggleShowOldPassword = () => {
    setShowOldPassword((prev) => !prev);
  };
  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  return (
    <Dialog
      title="Reset Password"
      open={open}
      close={close}
      size="591px"
      fullWidth
    >
      <form onSubmit={handleSubmit(resetPasswordMutation.mutate)}>
        <div className={styles.editFormWrapper}>
          <Input
            label="Current Password"
            type={showOldPassword ? 'text' : 'password'}
            error={errors?.oldPassword}
            {...register('oldPassword')}
            endAdornment={
              <EyeButton
                toggleFn={toggleShowOldPassword}
                isShow={showOldPassword}
              />
            }
          />
          <Input
            label="New Password"
            type={showPassword ? 'text' : 'password'}
            error={errors?.password}
            {...register('password')}
            endAdornment={
              <EyeButton toggleFn={toggleShowPassword} isShow={showPassword} />
            }
          />
          <Input
            label="Confirm New Password"
            type={showConfirmPassword ? 'text' : 'password'}
            error={errors?.confirmPassword}
            {...register('confirmPassword')}
            endAdornment={
              <EyeButton
                toggleFn={toggleShowConfirmPassword}
                isShow={showConfirmPassword}
              />
            }
          />
        </div>
        <div className={styles.editProfileContainer}>
          <div
            className={clsx(styles.campaignBtns, styles.nxtWrap)}
            style={{ marginTop: '16px' }}
          >
            <button type="button" className={styles.prevBtn} onClick={close}>
              Back
            </button>
            <button
              type="submit"
              style={{ width: '30%' }}
              className={styles.updateBtn}
              disabled={resetPasswordMutation.isLoading}
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

ResetPassword.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

const EyeButton = ({ toggleFn, isShow }) => (
  <button
    type="button"
    onClick={toggleFn}
    title={isShow ? 'Hide password' : 'Show password'}
  >
    <img src={isShow ? eyeCloseImage : eyeImage} alt="" />
  </button>
);

EyeButton.propTypes = {
  isShow: PropTypes.bool,
  toggleFn: PropTypes.func,
};

export default ResetPassword;
