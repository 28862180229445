import React, { useEffect, useId, useRef } from 'react';
import { animate, motion } from 'framer-motion';
import u1 from 'assets/images/home-svg-users/u1.jpg';
import u2 from 'assets/images/home-svg-users/u2.jpg';
import u3 from 'assets/images/home-svg-users/u3.jpg';
import u4 from 'assets/images/home-svg-users/u4.jpg';
import u5 from 'assets/images/home-svg-users/u5.jpg';

const MarketersBelieveBrand = (props) => {
  const p1 = useId();
  const p2 = useId();
  const p3 = useId();
  const p4 = useId();
  const p5 = useId();
  const g1 = useId();
  const f1 = useId();

  const numberRef = useRef();

  useEffect(() => {
    const node = numberRef.current;
    if (!node) return;

    const controls = animate(0, 56, {
      duration: 1,
      delay: 1,
      onUpdate(value) {
        node.textContent = `${Math.round(value).toString()}%`;
      },
    });

    // eslint-disable-next-line consistent-return
    return () => controls.stop();
  }, []);

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={193.2}
      height={174.5}
      {...props}
      style={{ overflow: 'visible', postion: 'relative' }}
    >
      <defs>
        <pattern
          id={p1}
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 512 512"
        >
          <image xlinkHref={u1} width={512} height={512} />
        </pattern>
        <pattern
          id={p2}
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 200 200"
        >
          <image xlinkHref={u2} width={200} height={200} />
        </pattern>
        <pattern
          id={p3}
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 128 128"
        >
          <image xlinkHref={u3} width={128} height={128} />
        </pattern>
        <pattern
          id={p4}
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 250 250"
        >
          <image xlinkHref={u4} width={250} height={250} />
        </pattern>
        <pattern
          id={p5}
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 741 772"
        >
          <image xlinkHref={u5} width={741} height={772} />
        </pattern>
        <linearGradient
          id={g1}
          x2={0.984}
          y1={0.5}
          y2={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={0} stopColor="#b584ce" stopOpacity={0.149} />
          <stop offset={1} stopColor="#361559" stopOpacity={0.8} />
        </linearGradient>
        <filter
          id={f1}
          width={209.443}
          height={195.715}
          x={110.271}
          y={119.117}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={4} dy={5} />
          <feGaussianBlur result="c" stdDeviation={5} />
          <feFlood floodColor="#e79ce3" floodOpacity={0.502} />
          <feComposite in2="c" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g opacity={0.8}>
        <rect
          width={179.443}
          height={168.657}
          fill="#361559"
          rx={12}
          transform="translate(13.728 5.883)"
          opacity={0.5}
          filter="blur(45px)"
        />
        <g filter={`url(#${f1})`} transform="translate(-121.27 -129.12)">
          <g
            fill={`url(#${g1})`}
            stroke="#fff"
            strokeWidth={1.5}
            transform="translate(121.27 129.12)"
          >
            <rect width={179.443} height={165.715} stroke="none" rx={12} />
            <rect
              width={177.943}
              height={164.215}
              x={0.75}
              y={0.75}
              fill="none"
              rx={11.25}
            />
          </g>
        </g>
        <g fill="rgba(255,255,255,0)" stroke="rgba(255,255,255,0.1)">
          <rect width={179.443} height={165.715} stroke="none" rx={12} />
          <rect
            width={178.443}
            height={164.715}
            x={0.5}
            y={0.5}
            fill="none"
            rx={11.5}
          />
        </g>
      </g>
      <text
        fill="#fff"
        fontFamily="Poppins-Bold, Poppins"
        fontSize={31}
        fontWeight={700}
        style={{
          whiteSpace: 'pre',
        }}
        transform="translate(23.534 48.69)"
      >
        <tspan x={0} y={0} ref={numberRef} />
      </text>
      <g stroke="#fffcfc" strokeWidth={1.5}>
        <g
          fill={`url(#${p1})`}
          opacity={0.5}
          transform="translate(20.592 66.189)"
        >
          <circle cx={22.945} cy={22.945} r={22.945} stroke="none" />
          <circle cx={22.945} cy={22.945} r={22.195} fill="none" />
        </g>
        <g fill={`url(#${p1})`} transform="translate(23.534 69.13)">
          <circle cx={20.004} cy={20.004} r={20.004} stroke="none" />
          <circle cx={20.004} cy={20.004} r={19.254} fill="none" />
        </g>
        <g
          fill={`url(#${p2})`}
          opacity={0.5}
          transform="translate(43.54 69.13)"
        >
          <circle cx={20.004} cy={20.004} r={20.004} stroke="none" />
          <circle cx={20.004} cy={20.004} r={19.254} fill="none" />
        </g>
        <g fill={`url(#${p2})`} transform="translate(43.537 69.13)">
          <circle cx={20.004} cy={20.004} r={20.004} stroke="none" />
          <circle cx={20.004} cy={20.004} r={19.254} fill="none" />
        </g>
        <g
          fill={`url(#${p3})`}
          opacity={0.5}
          transform="translate(63.54 69.13)"
        >
          <circle cx={20.004} cy={20.004} r={20.004} stroke="none" />
          <circle cx={20.004} cy={20.004} r={19.254} fill="none" />
        </g>
        <g fill={`url(#${p3})`} transform="translate(63.54 69.13)">
          <circle cx={20.004} cy={20.004} r={20.004} stroke="none" />
          <circle cx={20.004} cy={20.004} r={19.254} fill="none" />
        </g>
        <g
          fill={`url(#${p4})`}
          opacity={0.5}
          transform="translate(83.547 69.13)"
        >
          <circle cx={20.004} cy={20.004} r={20.004} stroke="none" />
          <circle cx={20.004} cy={20.004} r={19.254} fill="none" />
        </g>
        <g fill={`url(#${p4})`} transform="translate(83.544 69.13)">
          <circle cx={20.004} cy={20.004} r={20.004} stroke="none" />
          <circle cx={20.004} cy={20.004} r={19.254} fill="none" />
        </g>
        <g fill={`url(#${p5})`} transform="translate(103.352 69.13)">
          <circle cx={20.004} cy={20.004} r={20.004} stroke="none" />
          <circle cx={20.004} cy={20.004} r={19.254} fill="none" />
        </g>
      </g>
      {/* <text
        fill="#fff"
        fontFamily="Poppins-Medium, Poppins"
        fontSize={15}
        fontWeight={500}
        style={{
          whiteSpace: 'pre',
        }}
        transform="translate(20.592 143.384)"
      >
        <tspan x={0} y={0}>
          {'Marketers Believe'}
        </tspan>
      </text> */}
    </motion.svg>
  );
};
export default MarketersBelieveBrand;
