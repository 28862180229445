import React, { useMemo } from 'react';
import clsx from 'clsx';
import { differenceInCalendarDays } from 'date-fns';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getStateList } from 'api/influencer/profile';
import { getBrands, getCampaignReports } from 'api/influencer/campaign';
import Table from 'components/common/Table';
import FilterDropdown from 'components/common/FilterDropdown';
import { FilterChip } from 'components/common/Chip';
import FilterMenu from 'components/common/FilterDropdown/FilterMenu';
import Button from 'components/common/Button';
import TableError from 'components/common/Table/TableError';
import { Avatar, AvatarGroup } from 'components/common/Avatar';
import { FullPageLoader } from 'components/common/Loader';
import useDebounce from 'hooks/useDebounce';
import useFilterProps from 'hooks/useFilterProps';
import {
  // INF_CAMPAIGN_STATUS_LIST,
  GENDERS,
  PLATFORMS,
} from 'constants/config/filters';
import {
  CAMPAIGN_STATUS,
  INF_CAMPAIGN_STATUS_CHIPS,
} from 'constants/config/campaign';
import routes from 'constants/routes';
import { PLATFORM_ICONS } from 'constants/config/platformIcons';
import styles from 'assets/scss/pages/campaign.module.scss';
// import { ReactComponent as ShareIcon } from 'assets/images/share-colored.svg';
import {
  transparentBg,
  cogwheelIcon,
  locationSvg,
  searchImg,
  // statusIcon,
  targetAudienceIcon,
  targetGendrIcon,
} from 'constants/images';
import { formatNumber } from 'utils';
import DownloadReport from 'components/inf-dashboard/campaigns/DownloadReport';

const columns = [
  {
    Header: 'Campaign',
    accessor: 'first_name',
    width: 230,
    minWidth: 230,
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      const campaignStatus = row?.campaign_status;
      return (
        <div className={styles.profileDiv} style={{ paddingRight: '50px' }}>
          <Avatar src={row?.upload_image} alt="" size="large" />
          <div className="">
            <Link
              to={`${routes.INF_CAMPAIGNS}/${row?.id}`}
              className={styles.inflNameText}
            >
              {row?.campaign_name}
            </Link>
            <div className={styles.chipBtns}>
              {INF_CAMPAIGN_STATUS_CHIPS[campaignStatus]}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    Header: 'Brand',
    accessor: 'brand_name',
    Cell: (cellInfo) => (
      <p className={styles.downloadedTxt}>{cellInfo.value}</p>
    ),
  },
  {
    Header: 'Platform',
    accessor: 'platform',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return (
        <AvatarGroup>
          {row?.target_platforms?.map((platformName) => (
            <Avatar
              src={PLATFORM_ICONS[platformName]}
              alt=""
              key={platformName}
            />
          ))}
        </AvatarGroup>
      );
    },
  },
  {
    Header: 'Duration (Days)',
    accessor: 'duration',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      if (row?.campaign_start_date && row?.campaign_end_date) {
        return `${differenceInCalendarDays(
          new Date(row?.campaign_end_date),
          new Date(row.campaign_start_date)
        )}`;
      }
      return '-';
    },
  },
  {
    Header: 'Region',
    accessor: 'region',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return row?.regions?.map((region) => region.region_name).join(', ');
    },
  },
  {
    Header: 'Gender',
    accessor: 'gender',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return (
        <p style={{ textTransform: 'capitalize' }}>
          {row?.target_genders?.length === 3
            ? 'All'
            : row?.target_genders?.join(', ')}{' '}
        </p>
      );
    },
  },
  {
    Header: 'Age group (Yrs)',
    accessor: 'age_group',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return row?.ages
        ?.map(({ age_from: from, age_to: to }) => `${from}-${to}`)
        .join(', ');
    },
  },
  {
    Header: 'Tags',
    accessor: 'tags',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return row?.social_hashtags?.map((tag) => tag.hashtag_name).join(', ');
    },
  },
  {
    Header: '',
    accessor: 'options',
    width: 55,
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return (
        <div className={styles.profileDiv}>
          <DownloadReport campaignId={row?.id} />
          {/* <button type="button">
          <ShareIcon />
        </button> */}
        </div>
      );
    },
  },
];

function CampaignReports() {
  const {
    page,
    rowsPerPage,
    changePage,
    changeRowsPerPage,
    search,
    changeSearch,
    // sortBy,
    // sortDir,
    // changeSortBy,
    filter,
    changeFilter,
  } = useFilterProps({
    defaultFilters: { status: [CAMPAIGN_STATUS.COMPLETED] },
  });

  const debouncedSearchQuery = useDebounce(search);
  const platformFilter = filter.platform;
  const brandFilter = filter.brand;
  const statusFilter = filter.status;
  const regionFilter = filter.region;
  const ageGroupFilter = filter.ageGroup;
  const genderFilter = filter.gender;

  const {
    data: campaigns,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [
      'influencer-campaigns',
      page,
      rowsPerPage,
      debouncedSearchQuery,
      platformFilter,
      brandFilter,
      statusFilter,
      regionFilter,
      ageGroupFilter,
      genderFilter,
    ],
    queryFn: () =>
      getCampaignReports({
        pageNo: page,
        pageSize: rowsPerPage,
        search: debouncedSearchQuery || undefined,
        platform: platformFilter || undefined,
        brand: brandFilter || undefined,
        status: statusFilter || undefined,
        region: regionFilter || undefined,
        age_from: ageGroupFilter ? ageGroupFilter[0] : undefined,
        age_to: ageGroupFilter ? ageGroupFilter[1] : undefined,
        gender: genderFilter || undefined,
      }),
    select: (res) => res?.data?.campaigns,
  });

  const { data: states } = useQuery({
    queryKey: ['influencer-states'],
    queryFn: () => getStateList(),
  });

  const { data: brands } = useQuery({
    queryKey: ['influencer-campaign-brands-filter'],
    queryFn: () => getBrands(),
  });

  const onSearchChange = (e) => {
    changeSearch(e.target.value);
  };

  const onPlatformFilterRemove = (id) => {
    changeFilter(
      'platform',
      platformFilter?.filter((platform) => platform !== id)
    );
  };
  const onBrandFilterRemove = (id) => {
    changeFilter(
      'brand',
      brandFilter?.filter((brand) => brand !== id)
    );
  };
  // const onStatusFilterRemove = (id) => {
  //   changeFilter(
  //     'status',
  //     statusFilter?.filter((status) => status !== id)
  //   );
  // };

  const onRegionFilterRemove = (id) => {
    changeFilter(
      'region',
      regionFilter?.filter((region) => region !== id)
    );
  };

  const onAgeGroupFilterRemove = () => {
    changeFilter('ageGroup', null);
  };

  const onGenderFilterRemove = (id) => {
    changeFilter(
      'gender',
      genderFilter?.filter((gender) => gender !== id)
    );
  };

  const onClearFilter = () => {
    changeFilter('platform', []);
    changeFilter('brand', []);
    // changeFilter('status', []);
    changeFilter('region', []);
    changeFilter('ageGroup', null);
    changeFilter('gender', []);
  };

  const selectedPlatforms = useMemo(
    () =>
      PLATFORMS.filter((platform) => platformFilter?.includes(platform.value)),
    [platformFilter]
  );

  // const selectedStatus = useMemo(
  //   () =>
  //     INF_CAMPAIGN_STATUS_LIST.filter((status) =>
  //       statusFilter?.includes(status.value)
  //     ),
  //   [statusFilter]
  // );

  const selectedRegions = useMemo(
    () => states?.filter(({ id }) => regionFilter?.includes(id)) || [],
    [states, regionFilter]
  );

  const selectedGenders = useMemo(
    () => GENDERS.filter((gender) => genderFilter?.includes(gender.value)),
    [genderFilter]
  );

  const showClearButton =
    platformFilter?.length ||
    brandFilter?.length ||
    // statusFilter?.length ||
    regionFilter?.length ||
    ageGroupFilter?.length ||
    genderFilter?.length;
  return (
    <>
      <div className={styles.dashboardTop}>
        <img className={styles.transBg} src={transparentBg} alt="" />
        <div className={styles.campaignContainer}>
          <h2 className={styles.campignTxt}>Campaign Reports</h2>
        </div>
      </div>
      <div>
        <div className={styles.influencerHeader}>
          <div className={styles.categorySelector}>
            <FilterDropdown
              options={PLATFORMS}
              label="Platform"
              startIconUrl={cogwheelIcon}
              onChange={(newValue) => changeFilter('platform', newValue)}
              value={platformFilter}
            />
            <FilterDropdown
              options={brands}
              label="Brand"
              labelSelector="name"
              valueSelector="name"
              startIconUrl={cogwheelIcon}
              onChange={(newValue) => changeFilter('brand', newValue)}
              value={brandFilter}
            />
            {/* <FilterDropdown
              options={INF_CAMPAIGN_STATUS_LIST}
              label="Status"
              startIconUrl={statusIcon}
              onChange={(newValue) => changeFilter('status', newValue)}
              value={statusFilter}
            /> */}
            <FilterDropdown
              options={states}
              label="Region"
              labelSelector="name"
              valueSelector="id"
              startIconUrl={locationSvg}
              onChange={(newValue) => changeFilter('region', newValue)}
              value={regionFilter}
            />
            <div style={{ width: 173, flexShrink: 0 }}>
              <FilterMenu.RangeSelector
                label="Age Group"
                startIconUrl={targetAudienceIcon}
                minLimit={0}
                maxLimit={100}
                onClear={() => changeFilter('ageGroup', null)}
                onApply={(newValue) => changeFilter('ageGroup', newValue)}
                buttonText={
                  ageGroupFilter
                    ? `${ageGroupFilter[0]} - ${ageGroupFilter[1]} yrs`
                    : 'All'
                }
                value={ageGroupFilter}
                isMenuChild={false}
                placement="bottom-start"
                fullWidth
                portal
              />
            </div>
            <FilterDropdown
              options={GENDERS}
              label="Gender"
              startIconUrl={targetGendrIcon}
              onChange={(newValue) => changeFilter('gender', newValue)}
              value={genderFilter}
            />
          </div>
        </div>
        <div className="influencerList">
          <div className={styles.influencerSelectors}>
            {selectedPlatforms?.map(({ icon, label, value }) => (
              <FilterChip
                icon={icon}
                label={label}
                isActive
                key={label}
                value={value}
                onRemove={onPlatformFilterRemove}
              />
            ))}
            {brandFilter?.map((brand) => (
              <FilterChip
                label={brand}
                key={brand}
                value={brand}
                onRemove={onBrandFilterRemove}
              />
            ))}
            {/* {selectedStatus?.map(({ label, value }) => (
              <FilterChip
                label={label}
                key={value}
                value={value}
                onRemove={onStatusFilterRemove}
              />
            ))} */}
            {selectedRegions?.map((region) => (
              <FilterChip
                label={region.name}
                key={region.id}
                value={region.id}
                onRemove={onRegionFilterRemove}
              />
            ))}
            {ageGroupFilter && (
              <FilterChip
                label={`Age ${ageGroupFilter[0]}-${ageGroupFilter[1]}`}
                onRemove={onAgeGroupFilterRemove}
              />
            )}
            {selectedGenders?.map(({ label, value }) => (
              <FilterChip
                label={label}
                key={value}
                value={value}
                onRemove={onGenderFilterRemove}
              />
            ))}
            {showClearButton ? (
              <Button variant="text" onClick={onClearFilter}>
                Clear Filters
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.influencerHeader}>
        <div className={styles.subCategoryTitle}>
          <h2 className={styles.trendingTxt}>
            {formatNumber(campaigns?.count)} Campaign Reports Found
          </h2>
          <div className={clsx(styles.searchField, styles.campaignField)}>
            <div className={styles.searchAction}>
              <img className={styles.sIcon} src={searchImg} alt="" />
              <input
                className={styles.searchTxt}
                placeholder="Type to search list below"
                type="text"
                value={search || ''}
                onChange={onSearchChange}
              />
            </div>
          </div>
        </div>
        <Table
          data={campaigns?.rows}
          columns={columns}
          paginationProps={{
            currentPage: page,
            changePage,
            count: campaigns?.count,
            rowsPerPage,
            changeRowsPerPage,
          }}
          isLoading={isLoading || isFetching}
          noDataValue={
            <TableError
              infoText="No Campaign Reports Found."
              searchQuery={debouncedSearchQuery}
            />
          }
        />

        <FullPageLoader loadingText="Exporting report, please wait..." />
      </div>
    </>
  );
}

export default CampaignReports;
