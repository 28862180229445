import React from 'react';
import useSyncronizedAnimations from 'hooks/useSyncronizedAnimations';
import styles from './Loader.module.scss';

const Loader = (props) => {
  const ref = useSyncronizedAnimations();

  return (
    <svg
      className={styles.spinner}
      width="24px"
      height="24px"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <circle
        className={styles.path}
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
        ref={ref}
      />
    </svg>
  );
};

export default Loader;
