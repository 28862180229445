import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import ErrorMessage from 'components/common/form/ErrorMessage';
import { Dialog } from 'components/common/dialog';
import { queryClient } from 'App';
import { editProfile, getProfile } from 'api/brand/profile';
import { BRAND_PROFILE_PANDOC_SCHEMA } from 'constants/schema';
import styles from 'assets/scss/pages/popup.module.scss';
import { purplePlusSign, redCross } from 'constants/images';

const defaultValues = {
  panDoc: '',
  hasPanDoc: '',
  pan_card_no: '',
};

const EditProfilePanDoc = ({ open, close }) => {
  const { data } = useQuery({
    queryKey: ['brand-profile'],
    queryFn: () => getProfile(),
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BRAND_PROFILE_PANDOC_SCHEMA),
    defaultValues,
  });

  const editProfileMutation = useMutation({
    mutationFn: ({ panDoc, pan_card_no: panNo }) => {
      const payload = new FormData();
      if (panDoc?.[0]) payload.append('pan_card_upload', panDoc[0]);
      payload.append('pan_card_no', panNo);
      return editProfile(payload);
    },
    onSuccess: (data) => {
      toast.success(data?.message, { position: 'top-center' });
      queryClient.invalidateQueries({ queryKey: ['brand-profile'] });
      reset();
      close();
    },
  });

  useEffect(() => {
    if (data?.data) {
      const { pan_card_upload: uploadedPan, pan_card_no: panCardNo } =
        data?.data?.getProfile || {};
      reset({
        hasPanDoc: uploadedPan?.split('/')?.slice(-1)?.[0]?.split('?') || '',
        pan_card_no: panCardNo,
      });
    }
  }, [data, reset]);

  const panDoc = watch('panDoc')?.[0];
  const uploadedDoc = watch('hasPanDoc')?.[0];

  const onGstDocRemove = () => {
    setValue('panDoc', '');
    setValue('hasPanDoc', '');
  };

  return (
    <Dialog
      title="Update PAN Details"
      open={open}
      close={close}
      size="591px"
      fullWidth
    >
      <form onSubmit={handleSubmit(editProfileMutation.mutate)}>
        <div className={styles.editFormWrapper}>
          <div className={styles.formGroup}>
            <label style={{ color: '#757575' }}>PAN Card Number</label>
            <input
              className={styles.formPanInput}
              label="Pan Number"
              type="panInput"
              // error={errors?.pan_card_no}
              {...register('pan_card_no')}
            />
            <ErrorMessage error={errors?.pan_card_no} />
          </div>
          <div className={styles.formGroup}>
            <label style={{ color: '#757575' }}>Upload PAN Card Document</label>
            <div className={styles.gstDiv}>
              <div className={styles.gstSubPart}>
                <p className={styles.gstFileUploadField} title={panDoc?.name}>
                  {panDoc?.name || uploadedDoc}
                </p>
                {(panDoc || uploadedDoc) && (
                  <button
                    type="button"
                    className={styles.docRemoveBtn}
                    onClick={onGstDocRemove}
                  >
                    <img className={styles.redCrossImg} src={redCross} alt="" />
                  </button>
                )}
              </div>
              <div className={styles.gstUploadWrapper}>
                <input type="text" {...register('hasPanDoc')} hidden />
                <label
                  className={clsx({
                    [styles.uploadFileBtn]: true,
                    [styles.disabled]: panDoc || uploadedDoc,
                  })}
                >
                  <input
                    type="file"
                    {...register('panDoc')}
                    accept="application/pdf"
                    hidden
                    disabled={panDoc || uploadedDoc}
                  />
                  <img src={purplePlusSign} alt="" /> Upload
                </label>
              </div>
            </div>
            <ErrorMessage error={errors?.panDoc} />
            <p className={styles.formLabel}>Only PDF files are allowed.</p>
          </div>
        </div>
        <div className={styles.editProfileContainer}>
          <div
            className={clsx(styles.campaignBtns, styles.nxtWrap)}
            style={{ marginTop: '16px' }}
          >
            <button type="button" className={styles.prevBtn} onClick={close}>
              Back
            </button>
            <button
              type="submit"
              style={{ width: '30%' }}
              className={styles.updateBtn}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

EditProfilePanDoc.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default EditProfilePanDoc;
