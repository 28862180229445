import React from 'react';
import PropTypes from 'prop-types';
import styles from 'assets/scss/pages/popup.module.scss';

const InputWrapper = ({ children, icon }) => (
  <div className={styles.secBodyWrapper} style={{ position: 'relative' }}>
    <img src={icon} alt="" />
    {children}
  </div>
);

InputWrapper.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
};

export default InputWrapper;
