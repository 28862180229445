import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Dialog } from 'components/common/dialog';
import styles from 'assets/scss/pages/popup.module.scss';
import { coloredCrossIcon, transparentBg } from 'constants/images';
import { Tab } from '@headlessui/react';
import LinkImportForm from './LinkImportForm';
import DirectUploadForm from './DirectUploadForm';

const UploadCampaignDeliverable = ({
  open,
  close,
  platform,
  deliverableType,
  campaignTags,
  campaignId,
}) => (
  <Dialog open={open} close={close} fullWidth fullHeight>
    <Tab.Group>
      <div className={styles.dashboardTop}>
        <img className={styles.transBg} src={transparentBg} alt="" />
        <div className={styles.campaignContainer} style={{ padding: '25px' }}>
          <div className={styles.innrWrapper}>
            <h2 className={styles.creationTxt}>
              {platform} {deliverableType}
            </h2>
            <button onClick={close} type="button">
              <img src={coloredCrossIcon} alt="" />
            </button>
          </div>
        </div>
        <Tab.List as="div" className={styles.categoryButtons}>
          <Tab
            className={({ selected }) =>
              clsx({
                [styles.influencerCateBtn]: true,
                [styles.whiteActiveBtn]: selected,
              })
            }
          >
            Link Import
          </Tab>
          <Tab
            className={({ selected }) =>
              clsx({
                [styles.influencerCateBtn]: true,
                [styles.whiteActiveBtn]: selected,
              })
            }
          >
            Direct Upload
          </Tab>
        </Tab.List>
      </div>
      <Tab.Panels>
        <Tab.Panel as="div">
          <LinkImportForm
            close={close}
            platform={platform}
            deliverableType={deliverableType}
            campaignTags={campaignTags}
            campaignId={campaignId}
          />
        </Tab.Panel>
        <Tab.Panel as="div">
          <DirectUploadForm
            close={close}
            platform={platform}
            deliverableType={deliverableType}
            campaignTags={campaignTags}
            campaignId={campaignId}
          />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  </Dialog>
);

UploadCampaignDeliverable.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  platform: PropTypes.oneOf(['instagram', 'youtube', 'twitter', 'facebook']),
  deliverableType: PropTypes.string,
  campaignTags: PropTypes.array,
  campaignId: PropTypes.string,
};

export default UploadCampaignDeliverable;
