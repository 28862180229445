import { baseQuery } from './baseQuery';

const apiVersion = process.env.REACT_APP_API_VERSION;

export const createContactRequest = (body) =>
  baseQuery({
    url: `/admin/${apiVersion}/contact-us`,
    method: 'POST',
    body,
  });
