import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/common/Button';

import { EMAIL_SCHEMA } from 'constants/schema';
import routes from 'constants/routes';
import { signup } from 'api/brand/auth';
import styles from 'assets/scss/pages/onboarding.module.scss';
import GetInTouchDialog from 'components/onboarding/GetInTouchDialog';
import { useBrandOnboardingForm } from 'stores/onboarding';

const defaultValues = { email: '' };

const BrandSignupForm = () => {
  const [getInTouchMail, setGetInTouchMail] = useState('');
  const navigate = useNavigate();

  const { formData, reset: resetOnboarding } = useBrandOnboardingForm();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EMAIL_SCHEMA),
    defaultValues,
  });

  const signupMutation = useMutation({
    mutationFn: ({ email }) =>
      signup({
        email,
        influencer_campaigns:
          formData.campaignType === 'both'
            ? { in_house_team: true, external_agency: true }
            : { [formData.campaignType]: true },
      }),
    onSuccess: (data, variables) => {
      if (data?.data?.is_agency) {
        resetOnboarding();
        navigate(routes.BRAND_SIGNUP_VERIFY_OTP, { state: variables });
      } else {
        reset(defaultValues);
        setGetInTouchMail(variables.email);
      }
    },
  });

  const onGetInTouchClose = (value) => {
    resetOnboarding();
    setGetInTouchMail(value);
  };

  return (
    <>
      <h1>Sign Up As A Brand</h1>
      <p>Enter details to sign up as a brand</p>
      <form
        className={styles.signupFormItems}
        onSubmit={handleSubmit(signupMutation.mutate)}
      >
        <div>
          <input
            type="email"
            placeholder="Enter Your Email ID"
            maxLength="70"
            {...register('email')}
          />
          {errors?.email ? (
            <p className={styles.error}>{errors.email?.message}</p>
          ) : null}
        </div>
        <Button
          type="submit"
          size="large"
          isLoading={signupMutation.isLoading}
          fullWidth
        >
          Sign Up
        </Button>
      </form>
      <p className={styles.signupLink}>
        Already have an account?{' '}
        <Link to={routes.BRAND_LOGIN}>Sign in here</Link>
      </p>
      <GetInTouchDialog
        setIsOpen={onGetInTouchClose}
        isOpen={Boolean(getInTouchMail)}
        email={getInTouchMail}
      />
    </>
  );
};

export default BrandSignupForm;
