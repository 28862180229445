import React, { useEffect, useId, useRef } from 'react';
import { animate, motion } from 'framer-motion';

const UsersOnInstagramDarkPink = (props) => {
  const gradient1 = useId();
  const filter1 = useId();
  const filter2 = useId();

  const numberRef = useRef();

  useEffect(() => {
    const node = numberRef.current;
    if (!node) return;

    const controls = animate(0, 47, {
      duration: 1,
      delay: 1,
      onUpdate(value) {
        node.textContent = `${Math.round(value).toString()}%`;
      },
    });

    // eslint-disable-next-line consistent-return
    return () => controls.stop();
  }, []);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={202} height={202} {...props}>
      <defs>
        <filter
          id={filter1}
          width={202}
          height={202}
          x={0}
          y={0}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={4} dy={5} />
          <feGaussianBlur result="blur" stdDeviation={5} />
          <feFlood floodColor="#fff" floodOpacity={0.502} />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id={filter2}
          width={103.5}
          height={103.5}
          x={45.25}
          y={44.25}
          filterUnits="userSpaceOnUse"
        >
          <feOffset />
          <feGaussianBlur result="blur-2" stdDeviation={7.5} />
          <feFlood floodColor="#fff" floodOpacity={0.4} result="color" />
          <feComposite in="SourceGraphic" in2="blur-2" operator="out" />
          <feComposite in="color" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <linearGradient
          id={gradient1}
          x2={0.984}
          y1={0.5}
          y2={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.607} stopColor="#ff50bf" stopOpacity={0.239} />
          <stop offset={1} stopColor="#ff00a2" />
        </linearGradient>
      </defs>
      <g data-name="Group 55309">
        <g filter={`url(#${filter1})`}>
          <g
            fill={`url(#${gradient1})`}
            stroke="#fff"
            strokeWidth={1.5}
            data-name="Ellipse 1524"
            transform="translate(11 10)"
          >
            <circle cx={86} cy={86} r={86} stroke="none" />
            <circle cx={86} cy={86} r={85} fill="none" />
          </g>
        </g>
        <g data-type="innerShadowGroup" transform="translate(-834 -569)">
          <g filter={`url(#${filter2})`} transform="translate(834 569)">
            <circle
              cx={51}
              cy={51}
              r={52}
              fill="#fff"
              data-name="Ellipse 1525"
              transform="translate(46 45)"
            />
          </g>
          <circle
            cx={51}
            cy={51}
            r={51}
            fill="none"
            stroke="#fff"
            strokeWidth={1.5}
            data-name="Ellipse 1525"
            transform="translate(880 614)"
          />
        </g>
        <g
          fill="none"
          stroke="#8D0059"
          strokeDasharray="250 1000"
          strokeWidth={35}
          data-name="Ellipse 1526"
          transform="translate(11 10)"
        >
          <circle cx={86} cy={86} r={86} stroke="none" />
          <motion.circle
            cx={86}
            cy={86}
            r={68}
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 0.47 }}
            transition={{ duration: 1, delay: 1 }}
            transform="rotate(-90, 86, 86)"
          />
        </g>
        <text
          fill="#fff"
          data-name="47%"
          fontFamily="Poppins-Bold, Poppins"
          fontSize={35}
          fontWeight={700}
          transform="translate(97 109)"
        >
          <tspan x={-36} y={0} ref={numberRef} />
        </text>
      </g>
    </svg>
  );
};
export default UsersOnInstagramDarkPink;
