import React from 'react';
import { Link } from 'react-router-dom';
import routes from 'constants/routes';
import styles from 'assets/scss/pages/landing-homepage.module.scss';
import { arrowBlackImg } from 'constants/images';
import { caseStudies } from 'constants/config/case-studies';

const CaseStudies = () => (
  <div className={styles.questionSec}>
    <div className={styles.queryBlock1}>
      <h3 className={styles.queTxt}>Case Studies</h3>
      <p className={styles.assistanceTXT}>
        Yet bed any for assistance indulgence unpleasing. Not thoughts all
        exercise blessing. Indulgence way everything joy alteration boisterous
        the attachment.
      </p>
      <Link to={routes.CASE_STUDIES} className={styles.moreFaqBtn}>
        More Casestudy <img src={arrowBlackImg} alt="" />
      </Link>
    </div>
    <div className={styles.caseStudiesWrapper}>
      {caseStudies.map(({ id, ...rest }) => (
        <CaseStudyCard key={id} id={id} {...rest} />
      ))}
    </div>
  </div>
);

const CaseStudyCard = ({ id, videoUrl, title, shortDesc }) => (
  <div className={styles.caseStudyCard}>
    <iframe
      src={videoUrl}
      className={styles.caseStudyVideo}
      title={title}
      width="560"
      height="315"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    />
    <h4 className={styles.caseStudyTitle}>{title}</h4>
    <div className={styles.caseStudyDescSection}>
      <p className={styles.caseStudyDesc}>{shortDesc}</p>
    </div>
    <div>
      <Link
        to={`${routes.CASE_STUDIES}/${id}`}
        className={styles.caseStudyReadMore}
      >
        Read More <img src={arrowBlackImg} alt="" />
      </Link>
    </div>
  </div>
);

export { CaseStudyCard };
export default CaseStudies;
