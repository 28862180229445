import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  downloadCampaignMeetingNote,
  getCampaignMeetingNotes,
} from 'api/brand/campaign';
import Table from 'components/common/Table';
import TableError from 'components/common/Table/TableError';
import styles from 'assets/scss/pages/campaign.module.scss';
import {
  featherDownloadImg,
  // featherEyeImg,
  pdfImg,
  txtImg,
  docxImg,
} from 'constants/images';
import { format } from 'date-fns';
import { saveFile } from 'utils';

const columns = [
  {
    Header: 'Title',
    accessor: 'meeting_agenda',
    width: 350,
    minWidth: 300,
    Cell: (cellInfo) => (
      <p className={styles.meetingNoteTitle}>{cellInfo.value}</p>
    ),
  },
  {
    Header: 'Date',
    accessor: 'date',
    width: 150,
    minWidth: 150,
    Cell: (cellInfo) => (
      <p className={styles.downloadedTxt}>
        {cellInfo.value ? format(new Date(cellInfo.value), 'dd/MM/yyyy') : ''}
      </p>
    ),
  },
  {
    Header: 'Uploaded By',
    accessor: 'uploaded_by',
    width: 250,
    minWidth: 250,
    Cell: (cellInfo) => (
      <p className={styles.meetingNoteTitle}>{cellInfo.value}</p>
    ),
  },
  {
    Header: 'File Type',
    accessor: 'file_type',
    width: 120,
    minWidth: 120,
    Cell: (cellInfo) => (
      <>
        {cellInfo.value === 'pdf' && <img src={pdfImg} alt="" />}
        {cellInfo.value === 'text' && <img src={txtImg} alt="" />}
        {cellInfo.value === 'docx' && <img src={docxImg} alt="" />}
        {cellInfo.value === 'doc' && <img src={docxImg} alt="" />}
      </>
    ),
  },
  {
    Header: '',
    accessor: 'actions',
    width: 110,
    minWidth: 110,
    Cell: (cellInfo) => {
      const noteId = cellInfo.row?.original?.id;
      const fileType = cellInfo.row?.original?.file_type;

      const getMimeType = (type) => {
        switch (type) {
          case 'pdf':
            return 'application/pdf';
          case 'docx':
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          case 'doc':
            return 'application/msword';
          default:
            return 'text/plain';
        }
      };

      const downloadMeetingNoteMutation = useMutation({
        mutationFn: ({ meetingNoteId }) =>
          downloadCampaignMeetingNote({ meeting_note_id: meetingNoteId }),
        onSuccess: (res) => {
          saveFile(res, getMimeType(fileType), `meeting_notes`);
        },
      });
      return (
        <div className={styles.downloadImgDiv}>
          {/* <button type="button">
            <img src={featherEyeImg} alt="" />
          </button> */}
          <button
            type="button"
            onClick={() =>
              downloadMeetingNoteMutation.mutate({ meetingNoteId: noteId })
            }
            disabled={downloadMeetingNoteMutation.isLoading}
          >
            <img src={featherDownloadImg} alt="" />
          </button>
        </div>
      );
    },
  },
];

const MeetingNotes = () => {
  const { id: campaignId } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ['brand-campaign-meeting-notes', campaignId],
    queryFn: () => getCampaignMeetingNotes({ id: campaignId }),
  });

  const meetingNotes = data?.data?.meetingnotes?.rows;

  return (
    <section>
      <div style={{ padding: '15px 30px' }}>
        <Table
          data={meetingNotes}
          columns={columns}
          isLoading={isLoading}
          container={false}
          noDataValue={<TableError infoText="No metting notes found." />}
        />
      </div>
    </section>
  );
};

export default MeetingNotes;
