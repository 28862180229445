import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { subscribeEmail } from 'api/common/emailSubscription';
import routes from 'constants/routes';
import { EMAIL_SCHEMA } from 'constants/schema';
// import {
//   facebookImage,
//   instagramImage,
//   linkedinImage,
//   twitterImage,
//   youtubeImage,
// } from 'constants/images';
import styles from './Footer.module.scss';

const FOOTER_VARIENTS = {
  DEFAULT: 'default',
  INF: 'influencer',
  BRAND: 'brand',
};

const Footer = ({
  varient = FOOTER_VARIENTS.DEFAULT,
  showEmailSubscription = true,
}) => {
  const { register, handleSubmit, reset } = useForm({
    resolver: yupResolver(EMAIL_SCHEMA),
    defaultValues: { email: '' },
  });

  const emailSubscribeMutation = useMutation({
    mutationFn: ({ email }) => subscribeEmail({ email, website: varient }),
    onSuccess: (data) => {
      reset();
      toast.success(data?.message);
    },
  });

  return (
    <div>
      <footer className={styles.footer}>
        {showEmailSubscription && (
          <div className={styles.footerHeader}>
            <h1 className={styles.joinTxt}>
              {varient === FOOTER_VARIENTS.DEFAULT
                ? 'Join Millions of creators NOW'
                : 'Be part of a worldwide community of creators'}
            </h1>
            <form
              className={styles.addressSubscribe}
              onSubmit={handleSubmit(emailSubscribeMutation.mutate)}
            >
              <input
                className={styles.emailInput}
                type="email"
                placeholder="Your Email Address"
                autoComplete="email"
                {...register('email', { required: true })}
              />
              <button
                type="submit"
                className={clsx({
                  [styles.subBtn]: true,
                  [styles.pinkBg]: varient === FOOTER_VARIENTS.INF,
                })}
                disabled={emailSubscribeMutation.isLoading}
              >
                Subscribe
              </button>
            </form>
          </div>
        )}
        <div className={styles.footerTop}>
          <div className={styles.footerLeft}>
            <div>
              <p className={styles.pTitle}>
                Discover the all-in-one platform - SMINCO, where entertainment
                meets learning, data analysis, and influencer marketing!
              </p>
              <div className={styles.socialLinks}>
                {/* <a href="#">
                  <img src={facebookImage} alt="" />
                </a>
                <a href="#">
                  <img src={instagramImage} alt="" />
                </a>
                <a href="#">
                  <img src={twitterImage} alt="" />
                </a>
                <a href="#">
                  <img src={youtubeImage} alt="" />
                </a>
                <a href="#">
                  <img src={linkedinImage} alt="" />
                </a> */}
              </div>
            </div>
          </div>
          <div className={styles.footerNav}>
            <h2>OVERVIEW</h2>
            <nav>
              <Link to={routes.ABOUT_US}>About Us</Link>
              <Link to={routes.PRIVACY_POLICY}>Privacy Policy</Link>
              <Link to={routes.BRAND_TERMS_AND_CONDTIONS}>
                Terms of Use - Brands
              </Link>
              <Link to={routes.INF_TERMS_AND_CONDTIONS}>
                Terms of Use - Influencers
              </Link>
              {/* <Link to={routes.SUBSCRIPTION}>Pricing</Link> */}
            </nav>
          </div>
          <div className={styles.footerNav}>
            <h2>CONNECT WITH US</h2>
            <nav>
              <Link to={routes.CONTACT_US}>Contact Us</Link>
            </nav>
          </div>
          <div className={styles.footerNav}>
            <h2>RESOURCES</h2>
            <nav>
              <Link to={routes.CASE_STUDIES}>Case studies</Link>
              <Link
                to={
                  varient === FOOTER_VARIENTS.DEFAULT
                    ? routes.FAQ
                    : `${routes.FAQ}?type=${varient}`
                }
              >
                FAQ's
              </Link>
            </nav>
          </div>
        </div>
        <div
          className={clsx({
            [styles.footerBottom]: true,
            [styles.pinkBg]: varient === FOOTER_VARIENTS.INF,
          })}
        >
          <p className={styles.pTitle}>
            Copyright 2024 - ©2024 Music Broadcast Ltd. All Rights Reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

Footer.propTypes = {
  varient: PropTypes.oneOf(Object.values(FOOTER_VARIENTS)),
  showEmailSubscription: PropTypes.bool,
};

export default Footer;
