import React, { useEffect, useId } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { getStateList } from 'api/brand/search';
import { useCreateCampaign } from 'stores/create-campaign';
import { BRAND_CREATE_CAMPAIGN_STEP2_SCHEMA } from 'constants/schema';
import { PLATFORMS } from 'constants/config/filters';
import ErrorMessage from 'components/common/form/ErrorMessage';
import { Select } from 'components/common/campaign';
import styles from 'assets/scss/pages/popup.module.scss';
import {
  cogwheelIcon,
  coloredCrossIcon,
  pinkAddIcon,
  targetAudienceIcon,
  targetGendrIcon,
  targetReginIcon,
} from 'constants/images';

const defaultAgeGroup = { from: '', to: '' };

const defaultValues = {
  region: [],
  gender: [],
  ageGroup: [defaultAgeGroup],
  platforms: [],
};

const StepTwo = () => {
  const { formData, goToNextStep, goToPrevStep, setFormData } =
    useCreateCampaign();

  const { data: states } = useQuery({
    queryKey: ['brand-states'],
    queryFn: () => getStateList(),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BRAND_CREATE_CAMPAIGN_STEP2_SCHEMA),
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ageGroup',
  });

  const onSubmit = (data) => {
    setFormData(data);
    goToNextStep();
  };

  const onAgeGroupAdd = () => {
    append(defaultAgeGroup);
  };

  const onGoBack = () => {
    const currentFormData = getValues();
    setFormData(currentFormData);
    goToPrevStep();
  };

  useEffect(() => {
    if (formData.region) {
      reset({
        region: formData.region || [],
        gender: formData.gender || [],
        ageGroup: formData.ageGroup || defaultValues.ageGroup,
        platforms: formData.platforms || [],
      });
    }
  }, [reset, formData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.secBody}>
        <div className={styles.secBodyWrapper}>
          <img src={targetReginIcon} alt="" />
          <div className={styles.popupInputWrap}>
            <Controller
              render={({ field }) => (
                <Select
                  label="Region"
                  onChange={field.onChange}
                  value={field.value}
                  options={states}
                  labelSelector="name"
                  valueSelector="id"
                  multiple
                  showSelectAll
                />
              )}
              control={control}
              name="region"
            />
            <ErrorMessage error={errors?.region} />
          </div>
        </div>
        <div className={styles.secBodyWrapper}>
          <img src={targetGendrIcon} alt="" />
          <div className={styles.popupInputWrap}>
            <h2 className={styles.hTxt}>Gender</h2>
            <div className={styles.genderBox}>
              <GenderInput label="Male" value="male" {...register('gender')} />
              <GenderInput
                label="Female"
                value="female"
                {...register('gender')}
              />
              <GenderInput
                label="Other"
                value="other"
                {...register('gender')}
              />
            </div>
            <ErrorMessage error={errors?.gender} />
          </div>
        </div>
        <div className={styles.secBodyWrapper}>
          <img src={targetAudienceIcon} alt="" />
          <div className={styles.popupInputWrap}>
            <h2 className={styles.hTxt}>Age Group</h2>
            {fields.map((field, index) => (
              <div key={field.id}>
                <div className={styles.ageGroup}>
                  <h2 className={styles.hTxt}>From</h2>
                  <div className={styles.innrTimeTxt}>
                    <input
                      {...register(`ageGroup.${index}.from`)}
                      type="number"
                      min="5"
                      max="100"
                      className={styles.ageInput}
                    />
                    yrs
                  </div>
                  <h2 className={styles.hTxt}>To</h2>
                  <div className={styles.innrTimeTxt}>
                    <input
                      {...register(`ageGroup.${index}.to`)}
                      type="number"
                      min="5"
                      max="100"
                      className={styles.ageInput}
                    />
                    yrs
                  </div>
                  {fields.length > 1 && (
                    <button type="button" onClick={() => remove(index)}>
                      <img src={coloredCrossIcon} alt="remove" />
                    </button>
                  )}
                </div>
                <ErrorMessage
                  error={
                    errors?.ageGroup?.[index]?.from ||
                    errors?.ageGroup?.[index]?.to
                  }
                />
              </div>
            ))}
            {fields.length < 5 && (
              <div className="tagsField">
                <button
                  type="button"
                  className={styles.addNewBtn}
                  onClick={onAgeGroupAdd}
                >
                  <img src={pinkAddIcon} alt="" /> Add New
                </button>
              </div>
            )}
          </div>
        </div>
        <div className={styles.secBodyWrapper}>
          <img src={cogwheelIcon} alt="" />
          <div className={styles.popupInputWrap}>
            <Controller
              render={({ field }) => (
                <Select
                  label="Choose Platform"
                  onChange={field.onChange}
                  value={field.value}
                  options={PLATFORMS}
                  multiple
                />
              )}
              control={control}
              name="platforms"
            />
            <ErrorMessage error={errors?.platforms} />
          </div>
        </div>
      </div>
      <div className={styles.createInfFooter}>
        <div className={clsx(styles.campaignBtns, styles.nxtWrap)}>
          <button type="button" onClick={onGoBack} className={styles.prevBtn}>
            Previous
          </button>
          <button
            type="submit"
            style={{ width: 166 }}
            className={styles.updateBtn}
          >
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

const GenderInput = React.forwardRef(({ label, ...rest }, ref) => {
  const inputId = useId();
  return (
    <div className={styles.innrTnput}>
      <input
        className={styles.checkBox}
        type="checkbox"
        {...rest}
        ref={ref}
        id={inputId}
      />
      <label className={styles.genderLabel} htmlFor={inputId}>
        {label}
      </label>
    </div>
  );
});

GenderInput.propTypes = {
  label: PropTypes.node,
};

export default StepTwo;
