import { useCallback, useRef, useState } from 'react';
import useEventListener from 'hooks/useEventListener';

const useElementDimensions = () => {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState(null);

  const refresh = useCallback(() => {
    const domRect = ref.current?.getBoundingClientRect();

    if (domRect) {
      setDimensions(domRect);
    }
  }, []);

  useEventListener('resize', refresh);

  return { dimensions, ref, refresh };
};

export default useElementDimensions;
