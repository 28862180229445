import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import styles from 'assets/scss/pages/landing-homepage.module.scss';
import { facebookSvg, instaSvg, quoteUpImg } from 'constants/images';

const influencerTestimonials = [
  {
    id: 1,
    name: 'RJ Archana',
    testimonial:
      "SMINCO has become my go-to platform for sharing my voice and connecting with a community of passionate creators committed to making a difference. It's a space that truly amplifies my presence.",
    image:
      'https://storage.googleapis.com/radiocity-uat/1709206476485-IMG_6567.JPG',
    designation: 'Radio Jockey | Presenter | Voice Artiste | Performer',
    socials: [
      { platform: 'instagram', followers: 239000, handle: 'archanaapania' },
      { platform: 'facebook', followers: 5000, handle: 'RJ Archana Pania' },
    ],
  },
  {
    id: 2,
    name: 'RJ Salil',
    testimonial:
      'Being part of the SMINCO platform is a source of genuine satisfaction for me. It not only provides me with opportunities to collaborate with powerful brands but also spotlights extraordinary campaigns that resonate with my passions.',
    image:
      'https://storage.googleapis.com/radiocity-uat/1709206594551-IMG_6687.JPG',
    designation: 'TV/Film/Radio Guy | TEDx Speaker',
    socials: [
      { platform: 'instagram', followers: 375000, handle: 'salilacharya' },
      { platform: 'facebook', followers: 5000, handle: 'Salil' },
    ],
  },
  {
    id: 3,
    name: 'RJ Netra',
    testimonial:
      "SMINCO offers incredible insights into my audience's preferences, allowing me to tailor my content accordingly. The data and analytics available here are truly invaluable, making my experience on the platform immensely enriching.",
    image:
      'https://storage.googleapis.com/radiocity-uat/1709206879780-IMG_3515.JPG',
    designation: 'RJ | Programming Director | Actor | BBK03 | Blogger',
    socials: [
      { platform: 'instagram', followers: 67000, handle: 'naan_nethra' },
      { platform: 'facebook', followers: 5000, handle: 'RJ Netraa' },
    ],
  },
  {
    id: 4,
    name: 'RJ Divya',
    testimonial:
      "SMINCO has given me a distinct voice in the creator's economy space. The platform's seamless design and user-friendly interface have made expressing myself and connecting with my audience an effortless experience.",
    image:
      'https://storage.googleapis.com/radiocity-uat/1709207008177-IMG_0269.JPG',
    designation: 'RJ/ Podcaster/ TEDx Speaker/ Blogger',
    socials: [
      { platform: 'instagram', followers: 15600, handle: 'rjdivyaa' },
      { platform: 'facebook', followers: 5000, handle: 'RJ Divya' },
    ],
  },
  {
    id: 5,
    name: 'RJ Ginnie',
    testimonial:
      'As someone venturing into the digital realm, SMINCO has been a revelation for me. It not only allows me to connect with fellow creators who share my passion but also provides an invaluable opportunity to learn content strategies from industry leaders.',
    image:
      'https://storage.googleapis.com/radiocity-uat/1709207101987-ginni.jpeg',
    designation: 'RJ/Artist/Digital Creator',
    socials: [
      { platform: 'instagram', followers: 18100, handle: 'rjginnie' },
      { platform: 'facebook', followers: 5000, handle: 'RJ Ginnie' },
    ],
  },
  {
    id: 6,
    name: 'RJ Rajas',
    testimonial:
      "My journey on SMINCO has been nothing short of fantastic. The platform not only supports me through the intricacies of campaign management but also contributes to building my social capital through comprehensive industry data and analytics. It's been an invaluable resource for my growth and success.",
    image:
      'https://storage.googleapis.com/radiocity-uat/1709207172890-IMG_3572.JPG',
    designation: 'RJ | Host Taste Of Bengaluru | Food Influencer | Voice Actor',
    socials: [
      { platform: 'instagram', followers: 72400, handle: 'rjrajas' },
      { platform: 'facebook', followers: 5000, handle: 'RJ Rajas' },
    ],
  },
];

const InfluencerTestimonials = () => {
  const [_, setInit] = useState();
  const paginationRef = useRef(null);

  return (
    <div className={styles.feedbackWrapper}>
      <div className={styles.sliderHeader}>
        <h2 className={styles.serviceTxt}>What Are They Saying?</h2>
        <p className={styles.serviceTxt2}>
          Join the fastest growing community on SMINCO, where thousands of
          influencers and brands have already found success. Don't miss out –
          become a part of the next big thing in influencer marketing!
        </p>
      </div>
      <div className={styles.sliderBody} style={{ padding: '0 16px' }}>
        <div>
          <Swiper
            modules={[Navigation, Pagination, A11y, Autoplay]}
            slidesPerView={1}
            pagination={{ clickable: true, el: paginationRef.current }}
            onInit={() => setInit(true)}
            className="testimonials-slider"
            spaceBetween={50}
            breakpoints={{
              1024: {
                slidesPerView: 1.5,
                spaceBetween: 30,
              },
            }}
            autoplay={{ delay: 3000, disableOnInteraction: true }}
            centeredSlides
          >
            {influencerTestimonials.map(({ id, ...rest }) => (
              <SwiperSlide key={id} className="influencer-slide">
                <InfluencerTestimonialCard {...rest} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div>
            <div ref={paginationRef} className="testimonials-pagination" />
          </div>
        </div>
      </div>
    </div>
  );
};

const InfluencerTestimonialCard = ({
  designation,
  image,
  name,
  testimonial,
  socials,
}) => (
  <div className={styles.infTestimonialContainer}>
    <div className={styles.leftBorder} />
    <div className={styles.infTestimonial}>
      <div>
        <img src={image} alt="" className={styles.infTestimonialImg} />
      </div>
      <div className={styles.infTestimonialData}>
        <div className={styles.infTestimonialTextSection}>
          <img src={quoteUpImg} alt="" width={36} height={36} />
          <p className={styles.infTestimonialText}>{testimonial}</p>
        </div>
        <div>
          <p className={styles.infTestimonialName}>{name}</p>
          <p className={styles.infTestimonialDesignation}>{designation}</p>
          <div className={styles.infTestimonialSocialsSection}>
            {socials?.map(({ platform, handle }) => (
              <a
                href={
                  platform === 'instagram'
                    ? `https://www.instagram.com/${handle}`
                    : `https://www.facebook.com/${handle}`
                }
                target="_blank"
                key={handle}
                rel="noreferrer"
              >
                <img
                  src={platform === 'instagram' ? instaSvg : facebookSvg}
                  alt=""
                />
                {/* {handle} */}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export { InfluencerTestimonialCard, influencerTestimonials };

export default InfluencerTestimonials;
