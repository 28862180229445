import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSpinDelay } from 'spin-delay';
import { getProfile } from 'api/influencer/profile';
import { useInfluencerAuth } from 'stores/auth/influencer';
import { LoaderWithWrapper } from 'components/common/Loader';
import { Error } from 'components/common/Error';
import routes from 'constants/routes';

function InfluencerAuthGuard({
  children,
  checkForAddress = true,
  checkForSocial = true,
}) {
  const token = useInfluencerAuth((state) => state.token);
  const setUserData = useInfluencerAuth((state) => state.setUserData);

  const {
    data: user,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ['influencer-profile'],
    queryFn: () => getProfile(),
    onSuccess: (profileData) => {
      if (profileData) {
        setUserData(profileData);
      }
    },
    enabled: !!token,
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  if (!token) {
    return <Navigate to={routes.INF_LOGIN} replace />;
  }

  if (delayedIsLoading || isLoading) {
    return <LoaderWithWrapper show={delayedIsLoading} />;
  }
  if (isError) {
    return <Error retry={refetch} errorMessage={error?.message} />;
  }

  if (checkForAddress && !user?.addresses?.length > 0) {
    return <Navigate to={routes.INF_SIGNUP_ADD_ADDRESS} replace />;
  }

  const isAddedSocial =
    user?.is_facebook ||
    user?.is_instagram ||
    user?.is_twitter ||
    user?.is_youtube;

  if (checkForSocial && !isAddedSocial) {
    return <Navigate to={routes.INF_SIGNUP_ADD_SOCIAL} replace />;
  }

  return children;
}

InfluencerAuthGuard.propTypes = {
  children: PropTypes.node,
  checkForAddress: PropTypes.bool,
  checkForSocial: PropTypes.bool,
};

export default InfluencerAuthGuard;
