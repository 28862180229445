import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { queryClient } from 'App';
import { Avatar } from 'components/common/Avatar';
import Menu from 'components/common/Menu';
import Table from 'components/common/Table';
import { Dialog } from 'components/common/dialog';
import FilterMenu from 'components/common/FilterDropdown/FilterMenu';
import TableError from 'components/common/Table/TableError';
import useFilterProps from 'hooks/useFilterProps';
import useDebounce from 'hooks/useDebounce';
import {
  getCampaignDetails,
  getCampaignInfluencers,
  getCampaignQuotes,
  requestCampaignQuote,
} from 'api/brand/campaign';
import { useDialog } from 'stores/dialog';
import { useCreateCampaign } from 'stores/create-campaign';
import { DIALOGS } from 'constants/config/dialog-names';
import { formatCurrency, formatNumber } from 'utils';
import styles from 'assets/scss/pages/campaign.module.scss';
import popupStyles from 'assets/scss/pages/popup.module.scss';
import { ReactComponent as MoreIcon } from 'assets/images/more-icon.svg';
// import { ReactComponent as InsightsIcon } from 'assets/images/view-insights.svg';
import { ReactComponent as PreviewIcon } from 'assets/images/stats-icon.svg';
import { ReactComponent as AddRemoveIcon } from 'assets/images/plus-minus.svg';
import {
  searchImg,
  followerSvg,
  rcScoreSvg,
  filterIcon,
  arrowDownIcon,
  eyeIcon,
  shareIcon,
  whiteDownloadIcon,
  contactsIcon,
  fileInvoiceIcon,
  engagemntRateImg,
  likesIcon,
  viewsIcon,
  costIcon,
  icInstaIcon,
  icFbIcon,
  icTwitterIcon,
  icYtIcon,
} from 'constants/images';
import {
  CAMPAIGN_QUOTE_STATUS,
  CAMPAIGN_STATUS,
} from 'constants/config/campaign';
import routes from 'constants/routes';

const PLATFORM_ICONS = {
  instagram: icInstaIcon,
  facebook: icFbIcon,
  twitter: icTwitterIcon,
  youtube: icYtIcon,
};

const HIDDEN_COLUMNS = ['total_cost'];

const columns = [
  {
    Header: 'Profile',
    accessor: 'influencer',
    width: 250,
    minWidth: 250,
    Cell: (cellInfo) => {
      const row = cellInfo.row?.original;
      return (
        <div className={styles.profileDiv}>
          <Avatar src={row?.influencer?.profile_image} size="large" alt="" />
          <p>{row?.influencer?.full_name}</p>
          <span />
        </div>
      );
    },
  },
  // {
  //   Header: 'RC Scrore',
  //   accessor: 'rc_score',
  //   info: 'Platform Score',
  //   Cell: (cellInfo) => {
  //     const row = cellInfo.row?.original;
  //     return (
  //       <p className={styles.downloadedTxt}>
  //         {formatNumber(row?.influencer?.rc_score || 0)}
  //       </p>
  //     );
  //   },
  // },
  {
    Header: 'Deliverables',
    accessor: 'deliverables',
    Cell: (cellInfo) => {
      const row = cellInfo.row?.original;

      const deliverablesBreakdown = row?.deliverables?.map(
        ({
          media_type: platform,
          image,
          reel,
          stories,
          video,
          shorts,
          posts,
        }) => {
          const deliverables = [];
          let platformInitals = '';
          switch (platform) {
            case 'instagram':
              if (image) deliverables.push({ name: 'Post', qty: image });
              if (reel) deliverables.push({ name: 'Reel', qty: reel });
              if (stories) deliverables.push({ name: 'Story', qty: stories });
              if (video) deliverables.push({ name: 'Video', qty: video });
              platformInitals = 'IG';
              break;
            case 'youtube':
              if (video) deliverables.push({ name: 'Video', qty: video });
              if (shorts) deliverables.push({ name: 'Short', qty: shorts });
              platformInitals = 'YT';
              break;
            case 'twitter':
              platformInitals = 'TW';
              break;
            case 'facebook':
              if (posts) deliverables.push({ name: 'Post', qty: posts });
              platformInitals = 'FB';
              break;
            default:
              break;
          }
          return { platform, deliverables, platformInitals };
        }
      );

      const platformToShow = deliverablesBreakdown?.[0]?.platform;
      const dilivrableNameToShow =
        deliverablesBreakdown?.[0]?.deliverables?.[0]?.name;
      const dilivrableCountToShow =
        deliverablesBreakdown?.[0]?.deliverables?.[0]?.qty;
      const platformInitalsToShow = deliverablesBreakdown?.[0]?.platformInitals;

      const totalDeliverables = deliverablesBreakdown?.reduce(
        (acc, current) => acc + current.deliverables.length,
        0
      );

      if (totalDeliverables < 1) {
        return null;
      }

      return (
        <div className={styles.deliveryDiv}>
          <div className={styles.igCount}>
            <img
              src={PLATFORM_ICONS[platformToShow]}
              alt=""
              className={styles.deliverablesIcon}
            />
            <span className={styles.spCount}>{dilivrableCountToShow}</span>
          </div>
          <div className="igNum">
            <p className={styles.igTxt}>
              {platformInitalsToShow} {dilivrableNameToShow}
            </p>
            {totalDeliverables > 1 && (
              <span className={styles.igPlusCount}>
                +{totalDeliverables - 1}
              </span>
            )}
          </div>
        </div>
      );
    },
  },
  {
    Header: 'Categories',
    accessor: 'influencer.subcategories',
    Cell: (cellInfo) => {
      const row = cellInfo.row?.original;
      const categories = row?.influencer?.subcategories;
      const categoryToShow = categories?.[0];
      if (categories?.length > 0) {
        return (
          <div className={styles.catgryDiv}>
            <span className={styles.catgrySpan}>
              {categoryToShow?.subcategory_name}
            </span>
            {categories.length > 1 && (
              <p className={styles.catgryTxt}>+{categories.length - 1} More</p>
            )}
          </div>
        );
      }
      return null;
    },
  },
  {
    Header: 'Followers',
    accessor: 'followers',
    Cell: (cellInfo) => formatNumber(cellInfo.value || 0),
  },
  {
    Header: 'Avg Likes',
    accessor: 'avg_likes',
    Cell: (cellInfo) => formatNumber(cellInfo.value || 0),
  },
  {
    Header: 'Avg Views',
    accessor: 'avg_views',
    Cell: (cellInfo) => formatNumber(cellInfo.value || 0),
  },
  {
    Header: 'ER',
    accessor: 'engagement_rate',
    info: 'Engagement Rate',
    Cell: (cellInfo) => (
      <p className={styles.downloadedTxt}>
        {formatNumber(cellInfo.value || 0)}%
      </p>
    ),
  },
  {
    Header: 'Location',
    accessor: 'influencer.addresses[0].state',
    width: 200,
    minWidth: 200,
  },
  {
    Header: 'Cost',
    accessor: 'total_cost',
    Cell: (cellInfo) => {
      const row = cellInfo.row?.original;
      const total = Number(row?.cost);
      return (
        <p className={styles.inflNameText}>
          {Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
          }).format(total)}
        </p>
      );
    },
  },

  {
    Header: '',
    accessor: 'options',
    width: 20,
    Cell: (cellInfo) => {
      const row = cellInfo.row?.original;
      const setDialog = useDialog((state) => state.setDialog);
      const setCampaingData = useCreateCampaign((state) => state.setFormData);
      const setCampaingStep = useCreateCampaign((state) => state.setFormStep);

      const { data: campaign } = useQuery({
        queryKey: ['brand-campaign-details', row?.campaignId],
        queryFn: () => getCampaignDetails({ id: row?.campaignId }),
        select: (res) => res?.data?.campaign,
        enabled: !!row?.campaignId,
      });

      const {
        campaign_status: campaignStatus,
        quotation_status: campaignQuoteStatus,
      } = campaign || {};

      const onAddRemoveInfluencer = () => {
        setCampaingStep(3);
        setCampaingData({ campaignId: row?.campaignId });
        setDialog(DIALOGS.CREATE_CAMPAIGN);
      };

      return (
        <Menu
          renderButton={() => (
            <Menu.Button>
              <MoreIcon />
            </Menu.Button>
          )}
        >
          {/* {campaignStatus !== CAMPAIGN_STATUS.DRAFTED &&
            campaignStatus !== CAMPAIGN_STATUS.UPCOMING &&
            campaignStatus !== CAMPAIGN_STATUS.DROPPED && (
              <Menu.Item onClick={() => setDialog(DIALOGS.CAMPAIGN_INSIGHTS)}>
                <InsightsIcon />
                <span>View Insights</span>
              </Menu.Item>
            )} */}
          {campaignStatus === CAMPAIGN_STATUS.DRAFTED &&
            (!campaignQuoteStatus ||
              campaignQuoteStatus === CAMPAIGN_QUOTE_STATUS.NOT_SENT) && (
              <Menu.Item onClick={onAddRemoveInfluencer}>
                <AddRemoveIcon />
                <span>Add/Remove</span>
              </Menu.Item>
            )}
          <Menu.Item
            onClick={() =>
              setDialog(DIALOGS.BRAND_INF_PROFILE_OVERVIEW, {
                influencerId: row?.influencer.id,
                platform: row?.deliverables?.[0]?.media_type,
              })
            }
          >
            <PreviewIcon />
            <span>Preview</span>
          </Menu.Item>
        </Menu>
      );
    },
  },
];

const CampaignInfluencers = () => {
  const { id: campaignId } = useParams();
  const tableRef = useRef(null);

  const {
    page,
    rowsPerPage,
    changePage,
    changeRowsPerPage,
    search,
    changeSearch,
    filter,
    changeFilter,
  } = useFilterProps();

  const debouncedSearchQuery = useDebounce(search);
  const likesFilter = filter.likes;
  const viewsFilter = filter.views;
  const engagementRateFilter = filter.engagementRate;
  const costFilter = filter.cost;
  const rcFilter = filter.rc;
  const followersFilter = filter.followers;

  const { data: campaignDetails } = useQuery({
    queryKey: ['brand-campaign-details', campaignId],
    queryFn: () => getCampaignDetails({ id: campaignId }),
    select: (res) => res?.data,
  });

  const { data: campaignQuotes } = useQuery({
    queryKey: ['brand-campaign-quotes', campaignId],
    queryFn: () => getCampaignQuotes({ id: campaignId }),
    select: (res) => res?.data?.quotations,
  });

  const {
    data: campaignInfluencersData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [
      'brand-campaign-influencers',
      campaignId,
      page,
      rowsPerPage,
      debouncedSearchQuery,
      rcFilter,
      followersFilter,
      likesFilter,
      viewsFilter,
      engagementRateFilter,
      costFilter,
    ],
    queryFn: () =>
      getCampaignInfluencers({
        id: campaignId,
        pageNo: page,
        pageSize: rowsPerPage,
        search: debouncedSearchQuery || undefined,
        likes_from: likesFilter?.[0] || undefined,
        likes_to: likesFilter?.[1] || undefined,
        views_from: viewsFilter?.[0] || undefined,
        views_to: viewsFilter?.[1] || undefined,
        engagement_rate_from: engagementRateFilter?.[0] || undefined,
        engagement_rate_to: engagementRateFilter?.[1] || undefined,
        total_cost_from: costFilter?.[0] || undefined,
        total_cost_to: costFilter?.[1] || undefined,
        rc_from: rcFilter?.[0] || undefined,
        rc_to: rcFilter?.[0] || undefined,
        followers_from: followersFilter?.[0] || undefined,
        followers_to: followersFilter?.[0] || undefined,
      }),
    select: (res) => res?.data?.influencers,
  });

  const requestQuoteMutation = useMutation({
    mutationFn: ({ campaignId }) => requestCampaignQuote({ campaignId }),
    onSuccess: (res, { campaignId }) => {
      queryClient.invalidateQueries({
        queryKey: ['brand-campaigns'],
      });
      queryClient.invalidateQueries({
        queryKey: ['brand-campaign-details', campaignId],
      });
      queryClient.invalidateQueries({
        queryKey: ['brand-campaign-quotes', campaignId],
      });
      queryClient.invalidateQueries({
        queryKey: ['brand-campaign-quote-details', campaignId],
      });
      // Request for quote has been sent to admin successfully.
      toast.success(res?.message, {
        position: 'top-center',
      });
    },
  });

  const campaignStatus = campaignDetails?.campaign?.campaign_status;
  const campaignQuoteStatus = campaignDetails?.campaign?.quotation_status;

  const influencers = campaignInfluencersData?.rows;

  const expectedTotal = useMemo(
    () => ({
      followers: campaignDetails?.total_followers || 0,
      avgLikes: campaignDetails?.avg_likes || 0,
      avgViews: campaignDetails?.avg_views || 0,
      avgEngagementRate: campaignDetails?.avg_er || 0,
      avgCPE: campaignDetails?.avg_cpe || 0,
      totalCost: campaignDetails?.total_cost || 0,
    }),
    [campaignDetails]
  );

  useEffect(() => {
    if (
      campaignQuoteStatus === CAMPAIGN_QUOTE_STATUS.ADMIN_CONFIRMED ||
      campaignQuoteStatus === CAMPAIGN_QUOTE_STATUS.USER_APPROVED ||
      campaignQuoteStatus === CAMPAIGN_QUOTE_STATUS.USER_APPROVED_SIGNED
    ) {
      tableRef.current?.setHiddenColumns([]);
    } else {
      tableRef.current?.setHiddenColumns(HIDDEN_COLUMNS);
    }
  }, [campaignQuoteStatus]);

  const activeQuote = useMemo(
    () => campaignQuotes?.rows?.find((quote) => !quote?.is_deactivated),
    [campaignQuotes]
  );

  const isQuoteProvideded =
    campaignQuoteStatus === CAMPAIGN_QUOTE_STATUS.ADMIN_CONFIRMED ||
    campaignQuoteStatus === CAMPAIGN_QUOTE_STATUS.USER_APPROVED ||
    campaignQuoteStatus === CAMPAIGN_QUOTE_STATUS.USER_APPROVED_SIGNED;

  return (
    <>
      <div className={styles.influencerHeader}>
        <div className={styles.subCategoryTitle}>
          <h2 className={styles.trendingTxt}>
            {campaignInfluencersData?.count} Influencers Found
          </h2>
          <div className={clsx(styles.searchField, styles.campaignField)}>
            <FilterDialog
              changeFilters={changeFilter}
              render={({ open }) => (
                <button
                  type="button"
                  onClick={open}
                  className={styles.filterType}
                >
                  <img className={styles.fIcon} src={filterIcon} alt="" />
                </button>
              )}
            />

            <div className={styles.searchAction}>
              <img className={styles.sIcon} src={searchImg} alt="" />
              <input
                className={styles.searchTxt}
                placeholder="Type to search list below"
                type="text"
                value={search || ''}
                onChange={(e) => changeSearch(e.target.value)}
              />
            </div>
            {campaignStatus === CAMPAIGN_STATUS.DRAFTED &&
              (!campaignQuoteStatus ||
                campaignQuoteStatus === CAMPAIGN_QUOTE_STATUS.NOT_SENT) && (
                <button
                  type="button"
                  className={styles.addList}
                  onClick={() => requestQuoteMutation.mutate({ campaignId })}
                  disabled={
                    requestQuoteMutation.isLoading ||
                    campaignQuoteStatus === CAMPAIGN_QUOTE_STATUS.USER_SENT
                  }
                >
                  <img src={fileInvoiceIcon} alt="" /> Request Quote
                </button>
              )}
            {isQuoteProvideded && (
              <Link
                to={activeQuote?.id}
                type="button"
                className={styles.addList}
              >
                <img src={eyeIcon} alt="" /> View Quote
                <img src={arrowDownIcon} alt="" />
              </Link>
            )}
            {/* {campaignStatus !== CAMPAIGN_STATUS.DRAFTED &&
              campaignStatus !== CAMPAIGN_STATUS.UPCOMING &&
              campaignStatus !== CAMPAIGN_STATUS.DROPPED && (
                <>
                  <button type="button" className={styles.addList}>
                    <img src={whiteDownloadIcon} alt="" /> Download Report
                  </button>
                  <button type="button" className={styles.addList}>
                    <img src={shareIcon} alt="" /> Share Report
                  </button>
                </>
              )} */}
            {campaignStatus === CAMPAIGN_STATUS.DRAFTED && (
              <Link
                to={routes.CONTACT_US}
                className={styles.addList}
                state={{ type: 'admin-support' }}
              >
                <img src={contactsIcon} alt="" /> Contact Admin
              </Link>
            )}
          </div>
        </div>
        <Table
          data={influencers}
          columns={columns}
          isLoading={isLoading || isFetching}
          paginationProps={{
            currentPage: page,
            changePage,
            count: campaignInfluencersData?.count,
            rowsPerPage,
            changeRowsPerPage,
          }}
          initialState={{
            hiddenColumns: HIDDEN_COLUMNS,
          }}
          ref={tableRef}
          dense
          noDataValue={
            <TableError
              infoText="No influencers found."
              searchQuery={debouncedSearchQuery}
            />
          }
        />
      </div>
      <BottomBar
        expectedTotal={expectedTotal}
        isQuoteProvideded={isQuoteProvideded}
      />
    </>
  );
};

const FilterDialog = ({ render, changeFilters }) => {
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({});

  const open = () => setShowFilterDialog(true);
  const close = () => setShowFilterDialog(false);

  const changeCurrentFilter = (key, value) => {
    setCurrentFilters((prev) => ({ ...prev, [key]: value }));
  };

  const applyFilters = (filtersToApply) => {
    changeFilters('rc', filtersToApply.rc);
    changeFilters('followers', filtersToApply.followers);
    changeFilters('likes', filtersToApply.likes);
    changeFilters('views', filtersToApply.views);
    changeFilters('engagementRate', filtersToApply.engagementRate);
    changeFilters('cost', filtersToApply.cost);
  };

  const onClearAll = () => {
    applyFilters({});
    setCurrentFilters({});
    close();
  };

  const onApplyFilters = () => {
    applyFilters(currentFilters);
    close();
  };

  return (
    <>
      {render({ open })}
      <Dialog title="Filters" open={showFilterDialog} close={close} fullWidth>
        <div
          style={{
            padding: '16px 25px',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 20,
          }}
        >
          {/* <FilterMenu.RangeSelector
            label="RC Score"
            startIconUrl={rcScoreSvg}
            minLimit={0}
            maxLimit={10}
            onClear={() => changeCurrentFilter('rc', null)}
            onApply={(newValue) => changeCurrentFilter('rc', newValue)}
            buttonText={
              currentFilters.rc
                ? `${currentFilters.rc[0]} - ${currentFilters.rc[1]}`
                : 'All'
            }
            value={currentFilters.rc}
            isMenuChild={false}
            placement="bottom-start"
            fullWidth
            adaptiveWidth
          /> */}
          <FilterMenu.RangeSelector
            label="Followers"
            startIconUrl={followerSvg}
            minLimit={5000}
            maxLimit={250000}
            onClear={() => changeCurrentFilter('followers', null)}
            onApply={(newValue) => changeCurrentFilter('followers', newValue)}
            buttonText={
              currentFilters.followers
                ? `${formatNumber(
                    currentFilters.followers[0]
                  )} - ${formatNumber(currentFilters.followers[1])}`
                : 'All'
            }
            value={currentFilters.followers}
            isMenuChild={false}
            placement="bottom-start"
            fullWidth
            adaptiveWidth
            sliderProps={{ step: 500 }}
          />
          <FilterMenu.RangeSelector
            label="Likes"
            startIconUrl={likesIcon}
            minLimit={1000}
            maxLimit={200000}
            onClear={() => changeCurrentFilter('likes', null)}
            onApply={(newValue) => changeCurrentFilter('likes', newValue)}
            buttonText={
              currentFilters.likes
                ? `${formatNumber(currentFilters.likes[0])} - ${formatNumber(
                    currentFilters.likes[1]
                  )}`
                : 'All'
            }
            value={currentFilters.likes}
            isMenuChild={false}
            placement="bottom-start"
            fullWidth
            adaptiveWidth
            sliderProps={{ step: 500 }}
          />
          <FilterMenu.RangeSelector
            label="Views"
            startIconUrl={viewsIcon}
            minLimit={1000}
            maxLimit={600000}
            onClear={() => changeCurrentFilter('views', null)}
            onApply={(newValue) => changeCurrentFilter('views', newValue)}
            buttonText={
              currentFilters.views
                ? `${formatNumber(currentFilters.views[0])} - ${formatNumber(
                    currentFilters.views[1]
                  )}`
                : 'All'
            }
            value={currentFilters.views}
            isMenuChild={false}
            placement="bottom-start"
            fullWidth
            adaptiveWidth
            sliderProps={{ step: 1000 }}
          />
          <FilterMenu.RangeSelector
            label="Engagement Rate"
            startIconUrl={engagemntRateImg}
            minLimit={0}
            maxLimit={100}
            onClear={() => changeCurrentFilter('engagementRate', null)}
            onApply={(newValue) =>
              changeCurrentFilter('engagementRate', newValue)
            }
            buttonText={
              currentFilters.engagementRate
                ? `${currentFilters.engagementRate[0]}% - ${currentFilters.engagementRate[1]}%`
                : 'All'
            }
            value={currentFilters.engagementRate}
            isMenuChild={false}
            placement="bottom-start"
            fullWidth
            adaptiveWidth
          />
          <FilterMenu.RangeSelector
            label="Cost"
            startIconUrl={costIcon}
            minLimit={1000}
            maxLimit={200000}
            onClear={() => changeCurrentFilter('cost', null)}
            onApply={(newValue) => changeCurrentFilter('cost', newValue)}
            buttonText={
              currentFilters.cost
                ? `${formatCurrency(currentFilters.cost[0])} - ${formatCurrency(
                    currentFilters.cost[1]
                  )}`
                : 'All'
            }
            value={currentFilters.cost}
            isMenuChild={false}
            placement="bottom-start"
            fullWidth
            adaptiveWidth
            sliderProps={{ step: 500 }}
          />
        </div>
        <div className={popupStyles.createInfFooter}>
          <div className={clsx(popupStyles.campaignBtns, popupStyles.nxtWrap)}>
            <button
              type="button"
              className={popupStyles.prevBtn}
              onClick={onClearAll}
            >
              Clear All
            </button>
            <button
              type="submit"
              style={{ width: 166 }}
              className={popupStyles.updateBtn}
              onClick={onApplyFilters}
            >
              Apply
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

FilterDialog.propTypes = {
  render: PropTypes.func,
  changeFilters: PropTypes.func,
};

const BottomBar = ({ expectedTotal = {}, isQuoteProvideded = false }) => (
  <div className={styles.costAnalysis}>
    <div>
      <h2 className={styles.totalHeadingTxt}>Expected Total</h2>
    </div>
    <div>
      <ul className={styles.campaignList} style={{ marginTop: '0px' }}>
        <li className={styles.campaignInnrList}>
          <h2 className={styles.overviewTitle}>ER</h2>
          <p className={styles.overviewDetail}>
            {formatNumber(expectedTotal.avgEngagementRate)}%
          </p>
        </li>
        <li className={styles.campaignInnrList}>
          <h2 className={styles.overviewTitle}>Followers</h2>
          <p className={styles.overviewDetail}>
            {formatNumber(expectedTotal.followers)}
          </p>
        </li>
        <li className={styles.campaignInnrList}>
          <h2 className={styles.overviewTitle}>Avg. Likes</h2>
          <p className={styles.overviewDetail}>
            {formatNumber(expectedTotal.avgLikes)}
          </p>
        </li>
        <li className={styles.campaignInnrList}>
          <h2 className={styles.overviewTitle}>Avg. Views</h2>
          <p className={styles.overviewDetail}>
            {formatNumber(expectedTotal.avgViews)}
          </p>
        </li>
        <li className={styles.campaignInnrList}>
          <h2 className={styles.overviewTitle}>Avg. CPE</h2>
          <p className={styles.overviewDetail}>
            {Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
            }).format(expectedTotal.avgCPE)}
          </p>
        </li>

        {isQuoteProvideded && (
          <li className={styles.campaignInnrList}>
            <h2 className={styles.overviewTitle}>Cost</h2>
            <p className={styles.overviewDetail}>
              {Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: 'INR',
              }).format(expectedTotal.totalCost)}
            </p>
          </li>
        )}
      </ul>
    </div>
  </div>
);

BottomBar.propTypes = {
  expectedTotal: PropTypes.object,
  isQuoteProvideded: PropTypes.bool,
};

export default CampaignInfluencers;
