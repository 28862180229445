import React, { useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSpinDelay } from 'spin-delay';
// import { toast } from 'react-toastify';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from 'App';
import { getCampaignQuoteDetails } from 'api/brand/campaign';
import {
  getPaymentDetails,
  getPaymentRedirectUrl,
  initiateCampaignPayment,
  uploadCampaignPaymentSlip,
} from 'api/brand/payments';
import { LoaderWithWrapper } from 'components/common/Loader';
import routes from 'constants/routes';
import { PLATFORM_ICONS } from 'constants/config/platformIcons';
import styles from 'assets/scss/pages/campaign-quotation.module.scss';
import { locationDownIcon, successIcon } from 'constants/images';
import { formatCurrency } from 'utils';
import {
  CAMPAIGN_PAYMENT_STATUS,
  CAMPAIGN_QUOTE_STATUS,
} from 'constants/config/campaign';
import PaymentSuccessDialog from 'components/payments/PaymentSuccessDialog';
import PaymentFailDialog from 'components/payments/PaymentFailDialog';

const transectionStatus = {
  SUCCESS: 'Success',
  INIT: 'Initiated',
};

const PaymentAndCheckout = () => {
  const { id: campaignId, quoteId } = useParams();
  const inputRef = useRef(0);

  const { data: quoteData, isLoading } = useQuery({
    queryKey: ['brand-campaign-quote-details', campaignId, quoteId],
    queryFn: () => getCampaignQuoteDetails({ campaignId, quoteId }),
    select: (res) => res?.data?.quotation,
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  const initiatePaymentMutation = useMutation({
    mutationFn: ({ quoteId }) =>
      initiateCampaignPayment({
        quoteId,
        body: { return_url: window.location.pathname },
      }),
    onSuccess: () => {
      window.location.replace(getPaymentRedirectUrl({ paymentId: quoteId }));
    },
  });

  const uploadPurchase = useMutation({
    mutationFn: ({ quoteId, payload }) =>
      uploadCampaignPaymentSlip({ quoteId, body: payload }),
    onSuccess: (data) => {
      toast.success(data?.message, { position: 'top-center' });
      queryClient.invalidateQueries({
        queryKey: ['brand-campaign-details', campaignId],
      });
      queryClient.invalidateQueries({
        queryKey: ['brand-campaign-quote-details', campaignId],
      });
    },
  });

  const onPayNow = () => {
    initiatePaymentMutation.mutate({ quoteId, campaignId });
  };

  const uploadPurchaseHandler = (e) => {
    const { files } = e.target;
    const formData = new FormData();
    if (files[0]) {
      formData.append('receipt_file', files[0]);
      // formData.append('bank_ref_no');
      uploadPurchase.mutate({ quoteId, payload: formData });
    }
  };

  const totalDeliverables = useMemo(() => {
    const summaryData = quoteData?.summary;

    return summaryData
      ? Object.keys(summaryData).reduce(
          (acc, current) => acc + Number(summaryData[current]?.qty),
          0
        )
      : 0;
  }, [quoteData]);

  const {
    quotation_status: quoteStatus,
    payment_status: paymentStatus,
    campaign_name: campaignName,
    duration: campaignDurationInDays,
    region: regionString,
    age_group: ageGroupString,
    total: grandTotal,
    total_paid: amountPaid,
    amount_payable: amountRequested,
    transactions,
    address,
    full_name: clientFullName,
    email: clientEmail,
    mobile: clientMobile,
    submitted_by: submittedBy,
    campaign: campaignData,
    influencers,
    summary: deliverablesSummary,
  } = quoteData || {};

  const totalInfluencers = influencers?.length;
  const isPaid = paymentStatus === CAMPAIGN_PAYMENT_STATUS.FULL_PAYMENT;

  const isFullPayment = amountRequested === grandTotal;

  const hasPendingTransection = Boolean(
    transactions?.find(
      ({ order_status: status }) => status === transectionStatus.INIT
    )
  );

  const sortedTransections = transactions?.sort((a, b) =>
    a.created_at?.localeCompare(b.created_at)
  );

  if (isLoading || delayedIsLoading) {
    return <LoaderWithWrapper show={delayedIsLoading} />;
  }

  return (
    <section>
      <div className={styles.quotationContainer}>
        <div className={styles.campignBreadcrumbs}>
          <Link to={routes.BRAND_CAMPAIGNS} className={styles.navTxt}>
            Campaings
          </Link>
          <img className={styles.rightArrow} src={locationDownIcon} alt="" />
          <Link
            to={`${routes.BRAND_CAMPAIGNS}/${campaignId}`}
            className={styles.navSecndTxt}
          >
            {campaignName}
          </Link>
          <img className={styles.rightArrow} src={locationDownIcon} alt="" />
          <Link
            to={`${routes.BRAND_CAMPAIGNS}/${campaignId}/${quoteId}`}
            className={styles.navSecndTxt}
          >
            Quotation Detail
          </Link>
          <img className={styles.rightArrow} src={locationDownIcon} alt="" />
          <span className={styles.navSecndTxt}>Payment & checkout</span>
        </div>
        <div className={styles.quotationHeader}>
          <h2 className={styles.quoHeading}>Payment & Checkout</h2>
        </div>
        <div className={styles.paymntCntainer}>
          <div className={styles.paymntLeft}>
            <div className="quotationDetail">
              <div className={styles.detailHeader}>
                <h2 className={styles.detailHeading}>CLIENT DETAILS</h2>
              </div>
              <div className={styles.detailInnerContent}>
                <div className={styles.subPart}>
                  <div>
                    <div className={styles.labelContainer}>
                      <label className={styles.title}>Full Name:</label>
                      <p className={styles.content}>{clientFullName}</p>
                    </div>
                    <div className={styles.labelContainer}>
                      <label className={styles.title}>Email Id:</label>
                      <p className={styles.content}>{clientEmail}</p>
                    </div>
                    <div className={styles.labelContainer}>
                      <label className={styles.title}>Mobile Number:</label>
                      <p className={styles.content}>{clientMobile}</p>
                    </div>
                    <div className={styles.labelContainer}>
                      <label className={styles.title}>Address:</label>
                      <p className={styles.addressContent}>
                        {address}
                        {/* {clientAddress.address_line_1 &&
                          `${clientAddress.address_line_1}, `}
                        {clientAddress.address_line_2 &&
                          `${clientAddress.address_line_2}, `}
                        {clientAddress.landmark &&
                          `${clientAddress.landmark}, `}
                        {clientAddress.city && `${clientAddress.city}, `}
                        {clientAddress.state} - {clientAddress.pincode} */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="quotationDetail">
              <div className={styles.detailHeader}>
                <h2 className={styles.detailHeading}>CAMPAIGN DETAILS</h2>
              </div>
              <div className={styles.detailInnerContent}>
                <div className={styles.subPart}>
                  <div>
                    <div className={styles.labelContainer}>
                      <label className={styles.title}>Campaign Name:</label>
                      <p className={styles.content}>{campaignName}</p>
                    </div>
                    <div className={styles.labelContainer}>
                      <label className={styles.title}>Platform:</label>
                      <p className={styles.platformIconsWrapper}>
                        {campaignData?.target_platforms?.map((platformName) => (
                          <img
                            src={PLATFORM_ICONS[platformName]}
                            alt=""
                            key={platformName}
                          />
                        ))}
                      </p>
                    </div>
                    <div className={styles.labelContainer}>
                      <label className={styles.title}>Duration:</label>
                      <p className={styles.content}>
                        {campaignDurationInDays} days
                      </p>
                    </div>
                    <div className={styles.labelContainer}>
                      <label className={styles.title}>Region:</label>
                      <p className={styles.content}>{regionString}</p>
                    </div>
                    <div className={styles.labelContainer}>
                      <label className={styles.title}>Age Group:</label>
                      <p className={styles.content}>{ageGroupString}</p>
                    </div>
                    <div className={styles.labelContainer}>
                      <label className={styles.title}>Submitted By:</label>
                      <p className={styles.content}>{submittedBy}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.paymentWrapper}>
              {sortedTransections?.map(
                ({ id, amount, order_status: status }, index) => (
                  <div className={styles.fullPaymnt} key={id}>
                    <div>
                      <h2 className={styles.paymntTitle}>
                        Payment {index + 1}
                      </h2>
                      <p className={styles.paymntPrice}>
                        {formatCurrency(amount)}
                      </p>
                    </div>
                    <div>
                      {status === transectionStatus.SUCCESS && (
                        <div className={styles.paymentStatus}>
                          <img src={successIcon} />
                          <span>Successful</span>
                        </div>
                      )}
                      {status === transectionStatus.INIT && (
                        // TODO: change icon
                        <div
                          className={clsx(
                            styles.paymentStatus,
                            styles.processing
                          )}
                        >
                          <span>Processing</span>
                        </div>
                      )}
                    </div>
                  </div>
                )
              )}
              {!hasPendingTransection && (
                <div className={styles.fullPaymnt}>
                  <div>
                    <h2 className={styles.paymntTitle}>
                      {isFullPayment
                        ? 'Full Payment'
                        : `Payment ${transactions?.length + 1}`}
                    </h2>
                    <p className={styles.paymntPrice}>
                      {formatCurrency(
                        Number(amountRequested) || grandTotal - amountPaid
                      )}
                    </p>
                  </div>
                  <div className={styles.payBtnWrapper}>
                    {quoteStatus ===
                      CAMPAIGN_QUOTE_STATUS.USER_APPROVED_SIGNED &&
                    !isPaid &&
                    Number(amountRequested) ? (
                      <>
                        <button
                          type="button"
                          onClick={onPayNow}
                          className={styles.payBtn}
                          disabled={initiatePaymentMutation.isLoading}
                        >
                          Pay Now
                        </button>
                        <button
                          className={styles.uploadBtn}
                          type="button"
                          onClick={() => inputRef.current.click()}
                          disabled={
                            quoteStatus !==
                            CAMPAIGN_QUOTE_STATUS.USER_APPROVED_SIGNED
                          }
                        >
                          Upload Purchase Order
                        </button>
                        <input
                          type="file"
                          ref={inputRef}
                          hidden
                          onChange={uploadPurchaseHandler}
                        />
                      </>
                    ) : null}

                    {isPaid && (
                      <div className={styles.paymentStatus}>
                        <img src={successIcon} />
                        <span>Successful</span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.paymntRight}>
            <div className={styles.paymentRightBg}>
              <div className={clsx(styles.detailHeader, styles.summaryDetail)}>
                <h2 className={styles.detailHeading}>SUMMARY</h2>
              </div>
              <div className={styles.summaryDetail}>
                <div className={styles.innerTableItems}>
                  <h3 className={styles.itemsTitle}>
                    {totalDeliverables} Items
                  </h3>
                  <div>
                    <table width="100%">
                      <tbody>
                        <SummaryItem
                          label={`${deliverablesSummary?.storiesCount?.qty} Instagram Stories`}
                          value={formatCurrency(
                            deliverablesSummary?.storiesCount?.cost
                          )}
                          shouldShow={!!deliverablesSummary?.storiesCount?.qty}
                        />
                        <SummaryItem
                          label={`${deliverablesSummary?.imageCount?.qty} Instagram Images`}
                          value={formatCurrency(
                            deliverablesSummary?.imageCount?.cost
                          )}
                          shouldShow={!!deliverablesSummary?.imageCount?.qty}
                        />
                        <SummaryItem
                          label={`${deliverablesSummary?.videoInstaCount?.qty} Instagram Videos`}
                          value={formatCurrency(
                            deliverablesSummary?.videoInstaCount?.cost
                          )}
                          shouldShow={
                            !!deliverablesSummary?.videoInstaCount?.qty
                          }
                        />
                        <SummaryItem
                          label={`${deliverablesSummary?.reelCount?.qty} Instagram Reels`}
                          value={formatCurrency(
                            deliverablesSummary?.reelCount?.cost
                          )}
                          shouldShow={!!deliverablesSummary?.reelCount?.qty}
                        />
                        <SummaryItem
                          label={`${deliverablesSummary?.videoYoutubeCount?.qty} YouTube Videos`}
                          value={formatCurrency(
                            deliverablesSummary?.videoYoutubeCount?.cost
                          )}
                          shouldShow={
                            !!deliverablesSummary?.videoYoutubeCount?.qty
                          }
                        />
                        <SummaryItem
                          label={`${deliverablesSummary?.shortsCount?.qty} YouTube Shorts`}
                          value={formatCurrency(
                            deliverablesSummary?.shortsCount?.cost
                          )}
                          shouldShow={!!deliverablesSummary?.shortsCount?.qty}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <table width="100%">
                    <tbody>
                      <tr className={styles.tableItems}>
                        <td>No. of Influencers</td>
                        <td align="right">{totalInfluencers}</td>
                      </tr>
                      {/* <tr className={styles.tableItems}>
                        <td>Influencers Cost</td>
                        <td align="right">₹1,550,000</td>
                      </tr>
                      <tr className={styles.tableItems}>
                        <td>Cost of Campaign performance</td>
                        <td align="right">₹1,000,000</td>
                      </tr>
                      <tr className={styles.tableItems}>
                        <td>Cost to view contact details</td>
                        <td align="right">₹70,000</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={styles.total}>
                <h2 className={styles.itemsTitle}>Total</h2>
                <p className={styles.itemsTitle}>
                  {formatCurrency(grandTotal)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HandlePaymentRedirect />
    </section>
  );
};

const SummaryItem = ({ label, value, shouldShow }) => {
  if (shouldShow) {
    return (
      <tr className={styles.tableItems}>
        <td>{label}</td>
        <td align="right">{value}</td>
      </tr>
    );
  }

  return null;
};

SummaryItem.propTypes = {
  label: PropTypes.node,
  value: PropTypes.node,
  shouldShow: PropTypes.bool,
};

const PAYMENT_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
};

const formatPrice = (amount, props = {}) =>
  Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 2,
    ...props,
  }).format(amount);

const HandlePaymentRedirect = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paymentStatus = searchParams.get('status');
  const paymentError = searchParams.get('error');
  const orderId = searchParams.get('order');

  const { data: paymentData, isLoading } = useQuery({
    enabled: Boolean(orderId),
    queryKey: ['payment-details', orderId],
    queryFn: () => getPaymentDetails({ paymentId: orderId }),
    select: (res) => res?.data?.order,
  });

  const onStatusDialogClose = () => {
    setSearchParams({});
  };

  const amount =
    paymentData?.payload?.amount ||
    paymentData?.quotation_campaign?.amount_payable ||
    0;
  const formattedAmountWithCode = formatPrice(amount, {
    currencyDisplay: 'code',
  });

  const isPaymentSuccess = paymentStatus === PAYMENT_STATUS.SUCCESS;
  const isPaymentFail = paymentStatus === PAYMENT_STATUS.FAILED;

  if (isLoading) return null;

  return (
    <>
      <PaymentSuccessDialog
        isOpen={isPaymentSuccess}
        onClose={onStatusDialogClose}
        amount={formattedAmountWithCode}
        orderId={orderId}
      />
      <PaymentFailDialog
        isOpen={isPaymentFail}
        onClose={onStatusDialogClose}
        error={paymentError}
      />
    </>
  );
};

export default PaymentAndCheckout;
