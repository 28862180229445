import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { deleteCampaign } from 'api/brand/campaign';
import { queryClient } from 'App';
import { AlertDialog } from 'components/common/dialog';

const DeleteCampaignDialog = ({ open, close, campaignId, campaignName }) => {
  const deleteCampaignMutation = useMutation({
    mutationFn: ({ campaignId }) =>
      deleteCampaign({
        campaign_id: campaignId,
      }),
    onSuccess: (res, { campaignId }) => {
      queryClient.invalidateQueries({ queryKey: ['brand-campaigns'] });
      queryClient.invalidateQueries({
        queryKey: ['brand-campaign-details', campaignId],
      });
      close();
    },
  });

  const onDeleteCampaign = () => {
    deleteCampaignMutation.mutate({ campaignId });
  };

  return (
    <AlertDialog
      open={open}
      title="Delete Campaign"
      description={`Are you sure you want to delete campaign "${campaignName}"?`}
      agreeBtnText="Delete"
      disagreeBtnText="Cancel"
      postion="center"
      onAgree={onDeleteCampaign}
      onDisagre={close}
    />
  );
};

DeleteCampaignDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  campaignId: PropTypes.string,
  campaignName: PropTypes.string,
};

export default DeleteCampaignDialog;
