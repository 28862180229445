import React from 'react';
import DialogSwicher from 'components/brand-dashboard/dialogs/DialogSwicher';
import CallPopover from 'components/brand-dashboard/CallPopover';
import routes from 'constants/routes';
import { ReactComponent as ProfileIcon } from 'assets/images/profile.svg';
import { ReactComponent as HomeIcon } from 'assets/images/home.svg';
import { ReactComponent as SearchIcon } from 'assets/images/seach.svg';
import { ReactComponent as CampaignIcon } from 'assets/images/campaign.svg';
import { ReactComponent as AnalysisIcon } from 'assets/images/analysis-icon.svg';
// import { ReactComponent as NotificationIcon } from 'assets/images/notification-bell.svg';

import DashboardLayout from '.';

const config = [
  {
    title: 'Dashboard',
    path: '',
    icon: HomeIcon,
    exact: true,
  },
  {
    title: 'Search',
    path: routes.BRAND_SEARCH_INF,
    icon: SearchIcon,
  },
  {
    title: 'Campaigns',
    path: routes.BRAND_CAMPAIGNS,
    icon: CampaignIcon,
  },
  {
    title: 'Brand Analysis',
    path: routes.BRAND_ANALYSIS,
    icon: AnalysisIcon,
  },
];

const config2 = [
  // {
  //   title: 'Notifications',
  //   path: routes.BRAND_NOTIFICATIONS,
  //   icon: NotificationIcon,
  // },
  {
    title: 'My Profile',
    path: routes.BRAND_PROFILE,
    icon: ProfileIcon,
  },
];

const BrandDashboardLayout = () => (
  <>
    <DashboardLayout config={config} config2={config2} />
    <CallPopover />
    <DialogSwicher />
  </>
);

export default BrandDashboardLayout;
