import React, { useId } from 'react';
import PropTypes from 'prop-types';
import styles from 'assets/scss/pages/payments.module.scss';

const InputField = React.forwardRef(({ label, error, ...rest }, ref) => {
  const inputId = useId();
  const errorId = useId();

  return (
    <div className={styles.inputContainer}>
      {label ? <Label htmlFor={inputId}>{label}</Label> : null}
      <Input
        aria-invalid={error ? 'true' : 'false'}
        aria-describedby={error ? errorId : undefined}
        data-invalid={error ? true : undefined}
        {...rest}
        ref={ref}
        id={inputId}
      />
      <ErrorMessage error={error} id={errorId} />
    </div>
  );
});

InputField.propTypes = {
  label: PropTypes.node,
  error: PropTypes.object,
};

const Input = React.forwardRef((props, ref) => (
  <input type="text" {...props} className={styles.input} ref={ref} />
));

const Label = (props) => <label className={styles.label} {...props} />;

const ErrorMessage = ({ error, id }) => {
  if (error) {
    return (
      <p className={styles.error} id={id} role="alert">
        {error.message}
      </p>
    );
  }

  return null;
};

ErrorMessage.propTypes = {
  error: PropTypes.object,
  id: PropTypes.string,
};

export { Label, Input, ErrorMessage };
export default InputField;
