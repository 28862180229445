import React from 'react';
import routes from 'constants/routes';
import DialogSwicher from 'components/inf-dashboard/dialogs/DialogSwitcher';
import { ReactComponent as ProfileIcon } from 'assets/images/profile.svg';
import { ReactComponent as HelpIcon } from 'assets/images/Help.svg';
// import { ReactComponent as NotificationIcon } from 'assets/images/notification-bell.svg';
import { ReactComponent as HomeIcon } from 'assets/images/home.svg';
import { ReactComponent as CampaignPlannerIcon } from 'assets/images/campaign-planer.svg';
import { ReactComponent as CampaignIcon } from 'assets/images/campaign.svg';
import { ReactComponent as AnalyseProfileIcon } from 'assets/images/view_profile.svg';

import DashboardLayout from '.';

const config = [
  {
    title: 'Dashboard',
    path: '',
    icon: HomeIcon,
    exact: true,
  },
  {
    title: 'Campaigns',
    path: routes.INF_CAMPAIGNS,
    icon: CampaignIcon,
  },
  {
    title: 'Reports',
    path: routes.INF_CAMPAIGN_REPORTS,
    icon: CampaignPlannerIcon,
  },
  {
    title: 'Analyse Self Profile',
    path: routes.INF_ANALYSE_SELF_PROFILE,
    icon: AnalyseProfileIcon,
  },
];

const config2 = [
  {
    title: 'Help',
    path: routes.INF_HELP,
    icon: HelpIcon,
  },
  // {
  //   title: 'Notifications',
  //   path: routes.INF_NOTIFICATIONS,
  //   icon: NotificationIcon,
  // },
  {
    title: 'My Profile',
    path: routes.INF_PROFILE,
    icon: ProfileIcon,
  },
];

const InfluencerDashboardLayout = () => (
  <>
    <DashboardLayout config={config} config2={config2} />
    <DialogSwicher />
  </>
);

export default InfluencerDashboardLayout;
