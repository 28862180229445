import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import styles from 'assets/scss/pages/static-content.module.scss';
import { dotImage } from 'constants/images';
import { caseStudies } from 'constants/config/case-studies';

const CaseStudyDetails = () => {
  const { caseStudyId } = useParams();
  const caseStudy = caseStudies.find(({ id }) => id === caseStudyId);

  const { title, videoUrl, details, clientBrief, results } = caseStudy || {};
  return (
    <section>
      <div className={styles.headingContainer}>
        <h1 className={styles.headingTxt}>Case Study</h1>
      </div>
      <div className={styles.container} style={{ position: 'relative' }}>
        <div className={styles.dotBg}>
          <img className="dotImg" src={dotImage} alt="" />
        </div>
        <div className={styles.dotBg2}>
          <img className="dotImg" src={dotImage} alt="" />
        </div>
        <div className={styles.detailOuterContainer}>
          <div className={styles.detailContainer}>
            <iframe
              src={videoUrl}
              title={title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className={styles.caseStudyVideo}
              style={{ marginBottom: 28 }}
            />
            <h2>{title}</h2>
            <p>
              {clientBrief.split('\n').map((line, index) => (
                <Fragment key={index}>
                  {line}
                  <br />
                </Fragment>
              ))}
            </p>
            {details.split('\n').map((line, index) => (
              <p key={index}>{line}</p>
            ))}

            <strong>
              {results.split('\n').map((line, index) => (
                <Fragment key={index}>
                  {line}
                  <br />
                </Fragment>
              ))}
            </strong>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CaseStudyDetails;
