import { formatDate, formatNumber } from 'utils';

const INSTA_URL = 'https://instagram.com/';
const YOUTUBE_URL = 'https://www.youtube.com/channel/';
const FACEBOOK_URL = 'https://www.facebook.com/';
const TWITTER_URL = 'https://twitter.com/';

export const formatTwitterPost = ({
  media_id: postId,
  impression_count: views,
  like_count: likes,
  reply_count: comments,
  retweet_count: retweets,
  caption: title,
  timestamp: publishedAt,
  thumbnail,
  branded_post: isBrandPost,
}) => ({
  postId,
  views: formatNumber(views),
  likes: formatNumber(likes),
  comments: formatNumber(comments),
  retweets: formatNumber(retweets),
  impressionCount: views,
  likesCount: likes,
  commentsCount: comments,
  retweetsCount: retweets,
  thumbnail,
  permalink: `${TWITTER_URL}i/web/status/${postId}`,
  title,
  kind: 'tweet',
  publishedAt: publishedAt && formatDate(publishedAt),
  isBrandPost,
});

export const formatFacebookPost = ({
  caption,
  picture_id: postId,
  media_url: thumbnail,
  like_count: likes,
  comments_count: comments,
  media_type: kind,
  timestamp: publishedAt,
  permalink,
}) => ({
  caption,
  postId,
  thumbnail,
  permalink,
  likes: formatNumber(likes),
  comments: formatNumber(comments),
  likesCount: likes,
  commentsCount: comments,
  kind: kind?.toLowerCase(),
  publishedAt: formatDate(publishedAt),
});

export const formatYoutubePost = ({
  video_id: postId,
  view_count: views,
  like_count: likes,
  comment_count: comments,
  thumbnail,
  hastag,
  title,
  permalink,
  published_at: publishedAt,
  branded_post: isBrandPost,
}) => ({
  postId,
  views: formatNumber(views),
  likes: formatNumber(likes),
  comments: formatNumber(comments),
  viewsCount: views,
  likesCount: likes,
  commentsCount: comments,
  thumbnail,
  permalink,
  title,
  kind: 'video',
  hastag,
  publishedAt: formatDate(publishedAt),
  isBrandPost,
});

export const formatInstagramPost = ({
  media_id: postId,
  view_count: views,
  like_count: likes,
  comments_count: comments,
  thumbnail,
  permalink,
  caption: title,
  media_type: kind,
  timestamp: publishedAt,
  branded_post: isBrandPost,
}) => ({
  postId,
  views: formatNumber(views),
  likes: formatNumber(likes),
  comments: formatNumber(comments),
  viewsCount: views,
  likesCount: likes,
  commentsCount: comments,
  thumbnail,
  permalink,
  title,
  kind: kind?.toLowerCase(),
  publishedAt: formatDate(publishedAt),
  isBrandPost,
});
