import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { getInfCategories } from 'api/brand/search';
import { getTags } from 'api/brand/campaign';
import { useCreateCampaign } from 'stores/create-campaign';
import Tooltip from 'components/common/Tooltip';
import Dropzone from 'components/common/form/Dropzone';
import ErrorMessage from 'components/common/form/ErrorMessage';
import useDebounce from 'hooks/useDebounce';
import { BRAND_CREATE_CAMPAIGN_STEP1_SCHEMA } from 'constants/schema';
import styles from 'assets/scss/pages/popup.module.scss';
import styles2 from 'assets/scss/pages/search.module.scss';
import {
  brandNameIcon,
  calndrImg,
  campaignPopupImg,
  chooseTagsIcon,
  // cutIcon,
  descIcon,
  expectedBudgIcon,
  featherEyeImg,
  paymentTermsIcon,
  pinkAddIcon,
  searchImg,
  selectCatIcon,
  uplImgVideoIcon,
} from 'constants/images';
import { Input, InputWrapper, RadioInput } from 'components/common/campaign';
import { VIDEO_EXTENTIONS_REGX } from 'constants/config/regex';
import Select from '../../../common/campaign/Select';
import CreateTagDialog from './CreateTagDialog';

const defaultValues = {
  campaignName: '',
  brandName: '',
  startDateTime: null,
  endDateTime: null,
  expectedBudget: '',
  paymentTerms: '',
  description: '',
  categories: [],
  tags: [],
  media: '',
};

const StepOne = () => {
  const { formData, goToNextStep, setFormData } = useCreateCampaign();

  const { data: categories } = useQuery({
    queryKey: ['brand-categories'],
    queryFn: () => getInfCategories(),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BRAND_CREATE_CAMPAIGN_STEP1_SCHEMA),
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tags',
  });

  const onSubmit = ({ startDateTime, endDateTime, ...rest }) => {
    setFormData({
      ...rest,
      startDateTime: startDateTime?.toISOString(),
      endDateTime: endDateTime?.toISOString(),
    });
    goToNextStep();
  };

  const startDateTime = watch('startDateTime');

  const minEndDateTime = useMemo(() => {
    const minEndDateTime = startDateTime ? new Date(startDateTime) : undefined;
    minEndDateTime?.setDate(minEndDateTime.getDate() + 1);

    return minEndDateTime
      ? format(minEndDateTime, 'yyyy-MM-dd HH:mm')
      : undefined;
  }, [startDateTime]);

  useEffect(() => {
    if (formData.campaignName) {
      reset({
        campaignName: formData.campaignName || '',
        brandName: formData.brandName || '',
        expectedBudget: formData.expectedBudget || '',
        paymentTerms: formData.paymentTerms || '',
        description: formData.description || '',
        categories: formData.categories || [],
        tags: formData.tags || [],
        media: formData.media || '',
        startDateTime: formData.startDateTime
          ? format(new Date(formData.startDateTime), 'yyyy-MM-dd HH:mm')
          : null,
        endDateTime: formData.endDateTime
          ? format(new Date(formData.endDateTime), 'yyyy-MM-dd HH:mm')
          : null,
      });
    }
  }, [formData, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="create-campaign-step1-form">
      <div className={styles.secBody}>
        <InputWrapper icon={campaignPopupImg}>
          <Input
            label="Campaign Name"
            maxLength="40"
            error={errors?.campaignName}
            {...register('campaignName')}
          />
        </InputWrapper>
        <InputWrapper icon={brandNameIcon}>
          <Input
            label="Brand Name"
            maxLength="40"
            error={errors?.brandName}
            {...register('brandName')}
          />
        </InputWrapper>
        <InputWrapper icon={calndrImg}>
          <div className="timeZoneDiv">
            <div className={styles.timeWrapp}>
              <div>
                <div className={styles.headWrapper}>
                  <h2 className={styles.hTxt}>Start On (Estimated) </h2>
                  <Tooltip label="The campaign start date is subject to change and is not fixed." />
                </div>
                <div className={styles.timeDiv}>
                  <input
                    type="datetime-local"
                    className={styles.innrTimeTxt}
                    {...register('startDateTime')}
                  />
                </div>
              </div>
              <div>
                <h2 style={{ marginBottom: '10px' }} className={styles.hTxt}>
                  To
                </h2>
              </div>
              <div>
                <h2 className={styles.hTxt}>Ends On (Optional)</h2>
                <div className={styles.timeDiv}>
                  <input
                    min={minEndDateTime}
                    type="datetime-local"
                    className={styles.innrTimeTxt}
                    {...register('endDateTime')}
                  />
                </div>
              </div>
            </div>
            {/* <div className={styles.locationWrap}>
              <p className={styles.locaTxt}>Asia/ Mumbai</p>
              <img className={styles.downImg} src={downIcon} alt="" />
            </div> */}
            <ErrorMessage error={errors?.startDateTime} />
            <ErrorMessage error={errors?.endDateTime} />
          </div>
        </InputWrapper>
        <InputWrapper icon={expectedBudgIcon}>
          <Input
            label="Expected Budget"
            error={errors?.expectedBudget}
            type="number"
            startAdornment="₹"
            {...register('expectedBudget')}
            // following is to prevent value change on scroll
            onWheel={(e) => e.target.blur()}
          />
        </InputWrapper>
        <InputWrapper icon={paymentTermsIcon}>
          <div className={styles.popupInputWrap}>
            <h2 className={styles.hTxt}>Payment Terms</h2>
            <div className={styles.genderBox}>
              <RadioInput
                label="Cash"
                {...register('paymentTerms')}
                value="Cash"
              />
              <RadioInput
                label="Full Payment"
                {...register('paymentTerms')}
                value="Full Payment"
              />
              <RadioInput
                label="Partial Payment"
                {...register('paymentTerms')}
                value="Partial Payment"
              />
            </div>
            <ErrorMessage error={errors?.paymentTerms} />
          </div>
        </InputWrapper>
        <InputWrapper icon={descIcon}>
          <Input
            label="Description"
            placeholder={`Add the description notes for all the platforms/deliverables here. 

Eg.
Youtube: Description for youtube deliverables
Instagram: Description for instagram deliverables`}
            error={errors?.description}
            Component="textarea"
            name="description"
            {...register('description')}
          />
          <div className={styles.descriptionI}>
            <Tooltip
              maxWidth={400}
              label={
                <>
                  Add the description notes for all the platforms/deliverables
                  here. <br />
                  <br />
                  Eg.
                  <br />
                  Youtube: Description for youtube deliverables
                  <br />
                  Instagram: Description for instagram deliverables
                </>
              }
            />
          </div>
        </InputWrapper>
        <InputWrapper icon={selectCatIcon}>
          <div className={styles.popupInputWrap}>
            <Controller
              render={({ field }) => (
                <Select
                  label="Select Category"
                  onChange={field.onChange}
                  value={field.value}
                  options={categories}
                  labelSelector="name"
                  valueSelector="id"
                  multiple
                  showSelectAll
                />
              )}
              control={control}
              name="categories"
            />
            <ErrorMessage error={errors?.categories} />
          </div>
        </InputWrapper>
        <TagsSection
          fields={fields}
          append={append}
          remove={remove}
          errors={errors}
        />
        <InputWrapper icon={uplImgVideoIcon}>
          <div className={styles.popupInputWrap}>
            <h2 className={styles.hTxt}>Upload Images/Videos</h2>
            <Controller
              render={({ field }) => {
                const isSelectedFile = field.value[0] instanceof File;
                const fileName = isSelectedFile
                  ? field.value[0].name
                  : field.value?.split('/')?.slice(-1)[0];
                const fileExtention = fileName?.split('.')?.slice(-1)?.[0];
                const isVideo = fileExtention?.match(VIDEO_EXTENTIONS_REGX);
                const previewUrl = isSelectedFile
                  ? URL.createObjectURL(field.value[0])
                  : field.value;

                return (
                  <>
                    <Dropzone
                      wrapperClass={styles.uploadFolderSec}
                      ref={field.ref}
                      onChange={field.onChange}
                      render={() => (
                        <>
                          <img src="#" alt="" />
                          <h3 className={styles.dragDropTxt}>
                            <span>Drag & drop</span> or <span>browse</span> from
                            your computer
                          </h3>
                          <p className={styles.folderSizeTxt}>
                            Support .jpg, .png, .gif, .mp4 max 200 MB
                          </p>
                          <p className={styles.folderSizeTxt}>
                            (Recommended file format is gif)
                          </p>
                        </>
                      )}
                      accept={{
                        'image/*': ['.jpg', '.png', '.jpeg', '.gif'],
                        'video/*': ['.mp4'],
                      }}
                    />
                    {field.value && (
                      <div className={styles.previewContainer}>
                        {isVideo ? (
                          // eslint-disable-next-line jsx-a11y/media-has-caption
                          <video src={previewUrl} className={styles.preview} />
                        ) : (
                          <img
                            src={previewUrl}
                            width="80"
                            height="80"
                            className={styles.preview}
                          />
                        )}
                        <div className={styles.fileInfoWrapper}>
                          <p className={styles.filename}>{fileName}</p>
                          <p className={styles.filesize}>
                            {isSelectedFile
                              ? `${(
                                  (field.value[0].size || 0) / 1048576
                                ).toFixed(2)} MB`
                              : ' '}
                          </p>
                        </div>
                        <a
                          className={styles.viewLink}
                          href={previewUrl}
                          target="_blank"
                          rel="noreferrer"
                          title="View File"
                        >
                          <img src={featherEyeImg} alt="view" />
                        </a>
                      </div>
                    )}
                  </>
                );
              }}
              control={control}
              name="media"
            />
            <ErrorMessage error={errors?.media} />
          </div>
        </InputWrapper>
      </div>
      <div className={styles.createInfFooter}>
        <div className={clsx(styles.campaignBtns, styles.nxtWrap)}>
          <button
            type="submit"
            style={{ width: 166 }}
            className={styles.updateBtn}
          >
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

const TagsSection = ({ fields, remove, append, errors }) => {
  const [search, setSearch] = useState('');
  const debouncedSearchQuery = useDebounce(search);

  const { data: tags } = useQuery({
    queryKey: ['brand-campaign-tags-filter', 1, 10, debouncedSearchQuery],
    queryFn: () =>
      getTags({
        pageNo: 1,
        pageSize: 10,
        search: debouncedSearchQuery || undefined,
      }),
  });

  const selectedTagIds = useMemo(
    () => fields.map(({ tagId }) => tagId),
    [fields]
  );

  const filteredTags = useMemo(
    () =>
      debouncedSearchQuery
        ? tags?.map((tag) => ({
            ...tag,
            isSelected: selectedTagIds.includes(tag.id),
          }))
        : tags?.filter((tag) => !selectedTagIds.includes(tag.id)),
    [debouncedSearchQuery, tags, selectedTagIds]
  );

  const uniqueHashTags = useMemo(
    () => [...new Map(filteredTags?.map((item) => [item.name, item])).values()],
    [filteredTags]
  );

  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const onTagClick = (tag) => () => {
    if (tag.isSelected) {
      const index = fields.findIndex((field) => field.tagId === tag.id);
      remove(index);
    } else {
      append({ name: tag.name, tagId: tag.id });
    }
  };

  return (
    <InputWrapper icon={chooseTagsIcon}>
      <div className={styles.popupInputWrap}>
        <h2 className={styles.hTxt}>Choose Tags</h2>
        <div className={styles2.searchField} style={{ marginTop: 10 }}>
          <img
            className={clsx(styles2.sIcon, styles2.campignSearchIcon)}
            src={searchImg}
            alt=""
          />
          <input
            className={clsx(styles2.searchTxt, styles2.campaignSearchField)}
            placeholder="Type to search tags"
            type="text"
            value={search}
            onChange={onSearchChange}
          />
        </div>
        <div className={styles.tagsField}>
          {!debouncedSearchQuery &&
            fields?.map((tag, index) => (
              <button
                key={tag.id}
                type="button"
                className={clsx(styles.tags, styles.activeTags)}
                onClick={() => remove(index)}
              >
                {tag.name}

                {/* <img src={cutIcon} alt="" /> */}
              </button>
            ))}
          {uniqueHashTags?.map((tag) => (
            <button
              key={tag.id}
              type="button"
              className={clsx({
                [styles.tags]: true,
                [styles.activeTags]: tag.isSelected,
              })}
              onClick={onTagClick(tag)}
            >
              {tag.name}
            </button>
          ))}
        </div>
        {debouncedSearchQuery && tags?.length === 0 && (
          <ErrorMessage error={{ message: 'No tags found' }} />
        )}
        <div className="tagsField">
          <CreateTagDialog
            // onAdd={(tagName) => append({ name: tagName, tagId })}
            render={({ open }) => (
              <button type="button" className={styles.addNewBtn} onClick={open}>
                <img src={pinkAddIcon} alt="" /> Add New
              </button>
            )}
          />
        </div>
        <ErrorMessage error={errors?.tags} />
      </div>
    </InputWrapper>
  );
};

TagsSection.propTypes = {
  fields: PropTypes.array,
  append: PropTypes.func,
  remove: PropTypes.func,
  errors: PropTypes.object,
};

export default StepOne;
