import Chip from 'components/common/Chip';

const CAMPAIGN_STATUS = {
  DRAFTED: 'Drafted',
  UPCOMING: 'Upcoming',
  ACTIVE: 'Active',
  PAUSED: 'Paused',
  COMPLETED: 'Completed',
  ARCHIVED: 'Archived',
  DROPPED: 'Dropped',
};
Object.freeze(CAMPAIGN_STATUS);

const CAMPAIGN_QUOTE_STATUS = {
  NOT_SENT: 'no_quotation',
  USER_SENT: 'Quotation Request Sent',
  SUPERVISOR_REVISION_REQUESTED: 'Quotation Supervisor Revision Request',
  EXECUTIVE_REVISION_REQUESTED: 'Quotation Executive Revision Request',
  REVISION_REQUESTED: 'Quotation Admin Revision Request',
  MANAGER_CONFIRMED: 'Quotation Manager Confirmed',
  SUPERVISOR_CONFIRMED: 'Quotation Supervisor Confirmed',
  EXECUTIVE_CONFIRMED: 'Quotation Executive Confirmed',
  ADMIN_CONFIRMED: 'Quotation Admin Confirmed',
  USER_APPROVED: 'Quotation Approved',
  USER_REVISION_REQUESTED: 'Quotation Revision Request Sent',
  USER_APPROVED_SIGNED: 'Quotation Approved And Signed',
  DROPPED: 'Quotation Dropped',
};
Object.freeze(CAMPAIGN_QUOTE_STATUS);

const CAMPAIGN_QUOTE_ACTIONS = {
  ACCEPT: 'accept',
  RECHECK: 'recheck',
  DROP: 'drop',
};
Object.freeze(CAMPAIGN_QUOTE_ACTIONS);

const CAMPAIGN_PAYMENT_STATUS = {
  NA_PAYMENT: 'Not Applicable',
  NO_PAYMENT: 'Not Paid',
  PARTIAL_PAYMENT: 'Partially Paid',
  FULL_PAYMENT: 'Fully Paid',
};
Object.freeze(CAMPAIGN_PAYMENT_STATUS);

const CAMPAIGN_STATUS_CHIPS = {
  [CAMPAIGN_STATUS.DRAFTED]: <Chip value="Drafted" color="violet" />,
  [CAMPAIGN_STATUS.UPCOMING]: <Chip value="Upcoming" color="violet" />,
  [CAMPAIGN_STATUS.ACTIVE]: <Chip value="Active" color="green" />,
  [CAMPAIGN_STATUS.PAUSED]: <Chip value="Paused" color="yellow" />,
  [CAMPAIGN_STATUS.COMPLETED]: <Chip value="Completed" color="blue" />,
  [CAMPAIGN_STATUS.ARCHIVED]: <Chip value="Archived" color="red" />,
  [CAMPAIGN_STATUS.DROPPED]: <Chip value="Dropped" color="red" />,
};
Object.freeze(CAMPAIGN_STATUS_CHIPS);

const INF_CAMPAIGN_STATUS_CHIPS = {
  ...CAMPAIGN_STATUS_CHIPS,
};
Object.freeze(INF_CAMPAIGN_STATUS_CHIPS);

const INF_DELIVERABLE_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  SCHEDULED: 'scheduled',
  UPLOADED: 'uploaded',
  FAILED: 'upload_failed',
};
Object.freeze(INF_DELIVERABLE_STATUS);

export {
  CAMPAIGN_STATUS,
  CAMPAIGN_QUOTE_STATUS,
  CAMPAIGN_QUOTE_ACTIONS,
  CAMPAIGN_PAYMENT_STATUS,
  CAMPAIGN_STATUS_CHIPS,
  INF_CAMPAIGN_STATUS_CHIPS,
  INF_DELIVERABLE_STATUS,
};
