import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Popover } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import ReactCountryFlag from 'react-country-flag';
import { useMutation } from '@tanstack/react-query';
import { createContactRequest } from 'api/common/contactUs';
import Input from 'components/common/edit-profile/Input';
import { CONTACT_US_SCHEMA } from 'constants/schema';
import { ReactComponent as CallIcon } from 'assets/images/call-help.svg';
import styles from 'assets/scss/pages/dashboard.module.scss';
import Button from 'components/common/Button';
import { useBrandAuth } from 'stores/auth/brand';

const defaultValues = {
  name: '',
  mobile: '',
  countryCode: '+91',
  company: '',
  email: '',
  message: '',
  type: 'brand',
};
const CallPopover = () => {
  const triggerRef = useRef();

  const currentUser = useBrandAuth((state) => state.user);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CONTACT_US_SCHEMA),
    defaultValues,
  });

  const contactUsMutation = useMutation({
    mutationFn: ({ name, company, email, mobile, message }) => {
      const payload = { name, mobile, user_type: 'brand' };
      if (email) payload.email = email;
      if (company) payload.company_name = company;
      if (message) payload.message = message;

      return createContactRequest(payload);
    },
    onSuccess: (data) => {
      toast.success(data?.message);
      handleClose();
    },
  });

  useEffect(() => {
    if (currentUser) {
      reset({
        name: `${currentUser.first_name} ${currentUser.last_name}`,
        mobile: currentUser.mobile,
        company: currentUser.agency_name || '',
        email: currentUser.email || '',
        countryCode: '+91',
        message: 'Sminco support request.',
        type: 'brand',
      });
    }
  }, [currentUser, reset]);

  const handleClose = () => {
    triggerRef.current?.firstChild?.click();
    reset();
  };

  return (
    <Popover>
      <div ref={triggerRef}>
        <Float portal offset={24} placement="top-end">
          <Popover.Button type="button" className={styles.callBtn}>
            <CallIcon />
          </Popover.Button>
          <Popover.Panel className={styles.callPopoverPanel}>
            <h1 className={styles.heading}>Sminco Support</h1>
            <form onSubmit={handleSubmit(contactUsMutation.mutate)}>
              <Input
                label="Full Name"
                error={errors?.name}
                {...register('name')}
              />
              <Input
                startAdornment={
                  <span>
                    <ReactCountryFlag
                      countryCode="IN"
                      svg
                      style={{ fontSize: '1.3em' }}
                    />{' '}
                    (+91)
                  </span>
                }
                label="Mobile Number"
                ismobile="mobile"
                error={errors?.mobile}
                type="tel"
                maxLength="10"
                {...register('mobile')}
              />
              <Button
                fullWidth
                size="large"
                type="submit"
                isLoading={contactUsMutation.isLoading}
              >
                Submit
              </Button>
            </form>
          </Popover.Panel>
        </Float>
      </div>
    </Popover>
  );
};

export default CallPopover;
