import React from 'react';
import clsx from 'clsx';
import { useQuery } from '@tanstack/react-query';
import {
  getTrendingInfluencers,
  getTrendingSubCategories,
  getTrendingCategories,
} from 'api/brand/search';
import { Avatar } from 'components/common/Avatar';
import styles from 'assets/scss/pages/search.module.scss';
import { Link } from 'react-router-dom';
import routes from 'constants/routes';
import { Popover } from '@headlessui/react';

const SearchPropoverContent = () => {
  const { data: influencersData } = useQuery({
    queryKey: ['brand-trending-influencers', 1, 5],
    queryFn: () => getTrendingInfluencers({ pageNo: 1, pageSize: 5 }),
  });

  const { data: subCategoriesData } = useQuery({
    queryKey: ['brand-trending-subcategories'],
    queryFn: () => getTrendingSubCategories(),
  });

  const { data: categoriesData } = useQuery({
    queryKey: ['brand-trending-categories'],
    queryFn: () => getTrendingCategories(),
  });

  const influencers = influencersData?.data?.trendingCampaignListData;
  const subCategories = subCategoriesData?.data?.trendingSubCategoriesListData;
  const categories = categoriesData?.data?.trendingCategoriesListData;

  return (
    <div className={styles.searchPopupContainer}>
      <div className={styles.searchPopup}>
        <nav className="searchList">
          <h3 className={styles.searchHeading}>Popular Categories</h3>
          {categories
            ?.slice(0, 8)
            ?.map(
              ({
                id,
                category_name: categoryName,
                category_image: categoryImage,
              }) => (
                <Popover.Button
                  as={Link}
                  to={routes.BRAND_SEARCH_INF_LIST}
                  state={{ categories: [id] }}
                  className={styles.searchSubList}
                  key={id}
                >
                  <img className={styles.imgWidth} src={categoryImage} alt="" />
                  <p className={styles.searchsubTxt}>{categoryName}</p>
                </Popover.Button>
              )
            )}
        </nav>
        <nav>
          <h3 className={styles.searchHeading}>Trending Influencers</h3>
          {influencers
            ?.slice(0, 5)
            ?.map(
              ({
                id,
                full_name: influencerName,
                profile_image: influencerImage,
              }) => (
                <Popover.Button
                  as={Link}
                  to={routes.BRAND_SEARCH_INF_LIST}
                  state={{ search: influencerName }}
                  className={clsx(styles.searchSubList, styles.searchSecList)}
                  key={id}
                >
                  <Avatar src={influencerImage} alt="" size="large" />
                  <p className={styles.searchsubTxt}>{influencerName}</p>
                </Popover.Button>
              )
            )}
        </nav>
        <nav>
          <h3 className={styles.searchHeading}>Trending Sub Categories</h3>
          {subCategories
            ?.slice(0, 8)
            ?.map(({ id, subcategory_name: subCategoryName }) => (
              <Popover.Button
                as={Link}
                to={routes.BRAND_SEARCH_INF_LIST}
                state={{ subCategories: [id] }}
                className={clsx(styles.searchSubList, styles.searchSecList)}
                key={id}
              >
                <p className={styles.searchsubTxt}>{subCategoryName}</p>
              </Popover.Button>
            ))}
        </nav>
      </div>
    </div>
  );
};

export default SearchPropoverContent;
