const PAYMENT_TYPES = {
  CREDIT_CARD: 'OPTCRDC',
  DEBIT_CARD: 'OPTDBCRD',
  NET_BANKING: 'OPTNBK',
};
Object.freeze(PAYMENT_TYPES);

const PAYMENT_MODULES = {
  CAMPAIGN: 'campaign',
  BRAND_SUBSCRIPTION: 'brand-subscription',
};
Object.freeze(PAYMENT_MODULES);

export { PAYMENT_TYPES, PAYMENT_MODULES };
