import React, { useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  getCustomListDetails,
  getFollowersRange,
  getInfCategories,
  getStateList,
} from 'api/brand/search';
import Table from 'components/common/Table';
import { Avatar } from 'components/common/Avatar';
import Menu from 'components/common/Menu';
import Button from 'components/common/Button';
import ActionBar from 'components/brand-dashboard/ActionBar';
import FilterMenu from 'components/common/FilterDropdown/FilterMenu';
import TableError from 'components/common/Table/TableError';
import FilterDropdown from 'components/common/FilterDropdown';
import Tooltip from 'components/common/Tooltip';
import useFilterProps from 'hooks/useFilterProps';
import useDebounce from 'hooks/useDebounce';
import styles from 'assets/scss/pages/search.module.scss';
import {
  searchImg,
  ytIcon,
  cogwheelIcon,
  categoryIcon,
  followerSvg,
  rcScoreSvg,
  materialSvg,
  locationSvg,
  locationDownIcon,
  instaSvg,
  twitterSvg,
  facebookSvg,
  pinkCrossIcon,
  blueTickIcon,
} from 'constants/images';
import { ReactComponent as MoreIcon } from 'assets/images/more-icon.svg';
// import { ReactComponent as AddIcon } from 'assets/images/add_grey_icon.svg';
import { ReactComponent as StatsIcon } from 'assets/images/stats-icon.svg';
import { ReactComponent as ListIcon } from 'assets/images/custom-list.svg';
import { ReactComponent as RupeeIcon } from 'assets/images/indian-rupee-sign-solid.svg';

import { PLATFORMS } from 'constants/config/filters';
import { useDialog } from 'stores/dialog';
import { DIALOGS } from 'constants/config/dialog-names';
import routes from 'constants/routes';
import { formatNumber } from 'utils';
import { uinqueCategories } from '../influencer-profile/formatData/sortData';

const columns = [
  {
    Header: 'Profile',
    accessor: 'full_name',
    width: 240,
    minWidth: 240,
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return (
        <div className={styles.profileDiv}>
          <Avatar src={row?.profile_image} alt="" size="large" />
          <p>{row?.full_name}</p>
          {row?.is_registered ? (
            <img src={blueTickIcon} alt="verified" />
          ) : null}
        </div>
      );
    },
  },
  {
    Header: 'Platform',
    accessor: 'platform',
    width: 60,
    minWidth: 60,
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      const icon = useMemo(() => {
        switch (row?.platform) {
          case 'youtube':
            return ytIcon;
          case 'instagram':
            return instaSvg;
          case 'twitter':
            return twitterSvg;
          case 'facebook':
            return facebookSvg;
          default:
            return null;
        }
      }, [row]);
      return icon ? <Avatar src={icon} alt="" /> : null;
    },
  },
  // {
  //   Header: 'SMINCO Scrore',
  //   accessor: 'sminco_score',
  //   info: 'Platform Score',
  //   Cell: (cellInfo) => {
  //     const row = cellInfo?.row?.original;
  //     return <p className={styles.downloadedTxt}>{row?.smincoScore}</p>;
  //   },
  // },
  {
    Header: 'Categories',
    accessor: 'categories',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      const categories = uinqueCategories(
        row?.influencer_categories?.map((category) => category.subcategory) ||
          []
      );
      const categoriesCount = categories?.length;
      return (
        <>
          {categoriesCount > 0 && (
            <div className={styles.catgryDiv}>
              {categoriesCount > 0 && (
                <span className={styles.catgrySpan}>{categories[0]}</span>
              )}
              {categoriesCount > 1 ? (
                <>
                  <Tooltip
                    className={styles.catgryTxt}
                    label={
                      <div className={styles.moreContainer}>
                        <ul>
                          {categories
                            ?.slice(1, categoriesCount + 1)
                            ?.map((category, i) => (
                              <li key={i}>{category}</li>
                            ))}
                        </ul>
                      </div>
                    }
                  >
                    +{categoriesCount - 1} More
                  </Tooltip>
                </>
              ) : null}
            </div>
          )}
        </>
      );
    },
  },
  { Header: 'Followers', accessor: 'followers' },
  { Header: 'Avg Likes', accessor: 'avgLikes' },
  { Header: 'Avg Views', accessor: 'avgViews' },
  {
    Header: 'ER',
    accessor: 'engagementRate',
    info: 'Engagement Rate',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return <p className={styles.downloadedTxt}>{row?.engagementRate}%</p>;
    },
  },
  { Header: 'Location', accessor: 'location' },
  {
    Header: '',
    accessor: 'options',
    width: 20,
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      const setDialog = useDialog((state) => state.setDialog);
      return (
        <Menu
          renderButton={() => (
            <Menu.Button>
              <MoreIcon />
            </Menu.Button>
          )}
        >
          {/* <Menu.Item
            onClick={() =>
              setDialog(DIALOGS.BRAND_INF_COST_OVERVIEW, {
                influencerId: row?.id,
                platform: row?.platform,
              })
            }
            disabled={!row?.is_registered}
          >
            <RupeeIcon />
            <span>View Cost</span>
          </Menu.Item> */}
          <Menu.Item
            onClick={() =>
              setDialog(DIALOGS.BRAND_INF_PROFILE_OVERVIEW, {
                influencerId: row?.id,
                platform: row?.platform,
              })
            }
          >
            <StatsIcon />
            <span>Preview</span>
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              setDialog(DIALOGS.ADD_INF_TO_CUSTOM_LIST, {
                influencerIds: [row?.id],
              })
            }
          >
            <ListIcon />
            <span>Custom List</span>
          </Menu.Item>
        </Menu>
      );
    },
  },
];

const ListDetails = () => {
  const { id: listId } = useParams();
  const location = useLocation();
  const { listName } = location.state || {};

  const [selectedRows, setSelectedRows] = useState([]);

  const tableRef = useRef(null);
  const {
    page,
    rowsPerPage,
    changePage,
    changeRowsPerPage,
    search,
    changeSearch,
    // order,
    // orderBy,
    // changeOrder,
    filter,
    changeFilter,
  } = useFilterProps({
    defaultFilters: { platform: 'instagram' },
  });

  const debouncedSearchQuery = useDebounce(search);
  const platformFilter = filter.platform;
  const categoriesFilter = filter.categories;
  const locationFilter = filter.location;
  const followersFilter = filter.followers;
  const engagementRateFilter = filter.engagement;
  const costFilter = filter.cost;

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      'brand-custom-lists-details',
      listId,
      page,
      rowsPerPage,
      debouncedSearchQuery,
      categoriesFilter?.toString(),
      locationFilter?.toString(),
      followersFilter,
      platformFilter,
    ],
    queryFn: () =>
      getCustomListDetails({
        custom_whishlist_id: listId,
        pageNo: page,
        pageSize: rowsPerPage,
        search: debouncedSearchQuery || undefined,
        category_id: categoriesFilter || undefined,
        location: locationFilter || undefined,
        subscriber_count_from: followersFilter?.split('-')[0] || undefined,
        subscriber_count_to: followersFilter?.split('-')[1] || undefined,
        is_youtube: platformFilter ? platformFilter === 'youtube' : undefined,
        is_instagram: platformFilter
          ? platformFilter === 'instagram'
          : undefined,
        is_facebook: platformFilter ? platformFilter === 'facebook' : undefined,
        is_twitter: platformFilter ? platformFilter === 'twitter' : undefined,
      }),
    enabled: !!listId,
  });

  const { data: categories } = useQuery({
    queryKey: ['brand-categories'],
    queryFn: () => getInfCategories(),
  });

  const { data: followersRange } = useQuery({
    queryKey: ['brand-followers-range'],
    queryFn: () => getFollowersRange(),
  });

  const { data: states } = useQuery({
    queryKey: ['brand-states'],
    queryFn: () => getStateList(),
  });

  const onPlatformChange = (newValue) => {
    if (newValue === 'instagram' || newValue === 'facebook') {
      tableRef.current?.setHiddenColumns(['avgViews']);
    } else {
      tableRef.current?.setHiddenColumns([]);
    }
    changeFilter('platform', newValue);
  };

  const onCategoriesChange = (newValue) => {
    changeFilter('categories', newValue);
  };

  const onLocationChange = (newValue) => {
    changeFilter('location', newValue);
  };

  const onFollowersChange = (newValue) => {
    changeFilter('followers', newValue);
  };

  // const onRCScoreChange = (newValue) => {
  //   changeFilter('rc', newValue);
  // };

  const onEngagementRateChange = (newValue) => {
    changeFilter('engagement', newValue);
  };

  const onCostChange = (newValue) => {
    changeFilter('cost', newValue);
  };

  const onSearchChange = (e) => {
    changeSearch(e.target.value);
  };

  const onCategoryRemove = (id) => () => {
    onCategoriesChange(
      categoriesFilter?.filter((categoryId) => categoryId !== id)
    );
  };

  const onLocationRemove = (id) => () => {
    onLocationChange(locationFilter?.filter((location) => location !== id));
  };

  const onEngagementRateFilterRemove = () => {
    onEngagementRateChange(undefined);
  };
  const onCostFilterRemove = () => {
    onCostChange(undefined);
  };

  const onClearFilter = () => {
    changeFilter('categories', []);
    changeFilter('location', []);
    changeFilter('followers', undefined);
    changeFilter('engagement', undefined);
    changeFilter('cost', undefined);
  };

  // const onPlatformRemove = () => {
  //   onPlatformChange('');
  // };

  const onFollowersFilterRemove = () => {
    onFollowersChange('');
  };

  const selectdCategories = useMemo(
    () =>
      categories?.filter((category) => categoriesFilter?.includes(category.id)),
    [categoriesFilter, categories]
  );

  const selectedPlatform = useMemo(
    () => PLATFORMS.find((platform) => platform.value === filter.platform),
    [filter]
  );

  const influencers = useMemo(
    () =>
      data?.data?.getWhishlistList?.map((inf = {}) => {
        inf.platform = platformFilter;
        inf.location = inf?.city;
        if (platformFilter === 'youtube') {
          const {
            avg_likes: avgLikes,
            avg_views: avgViews,
            subscriber_count: followers,
            avg_enagagement_rate: engagementRate,
            profile_image_url: youtubeProfile,
            sminco_score: smincoScore,
          } = inf?.influencer_youtube || {};
          return {
            ...inf,
            profile_image: inf?.profile_image || youtubeProfile,
            smincoScore,
            followers: formatNumber(followers),
            avgLikes: formatNumber(avgLikes),
            avgViews: formatNumber(avgViews),
            engagementRate: formatNumber(engagementRate),
          };
        }
        if (platformFilter === 'instagram') {
          const followers = inf?.influencer_instagram?.followers_count;
          const engagementRate =
            inf?.influencer_instagram?.avg_enagagement_rate;
          const smincoScore = inf?.influencer_instagram?.sminco_score;
          const {
            avg_likes: avgLikes,
            avg_comments: avgComments,
            profile_image_url: instagramProfile,
          } = inf?.influencer_instagram?.media_stat || {};
          return {
            ...inf,
            profile_image: inf?.profile_image || instagramProfile,
            smincoScore,
            followers: formatNumber(followers),
            avgLikes: formatNumber(avgLikes),
            avgComments: formatNumber(avgComments),
            avgViews: 0,
            engagementRate: formatNumber(engagementRate),
          };
        }
        if (platformFilter === 'facebook') {
          const {
            followers_count: followers,
            avg_likes: avgLikes,
            profile_picture: facebookProfile,
            sminco_score: smincoScore,
          } = inf?.influencer_facebook || {};
          return {
            ...inf,
            profile_image: inf?.profile_image || facebookProfile,
            smincoScore,
            followers: formatNumber(followers),
            avgLikes: formatNumber(avgLikes),
            avgViews: 0,
            engagementRate: 0,
          };
        }
        if (platformFilter === 'twitter') {
          const {
            avg_likes: avgLikes,
            avg_impression_count: avgViews,
            followers_count: followers,
            engagement_rate: engagementRate,
            profile_picture: twitterProfile,
            sminco_score: smincoScore,
          } = inf?.influencer_twitter || {};
          return {
            ...inf,
            smincoScore,
            profile_image: inf?.profile_image || twitterProfile,
            followers: formatNumber(followers),
            avgLikes: formatNumber(avgLikes),
            avgViews: formatNumber(avgViews),
            engagementRate: formatNumber(engagementRate),
          };
        }
        return inf;
      }),
    [data, platformFilter]
  );

  const resetRowSelection = () => tableRef?.current?.resetSelectedRows();

  const influencersCount = data?.data?.count;
  const showClearButton =
    selectdCategories?.length > 0 ||
    locationFilter?.length > 0 ||
    followersFilter;

  return (
    <>
      <div className={styles.influencerHeader}>
        <div className={styles.campignBreadcrumbs}>
          <Link to={routes.BRAND_SEARCH_CUSTOM_LIST} className={styles.navTxt}>
            Custom List
          </Link>
          <img className={styles.rightArrow} src={locationDownIcon} alt="" />
          <span className={styles.navSecndTxt}>{listName}</span>
        </div>

        <div className={styles.brandHeader}>
          <h1 className={styles.brTxt}>{listName}</h1>
          <img src="#" alt="" />
        </div>

        <div className={styles.categorySelector}>
          <FilterDropdown
            options={PLATFORMS}
            label="Platform"
            startIconUrl={cogwheelIcon}
            multiple={false}
            onChange={onPlatformChange}
            value={filter.platform}
          />
          <FilterDropdown
            options={categories}
            label="Categories"
            startIconUrl={categoryIcon}
            labelSelector="name"
            valueSelector="id"
            onChange={onCategoriesChange}
            value={categoriesFilter}
          />
          <FilterDropdown
            options={states}
            label="Location"
            labelSelector="name"
            valueSelector="name"
            startIconUrl={locationSvg}
            onChange={onLocationChange}
            value={locationFilter}
          />
          <FilterDropdown
            options={followersRange}
            label="Followers"
            startIconUrl={followerSvg}
            multiple={false}
            onChange={onFollowersChange}
            value={followersFilter}
          />
          {/* <FilterDropdown
            options={[{ label: 'abc', value: 'abc' }]}
            label="SMINCO Score"
            startIconUrl={rcScoreSvg}
            disabled
          /> */}
          <FilterMenu label="More" startIconUrl={materialSvg}>
            <FilterMenu.RangeSelector
              label="Engagement Rate"
              minLimit={2}
              maxLimit={10}
              onClear={onEngagementRateFilterRemove}
              onApply={onEngagementRateChange}
            />
            <FilterMenu.RangeSelector
              label="Cost"
              popoverLabel="Select Cost"
              minLimit={100000}
              maxLimit={600000}
              sliderProps={{ step: 100 }}
              onClear={onCostFilterRemove}
              onApply={onCostChange}
              disabled
            />
          </FilterMenu>
        </div>
      </div>
      <div className="influencerList">
        <div className={styles.influencerSelectors}>
          {selectedPlatform ? (
            <div
              className={clsx(styles.categryDropdown, styles.activeDropdown)}
            >
              <div className={styles.dropdownItem}>
                <img
                  src={selectedPlatform.icon}
                  alt=""
                  height="24"
                  width="24"
                  className={styles.itemImage}
                />
                <span className={clsx(styles.itemTxt, styles.activeTxt)}>
                  {selectedPlatform.label}
                </span>
              </div>
              {/* <button type="button" onClick={onPlatformRemove}>
                <img src={cutIcon} alt="" />
              </button> */}
            </div>
          ) : null}

          {selectdCategories?.map(({ id, name, image }) => (
            <div className={styles.categryDropdown} key={id}>
              <div className={styles.dropdownItem}>
                {image && (
                  <img
                    src={image}
                    alt=""
                    height="24"
                    width="24"
                    className={styles.itemImage}
                  />
                )}
                <span className={styles.itemTxt}>{name}</span>
              </div>
              <button type="button" onClick={onCategoryRemove(id)}>
                <img src={pinkCrossIcon} alt="" />
              </button>
            </div>
          ))}

          {locationFilter?.map((location) => (
            <div className={styles.categryDropdown} key={location}>
              <div className={styles.dropdownItem}>
                <span className={styles.itemTxt}>{location}</span>
              </div>
              <button type="button" onClick={onLocationRemove(location)}>
                <img src={pinkCrossIcon} alt="" />
              </button>
            </div>
          ))}

          {followersFilter && (
            <div className={styles.categryDropdown}>
              <div className={styles.dropdownItem}>
                <span className={styles.itemTxt}>
                  {followersFilter} Followers
                </span>
              </div>
              <button type="button" onClick={onFollowersFilterRemove}>
                <img src={pinkCrossIcon} alt="" />
              </button>
            </div>
          )}

          {engagementRateFilter && (
            <div className={styles.categryDropdown}>
              <div className={styles.dropdownItem}>
                <span className={styles.itemTxt}>
                  {engagementRateFilter[0]}-{engagementRateFilter[1]} Engagement
                  Rate
                </span>
              </div>
              <button type="button" onClick={onEngagementRateFilterRemove}>
                <img src={pinkCrossIcon} alt="" />
              </button>
            </div>
          )}

          {costFilter && (
            <div className={styles.categryDropdown}>
              <div className={styles.dropdownItem}>
                <span className={styles.itemTxt}>
                  {costFilter[0]}-{costFilter[1]} Cost
                </span>
              </div>
              <button type="button" onClick={onCostFilterRemove}>
                <img src={pinkCrossIcon} alt="" />
              </button>
            </div>
          )}

          {showClearButton && (
            <Button variant="text" onClick={onClearFilter}>
              Clear Filters
            </Button>
          )}
        </div>
      </div>
      <div className={styles.influencerHeader}>
        <div className={styles.subCategoryTitle}>
          <h2 className={styles.trendingTxt}>
            {formatNumber(influencersCount, { notation: 'standard' })}{' '}
            Influencers Added
          </h2>
          <div className={styles.searchField}>
            <img className={styles.sIcon} src={searchImg} alt="" />
            <input
              className={styles.searchTxt}
              placeholder="Type to search list below"
              type="text"
              value={search || ''}
              onChange={onSearchChange}
            />
          </div>
        </div>
        <Table
          data={influencers}
          columns={columns}
          paginationProps={{
            currentPage: page,
            changePage,
            count: influencersCount,
            rowsPerPage,
            changeRowsPerPage,
            rowsPerPageOptions: [10, 30, 50],
          }}
          isRowsSelectable
          onSlectedRowsChange={setSelectedRows}
          // onSortChange={changeOrder}
          noDataValue={
            <TableError infoText="No influencers match the search criteria." />
          }
          isLoading={isLoading || isFetching}
          ref={tableRef}
        />
      </div>
      <ActionBar
        resetRowSelection={resetRowSelection}
        selectedRows={selectedRows}
        showCustomListButton={false}
        show={selectedRows.length > 0}
        platform={platformFilter}
      />
    </>
  );
};

export default ListDetails;
