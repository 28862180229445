import { Outlet, ScrollRestoration } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { toast, ToastContainer } from 'react-toastify';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 1 * 60 * 1000, // in milliseconds
      cacheTime: 5 * 60 * 1000, // in milliseconds
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      networkMode: 'always',
    },
    mutations: {
      retry: false,
      onError: (err) => {
        console.log(err);
        const errorMessage =
          err.response?.data?.message ||
          err.response?.data?.error ||
          err.message;
        toast.error(errorMessage);
      },
    },
  },
});

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Outlet />
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
      <ToastContainer
        closeButton={false}
        theme="colored"
        autoClose={2500}
        hideProgressBar
        limit={2}
      />
      <ScrollRestoration />
    </>
  );
}

export default App;
