import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Sector } from 'recharts';
import PropTypes from 'prop-types';

const InfPerformancePieChart = ({ trackingData }) => (
  <div className="analyticsSection">
    <div className="header">
      <h2 className="title">influencer performance</h2>

      {/* <div className={styles.benchmarkSectionView}>
        <select className={styles.selctMenu}>
          <option value="value">Audience Reach</option>
        </select>
      </div> */}
    </div>
    <div className="contentWrapper">
      <ResponsiveContainer width="100%" height={224}>
        <PieChart width={216} height={216}>
          <Pie
            activeIndex={0}
            activeShape={renderCustomizedLabelPieChart}
            dataKey="value"
            data={trackingData}
            innerRadius={65}
            outerRadius={100}
            fill="#7C087F"
            startAngle={90}
            endAngle={550}
            isAnimationActive={false}
            labelLine={false}
            stroke="none"
          >
            {trackingData?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry?.color}
                style={{ outline: 'none' }}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  </div>
);

const renderCustomizedLabelPieChart = (props) => {
  const {
    cx,
    cy,
    value,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  } = props;
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        textAnchor="middle"
      />
      <text
        textAnchor="middle"
        x={cx}
        y={cy + 12}
        fontSize={28}
        fontWeight={600}
      >
        {value}%
      </text>
    </g>
  );
};

InfPerformancePieChart.propTypes = {
  trackingData: PropTypes.array,
};

export default InfPerformancePieChart;
