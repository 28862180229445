import React from 'react';
import styles from 'assets/scss/pages/landing-homepage.module.scss';
import {
  digitalStrategyImg,
  influencerMarketingImg,
  hashImg,
  socialMediaGrowthImg,
  socialMediaAmplificationImg,
  accountManagemntImg,
  contntMangmntImg,
  viralMarktingImg,
  technologyImg,
} from 'constants/images';

const OurServicesSection = () => (
  <div className={styles.ourServicesContainer}>
    <div className={styles.servicesHeader}>
      <h2 className={styles.serviceTxt}>Services we provide</h2>
      <p className={styles.serviceTxt2}>
        If you're ready to launch your influencer marketing campaign, you're in
        the right spot!
      </p>
    </div>
    <div className={styles.servicesInnrContainer}>
      <div className={styles.contInnrPart}>
        <img src={digitalStrategyImg} alt="" />
        <h3 className={styles.serviceTxt3}>Digital Strategy</h3>
      </div>
      <div className={styles.contInnrPart}>
        <img src={influencerMarketingImg} alt="" />
        <h3 className={styles.serviceTxt3}>Influencer Marketing</h3>
      </div>
      <div className={styles.contInnrPart}>
        <img src={hashImg} alt="" />
        <h3 className={styles.serviceTxt3}>Hashtag Challenge</h3>
      </div>
      <div className={styles.contInnrPart}>
        <img src={socialMediaGrowthImg} alt="" />
        <h3 className={styles.serviceTxt3}>Social Media Growth</h3>
      </div>
      <div className={styles.contInnrPart}>
        <img src={socialMediaAmplificationImg} alt="" />
        <h3 className={styles.serviceTxt3}>Social Media Amplification</h3>
      </div>
      <div className={styles.contInnrPart}>
        <img src={accountManagemntImg} alt="" />
        <h3 className={styles.serviceTxt3}>Account Management</h3>
      </div>
      <div className={styles.contInnrPart}>
        <img src={contntMangmntImg} alt="" />
        <h3 className={styles.serviceTxt3}>Content Management</h3>
      </div>
      <div className={styles.contInnrPart}>
        <img src={viralMarktingImg} alt="" />
        <h3 className={styles.serviceTxt3}>Viral Marketing</h3>
      </div>
      <div className={styles.contInnrPart}>
        <img src={technologyImg} alt="" />
        <h3 className={styles.serviceTxt3}>Technology</h3>
      </div>
    </div>
  </div>
);

export default OurServicesSection;
