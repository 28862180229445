import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'components/common/dialog';
import styles from 'assets/scss/pages/popup.module.scss';
import { Select } from 'components/common/campaign';
import { useQuery } from '@tanstack/react-query';
import { getCampaigns } from 'api/brand/campaign';
import { useCreateCampaign } from 'stores/create-campaign';
import { useDialog } from 'stores/dialog';
import { DIALOGS } from 'constants/config/dialog-names';
import ErrorMessage from 'components/common/form/ErrorMessage';
import errorMessages from 'constants/config/error-messagess';
import {
  CAMPAIGN_QUOTE_STATUS,
  CAMPAIGN_STATUS,
} from 'constants/config/campaign';
import { generateDefaultDeliverable } from 'utils';

const AddInfToExistingCampaignDialog = ({ open, close, influencers }) => {
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [error, setError] = useState();

  const { setFormData: setInfsForCampaign, setFormStep: setCampaignFormStep } =
    useCreateCampaign();
  const setDialog = useDialog((state) => state.setDialog);

  const { data: campaigns } = useQuery({
    queryKey: [
      'brand-campaigns',
      CAMPAIGN_STATUS.DRAFTED,
      CAMPAIGN_QUOTE_STATUS.NOT_SENT,
    ],
    queryFn: () =>
      getCampaigns({
        status: [CAMPAIGN_STATUS.DRAFTED],
        quotation_status: [CAMPAIGN_QUOTE_STATUS.NOT_SENT],
      }),
    select: (res) => res?.data?.campaigns?.rows,
  });

  const influencerDeliverables = useMemo(() => {
    const influencerDeliverables = [];
    influencers?.forEach((influencer) => {
      const deliverables = [];
      const platforms = [];

      if (influencer.is_instagram) {
        deliverables.push(generateDefaultDeliverable('instagram'));
        platforms.push('instagram');
      }
      if (influencer.is_facebook) {
        deliverables.push(generateDefaultDeliverable('facebook'));
        platforms.push('facebook');
      }
      if (influencer.is_twitter) {
        deliverables.push(generateDefaultDeliverable('twitter'));
        platforms.push('twitter');
      }
      if (influencer.is_youtube) {
        deliverables.push(generateDefaultDeliverable('youtube'));
        platforms.push('youtube');
      }
      influencerDeliverables.push({
        platforms,
        influencerId: influencer.id,
        influencerProfile: influencer.profile_image,
        influencerName: influencer.full_name,
        deliverables,
      });
    });
    return influencerDeliverables;
  }, [influencers]);

  const onCampaignChange = (newValue) => {
    setSelectedCampaign(newValue);
    setError(null);
  };

  const onUpdate = () => {
    if (selectedCampaign) {
      setInfsForCampaign({ campaignId: selectedCampaign });
      setCampaignFormStep(3);
      setDialog(DIALOGS.CREATE_CAMPAIGN, {
        newAddedInfluencers: influencerDeliverables,
      });
    } else {
      setError({ message: errorMessages.CMAPAIGN_REQ });
    }
  };

  const onCreateNewCampaign = () => {
    close();
    setInfsForCampaign({ influencers: influencerDeliverables });
    setDialog(DIALOGS.CREATE_CAMPAIGN);
  };

  return (
    <Dialog
      title="Select Campaign"
      open={open}
      close={close}
      size="xs"
      fullWidth
    >
      <div className={styles.campListWrap}>
        <Select
          options={campaigns}
          labelSelector="campaign_name"
          valueSelector="id"
          placeholder="Click to select campaign"
          value={selectedCampaign}
          onChange={onCampaignChange}
        />
        <ErrorMessage error={error} />
      </div>
      <div className={styles.campaignBtns}>
        <button
          type="button"
          className={styles.createCampaignBtn}
          onClick={onCreateNewCampaign}
        >
          Create New Campaign
        </button>
        <button type="button" className={styles.updateBtn} onClick={onUpdate}>
          Update
        </button>
      </div>
    </Dialog>
  );
};

AddInfToExistingCampaignDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  influencers: PropTypes.array,
};

export default AddInfToExistingCampaignDialog;
