import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/common/Loader';
import styles from './Table.module.scss';

const LoaderWithWrapper = () => (
  <div style={{ color: '#ff00a2' }}>
    <Loader />
  </div>
);

const TableState = ({
  isLoading = false,
  delayedIsLoading = false,
  loadingValue = <LoaderWithWrapper />,
  noDataValue = 'No rows found',
}) => (
  <tr className={styles.tableStateContainer}>
    <td colSpan={99} style={{ padding: 0 }}>
      <div className={styles.stateWrapper}>
        <div className={styles.textCenter}>
          {delayedIsLoading ? loadingValue : null}
          {!isLoading && !delayedIsLoading ? noDataValue : null}
        </div>
      </div>
    </td>
  </tr>
);

TableState.propTypes = {
  isLoading: PropTypes.bool,
  delayedIsLoading: PropTypes.bool,
  loadingValue: PropTypes.node,
  noDataValue: PropTypes.node,
};

export default TableState;
