import { DIALOGS } from 'constants/config/dialog-names';
import React from 'react';
import { useDialog } from 'stores/dialog';
import InfCostOverviewDialog from './InfCostOverviewDialog';
import InfProfileOverviewDialog from './InfProfileOverviewDialog';
import AddInfToExistingCampaignDialog from './AddInfToExistingCampaignDialog';
import CreateCampaignDialog from './create-campaign';
import DeliverablesCostBreakdownDialog from './DeliverablesCostBreakdownDialog';
import CreateCustomListDialog from './CreateCustomListDialog';
import AddInfToCustomListDialog from './AddInfToCustomListDialog';
import QuestionnaireDialog from './QuestionnaireDialog';
import CampaignInsightsDialog from './CampaignInsightsDialog';
import EditProfileDialog from './EditProfile';
import EditAddressDialog from './EditAddress';
import EditProfileCategories from './EditProfileCategories';
import EditProfilePreferences from './EditProfilePreferences';
import EditProfileGstDoc from './EditProfileGstDoc';
import EditProfilePanDoc from './EditProfilePanDoc';
import InfBrandPostsDialog from './InfBrandPostsDialog';
import DeleteCampaignDialog from './DeleteCampaignDialog';

function DialogSwicher() {
  const { dialog, dialogProps, clearDialog } = useDialog();

  if (dialog === DIALOGS.BRAND_INF_COST_OVERVIEW) {
    return (
      <InfCostOverviewDialog
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }

  if (dialog === DIALOGS.BRAND_INF_PROFILE_OVERVIEW) {
    return (
      <InfProfileOverviewDialog
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }

  if (dialog === DIALOGS.BRAND_INF_BRAND_POSTS) {
    return (
      <InfBrandPostsDialog
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }

  if (dialog === DIALOGS.ADD_INF_TO_EXISTING_CAMPAIGN) {
    return (
      <AddInfToExistingCampaignDialog
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }

  if (dialog === DIALOGS.CREATE_CAMPAIGN) {
    return (
      <CreateCampaignDialog
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }

  if (dialog === DIALOGS.BRAND_DELETE_CAMPAIGN) {
    return (
      <DeleteCampaignDialog
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }

  if (dialog === DIALOGS.DELIVERABLES_COST_BREAKDOWN) {
    return (
      <DeliverablesCostBreakdownDialog
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }

  if (dialog === DIALOGS.CAMPAIGN_INSIGHTS) {
    return (
      <CampaignInsightsDialog
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }

  if (dialog === DIALOGS.CREATE_CUSTOM_LIST) {
    return (
      <CreateCustomListDialog
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }

  if (dialog === DIALOGS.ADD_INF_TO_CUSTOM_LIST) {
    return (
      <AddInfToCustomListDialog
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }

  if (dialog === DIALOGS.QUESTIONAIRE) {
    return (
      <QuestionnaireDialog
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }

  if (dialog === DIALOGS.BRAND_EDIT_PROFILE) {
    return (
      <EditProfileDialog {...dialogProps} open={!!dialog} close={clearDialog} />
    );
  }

  if (dialog === DIALOGS.BRAND_EDIT_ADDRESS) {
    return (
      <EditAddressDialog {...dialogProps} open={!!dialog} close={clearDialog} />
    );
  }

  if (dialog === DIALOGS.BRAND_EDIT_PROFILE_CATEGORIES) {
    return (
      <EditProfileCategories
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }
  if (dialog === DIALOGS.BRAND_EDIT_PROFILE_PREFERENCES) {
    return (
      <EditProfilePreferences
        {...dialogProps}
        open={!!dialog}
        close={clearDialog}
      />
    );
  }
  if (dialog === DIALOGS.BRAND_EDIT_GST_DOC) {
    return (
      <EditProfileGstDoc {...dialogProps} open={!!dialog} close={clearDialog} />
    );
  }

  if (dialog === DIALOGS.BRAND_EDIT_PAN_DOC) {
    return (
      <EditProfilePanDoc {...dialogProps} open={!!dialog} close={clearDialog} />
    );
  }

  return null;
}

export default DialogSwicher;
