import React, { useState } from 'react';
import styles from 'assets/scss/pages/profile-dashboard.module.scss';
import { activeSubs, addSubs, renewSubs } from 'constants/images';
import clsx from 'clsx';
import { Dialog } from '@headlessui/react';

const Subscription = () => {
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);

  const toggleUpgradeDialog = () => {
    setShowUpgradeDialog((prev) => !prev);
  };

  return (
    <>
      <div className={styles.subscriptionBoxContainer}>
        <div className="subscriptionBox">
          <h2 className={styles.subscriptionTxt}>Active Subscription</h2>
          {/* TODO: need to do UI fix, make as per design  */}
          <div className={styles.upgradePlanContent}>
            <div className={styles.planContainer}>
              <img className={styles.plnnrImg} src={activeSubs} alt="" />
              <div className="subsTextContainer">
                <div className={styles.block1}>
                  <h2 className={styles.subHeadingText}>
                    Radio City Ninja Pro
                  </h2>
                  <span className={styles.plannerTxt}>Active</span>
                </div>
                <div className={styles.block2}>
                  <p className={styles.invoiceTxt}>Billing quaterly</p>
                  <span className={styles.divideLine} />
                  <p className={styles.invoiceTxt}>
                    Next invoice on may 13 for ₹1499
                  </p>
                </div>
              </div>
            </div>
            <div className="block3">
              <button
                type="button"
                className={styles.upgradeBtn}
                onClick={toggleUpgradeDialog}
              >
                Upgrade Plan
              </button>
            </div>
          </div>
        </div>
        <div className="subscriptionBox">
          <h2 className={styles.subscriptionTxt}>No Subscription</h2>
          <div className={styles.upgradePlanContent}>
            <div className={styles.planContainer}>
              <img className={styles.plnnrImg} src={addSubs} alt="" />
              <div className="subsTextContainer">
                <div className={styles.block1}>
                  <h2 className={styles.subHeadingText}>
                    Add Subscription Plan
                  </h2>
                </div>
                <div className={styles.block2}>
                  <p className={styles.invoiceTxt}>Plan starting at ₹1499</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="subscriptionBox">
          <h2 className={styles.subscriptionTxt}>Subscription Expired</h2>
          <div className={styles.upgradePlanContent}>
            <div className={styles.planContainer}>
              <img className={styles.plnnrImg} src={renewSubs} alt="" />
              <div className="subsTextContainer">
                <div className={styles.block1}>
                  <h2 className={styles.subHeadingText}>Renew/Upgrade Plan</h2>
                  <span
                    className={clsx(styles.plannerTxt, styles.expiredPlanTxt)}
                  >
                    Expired
                  </span>
                </div>
                <div className={styles.block2}>
                  <p className={styles.invoiceTxt}>Billing quaterly</p>
                  <span className={styles.divideLine} />
                  <p className={styles.invoiceTxt}>
                    Last invoice on May 13 for ₹1499
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.block3}>
              <button type="button" className={styles.renewBtn}>
                Renew
              </button>
              <button type="button" className={styles.upgradeBtn}>
                Upgrade Plan
              </button>
            </div>
          </div>
        </div>
        <div className="subscriptionBox" style={{ marginTop: '70px' }}>
          <h2 className={styles.subscriptionTxt}>Billing Details</h2>
          <div className={styles.billingTableContainer}>
            <table className={styles.billingTable}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Plan Type</th>
                  <th>Duration</th>
                  <th>Amount</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>08/07/2022</td>
                  <td>Beginner plan</td>
                  <td>Quaterly</td>
                  <td className={styles.amntText}>₹20,000</td>
                  <td className={styles.downloadedTxt}>Invoice 08 July 22</td>
                </tr>
                <tr>
                  <td>08/04/2022</td>
                  <td>Beginner plan</td>
                  <td>Quaterly</td>
                  <td className={styles.amntText}>₹60,000</td>
                  <td className={styles.downloadedTxt}>Invoice 08 Arpil 22</td>
                </tr>
                <tr>
                  <td>08/01/2022</td>
                  <td>Beginner plan</td>
                  <td>Quaterly</td>
                  <td className={styles.amntText}>₹25,000</td>
                  <td className={styles.downloadedTxt}>Invoice 08 Jan 22</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* =========== upgrade Plan ================= */}
      <Dialog open={showUpgradeDialog} onClose={toggleUpgradeDialog}>
        <Dialog.Panel>
          <Dialog.Title>Upgrade Plan</Dialog.Title>
          <div className={styles.containerHoverlay}>
            <div className={styles.customInner}>
              <div className={styles.container_content}>
                <div>
                  <h2 className={styles.popupTitle}>Upgrade Plan</h2>
                </div>
                <div className={styles.comparePlan}>
                  <div className={styles.upgradeInputField}>
                    <label className={styles.inputTitle}>Current Plan</label>
                    <input className={styles.inputContainer} type="text" />
                  </div>
                  <div className={styles.upgradeInputField}>
                    <label className={styles.inputTitle}>Upgrade Plan</label>
                    <input className={styles.inputContainer} type="text" />
                  </div>
                </div>
                <div className={styles.upgradeBlock}>
                  <div className={styles.blockLabel1}>
                    <div className="mergeBlock">
                      <div className="subBlock1">
                        <p
                          className={styles.detailTxt}
                          style={{ textAlign: 'left', marginTop: '0px' }}
                        >
                          ₹1499
                          <span className={styles.detailInnerTxt}>
                            /Quaterly
                          </span>
                        </p>
                      </div>
                      <div className={styles.subBlock2}>
                        <ul className={styles.planListContainer}>
                          <li className={styles.planInnrList}>
                            Unlimited Post
                          </li>
                          <li className={styles.planInnrList}>
                            We’ll get you onboarded
                          </li>
                          <li className={styles.planInnrList}>Live stream</li>
                          <li className={styles.planInnrList}>
                            Access to message in one inbox
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={styles.blockLabel1}>
                    <div className="mergeBlock">
                      <div className="subBlock1">
                        <p
                          className={styles.detailTxt}
                          style={{ textAlign: 'left', marginTop: '0px' }}
                        >
                          ₹2499
                          <span className={styles.detailInnerTxt}>
                            /Quaterly
                          </span>
                        </p>
                      </div>
                      <div className={styles.subBlock2}>
                        <ul className={styles.planListContainer}>
                          <li className={styles.planInnrList}>
                            Unlimited Post
                          </li>
                          <li className={styles.planInnrList}>
                            We’ll get you onboarded
                          </li>
                          <li className={styles.planInnrList}>Live stream</li>
                          <li className={styles.planInnrList}>
                            Access to message in one inbox
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.updateBtns}>
                  <button
                    type="button"
                    className={styles.btn1}
                    onClick={toggleUpgradeDialog}
                  >
                    Cancel
                  </button>
                  <button type="button" className={styles.btn2}>
                    Upgrade
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  );
};

export default Subscription;
