import React from 'react';
import PropTypes from 'prop-types';
import AnalyticsCard from 'components/brand-dashboard/campaigns/AnalyticsCard';
import PostingBarChart from 'components/brand-dashboard/campaigns/PostingBarChart';
import { formatNumber } from 'utils';
import {
  engagemntRateImg,
  influencerLinedImg,
  postIcon,
  videoWatchedImg,
} from 'constants/images';
import VideoViewLineChart from 'components/brand-dashboard/campaigns/VideoViewLineChart';
import AudienceEngagementRatesPieChart from 'components/brand-dashboard/campaigns/AudienceEngagementRatesPieChart';
import InfPerformancePieChart from 'components/brand-dashboard/campaigns/InfPerformancePieChart';
import CampaignPerformancePieChart from 'components/brand-dashboard/campaigns/CampaignPerformancePieChart';
import HashTag from 'pages/influencer/dashboard/campaigns/campaign-details/hashtag/HashTag';
import { CmapaignPost } from 'components/campaign-report';

const varients = {
  BRAND: 'brand',
  INF: 'influencer',
};

const CampaignReport = ({
  insights,
  varient = varients.BRAND,
  postingsData,
  audienceEngagementRateData,
  campaignPerformanceData,
  influencerPerformanceData,
  posts,
  isExporting,
}) => (
  <div
    className="exportContainer"
    id={isExporting ? 'section-to-print' : undefined}
  >
    <div className="pageBreak">
      <div className="metricCardsWrapper">
        {varient === varients.BRAND ? (
          <AnalyticsCard
            image={influencerLinedImg}
            title="Total Influencers"
            value={formatNumber(insights.totalInfluencers)}
          />
        ) : null}
        <AnalyticsCard
          image={postIcon}
          title="Total Campaign Posting"
          value={formatNumber(insights.totalPosts)}
        />
        <AnalyticsCard
          image={videoWatchedImg}
          title="Videos Watched"
          value={formatNumber(insights.totalViews)}
        />
        <AnalyticsCard
          image={engagemntRateImg}
          title="Engagement Rate"
          value={`${formatNumber(insights.engagementRate)}`}
        />
      </div>
      <PostingBarChart
        data={postingsData.data}
        filter={postingsData.filter}
        isExporting={isExporting}
        changeFilter={postingsData.changeFilter}
      />
      <div className="insightItems">
        <VideoViewLineChart data={postingsData.data} insights={insights} />
        <div className="analyticsSection">
          <div className="header">
            <h2 className="title">tags</h2>
          </div>
          <HashTag hashtags={postingsData.hashtags} />
        </div>
      </div>
    </div>

    <div className="pageBreak">
      <AudienceEngagementRatesPieChart
        trackingData={audienceEngagementRateData}
      />

      <div className="insightItems">
        <InfPerformancePieChart trackingData={influencerPerformanceData} />
        <CampaignPerformancePieChart cpm={campaignPerformanceData} />
      </div>
    </div>

    <div className="pageBreak">
      <div className="analyticsSection">
        <div className="header">
          <h2 className="title">post</h2>
        </div>

        <div className="contentWrapper">
          <div className="posts">
            {posts?.map((postDetails) => (
              <CmapaignPost
                postDetails={postDetails}
                key={postDetails?.media_id}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

CampaignReport.propTypes = {
  isExporting: PropTypes.bool,
  insights: PropTypes.object,
  varient: PropTypes.oneOf(['brand', 'influencer']),
  postingsData: PropTypes.object,
  audienceEngagementRateData: PropTypes.array,
  campaignPerformanceData: PropTypes.array,
  influencerPerformanceData: PropTypes.array,
  posts: PropTypes.array,
};

export default CampaignReport;
