import PropTypes from 'prop-types';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { Transition } from '@headlessui/react';
import React, { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDialog } from 'stores/dialog';
import { useCreateCampaign } from 'stores/create-campaign';
import routes from 'constants/routes';
import styles from 'assets/scss/pages/search.module.scss';
import commonStyles from 'assets/scss/common.module.scss';
import { compareIcon, copyText, whiteAddIcon } from 'constants/images';
import { DIALOGS } from 'constants/config/dialog-names';

const ActionBar = ({
  platform,
  selectedRows,
  resetRowSelection,
  showCustomListButton = true,
  show = false,
}) => {
  const { reset: resetCampaignCreation, setFormData: setInfsForCampaign } =
    useCreateCampaign();
  const setDialog = useDialog((state) => state.setDialog);

  const navigate = useNavigate();
  const selectedInfIds = useMemo(
    () => selectedRows?.map(({ original }) => original?.id),
    [selectedRows]
  );

  const isNotRegisterdInfSelected = useMemo(
    () =>
      Boolean(selectedRows?.find(({ original }) => !original?.is_registered)),
    [selectedRows]
  );

  const onCompare = () => {
    navigate(
      `${
        routes.BRAND_COMPARE_INFS
      }?ids=${selectedInfIds?.toString()}&platform=${platform}`
    );
  };

  const onCreateCampaign = () => {
    if (isNotRegisterdInfSelected) {
      toast.warning('Only verified influencers can be added in a campaign.', {
        position: 'top-center',
      });
      return;
    }
    resetCampaignCreation();
    const influencerDeliverables = [];
    selectedRows?.forEach(({ original: influencer }) => {
      const platforms = [];

      if (influencer.is_instagram) {
        platforms.push('instagram');
      }
      if (influencer.is_facebook) {
        platforms.push('facebook');
      }
      if (influencer.is_twitter) {
        platforms.push('twitter');
      }
      if (influencer.is_youtube) {
        platforms.push('youtube');
      }
      influencerDeliverables.push({
        platforms,
        influencerId: influencer.id,
        influencerProfile: influencer.profile_image,
        influencerName: influencer.full_name,
        deliverables: [],
      });
    });
    setInfsForCampaign({ influencers: influencerDeliverables });
    setDialog(DIALOGS.CREATE_CAMPAIGN);
  };

  const onAddInfToExistingCampaign = () => {
    if (isNotRegisterdInfSelected) {
      toast.warning('Only verified influencers can be added in a campaign.', {
        position: 'top-center',
      });
      return;
    }
    setDialog(DIALOGS.ADD_INF_TO_EXISTING_CAMPAIGN, {
      influencers: selectedRows?.map(({ original }) => original),
    });
  };

  return (
    <Transition
      as={Fragment}
      show={show}
      enter={commonStyles.enter}
      enterFrom={commonStyles.hidden}
      enterTo={commonStyles.view}
      leave={commonStyles.leave}
      leaveFrom={commonStyles.view}
      leaveTo={commonStyles.hidden}
    >
      <div className={styles.subscriptionBox}>
        <div className={styles.selectBoxContrller}>
          <div className={styles.counter}>
            <span className={styles.count}>{selectedRows.length}</span>
            <span className={styles.countTxt}>Influencer selected</span>
          </div>
          <div className={styles.influncrControl}>
            <button
              type="button"
              className={styles.deselectBtn}
              onClick={resetRowSelection}
            >
              Deselect All
            </button>
            <button
              type="button"
              className={styles.cntrllerBtns}
              onClick={onCreateCampaign}
            >
              <img src={whiteAddIcon} alt="" /> Add To New Campaign
            </button>
            <button
              type="button"
              className={styles.cntrllerBtns}
              onClick={onAddInfToExistingCampaign}
            >
              <img src={whiteAddIcon} alt="" /> Add To Existing Campaigns
            </button>
            <button
              type="button"
              className={clsx(styles.cntrllerBtns, styles.compareCntrlrBtns)}
              onClick={onCompare}
              disabled={selectedInfIds?.length < 2}
            >
              <img src={compareIcon} alt="" /> Compare
            </button>
            {showCustomListButton ? (
              <button
                type="button"
                className={styles.cntrllerBtns}
                onClick={() =>
                  setDialog(DIALOGS.ADD_INF_TO_CUSTOM_LIST, {
                    influencerIds: selectedInfIds,
                  })
                }
              >
                <img src={copyText} alt="" /> Custom List
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </Transition>
  );
};

ActionBar.propTypes = {
  selectedRows: PropTypes.array,
  resetRowSelection: PropTypes.func,
  showCustomListButton: PropTypes.bool,
  show: PropTypes.bool,
  platform: PropTypes.string,
};

export default React.memo(ActionBar);
