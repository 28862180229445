import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { differenceInCalendarDays, format } from 'date-fns';
import { useSpinDelay } from 'spin-delay';
import { Link, NavLink, Outlet, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getCampaignDetails, downloadCampaign } from 'api/influencer/campaign';
import { LoaderWithWrapper } from 'components/common/Loader';
import { Avatar, AvatarGroup } from 'components/common/Avatar';
import {
  CAMPAIGN_STATUS,
  INF_CAMPAIGN_STATUS_CHIPS,
} from 'constants/config/campaign';
import { PLATFORM_ICONS } from 'constants/config/platformIcons';
import routes from 'constants/routes';
import styles from 'assets/scss/pages/campaign.module.scss';
import style from 'assets/scss/pages/campaign-quotation.module.scss';

import {
  transparentBg,
  locationDownIcon,
  whiteDownloadIcon,
} from 'constants/images';
import { saveFile } from 'utils';

const NavItem = ({ children, ...props }) => (
  <NavLink
    className={({ isActive }) =>
      clsx({
        [styles.influencerCateBtn]: true,
        [styles.whiteActiveBtn]: isActive,
      })
    }
    {...props}
  >
    {children}
  </NavLink>
);

NavItem.propTypes = {
  children: PropTypes.node,
};

function CampaignDetails() {
  const { id: campaignId } = useParams();

  const downloadContractMutation = useMutation({
    mutationFn: ({ campaignId }) => downloadCampaign({ campaignId }),
    onSuccess: (res) => {
      saveFile(
        res,
        'application/pdf',
        `campaign_quote${format(new Date(), 'dd_MMM_yyyy_hh_mm_aaa')}`
      );
    },
  });

  const { data: campaign, isLoading } = useQuery({
    queryKey: ['influencer-campaign-details', campaignId],
    queryFn: () => getCampaignDetails({ campaignId }),
    select: (res) => res?.data?.campaign,
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  const {
    campaign_name: campaignName,
    brand_name: brandName,
    campaign_description: description,
    target_platforms: platforms,
    campaign_start_date: startDateTime,
    campaign_end_date: endDateTime,
    regions,
    target_genders: genders,
    ages: ageGroups,
    social_hashtags: tags,
    campaign_status: campaignStatus,
    campaign_influencers: campaignInfluencers,
  } = campaign || {};

  const regionString =
    regions?.map((region) => region.region_name).join(', ') || '-';
  const genderString =
    genders?.length === 3 ? 'All' : genders?.join(', ') || '-';
  const ageGroupString =
    ageGroups
      ?.map(({ age_from: from, age_to: to }) => `${from}-${to}`)
      .join(', ') || '-';
  const tagString = tags?.map((tag) => tag.hashtag_name).join(', ') || '-';
  const campaignDurationInDays =
    startDateTime && endDateTime
      ? differenceInCalendarDays(new Date(endDateTime), new Date(startDateTime))
      : '-';
  const descriptionLines = description?.split('\n');

  const isContractSigned = Boolean(campaignInfluencers?.[0]?.signed_url);

  if (isLoading || delayedIsLoading) {
    return <LoaderWithWrapper show={delayedIsLoading} />;
  }

  return (
    <>
      <div className={styles.dashboardTop}>
        <img className={styles.transBg} src={transparentBg} alt="" />
        <div className={styles.campaignContainer}>
          <div className={styles.campignBreadcrumbs}>
            <Link to={routes.INF_CAMPAIGNS} className={styles.navTxt}>
              Campaings
            </Link>
            <img className={styles.rightArrow} src={locationDownIcon} alt="" />
            <span className={styles.navSecndTxt}>{campaignName}</span>
          </div>
          <div className={styles.campaignDownloadContainer}>
            <div className={styles.campaignHeading}>
              <h2 className={styles.campignTxt}>{campaignName}</h2>
              {INF_CAMPAIGN_STATUS_CHIPS[campaignStatus]}
            </div>
            <div className={style.quotationHeader}>
              {isContractSigned ? (
                <button
                  className={style.downloadQuotBtn}
                  type="button"
                  onClick={() =>
                    downloadContractMutation.mutate({ campaignId })
                  }
                  disabled={downloadContractMutation.isLoading}
                >
                  <img src={whiteDownloadIcon} alt="" />
                  Download Contract
                </button>
              ) : null}
            </div>
          </div>
          <div className="campaignDiv">
            <ul className={styles.campaignList}>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Brand</h2>
                <p className={styles.detailTxt}>{brandName}</p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Platform</h2>
                {platforms ? (
                  <AvatarGroup>
                    {platforms.map((platformName) => (
                      <Avatar
                        src={PLATFORM_ICONS[platformName]}
                        alt=""
                        key={platformName}
                        size="small"
                      />
                    ))}
                  </AvatarGroup>
                ) : (
                  '-'
                )}
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Duration (Days)</h2>
                <p className={styles.detailInnrTxt}>{campaignDurationInDays}</p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Region</h2>
                <p
                  className={clsx(styles.detailInnrTxt, 'line-clamp-2')}
                  title={regionString}
                >
                  {regionString}
                </p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Gender</h2>
                <p className={styles.detailInnrTxt}>{genderString}</p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Age Group (Years)</h2>
                <p className={styles.detailInnrTxt}>{ageGroupString}</p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Tags</h2>
                <p className={styles.detailInnrTxt}>{tagString}</p>
              </li>
            </ul>
            <br />
            <ul>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Description</h2>
                <p className={styles.detailInnrTxt}>
                  {descriptionLines?.map((line, index) => (
                    <Fragment key={index}>
                      <span>{line}</span>
                      <br />
                    </Fragment>
                  ))}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.categoryButtons}>
          <NavItem to="deliverables">Deliverables</NavItem>
          {campaignStatus === CAMPAIGN_STATUS.ACTIVE ||
          campaignStatus === CAMPAIGN_STATUS.COMPLETED ||
          campaignStatus === CAMPAIGN_STATUS.ARCHIVED ? (
            <NavItem to="analytics" end>
              Live Tracking
            </NavItem>
          ) : null}
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default CampaignDetails;
