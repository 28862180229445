import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getStateList } from 'api/brand/search';
import Input from 'components/common/edit-profile/Input';
import CustomSelect from 'components/common/edit-profile/CustomSelect';
import { Dialog } from 'components/common/dialog';
import ErrorMessage from 'components/common/form/ErrorMessage';
import { queryClient } from 'App';
import { addAddress, editAddress, getProfile } from 'api/brand/profile';
import { ADDRESS_FORM_SCHEMA } from 'constants/schema';
import { ADDRESS_TYPES } from 'constants/config';
import styles from 'assets/scss/pages/popup.module.scss';

const defaultValues = {
  addressLine1: '',
  addressLine2: '',
  landmark: '',
  pincode: '',
  state: '',
  city: '',
  addressType: '',
  addressId: '',
};

const EditAddress = ({ open, close, addressId }) => {
  const { data } = useQuery({
    queryKey: ['brand-profile'],
    queryFn: () => getProfile(),
    enabled: !!addressId,
  });
  const { data: states } = useQuery({
    queryKey: ['brand-states'],
    queryFn: () => getStateList(),
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ADDRESS_FORM_SCHEMA),
    defaultValues,
  });

  const editAddressMutation = useMutation({
    mutationFn: ({
      addressLine1,
      addressLine2,
      landmark,
      pincode,
      state,
      city,
      addressType,
      addressId,
    }) => {
      const payload = {
        address_line_1: addressLine1,
        address_line_2: addressLine2 || undefined,
        pincode,
        state,
        city,
        landmark: landmark || undefined,
        address_type: addressType,
        address_id: addressId || undefined,
      };

      return addressId ? editAddress(payload) : addAddress(payload);
    },
    onSuccess: (data) => {
      toast.success(data?.message, { position: 'top-center' });
      queryClient.invalidateQueries({ queryKey: ['brand-profile'] });
      reset();
      close();
    },
  });

  useEffect(() => {
    if (data?.data && addressId) {
      const address =
        data.data.getProfile?.addresses?.find(({ id }) => id === addressId) ||
        {};
      reset({
        addressLine1: address.address_line_1 || '',
        addressLine2: address.address_line_2 || '',
        landmark: address.landmark || '',
        pincode: address.pincode || '',
        state: address.state || '',
        city: address.city || '',
        addressType: address.address_type || '',
        addressId: address.id || '',
      });
    }
  }, [data, reset, addressId]);

  const selectedState = watch('state');

  const filteredCities = useMemo(
    () =>
      states
        ?.reduce(
          (accumulator, state) =>
            selectedState === state?.name
              ? [...accumulator, ...state.cities]
              : accumulator,
          []
        )
        .sort()
        .map((city) => ({ name: city })),
    [states, selectedState]
  );

  return (
    <Dialog
      title="Edit Profile"
      open={open}
      close={close}
      size="591px"
      fullWidth
    >
      <form onSubmit={handleSubmit(editAddressMutation.mutate)}>
        <div className={styles.editFormWrapper}>
          <div className={styles.addressFormGroup}>
            <Controller
              render={({ field }) => (
                <CustomSelect
                  onChange={field.onChange}
                  value={field.value}
                  options={ADDRESS_TYPES}
                  placeholder="Address Type"
                  ref={field.ref}
                />
              )}
              control={control}
              name="addressType"
            />
            <ErrorMessage error={errors?.addressType} />
          </div>
          <Input
            maxLength="75"
            as="textarea"
            placeholder="Apartment, Suite, Unit, Floor"
            wrapperClass={styles.addressFormGroup}
            error={errors?.addressLine1}
            {...register('addressLine1')}
          />
          <Input
            maxLength="34"
            placeholder="Landmark"
            wrapperClass={styles.addressFormGroup}
            error={errors?.landmark}
            {...register('landmark')}
          />
          <div className={styles.stateDiv}>
            <div>
              <Input
                placeholder="Pincode"
                wrapperClass={styles.addressFormGroup}
                error={errors?.pincode}
                {...register('pincode')}
                maxLength="6"
              />
            </div>
            <div className={styles.addressFormGroup}>
              <Controller
                render={({ field }) => (
                  <CustomSelect
                    onChange={(...params) => {
                      setValue('city', '');
                      field.onChange(...params);
                    }}
                    value={field.value}
                    options={states}
                    labelSelector="name"
                    valueSelector="name"
                    placeholder="State"
                    ref={field.ref}
                  />
                )}
                control={control}
                name="state"
              />
              <ErrorMessage error={errors?.state} />
            </div>
          </div>
          <div className={styles.formGroup}>
            <Controller
              render={({ field }) => (
                <CustomSelect
                  onChange={field.onChange}
                  value={field.value}
                  options={filteredCities}
                  labelSelector="name"
                  valueSelector="name"
                  placeholder="City"
                  ref={field.ref}
                />
              )}
              control={control}
              name="city"
            />
            <ErrorMessage error={errors?.city} />
          </div>
        </div>
        <div className={styles.editProfileContainer}>
          <div
            className={clsx(styles.campaignBtns, styles.nxtWrap)}
            style={{ marginTop: '16px' }}
          >
            <button type="button" className={styles.prevBtn} onClick={close}>
              Back
            </button>
            <button
              type="submit"
              style={{ width: '30%' }}
              className={styles.updateBtn}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

EditAddress.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  addressId: PropTypes.string,
};

export default EditAddress;
