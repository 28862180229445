import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Listbox } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { formatNumber } from 'utils';
import { ReactComponent as DownIcon } from 'assets/images/arrow-down.svg';
import { ReactComponent as PrevIcon } from 'assets/images/arrow-back.svg';
import { ReactComponent as NextIcon } from 'assets/images/arrow-forward.svg';
import styles from './Table.module.scss';

function PaginationWithPageInput({
  currentPage,
  changePage,
  count,
  rowsPerPage,
  changeRowsPerPage,
  rowsPerPageOptions = [10, 20, 30],
}) {
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(count / rowsPerPage));
  }, [count, rowsPerPage]);

  const handlePageChange = (e) => {
    const newValue = e.target.valueAsNumber;
    changePage(newValue);
  };

  const handleInputLeave = (e) => {
    const inputValue = e.target.valueAsNumber;
    if (!inputValue) {
      changePage(1);
    }
  };

  return (
    <div className={styles.footer}>
      {changeRowsPerPage ? (
        <div className={styles.itemCountSelection}>
          <p>Items per page</p>
          <Listbox value={rowsPerPage} onChange={changeRowsPerPage}>
            <Float placement="top-start" offset={4} portal>
              <Listbox.Button className={styles.perPageSelectionButton}>
                {rowsPerPage}
                <DownIcon />
              </Listbox.Button>
              <Listbox.Options className={styles.selectDropdown}>
                {rowsPerPageOptions.map((value) => (
                  <Listbox.Option
                    key={value}
                    value={value}
                    className={({ active }) =>
                      clsx({ [styles.option]: true, [styles.active]: active })
                    }
                  >
                    {value}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Float>
          </Listbox>
        </div>
      ) : (
        <span />
      )}
      <div className={styles.paginationWrapper}>
        <p>
          Total Pages:{' '}
          {formatNumber(pageCount, {
            maximumFractionDigits: 0,
            notation: 'standard',
          })}
        </p>
        <button
          type="button"
          className={styles.paginationButton}
          onClick={() => changePage(currentPage - 1)}
          disabled={currentPage <= 1}
        >
          <PrevIcon />
        </button>
        <input
          type="number"
          className={styles.paginationInput}
          value={currentPage}
          onChange={handlePageChange}
          onBlur={handleInputLeave}
        />
        <button
          type="button"
          className={styles.paginationButton}
          onClick={() => changePage(currentPage + 1)}
          disabled={pageCount <= currentPage}
        >
          <NextIcon />
        </button>
      </div>
    </div>
  );
}

PaginationWithPageInput.propTypes = {
  currentPage: PropTypes.number,
  changePage: PropTypes.func,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  changeRowsPerPage: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

export default PaginationWithPageInput;
