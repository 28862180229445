import { useCallback, useState } from 'react';

const useFilterProps = ({
  rowsPerPage = 10,
  defaultFilters = {},
  defaultSortBy,
} = {}) => {
  const [limit, setLimit] = useState(rowsPerPage);
  const [page, setPage] = useState(1);

  const [sortBy, setSortBy] = useState(defaultSortBy);

  const [search, setSearch] = useState('');

  const [filter, setFilter] = useState(defaultFilters);

  const changePage = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const changeRowsPerPage = useCallback((value) => {
    setLimit(parseInt(value, 10));
    setPage(1);
  }, []);

  const changeSortBy = useCallback((property) => {
    setPage(1);
    if (property) {
      setSortBy(property);
    } else {
      setSortBy(null);
    }
  }, []);

  const changeSearch = useCallback((newSearchValue) => {
    setSearch(newSearchValue);
    setPage(1);
  }, []);

  const changeFilter = useCallback((key, value) => {
    setPage(1);
    setFilter((prev) => ({ ...prev, [key]: value }));
  }, []);

  return {
    page,
    rowsPerPage: limit,
    changePage,
    changeRowsPerPage,
    sortBy,
    changeSortBy,
    search,
    changeSearch,
    filter,
    changeFilter,
  };
};

export default useFilterProps;
