import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tab } from '@headlessui/react';
import { Dialog } from 'components/common/dialog';
import HistoricalVsActualBarChart from 'components/brand-dashboard/campaigns/HistoricalVsActualBarChart';
import AudienceLikeDislikeSentimentsPieChart from 'components/brand-dashboard/campaigns/AudienceLikeDislikeSentimentsPieChart';
import AudienceCommentsSentimentsPieChart from 'components/brand-dashboard/campaigns/AudienceCommentsSentimentsPieChart';
import styles from 'assets/scss/pages/campaign.module.scss';
import {
  transparentBg,
  trendingTagsImg,
  coloredCrossIcon,
  nishaMadhulikaImg,
  recipeImage,
} from 'constants/images';

const CampaignInsightsDialog = ({ open, close }) => (
  <Dialog open={open} close={close} size="lg" fullWidth fullHeight>
    <Tab.Group className="UserPopup" as="div">
      <div className={styles.dashboardTop}>
        <img className={styles.transBg} src={transparentBg} alt="" />
        <div className={styles.campaignContainer} style={{ padding: '25px' }}>
          <div className={styles.popupHeader}>
            <div className={styles.campaignHeading} style={{ margin: '0px' }}>
              <img src={nishaMadhulikaImg} alt="" />
              <h2
                className={styles.campignTxt}
                style={{ fontSize: '1.125rem' }}
              >
                Nisha Madhulika
              </h2>
            </div>
            <div className={styles.crossImg}>
              <button type="button" onClick={close}>
                <img src={coloredCrossIcon} alt="" />
              </button>
            </div>
          </div>
          <div className="campaignDiv">
            <ul className={styles.campaignList}>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>RC Score</h2>
                <p className={styles.detailTxt}>9.50</p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Followers</h2>
                <p className={styles.detailInnrTxt}>5.1m</p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Avg Likes</h2>
                <p className={styles.detailInnrTxt}>119.8k</p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Avg Views</h2>
                <p className={styles.detailInnrTxt}>610k</p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>ER</h2>
                <p className={styles.detailTxt}>6.02%</p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>CPV</h2>
                <p className={styles.detailInnrTxt}>₹2.3</p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>CPE</h2>
                <p className={styles.detailInnrTxt}>₹8.3</p>
              </li>
            </ul>
          </div>
        </div>
        <Tab.List className={styles.categoryButtons} as="div">
          <Tab
            as="a"
            className={({ selected }) =>
              clsx({
                [styles.influencerCateBtn]: true,
                [styles.whiteActiveBtn]: selected,
              })
            }
          >
            Insight
          </Tab>
          <Tab
            as="a"
            className={({ selected }) =>
              clsx({
                [styles.influencerCateBtn]: true,
                [styles.whiteActiveBtn]: selected,
              })
            }
          >
            Content Posted
          </Tab>
        </Tab.List>
      </div>
      <Tab.Panels>
        <Tab.Panel as="div" className={styles.insigtInnerpopup}>
          <div
            className={styles.postingInsightBox}
            style={{ maxWidth: '521px', margin: '0px' }}
          >
            <div className={styles.postHeader}>
              <div>
                <h2 className={styles.insightHeading}>trending tags</h2>
              </div>
              <div>
                <ul className={styles.TimeBox}>
                  <li className={clsx(styles.yearList, styles.activeYearList)}>
                    Hashtags
                  </li>
                  <li className={styles.yearList}>Keyworkds</li>
                </ul>
              </div>
            </div>
            <div className={styles.postBody}>
              <img className={styles.graphImg} src={trendingTagsImg} alt="" />
            </div>
          </div>
          <HistoricalVsActualBarChart />
          <AudienceLikeDislikeSentimentsPieChart />
          <AudienceCommentsSentimentsPieChart />
        </Tab.Panel>
        <Tab.Panel as="div" className={styles.createdPostPopup}>
          <div className={styles.recipePostContainer}>
            <div className="recipeImg">
              <img src={recipeImage} alt="" />
            </div>
            <div className="campaignDiv">
              <div className={styles.innerDiv}>
                <div className="recipeNameContainer">
                  <h3 className={styles.reciNameTxt}>
                    SeeSomethingNew with Nisha Madhulika
                  </h3>
                  <p className={styles.timelineTxt}>1 year ago</p>
                </div>
                <ul className={styles.campaignList} style={{ margin: '0px' }}>
                  <li className={styles.campaignInnrList}>
                    <h2 className={styles.headingTxt}>Followers</h2>
                    <p className={styles.detailInnrTxt}>5.1m</p>
                  </li>
                  <li className={styles.campaignInnrList}>
                    {' '}
                    <h2 className={styles.headingTxt}> Likes</h2>
                    <p className={styles.detailInnrTxt}>119.8k</p>
                  </li>
                  <li className={styles.campaignInnrList}>
                    <h2 className={styles.headingTxt}>Views</h2>
                    <p className={styles.detailInnrTxt}>610k</p>
                  </li>
                  <li className={styles.campaignInnrList}>
                    <h2 className={styles.headingTxt}>ER</h2>
                    <p className={styles.detailTxt}>6.02%</p>
                  </li>
                  <li className={styles.campaignInnrList}>
                    <h2 className={styles.headingTxt}>CPV</h2>
                    <p className={styles.detailInnrTxt}>₹2.3</p>
                  </li>
                  <li className={styles.campaignInnrList}>
                    <h2 className={styles.headingTxt}>CPE</h2>
                    <p className={styles.detailInnrTxt}>₹8.3</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.recipePostContainer}>
            <div className="recipeImg">
              <img src={recipeImage} alt="" />
            </div>
            <div className="campaignDiv">
              <div className={styles.innerDiv}>
                <div className="recipeNameContainer">
                  <h3 className={styles.reciNameTxt}>
                    SeeSomethingNew with Nisha Madhulika
                  </h3>
                  <p className={styles.timelineTxt}>1 year ago</p>
                </div>
                <ul className={styles.campaignList} style={{ margin: '0px' }}>
                  <li className={styles.campaignInnrList}>
                    <h2 className={styles.headingTxt}>Followers</h2>
                    <p className={styles.detailInnrTxt}>5.1m</p>
                  </li>
                  <li className={styles.campaignInnrList}>
                    {' '}
                    <h2 className={styles.headingTxt}> Likes</h2>
                    <p className={styles.detailInnrTxt}>119.8k</p>
                  </li>
                  <li className={styles.campaignInnrList}>
                    <h2 className={styles.headingTxt}>Views</h2>
                    <p className={styles.detailInnrTxt}>610k</p>
                  </li>
                  <li className={styles.campaignInnrList}>
                    <h2 className={styles.headingTxt}>ER</h2>
                    <p className={styles.detailTxt}>6.02%</p>
                  </li>
                  <li className={styles.campaignInnrList}>
                    <h2 className={styles.headingTxt}>CPV</h2>
                    <p className={styles.detailInnrTxt}>₹2.3</p>
                  </li>
                  <li className={styles.campaignInnrList}>
                    <h2 className={styles.headingTxt}>CPE</h2>
                    <p className={styles.detailInnrTxt}>₹8.3</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  </Dialog>
);

CampaignInsightsDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default CampaignInsightsDialog;
