import React, { useMemo, useState } from 'react';
import { Element } from 'react-scroll';
import { useQuery } from '@tanstack/react-query';
import {
  getFollowersGrowth,
  getProfile,
  getRank,
} from 'api/influencer/profile';
import {
  BrandsSection,
  ContentSection,
  EngagementCard,
  InfContentPerformanceAndBenchmark,
  InfFollowerGrowth,
  InfProfileDetailsNavbar,
  OverviewCard,
} from 'components/common/inf-profile';
import { formatNumber } from 'utils';
import {
  brandIcon,
  contentIcon,
  growthGrayIcon,
  heartGrayIcon,
  platformIcon,
  twitterSvg,
} from 'constants/images';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import clsx from 'clsx';
import { NoData } from 'components/common/NoData';

const Twitter = () => {
  const [follersGrowthFilter, setFollersGrowthFilter] = useState(90);

  const { data: influencer } = useQuery({
    queryKey: ['influencer-profile'],
    queryFn: () => getProfile(),
  });

  const { data: infleucnerRank } = useQuery({
    queryKey: ['influencer-rank'],
    queryFn: () => getRank(),
  });

  const { data: followerGrowthData, isLoading: isLoadingFollowersGrowth } =
    useQuery({
      queryKey: ['influencer-follower-growth', 'twitter', follersGrowthFilter],
      queryFn: () => {
        const params = {
          follower_growth_filter: follersGrowthFilter,
          platform: 'twitter',
        };
        return getFollowersGrowth(params);
      },
    });

  const { twitterProfile } = influencer || {};
  const { brandPosts, recentPosts, topPosts, hashtags, brands } =
    twitterProfile || {};

  const contentPerformance = useMemo(
    () =>
      recentPosts?.map((tweet) => ({
        tweetId: tweet.postId,
        impressions: Number(tweet.impressionCount),
      })),
    [recentPosts]
  );

  return (
    <section style={{ position: 'relative' }}>
      <InfProfileDetailsNavbar
        config={[
          {
            title: 'Overview',
            to: 'overview',
            icon: platformIcon,
            offset: -200,
          },
          { title: 'Engagement', to: 'engagement', icon: heartGrayIcon },
          // { title: 'Audience', to: 'audience', icon: usersGrayIcon },
          { title: 'Growth', to: 'growth', icon: growthGrayIcon },
          { title: 'Content', to: 'content', icon: contentIcon },
          { title: 'Brands', to: 'brands', icon: brandIcon },
        ]}
      />
      <Element className={styles.navContentSection} name="overview">
        <OverviewCard
          title="Followers"
          value={formatNumber(twitterProfile?.followers)}
        />
        {/* <OverviewCard
          title="Total Tweets"
          value={formatNumber(twitterProfile?.mediaCount)}
        /> */}
        <OverviewCard
          title="Estimated reach"
          value={formatNumber(followerGrowthData?.estimatedReach)}
        />
        {/* <OverviewCard
          title="SMINCO Score"
          value={formatNumber(twitterProfile?.smincoScore)}
          chipValue={infleucnerRank?.topRankText}
          info="Platform Score"
        /> */}
      </Element>
      {/* Engagement */}
      <Element className={styles.navCategory} name="engagement">
        <div className={styles.sectionHeader}>
          <img src={heartGrayIcon} alt="" />
          <h2 className={styles.contntTxt}>Engagements & Views</h2>
        </div>
        <div className={clsx(styles.sectionBody, styles.engamntBody)}>
          <EngagementCard
            icon={twitterSvg}
            title="Tweets"
            items={[
              // {
              //   name: 'Total Tweets',
              //   value: formatNumber(twitterProfile?.mediaCount),
              // },
              {
                name: 'Avg. Impressions',
                value: formatNumber(twitterProfile?.avgImpressions, {
                  maximumFractionDigits: 2,
                }),
              },
              {
                name: 'Avg. Likes',
                value: formatNumber(twitterProfile?.avgLikes),
              },
              {
                name: 'Avg. Replies',
                value: formatNumber(twitterProfile?.avgComments),
              },
              {
                name: 'Avg. Retweets',
                value: formatNumber(twitterProfile?.avgRetweets),
              },
              // {
              //   name: 'Likes-Comments Ratio ',
              //   value: formatNumber(twitterProfile?.likesCommentsRatio, {
              //     maximumFractionDigits: 2,
              //   }),
              // },
            ]}
          />
          <EngagementCard
            icon={twitterSvg}
            title="Bio"
            items={[
              {
                name: 'Profile Tagline',
                value: twitterProfile?.bio,
              },
            ]}
          />
        </div>
        <InfContentPerformanceAndBenchmark
          data={contentPerformance}
          valueKey="impressions"
          influencerAverage={twitterProfile?.avgImpressions}
        />
      </Element>
      <Element name="growth">
        <InfFollowerGrowth
          data={followerGrowthData}
          isLoading={isLoadingFollowersGrowth}
          handleFilterChange={setFollersGrowthFilter}
          filter={follersGrowthFilter}
        />
      </Element>
      <Element name="content">
        <ContentSection
          hashtags={hashtags}
          recentPosts={recentPosts}
          topPosts={topPosts}
          brandPosts={brandPosts}
        />
      </Element>
      <Element name="brands">
        <BrandsSection brands={brands} platform="twitter" />
      </Element>
      {!twitterProfile.isDataFetched && (
        <div className={styles.profileOverlay}>
          <NoData title="Your profile is under review." description="" />
        </div>
      )}
    </section>
  );
};

export default Twitter;
