import React, { useState } from 'react';
import clsx from 'clsx';
import { motion, AnimatePresence } from 'framer-motion';
import styles from 'assets/scss/pages/landing-homepage.module.scss';
import {
  radiocityWebSearchImg,
  radiocityWebCustomListImg,
  radiocityWebAnalyseProfileImg,
} from 'constants/images';

const STEPS = [
  {
    id: 1,
    title: 'Find Influencers',
    description:
      'Identify impactful influencers tailored to your campaigns and their audience through our cutting-edge filters on diverse social media platforms. Gain crucial insights, including engagement rates, demographics, and audience demographics.',
    image: radiocityWebSearchImg,
  },
  {
    id: 2,
    title: 'Analyze Influencers',
    description:
      'Experience the efficiency of a unified dashboard, providing a comprehensive overview of content generated by influencers. Assess the influence of influencers on campaigns, measuring reach and engagement rates to optimize your strategy.',
    image: radiocityWebAnalyseProfileImg,
  },
  {
    id: 3,
    title: 'Create Own Custom List',
    description:
      'Take control of your influencer selection process by applying filters of your choice. Curate a customized list of selected influencers that aligns perfectly with your preferences.',
    image: radiocityWebCustomListImg,
  },
];

const HowWeWorkSection = () => {
  const [selectedStep, setSelectedStep] = useState(STEPS[0]);
  return (
    <div className={styles.workContainer}>
      <h1 className={styles.elevationTxt}>How do we work?</h1>
      <p className={styles.exTxt}>
        Here are the steps you can follow to find what you are looking for.
      </p>
      <div className={styles.workInnrWrapper}>
        <div>
          <AnimatePresence mode="wait">
            <motion.div
              key={selectedStep ? selectedStep.title : 'empty'}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -20, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <img src={selectedStep.image} alt="" width="812" height="531" />
            </motion.div>
          </AnimatePresence>
        </div>
        <div className={styles.workListContainer}>
          {STEPS.map((step) => {
            const isSlected = step.id === selectedStep.id;
            return (
              <div
                className={clsx({
                  [styles.workListInnr]: true,
                  [styles.workSub]: isSlected,
                })}
                key={step.id}
              >
                <button
                  type="button"
                  className={styles.workListBtn}
                  data-open={isSlected}
                  aria-expanded={isSlected}
                  aria-controls={`how-we-work-panel-${step.id}`}
                  onClick={() => setSelectedStep(step)}
                >
                  {step.title}
                </button>
                <motion.div
                  className={styles.workListTxt}
                  id={`how-we-work-panel-${step.id}`}
                  initial={false}
                  animate={
                    isSlected
                      ? {
                          height: 'auto',
                          opacity: 1,
                          display: 'block',
                          transition: {
                            height: {
                              duration: 0.3,
                            },
                            opacity: {
                              duration: 0.25,
                              delay: 0.15,
                            },
                          },
                        }
                      : {
                          height: 0,
                          opacity: 0,
                          transition: {
                            height: {
                              duration: 0.3,
                            },
                            opacity: {
                              duration: 0.25,
                            },
                          },
                          transitionEnd: {
                            display: 'none',
                          },
                        }
                  }
                >
                  {step.description}
                </motion.div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HowWeWorkSection;
