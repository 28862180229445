import { baseQuery } from './baseQuery';

const apiVersion = process.env.REACT_APP_API_VERSION;

export const linkInstagram = (params) =>
  baseQuery({
    url: `/auth/${apiVersion}/influencer/verify-instagramid`,
    method: 'GET',
    params,
  });

export const linkFacebook = (params) =>
  baseQuery({
    url: `/auth/${apiVersion}/influencer/verify-facebookid`,
    method: 'GET',
    params,
  });

export const linkYoutube = (params) =>
  baseQuery({
    url: `/auth/${apiVersion}/influencer/verify-youtube`,
    method: 'GET',
    params,
  });

export const linkTwitter = (params) =>
  baseQuery({
    url: `/auth/${apiVersion}/influencer/verify-twitter`,
    method: 'GET',
    params,
  });
