import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Dialog } from 'components/common/dialog';
import { queryClient } from 'App';
import { editProfile, getProfile } from 'api/influencer/profile';
import { INFLUENCER_PROFIE_LANGUAGES_SCHEMA } from 'constants/schema';
import CustomSelect from 'components/common/edit-profile/CustomSelect';
import { LANGUAGES } from 'constants/config';
import styles from 'assets/scss/pages/popup.module.scss';

const defaultValues = {
  language: [],
};

const EditProfileLanguages = ({ open, close }) => {
  const { data } = useQuery({
    queryKey: ['influencer-profile'],
    queryFn: () => getProfile(),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(INFLUENCER_PROFIE_LANGUAGES_SCHEMA),
    defaultValues,
  });

  const editProfileMutation = useMutation({
    mutationFn: ({ language }) => editProfile({ language }),
    onSuccess: () => {
      toast.success('Languages updated successfully', {
        position: 'top-center',
      });
      queryClient.invalidateQueries({ queryKey: ['influencer-profile'] });
      reset();
      close();
    },
  });

  useEffect(() => {
    if (data) {
      reset({
        language: data?.language ?? [],
      });
    }
  }, [data, reset]);

  return (
    <Dialog
      title="Edit Languages"
      open={open}
      close={close}
      size="591px"
      fullWidth
    >
      <form onSubmit={handleSubmit(editProfileMutation.mutate)}>
        <div className={styles.editFormWrapper}>
          <div className={styles.formGroup}>
            <Controller
              render={({ field }) => (
                <CustomSelect
                  label="Languages"
                  onChange={field.onChange}
                  value={field.value}
                  options={LANGUAGES}
                  ref={field.ref}
                  multiple
                />
              )}
              control={control}
              name="language"
            />
            {errors?.language ? (
              <p className={styles.formError}>{errors.language?.message}</p>
            ) : null}
          </div>
        </div>
        <div className={styles.editProfileContainer}>
          <div
            className={clsx(styles.campaignBtns, styles.nxtWrap)}
            style={{ marginTop: '16px' }}
          >
            <button type="button" className={styles.prevBtn} onClick={close}>
              Back
            </button>
            <button
              type="submit"
              style={{ width: '30%' }}
              className={styles.updateBtn}
              disabled={editProfileMutation.isLoading}
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

EditProfileLanguages.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default EditProfileLanguages;
