import React, { useCallback, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

const Dropzone = React.forwardRef(
  ({ onChange, render, wrapperClass, multiple = false, ...rest }, ref) => {
    const onDrop = useCallback(
      (acceptedFiles) => {
        onChange(acceptedFiles);
      },
      [onChange]
    );

    const { getRootProps, getInputProps, isDragActive, inputRef, ...other } =
      useDropzone({
        multiple,
        ...rest,
        onDrop,
      });

    useImperativeHandle(ref, () => inputRef.current, [inputRef]);

    return (
      <div
        className={wrapperClass}
        {...getRootProps()}
        style={{ opacity: isDragActive ? 0.72 : 1 }}
      >
        <input {...getInputProps()} />
        {render({ isDragActive, ...other })}
      </div>
    );
  }
);

Dropzone.propTypes = {
  onChange: PropTypes.func,
  render: PropTypes.func,
  wrapperClass: PropTypes.string,
  multiple: PropTypes.bool,
};

export default Dropzone;
