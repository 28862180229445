import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { animate, motion, useInView } from 'framer-motion';
import styles from 'assets/scss/pages/landing-homepage.module.scss';
import {
  influencerBrImg,
  // campaigBrImg,
  // memeberBrImg,
  // awardBrImg,
  homeSecondSectionImage,
} from 'constants/images';

// --------------------------------------------------------------------------

const IncresingNumber = ({ from, to }) => {
  const ref = useRef();
  const [alreadyInView, setAlreadyInView] = useState(false);

  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) setAlreadyInView(true);
  }, [isInView]);

  useEffect(() => {
    if (!alreadyInView) return;

    const node = ref.current;
    if (!node) return;

    const controls = animate(from, to, {
      duration: 2,
      viewport: { once: true },
      onUpdate(value) {
        node.textContent = Math.round(value).toString();
      },
    });

    // eslint-disable-next-line consistent-return
    return () => {
      controls.stop();
    };
  }, [from, to, alreadyInView]);

  return <motion.span ref={ref} />;
};

IncresingNumber.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
};

// --------------------------------------------------------------------------

const NumbersSection = () => (
  <div className={styles.brandingSection}>
    <div className={styles.innrBrand}>
      <img src={influencerBrImg} alt="" />
      <h3 className={styles.subscribeUserTxt}>
        <IncresingNumber from={0} to={60} />
        K+
      </h3>
      <p className={styles.subsTitle}>Influencers across India</p>
    </div>
    <div>
      <img
        src={homeSecondSectionImage}
        alt="influencers collage"
        className={styles.numbersImage}
      />
    </div>
    {/* <div className={styles.innrBrand}>
      <img src={campaigBrImg} alt="" />
      <h3 className={styles.subscribeUserTxt}>
        <IncresingNumber from={0} to={50} />K +
      </h3>
      <p className={styles.subsTitle}>Campaigns</p>
    </div>
    <div className={styles.innrBrand}>
      <img src={memeberBrImg} alt="" />
      <h3 className={styles.subscribeUserTxt}>
        <IncresingNumber from={0} to={2} />
        M+
      </h3>
      <p className={styles.subsTitle}>Members</p>
    </div>
    <div className={styles.innrBrand}>
      <img src={awardBrImg} alt="" />
      <h3 className={styles.subscribeUserTxt}>
        <IncresingNumber from={0} to={200} />+
      </h3>
      <p className={styles.subsTitle}>Awards</p>
    </div> */}
  </div>
);

export default NumbersSection;
