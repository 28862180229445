import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tab } from '@headlessui/react';
import { Dialog } from 'components/common/dialog';
import { Avatar } from 'components/common/Avatar';
import styles from 'assets/scss/pages/dashboard.module.scss';
import { coloredCrossIcon, transparentBg } from 'constants/images';
import { formatCurrency } from 'utils';

const DeliverablesCostBreakdownDialog = ({ open, close, influencer = {} }) => (
  <Dialog open={open} close={close} fullWidth>
    <Tab.Group as="div">
      <div className={styles.dashboardTop}>
        <img className={styles.transBg} src={transparentBg} alt="" />
        <div className={styles.campaignContainer} style={{ padding: '25px' }}>
          <div className={styles.popupHeader}>
            <div className={styles.campaignHeading} style={{ margin: '0px' }}>
              <Avatar size="large" src={influencer.profileImage} alt="" />
              <h2
                className={styles.campignTxt}
                style={{ fontSize: '1.125rem' }}
              >
                {influencer.fullName}
              </h2>
            </div>
            <div className={styles.crossImg}>
              <button type="button" onClick={close}>
                <img src={coloredCrossIcon} alt="" />
              </button>
            </div>
          </div>
        </div>
        <Tab.List className={styles.categoryButtons}>
          {influencer.costBreakdownByPlatform?.map(({ platform }) => (
            <Tab
              key={platform}
              className={({ selected }) =>
                clsx(
                  styles.influencerCateBtn,
                  selected && styles.whiteActiveBtn
                )
              }
            >
              {platform}
            </Tab>
          ))}
        </Tab.List>
      </div>
      <Tab.Panels className={styles.billingTableContainer}>
        {influencer.costBreakdownByPlatform?.map(
          ({ platform, total, deliverablesCostBreakdown }) => (
            <Tab.Panel key={platform}>
              <table className={styles.billingTable}>
                <thead>
                  <tr>
                    <th>Deliverable Type</th>
                    <th>Qty</th>
                    <th>Unit Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {deliverablesCostBreakdown?.map(
                    ({ qty, total, type, unitPrice }) => (
                      <tr key={type}>
                        <td className={styles.capitalize}>{type}</td>
                        <td>{qty}</td>
                        <td>{formatCurrency(unitPrice)}</td>
                        <td>{formatCurrency(total)}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <div className={styles.billingFooter}>
                <p>Total</p>
                <p>{formatCurrency(total)}</p>
              </div>
            </Tab.Panel>
          )
        )}
      </Tab.Panels>
    </Tab.Group>
  </Dialog>
);

DeliverablesCostBreakdownDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  influencer: PropTypes.object,
};

export default DeliverablesCostBreakdownDialog;
