import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = {
  formData: {},
  formStep: 1,
};

export const useInfulencerOnboardingForm = create((set) => ({
  ...initialState,
  goToNextStep: () =>
    set((state) => ({ ...state, formStep: state.formStep + 1 })),
  addFormData: (data) =>
    set((state) => ({ ...state, formData: { ...state.formData, ...data } })),
  reset: () => set(initialState),
}));

export const useBrandOnboardingForm = create((set) => ({
  ...initialState,
  goToNextStep: () =>
    set((state) => ({ ...state, formStep: state.formStep + 1 })),
  addFormData: (data) =>
    set((state) => ({ ...state, formData: { ...state.formData, ...data } })),
  reset: () => set(initialState),
}));
