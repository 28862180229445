import React from 'react';
import PropTypes from 'prop-types';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import Tooltip from 'components/common/Tooltip';

const OverviewCard = ({ info, title, value, chipValue }) => (
  <div className={styles.tabCardSection}>
    <div className={styles.sectionHeader}>
      <h3 className={styles.followerLabel}>{title}</h3>
      {info && <Tooltip label={info} />}
    </div>
    <div className={styles.sectionBottom}>
      {chipValue ? (
        <>
          <p className={styles.radioCount}>{value}</p>
          <span className={styles.offerTxt}>{chipValue}</span>
        </>
      ) : (
        <p className={styles.followerCount}>{value}</p>
      )}
    </div>
  </div>
);

OverviewCard.propTypes = {
  info: PropTypes.node,
  title: PropTypes.node,
  value: PropTypes.node,
  chipValue: PropTypes.node,
};

export default OverviewCard;
