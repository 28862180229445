import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCampaignDetails } from 'api/influencer/campaign';
import styles from 'assets/scss/pages/deliverables.module.scss';
import { locationDownIcon } from 'constants/images';
import { PLATFORM_ICONS } from 'constants/config/platformIcons';

const Deliverables = () => {
  const { id: campaignId } = useParams();

  const { data: campaign } = useQuery({
    queryKey: ['influencer-campaign-details', campaignId],
    queryFn: () => getCampaignDetails({ campaignId }),
    select: (res) => res?.data?.campaign,
  });

  const deliverablePlatforms = useMemo(
    () =>
      campaign?.campaign_influencers?.[0]?.deliverables?.map(
        ({ media_type: platform }) => platform
      ) || [],
    [campaign]
  );

  const isInstagram = deliverablePlatforms.includes('instagram');
  const isYoutube = deliverablePlatforms.includes('youtube');
  const isTwitter = deliverablePlatforms.includes('twitter');
  const isFacebook = deliverablePlatforms.includes('facebook');

  return (
    <section>
      <div className={styles.deliverableWrapper}>
        <div className={styles.delLeft}>
          <div className={styles.summaryTabList}>
            {isInstagram && (
              <TabButton
                icon={PLATFORM_ICONS.instagram}
                to="instagram"
                title="Instagram"
              />
            )}
            {isYoutube && (
              <TabButton
                icon={PLATFORM_ICONS.youtube}
                to="youtube"
                title="Youtube"
              />
            )}
            {isTwitter && (
              <TabButton
                icon={PLATFORM_ICONS.twitter}
                to="twitter"
                title="Twitter"
              />
            )}
            {isFacebook && (
              <TabButton
                icon={PLATFORM_ICONS.facebook}
                to="facebook"
                title="Facebook"
              />
            )}
          </div>
        </div>
        <Outlet />
      </div>
    </section>
  );
};

const TabButton = ({ icon, title, to }) => (
  <NavLink
    className={({ isActive }) =>
      clsx({
        [styles.summaryTabListItem]: true,
        [styles.activeTabItem]: isActive,
      })
    }
    to={to}
    end
  >
    <span className={styles.subList}>
      <img className={styles.socialImg} src={icon} alt="" />
      <span className={styles.activeTxt}>{title}</span>
    </span>
    <img className={styles.rightArrow} src={locationDownIcon} alt="" />
  </NavLink>
);

TabButton.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string,
};

export default Deliverables;
