import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import DashboardProfileSeaction from 'components/brand-dashboard/DashboardProfileSection';
import DashboardSearchSection from 'components/brand-dashboard/DashboardSearchSection';
import routes from 'constants/routes';
import styles from 'assets/scss/pages/dashboard.module.scss';
import {
  arrowDownIcon,
  locationDownIcon,
  reportIcon,
  expertCallImage,
  svgExport1,
  svgExport2,
  svgExport3,
  svgExport4,
  transparentBg,
  // blurBg,
  // premiumIcon,
  // coloredCrossIcon,
  // greenDoubletickIcon,
} from 'constants/images';
import CampaignDashBoard from './CampaignDashboard';
import { prefetchInfluencers } from './search/InfluencerList';
// import { prefectchSubCategories } from './search/InfluencerCategories';

const HOW_IT_WORKS_VIDEOS = [
  {
    title: "Natures's Basket",
    youtubeEmbedUrl:
      'https://www.youtube.com/embed/JLt70IJszN8?si=MDuE9W_VwSyI1ndy',
    addedOn: '26 Dec 2023',
  },
  {
    title: 'Payed',
    youtubeEmbedUrl:
      'https://www.youtube.com/embed/Wb9cKd1OQ_g?si=tVcS06pcIOPi8od6',
    addedOn: '26 Dec 2023',
  },
  {
    title: 'Payed 02',
    youtubeEmbedUrl:
      'https://www.youtube.com/embed/5nYvLUvQ-cg?si=O6nqgqDy3GekY2jx',
    addedOn: '26 Dec 2023',
  },
  {
    title: 'Cidco',
    youtubeEmbedUrl:
      'https://www.youtube.com/embed/cOQV9dpCQkc?si=H0YxiPpvzk5sfWjg',
    addedOn: '26 Dec 2023',
  },
  {
    title: 'Western Railways',
    youtubeEmbedUrl:
      'https://www.youtube.com/embed/esQVSGnq54M?si=FQr7pdQuUPDRTqxi',
    addedOn: '26 Dec 2023',
  },
];

const Dashboard = () => {
  const timeoutRef = useRef();
  const [showHowItWorks, setShowHowItWorks] = useState(false);

  const toggleHowItWorks = () => {
    setShowHowItWorks((prev) => !prev);
  };

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      prefetchInfluencers();
      // prefectchSubCategories();
    }, 1000);

    return () => clearTimeout(timeoutRef.current);
  }, []);

  return (
    <>
      <div className="dashboardSearch">
        <div className={styles.dashboardTop}>
          <img className={styles.transBg} src={transparentBg} alt="" />
          <div className={styles.innerDashboardContent}>
            {/* <h3 className={styles.alertBox}>
              <span className={styles.spanBox} />
              News or Alert Box: Check out our latest offers. Subscribe now to
              avail combo!
            </h3> */}
            <DashboardProfileSeaction />
            <DashboardSearchSection />
            <button
              type="button"
              className={styles.searchModal}
              onClick={toggleHowItWorks}
            >
              <span className={styles.linkTxt}>See how it works</span>
              <img src={locationDownIcon} alt="" />
            </button>
            {showHowItWorks ? (
              <div className={styles.rowCard}>
                {HOW_IT_WORKS_VIDEOS.map((video) => (
                  <div
                    className={styles.cardHeader}
                    key={video.youtubeEmbedUrl}
                  >
                    <iframe
                      className={styles.cardImage}
                      width="560"
                      height="315"
                      src={video.youtubeEmbedUrl}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    />

                    <h2 className={styles.cardTxt}>{video.title}</h2>
                    <p className={styles.expDate}>Added {video.addedOn}</p>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.categoryContainer}>
        <Link to={routes.BRAND_SEARCH_INF} className={styles.viewAllCate}>
          <div className={styles.cateImg}>
            <img src={reportIcon} alt="" />
          </div>
          <div className={styles.categoryHeader}>
            <span className={styles.categoryTxt}>
              View All Influencer Categories
            </span>
            <img className={styles.drImg} src={arrowDownIcon} alt="" />
          </div>
        </Link>
        <div className={styles.expertCate}>
          <div className={styles.expertInner}>
            <div className={styles.expertCallImg}>
              <img src={expertCallImage} alt="" />
            </div>
            <div className={styles.extContent}>
              <h2 className={styles.extTxt}>
                Get strategic inputs from our experts who have serviced over 500
                global brands
              </h2>
              <ul className={styles.expList}>
                <li className={styles.expInnerList}>
                  <img src={svgExport1} alt="" />
                  <span className={styles.guidedText}>
                    Guided influencer selection
                  </span>
                </li>
                <li className={styles.expInnerList}>
                  <img src={svgExport2} alt="" />
                  <span className={styles.guidedText}>
                    Better ROI prediction
                  </span>
                </li>
                <li className={styles.expInnerList}>
                  <img src={svgExport3} alt="" />
                  <span className={styles.guidedText}>
                    Prompt response usually in an hour
                  </span>
                </li>
                <li className={styles.expInnerList}>
                  <img src={svgExport4} alt="" />
                  <span className={styles.guidedText}>Transparent pricing</span>
                </li>
                {/* <li>
                  <div className="connectBtns">
                    <button type="button" className={styles.connctBtn}>
                      Talk To Us
                    </button>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.guestUser}>
        <img className={styles.blurBg} src={blurBg} alt="" />
        <div className={styles.subscriptionBox}>
          <div className={styles.subscriberHeader}>
            <div className={styles.subsTxt}>
              <img src={premiumIcon} alt="" />
              <h3 className={styles.premiumTxt}>
                For access to all the full features of RC, subscribe now!
              </h3>
            </div>
            <div className={styles.crossImg}>
              <img src={coloredCrossIcon} alt="" />
            </div>
          </div>
          <div className={styles.subscriptionDetail}>
            <ul className="subcrList">
              <li className={styles.featurePoints}>
                <img src={greenDoubletickIcon} alt="" />
                <span className={styles.featurTxt}>Unlimited Post</span>
              </li>
              <li className={styles.featurePoints}>
                <img src={greenDoubletickIcon} alt="" />
                <span className={styles.featurTxt}>
                  We’ll get you onboarded
                </span>
              </li>
              <li className={styles.featurePoints}>
                <img src={greenDoubletickIcon} alt="" />
                <span className={styles.featurTxt}>Live stream</span>
              </li>
              <li className={styles.featurePoints}>
                <img src={greenDoubletickIcon} alt="" />
                <span className={styles.featurTxt}>
                  Access to message in one inbox
                </span>
              </li>
            </ul>
            <div className={styles.subscribeButtons}>
              <button type="button" className={styles.scheduleBtn}>
                Schedule a Demo
              </button>
              <button type="button" className={styles.subscribenowBtn}>
                Subscribe Now
              </button>
            </div>
          </div>
        </div>
      </div> */}
      <CampaignDashBoard />
    </>
  );
};

export default Dashboard;
