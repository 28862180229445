import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSpinDelay } from 'spin-delay';
import { differenceInCalendarDays } from 'date-fns';
import { Link, NavLink, Outlet, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCampaignDetails } from 'api/brand/campaign';
import { Avatar, AvatarGroup } from 'components/common/Avatar';
import { LoaderWithWrapper } from 'components/common/Loader';
import styles from 'assets/scss/pages/campaign.module.scss';
import { transparentBg, locationDownIcon } from 'constants/images';
import routes from 'constants/routes';
import {
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_CHIPS,
} from 'constants/config/campaign';
import { PLATFORM_ICONS } from 'constants/config/platformIcons';

const NavItem = ({ children, ...props }) => (
  <NavLink
    className={({ isActive }) =>
      clsx({
        [styles.influencerCateBtn]: true,
        [styles.whiteActiveBtn]: isActive,
      })
    }
    {...props}
  >
    {children}
  </NavLink>
);

NavItem.propTypes = {
  children: PropTypes.node,
};

const CampaignDetails = () => {
  const { id: campaignId } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ['brand-campaign-details', campaignId],
    queryFn: () => getCampaignDetails({ id: campaignId }),
  });

  const delayedIsLoading = useSpinDelay(isLoading, {
    delay: 500,
    minDuration: 700,
  });

  const {
    campaign_name: campaignName = '-',
    brand_name: brandName = '-',
    target_platforms: platforms,
    campaign_start_date: startDateTime,
    campaign_end_date: endDateTime,
    regions,
    target_genders: genders,
    ages: ageGroups,
    social_hashtags: tags,
    categories,
    campaign_status: campaignStatus,
  } = data?.data?.campaign || {};

  const regionString =
    regions?.map((region) => region.region_name).join(', ') || '-';
  const genderString =
    genders?.length === 3 ? 'All' : genders?.join(', ') || '-';
  const ageGroupString =
    ageGroups
      ?.map(({ age_from: from, age_to: to }) => `${from}-${to}`)
      .join(', ') || '-';
  const categoryString =
    categories?.map((category) => category?.category_name).join(', ') || '-';
  const tagString = tags?.map((tag) => tag.hashtag_name).join(', ') || '-';
  const campaignDurationInDays =
    startDateTime && endDateTime
      ? differenceInCalendarDays(new Date(endDateTime), new Date(startDateTime))
      : '-';

  if (isLoading || delayedIsLoading) {
    return <LoaderWithWrapper show={delayedIsLoading} />;
  }

  return (
    <>
      <div className={styles.dashboardTop}>
        <img className={styles.transBg} src={transparentBg} alt="" />
        <div className={styles.campaignContainer}>
          <div className={styles.campignBreadcrumbs}>
            <Link to={routes.BRAND_CAMPAIGNS} className={styles.navTxt}>
              Campaings
            </Link>
            <img className={styles.rightArrow} src={locationDownIcon} alt="" />
            <span className={styles.navSecndTxt}>{campaignName}</span>
          </div>
          <div className={styles.campaignHeading}>
            <h2 className={styles.campignTxt}>{campaignName}</h2>
            {CAMPAIGN_STATUS_CHIPS[campaignStatus]}
          </div>
          <div className="campaignDiv">
            <ul className={styles.campaignList}>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Brand</h2>
                <p className={styles.detailTxt}>{brandName}</p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Platform</h2>
                {platforms ? (
                  <AvatarGroup>
                    {platforms.map((platformName) => (
                      <Avatar
                        src={PLATFORM_ICONS[platformName]}
                        alt=""
                        key={platformName}
                        size="small"
                      />
                    ))}
                  </AvatarGroup>
                ) : (
                  '-'
                )}
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Duration (Days)</h2>
                <p className={styles.detailInnrTxt}>{campaignDurationInDays}</p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Region</h2>
                <p
                  className={clsx(styles.detailInnrTxt, 'line-clamp-2')}
                  title={regionString}
                >
                  {regionString}
                </p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Gender</h2>
                <p className={styles.detailInnrTxt}>{genderString}</p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Age Group (Years)</h2>
                <p className={styles.detailInnrTxt}>{ageGroupString}</p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Category</h2>
                <p
                  className={clsx(styles.detailInnrTxt, 'line-clamp-2')}
                  title={categoryString}
                >
                  {categoryString}
                </p>
              </li>
              <li className={styles.campaignInnrList}>
                <h2 className={styles.headingTxt}>Tags</h2>
                <p className={styles.detailInnrTxt}>{tagString}</p>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.categoryButtons}>
          <NavItem to="" end>
            Influencers
          </NavItem>
          {campaignStatus === CAMPAIGN_STATUS.ACTIVE ||
          campaignStatus === CAMPAIGN_STATUS.COMPLETED ||
          campaignStatus === CAMPAIGN_STATUS.ARCHIVED ? (
            <NavItem to="reports">
              {campaignStatus === CAMPAIGN_STATUS.COMPLETED ||
              campaignStatus === CAMPAIGN_STATUS.ARCHIVED
                ? 'Campaign Reports'
                : 'Live Tracking'}
            </NavItem>
          ) : null}
          <NavItem to="meeting-notes">Meeting Notes</NavItem>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default CampaignDetails;
