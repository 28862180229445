import React, { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  getCampaignDetails,
  getCampaignDeliverables,
} from 'api/influencer/campaign';
import DeliverableSection from 'components/inf-dashboard/campaigns/DeliverableSection';
import styles from 'assets/scss/pages/deliverables.module.scss';

const PlatformDeliverables = () => {
  const { id: campaignId, platform } = useParams();

  const { data: campaign } = useQuery({
    queryKey: ['influencer-campaign-details', campaignId],
    queryFn: () => getCampaignDetails({ campaignId }),
    select: (res) => res?.data?.campaign,
  });

  const { data: uploadedFiles, isLoading } = useQuery({
    queryKey: ['influencer-campaign-deliverables', campaignId, platform],
    queryFn: () => getCampaignDeliverables({ campaignId, platform }),
    keepPreviousData: false,
    select: (res) => res?.data?.result?.rows,
  });

  const platformDeliverables = useMemo(() => {
    const rawDeliverables =
      campaign?.campaign_influencers?.[0]?.deliverables?.find(
        (deliverable) => deliverable.media_type === platform
      );
    const uploadedDeliverables = campaign?.campaign_influencers?.[0];

    const deliverables = [];
    if (rawDeliverables) {
      switch (platform) {
        case 'instagram':
          if (rawDeliverables.reel) {
            deliverables.push({
              type: 'reel',
              id: 'insta-reel',
              allocatedCount: rawDeliverables.reel,
              uploadedCount: uploadedDeliverables.uploaded_reel,
            });
          }
          if (rawDeliverables.stories) {
            deliverables.push({
              type: 'stories',
              id: 'insta-story',
              allocatedCount: rawDeliverables.stories,
              uploadedCount: uploadedDeliverables.uploaded_stories,
            });
          }
          // NOTE: Video is not supported by insta
          // if (rawDeliverables.video) {
          //   deliverables.push({
          //     type: 'video',
          //     id: 'insta-video',
          //     allocatedCount: rawDeliverables.video,
          //     uploadedCount: uploadedDeliverables.uploaded_video_instagram,
          //   });
          // }
          if (rawDeliverables.image) {
            deliverables.push({
              type: 'image',
              id: 'insta-image',
              allocatedCount: rawDeliverables.image,
              uploadedCount: uploadedDeliverables.uploaded_image,
            });
          }
          break;
        case 'youtube':
          if (rawDeliverables.video) {
            deliverables.push({
              type: 'video',
              id: 'yt-video',
              allocatedCount: rawDeliverables.video,
              uploadedCount: uploadedDeliverables.uploaded_video_youtube,
            });
          }
          if (rawDeliverables.shorts) {
            deliverables.push({
              type: 'shorts',
              id: 'yt-short',
              allocatedCount: rawDeliverables.shorts,
              uploadedCount: uploadedDeliverables.uploaded_shorts,
            });
          }
          break;
        case 'facebook':
          if (rawDeliverables.posts) {
            deliverables.push({
              type: 'posts',
              id: 'fb-post',
              allocatedCount: rawDeliverables.posts,
              uploadedCount: uploadedDeliverables.uploaded_posts,
            });
          }
          break;
        case 'twitter':
          if (rawDeliverables.tweets) {
            deliverables.push({
              type: 'tweets',
              id: 'twitter-tweet',
              allocatedCount: rawDeliverables.tweets,
              uploadedCount: uploadedDeliverables.uploaded_tweet,
            });
          }
          break;
        default:
          break;
      }
    }
    return deliverables;
  }, [campaign, platform]);

  if (isLoading) {
    return null;
  }

  // *redirect to platform from deliverables if there is no-data(platform is not part of campaign/deliverables) for selected platform
  if (campaign?.campaign_influencers && !platformDeliverables.length) {
    return (
      <Navigate
        to={`../${campaign?.campaign_influencers?.[0]?.deliverables?.[0]?.media_type}`}
        replace
      />
    );
  }

  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <div className={styles.delRight}>
        {platformDeliverables.map(
          ({ id, type, allocatedCount, uploadedCount }) => (
            <DeliverableSection
              key={id}
              platform={platform}
              deliverableType={type}
              allocatedCount={allocatedCount}
              uploadedCount={Number(uploadedCount)}
              uploadedFiles={uploadedFiles}
              campaignId={campaignId}
              campaignStatus={campaign?.campaign_status}
              campaignTags={campaign?.social_hashtags}
            />
          )
        )}
      </div>
    </div>
  );
};

export default PlatformDeliverables;
